import { Box, Chip, Stack, Typography } from "@mui/material";
import React from "react";
import { formatBytes } from "@/functions";
import { CAPACYTIES } from "@/libs/constants";

const CellConfig = ({ configures, message }) => {
  const rebuildConfigServer = () => {
    return CAPACYTIES.map((o) => ({ ...o, value: configures[o.key] || 0 }));
  };
  const isEmptyConfig =
    rebuildConfigServer()?.filter(({ value }) => value === 0)?.length === 4;
  return (
    <Box
      sx={{
        height: "auto",
        p: "4px",
        width: "100%",
        overflow: "hidden",
        justifyContent: "center",
      }}
    >
      {isEmptyConfig ? (
        <Typography sx={{ color: "#777680", fontSize: 14 }}>
          {message || "Không Có Cấu Hình"}
        </Typography>
      ) : (
        <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>
          {rebuildConfigServer()?.map((config, index) => {
            if (config?.value === 0) return null;

            return (
              <Chip
                key={index}
                label={
                  ["STORAGE", "RAM"].includes(config?.label)
                    ? `${config?.label}: ${formatBytes(config?.value)}`
                    : `${config?.label}: ${config?.value} ${config?.unit}`
                }
                variant="outlined"
                size="small"
                color={`${config?.key.toLowerCase()}`}
              />
            );
          })}
        </Stack>
      )}
    </Box>
  );
};

export default CellConfig;
