import InvestmentApi from "@/apis/beta/Warehouse/InvestmentApi";
import WarehouseRackApi from "@/apis/beta/Warehouse/WarehouseRackApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Grid, Link, Paper } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import CellSpecification from "@components/DataGrid/CellSpecification";
import CellConfig from "@components/DataGrid/CellConfig2";
import ItemPaperStatistical from "@components/shared/ItemPaperStatistical";
import DataGridProCustomize from "@components/DataGridProCustomize";
import { getMuiDataGridRowHeight, getRacks } from "@/functions";

const ReportResource = () => {
    const { data: resource_services, isLoading } = InvestmentApi.overview();
    const { data: warehouse_racks } = WarehouseRackApi.getAll();
    const { data: investments } = InvestmentApi.getAll()


    const [data, setData] = React.useState([])
    const [collumns, setCollumns] = React.useState([])

    const getData = () => {
        let rows = []
        _.uniqBy(resource_services, o => o.warehouse_resource_service_name).forEach((item, item_index) => {
            const clusters = resource_services.filter(i => i.warehouse_resource_service_name === item.warehouse_resource_service_name)
            const configs = _.flatten(_.map(clusters, c => c["warehouse_resource_configs"]))
            const devices = _.flatten(_.map(clusters, c => c["warehouse_items"]))

            _.forEach(clusters, (o, o_index) => {
                const i_devices = o["warehouse_items"]
                const i_configs = o["warehouse_resource_configs"]

                rows.push({
                    id: `${o_index + 1}.${o.warehouse_resource_service_id}`,
                    type: "investment",
                    service_name: item.warehouse_resource_service_node ? item.warehouse_resource_service_node : item.warehouse_resource_service_name,
                    investment_name: `${o.warehouse_resource_service_code}`,
                    investment_id: _.find(investments, o => o["investment_name"] === item.warehouse_resource_service_code)?.investment_id,
                    configs: i_configs,
                    racks: getRacks(i_devices, warehouse_racks),
                    group: [item_index + 1, o_index + 1],
                    total_server: i_devices.filter(ii => ii.warehouse_type_name === "SERVER")?.length,
                    total_switch: i_devices.filter(ii => ii.warehouse_type_name === "SWITCH")?.length,
                })
            })

            rows.push({
                id: item.warehouse_resource_service_id,
                type: "service",
                service_name: item.warehouse_resource_service_name,
                configs: configs,
                racks: getRacks(devices, warehouse_racks),
                group: [item_index + 1],
                total_server: devices.filter(ii => ii.warehouse_type_name === "SERVER")?.length,
                total_switch: devices.filter(ii => ii.warehouse_type_name === "SWITCH")?.length,
            })
        })

        setData(rows)
        setCollumns([
            {
                field: 'service_name',
                headerName: "Dịch Vụ",
                width: 200,
            },
            {
                field: 'investment_id',
                headerName: "Tên Cụm Đầu Tư",
                width: 200,
                renderCell: ({ row, value }) => {

                    return <> {value && <Link component={NavLink} to={`/warehouse/investments/${value}`} > {row?.investment_name}</Link>}</>

                }

            },
            {
                field: 'total_server',
                headerName: "Server",
                cellClassName: 'server',
                headerAlign: 'center',
                sortable: true,
                width: 100,
            },

            {
                field: 'total_switch',
                headerName: "Switch",
                cellClassName: 'switch',
                headerAlign: 'center',
                sortable: true,
                width: 100,
            },
            {
                field: 'uplinks',
                headerName: "Uplink",
                sortable: true,
                width: 100,
            },
            {
                field: 'capacity',
                headerName: "Capacity",
                sortable: true,
                valueGetter: ({ row }) => {
                    const config = {
                        capacity_cpu: _.sumBy(row.configs, o => (o.warehouse_resource_config_type === "config") ? o.warehouse_resource_config_cpu : 0),
                        capacity_ram: _.sumBy(row.configs, o => (o.warehouse_resource_config_type === "config") ? o.warehouse_resource_config_ram : 0),
                        capacity_storage: _.sumBy(row.configs, o => (o.warehouse_resource_config_type === "config") ? o.warehouse_resource_config_storage : 0),
                    }
                    return config
                },
                renderCell: ({ value }) => (value && <CellConfig configures={value} />),
                width: 450,
            },
            {
                field: 'racks',
                width: 300,
                headerName: 'Tủ Rack - IDC',
                renderCell: ({ value }) => (value && <CellSpecification items={value} />),
            },
        ])
    }
    React.useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box sx={{
            p: 1,
            '& .server': {
                backgroundColor: '#b9d5ff91',
                fontWeight: '600',
                textAlign: "center"
            },
            '& .switch': {
                backgroundColor: '#ff943975',
                fontWeight: '600',
                textAlign: "center"
            },

        }}>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xl={2} lg={2} md={3} sm={6} xs={6} >
                    <ItemPaperStatistical key={1} label={`Server`} value={_.sumBy(data, o => (o.type === "service") ? o.total_server : 0)} />
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={6} xs={6} >
                    <ItemPaperStatistical key={1} label={`Switch `} value={_.sumBy(data, o => (o.type === "service") ? o.total_switch : 0)} />
                </Grid>
            </Grid>

            <Paper sx={{
                mt: 3,
            }}>
                <DataGridProCustomize
                    rows={data}
                    columns={collumns}
                    loading={isLoading}
                    getTreeDataPath={(row) => row?.group}
                    getRowHeight={(params) => {
                        return getMuiDataGridRowHeight([_.uniq(params.model?.racks), [{}, {}]], {})
                    }}
                    treeData
                    defaultGroupingExpansionDepth={0}
                    disableColumnFilter={true}
                />
            </Paper>
        </Box>
    )
}
export default withSuspense(withPermission(ReportResource, {
    feature: "warehouse",
    action: "report-resource"
}), MuiSkeleton["DataGrid"]) 