import { Button } from '@mui/material';
import React from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import withPermission from '@/hocs/withPermission';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';

const AddContact = ({
    ...customer
}) => {
    const { customer_id } = useParams()
    const navigate = useNavigate();
    const isDisabled = customer?.customer_service_information?.customer_status === "cancelled" || customer?.customer_service_information?.customer_status !== "active";

    const handleClick = () => {
        if (!isDisabled) {
            navigate(`/customers/${customer_id}/customer_contacts/new`,{replace: true});
        }
    };

    return (
        <Button variant='outlined'
            disabled={isDisabled}
            size='small'
            fullWidth
            startIcon={<PersonAddOutlinedIcon fontSize='small' />}
            onClick={handleClick}
            sx={{ height: { xs: 40, md: 42 } }}
        >
            Thêm mới liên hệ
        </Button>
    );
}

export default withPermission(AddContact, { feature: "customer", action: "update", type: "button" });