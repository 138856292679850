import { Box, CircularProgress, Drawer, Tooltip, Typography } from "@mui/material";
import React from "react";
import QuickViewWarehouseItem from "./QuickViewWarehouseItem";


const DrawerWarehouseItem = ({items}) => {
    const [openDrawer,setOpenDrawer] = React.useState({open: false, data: null})
    return (
        <Box sx={{ width: '100%', p: 1 }}>
            <React.Suspense fallback={<CircularProgress size={16} disableShrink />}>
            {openDrawer?.open &&
                <Drawer
                    anchor="right"
                    open={openDrawer?.open}
                    sx={{ width: 360 }}
                >
                    <QuickViewWarehouseItem data={openDrawer?.data}
                        handleClose={() => setOpenDrawer({ data: undefined, open: false })}
                    />
                </Drawer>}
            </React.Suspense>
            {items?.map((warehouseItem, index) =>
                <Tooltip title={`${warehouseItem?.warehouse_item_name} - (${warehouseItem?.warehouse_item_service_tag})`}
                    key={index}
                    placement="top"
                >
                    <Typography
                        color="primary"
                        onClick={() => setOpenDrawer({ open: true, data: warehouseItem })}
                        sx={{
                            fontSize: 14, ":hover": {
                                textDecoration: "underline",
                                cursor: "pointer"
                            }
                        }}>{`${warehouseItem?.warehouse_item_name}`}</Typography>
                </Tooltip>
            )}
        </Box>
    )
}
export default DrawerWarehouseItem;