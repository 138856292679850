import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseBoxLogApi from "@/apis/beta/Warehouse/WarehouseBoxLogApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import { useEmployeeContext } from "@contexts/employee";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

const CreateNewIDC = ({ open, handleClose, refetch, refetchLogs }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      warehouse_box_name: "",
      warehouse_box_region: "",
      warehouse_box_type: 1,
      warehouse_box_description: "",
    },
  });
  const {employee} = useEmployeeContext();

  const { data: regions, isLoading } = WarehouseBoxApi.regions();

  const {
    mutateAsync: createIDC,
    isLoading: createIDCPending,
    notify,
  } = useMutationWithNotification(WarehouseBoxApi.create);

  const handleCreateIDC = async (values) => {
    const response = await createIDC(values);
    if (!response?.errors) {
      const log = {
        warehouse_box_name: response?.data?.data.warehouse_box?.warehouse_box_name,
        warehouse_box_id: response?.data?.data.warehouse_box?.warehouse_box_id,
        warehouse_box_update_type: "CREATE-IDC",
        metadata: response?.data?.data.warehouse_box,
        warehouse_box_update_description: "Tạo mới IDC",
        warehouse_box_updater_id: employee?.employee_id,
        warehouse_box_updater_name: employee?.employee_name,
      }
      await WarehouseBoxLogApi.createBoxLog(log);
      notify("success", "Tạo IDC thành công");
      refetch();
      if (refetchLogs) refetchLogs();
      handleClose();
    }
  };

  useEffect(() => {
    setValue(
      "warehouse_box_region",
      regions?.warehouse_box_regions?.[0]?.value
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions?.warehouse_box_regions?.length]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <Box component="form" onSubmit={handleSubmit(handleCreateIDC)}>
        <DialogTitle>Thêm IDC</DialogTitle>

        <DialogContent>
          <Box mt={2} display="flex" gap={2}>
            <Box flex={1}>
              <Controller
                control={control}
                rules={{
                  required: "Tên IDC là bắt buộc.",
                }}
                name="warehouse_box_name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    label="Tên IDC"
                    error={!!errors?.warehouse_box_name}
                    helperText={errors?.warehouse_box_name?.message}
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                control={control}
                name="warehouse_box_region"
                rules={{
                  required: "Thông tin bắt buộc.",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    disabled={isLoading}
                  >
                    {regions?.warehouse_box_regions?.map((item) => (
                      <MenuItem key={item?.value} value={item?.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {!!errors?.warehouse_box_region && (
                <ErrorHelperText
                  message={errors?.warehouse_box_region?.message}
                />
              )}
            </Box>
          </Box>

          <Box mt={2}>
            <Controller
              control={control}
              name="warehouse_box_description"
              render={({ field }) => (
                <TextField multiline {...field} placeholder="Note" />
              )}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button size="small" variant="outlined" onClick={handleClose}>
            Huỷ
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            disabled={createIDCPending}
            startIcon={createIDCPending && <CircularProgress size={14} />}
          >
            Thêm IDC
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreateNewIDC;
