import ContractApi from "@/apis/beta/Contract/ContractApi";
import FileApi from "@/apis/shared/File/FileApi";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import InputFileCustomize from "@/components/shared/InputFileCustomize";
import SingleDocument from "@/components/shared/SingleDocument";
import SingleSelectCustomize from "@/components/shared/SingleSelectCustomize";
import { useNotificationContext } from "@/contexts/notification";
import { SERVICES } from "@/libs/constants";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

const SampleContractForm = ({
  type = "create",
  data,
  callback,
  handleClose,
}) => {
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contract_key: data?.contract_key || "",
      contract_service: data?.contract_service || {},
      contract_note: data?.contract_note || "",
    },
  });
  const [attachment, setAttachment] = React.useState({
    file: null,
    percen: 0,
    isUploading: false,
    isCompleted: false,
  });

  React.useEffect(() => {
    if (type === "detail" || type === "update") {
      if (data?.attachments && data?.attachments?.length > 0) {
        const contractAttachment = data?.attachments?.[0];
        setAttachment((prev) => ({
          ...prev,
          file: {
            name: contractAttachment?.attachment_file_name,
            size: contractAttachment?.attachment_file_size,
            type: contractAttachment?.attachment_file_type,
            url: contractAttachment?.attachment_file_url,
          },
        }));
      }
    }
  }, [data, type]);

  const fileHandler = async (files) => {
    const extension = files[0].name.split(".")[1]?.toLowerCase();
    if (extension === undefined) {
      return setNotification({
        open: true,
        severity: "error",
        message: "Thể loại tập tin không được hỗ trợ",
      });
    }
    return setAttachment((prev) => ({ ...prev, file: files[0] }));
  };

  const onSubmit = async (values) => {
    setSubmit(true);
    let form = { ...values, contract_type: 2 };

    if (attachment?.file && attachment?.file?.url && type === "update") {
      // attachment is update
      form = {
        ...form,
        contract_document: [attachment?.file],
      };
    } else {
      const prefixPathname = "sample_contracts/documents";
      const token = await FileApi.GetPresignedTokenAttachment({
        filename: {
          name: attachment?.file?.name,
          folder: prefixPathname,
        },
      });
      if (!token.token || token.status !== 200) {
        setSubmit(false);
        return setNotification({
          open: true,
          message: "Lấy thông tin xử lý file thất bại",
          severity: "error",
        });
      }
      setAttachment((prev) => ({
        ...prev,
        percen: 0,
        isUploading: true,
        isCompleted: false,
      }));
      const uploaded = await FileApi.UploadAttachmentToStorage({
        token: token?.token,
        file: attachment?.file,
        handleUploadFile: setAttachment,
      });
      if (!uploaded) {
        setSubmit(false);
        setAttachment({
          file: null,
          percen: 0,
          isUploading: false,
          isCompleted: false,
        });
        return setNotification({
          open: true,
          message: "Upload dữ liệu thất bại.",
          severity: "error",
        });
      }
      setAttachment((prev) => ({
        ...prev,
        isUploading: false,
        isCompleted: true,
      }));
      form = {
        ...form,
        contract_attachment: [
          {
            attachment_file_name: attachment?.file?.name,
            attachment_file_type: attachment?.file?.type,
            attachment_file_size: attachment?.file?.size,
            attachment_file_url: ["pub", prefixPathname, token?.filename].join(
              "/"
            ),
          },
        ], // type is arrays
      };
    }

    if (type === "create") {
      await ContractApi.createContract({
        contract: form,
      }).then((response) => {
        if (!response?.contract) {
          return [
            setSubmit(false),
            setNotification({
              open: true,
              message:
                response?.errors?.[0]?.message || "Tạo hợp đồng mẫu thất bại.",
              severity: "error",
            }),
          ];
        }
        return [
          handleClose(),
          setNotification({
            open: true,
            message: "Tạo hợp đồng mẫu thành công.",
            severity: "success",
          }),
          callback(),
        ];
      });
    } else {
      await ContractApi.updateContractCustomer(data?.contract_id, {
        contract: form,
      }).then((response) => {
        if (!response?.contract) {
          return [
            setSubmit(false),
            setNotification({
              open: true,
              message:
                response?.errors?.[0]?.message ||
                "Cập nhật hợp đồng mẫu thất bại.",
              severity: "error",
            }),
          ];
        }
        return [
          handleClose(),
          setNotification({
            open: true,
            message: "Cập nhật hợp đồng mẫu thành công.",
            severity: "success",
          }),
          callback(),
        ];
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      {(type === "detail" || type === "update") && (
        <React.Fragment>
          <Typography sx={{ mb: 0.5 }}>Mã Số Hợp Đồng</Typography>
          <TextField
            value={data?.contract_number_id}
            disabled
            size="small"
            fullWidth
          />
        </React.Fragment>
      )}
      <Typography sx={{ mb: 0.5, mt: 2 }}>Từ Khoá Hợp Đồng</Typography>
      <Controller
        control={control}
        name="contract_key"
        render={({ field }) => (
          <TextField
            {...field}
            disabled={type === "detail"}
            placeholder="Nhập từ khoá hợp đồng..."
            error={!!errors?.contract_key}
            helperText={errors?.contract_key?.message}
            size="small"
            fullWidth
          />
        )}
      />
      {(type === "detail" || type === "update") && (
        <Box>
          <Typography sx={{ mb: 0.5, mt: 2 }}>Tệp Tin Đính Kèm Cũ *</Typography>
          <TextField
            size="small"
            value={
              data?.link
                ? data?.link
                : data?.attachments && data?.attachments[0]?.attachment_file_url
                ? `${
                    process.env.REACT_APP_DOMAIN_FILE
                  }${data?.attachments[0]?.attachment_file_url.replace(
                    "pub/",
                    ""
                  )}`
                : "#"
            }
            InputProps={{
              sx: { bgcolor: "#9E9E9E1F" },
            }}
            disabled
            fullWidth
          />
        </Box>
      )}
      {["create", "update"].includes(type) && (
        <Typography sx={{ mb: 0.5, mt: 2 }}>Tệp Tin Đính Kèm</Typography>
      )}
      {["create", "update"].includes(type) && (
        <InputFileCustomize
          file={attachment?.file}
          disabled={type === "detail"}
          fileHandler={fileHandler}
        />
      )}
      {attachment?.file && (
        <SingleDocument
          document={attachment?.file}
          handleDelete={
            type === "detail"
              ? undefined
              : () => {
                  return setAttachment((prev) => ({ ...prev, file: null }));
                }
          }
        />
      )}
      <Typography sx={{ mb: 0.5, mt: 2 }}>Dịch Vụ</Typography>
      <Controller
        control={control}
        name="contract_service"
        render={({ field: { onChange, value } }) => (
          <FormControl fullWidth>
            <SingleSelectCustomize
              disabled={type === "detail"}
              options={SERVICES}
              value={value}
              onChange={(e, newValue) => onChange(newValue)}
              input={{ placeholder: "Nhập tên dịch vụ tìm kiếm..." }}
            />
            {!!errors?.contract_service && (
              <ErrorHelperText message={errors?.contract_service?.message} />
            )}
          </FormControl>
        )}
      />
      <Typography sx={{ mb: 0.5, mt: 2 }}>Ghi Chú</Typography>
      <Controller
        control={control}
        name="contract_note"
        render={({ field }) => (
          <FormControl fullWidth>
            <TextField
              multiline
              {...field}
              disabled={type === "detail"}
              placeholder="Nhập ghi chú hợp đồng mẫu..."
            />
          </FormControl>
        )}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          size="small"
          variant={type === "detail" ? "outlined" : "text"}
          sx={{ height: 36 }}
          onClick={handleClose}
          disabled={submit}
        >
          Đóng
        </Button>
        {["create", "update"].includes(type) && (
          <Button
            size="small"
            variant="outlined"
            type="submit"
            sx={{ height: 36, ml: 2 }}
            disabled={submit}
          >
            {type === "create" ? "Thêm Mới" : "Cập Nhật"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SampleContractForm;
