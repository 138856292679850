import ProviderApi from "@/apis/beta/Provider/ProviderApi";
import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import WarehouseItemForm from "../WarehouseItemForm";
import AddChildItemForm from "../WarehouseItemForm/AddChildItemForm";
import TableGroupWarehouseItem from "./TableGroupWarehouseItem";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const FormImport = ({ handleCreate, isFetching }) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useFormContext();

  const params = useParams();
  const [isResetMetadata, setIsResetMetadata] = useState(true);

  const { data: providers, isLoading: providersLoading } = ProviderApi.getAll();

  const {
    data: { warehouse_boxes },
    isLoading: warehouse_boxes_loading,
  } = WarehouseBoxApi.index({
    type: 0,
  });

  const [showForm, setShowForm] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [typeTo, setTypeTo] = useState("select");

  const handleOnChange = (values) => {
    setValue("warehouse_process_metadata", [
      ...watch("warehouse_process_metadata"),
      values,
    ]);
  };

  const handleRemoveItemInProcessMetadata = (id) => {
    setValue(
      "warehouse_process_metadata",
      watch("warehouse_process_metadata").filter((item) => item.id !== id)
    );
  };

  const handleShowDialogEditItemInProcessMetadata = (values) => {
    setShowForm(true);
    setDataEdit(
      watch("warehouse_process_metadata").find(
        (item) => item?.id === values?.id
      ) || null
    );
  };

  const handleEditProcessMetadataCurrent = (values) => {
    setValue(
      "warehouse_process_metadata",
      watch("warehouse_process_metadata")?.map((item) =>
        item?.id === values?.id ? values : item
      )
    );
    setShowForm(false);
    setDataEdit(null);
  };
  if(isResetMetadata) {
    //reset when change type process
    setValue("warehouse_process_metadata", []);
    setIsResetMetadata(false);
  }
  return (
    <Box
    >
      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>
            Nơi đến (Nếu chưa có nơi mua bạn có thể chọn nhập) *
          </Typography>
        </Grid>

        <Grid xs={8}>
          <Box display="flex" gap={2} alignItems="center">
            <Controller
              rules={{ required: "Vui lòng chọn nơi mua" }}
              name="warehouse_process_from"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  {typeTo === "select" ? (
                    <>
                      <Select
                        {...field}
                        size="small"
                        fullWidth
                        disabled={providersLoading}
                      >
                        {providers?.map((item) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>

                      {!!errors?.warehouse_process_from && (
                        <ErrorHelperText
                          message={errors?.warehouse_process_from?.message}
                        />
                      )}
                    </>
                  ) : (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      label="Nhập nơi đến"
                      error={!!errors?.warehouse_process_from}
                      helperText={errors?.warehouse_process_from?.message}
                    />
                  )}
                </FormControl>
              )}
            />

            <Button
              variant="contained"
              size="small"
              onClick={() =>
                setTypeTo((prev) => (prev === "select" ? "input" : "select"))
              }
            >
              {typeTo === "select" ? "Nhập nơi mua" : "Chọn nơi mua"}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Đến kho *</Typography>
        </Grid>

        <Grid xs={8}>
          <Controller
            rules={{ required: "Vui lòng chọn kho" }}
            name="warehouse_process_to"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  size="small"
                  fullWidth
                  disabled={warehouse_boxes_loading}
                >
                  {warehouse_boxes?.map((item) => (
                    <MenuItem
                      key={item?.warehouse_box_name}
                      value={item?.warehouse_box_name}
                    >
                      {item?.warehouse_box_name}
                    </MenuItem>
                  ))}
                </Select>

                {!!errors?.warehouse_process_to && (
                  <ErrorHelperText
                    message={errors?.warehouse_process_to?.message}
                  />
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Lý do *</Typography>
        </Grid>

        <Grid xs={8}>
          <Controller
            rules={{ required: "Vui lòng nhập lý do" }}
            name="warehouse_process_reason"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField multiline {...field} placeholder="Nhập lý do..." />
                {!!errors?.warehouse_process_reason && (
                  <ErrorHelperText
                    message={errors?.warehouse_process_reason?.message}
                  />
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Ghi chú</Typography>
        </Grid>

        <Grid xs={8}>
          <Controller
            name="warehouse_process_note"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField multiline {...field} placeholder="Nhập ghi chú..." />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid xs={4}></Grid>

        <Grid xs={8}>
          <Button
            onClick={() => setShowForm(true)}
            disabled={showForm}
            variant="outlined"
            size="small"
            startIcon={<AddIcon />}
          >
            Thêm hàng vào phiếu
          </Button>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid xs={4}></Grid>

        <Grid xs={8}>
          <Box display="flex" flexDirection="column" gap={2}>
            {!dataEdit && showForm && (
              <WarehouseItemForm
                dataEdit={dataEdit}
                onClose={() => setShowForm(false)}
                onChange={handleOnChange}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      {watch("warehouse_process_metadata")?.length > 0 && (
        <TableGroupWarehouseItem
          warehouse_process_metadata={[
            ...(watch("warehouse_process_metadata") || []),
          ]}
          handleRemoveItemInProcessMetadata={handleRemoveItemInProcessMetadata}
          handleShowDialogEditItemInProcessMetadata={
            handleShowDialogEditItemInProcessMetadata
          }
        />
      )}

      {dataEdit && showForm && (
        <AddChildItemForm
          data={dataEdit}
          onChange={handleEditProcessMetadataCurrent}
          onClose={() => {
            setShowForm(false);
            setDataEdit(null);
          }}
          group={dataEdit?.warehouse_group_id}
        />
      )}

      <Box mt={4}>
        <Button
          type={"button"}
          disabled={showForm || isFetching}
          variant="contained"
          size="small"
          fullWidth
          startIcon={isFetching && <CircularProgress size={14} />}
          onClick={handleSubmit(handleCreate)}
        >
          {params?.id ? "Cập nhật phiếu" : "Thêm phiếu"}
        </Button>
      </Box>
    </Box>
  );
};

export default withSuspense(FormImport, MuiSkeleton["LinearProgress"]);
