import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import { Box, FormControl, TextField, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import { Controller, useFormContext } from 'react-hook-form'

const Terminal = () => {

    const { control } = useFormContext()
    return (
        <Controller
            name="commitment.term"
            control={control}
            render={({ field: { value, onChange } }) => {
                const isUnlimited = parseFloat(value) >= UNLIMITED
                return (
                    <FormControl fullWidth>
                        <TextField
                            label="Gói cố định (? Tháng) *"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                sx: {
                                    paddingRight: 0,
                                },
                                inputComponent: NumericFormatCustom,
                                endAdornment:
                                    <Box sx={{
                                        display: "flex",
                                        height: "40px",
                                        width: {
                                            xs: isUnlimited ? "calc(100%)" : "calc(100% / 3)",
                                            md: isUnlimited ? "calc(100%)" : "calc(100% / 4)",
                                        },
                                        transition: "width 0.75s ease-in-out",
                                        bgcolor: "rgba(0, 0, 0, 0.18)",
                                        borderTopRightRadius: "4px",
                                        borderBottomRightRadius: "4px",
                                        cursor: "pointer",
                                    }}
                                    >
                                        <Typography
                                            color="text.success"
                                            sx={{
                                                m: 'auto 8px auto auto',
                                                fontSize: 13,
                                                textTransform: 'uppercase',
                                                color: value === UNLIMITED ? green[800] : "#777680"
                                            }}>
                                            Tháng
                                        </Typography>
                                    </Box>
                            }}
                            value={isUnlimited ? "UNLIMITED" : value}
                            onChange={onChange}
                            placeholder='0'
                            fullWidth
                            size='small' />
                    </FormControl>
                )
            }} />
    )
}

export default Terminal