import { useNotificationContext } from "@contexts/notification";
import { Box, Button, CircularProgress, FormControl, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { format_pricing_vnd } from "@/libs/constants";
import CellConfig from "@components/DataGrid/CellConfig2";
import MuiCurrency from "@components/shared/CurrencyInputCustomize";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import WarehouseResoureServiceApi from "@/apis/beta/Warehouse/WarehouseResoureServiceApi";

const QuickViewFormResourceService = ({ data = {}, handleClose, refetchDataTable = () => null, refetchData = () => null }) => {
    const { handleSubmit, formState: { errors }, control } = useForm();

    const { setNotification } = useNotificationContext();


    const [submit, setSubmit] = React.useState(false)

    const [config] = React.useState(_.find(data?.warehouse_resource?.warehouse_resource_configs, { warehouse_resource_config_type: "config" }))

    const onSubmit = async (form) => {
        setSubmit(true)
        if (form.warehouse_resource_service_price > data?.warehouse_resource?.warehouse_resource_price){
            setNotification({ open: true, message: "Vui lòng kiểm tra lại tiền phân bổ lớn hơn tiền tổng thiết bị mã hoá.", severity: "error" });
            return [
                setSubmit(false)
            ];
        }
        await WarehouseResoureServiceApi.updateResourceServicePrice(data?.warehouse_resource_id, data?.warehouse_resource_service_id, form.warehouse_resource_service_price).then(response => {
            const data = response.data;
            if (data.status !== 200) {
                setNotification({ open: true, message: data.errors[0].message, severity: "error" });
                return [
                    setSubmit(false)
                ];
            }
            setSubmit(true);
            setNotification({ open: true, message: "Cập nhật thành công.", severity: "success" });
            refetchData();
            refetchDataTable();
            handleClose();
        });
    }

    return(
        <Box sx={{ p: 3, minWidth: 400 }}>
            <Typography sx={{ fontSize: { xs: 18, md: 20 }, fontWeight: 'medium', mb: 2 }}>
                Cập nhật tiền phân bổ tài nguyên
            </Typography>
            <Box sx={{ mb: 1 }}>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                    Loại Tài Nguyên
                </Typography>
                <Typography sx={{ color: "#00000F" }}>{
                    data?.warehouse_resource?.warehouse_resource_type.toUpperCase()}</Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                    Tài Nguyên Chạy Dịch Vụ
                </Typography>
                <Typography sx={{ color: "#00000F" }}>{
                    data?.warehouse_resource_service_name.toUpperCase()}</Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                    Tổng Tiền Thiết Bị Ảo Hoá
                </Typography>
                <Typography sx={{ color: "#00000F" }}>{
                    format_pricing_vnd(data?.warehouse_resource?.warehouse_resource_price)}</Typography>
            </Box>
            <Box>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                    Cấu Hình Thiết Bị Ảo Hoá
                </Typography>
                <Typography sx={{ color: "#00000F" }}>
                    <CellConfig configures={
                        {
                            capacity_cpu: config?.warehouse_resource_config_cpu,
                            capacity_ram: config?.warehouse_resource_config_ram,
                            capacity_storage: config?.warehouse_resource_config_storage
                        }
                    } />
                </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                    Nhập tiền phân bổ *
                </Typography>
                <Controller
                    control={control}
                    defaultValue={data?.warehouse_resource_service_price}
                    rules={{
                        required: ("Vui lòng Nhập tiền phân bổ."),
                    }}
                    name="warehouse_resource_service_price"
                    render={({field: { value, onChange }}) =>
                        <FormControl fullWidth>
                            <MuiCurrency value={value} onChange={onChange} />
                        </FormControl>
                    } />
                {!!errors?.warehouse_resource_service_price && <ErrorHelperText message={errors?.warehouse_resource_service_price?.message} />}

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                    disabled={submit}
                    size="small"
                    variant='outlined' sx={{ height: 36 }}
                    onClick={handleClose}
                >
                    Huỷ Thao Tác
                </Button>
                <Button
                    disabled={submit}
                    size="small"
                    onClick={handleSubmit(onSubmit)}
                    startIcon={submit &&
                        <CircularProgress sx={{ m: 'auto 0', color: '#777680' }} size={14} />}
                    variant='contained' sx={{ ml: 2, height: 36 }}
                >
                    Cập Nhật
                </Button>
            </Box>
        </Box>
    )
}
export default QuickViewFormResourceService;