import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";
import queryString from "query-string";

class WarehouseResoureServiceApi {
  reports = () =>
    requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_resource_services/report`
    );

  create = (id, params) =>
    requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_resources/${id}/warehouse_resource_services`,
      "POST",
      { warehouse_resource_service: params }
    );

  getAll = () =>
    useQueryWithCustomized(
      ["warehouse.resources.services.all"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_resource_services/list?group=true`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_resource_services?.map((o, index) => ({
              value: o.warehouse_resource_service_id || index,
              label: o.warehouse_resource_service_name,
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );

  index = ({
    resource_id = null,
    query = null,
    searchQuery = null,
    page = 1,
    pageSize = 20,
  }) => {
    const paramsQueryString = queryString.stringify(
      {
        page: page,
        page_size: pageSize,
      },
      {
        skipNull: true,
        arrayFormat: "bracket",
        skipEmptyString: true,
      }
    );
    return useQueryWithCustomized(
      [
        "warehouse.warehouse_resources.services.list",
        resource_id,
        query,
        searchQuery,
        page,
        pageSize,
        paramsQueryString,
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_resources/${resource_id}/warehouse_resource_services?${paramsQueryString} ${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${searchQuery}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_resource_services:
                data?.data?.warehouse_resource_services.map((o) => ({
                  id: o.warehouse_resource_service_id,
                  ...o,
                })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            console.log(error.response);
            return {
              warehouse_resources: [],
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  updateResourceServicePrice = (id, service_id, price) =>
    requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_resources/${id}/warehouse_resource_services/${service_id}/update_warehouse_resource_service_price`,
      "PUT",
      { warehouse_resource_service_price: price }
    );

  update = (id, service_id, params) =>
    requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_resources/${id}/warehouse_resource_services/${service_id}`,
      "PUT",
      { warehouse_resource_service: params }
    );

  cancelled = (id) =>
    requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_resource_services/${id}/cancelled`,
      "DELETE",
      {}
    );

  show = (id, service_id, callback) =>
    useQueryWithCustomized(
      ["warehouse_resource.service.detail", id, service_id],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_resources/${id}/warehouse_resource_services/${service_id}`
        )
          .then(({ data }) => {
            if (data?.errors) return callback(data.errors);

            return {
              warehouse_resource_service:
                data?.data?.warehouse_resource_service,
            };
          })
          .catch((error) => {
            console.log(error);
            return {
              warehouse_resource_service: null,
            };
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        notifyOnChangePropsExclusions: ["isStale"],
        suspense: true,
      }
    );
  GetResourceServices = ({ searchQuery }) =>
    useQueryWithCustomized(
      ["warehouse.resources.services.list", searchQuery],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_resource_services/resource_services?${searchQuery ? `&queries=${searchQuery}` : ''}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_resource_services?.map((o, index) => ({
              id: index,
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );

  // eslint-disable-next-line no-dupe-class-members
  // updateResourceServicePrice = async (id, service_id, price) => {
  //   return await requestWithToken(`${API_WAREHOUSE_V1_URL}/warehouse_resources/${id}/warehouse_resource_services/${service_id}/update_warehouse_resource_service_price`, 'PUT', { warehouse_resource_service_price: price })
  // };

}
export default WarehouseResoureServiceApi = new WarehouseResoureServiceApi();
