import MuiScrollTabs from "@/components/shared/MuiScrollTabs";
import StorageIcon from "@mui/icons-material/Storage";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import AlbumIcon from "@mui/icons-material/Album";
import MemoryIcon from "@mui/icons-material/Memory";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import TuneIcon from "@mui/icons-material/Tune";
import LaptopIcon from "@mui/icons-material/Laptop";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import React from "react";

export const initialTabsTypeWarehouse = [
  {
    value: "server",
    label: "Server",
    href: `server/${JSON.stringify([1])}`,
    icon: StorageIcon,
    data: "[1]",
  },
  {
    value: "processor",
    label: "Processor",
    href: `processor/${JSON.stringify([7])}`,
    icon: DeveloperBoardIcon,
    data: "[7]",
  },
  {
    value: "disk",
    label: "Disk",
    href: `disk/${JSON.stringify([3, 16, 6, 5, 4, 22])}`,
    icon: AlbumIcon,
    data: "[3, 16, 6, 5, 4, 22]",
  },
  {
    value: "memory",
    label: "Memory",
    href: `memory/${JSON.stringify([8])}`,
    icon: MemoryIcon,
    data: "[8]",
  },
  {
    value: "networking",
    label: "Networking",
    href: `networking/${JSON.stringify([10, 14])}`,
    icon: NetworkCheckIcon,
    data: "[10, 14]",
  },
  {
    value: "switch",
    label: "Switch",
    href: `switch/${JSON.stringify([2])}`,
    icon: TuneIcon,
    data: "[2]",
  },
  {
    value: "laptop",
    label: "Laptop",
    href: `laptops/${JSON.stringify([20])}`,
    icon: LaptopIcon,
    data: "[20]",
  },
  {
    value: "other",
    label: "Other",
    href: `other/${JSON.stringify([
      27, 26, 25, 24, 23, 21, 19, 18, 17, 15, 12, 11, 9,
    ])}`,
    icon: DevicesOtherIcon,
    data: "[27, 26, 25, 24, 23, 21, 19, 18, 17, 15, 12, 11, 9]",
  },
];

const Tabs = () => {
  return (
    <>
      <MuiScrollTabs
        prefix={`warehouse-items`}
        initialTabs={initialTabsTypeWarehouse.filter((item) => !item?.hidden)}
      />
    </>
  );
};

export default Tabs;
