import RackBandwidthApi from "@/apis/beta/Warehouse/RackBandwidthApi";
import RackBandwidthItemApi from "@/apis/beta/Warehouse/RackBandwidthItemApi";
import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseRackApi from "@/apis/beta/Warehouse/WarehouseRackApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import DataGridCustomize from "@components/DataGrid";
import DatePicker from "@components/DatePicker";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

const month_current = {
  start: moment().startOf("month")?.format("YYYY-MM-DD"),
  end: moment().endOf("month")?.format("YYYY-MM-DD"),
};
const Form = ({
  type = "create",
  rack_bw_id = null,
  rack_bandwidth = {},
  refetchData,
}) => {
  const {
    notify,
  } = useMutationWithNotification();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useForm({
    defaultValues: {
      rack_bandwidth_items:
        type !== "create"
          ? rack_bandwidth?.rack_bandwidth_items
            ?.sort((a, b) => b?.warehouse_box?.warehouse_box_name?.replace("IDC ", "")?.split(" ")?.[0]?.localeCompare(a?.warehouse_box?.warehouse_box_name?.replace("IDC ", "")?.split(" ")?.[0]))
            ?.map((o, index) => ({
              ...o,
              id: index,
            }))
          : [],
    },
  });

  const { fields, remove, replace } = useFieldArray({
    control,
    name: "rack_bandwidth_items",
  });
  const [newRow, setNewRow] = React.useState([]);




  const [submit, setSubmit] = React.useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/warehouse/bandwidths/racks/");
  };

  const onSubmit = async (data
    // , SubmitType = type
  ) => {
    setSubmit(true);
    if (
      type === "create"
      // SubmitType === "create"
    ) {
      const response = await RackBandwidthApi.create(data)
      if (response?.errors) {
        setSubmit(false);
        return notify("error", response?.errors[0]?.message);
      }
      notify("success", "Đã tạo đối soát bandwidth thành công.");
      navigate("/warehouse/bandwidths/racks");
    } else {
      const filteredNewItem = data?.rack_bandwidth_items?.filter((item) => !item?.rack_bandwidth_item_id)
      const filteredOldItem = data?.rack_bandwidth_items?.filter((item) => item?.rack_bandwidth_item_id)
      const response = await RackBandwidthItemApi.update({ rack_bandwidth_id: rack_bw_id, rack_bandwidth_items: filteredOldItem })
      let responseFromCreate = null
      if (filteredNewItem.length > 0) {
        responseFromCreate = await RackBandwidthItemApi.create({ rack_bandwidth_id: rack_bw_id, rack_bandwidth_items: filteredNewItem })
      }
      if (response?.errors || responseFromCreate?.errors) {
        setSubmit(false);
        return notify("error", response?.errors[0]?.message || responseFromCreate?.errors[0]?.message);
      }
      notify("success", "Cập nhật thành công đối soát bandwidth.");
      // refetchRackGrow();
      navigate("/warehouse/bandwidths/racks");
    }
  };
  const { data: warehouse_racks } = WarehouseRackApi.listRackBW();
  const { data: warehouse_boxes } = WarehouseBoxApi.getAll();

  const initialColumnsInputValue = [
    {
      field: 'warehouse_rack_name',
      headerName: 'Tên rack',
      renderCell: ({ value, row }) => {
        if (typeof (value) === "object") {
          return value || "-";
        }
        const rack = warehouse_racks?.find((o) => o?.warehouse_rack_id === row?.warehouse_rack_id)
        return (
          <Link component={NavLink} to={`../warehouse/racks/${row?.warehouse_rack_id}`} >
            <Typography className='react-link-table'>
              {rack?.warehouse_rack_name || value || "-"}
            </Typography>
          </Link>
        )
      },
      sortable: false,
      width: 150,
    },
    {
      field: 'warehouse_rack_height',
      headerName: 'Kích thước',
      hide: type !== "create",
      sortable: false,
      width: 150,
      renderCell: ({ row }) => {
        const rack = warehouse_racks?.find((o) => o?.warehouse_rack_id === row?.warehouse_rack_id)
        return (
          rack?.warehouse_rack_height
        )
      }
    },
    {
      field: 'warehouse_box.warehouse_box_name',
      headerName: 'Kho/Datacenter/ISP',
      sortable: false,
      width: 300,
      renderCell: ({ value, row }) => {
        if (typeof (value) === "object") {
          return value || "-";
        }
        const rack = warehouse_racks?.find((o) => Number(o?.warehouse_rack_id) === Number(row?.warehouse_rack_id))
        return (
          <Link component={NavLink} to={`../warehouse/boxes/${row?.warehouse_box?.warehouse_box_id}`} >
            <Typography className='react-link-table'>
              {rack?.warehouse_box?.warehouse_box_name}
            </Typography>
          </Link>
        )
      },
    },
    {
      field: "warehouse_racks_started",
      headerName: "Ngày bắt đầu tủ",
      hide: type !== "create",
      width: 200
    },
    {
      field: "warehouse_rack_date_end",
      headerName: "Ngày kết thúc tủ",
      hide: type !== "create",
      width: 200
    },
    {
      field: 'bandwidth_limit',
      headerName: 'Uplink giới hạn',
      sortable: false,
      width: 200,
      renderCell: ({ value, row }) => {
        return (
          value
        )
      }
    },
    {
      field: 'bandwidth_commit',
      headerName: 'Uplink thống nhất với nhà mạng',
      sortable: false,
      width: 250,
      renderCell: ({ value }) => {
        return (
          value
        )
      }
    },
    {
      field: 'bandwidth_reality',
      headerName: 'Uplink thực tế dùng',
      sortable: false,
      width: 200,
      renderCell: ({ value }) => {
        return (
          value
        )
      }
    }
  ]
  // const handleAddToAnalytic = (item) => {
  //   append({
  //     ...item,
  //   })
  // }
  const handleDelete = (index) => {
    if (fields[index]?.warehouse_rack_id?.toString().includes("ps")) {
      setNewRow(newRow.filter((o, i) => o.warehouse_rack_id !== fields[index]?.warehouse_rack_id))
    }
    remove(index)
  }
  // const handleAddNewRow = () => {
  //   const row = {
  //     warehouse_rack_id: `ps${newRow?.length + rack_grows?.length + 1}`,
  //     warehouse_rack_name: `Phát sinh ${newRow?.length + rack_grows?.length + 1}`,
  //   }
  //   setNewRow([...newRow, row])
  //   append({
  //     ...row
  //   })
  // }
  const reFormatData = () => {
    // const itemAdded = fields?.map((rack) => (rack.warehouse_rack_id)?.toString())
    return _.uniqBy(warehouse_racks, "warehouse_rack_id")
      ?.map((o, index) => ({ ...o, id: index }))
      // ?.concat(rack_grows?.map((o) => ({ ...o, id: o?.warehouse_rack_id, warehouse_box: {warehouse_box_name: o?.warehouse_box_name} })))
      // ?.filter((o) => !itemAdded.includes((o?.warehouse_rack_id)?.toString()))
      ?.sort((a, b) => b?.warehouse_box?.warehouse_box_name?.replace("IDC ", "")?.split(" ")?.[0]?.localeCompare(a?.warehouse_box?.warehouse_box_name?.replace("IDC ", "")?.split(" ")?.[0]));
  }
  useEffect(() => {
    // if(fields?.length === 0) {
    // fields = reFormatData()
    // let data = reFormatData()?.filter((item) => item?.warehouse_rack_active) || []
    // console.log(new Date(watch("rack_bandwidth_date_start")?.toString()))
    // console.log(new Date())
    if (type === "create") {
      // eslint-disable-next-line array-callback-return
      let data = reFormatData()?.filter((item) => {
        // console.log(new Date(item?.warehouse_rack_date_end))
        if (new Date(item?.warehouse_rack_date_end?.toString()) >= new Date(watch("rack_bandwidth_date_start")?.toString())
          && new Date(item?.warehouse_racks_started?.toString()) <= new Date(watch("rack_bandwidth_date_end")?.toString())
        ) {
          return item
        }
      }
      ) || []
      data = data?.map((item) => {
        return {
          ...item,
          bandwidth_commit: item?.warehouse_rack_uplink_commit,
          bandwidth_limit: item?.warehouse_rack_uplink_limit
        }
      })
      replace(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("rack_bandwidth_date_start"), watch("rack_bandwidth_date_end")])
  const reFormatFields = () => {
    const warehouse_box_names = _.uniq(warehouse_boxes?.map((o) => o?.warehouse_box_name?.replace("IDC ", "")?.split(" ")?.[0]))
    return fields?.map((rack, index) => {
      if (rack?.warehouse_rack_id?.toString()?.toLowerCase()?.includes("ps")) {
        if (rack?.existed || rack?.rack_bandwidth_id) {
          rack["warehouse_rack_name"] = <Typography color={"error"}>
            {rack?.warehouse_rack_name}
          </Typography>
          rack["warehouse_box.warehouse_box_name"] = <Typography color={"error"}>
            {rack?.warehouse_box?.warehouse_box_name}
          </Typography>
        } else {
          rack["warehouse_rack_name"] = (<Controller
            name={`rack_bandwidth_items[${index}][warehouse_rack_name]`}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  size="small"
                  value={rack?.warehouse_rack_name || ""}
                  type="text"
                  onKeyDown={(e) => {
                    if (e.key === ' ') {
                      e.stopPropagation();
                    }
                  }}
                  {...field}
                  ref={null}
                />
              </FormControl>
            )} />)
          rack["warehouse_box.warehouse_box_name"] =
            (<Controller
              name={`rack_bandwidth_items[${index}][warehouse_box][warehouse_box_name]`}
              control={control}
              render={({ field: { onChange, value, isError } }) => (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">ISP</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="ISP"
                    size="small"
                    value={value || ""}
                    onChange={onChange}
                    ref={null}
                    error={!!errors?.rack_bandwidth_items?.[index]?.warehouse_box?.warehouse_box_name}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {warehouse_box_names?.map((option, index) => (
                      <MenuItem key={index} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )} />)
        }
      }
      return ({
        ...rack,
        bandwidth_limit:
          <Controller
            name={`rack_bandwidth_items[${index}][bandwidth_limit]`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <TextField
                  type="number"
                  disabled
                  value={
                    value || ""
                    // value || undefined
                  }
                  onChange={onChange}
                  size="small"
                />
              </FormControl>
            )}
          />,
        bandwidth_commit:
          <Controller
            name={`rack_bandwidth_items[${index}][bandwidth_commit]`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <TextField
                  disabled
                  type="number"
                  size="small"
                  value={
                    value || ""
                  }
                  onChange={onChange}
                />
              </FormControl>
            )}
          />,
        bandwidth_reality:
          <Controller
            name={`rack_bandwidth_items[${index}][bandwidth_reality]`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <TextField
                  type="number"
                  size="small"
                  value={
                    value || ""
                  }
                  onChange={onChange}
                />
              </FormControl>
            )}
          />,
        actions:
          <Button
            variant="contained"
            onClick={() => handleDelete(index)}
          >
            <DeleteIcon />
          </Button>
      })
    })
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ p: 2 }} component="form" onSubmit={handleSubmit(onSubmit)}>
            <Typography
              sx={{ fontSize: { xs: 20, md: 24 }, fontWeight: "medium", mb: 2 }}
            >
              {type === "create" ? "Thêm" : "Chi tiết"} đối soát bandwidth rack
            </Typography>
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ mb: 0.5, mt: 1 }}>
                    {" "}
                    Thời gian bắt đầu đối soát: *
                  </Typography>
                  <Controller
                    name="rack_bandwidth_date_start"
                    control={control}
                    // ref={null}
                    defaultValue={
                      type === "create"
                        ? month_current.start
                        : type !== "create"
                          ? rack_bandwidth?.rack_bandwidth_date_start
                          : new Date()
                    }
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl
                          fullWidth
                          sx={{
                            ".MuiIconButton-edgeEnd": {
                              m: "unset",
                            },
                          }}
                        >
                          <DatePicker
                            value={value}
                            disabled={type !== "create"}
                            onChange={onChange}
                            ref={null}
                          />
                          {!!errors?.rack_bandwidth?.rack_bandwidth_date_start && (
                            <ErrorHelperText
                              message={
                                errors?.rack_bandwidth?.rack_bandwidth_date_start?.message
                              }
                            />
                          )}
                        </FormControl>
                      );
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ mb: 0.5, mt: 1 }}>
                    {" "}
                    Thời gian kết thúc đối soát: *
                  </Typography>
                  <Controller
                    name="rack_bandwidth_date_end"
                    control={control}
                    // ref={null}
                    defaultValue={
                      type === "create"
                        ? month_current.end
                        : type !== "create"
                          ? rack_bandwidth?.rack_bandwidth_date_end
                          : new Date()
                    }
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl
                          fullWidth
                          sx={{
                            ".MuiIconButton-edgeEnd": {
                              m: "unset",
                            },
                          }}
                        >
                          <DatePicker
                            disabled={type !== "create"}
                            value={value}
                            onChange={onChange}
                            ref={null}
                          />
                          {!!errors?.rack_bandwidth?.rack_bandwidth_date_end && (
                            <ErrorHelperText
                              message={
                                errors?.rack_bandwidth?.rack_bandwidth_date_end?.message
                              }
                            />
                          )}
                        </FormControl>
                      );
                    }}
                  />
                </Box>
              </Grid>
              {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Paper>
                    <DataGridCustomize
                        rows={reFormatData()
                          || []}
                        columns={initialColumns}                        
                        rowCount={warehouse_racks?.warehouse_racks?.length|| 0}
                        componentsProps={{
                        }}
                        sx={{ mt: 2 }}
                    />

                </Paper>
              </Grid> */}

              {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button variant="contained" onClick={() => handleAddNewRow()} size="small">
                    Thêm phát sinh
              </Button>

              </Grid> */}
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Paper>
                  <DataGridCustomize
                    rows={
                      reFormatFields()
                      || []}
                    columns={initialColumnsInputValue}
                    rowCount={warehouse_racks?.warehouse_racks?.length || 0}
                    initialState={{ pinnedColumns: { left: ["warehouse_rack_name"], right: ['actions', 'action'] } }}
                    sx={{ mt: 2 }}
                  />

                </Paper>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}>
              <Button
                size="small"
                variant="outlined"
                sx={{ height: 36 }}
                onClick={handleClose}
              >
                Huỷ Thao Tác
              </Button>
              <Button
                disabled={submit}
                size="small"
                type="submit"
                startIcon={
                  submit && (
                    <CircularProgress
                      sx={{ m: "auto 0", color: "#777680" }}
                      size={14}
                    />
                  )
                }
                variant="contained"
                sx={{ ml: 2, height: 36 }}
              >
                {type === "create" ? "Thêm" : "Cập Nhật"} đối soát bandwidth rack
              </Button>
              {/* {type !== "create" && <Button
                disabled={submit}
                size="small"
                type="submit"
                startIcon={
                  submit && (
                    <CircularProgress
                      sx={{ m: "auto 0", color: "#777680" }}
                      size={14}
                    />
                  )
                }
                onClick={
                  // handleSubmit(onCreate)
                  handleSubmit(async (data) => {
                    await onSubmit(data,"create")
                  })
                }
                variant="contained"
                color="warning"
                sx={{ ml: 2, height: 36 }}
              >
                {"Thêm"} đối soát bandwidth rack
              </Button>
              } */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Form;
