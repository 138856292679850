import React, { useState } from "react";
import { Box } from "@mui/material";
import TableModelV2 from "./TableModelV2";

const TypeTemplate = ({ type = {}}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <Box
    >
      {/* <Button variant="contained" size="small" >Thêm Model</Button> */}
      <TableModelV2 warehouse_type={type} type_id={type?.warehouse_type_id} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>
    </Box>
  );
};

export default React.memo(TypeTemplate);
