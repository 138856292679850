import withPermission from "@/hocs/withPermission";
import { BaseComponents } from "@components/Components";
import { Grid, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const CustomerCSFollowers = () => {
  const { control } = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
        <Typography sx={{ fontSize: 14, color: "#777680" }}>
          CS phụ trách
        </Typography>
      </Grid>
      <Grid item xl={9} lg={9} md={8} sm={6} xs={12}>
        <Controller
          control={control}
          name="customer_cs_followers"
          render={({ field: { value, onChange } }) => {
            return (
              <BaseComponents.SaleTracking
                value={value}
                onChange={onChange}
                name="customer_cs_followers"
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
export default withPermission(CustomerCSFollowers, {
  feature: "customer",
  action: "assignee-cs",
  type: "button",
});
// export default withSuspense(CustomerCSFollowers, MuiSkeleton["FormControlTextField"])
