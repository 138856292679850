import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
// import ProcessFormInput from "../ProcessCreate/share/ProcessFormInput";
import Information from "./Information";
import Storekeeper from "./Storekeeper";
import TableWarehouseItemTransfer from "./TableWarehouseItemTransfer";
import Technical from "./Technical";
import WarehouseProcessApi from "@/apis/beta/Warehouse/WarehouseProcessApi";
import HeaderTitle from "@components/Title/HeaderTitle";
import PaperLoading from "../../Shared/Loading/PaperLoading";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import TableGroupWarehouseItem from "../ProcessesNew/Form/FormImport/TableGroupWarehouseItem";
import ProcessesLog from "../Processes/ProcessesLog";
import WarehouseProcessLogApi from "@/apis/beta/Warehouse/WarehouseProcessLogApi";
import TableWarehouseItemExport from "./TableWarehouseItemExport";

const ProcessDetail = () => {
  const params = useParams();

  const {
    data: { warehouse_process },
    refetch: callbackProcess,
    isLoading,
  } = WarehouseProcessApi.Show(params?.id);

  const { data: warehouse_process_logs, refetch: refetchProcessLogs } = WarehouseProcessLogApi.Index(params?.id);

  const wpItemsJson = warehouse_process?.warehouse_process_metadata ?? [];

  if (isLoading) return <PaperLoading />;
  return (
    <Box>
      <HeaderTitle>
        Thông tin chi tiết phiếu #{warehouse_process?.warehouse_process_code}
      </HeaderTitle>

      <Information warehouseProcess={warehouse_process} />

      {warehouse_process?.warehouse_process_type === 0 && (
        <TableGroupWarehouseItem
          warehouse_process_metadata={wpItemsJson}
          type="detail"
        />
      )}
      {warehouse_process?.warehouse_process_type === 3 && (
        <TableWarehouseItemExport
          refetchProcessLogs={refetchProcessLogs}
          wpItemsJson={wpItemsJson}
          callbackProcess={callbackProcess}
          warehouse_process={warehouse_process}
        />
      )}

      {(warehouse_process?.warehouse_process_type !== 0 && warehouse_process?.warehouse_process_type !== 3) && (
        <TableWarehouseItemTransfer
          wpItemsJson={wpItemsJson}
          callbackProcess={callbackProcess}
          warehouse_process={warehouse_process}
        />
      )}

      {warehouse_process.warehouse_process_status === "pending" &&
        ["pending"].includes(
          warehouse_process.warehouse_process_storekeeper
        ) && (
          <Storekeeper
            warehouseProcess={warehouse_process}
            callbackProcess={callbackProcess}
            refetchProcessLogs={refetchProcessLogs}
          />
        )}

      {warehouse_process.warehouse_process_status === "pending" &&
        ["pending"].includes(warehouse_process.warehouse_process_lead) && (
          <Technical
            warehouseProcess={warehouse_process}
            callbackProcess={callbackProcess}
            refetchProcessLogs={refetchProcessLogs}
          />
        )}
      <ProcessesLog data={warehouse_process_logs} />
    </Box>
  );
};

export default withSuspense(
  withPermission(ProcessDetail, {
    feature: "warehouse",
    action: "processes-detail",
  }),
  MuiSkeleton["DataGrid"]
);
