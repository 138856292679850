import NumericFormatCustom from '@components/NumericFormatCustom'
import { FormControl, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const Price = () => {

    const { control, setValue, watch } = useFormContext()
    return (
        <Controller
            name="commitment.pricing_apply"
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <FormControl fullWidth>
                        <TextField
                            value={value}
                            label="Đơn giá bán *"
                            onChange={(event) => {
                                onChange(event?.target?.value)
                                setValue("commitment.pricing_amount", parseFloat(event?.target?.value) * parseFloat(watch(`commitment.value`)))
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: NumericFormatCustom,
                            }}
                            size="small"
                        />
                    </FormControl>
                );
            }}
        />
    )
}

export default Price