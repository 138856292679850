import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  INITIAL_OPERATORS,
  THRESHOLD_BIND,
  THRESHOLD_SCOPE,
  THRESHOLD_STATUS,
} from "@/libs/constants";
import { useMutation } from "react-query";
import ThresholdApi from "@/apis/beta/Threshold/ThresholdApi";
import { useParams } from "react-router-dom";
import { useNotificationContext } from "@contexts/notification";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditAlarm({ open, handleClose, values, refetch, handleResetState }) {
  const { id } = useParams();

  const {
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      alarm_bind: values?.alarm_bind || THRESHOLD_BIND?.[0]?.value,
      alarm_conditions: values?.alarm_conditions || [],
      alarm_description: values?.alarm_description || "",
      alarm_name: values?.alarm_name || "",
      alarm_scope: values?.alarm_scope || THRESHOLD_SCOPE?.[0]?.value,
      alarm_status: values?.alarm_status || THRESHOLD_STATUS?.[0]?.value,
      metadata:
        (typeof values?.metadata === "object"
          ? JSON.stringify(values?.metadata)
          : values?.metadata) || "",
    },
  });

  const { setNotification } = useNotificationContext();

  const { mutateAsync, isLoading } = useMutation(ThresholdApi.EditAlarm, {
    onError: () => {
      setNotification({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  const {
    mutateAsync: AddAlarmInThresholdMutation,
    isLoading: AddAlarmInThresholdLoading,
  } = useMutation(ThresholdApi.AddAlarmInThreshold, {
    onError: () => {
      setNotification({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  const onSubmit = async (formValues) => {
    const newValues = {
      ...formValues,
      metadata: JSON.parse(formValues?.metadata),
    };

    let response;

    if (values) {
      response = await mutateAsync({
        ...newValues,
        alarm_id: values?.alarm_id,
      });
    } else {
      response = await AddAlarmInThresholdMutation({
        threshold_id: id,
        values: newValues,
      });
    }

    if (response?.errors) {
      return setNotification({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }

    setNotification({
      open: true,
      message: "Success",
      severity: "success",
    });

    handleResetState();
    handleClose();
    refetch();
  };

  const isFetching = isLoading || AddAlarmInThresholdLoading;
  const action = values ? "Edit" : "Add";

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{action} Alarm</DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <Controller
                name="alarm_name"
                control={control}
                rules={{
                  required: { message: "alarm_name is required", value: true },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Alarm Name"
                    error={!!errors.alarm_name}
                    helperText={errors.alarm_name?.message}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%" }}
                  />
                )}
              />

              <Controller
                name="alarm_description"
                control={control}
                rules={{
                  required: {
                    message: "alarm_description is required",
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Alarm Description"
                    error={!!errors.alarm_description}
                    helperText={errors.alarm_description?.message}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%", mt: 2 }}
                  />
                )}
              />

              <Controller
                name="metadata"
                control={control}
                rules={{
                  required: { message: "metadata is required", value: true },
                }}
                render={({ field: { onChange, ...field } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        multiline
                        {...field}
                        sx={{ mt: 2 }}
                        placeholder="Metadata"
                        minRows={3}
                        onChange={(e) => {
                          try {
                            JSON.parse(e?.target?.value?.trim());
                            clearErrors("metadata");
                          } catch (error) {
                            setError("metadata", {
                              message: "metadata is in valid json format",
                            });
                          }

                          onChange(e?.target?.value);
                        }}
                      />
                      {errors?.metadata?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.metadata?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
              />

              <Box mt={2} display="flex" flexDirection="column" gap={2}>
                {watch("alarm_conditions")?.map((item, index) => (
                  <Box display="flex" gap={2} flexWrap="wrap">
                    <Controller
                      control={control}
                      name={`alarm_conditions[${index}].key`}
                      rules={{ required: "key là bắt buộc" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label="key"
                          helperText={
                            errors?.alarm_conditions?.[index]?.key?.message
                          }
                          sx={{ flex: 1 }}
                          fullWidth
                          error={!!errors?.alarm_conditions?.[index]?.key}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`alarm_conditions[${index}].condition`}
                      rules={{ required: "condition là bắt buộc" }}
                      render={({ field }) => (
                        <FormControl sx={{ flex: 1 }} fullWidth>
                          <Select {...field} fullWidth size="small">
                            {INITIAL_OPERATORS?.map((item) => (
                              <MenuItem
                                key={item?.operator}
                                value={item?.operator}
                              >
                                {item?.operator}
                              </MenuItem>
                            ))}
                          </Select>

                          {errors?.alarm_conditions?.[index]?.condition
                            ?.message && (
                            <ErrorHelperText
                              message={
                                errors?.alarm_conditions?.[index]?.condition
                                  ?.message
                              }
                            />
                          )}
                        </FormControl>
                      )}
                    />

                    <Controller
                      control={control}
                      name={`alarm_conditions[${index}].value`}
                      rules={{ required: "value là bắt buộc" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ flex: 1 }}
                          fullWidth
                          size="small"
                          label="value"
                          helperText={
                            errors?.alarm_conditions?.[index]?.value?.message
                          }
                          error={!!errors?.alarm_conditions?.[index]?.value}
                        />
                      )}
                    />

                    <IconButton
                      onClick={() =>
                        setValue(
                          "alarm_conditions",
                          watch("alarm_conditions").filter(
                            (_, i) => i !== index
                          )
                        )
                      }
                    >
                      <DeleteOutlineIcon color="error" />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  onClick={() =>
                    setValue("alarm_conditions", [
                      ...watch("alarm_conditions"),
                      {
                        condition: INITIAL_OPERATORS?.[0]?.operator,
                        key: "",
                        value: "",
                      },
                    ])
                  }
                  sx={{ mt: 2 }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="button"
                >
                  Thêm condition
                </Button>
              </Box>

              <Box mt={2}>
                <Typography fontSize={14}>Alarm Status</Typography>
                <Controller
                  name="alarm_status"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        error={!!errors.alarm_status}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                        fullWidth
                      >
                        {THRESHOLD_STATUS?.map((item) => (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.alarm_status?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.alarm_status?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Typography fontSize={14}>Alarm Scope</Typography>
                <Controller
                  name="alarm_scope"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        error={!!errors.alarm_scope}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                        fullWidth
                      >
                        {THRESHOLD_SCOPE?.map((item) => (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.alarm_scope?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.alarm_scope?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Typography fontSize={14}>Alarm Bind</Typography>
                <Controller
                  name="alarm_bind"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      sx={{ mt: 2 }}
                      error={!!errors?.alarm_bind}
                      helperText={errors?.alarm_bind?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="outlined" onClick={handleClose}>
              Huỷ
            </Button>
            <Button
              size="small"
              disabled={isFetching}
              variant="contained"
              type="submit"
            >
              {isFetching ? <CircularProgress size={25} /> : action}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

export default EditAlarm;
