import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import MultipleSelectCustomize from "@components/shared/MultipleSelectCustomize";
import FaceIcon from "@mui/icons-material/Face";
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  Paper,
  Typography
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const People = ({ ticket, ticket_request_id = -1 }) => {
  const { data: employees, isFetching } = EmployeeApi.All();

  const { control } = useFormContext();

  return (
    <Paper sx={{ p: "8px 16px 16px" }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography sx={{ fontWeight: 'medium', pb: 0.5 }}>
          Thông tin nhân viên (Assignee)
        </Typography>
        <IconButton>
          <FaceIcon color="error" />
        </IconButton>
      </Box>
      <Collapse in={!!ticket && ticket_request_id !== 2}>
        <Controller
          control={control}
          name="ticket_sale"
          render={({ field: { value, onChange } }) => {
            if (!value) {
              onChange([])
            }
            return (
              <FormControl fullWidth sx={{ mb: 1.75 }}>
                <MultipleSelectCustomize
                  loading={isFetching}
                  limitTags={3}
                  value={value}
                  options={employees}
                  formatCollection={{
                    value: "employee_id",
                    label: "employee_name",
                  }}
                  compareAttributes={["employee_name"]}
                  onChange={(_, newValue) => onChange(newValue || [])}
                  sx={{
                    "&.MuiAutocomplete-option": {
                      textTransform: "uppercase",
                    }
                  }}
                  input={{
                    label: 'Sale follower',
                    placeholder: "Chọn sale follower..."
                  }}
                  mode="server"
                  disabled
                />
              </FormControl>
            );
          }}
        />
        <Controller
          control={control}
          name="ticket_cs"
          render={({ field: { value, onChange } }) => {
            if (!value) {
              onChange([])
            }
            return (
              <FormControl fullWidth sx={{ mb: 1.75 }}>
                <MultipleSelectCustomize
                  loading={isFetching}
                  limitTags={3}
                  value={value}
                  options={employees}
                  formatCollection={{
                    value: "employee_id",
                    label: "employee_name",
                  }}
                  compareAttributes={["employee_name"]}
                  onChange={(_, newValue) => onChange(newValue || [])}
                  sx={{
                    "&.MuiAutocomplete-option": {
                      textTransform: "uppercase",
                    },
                  }}
                  input={{
                    label: 'CS follower',
                    placeholder: "Chọn cs follower..."
                  }}
                  mode="server"
                  disabled
                />
              </FormControl>
            );
          }}
        />
      </Collapse>
      <Controller
        control={control}
        name="ticket_technical"
        render={({ field: { value, onChange } }) => {
          if (!value) {
            onChange([])
          }
          return (
            <FormControl fullWidth>
              <MultipleSelectCustomize
                input={{
                  label: ticket_request_id === 2 ?
                    "Người xử lý" : "Technical *"
                  ,
                  placeholder: "Chọn người xử lý..."
                }}
                loading={isFetching}
                limitTags={3}
                value={value}
                options={employees}
                formatCollection={{
                  value: "employee_id",
                  label: "employee_name",
                }}
                compareAttributes={["employee_name"]}
                onChange={(event, newValue) => onChange(newValue || [])}
                sx={{
                  "&.MuiAutocomplete-option": {
                    textTransform: "uppercase",
                  },
                }}
                mode="server"
              />
            </FormControl>
          );
        }}
      />
    </Paper>
  );
};

export default withSuspense(People, MuiSkeleton["LinearProgress"]);
