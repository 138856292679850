import React from "react";
import PlaybookActionDetail from "../PlaybookActionDetail";
import { Grid, Paper } from "@mui/material";
const PlaybookActionList = ({actions, playbook, refetch}) => {

    return (
        <>
            <Paper sx={{ mt: 2, p: 2, border: '1px solid #f0f0f0', boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)', backgroundColor: '#f0f0f0' }}>
                <Grid container spacing={1}>
                    {actions?.length > 0 &&
                    <>
                    {actions?.map((action, index) => 
                        <Grid item xl={3} lg={3} md={6} xs={12} key={index}>
                            <PlaybookActionDetail 
                                key={index} 
                                action={action}
                                playbook={playbook}
                                refetch={refetch}
                            />
                        </Grid>
                        )
                    }
                    </> 
                    }
                </Grid>
            </Paper>
            
           
        </>
    );
};

export default PlaybookActionList;
