import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import { useNotificationContext } from "@/contexts/notification"
import { sleep } from '@/functions'
import withPermission from '@/hocs/withPermission'
import { EMPLOYEE_TYPES } from "@/libs/constants"
import { employeeTempValidator } from '@/libs/validators'
import { MenuProps } from '@/styles/common'
import { vestResolver } from '@hookform/resolvers/vest'
import { Box, Button, CircularProgress, FormControl, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import EmployeePosition from './shared/EmployeePosition'

const Create = ({
    handleClose,
    departments,
    callback
}) => {
    const { setNotification } = useNotificationContext()
    const [submit, setSubmit] = React.useState(false)

    const methods = useForm({
        resolver: vestResolver(employeeTempValidator),
        defaultValues: {
            employee_name: "",
            department_id: -1,
            position_id: -1,
            employee_type: -1,
            employee_email_company: ""
        }
    })
    const { handleSubmit, formState: { errors }, control, watch, setValue } = methods
    React.useEffect(() => {
        const departmentId = parseInt(watch("department_id"))
        if (departmentId === -1) return;
        setValue("position_id", -1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch("department_id")])

    const onSubmit = async (data) => {
        setSubmit(true)
        const response = await EmployeeApi.CreateTemp(data)
        await sleep(500)
        setSubmit(false)
        if (!response || response.status !== 201) return setNotification({
            open: true,
            message: "Thêm mới tài khoản nhân viên thất bại",
            severity: "error"
        })
        callback()
        handleClose()
        return setNotification({
            open: true,
            message: "Thêm mới tài khoản nhân viên thành công. Vui lòng gửi link cho nhân viên để cập nhật các thông tin khác.",
            severity: "success"
        })
    }
    return (
        <FormProvider {...methods}>
            <Paper sx={{ minWidth: 360, width: 360, height: '100vh', p: 3 }} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Typography className='page-title' sx={{ mb: 2 }}>
                    Thêm Nhân Viên Mới
                </Typography>
                <Typography sx={{ mb: 0.5 }}>
                    Họ & Tên Nhân Viên*
                </Typography>
                <Controller
                    control={control}
                    name="employee_name"
                    render={({ field }) =>
                        <TextField
                            size='small'
                            {...field}
                            error={!!errors?.employee_name}
                            helperText={errors?.employee_name?.message}
                            placeholder='Nhập họ và tên nhân viên...'
                            fullWidth />
                    } />
                <Typography sx={{ mb: 0.5, mt: 2 }}>
                    Phòng Ban*
                </Typography>
                <Controller
                    control={control}
                    name="department_id"
                    render={({ field: { value, onChange } }) =>
                        <FormControl fullWidth>
                            <Select
                                value={value}
                                onChange={(event) => onChange(event?.target?.value)}
                                size='small'
                                sx={{ color: value === -1 && "#777680" }}
                                MenuProps={MenuProps}
                            >
                                <MenuItem value={-1} sx={{ height: 38, fontSize: 14 }} disabled> --- chọn phòng ban</MenuItem>
                                {departments?.map(dept =>
                                    <MenuItem
                                        key={dept?.department_id}
                                        value={dept?.department_id} sx={{ height: 38, fontSize: 14 }}
                                    >
                                        {dept?.department_name}</MenuItem>

                                )}
                            </Select>
                        </FormControl>

                    } />
                <EmployeePosition />
                <Typography sx={{ mb: 0.5, mt: 2 }}>
                    Hình Thức Nhân Viên*
                </Typography>
                <Controller
                    control={control}
                    name="employee_type"
                    render={({ field: { value, onChange } }) =>
                        <FormControl fullWidth>
                            <Select
                                value={value}
                                onChange={(event) => onChange(event?.target?.value)}
                                size='small'
                                sx={{ color: value === -1 && "#777680" }}
                                MenuProps={MenuProps}
                            >
                                <MenuItem value={-1} sx={{ height: 38, fontSize: 14 }} disabled> --- chọn Hình Thức Nhân Viên</MenuItem>
                                {EMPLOYEE_TYPES?.map(t =>
                                    <MenuItem
                                        key={t?.value}
                                        value={t?.value} sx={{ height: 38, fontSize: 14 }}
                                    >
                                        {t?.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                    } />
                <Typography sx={{ mb: 0.5, mt: 2 }}>
                    Email Công Ty*
                </Typography>
                <Controller
                    control={control}
                    name="employee_email_company"
                    render={({ field }) =>
                        <TextField
                            size='small'
                            {...field}
                            error={!!errors?.employee_email_company}
                            helperText={errors?.employee_email_company?.message}
                            placeholder='Nhập email công ty cấp cho nhân viên...'
                            fullWidth />
                    } />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button variant='outlined' size="small" sx={{ height: 36, mr: 2 }}
                        onClick={handleClose}
                        disabled={submit}
                    >
                        Huỷ Thao Tác
                    </Button>
                    <Button variant='contained' size="small" sx={{ height: 36 }}
                        type="submit"
                        disabled={submit}
                        startIcon={submit && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                    >
                        Cấp Tài Khoản
                    </Button>
                </Box>

            </Paper>
        </FormProvider>
    )
}
export default withPermission(Create, { feature: 'employee', action: 'create' })