import BreadCrumb from "@components/shared/BreadCrumb";
import { Box, Typography } from "@mui/material";
import React from "react";
import Form from "../Form";
import { useParams } from "react-router";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import RackBandwidthApi from "@/apis/beta/Warehouse/RackBandwidthApi";

const Detail = () => {
    const params = useParams();
    const {data: rack_bandwidth, refetch} = RackBandwidthApi.Show({id:params?.id})
    return (
        <Box>
           <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>
                Quản lý Bandwidth
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Quản lý Bandwidth Rack", reactLink: "/warehouse/bandwidths/racks" },
                    { label: "Chi Tiết Đối Soát Bandwidth " }
                ]}
            /> 
            <Form type="update" rack_bandwidth={rack_bandwidth} rack_bw_id = {params?.id} refetchData={refetch}/>
        </Box>
    )
}
export default withSuspense(withPermission(Detail, {
    feature: "warehouse",
    action: "bandwidth-rack-detail"
}), MuiSkeleton["DataGrid"]) 