import InvestmentApi from "@/apis/beta/Warehouse/InvestmentApi";
import DatePicker from "@components/DatePicker";
import MuiCurrency from "@components/shared/CurrencyInputCustomize";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

const Form = ({
  type = "create",
  investment = {},
  handleClose,
  refetchData,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const { setNotification } = useNotificationContext();

  const [submit, setSubmit] = React.useState(false);

  const onSubmit = async (data) => {
    setSubmit(true);
    const form = data;
    let date_start = new Date(form.investment_start).getTime();
    let date_end = new Date(form.investment_end).getTime();
    if (parseInt(date_start) >= parseInt(date_end)) {
      return [
        setSubmit(false),
        setNotification({
          open: true,
          message: "Vui Lòng Chọn thời gian đúng .... ",
          severity: "error",
        }),
      ];
    }
    if (type === "create") {
      await InvestmentApi.create(form).then((response) => {
        const data = response.data;

        if (data.status !== 201) {
          setNotification({
            open: true,
            message: data.errors[0].message,
            severity: "error",
          });
          setSubmit(false);
          return;
        }
        return [
          setNotification({
            open: true,
            message: "Tạo đầu tư thành công.",
            severity: "success",
          }),
          setSubmit(false),
          handleClose(),
          refetchData(),
        ];
      });
    } else {
      await InvestmentApi.update(investment?.investment_id, form).then(
        (response) => {
          const data = response.data;

          if (data.status !== 200) {
            setNotification({
              open: true,
              message: data.errors[0].message,
              severity: "error",
            });
            setSubmit(false);
            return;
          }
          return [
            setNotification({
              open: true,
              message: "cập nhật đầu tư thành công.",
              severity: "success",
            }),
            setSubmit(false),
            handleClose(),
            refetchData(),
          ];
        }
      );
    }
  };

  return (
    <Box sx={{ p: 3, minWidth: 600 }}>
      <Typography
        sx={{ fontSize: { xs: 20, md: 24 }, fontWeight: "medium", mb: 2 }}
      >
        {type === "create" ? "Thêm" : "Sửa"} Đầu Tư
      </Typography>
      <Box>
        <Typography sx={{ mb: 0.5, mt: 1 }}> Tên cụm đầu tư: *</Typography>
        <Controller
          control={control}
          name="investment_name"
          defaultValue={investment?.investment_name || ""}
          rules={{
            required: "Vui lòng thêm tên đầu tư",
          }}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={type !== "create"}
              error={!!errors?.investment_name}
              helperText={errors?.investment_name?.message}
              placeholder="Nhập tên đầu tư"
              InputProps={{ sx: { height: 40, fontSize: 14 } }}
              fullWidth
            />
          )}
        />
      </Box>
      <Box>
        <Typography sx={{ mb: 0.5, mt: 1 }}> Thời gian bắt đầu: *</Typography>
        <Controller
          name="investment_start"
          defaultValue={
            type !== "create" ? investment?.investment_start : new Date()
          }
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl
                fullWidth
                sx={{
                  ".MuiIconButton-edgeEnd": {
                    m: "unset",
                  },
                }}
              >
                <DatePicker value={value} onChange={onChange} />
              </FormControl>
            );
          }}
        />
      </Box>
      <Box>
        <Typography sx={{ mb: 0.5, mt: 1 }}> Thời gian kết thúc: *</Typography>
        <Controller
          name="investment_end"
          defaultValue={
            type !== "create" ? investment?.investment_end : new Date()
          }
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl
                fullWidth
                sx={{
                  ".MuiIconButton-edgeEnd": {
                    m: "unset",
                  },
                }}
              >
                <DatePicker value={value} onChange={onChange} />
              </FormControl>
            );
          }}
        />
      </Box>
      <Box>
        <Typography sx={{ mb: 0.5, mt: 1 }}> Chi phí khác: *</Typography>
        <Controller
          control={control}
          defaultValue={
            type !== "create" ? investment?.investment_cost_other : 0
          }
          name="investment_cost_other"
          render={({ field: { value, onChange } }) => (
            <FormControl fullWidth>
              <MuiCurrency value={value} onChange={onChange} />
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Typography sx={{ mb: 0.5, mt: 1 }}> Ghi Chú: (nếu có) </Typography>
        <Controller
          control={control}
          name="investment_description"
          defaultValue={
            type !== "create" ? investment?.investment_description : ""
          }
          render={({ field }) => (
            <FormControl fullWidth>
              <TextField multiline {...field} placeholder="Nhập mô tả ..." />
            </FormControl>
          )}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          disabled={submit}
          size="small"
          variant="outlined"
          sx={{ height: 36 }}
          onClick={handleClose}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          disabled={submit}
          size="small"
          onClick={handleSubmit(onSubmit)}
          startIcon={
            submit && (
              <CircularProgress
                sx={{ m: "auto 0", color: "#777680" }}
                size={14}
              />
            )
          }
          variant="contained"
          sx={{ ml: 2, height: 36 }}
        >
          {type === "create" ? "Thêm" : "Cập Nhật"} Đầu Tư
        </Button>
      </Box>
    </Box>
  );
};
export default Form;
