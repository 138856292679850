import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControl,
  CircularProgress,
} from "@mui/material";
import {
  THRESHOLD_BIND,
  THRESHOLD_INTERVALS,
  THRESHOLD_SCOPE,
  THRESHOLD_STATUS,
} from "@/libs/constants";
import { useMutation, useQuery } from "react-query";
import ThresholdApi from "@/apis/beta/Threshold/ThresholdApi";
import { useNotificationContext } from "@contexts/notification";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const THRESHOLD_BINDS = [
  {
    label: "brobe::status::healthcheck",
    value: "brobe::status::healthcheck",
  },
  {
    label: "waf::elk::threshold",
    value: "waf::elk::threshold",
  },
  {
    label: "waf::usage::threshold",
    value: "waf::usage::threshold",
  },
  {
    label: "brobe::origin::status::healthcheck",
    value: "brobe::origin::status::healthcheck",
  },
]
function TechnicalThresholdCreate({
  open,
  handleClose,
  refetch,
  idUpdateThreshold,
  setIdUpdateThreshold = () => { },
}) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      threshold_name: "",
      threshold_description: "",
      metadata: "",
      threshold_status: THRESHOLD_STATUS?.[0]?.value,
      threshold_bind: THRESHOLD_BIND?.[0]?.value,
      threshold_scope: THRESHOLD_SCOPE?.[0]?.value,
      threshold_interval: THRESHOLD_INTERVALS?.[0]?.value,
    },
  });

  const { data, isLoading: FindOneThresholdLoading } = useQuery(
    `get-idUpdateThreshold-${idUpdateThreshold}`,
    () => ThresholdApi.FindOneThreshold(idUpdateThreshold)
  );

  const action = idUpdateThreshold ? "Cập nhật" : "Tạo mới";

  const { setNotification } = useNotificationContext();

  const { mutateAsync, isLoading } = useMutation(ThresholdApi.CreateThreshold, {
    onError: () =>
      setNotification({
        open: true,
        message: "Some thing went wrong",
        severity: "error",
      }),
  });

  const {
    mutateAsync: UpdateThresholdMutation,
    isLoading: UpdateThresholdLoading,
  } = useMutation(ThresholdApi.UpdateThreshold, {
    onError: () =>
      setNotification({
        open: true,
        message: "Some thing went wrong",
        severity: "error",
      }),
  });

  const isDisabled =
    isLoading || FindOneThresholdLoading || UpdateThresholdLoading;

  const onSubmit = async (values) => {
    const newValues = { ...values, metadata: JSON.parse(values.metadata) };

    let response;

    if (!idUpdateThreshold) {
      response = await mutateAsync(newValues);
    } else {
      response = await UpdateThresholdMutation({
        threshold_id: idUpdateThreshold,
        values: newValues,
      });
    }

    if (response?.errors) {
      return setNotification({
        open: true,
        message: "Some thing went wrong",
        severity: "error",
      });
    }

    setNotification({
      open: true,
      message: "Success",
      severity: "success",
    });

    setIdUpdateThreshold(null);
    handleClose();
    refetch();
  };

  React.useEffect(() => {
    if (!data?.data?.threshold) return;
    const values = data?.data?.threshold;

    setValue(
      "metadata",
      typeof values?.metadata === "object"
        ? JSON.stringify(values?.metadata)
        : values?.metadata
    );
    setValue("threshold_bind", values?.threshold_bind);
    setValue("threshold_description", values?.threshold_description);
    setValue("threshold_interval", values?.threshold_interval);
    setValue("threshold_name", values?.threshold_name);
    setValue("threshold_scope", values?.threshold_scope);
    setValue("threshold_status", values?.threshold_status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{action} Threshold</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Fill các thông tin của Threshold bên dưới
            </DialogContentText>

            <Box mt={2}>
              <Controller
                name="threshold_name"
                control={control}
                rules={{
                  required: {
                    message: "threshold_name is required",
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Threshold Name"
                    error={!!errors.threshold_name}
                    helperText={errors.threshold_name?.message}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%" }}
                  />
                )}
              />

              <Controller
                name="threshold_description"
                control={control}
                rules={{
                  required: {
                    message: "threshold_description is required",
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Threshold Description"
                    error={!!errors.threshold_description}
                    helperText={errors.threshold_description?.message}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%", mt: 2 }}
                  />
                )}
              />

              <Controller
                name="metadata"
                control={control}
                rules={{
                  required: { message: "metadata is required", value: true },
                }}
                render={({ field: { onChange, ...field } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        {...field}
                        sx={{ mt: 2 }}
                        multiline
                        placeholder="Metadata"
                        minRows={3}
                        onChange={(e) => {
                          try {
                            JSON.parse(e?.target?.value?.trim());
                            clearErrors("metadata");
                          } catch (error) {
                            setError("metadata", {
                              message: "metadata is in valid json format",
                            });
                          }

                          onChange(e?.target?.value);
                        }}
                      />
                      {errors?.metadata?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.metadata?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
              />

              <Box mt={2}>
                <Typography fontSize={14}>Threshold Status</Typography>
                <Controller
                  name="threshold_status"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        error={!!errors.threshold_status}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                        fullWidth
                      >
                        {THRESHOLD_STATUS?.map((item) => (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.threshold_status?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.threshold_status?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Typography fontSize={14}>Threshold Scope</Typography>
                <Controller
                  name="threshold_scope"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        error={!!errors.threshold_scope}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                        fullWidth
                      >
                        {THRESHOLD_SCOPE?.map((item) => (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.threshold_scope?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.threshold_scope?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Typography fontSize={14}>Threshold Bind</Typography>
                <Controller
                  name="threshold_bind"
                  control={control}
                  render={({ field }) => (
                    // <TextField
                    //   {...field}
                    //   size="small"
                    //   fullWidth
                    //   sx={{ mt: 2 }}
                    //   error={!!errors?.threshold_bind}
                    //   helperText={errors?.threshold_bind?.message}
                    // />
                    <Select value={field.value} onChange={field.onChange} setValue={setValue}  size="small" fullWidth>
                      {THRESHOLD_BINDS?.map((item) => (
                        <MenuItem value={item?.value} key={item?.value}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Typography fontSize={14}>Threshold Interval</Typography>
                <Controller
                  name="threshold_interval"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        error={!!errors.threshold_interval}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                        fullWidth
                      >
                        {THRESHOLD_INTERVALS?.map((item) => (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.threshold_interval?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.threshold_interval?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" size="small" onClick={handleClose}>
              Huỷ
            </Button>
            <Button
              disabled={isDisabled}
              size="small"
              variant="contained"
              type="submit"
            >
              {isDisabled ? <CircularProgress size={25} /> : action}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

export default TechnicalThresholdCreate;
