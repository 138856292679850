import BreadCrumb from "@components/shared/BreadCrumb";
import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import Form from "../Form";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const Create = () => {
    return(
        <Box>
            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>
                Quản lý Bandwidth
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Quản lý Bandwidth Datacenter", reactLink: "/warehouse/bandwidths/datacenters" },
                    { label: "Thêm  Hoá đơn đối soát Datacenter" }
                ]}
            />
            <React.Suspense fallback={<CircularProgress size={24} sx={{ m: 'auto'}}  />}>
            <Form type="create" refetchData={()=>{}}/>
            </React.Suspense>
            

        </Box>
    )
}
export default withSuspense(withPermission(Create, {
    feature: "warehouse",
    action: "bandwidth-datacenter-create"
}), MuiSkeleton["DataGrid"]) 
