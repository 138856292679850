import { REACT_APP_DOMAIN_FILE, styleLineClamp } from "@/libs/constants";
import { formatFileSize } from "@features/Module/Drives/shared";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { usePreviewFileContext } from "@contexts/preview-file";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
// import { useConfirmContext } from "@contexts/confirm";
// import useMutationWithNotification from "@/hooks/useMutationWithNotification";
// import DriveApi from "@/apis/beta/Drives/DriveApi";
// import TicketApi from "@/apis/beta/Ticket/TicketApi";
// import { useQueryClient } from "react-query";
// import { useParams } from "react-router-dom";

const AttactmentItem = ({ item, isPreview = true, handleDelete }) => {
  const preview = usePreviewFileContext();
  // const { showConfirmation } = useConfirmContext();
  // const { id } = useParams();

  // const queryClient = useQueryClient();

  // const { mutateAsync, isLoading, notify } = useMutationWithNotification(
  //   async () => {
  //     const [driveResponse, ticketResponse] = await Promise.all([
  //       DriveApi.Delete(item?.ticket_attachment_path),
  //       TicketApi.DeleteAttachment({
  //         attachment_id: item?.ticket_attactment_id,
  //       }),
  //     ]);

  //     if (!driveResponse?.errors && !ticketResponse?.errors) {
  //       queryClient.refetchQueries([`ticket.attachments.${id}`]);
  //       notify("success", "Success");
  //     }
  //   }
  // );

  // const handleDeleteAttachment = () => {
  //   mutateAsync();
  // };

  return (
    <Paper
      onClick={() => {
        if (!isPreview) return;
        preview.showNotUseDrivePath(`${REACT_APP_DOMAIN_FILE}${item?.ticket_attachment_path.replace("pub/", "")}`);
      }}
      sx={{
        p: 1.5,
        cursor: "pointer",
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        // opacity: isLoading ? 0.5 : 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <IconButton>
          <AttachmentIcon color="primary" />
        </IconButton>

        <Box>
          <Typography sx={styleLineClamp(1)}>
            {item?.ticket_attactment_name}
          </Typography>
          <Typography
            fontSize={14}
            color="GrayText"
            mt={0.5}
            sx={styleLineClamp(1)}
          >
            {formatFileSize(item?.ticket_attachment_size)} -{" "}
            {item?.ticket_attachment_type}
          </Typography>
        </Box>
      </Box>

      {handleDelete && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();

            if (handleDelete) {
              handleDelete();
              return;
            }

            // showConfirmation({
            //   title: "Bạn có chắc muốn xoá",
            //   open: true,
            //   description: "Thao tác này không thể khôi phục",
            //   loading: isLoading,
            //   handler: handleDeleteAttachment,
            // });
          }}
        >
          <DeleteOutlinedIcon color="error" />
        </IconButton>
      )}
    </Paper>
  );
};

export default AttactmentItem;
