import WarehouseRackApi from "@/apis/beta/Warehouse/WarehouseRackApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import Information from "./Infomation";
import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import HeaderTitle from "@components/Title/HeaderTitle";
import PaperLoading from "../../Shared/Loading/PaperLoading";
import IDCInstalling from "./IDCInstalling";

const RacksDetail = () => {
  const { id } = useParams();

  const { data, isLoading, refetch } = WarehouseRackApi.show(id);

  const isFetching = isLoading;

  if (isFetching) return <PaperLoading />;

  return (
    <Box>
      <HeaderTitle>
        Thông tin racks {data?.warehouse_rack?.warehouse_rack_name}
      </HeaderTitle>

      <Information
        callbackRefetch={refetch}
        warehouse_rack={data?.warehouse_rack}
      />

      <IDCInstalling
        wbRack={data?.warehouse_rack}
        refetchData={refetch}
        warehouseBoxItems={data?.warehouse_rack.warehouse_items}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(RacksDetail, {
    feature: "warehouse",
    action: "racks-detail",
  }),
  MuiSkeleton["GridInformation"]
);
