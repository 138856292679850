import ProductGroupApi from "@/apis/beta/Product/ProductGroupApi";
import InvestmentApi from "@/apis/beta/Warehouse/InvestmentApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseResoureServiceApi from "@/apis/beta/Warehouse/WarehouseResoureServiceApi";
import { Filter } from "@/hooks/useFilters";
import { SERVICES } from "@/libs/constants";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MultipleSelectCustomize from "@components/shared/MultipleSelectCustomize";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { useNotificationContext } from "@contexts/notification";
import { Box, Button, CircularProgress, FormControl, Grid, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Controller, useForm } from "react-hook-form";

export const WAREHOUSE_SERVICES = SERVICES.concat([{
    label: "CDN_TA",
    value: "CDN_TA"
},
{
    label: "CDN_FA",
    value: "CDN_FA"
},
{
    label: "CDN_DA",
    value: "CDN_DA"
},
{
    label: "CDN_MT",
    value: "CDN_MT"
}
])

const FormResourceSevice = ({
    warehouse_resource,
    type = "create",
    refetchData,
    warehouse_resource_service = {},
    refetchResourceSevice,
    handleClose = () => { },
    service_tag = null
}) => {
    const { control, handleSubmit, formState: { errors }, setValue } = useForm();
    const { setNotification } = useNotificationContext();

    const [loading, setLoading] = React.useState(false);

    const query = {
        $and: [
            { "warehouse_items.warehouse_item_form_factor": { $gt: 0 } },
            { "warehouse_box_items.warehouse_box_item_reference_type": { $eq: "WarehouseRack" } }
        ]
    }

    const { refetch: refetchWHItems } = WarehouseItemApi.Index({
        page: 1,
        pageSize: 20,
        query: Filter.stringify(query),
        search: null
    })

    const [warehouse_resource_config] = React.useState(_.find(warehouse_resource.warehouse_resource_configs, { warehouse_resource_config_type: "config" }) || {})


    const [configUsed, setConfigUsed] = React.useState({
        cpu: 0,
        ram: 0,
        storage: 0
    })

    const { data: investments } = InvestmentApi.getAll()


    const onSubmit = async (data) => {
        setLoading(true)
        data.warehouse_items = data.warehouse_items || []
        const form = data;
        if (warehouse_resource?.warehouse_resource_type === "virturalization") {
            form.warehouse_items = warehouse_resource?.warehouse_resource_metadata.map(o => o.warehouse_item_id)
        } else {
            form.warehouse_items = data.warehouse_items.map(o => o.warehouse_item_id)
        }
        form.warehouse_resource_service_name = data?.warehouse_resource_service_name?.value
        form.warehouse_resource_service_code = data?.warehouse_resource_service_code?.label
        if (type === "create" && form.warehouse_items.length === 0) {
            setNotification({ open: true, message: "Vui Lòng Chọn Thiết Bị", severity: "error" });
            setLoading(false)
            return;

        }
        if (type === "create") {
            await WarehouseResoureServiceApi.create(warehouse_resource?.warehouse_resource_id, form).then(response => {
                const data = response.data;
                if (data.status !== 201) {
                    setNotification({ open: true, message: data.errors[0].message, severity: "error" });
                    setLoading(false)
                    return;
                }
                refetchData();
                refetchResourceSevice();
                refetchWHItems();
                setNotification({ open: true, message: "Tạo tài nguyên dịch vụ thành công.", severity: "success" });
                setLoading(false);
                handleClose();
            })
        } else {
            await WarehouseResoureServiceApi.update(warehouse_resource?.warehouse_resource_id, warehouse_resource_service?.warehouse_resource_service_id, form).then(response => {
                const data = response.data;
                if (data.status !== 200) {
                    setNotification({ open: true, message: data.errors[0].message, severity: "error" });
                    setLoading(false)
                    return;
                }
                refetchData();
                refetchResourceSevice();
                refetchWHItems();
                setNotification({ open: true, message: "Cập nhật tài nguyên dịch vụ thành công.", severity: "success" });
                setLoading(false);
                handleClose();
            })
        }
    }

    const handleSelectItems = (items) => {
        const config = {
            cpu: 0,
            ram: 0,
            storage: 0
        }
        _.map(items, o => {
            if (o.capacity) {
                config.cpu = config.cpu + o?.capacity?.capacity_cpu;
                config.ram = config.ram + o?.capacity?.capacity_ram;
                config.storage = config.storage + o?.capacity?.capacity_storage;
            }
        })
        _.map(config, (value, config) => {
            setValue(`warehouse_resource_service_config.warehouse_resource_config_${config}`, value)
        })

        setConfigUsed(config)
    }


    const getConfigures = React.useCallback(() => {

        const config_used = _.find(warehouse_resource?.warehouse_resource_configs, { warehouse_resource_config_type: "used" })


        return {
            cpu: warehouse_resource_config?.warehouse_resource_config_cpu - config_used?.warehouse_resource_config_cpu,
            ram: warehouse_resource_config?.warehouse_resource_config_ram - config_used?.warehouse_resource_config_ram,
            storage: warehouse_resource_config?.warehouse_resource_config_storage - config_used?.warehouse_resource_config_storage,
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouse_resource])


    const getDevices = React.useCallback(() => {
        let ids = _.uniq(_.flatten(_.map(warehouse_resource?.warehouse_resource_services, o => (o.warehouse_resource_service_status === "active") ? o.warehouse_resource_service_metadata : null))) || []
        const warehouse_items = _.map(warehouse_resource?.warehouse_resource_metadata, o => ({
            ...o,
            value: o?.warehouse_item_service_tag,
            label: `${o?.warehouse_item_service_tag} - ${o?.warehouse_item_name}`
        })) || []


        let items = _.filter(warehouse_items, o => !ids.includes(o.warehouse_item_id))
        let default_select = []

        if (type !== "create") {
            default_select = _.filter(warehouse_items, o => warehouse_resource_service?.warehouse_resource_service_metadata.includes(o.warehouse_item_id))
            items = items.concat(default_select)
        }

        return {
            items: items,
            default: default_select
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouse_resource])
    const { data: productGroup } = ProductGroupApi.List();

    const getService = () => {
        let value = _.find(productGroup?.map((item) => (
            {
                label: item?.product_group_name,
                value: item?.product_group_name
            }
        )), { label: warehouse_resource_service?.warehouse_resource_service_name })
        if (!value && value !== "") {
            value = _.find(WAREHOUSE_SERVICES, { label: warehouse_resource_service?.warehouse_resource_service_name })
        }
        return value
    }
    return (
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                {warehouse_resource?.warehouse_resource_type === "dedicate" ? <>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography sx={{ mb: 0.5 }}>
                            Chọn Thiết Bị
                        </Typography>
                        <Controller
                            control={control}
                            defaultValue={getDevices()["default"]}
                            // rules={{
                            //     required: "Vui Lòng Chọn Thiết Bị",
                            // }}
                            {...type === "create" 
                                &&
                                {
                                    rules: {
                                    required: "Vui Lòng Chọn Thiết Bị",
                                    }
                                }
                            }
                            name="warehouse_items"
                            render={({ field: { value, onChange } }) =>
                                <FormControl fullWidth >
                                    <MultipleSelectCustomize
                                        compareAttributes={['label']}
                                        value={value}
                                        limitTags={10}
                                        options={getDevices()["items"]}
                                        input={{ placeholder: "Nhập tên thiết bị tìm kiếm..." }}
                                        onChange={(event, newValue) => {
                                            handleSelectItems(newValue)
                                            onChange(newValue)
                                        }}
                                        service_tag={service_tag}
                                    />
                                </FormControl>
                            } />
                        {!!errors?.warehouse_items && <ErrorHelperText message={errors?.warehouse_items?.message} />}

                    </Grid>
                </> : <>
                </>}

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>
                        Cụm Đầu Tư
                    </Typography>
                    <Controller
                        control={control}
                        name="warehouse_resource_service_code"
                        defaultValue={(type !== "create") ? _.find(investments, { label: warehouse_resource_service?.warehouse_resource_service_code }) : ""}
                        rules={{
                            required: "Vui Lòng chọn cụm đầu tư",
                        }}
                        render={({ field }) =>
                            <SingleSelectCustomize
                                {...field}
                                options={investments}
                                disabled={type !== "create"}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        field.onChange(newValue)
                                    }
                                }}
                                input={{
                                    placeholder: "Vui Lòng chọn cụm đầu tư",
                                }}
                            />
                        }
                    />
                    {!!errors?.warehouse_resource_service_code && <ErrorHelperText message={errors?.warehouse_resource_service_code?.message} />}
                </Grid>
                {(warehouse_resource?.warehouse_resource_type === "virturalization") ? <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>
                        Node
                    </Typography>
                    <Controller
                        control={control}
                        name="warehouse_resource_service_node"
                        defaultValue={(type !== "create") ? warehouse_resource_service?.warehouse_resource_service_node : ""}
                        rules={{
                            required: ("Vui lòng thêm tên Node"),
                        }}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                error={!!errors?.warehouse_resource_service_node}
                                helperText={errors?.warehouse_resource_service_node?.message}
                                placeholder="Nhập tên node ..."
                                InputProps={{ sx: { height: 40, fontSize: 14 } }}
                                fullWidth
                                sx={{
                                    mb: 2
                                }}
                            />
                        }
                    />
                </Grid> : <></>}
                <Grid item
                    xl={(warehouse_resource?.warehouse_resource_type === "virturalization") ? 3 : 6}
                    lg={(warehouse_resource?.warehouse_resource_type === "virturalization") ? 3 : 6}
                    md={(warehouse_resource?.warehouse_resource_type === "virturalization") ? 3 : 6}
                    sm={6} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>
                        Dịch Vụ Chạy
                    </Typography>
                    {console.log(_.find(WAREHOUSE_SERVICES, { label: warehouse_resource_service?.warehouse_resource_service_name }))}
                    <Controller
                        control={control}
                        name="warehouse_resource_service_name"
                        defaultValue={(type !== "create") ? getService()
                            : ""}
                        rules={{
                            required: "Vui Lòng điền mã tài nguyên dịch vụ",
                        }}
                        render={({ field }) =>
                            <SingleSelectCustomize
                                {...field}
                                // options={WAREHOUSE_SERVICES}
                                options={
                                    productGroup?.map((item) => (
                                        {
                                            label: item?.product_group_name,
                                            value: item?.product_group_name
                                        }
                                    ))?.concat([{
                                        label: "CDN_TA",
                                        value: "CDN_TA"
                                    },
                                    {
                                        label: "CDN_FA",
                                        value: "CDN_FA"
                                    },
                                    {
                                        label: "CDN_DA",
                                        value: "CDN_DA"
                                    },
                                    {
                                        label: "CDN_MT",
                                        value: "CDN_MT"
                                    }
                                    ])
                                }
                                disabled={type !== "create"}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        field.onChange(newValue)
                                    }
                                }}
                                input={{
                                    placeholder: "Chọn tên dịch vụ",
                                }}
                            />
                        }
                    />
                    {!!errors?.warehouse_resource_service_name && <ErrorHelperText message={errors?.warehouse_resource_service_name?.message} />}
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={{ mb: 1 }}>
                        Cấu Hình Tài Nguyên Chưa Dùng
                    </Typography>
                    <Grid container spacing={2}>
                        {_.map(["cpu", "ram", "storage"], (config) => {
                            return <Grid item xl={4} lg={4} md={4} sm={4} xs={12} key={`custom-config-${config}`}>
                                <Controller
                                    control={control}
                                    name={`warehouse_resource.warehouse_resource_config_${config}`}
                                    defaultValue={getConfigures()[config]}
                                    render={({ field: { onChange, value } }) =>
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            disabled
                                            error={!!errors?.warehouse_resource_service_config?.[`${config}`]}
                                            helperText={errors?.warehouse_resource_service_config?.[`${config}`]?.message}
                                            label={`${config} ${config !== 'cpu' ? "(GB)" : "(CORE)"}`}
                                            InputLabelProps={{ shrink: true, sx: { textTransform: 'uppercase' } }}
                                            size="small"
                                            InputProps={{
                                                type: "number",
                                                inputProps: {
                                                    min: 0,
                                                    // max: 0
                                                }
                                            }}
                                            sx={{ ":disabled": { bgcolor: "#EEEEEE" } }}
                                            fullWidth
                                        />
                                    } />
                            </Grid>
                        })}
                    </Grid>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={{ mb: 1 }}>
                        {warehouse_resource?.warehouse_resource_type === "dedicate" ? "Cấu Hình Tài Nguyên Thiết Bị Đã Chọn" : "Cấu Hình Tách Ảo Hoá"}
                    </Typography>
                    <Grid container spacing={2}>
                        {_.map(configUsed, (value, config) => {
                            return <Grid item xl={4} lg={4} md={4} sm={4} xs={12} key={`custom-config-${config}`}>
                                <Controller
                                    control={control}
                                    name={`warehouse_resource_service_config.warehouse_resource_config_${config}`}
                                    defaultValue={value}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            error={!!errors?.warehouse_resource_service_config?.[`${config}`]}
                                            helperText={errors?.warehouse_resource_service_config?.[`${config}`]?.message}
                                            label={`${config} ${config !== 'cpu' ? "(GB)" : "(CORE)"}`}
                                            InputLabelProps={{ shrink: true, sx: { textTransform: 'uppercase' } }}
                                            size="small"
                                            disabled={warehouse_resource?.warehouse_resource_type === "dedicate" ? true : false}
                                            InputProps={{
                                                type: "number",
                                                inputProps: {
                                                    min: 0,
                                                    // max: getConfigures()[config]
                                                }
                                            }}
                                            sx={{ ":disabled": { bgcolor: "#EEEEEE" } }}
                                            fullWidth
                                        />
                                    } />
                            </Grid>
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button variant='outlined' size='small'
                    onClick={handleClose}
                    sx={{ height: 40 }}>Huỷ Thao Tác</Button>
                <Button
                    variant='contained'
                    size="small"
                    startIcon={loading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                    disabled={loading}
                    sx={{ height: 40, ml: 2 }}
                    type="submit"
                >
                    {type === "create" ? "Tạo Tài Nguyên Dịch Vụ" : "Cập nhật "}
                </Button>
            </Box>
        </Box>
    )
}

export default FormResourceSevice;
