import { format_numeral_price } from '@/functions'

const ClusterConfig = ({
    informations,
    clusterQty
}) => {

    if (informations && informations?.length > 0) {
        return informations?.map((item) => {
            return `${format_numeral_price(item?.warehouse_capacity_value)} ${item?.warehouse_capacity_unit !== 'qty' ? item?.warehouse_capacity_unit?.toUpperCase() : ''}`
        })?.join(' & ')
    }
    return format_numeral_price(clusterQty)
}

export default ClusterConfig