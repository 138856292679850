import WarehouseProcessApi from "@/apis/beta/Warehouse/WarehouseProcessApi";
import WarehouseProcessLogApi from "@/apis/beta/Warehouse/WarehouseProcessLogApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { useEmployeeContext } from "@contexts/employee";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";

const CancelProcesses = ({ open, handleClose, refetch, data }) => {
  const [note, setNote] = useState(data?.warehouse_process_note || "");
  const { employee } = useEmployeeContext();
  const {
    mutateAsync: cancelProcesses,
    isLoading: cancelProcessesLoading,
    notify,
  } = useMutationWithNotification(WarehouseProcessApi.Cancelled);

  const handleCancle = async (e) => {
    e?.preventDefault();

    if (!note?.trim()) {
      notify("error", "Vui lòng cho biết lý do huỷ phiếu");
      return;
    }

    const response = await cancelProcesses({
      id: data?.warehouse_process_id,
      warehouse_process_note: note,
    });
    if (!response?.errors) {
      const warehouse_process_log = {
        warehouse_process_id: response?.data?.warehouse_process?.warehouse_process_id,
        warehouse_process_name: response?.data?.warehouse_process?.warehouse_process_code,
        warehouse_process_update_type: `cancel-${data?.warehouse_process_code.split('-')[0].toLowerCase()}`,
        warehouse_process_updater_id: employee?.employee_id,
        warehouse_process_updater_name: employee?.employee_name,
        metadata: response?.data?.warehouse_process,
        warehouse_process_update_description: `Huỷ phiếu ${data?.warehouse_process_code} với lý do ${note}`,
      }
      await WarehouseProcessLogApi.createProcessLog(warehouse_process_log);
      refetch();
      handleClose();
      notify("success", "Huỷ phiếu thành công");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Box component="form" onSubmit={handleCancle}>
        <DialogTitle>Xác nhận huỷ phiếu</DialogTitle>

        <DialogContent>
          <TextField
            multiline
            placeholder="Lý do huỷ phiếu..."
            value={note}
            onChange={(e) => setNote(e?.target?.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button size="small" variant="outlined" onClick={handleClose}>
            Huỷ
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            disabled={cancelProcessesLoading}
            startIcon={cancelProcessesLoading && <CircularProgress size={14} />}
          >
            Xác nhận
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CancelProcesses;
