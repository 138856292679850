import DatePicker from "@components/DatePicker";
import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const TicketServiceStartdate = ({ ticket_request_id = -1 }) => {
    const { control } = useFormContext()
    return (
        <Controller
            name="service_start_date"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
                <FormControl fullWidth>
                    <DatePicker
                        {...field}
                        onChange={onChange}
                        value={value}
                        type="date"
                        fullWidth
                        label={ticket_request_id === 1 ? "Ngày bắt đầu POC" : "Ngày bắt đầu sử dụng dịch vụ"}
                    />
                </FormControl>
            )}
        />
    )
}

export default TicketServiceStartdate