import EmployeeApi from '@/apis/beta/Employee/EmployeeApi'
import { buildQueryFilterCondition } from '@/functions'
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import useDebounce from '@/hooks/useDebounce'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize'
import { FormControl, Grid, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const CustomerSaleFollowers = () => {

    const { control, watch } = useFormContext()
    const [state, setState] = React.useState('')

    const debouncedSearchQuery = useDebounce(state, 400)

    const defaultFilterableEmployeeCs = React.useCallback(() => {
        const employeeSelectedIds = watch(`customer_sale_followers`)?.map((e) => e?.employee_id)?.join(',')
        let fieldQueries = [
            {
                name: 'employees.employee_id',
                operator: "$in",
                disabled: !employeeSelectedIds,
                value: employeeSelectedIds // default employees
            },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch(`customer_sale_followers`)])

    const seachFilterableEmployeeCs = React.useCallback(() => {
        if ((!debouncedSearchQuery || debouncedSearchQuery?.trim() === '')) return '';
        let fieldQueries = [
            {
                name: 'employees.employee_id',
                operator: "$eq",
                disabled: !parseInt(debouncedSearchQuery),
                value: parseInt(debouncedSearchQuery)
            },
            { name: 'employee_name', operator: "$contains", value: debouncedSearchQuery },
            { name: 'employee_code', operator: "$contains", value: debouncedSearchQuery }
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery, watch(`customer_sale_followers`)])

    const { data: employees, isFetching } = EmployeeApi.All({
        query: defaultFilterableEmployeeCs(),
        searchQuery: seachFilterableEmployeeCs()
    })

    return (
        <Grid container spacing={2}>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    SALE phụ trách
                </Typography>
            </Grid>
            <Grid item xl={9} lg={9} md={8} sm={6} xs={12}>
                <Controller
                    control={control}
                    name="customer_sale_followers"
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth>
                                <MultipleSelectCustomize
                                    loading={isFetching}
                                    limitTags={3}
                                    value={value}
                                    options={employees}
                                    formatCollection={{ value: "employee_id", label: "employee_name" }}
                                    compareAttributes={["employee_name"]}
                                    onChange={(event, newValue) => onChange(newValue || [])}
                                    input={{
                                        placeholder: "Nhập tên nhân viên sale tìm kiếm...",
                                        value: state,
                                        onChange: (e) => setState(e?.target?.value || '')
                                    }}
                                    sx={{
                                        "&.MuiAutocomplete-option": {
                                            textTransform: "uppercase"
                                        }
                                    }}
                                    mode="server"
                                />
                            </FormControl>
                        )
                    }} />
            </Grid>
        </Grid>
    )
}


export default withSuspense(withPermission(CustomerSaleFollowers, {
    feature: 'customer',
    action: 'assignee-sale',
    type: "button"
}), MuiSkeleton["FormControlTextField"])

// export default withSuspense(CustomerSaleFollowers, MuiSkeleton["FormControlTextField"])