import TicketApi from "@/apis/beta/Ticket/TicketApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Collapse, Paper } from "@mui/material";
import _ from "lodash";
import { useParams } from "react-router-dom";
import TicketForm from "../Shared/Forms/TicketForm";
import Header from "./Header";
import OverviewTicket from "./OverviewTicket";
import TicketInfo from "./TicketInfo";
import { TICKETS } from "../Create";
import { useState } from "react";

const Detail = () => {
  const { id: ticketId } = useParams();

  const {
    data: { ticket },
  } = TicketApi.ShowTicket({ ticketId });

  const onSubmit = async (values) => {
    return await TicketApi.UpdateTicket({
      ticket_id: ticket?.ticket_id,
      values: _.omit(values, ["ticket_cs", "ticket_sale"]),
    });
  };

  const [edit, setEdit] = useState(false);

  return (
    <Box>
      <Header id={ticket?.ticket_id} ticket_name="Thông Tin Ticket" />
      <TicketInfo ticket={ticket} setEdit={setEdit} edit={edit} />
      <Collapse in={edit}>
        <TicketForm
          ticket={ticket}
          defaultValues={{
            ..._.pick(ticket, [...Object.keys(TICKETS.VALUES)]),
          }}
          onSubmit={onSubmit}
          onClose={() => setEdit(false)}
        />
      </Collapse>
      <Paper sx={{ mt: 2 }}>
        <OverviewTicket />
      </Paper>
    </Box>
  );
};

export default withSuspense(
  withPermission(Detail, { feature: "ticket", action: "show" }),
  MuiSkeleton["DataGrid"]
);
