import DataGridProCustomize from "@components/DataGridProCustomize";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Link, NavLink } from "react-router-dom";
const GroupingColAndRowDataGrid = ({ rows, racks, type = "reality", year }) => {
    const reFormatDataNew = React.useCallback(() => {
        let columnGrouping = []
        if (type === "reality") {
            columnGrouping.push(
                {
                    groupId: `3`,
                    headerName: `Uplink thực tế`,
                    cellClassName: "Bandwidth_reality",
                    children: [
                        { field: `bw_reality.0` },
                        { field: `bw_reality.1` },
                        { field: `bw_reality.2` },
                        { field: `bw_reality.3` },
                        { field: `bw_reality.4` },
                        { field: `bw_reality.5` },
                        { field: `bw_reality.6` },
                        { field: `bw_reality.7` },
                        { field: `bw_reality.8` },
                        { field: `bw_reality.9` },
                        { field: `bw_reality.10` },
                        { field: `bw_reality.11` },
                        { field: `bw_reality.12` }]
                },
            )
        }
        else if (type === "commit") {
            columnGrouping.push(
                {
                    groupId: `2`,
                    headerName: `Uplink ký kết với nhà mạng`,
                    cellClassName: "Bandwidth_commit",
                    children: [
                        { field: `bw_commit.0` },
                        { field: `bw_commit.1` },
                        { field: `bw_commit.2` },
                        { field: `bw_commit.3` },
                        { field: `bw_commit.4` },
                        { field: `bw_commit.5` },
                        { field: `bw_commit.6` },
                        { field: `bw_commit.7` },
                        { field: `bw_commit.8` },
                        { field: `bw_commit.9` },
                        { field: `bw_commit.10` },
                        { field: `bw_commit.11` },
                        { field: `bw_commit.12` }]
                },
            )
        } else {
            columnGrouping.push(
                {
                    groupId: `1`,
                    headerName: `Uplink Giới Hạn`,
                    cellClassName: "Bandwidth_limit",
                    children: [
                        { field: `bw_limit.0` },
                        { field: `bw_limit.1` },
                        { field: `bw_limit.2` },
                        { field: `bw_limit.3` },
                        { field: `bw_limit.4` },
                        { field: `bw_limit.5` },
                        { field: `bw_limit.6` },
                        { field: `bw_limit.7` },
                        { field: `bw_limit.8` },
                        { field: `bw_limit.9` },
                        { field: `bw_limit.10` },
                        { field: `bw_limit.11` },
                        { field: `bw_limit.12` }]
                })
        }

        let columnsTable = [
            {
                field: 'warehouse_box_name',
                headerName: 'ISP',
                sortable: true,
                hideable: false,
                width: 150,
                valueGetter: ({ value, row }) => (value || row?.warehouse_box?.warehouse_box_name)?.replace("IDC ", "")?.split(" ")[0] || "",
                renderCell: ({ value, row }) => {
                    if (row?.warehouse_rack_id?.toString()?.includes("ps")) {
                        return <Typography color={"error"}
                        >
                            {value}
                        </Typography>
                    }
                    return (
                        <Typography color={"primary"}>{value}</Typography>
                    )
                }
            },
            {
                field: 'warehouse_rack_name',
                headerName: 'Rack',
                sortable: false,
                hideable: false,
                width: 200,
                renderCell: ({ value, row }) => {
                    if (row?.warehouse_rack_id?.toString()?.includes("ps")) {
                        return <Typography color={"error"}
                        >
                            {value}
                        </Typography>
                    }
                    return (
                        <Link color="primary" sx={{ textDecoration: "underline" }} component={NavLink} to={`/warehouse/racks/${row?.warehouse_rack_id}`}>
                            <Typography className='react-link-table' sx={{ textDecoration: "underline" }}>
                                {value}
                            </Typography>
                        </Link>
                    )
                }
            }
        ]
        let data_racks = []
        _.uniqBy(racks, "warehouse_rack_id")?.forEach((o) => {
            data_racks.push({
                id: o?.warehouse_rack_id,
                ...o
            })
        })


        const format = {
            bw_limit: "bandwidth_limit",
            bw_commit: "bandwidth_commit",
            bw_reality: "bandwidth_reality"
        }
        data_racks?.forEach((rack) => {
            const filterRows = rows?.filter(i => i?.warehouse_rack_id?.toString() === rack?.id?.toString())
            columnGrouping.forEach((i) => {
                i?.children?.forEach((j, index) => {
                    if (index === 0) {
                        const data = filterRows?.find((item) => new Date(item?.rack_bandwidth_date_start)?.getMonth() + 1 === 12 && new Date(item?.rack_bandwidth_date_start)?.getFullYear() === year - 1)
                        if (data) {
                            rack[`${j.field}`] = data[format[`${j?.field?.split(".")[0]}`]] || 0
                        } else {
                            rack[`${j.field}`] = ""
                        }
                    } else {
                        const data = filterRows?.find((item) => new Date(item?.rack_bandwidth_date_start)?.getMonth() + 1 === Number(j?.field?.split(".")[1]) && new Date(item?.rack_bandwidth_date_start)?.getFullYear() === year)
                        if (data) {
                            rack[`${j.field}`] = data[format[`${j?.field?.split(".")[0]}`]] || 0
                        } else {
                            rack[`${j.field}`] = ""
                        }
                    }
                    rack["rack_col_name"] = j.field
                })
            })
            rack["rack_bandwidth_date_start"] = rack?.rack_bandwidth_date_start
            if (rack?.warehouse_rack_id?.toString()?.includes("ps")) {
                rack["warehouse_box"] = { warehouse_box_name: rack?.warehouse_box_name }
            }
        })
        columnGrouping?.forEach((i) => {
            i?.children?.forEach((j, index) => {
                let col = {}
                if (index === 0) {
                    col["field"] = j?.field
                    col["headerName"] = `Tháng 12 - ${year - 1}`
                    col["sortable"] = false
                    col["width"] = 150
                    col["renderCell"] = ({ value }) => {
                        return value > 0 ? value : ""
                    }
                } else {
                    col["field"] = j?.field
                    col["headerName"] = `Tháng ${index}`
                    col["sortable"] = false
                    col["width"] = 100
                    col["renderCell"] = ({ value, row }) => {
                        const prevMonthData = row[`${row?.rack_col_name?.split(".")[0]}.${index - 1}`]
                        return value < 1 ? "" : prevMonthData === value ? "--" : value
                    }
                }
                columnsTable.push(col)
            })
        })

        return {
            results: data_racks?.sort((a, b) => b?.warehouse_box_name?.replace("IDC ", "")?.split(" ")[0].localeCompare(a?.warehouse_box_name?.replace("IDC ", "")?.split(" ")[0])),
            columnGrouping: columnGrouping,
            columnsTable: columnsTable
        }
    }, [rows])
    const helpUserCols = () => {
        return [
            {
                field: "syntax",
                headerName: "Ký tự",
                width: 150
            },
            {
                field: "note",
                headerName: "Chú thích",
                width: 250
            }
        ]
    }
    const helpUserRows = () => {
        return [
            {
                id: 1,
                syntax: "' -- '",
                note: "Biểu thị giá trị trùng với tháng trước"
            },
            {
                id: 2,
                syntax: "ô rỗng",
                note: "Không có giá trị thống kê hoặc = 0"
            },
        ]
    }

    return (
        <Box
            sx={{
                "& .MuiDataGrid-columnHeader--filledGroup": {
                    "&:nth-of-type(even)": {
                        backgroundColor: "#b9d5ff91",
                        color: "#607d8b",
                    },
                    "&:nth-of-type(Odd)": {
                        backgroundColor: "#607d8b",
                        color: "white",
                    }
                },
                "& .MuiDataGrid-columnHeader--emptyGroup": {
                    backgroundColor: "#ff943975"
                }
            }}
        >
            <DataGridProCustomize
                initialState={{
                    pinnedColumns: { left: ['warehouse_box_name', 'warehouse_rack_name'] },
                }}
                rows={reFormatDataNew()["results"] || []}
                columns={reFormatDataNew()["columnsTable"]}
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={reFormatDataNew()["columnGrouping"]}
                rowCount={reFormatDataNew().length}
                disableFooter
            />
            <Box sx={{ mt: 2 }}>
                <DataGridProCustomize
                    rows={helpUserRows()}
                    columns={helpUserCols()}
                    experimentalFeatures={{ columnGrouping: true }}
                    rowCount={helpUserRows().length}
                    disableFooter
                />
            </Box>
        </Box>
    )
}

export default GroupingColAndRowDataGrid;
