import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";

class WarehouseTypeApi {
  index = ({ query = null, searchQuery = null, page = 1, pageSize = 20 }) =>
    useQueryWithCustomized(
      ["warehouse.warehouse_types.list", query, searchQuery, page, pageSize],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_types?${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${
            searchQuery ? `&queries=${searchQuery}` : ""
          }&page=${page}&page_size=${pageSize}`
        )
          .then(({ data }) => {
            if (data?.error) return data;

            return {
              warehouse_types: data?.data?.warehouse_types,
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );

  All = () =>
    useQueryWithCustomized(
      ["warehouse.types.all"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_types/list`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_types?.map((o) => ({
              value: o?.warehouse_type_id,
              label: o?.warehouse_type_name.toUpperCase(),
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );

  Create = (params) =>
    requestWithToken(`${API_WAREHOUSE_V1_URL}/warehouse_types`, "POST", {
      warehouse_type: params,
    });

  Show = (id, callback) =>
    useQueryWithCustomized(
      ["warehouse_types.detail", id],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_types/${id}`
        )
          .then(({ data }) => {
            if (data?.errors) return callback(data.errors);

            return {
              warehouse_type: data?.data?.warehouse_type,
            };
          })
          .catch((error) => {
            console.log(error);

            return error.response;
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        notifyOnChangePropsExclusions: ["isStale"],
        suspense: true,
      }
    );

  Models = ({
    query = null,
    searchQuery = null,
    type_id,
    page = 1,
    pageSize = 20,
  }) =>
    useQueryWithCustomized(
      [
        "warehouse.warehouse_types.model.list",
        type_id,
        query,
        searchQuery,
        page,
        pageSize,
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_types/${type_id}/models?${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${
            searchQuery ? `&queries=${searchQuery}` : ""
          }&page=${page}&page_size=${pageSize}`
        )
          .then(({ data }) => {
            if (data?.error) return data;

            return {
              warehouse_models: data?.data?.warehouse_models.map((o) => ({
                ...o,
                id: o.warehouse_model_id,
              })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
}

export default WarehouseTypeApi = new WarehouseTypeApi();
