import HeaderTitle from "@components/Title/HeaderTitle";
import { Box, Button, IconButton, Popper, Typography } from "@mui/material";
import React, { useState } from "react";
import ProcessesFilter from "./ProcessesFilter";
import WarehouseProcessApi from "@/apis/beta/Warehouse/WarehouseProcessApi";
import useDataGridParams from "@/hooks/useDataGridParams";
import { Link, NavLink } from "react-router-dom";
import { CheckCircleOutline, MoreVertOutlined } from "@mui/icons-material";
import _ from "lodash";
import { format_date_short } from "@/functions";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import DataGridCustomize from "@components/DataGrid";
import PopperAction from "@components/PopperAction";
import CancelProcesses from "./CancelProcesses";

const Processes = () => {
  const initialColumns = [
    {
      field: "warehouse_process_code",
      headerName: "Mã",
      width: 200,
      renderCell: ({ value, row }) => (
        <Link
          component={NavLink}
          to={`/warehouse/processes/${row.warehouse_process_id}/detail`}
        >
          <Typography className="react-link-table">{value}</Typography>
        </Link>
      ),
    },
    {
      field: "warehouse_process_from",
      headerName: "Từ Nơi",
      width: 250,
      renderCell: ({ value, row }) => (
        <Typography fontSize={14} title={value}>
          {value}
        </Typography>
      ),
    },
    {
      field: "warehouse_process_to",
      headerName: "Nơi Đến",
      width: 250,
      renderCell: ({ value, row }) => (
        <Typography fontSize={14} title={value}>
          {value}
        </Typography>
      ),
    },
    {
      field: "warehouse_process_status",
      headerName: "Trạng Thái",
      width: 250,
      renderCell: ({ value, row }) => (
        <Typography fontSize={14} title={value}>
          {value}
        </Typography>
      ),
    },
    {
      field: "warehouse_process_storekeeper",
      headerName: "Thủ Kho",
      width: 150,
      renderCell: ({ value, row }) => (
        <>
          {row?.warehouse_process_storekeeper === "approved" ? (
            <CheckCircleOutline
              sx={{ fontSize: 20, color: "#357a38", mr: 0.5 }}
            />
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      field: "warehouse_process_lead",
      headerName: "Tech",
      width: 150,
      renderCell: ({ value, row }) => (
        <>
          {row?.warehouse_process_lead === "approved" ? (
            <CheckCircleOutline
              sx={{ fontSize: 20, color: "#357a38", mr: 0.5 }}
            />
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      field: "warehouse_process_reason",
      headerName: "Nội Dung",
      width: 300,
      renderCell: ({ value, row }) => (
        <Typography fontSize={14} title={value}>
          {value}
        </Typography>
      ),
    },
    {
      field: "warehouse_process_note",
      headerName: "Ghi Chú",
      width: 300,
      renderCell: ({ value, row }) => (
        <Typography title={value || ""}>{value || ""}</Typography>
      ),
    },
    {
      field: "employee_id",
      headerName: "Nhân Viên",
      valueGetter: ({ row }) =>
        _.find(employees, { employee_id: row.employee_id })?.employee_name,
      width: 200,
    },
    {
      field: "warehouse_process_created_at",
      headerName: "Ngày Tạo",
      width: 300,
      renderCell: ({ value, row }) => <>{format_date_short(value)}</>,
    },
    {
      field: "id",
      headerName: "Thao Tác",
      width: 80,
      renderCell: ({ row, value }) =>
        value && row.warehouse_process_status === "pending" ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              title="Thao Tác"
              onClick={(event) => {
                setPopper((prev) => ({
                  ...prev,
                  anchorEl: event?.target,
                  data: row,
                }));
              }}
            >
              <MoreVertOutlined sx={{ m: "auto" }} />
            </IconButton>
          </Box>
        ) : (
          ""
        ),
    },
  ];

  const {
    columns,
    debouncedSearchQuery,
    page,
    searchQuery,
    setColumns,
    setPage,
    setSearchQuery,
    query,
    updateQuery,
  } = useDataGridParams(initialColumns);

  const { data, isFetching, refetch } = WarehouseProcessApi.index({
    page: page?.page + 1 || 1,
    pageSize: page?.pageSize || 20,
    query,
    searchQuery: debouncedSearchQuery,
  });
  const [popper, setPopper] = useState({
    anchorEl: null,
    data: null,
  });

  const [showCancelProcesses, setShowCancelProcesses] = useState({
    open: false,
  });

  const actions = [
    {
      name: "update",
      href: { prefix: `/warehouse/processes/`, key: "id", suffix: "/edit" },
      icon: "Edit",
      label: "Chỉnh Sửa",
    },
    {
      name: "cancel",
      icon: "Delete",
      handleClick: (data) => {
        setShowCancelProcesses({
          open: true,
          data,
        });
      },
      label: "Huỷ Phiếu",
    },
  ];

  const { employees } = EmployeeApi.ListEmployees();

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <HeaderTitle>Quản Lý Phiếu Kho</HeaderTitle>
        <Link to="/warehouse/processes/new">
          <Button variant="contained" size="small">
            Thêm Phiếu
          </Button>
        </Link>
      </Box>

      {showCancelProcesses?.open && (
        <CancelProcesses
          open={showCancelProcesses?.open}
          data={showCancelProcesses?.data}
          handleClose={() => setShowCancelProcesses({ open: false })}
          refetch={refetch}
        />
      )}

      {popper?.anchorEl !== undefined && (
        <Popper anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)}>
          <PopperAction
            {...popper}
            actions={actions}
            handleClose={() =>
              setPopper((prev) => ({ ...prev, anchorEl: undefined }))
            }
          />
        </Popper>
      )}

      <ProcessesFilter updateQuery={updateQuery} />

      <DataGridCustomize
        sx={{ mt: 2 }}
        rows={data?.warehouse_processes || []}
        columns={columns}
        loading={isFetching}
        rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
        componentsProps={{
          toolbar: {
            store: `processes.list.hiddenColumns`,
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "processes",
            inputSearch: {
              placeholder: "Tìm kiếm,...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({
                ...prevState,
                pageSize: newPageSize,
              })),
          },
        }}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(Processes, { feature: "warehouse", action: "processes" }),
  MuiSkeleton["DataGrid"]
);
