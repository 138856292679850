import WarehouseResourceApi from "@/apis/beta/Warehouse/WarehouseResourceApi";
import { Box, Button, CircularProgress, Grid, Paper, Slide, Typography } from "@mui/material";
import React from "react";
import _ from "lodash";
import { useLocation, useParams } from "react-router";
import { format_pricing_vnd } from "@/libs/constants";
import { Lens } from "@mui/icons-material";
import { green, grey } from "@mui/material/colors";
import CellConfig from "@components/DataGrid/CellConfig2";
import CellSpecification from "@components/DataGrid/CellSpecification";
import { formatBytes, format_date_short } from "@/functions";
import Header from "../Header";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import DeviceTable from "../../Create/share/DeviceTable";
import WarehouseDeviceTable from "../../Create/share/WarehouseDeviceTable";
import ResourceForm from "../../Create/share/ResourceForm";

const Information = () => {
    const params = useParams();
    const {search} = useLocation();
    const searchParams = new URLSearchParams(search);
    const { data: { warehouse_resource }, refetch: refetchData } = WarehouseResourceApi.show(params?.id)

    const [config, setConfig] = React.useState(_.find(warehouse_resource.warehouse_resource_configs, { warehouse_resource_config_type: "config" }))
    const [resourceItems, setResourceItems] = React.useState(warehouse_resource?.warehouse_resource_metadata || [])

    const [showEdit, setShowEdit] = React.useState(false)

    const [configUsed, setConfigUsed] = React.useState(_.find(warehouse_resource.warehouse_resource_configs, { warehouse_resource_config_type: "used" }))


    const [device, setDevice] = React.useState({
        open: false
    })

    React.useEffect(() => {
        setResourceItems(warehouse_resource?.warehouse_resource_metadata)
        setConfig(_.find(warehouse_resource.warehouse_resource_configs, { warehouse_resource_config_type: "config" }))
        setConfigUsed(_.find(warehouse_resource.warehouse_resource_configs, { warehouse_resource_config_type: "used" }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouse_resource?.warehouse_resource_metadata]);

    return (
        <Box>
            <Header />
            <Paper sx={{ mt: 2, p: 2, mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <Box>
                            <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                Loại Tài Nguyên
                            </Typography>
                            <Typography sx={{ color: "#00000F" }}>{
                                warehouse_resource?.warehouse_resource_type.toUpperCase()}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <Box>
                            <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                Active
                            </Typography>
                            <Typography sx={{ color: "#00000F" }}><Lens sx={{ m: "auto", color: !warehouse_resource?.warehouse_resource_active ? grey[500] : green[900] }} /></Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <Box>
                            <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                Trạng Thái
                            </Typography>
                            <Typography sx={{ color: "#00000F" }}>
                                {!warehouse_resource.warehouse_resource_status ? "Hoạt động" : "Huỷ"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <Box>
                            <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                Tổng Đầu Tư
                            </Typography>
                            <Typography sx={{ color: "#00000F" }}>
                                {format_pricing_vnd(_.sumBy(warehouse_resource.warehouse_resource_metadata, o => o?.warehouse_item_price))}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <Box>
                            <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                Cấu Hình
                            </Typography>
                            <Typography sx={{ color: "#00000F" }}>
                                <CellConfig configures={
                                    {
                                        capacity_cpu: config?.warehouse_resource_config_cpu,
                                        capacity_ram: config?.warehouse_resource_config_ram,
                                        capacity_storage: config?.warehouse_resource_config_storage
                                    }
                                } />
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <Box>
                            <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                Cấu Hình Chưa Dùng
                            </Typography>
                            <Typography sx={{ color: "#00000F" }}>
                                <CellSpecification items={[
                                    { label: `CPU: ${config?.warehouse_resource_config_cpu - configUsed.warehouse_resource_config_cpu} `, value: "", unit: "CORE" },
                                    { label: `RAM: ${formatBytes(config?.warehouse_resource_config_ram - configUsed.warehouse_resource_config_ram)} `, value: "", unit: "" },
                                    { label: `STORAGE: ${formatBytes(config?.warehouse_resource_config_storage - configUsed.warehouse_resource_config_storage)} `, value: "", unit: "" }
                                ]} />
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <Box>
                            <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                Ngày Tạo
                            </Typography>
                            <Typography sx={{ color: "#00000F" }}>
                                {format_date_short(warehouse_resource?.warehouse_resource_created_at)}
                            </Typography>
                        </Box>
                    </Grid>

                </Grid>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant='outlined' size='small'
                        disabled={true}
                        sx={{ height: { xs: 36, md: 42 } }}>Huỷ Tài Nguyên</Button>
                    <Button
                        variant='contained'
                        disabled={showEdit}
                        onClick={() => setShowEdit(true)}

                        size='small' sx={{ ml: 2, height: { xs: 36, md: 42 } }}>Sửa Tài Nguyên</Button>
                </Box>
            </Paper>
            {showEdit ? <>
                <React.Suspense fallback={<CircularProgress size={16} disableShrink />}>
                    <Box sx={{ p: '10px 30px' }} >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                            <Button
                                variant='contained'
                                disabled={device?.open}
                                focusRipple={!device?.open}
                                onClick={() => setDevice({ open: true })}
                                size='small' sx={{ height: 42, mb: 1 }}>
                                Chọn Thiết Bị
                            </Button>
                        </Box>


                        {device?.open && <Slide in={device?.open} direction="up">
                            <Box>
                                <WarehouseDeviceTable key={"edit"} setResourceItems={setResourceItems} idsExist={resourceItems.map(item => item?.warehouse_box_item?.warehouse_box_item_id)} handleClose={() => setDevice({ open: false })} service_tag={searchParams?.get("service_tag")}/>
                            </Box>
                        </Slide>}
                    </Box>
                    <ResourceForm
                        resourceItems={resourceItems}
                        setResourceItems={setResourceItems}
                        type="edit" 
                        resource={warehouse_resource}
                        refetchData={refetchData}
                        handleClose={()=> {
                            setShowEdit(false);
                            setResourceItems(warehouse_resource?.warehouse_resource_metadata || [])
                        }}
                        service_tag={searchParams?.get("service_tag")}
                    />
                </React.Suspense>
            </> : <>
                <DeviceTable key="detail" warehouse_resource={warehouse_resource} resourceItems={warehouse_resource?.warehouse_resource_metadata} setResourceItems={setResourceItems} type={"detail"} service_tag={searchParams?.get("service_tag")}/>
            </>}
        </Box>
    )
}

export default withSuspense(withPermission(Information, {
    feature: "warehouse",
    action: "resource-information"
}), MuiSkeleton["DataGrid"]) 