import ServicePriceApi from '@/apis/beta/Service/ServicePriceApi'
import { format_date_short, queryClientRefetchScope } from '@/functions'
import { useNotificationContext } from '@contexts/notification'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import COMMITMENT from '../../../shared/Commit'
import PAYMENT from '../../../shared/ServicePayment'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import _ from 'lodash'

const PackagePrice = ({ price, serviceId }) => {

    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()
    const { mutateAsync, isLoading } = useMutation(ServicePriceApi.ChangeServiceConfig)
    const { mutateAsync: serviceUpdatePayment } = useMutation(ServicePriceApi.UpdatePaymentConfig)

    const { data: { payment } } = ServicePriceApi.PaymentConfig({ serviceId })

    const methods = useForm({
        defaultValues: useMemo(() => {
            return ({
                unit: price?.unit,
                id: price?.id,
                commitment: {
                    id: price?.commitment?.service_commitment_configure_id || -1,
                    commitment_available: price?.commitment?.commitment_available || 0,
                    value: price?.commitment?.value || 0,
                    pricing_apply: price?.commitment?.pricing_apply || 0,
                    pricing_amount: price?.commitment?.pricing_amount || 0,
                    startdate: !!price?.commitment?.startdate ? dayjs(price?.commitment?.startdate) : null,
                    enddate: !!price?.commitment?.enddate ? dayjs(price?.commitment?.enddate) : null,
                    term: price?.commitment?.term || 1,
                    prepaid: price?.commitment?.prepaid
                },
                payment_config: {
                    payment_config_optional: payment?.payment_config_optional
                }
            })
        }, [price, payment])
    })

    const updatePaymentConfig = async (name = 'default') => await serviceUpdatePayment({ serviceId, name })
    const updateServicePackage = async (form = {}) => await mutateAsync({ serviceId, data: form })

    const onSubmit = async (values) => {
        const form = {
            ...(_.omit(values, ['payment_config'])),
            commitment: {
                ...values?.commitment,
                startdate: format_date_short(values?.commitment?.startdate?.$d) || format_date_short(values?.commitment?.startdate),
                enddate: format_date_short(values?.commitment?.enddate?.$d) || format_date_short(values?.commitment?.enddate),
            }
        }

        const [servicePayment, servicePrice] = await Promise.all([
            updatePaymentConfig(values?.payment_config?.payment_config_optional),
            updateServicePackage(form)
        ])

        if (!servicePayment || !servicePrice || servicePrice?.errors || servicePayment?.errors) {
            return setNotification({ open: true, message: 'Cập nhật gói cố đinh dịch vụ thất bại.', severity: 'error' })
        }
        queryClientRefetchScope(queryClient, 'service')
        return setNotification({
            open: true, message: 'Thay đổi gói cố định thành công', severity: 'success'
        })
    }
    return (
        <FormProvider {...methods}>
            <Box
                component="form"
                onSubmit={methods.handleSubmit(onSubmit)}
                sx={{
                    p: 2,
                    border: '1px solid rgba(0,0,0,0.18)',
                    borderRadius: '4px'
                }}>
                <Typography sx={{ fontWeight: 'medium', mb: 1.25 }}>
                    Gói Cố Định
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2.5}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <COMMITMENT.Prepaid />
                            <PAYMENT.PaymentOptional />
                        </Box>
                    </Grid>
                    <Grid item xs={9} md={3} xl={3}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <COMMITMENT.Committed />
                            <COMMITMENT.Terminal />
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3} xl={3}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <COMMITMENT.Price />
                            <COMMITMENT.PriceSubtotal />
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3} xl={3}>
                        <COMMITMENT.Dates />
                    </Grid>
                    <Grid item xs={12} md={11.5}>
                        {methods?.formState?.isDirty && <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <Button
                                disabled={isLoading}
                                startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                                type='submit'
                                variant='contained'
                                size='small'
                            >
                                Thay Đổi Gói
                            </Button>
                        </Box>}
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
    )
}

export default withSuspense(PackagePrice, MuiSkeleton["Paper"])