import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useFilters, { Filter } from "@/hooks/useFilters";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import React from "react";
import { useNavigate } from "react-router";
import _ from "lodash";
import { Box, Button, Link } from "@mui/material";
import { NavLink } from "react-router-dom";
import Cell from "@components/DataGrid/Cell";
import CellSpecification from "@components/DataGrid/CellSpecification";
import CellConfig from "@components/DataGrid/CellConfig2";
import { formatBytes, format_date_short, getDataGridRowHeight } from "@/functions";
import { format_pricing_vnd } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import WarehouseResourceApi from "@/apis/beta/Warehouse/WarehouseResourceApi";
import { AddOutlined } from "@mui/icons-material";
import Header from "../Header";
const queryString = require("query-string");


const List = () => {
    const navigate = useNavigate();

    const url_params = queryString.parse(window.location.search, {
        skipNull: true,
        arrayFormat: 'bracket',
        skipEmptyString: true,
    })

    const { data: warehouse_boxes } = WarehouseBoxApi.getAll()


    const [urlParams, setUrlParams] = React.useState(
        {
            page: 1,
            page_size: 10,
            ...url_params,
        }
    )
    const [query] = useFilters('warehouse.resource.list.filters');

    const { data, isFetching } = WarehouseResourceApi.index({
        query: Filter.stringify(query),
        page: urlParams.page,
        pageSize: urlParams.page_size
    })


    const initialColumns = [
        {
            field: 'warehouse_resource_type',
            headerName: 'Loại',
            width: 200,
            renderCell: ({ row, value }) => (
                <Link component={NavLink} to={`/warehouse/resources/${row.warehouse_resource_id}/information`} > <Cell content={value.toUpperCase()} /></Link>
            )
        },
        {
            field: 'warehouse_resource_services',
            headerName: 'Cụm Đầu Tư',
            width: 200,
            valueGetter: ({ row }) => {
                return _.uniqBy(row?.warehouse_resource_services, o => o["o.warehouse_resource_service_code"]).map(o => (
                    { label: `${o.warehouse_resource_service_code}`, value: "", unit: "" }
                ))
            },
            renderCell: ({ value }) => (value && <CellSpecification items={_.uniq(value)} />)

        },
        {
            field: 'warehouse_resource_service_name',
            headerName: 'Dịch Vụ Chạy',
            width: 200,
            valueGetter: ({ row }) => {
                // const serviceName = row?.warehouse_resource_services?.map((item) => {
                //     return ({
                //         label: `${item.warehouse_resource_service_name}`,
                //         value: "",
                //         unit: ""
                //     })
                // })
                // return _.uniqBy(serviceName, "label");
                return _.uniqBy(row?.warehouse_resource_services, o => o["warehouse_resource_service_name"]).map(o => (
                    { label: `${o.warehouse_resource_service_name}`, value: "", unit: "" }
                ))
            },
            renderCell: ({ value }) => (value && <CellSpecification items={_.uniq(value)} />)
        },
        {
            field: 'warehouse_resource_config',
            headerName: 'Cấu Hình Tổng',
            width: 350,
            valueGetter: ({ row }) => {
                const warehouse_resource_config = _.find(row?.warehouse_resource_configs, { warehouse_resource_config_type: "config" })
                const config = {
                    capacity_cpu: warehouse_resource_config?.warehouse_resource_config_cpu,
                    capacity_ram: warehouse_resource_config?.warehouse_resource_config_ram,
                    capacity_storage: warehouse_resource_config?.warehouse_resource_config_storage
                }
                return config
            },
            renderCell: ({ value }) => (value && <CellConfig configures={value} />)
        },
        {
            field: 'warehouse_resource_config_used',
            headerName: 'Cấu Hình Còn Lại',
            width: 350,
            valueGetter: ({ row }) => {
                let warehouse_resource_config = _.find(row?.warehouse_resource_configs, { warehouse_resource_config_type: "config" })
                let warehouse_resource_config_used = _.find(row?.warehouse_resource_configs, { warehouse_resource_config_type: "used" })

                const config = {
                    cpu: warehouse_resource_config?.warehouse_resource_config_cpu - warehouse_resource_config_used?.warehouse_resource_config_cpu,
                    ram: warehouse_resource_config?.warehouse_resource_config_ram - warehouse_resource_config_used?.warehouse_resource_config_ram,
                    storage: warehouse_resource_config?.warehouse_resource_config_storage - warehouse_resource_config_used?.warehouse_resource_config_storage
                }

                return [
                    { label: `CPU: ${config["cpu"]} `, value: "", unit: "CORE" },
                    { label: `RAM: ${formatBytes(config["ram"])} `, value: "", unit: "" },
                    { label: `STORAGE: ${formatBytes(config["storage"])} `, value: "", unit: "" }
                ]
            },
            renderCell: ({ value }) => (value && <CellSpecification items={value} />)
        }, {
            field: 'warehouse_resource_status',
            headerName: 'Trạng Thái',
            width: 150,
            renderCell: ({ value }) => <>{!value ? "Hoạt động" : "Huỷ"}</>
        },
        {
            field: 'total',
            width: 150,
            headerName: 'Thiết Bị',
            valueGetter: ({ row }) => row.warehouse_resource_metadata.length
        },
        {
            field: 'total_price',
            width: 200,
            headerName: 'Đầu tư thiết bị',
            valueGetter: ({ row }) => {
                return format_pricing_vnd(_.sumBy(row.warehouse_resource_metadata, o => o?.warehouse_item_price))
            },
        },
        // {
        //     field: 'warehouse_resource_metadata', 
        //     width: 350,
        //     headerName: 'Danh Sách Thiết Bị',
        //     renderCell:({ value }) => <DrawerWarehouseItem items={value}/>
        // },
        {
            field: 'racks',
            width: 350,
            headerName: 'Tủ Rack',
            valueGetter: ({ row }) => {
                const racks = _.uniq(_.map(row.warehouse_resource_metadata, o => ({
                    label: `${o?.warehouse_rack?.warehouse_rack_name} - ${_.find(warehouse_boxes, { warehouse_box_id: o.warehouse_rack?.warehouse_box_id })?.label}`,
                    value: null
                })))

                return _.uniqBy(racks, o => o.label)
            },
            renderCell: ({ value }) => (value && <CellSpecification items={(value)} />)
        },
        {
            field: 'warehouse_resource_created_at',
            width: 200,
            headerName: 'Ngày Tạo',
            valueGetter: ({ value }) => (format_date_short(value)),
        },
    ];

    const historyColumsHidden = JSON.parse(localStorage.getItem("warehouse.resource.list.hiddenColumns")) || []
    const [columns, setColumns] = React.useState(initialColumns?.map((column) => {
        if (historyColumsHidden?.includes(column?.field)) {
            return { ...column, hide: true }
        }
        return { ...column }
    }) || initialColumns)


    React.useEffect(() => {
        navigate(`?page=${urlParams?.page}&page_size=${urlParams?.page_size}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams])

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, mt: 2 }}>
                <Button variant='contained'
                    size='small'
                    sx={{
                        height: { xs: 36, md: 42 },
                        m: 'auto 0'
                    }}
                    onClick={() => {
                        navigate("/warehouse/resources/new")
                    }}
                    startIcon={<AddOutlined />}
                >
                    Thêm Tài Nguyên
                </Button>
            </Box>
            <Header />
            <DataGridCustomize
                rows={data?.warehouse_resources || []}
                columns={columns?.filter(col => !col?.hide)}
                loading={isFetching}
                getRowHeight={(params) => {
                    return getDataGridRowHeight([_.uniq(_.map(params?.model?.warehouse_resource_metadata, o => o?.warehouse_rack?.warehouse_rack_name)), [{}, {}, {}]], {})
                }}
                componentsProps={{
                    toolbar: {
                        store: "warehouse.resource.list.hiddenColumns",
                        columns: columns?.filter(col => !col?.hideable),
                        setColumns: setColumns,
                        initialColumns: initialColumns,
                        feature: 'warehouse-resource',
                        actions: [

                        ],
                        // inputSearch: {
                        //     placeholder: "Nhập Service Tag / Serial Number, tên thiết bị, IDC,Rack tìm kiếm...",
                        //     value: searchQuery,
                        //     onChange: setSearchQuery
                        // }
                    },
                    pagination: {
                        page: urlParams?.page - 1 || 0,
                        pageSize: urlParams?.page_size || 20,
                        rowsPerPageOptions: [10, 20, 50, 100],
                        onPageChange: (newPage) => {
                            setUrlParams(prevState => ({ ...prevState, page: newPage + 1 }))
                        },
                        onPageSizeChange: (newPageSize) => setUrlParams(prevState => ({ ...prevState, page_size: newPageSize, page: 1 }))
                    }
                }}
                rowCount={data.pagination ? data?.pagination?.total : 0}
            />
        </Box>
    )
}
export default withSuspense(withPermission(List, {
    feature: "warehouse",
    action: "resource"
}), MuiSkeleton["DataGrid"]) 