import TicketStatusApi from "@/apis/beta/Ticket/TicketStatusApi";
import HeaderTitle from "@components/Title/HeaderTitle";
import BreadCrumb from "@components/shared/BreadCrumb";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Column from "./Column";
import Loading from "@components/shared/MuiSkeleton/Loading";
import TicketApi from "@/apis/beta/Ticket/TicketApi";

const Board = () => {
  const { data, isLoading } = TicketStatusApi.ListTicketStatus();
  const { data: tickets, isLoading: tickets_loading } = TicketApi.All();

  const [rebuildDataTicket, setRebuildDataTicket] = useState([]);

  useEffect(() => {
    setRebuildDataTicket(
      data?.reduce((final, item) => {
        if (!item?.ticket_status_order_ticket) {
          final.push(
            ...(tickets?.tickets?.filter(
              (t) =>
                t?.ticket_status?.ticket_status_id === item?.ticket_status_id
            ) || [])
          );
        } else {
          const sortedTickets = [];

          item?.ticket_status_order_ticket?.forEach((p) => {
            const find = tickets?.tickets?.find(
              (t) =>
                t?.ticket_id === p &&
                item?.ticket_status_id === t?.ticket_status?.ticket_status_id
            );
            if (find) sortedTickets.push(find);
          });

          final.push(
            ...sortedTickets,
            ...(tickets?.tickets?.filter(
              (t) =>
                item?.ticket_status_id === t?.ticket_status?.ticket_status_id &&
                !item?.ticket_status_order_ticket?.includes(t?.ticket_id)
            ) || [])
          );
        }

        return final;
      }, [])
    );
  }, [tickets, data]);

  if (isLoading || tickets_loading) {
    return <Loading />;
  }

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <HeaderTitle>Ticket Board</HeaderTitle>
      </Box>

      <BreadCrumb
        data={[
          { label: "Ticket Manager" },
          { label: "Ticket Board", reactLink: "/board" },
        ]}
      />

      <Box
        mt={2}
        display="flex"
        gap={2}
        sx={{
          width: "100%",
          maxWidth: "100%",
          overflow: "hidden",
          overflowX: "auto",
        }}
      >
        {data?.map((item) => (
          <Column
            key={item?.ticket_status_id}
            column={item}
            tickets={rebuildDataTicket}
            setTickets={setRebuildDataTicket}
            columns={data}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Board;
