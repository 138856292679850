import MuiDateTimePicker from "@components/shared/MuiDateTimePicker";
import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { ticketBuilderTitle } from "../functions";
import { useMemo } from "react";

const TicketTimeRequest = ({ ticket_request_id = 2 }) => {
    const { control } = useFormContext()
    const label = useMemo(() => ticketBuilderTitle('ticket_time_request', ticket_request_id), [ticket_request_id])
    return (
        <Controller
            name="ticket_time_request"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
                <FormControl fullWidth>
                    <MuiDateTimePicker
                        {...field}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label={label}
                    />
                </FormControl>
            )}
        />
    )
}

export default TicketTimeRequest