import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import Form from "../Shared/Form";
import React from "react";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const New = () => {
  return <Form />;
};

export default withSuspense(
  withPermission(New, { feature: "vcard", action: "create" }),
  MuiSkeleton["GridInformation"]
);
