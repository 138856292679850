import ThresholdApi from "@/apis/beta/Threshold/ThresholdApi";
import {
  SUBSCRIBER_SCOPE,
  THRESHOLD_SCOPE,
  THRESHOLD_STATUS,
} from "@/libs/constants";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddSubscriber({
  open,
  handleClose,
  values,
  refetch,
  curentChannelId,
  handleResetState,
}) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      subscriber_name: values?.subscriber_name || "",
      subscriber_description: values?.subscriber_description || "",
      subscriber_status:
        values?.subscriber_status || THRESHOLD_STATUS?.[0]?.value,
      subscriber_scope:
        values?.subscriber_scope || SUBSCRIBER_SCOPE?.[0]?.value,
      subscriber_bind: values?.subscriber_bind || THRESHOLD_SCOPE?.[0]?.value,
      subscriber_key: values?.subscriber_key || "",
      metadata:
        (typeof values?.metadata === "object"
          ? JSON.stringify(values?.metadata)
          : values?.metadata) || "",
    },
  });

  const { mutateAsync, isLoading } = useMutation(ThresholdApi.AddSubscriber, {
    onError: () => {
      setNotification({
        open: true,
        message: "Some thing went wrong",
        severity: "error",
      });
    },
  });

  const {
    mutateAsync: EditSubscriberMutation,
    isLoading: EditSubscriberLoading,
  } = useMutation(ThresholdApi.EditSubscriber, {
    onError: () => {
      setNotification({
        open: true,
        message: "Some thing went wrong",
        severity: "error",
      });
    },
  });

  const { setNotification } = useNotificationContext();

  const onSubmit = async (formValues) => {
    const newValues = {...formValues, metadata: JSON.parse(formValues?.metadata)}

    let response;

    if (!values) {
      response = await mutateAsync({
        ...newValues,
        channel_id: curentChannelId,
      });
    } else {
      response = await EditSubscriberMutation({
        ...newValues,
        subscriber_id: values?.subscriber_id,
      });
    }

    if (response?.errors) {
      return setNotification({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }

    setNotification({
      open: true,
      message: "Success",
      severity: "success",
    });

    handleResetState();

    handleClose();

    refetch();
  };

  const isFetching = isLoading || EditSubscriberLoading;
  const action = values ? "Edit" : "Add";

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{action} Subscribers</DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <Controller
                name="subscriber_name"
                control={control}
                rules={{
                  required: {
                    message: "subscriber_name is required",
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Subscriber Name"
                    error={!!errors.subscriber_name}
                    helperText={errors.subscriber_name?.message}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%" }}
                  />
                )}
              />

              <Controller
                name="subscriber_description"
                control={control}
                rules={{
                  required: {
                    message: "subscriber_description is required",
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Subscriber Description"
                    error={!!errors.subscriber_description}
                    helperText={errors.subscriber_description?.message}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%", mt: 2 }}
                  />
                )}
              />

              <Controller
                name="subscriber_key"
                control={control}
                rules={{
                  required: {
                    message: "subscriber_key is required",
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Subscriber Key"
                    error={!!errors.subscriber_key}
                    helperText={errors.subscriber_key?.message}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%", mt: 2 }}
                  />
                )}
              />

              <Controller
                name="metadata"
                control={control}
                rules={{
                  required: { message: "metadata is required", value: true },
                }}
                render={({ field: { onChange, ...field } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        multiline
                        {...field}
                        sx={{ mt: 2 }}
                        placeholder="Metadata"
                        minRows={3}
                        onChange={(e) => {
                          try {
                            JSON.parse(e?.target?.value?.trim());
                            clearErrors("metadata");
                          } catch (error) {
                            setError("metadata", {
                              message: "metadata is in valid json format",
                            });
                          }

                          onChange(e?.target?.value);
                        }}
                      />
                      {errors?.metadata?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.metadata?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
              />

              <Box mt={2}>
                <Typography fontSize={14}>Subscriber Status</Typography>
                <Controller
                  name="subscriber_status"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        error={!!errors.subscriber_status}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                        fullWidth
                      >
                        {THRESHOLD_STATUS?.map((item) => (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.subscriber_status?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.subscriber_status?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Typography fontSize={14}>Subscriber Scope</Typography>
                <Controller
                  name="subscriber_scope"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        error={!!errors.subscriber_scope}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                        fullWidth
                      >
                        {SUBSCRIBER_SCOPE?.map((item) => (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.subscriber_scope?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.subscriber_scope?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Typography fontSize={14}>Subscriber Bind</Typography>
                <Controller
                  name="subscriber_bind"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        error={!!errors.subscriber_bind}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                        fullWidth
                      >
                        {THRESHOLD_SCOPE?.map((item) => (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.subscriber_bind?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.subscriber_bind?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="outlined" onClick={handleClose}>
              Huỷ
            </Button>
            <Button size="small" disabled={isFetching} variant="contained" type="submit">
              {isFetching ? <CircularProgress size={25} /> : action}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

export default AddSubscriber;
