import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class TicketTypeApi {
  ListTicketType = () => {
    return useQueryWithCustomized(["ticket_types.all"], async () => {
      const response = await requestWithToken(
        `${API_V3_URL}/ticket_types/list`
      );
      if (response?.data?.data) {
        return response?.data?.data?.ticket_types;
      }
      return response?.data;
    });
  };

  CreateTicketType = async (ticket_type_title) => {
    const response = await requestWithToken(
      `${API_V3_URL}/ticket_types/create_ticket_type`,
      "POST",
      { ticket_type_title }
    );

    return response?.data
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TicketTypeApi()
