import React, { createContext, useContext } from "react";
import StateArrayContextProvider from "./stateArray";

const CustomerContext = createContext();

const CustomerContextProvider = ({ children }) => {

    const onChangeQueryString = (newString = '') => {
        return setCustomerState(prevState => ({
            ...prevState,
            queryString: newString
        }))
    }

    const onChangeQuery = (newString = '') => {
        return setCustomerState(prevState => ({
            ...prevState,
            queryContext: newString
        }))
    }

    const [customerState, setCustomerState] = React.useState({
        queryString: '',
        onChangeQueryString: onChangeQueryString,
        queryContext: '',
        onChangeQuery
    })

    return (
        <CustomerContext.Provider value={{ ...customerState }}>
            <StateArrayContextProvider>
                {children}
            </StateArrayContextProvider>
        </CustomerContext.Provider>
    );
};

export const useCustomerContext = () => {
    return useContext(CustomerContext)
}

export default CustomerContextProvider;
