import CompanyApi from '@/apis/beta/Company/CompanyApi'
import DatePicker from "@/components/DatePicker"
import ErrorHelperText from '@/components/shared/ErrorHelperText'
import SingleSelectCustomize from '@/components/shared/SingleSelectCustomize'
import { useNotificationContext } from "@/contexts/notification"
import { sleep } from "@/functions"
import withSuspense from '@/hocs/withSuspense'
import { INDUSTRIES } from '@/libs/constants'
import { companyValidator } from '@/libs/validators'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { vestResolver } from '@hookform/resolvers/vest'
import { Box, Button, CircularProgress, FormControl, Grid, Paper, TextField, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'

const CompanyForm = ({
    type,
    data,
    callback,
    handleClose
}) => {
    const { setNotification } = useNotificationContext();
    const [submit, setSubmit] = React.useState(false)

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: vestResolver((data) => companyValidator({
            ...data,
            type: type,
            company_name_old: data?.company_name,
            company_tax_old: data?.company_tax_old
        })),
        defaultValues: {
            company_name: data?.company_name || "",
            company_tax: data?.company_tax || "",
            company_website: data?.company_website || "",
            company_established_date: moment(data?.company_established_date),
            company_industry: data?.company_industry,
            company_address: data?.company_address || "",
        }
    })

    const onSubmit = async (values) => {
        setSubmit(true)
        const response = type === 'create' ?
            await CompanyApi.Create({ ...values, customer_id: [data?.customerId] })
            : await CompanyApi.Update(values, data?.company_id)
        await sleep(500)
        setSubmit(false)
        if (!response || (response.status !== 200 && response.status !== 201)) {
            return setNotification({ open: true, message: response?.errors[0]?.message, severity: "error" })
        }
        setNotification({
            open: true,
            message: `Cập nhật thông tin công ty khách hàng thành công.`,
            severity: "success"
        })
        try {
            localStorage.removeItem(`company.data.create`)
            callback()
        } catch (error) { alert("Company Create Exception: " + error?.message) }
        return handleClose()
    }
    return (
        <Paper sx={{ width: '100%', height: '100%', minWidth: 360, p: 3 }} component="form" onSubmit={handleSubmit(onSubmit)}>
            <Typography sx={{ mb: 2 }}>
                {type === "update" ? "Chỉnh Sửa Thông Tin" : "Thêm Mới"} Công Ty
            </Typography>
            <Grid container spacing={2}>
                <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Mã Số Thuế *</Typography>
                    <Controller
                        control={control}
                        name="company_tax"
                        render={({ field }) =>
                            <TextField
                                {...field}
                                placeholder="Nhập mã số thuế..."
                                error={!!errors?.company_tax}
                                helperText={errors?.company_tax?.message}
                                size="small"
                                fullWidth
                            />
                        } />
                </Grid>
                <Grid item xl={9} lg={9} md={8} sm={6} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Tên Công Ty *</Typography>
                    <Controller
                        control={control}
                        name="company_name"
                        render={({ field }) =>
                            <TextField
                                {...field}
                                error={!!errors?.company_tax}
                                helperText={errors?.company_tax?.message}
                                placeholder="Nhập tên công ty..."
                                size="small"
                                fullWidth
                            />
                        } />
                </Grid>
                <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Website</Typography>
                    <Controller
                        control={control}
                        name="company_website"
                        render={({ field }) =>
                            <TextField
                                {...field}
                                placeholder="Nhập website công ty..."
                                size="small"
                                fullWidth
                            />
                        } />
                </Grid>
                <Grid item xl={9} lg={9} md={8} sm={6} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Địa Chỉ</Typography>
                    <Controller
                        control={control}
                        name="company_address"
                        render={({ field }) =>
                            <TextField
                                {...field}
                                placeholder="Nhập địa chỉ công ty..."
                                size="small"
                                fullWidth
                            />
                        } />
                </Grid>
                <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Ngày Thành Lập *</Typography>
                    <Controller
                        control={control}
                        name="company_established_date"
                        render={({ field: { value, onChange } }) =>
                            <FormControl fullWidth>
                                <DatePicker value={value} onChange={onChange} />
                                {!!errors?.company_established_date &&
                                    <ErrorHelperText message={errors?.company_established_date?.message} />}
                            </FormControl>
                        } />
                </Grid>
                <Grid item xl={9} lg={9} md={8} sm={6} xs={12}>
                    <Typography sx={{ mb: 0.5 }}>Lĩnh Vực *</Typography>
                    <Controller
                        control={control}
                        name="company_industry"
                        render={({ field: { value, onChange } }) => {
                            const selected = INDUSTRIES?.find(i => i?.value === value) || null
                            return (
                                <FormControl fullWidth>
                                    <SingleSelectCustomize
                                        value={selected}
                                        options={INDUSTRIES}
                                        input={{ placeholder: "Nhập lĩnh vực/ ngành nghề tìm kiếm..." }}
                                        onChange={(event, newValue) => onChange(newValue?.value || -1)}
                                    />
                                    {!!errors?.company_industry && <ErrorHelperText message={errors?.company_industry?.message} />}
                                </FormControl>
                            )
                        }} />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button
                    size='small'
                    variant='text'
                    disabled={submit}
                    onClick={handleClose}
                    sx={{ height: 36 }}>Đóng</Button>
                <Button
                    size='small'
                    variant='outlined'
                    type='submit'
                    disabled={submit}
                    startIcon={submit && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                    sx={{ height: 36, ml: 2 }}>
                    {type === "update" ?
                        "Cập Nhật" : "Thêm Mới"}</Button>
            </Box>
        </Paper>
    )
}

export default withSuspense(CompanyForm, MuiSkeleton["PaperInformation"])