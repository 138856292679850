import TicketApi from "@/apis/beta/Ticket/TicketApi";
import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useInfiniteQuery } from "react-query";
import { useParams } from "react-router";
import { InView } from "react-intersection-observer";
import AttactmentItem from "./AttactmentItem";

const Attachments = () => {
  const { id } = useParams();

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      [`ticket.attachments.${id}`],
      (pageParams) => {
        return TicketApi.Attachments({
          ticket_id: id,
          pageSize: 10,
          page: pageParams.pageParam || 1,
        });
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage?.pagination?.page_next;
        },
      }
    );

  if (isLoading)
    return (
      <Box p={2}>
        <CircularProgress />
      </Box>
    );

  if (data?.pages?.[0]?.ticket_attachements?.length === 0)
    return <Typography sx={{ fontSize: 14, p: '16px 0' }} color="text.grey">Chưa có Attachment nào</Typography>;

  return (
    <Box
      maxHeight={500}
      overflow={"auto"}
      display="flex"
      flexDirection="column"
      gap={1}
    >
      {data?.pages
        ?.reduce((final, item) => {
          final.push(...item?.ticket_attachements);
          return final;
        }, [])
        ?.map((item) => (
          <AttactmentItem key={item?.ticket_attactment_id} item={item} />
        ))}

      <InView
        fallbackInView
        onChange={(InVidew) => {
          if (InVidew && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
          }
        }}
      >
        {({ ref }) => (
          <Box
            ref={ref}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ pb: 1 }}
          >
            {isFetchingNextPage && (
              <CircularProgress size={25} sx={{ mt: 2 }} />
            )}
          </Box>
        )}
      </InView>
    </Box>
  );
};

export default Attachments;
