import DataGrid from "@/components/DataGrid";
import { Box } from "@mui/material";
import clsx from "clsx";
import { Fragment, useMemo } from "react";
import VNISLibs from "../functions";
import ServiceActivities from "../../shared/Service/ServiceActivities";
import ServiceManager from "./ServiceManager";

const VNIS = ({ service, addons }) => {

    const initialColumns = useMemo(() => {
        return [...VNISLibs.rows({ services: addons, service })]
    }, [service, addons]);

    return (
        <Fragment>
            <ServiceActivities activities={VNISLibs.activities} service={service} module={VNISLibs?.module} />
            <Box sx={{ mt: "10px" }}>
                <DataGrid
                    initialState={{
                        pinnedColumns: { right: ["actions"] },
                    }}
                    rows={
                        initialColumns?.filter((service) => !service?.hide)?.map((service, index) => ({
                            ...service,
                            id: index + 1,
                        })) || []
                    }
                    columns={VNISLibs?.columns}
                    components={{
                        Toolbar: "disabled",
                        Pagination: "disabled",
                    }}
                    disableSelectionOnClick
                    disableColumnFilter
                    getRowClassName={({ row }) => {
                        return clsx(`grid-dc-vnetwork`, row?.classNames || "");
                    }}
                />
            </Box>
            <ServiceManager service={service} addons={addons} />
        </Fragment>

    );
};
export default VNIS;