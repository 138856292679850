import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const UpdateHoliday = () => {
    const { holiday_id } = useParams();
    const { control, handleSubmit, setValue } = useForm();
    const [initialData, setInitialData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchHolidayData = async () => {
            try {
                const response = await WorkDayApi.detailHoliday(holiday_id);
                const { holiday_date, holiday_name, holiday_work_n_day } = response.data.holidays;

                setValue("data[0].holiday_date", holiday_date);
                setValue("data[0].holiday_name", holiday_name);
                setValue("data[0].holiday_work_n_day", holiday_work_n_day);

                setInitialData({
                    holiday_date: dayjs(holiday_date),
                    holiday_name,
                    holiday_work_n_day,
                });
            } catch (error) {
                console.error("Error fetching holiday data:", error);
            }
        };

        if (holiday_id) {
            fetchHolidayData();
        }
    }, [holiday_id, setValue]);

    const { mutateAsync, notify } = useMutationWithNotification(
        WorkDayApi.UpdateHoliday
    );

    const updateHoliday = async (values) => {
        try {
            const formData = values.data[0];
            // console.log("formData", formData)
            const updatedHolidayData = {
                holiday: {
                    holiday_date: formData?.holiday_date,
                    holiday_name: formData?.holiday_name,
                    holiday_work_n_day: formData?.holiday_work_n_day,
                },
            };
            // console.log("updatedHolidayData", updatedHolidayData)
            const response = await mutateAsync({ holidayId: holiday_id, updatedHolidayData });
            // console.log("response", response)
            if (response && !response.errors) {
                notify("success", "Cập nhật thành công");
                navigate("/workdays/holiday");
            } else {
                notify(
                    "error",
                    response?.errors?.[0]?.message || "Something went wrong"
                );
            }
        } catch (error) {
            console.error("Error updating holiday:", error);
            notify("error", "Something went wrong");
        }
    };

    useEffect(() => {
        if (initialData) {
            setValue("data[0].holiday_work_n_day", initialData.holiday_work_n_day);
        }
    }, [initialData, setValue]);

    return (
        <Box>
            <Box component="form" onSubmit={handleSubmit(updateHoliday)} p={2}>
                <Typography
                    sx={{
                        fontSize: { xs: 18, md: 24 },
                        fontWeight: "medium",
                    }}
                >
                    Sửa Ngày Nghỉ
                </Typography>

                <Paper sx={{ mt: 2, p: 2 }}>
                    <Typography fontSize={14}>Chọn ngày nghỉ</Typography>

                    <Box mt={2}>
                        {initialData && (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box display="flex" alignItems="center" mb={2} flexWrap="wrap">
                                    <Controller
                                        control={control}
                                        name={`data[0].holiday_date`}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        sx={{ mr: 2, flex: 1 }}
                                                        label="Ngày nghỉ"
                                                    />
                                                )}
                                                inputFormat="DD/MM/YYYY"
                                            />
                                        )}
                                    />

                                    <Tabs
                                        value={initialData.holiday_work_n_day}
                                        onChange={(event, value) => {
                                            setValue("data[0].holiday_work_n_day", value);
                                            setInitialData(prevState => ({
                                                ...prevState,
                                                holiday_work_n_day: value
                                            }));
                                        }}
                                        variant="standard"
                                        scrollButtons="auto"
                                        sx={{ mr: 3, flex: 1 }}
                                    >
                                        <Tab label="Buổi sáng" value={0} />
                                        <Tab label="Buổi chiều" value={1} />
                                        <Tab label="Cả Ngày" value={2} />
                                    </Tabs>

                                    <Controller
                                        control={control}
                                        name={`data[0].holiday_name`}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                size="small"
                                                label="Ghi chú"
                                                sx={{ mr: 2, flex: 1 }}
                                            />
                                        )}
                                    />

                                </Box>
                            </LocalizationProvider>
                        )}
                    </Box>

                    <Box mt={4}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Cập Nhật
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default withSuspense(
    withPermission(UpdateHoliday, {
        feature: "workdays",
        action: "update-holiday",
    }),
    MuiSkeleton["GridInformation"]
);

