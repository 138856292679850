import InvoiceApi from '@/apis/beta/Invoice/InvoiceApi'
import ItemPaperStatistical from '@/components/shared/ItemPaperStatistical'
import { buildQueryFilterCondition, format_date_short, format_numeral_price } from '@/functions'
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import DateRangeCustomize from '@components/datepickers/DateRangeCustomize'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Box, Collapse, FormControl, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import moment from 'moment'
import React from 'react'

const PRIVATE_OPTIONS = [
    { value: "this-month", label: "Tháng hiện tại" },
    { value: "this-quaterly", label: "Quý hiện tại" },
    { value: "this-year", label: "Năm hiện tại" },
    { value: "last-30-days", label: "Last 30days" },
    { value: "customize", label: "Tuỳ chỉnh" },
    { value: "use-query", label: "Theo bộ tìm kiếm" },
    { value: "un-query", label: "Tất cả thời gian" }
]
const Statistical = ({
    query,
    searchQuery
}) => {
    const [today] = React.useState(dayjs())

    const [state, setState] = React.useState({
        choice: 'use-query',
        startdate: today.startOf('m'),
        enddate: today?.endOf('m')
    })
    const queryCallback = React.useCallback(() => {
        let queries = []
        switch (state?.choice) {
            case "this-quaterly":
                const startOfQuaterly = moment().quarter(moment().quarter()).startOf('quarter');
                const endOfQuaterly = moment().quarter(moment().quarter()).endOf('quarter');
                console.log(startOfQuaterly, endOfQuaterly)

                queries = [
                    {
                        name: 'invoices.invoice_created_at',
                        operator: "$gte",
                        value: format_date_short(startOfQuaterly)
                    }, {
                        name: 'invoices.invoice_created_at',
                        operator: "$lte",
                        value: format_date_short(endOfQuaterly)
                    }
                ]
                break;
            case "use-query": return query;
            case "this-month":
                queries = [
                    {
                        name: 'invoices.invoice_created_at',
                        operator: "$gte",
                        value: format_date_short(moment().startOf("month"))
                    }, {
                        name: 'invoices.invoice_created_at',
                        operator: "$lte",
                        value: format_date_short(moment().endOf("month"))
                    }
                ]
                break;
            case "this-year":
                const startofYear = format_date_short(today?.startOf("year"))
                const endofYear = format_date_short(today?.endOf("year"))
                queries = [
                    {
                        name: 'invoices.invoice_created_at',
                        operator: "$gte",
                        value: startofYear
                    }, {
                        name: 'invoices.invoice_created_at',
                        operator: "$lte",
                        value: endofYear
                    }
                ]
                break;
            case "last-30-days":
                const startdate = moment().add(-1, "month").add(1, 'd')
                const enddate = moment()
                queries = [
                    {
                        name: 'invoices.invoice_created_at',
                        operator: "$gte",
                        value: format_date_short(startdate)
                    }, {
                        name: 'invoices.invoice_created_at',
                        operator: "$lte",
                        value: format_date_short(enddate)
                    }
                ]
                break;
            case "customize":
                console.log(state)
                queries = [
                    {
                        name: 'invoices.invoice_created_at',
                        operator: "$gte",
                        value: format_date_short(state?.startdate?.$d)
                    }, {
                        name: 'invoices.invoice_created_at',
                        operator: "$lte",
                        value: format_date_short(state?.enddate?.$d)
                    }
                ]
                break;
            case "un-query": return ``;
            default: return ``;
        }
        return buildQueryFilterCondition("$and", 0, queries)
    }, [state, today, query])

    const { data } = InvoiceApi.ReportFinance({
        query: queryCallback(),
        searchQuery: state?.choice === `use-query` ? searchQuery : null,
    });

    const formatToArray = () => {
        return [
            { label: "Tổng Hoá Đơn", value: format_numeral_price(data?.total), endText: true },
            { label: "Đã Thanh Toán", value: format_numeral_price(data?.paid), endText: true },
            { label: "Công Nợ", value: format_numeral_price(data?.unpaid), endText: true },
            { label: "Đã Huỷ", value: format_numeral_price(data?.cancelled), endText: true },
        ]
    }
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={3}>
                <Typography sx={{
                    fontWeight: 'medium',
                    fontSize: {
                        xs: 18,
                        md: 24
                    }
                }}>
                    Hoá Đơn
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'flex-end',
                    flexDirection: {
                        xs: 'column-reverse', md: 'row'
                    }
                }}>
                    <FormControl fullWidth sx={{
                        zIndex: 9,
                        maxWidth: {
                            xs: '100%',
                            md: `calc((100% - 16px * 2) / 3 * 2 + 16px)`
                        }
                    }}>
                        <Collapse
                            in={state?.choice === "customize"}
                            sx={{
                                ".MuiStack-root": {
                                    display: "flex",
                                    flexDirection: { xs: "column", md: 'row' },
                                    gap: { xs: 1.5, md: 2 },
                                    alignItems: { xs: "unset", md: 'baseline' },
                                    ".MuiTypography-root": {
                                        display: "none",
                                    },
                                    ".MuiFormControl-root": {
                                        m: "0",
                                        ".MuiInputBase-root": {
                                            height: 42
                                        }
                                    }
                                }
                            }}
                        >
                            <DateRangeCustomize value={[state?.startdate, state?.enddate]}
                                handler={(newValue = []) => {
                                    return setState((prev) => ({
                                        ...prev,
                                        startdate: newValue[0],
                                        enddate: newValue[1]
                                    }))
                                }}
                            />
                        </Collapse>
                    </FormControl>
                    <FormControl fullWidth sx={{
                        zIndex: 9,
                        maxWidth: {
                            xs: '100%',
                            md: `calc((100% - 16px * 2) / 3)`
                        }
                    }}>
                        <SingleSelectCustomize
                            options={PRIVATE_OPTIONS}
                            value={PRIVATE_OPTIONS?.find(x => x.value === state?.choice)}
                            onChange={(event, newValue) => setState(prev => ({ ...prev, choice: newValue?.value }))}
                            input={{
                                label: "Thống kê hoá đơn",
                                placeholder: "Xem thống kê..."
                            }}
                        />
                    </FormControl>
                </Box>
            </Grid>
            {formatToArray().map((item, index) =>
                <Grid item xs={12} sm={6} md={3} key={index} sx={{ zIndex: 1 }}>
                    <ItemPaperStatistical {...item} />
                </Grid>
            )}
        </Grid>
    )
}

export default withSuspense(withPermission(Statistical, { feature: "invoice", action: "statistic", type: "Block" }), MuiSkeleton["Statistical"])