import List from "./List";
import Detail from "./Detail";
import Board from "./Board";
import Create from "./Create";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  List,
  Detail,
  Board,
  Create,
};
