import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import ReactJson from "react-json-view";
const DomainInfo = ({domain}) => {
    const isJSON = (object) => {
        if (typeof object !== "object") return false;

        try {
        JSON.parse(object);
        return true;
        } catch (error) {
        return false;
        }
    };
    const renderTextValue = (obj) => {
        return isJSON(obj) ? (
        <ReactJson collapsed src={JSON.parse(obj)} />
        ) : typeof obj === "object" ? (
        <ReactJson collapsed src={obj} />
        ) : (
        obj
        );
    };
    return (
        <>
           {
            domain && 
                <Paper sx={{ p: 3, mb: 1 }}>
                <Grid container spacing={2}>
                    {Object?.keys(domain)
                    ?.filter((item) => domain?.[item])
                    ?.map((item) => (
                        <Grid key={item} item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <Typography sx={{ mb: 0.5, color: "#777680" }}>
                            {item
                            ?.split("_")
                            ?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))
                            .join(" ")}
                            :
                        </Typography>
                        <Typography sx={{ color: "#00000F" }}>
                            {renderTextValue(domain?.[item])}
                        </Typography>
                        </Grid>
                    ))}
                </Grid>   
            </Paper>
            
           }
        </>
    );
};

export default DomainInfo;
