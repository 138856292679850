import { Box, CircularProgress } from "@mui/material";
import React from "react";
import Header from "../Header";
import TableWarehouseItemAccessory from "../TableWarehouseItemAccessory";


const Devices = () => {
    return(
        <Box>
            <Header/>
            <React.Suspense fallback={<CircularProgress size={24} sx={{ m: 'auto' }} />}>
            <TableWarehouseItemAccessory key={"device"} type={"device"} />
            </React.Suspense>
        </Box>
    )
}
export default Devices;