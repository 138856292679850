import { Paper, Typography } from "@mui/material";
import React from "react";

const BoxStatistical = ({ title, value }) => {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography fontWeight={600} color="#6b7280">
        {title}
      </Typography>
      <Typography variant="h4" fontWeight={600} sx={{ mt: 2 }}>
        {value ? (Number(value) < 0 ? 0 : value) : 0}
      </Typography>
    </Paper>
  );
};

export default BoxStatistical;
