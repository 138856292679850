import CompanyApi from "@/apis/beta/Company/CompanyApi";
import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { useEmployeeContext } from "@/contexts/employee";
import { useNotificationContext } from "@/contexts/notification";
import {
  isObjEmpty,
  queryClientRefetcher,
  selectObjectKeys,
  sleep,
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useOnBeforeUnload from "@/hooks/useOnBeforeUnload";
import { customerValidatorByStep } from "@/libs/validators";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import BasicInfomation from "./BasicInformation";
import CustomerDetailInformation from "./CustomerInformation";
import ErrorMessage from "./ErrorMessage";
import Explanation from "./Explanation";
import Header from "./Header";

const DEFAULT_CUSTOMER_DATA = {
  customer_type: "individual",
  customer_email: "",
  customer_name: "",
  customer_phone: "",
  customer_position: "",
  customer_dob: moment(),
  customer_note: "",
  customer_industry: -1,
  customer_industry_field: -1,
  customer_service_interestings: [],
  customer_source: "event",
  customer_source_event: "",
  customer_website: "",
  customer_contact_social: [
    {
      contact_channel: "",
      contact_channel_information: "",
    },
  ],
  customer_service_information: {
    customer_problem_explaination: "",
    customer_budget_explaination: 0,
    customer_expected_explaination: "",
    customer_provider_explaination: "",
    customer_expected_date: moment(),
    customer_influencer_explaination: "",
  },
};

const Create = () => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { employee } = useEmployeeContext();

  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);
  const [defaultSteps] = React.useState([
    { label: "Thông Tin Người Liên Hệ & Doanh Nghiệp" },
    { label: "Thông Tin Người Liên Hệ" },
    { label: "Thông Tin Khai Thác" },
  ]);
  const [openDialog, setOpenDialog] = React.useState({
    open:
      localStorage.getItem("customer.data.create") !== null ||
      localStorage.getItem("customer.data.create") === "{}",
  });
  const methods = useForm({
    resolver: vestResolver(customerValidatorByStep),
    defaultValues: {
      currentStep: 1,
      customer: {
        ...DEFAULT_CUSTOMER_DATA,
        customer_cs_followers: [employee?.employee_id],
        customer_sale_followers: [employee?.employee_id],
      },
      customer_service_information: {
        customer_problem_explaination: "",
        customer_budget_explaination: 0,
        customer_expected_explaination: "",
        customer_provider_explaination: "",
        customer_expected_date: moment(),
        customer_influencer_explaination: "",
      },
      company: {
        company_id: -1,
        company_name: "",
        company_address: "",
        company_tax: "",
      },
    },
  });
  const [steps, setSteps] = React.useState(defaultSteps);

  React.useEffect(() => {
    return setSteps(defaultSteps);
  }, [defaultSteps]);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = async () => {
    setSubmit(true);
    await sleep(300);
    try {
      handleSaveChange();
      methods.setValue("currentStep", methods.watch("currentStep") + 1);
      setActiveStep((prevStep) => prevStep + 1);
    } catch (error) {
      error?.console.log(error?.message);
    }
    setSubmit(false);
  };
  const handleBack = () => {
    methods.setValue("currentStep", methods.watch("currentStep") - 1);
    return setActiveStep((prevStep) => prevStep - 1);
  };

  const handleChangeStep = (nextStep) => {
    handleSaveChange();
    methods.setValue("currentStep", nextStep + 1);
    return setActiveStep(nextStep);
  };

  console.log(methods.formState.errors);
  const onSubmit = async (values) => {
    setSubmit(true);
    let messageErrors = [];
    let form = { ...values };
    localStorage.setItem(
      "customer.data.create",
      JSON.stringify(values) || `{}`
    );
    let companyId = values?.company?.company_id;
    if (values?.customer?.customer_type === "company") {
      if (companyId === -1) {
        // handle create new company
        try {
          let companyForm = selectObjectKeys(values?.company, [
            "company_address",
            "company_name",
          ]);
          const responseCreateCompany = await CompanyApi.Create({
            ...companyForm,
            company_tax: values?.company?.company_tax || "",
            company_website: values?.customer?.customer_website,
            company_industry: values?.customer?.customer_industry,
            company_industry_detail: values?.customer?.customer_industry_field,
          });
          if (
            !responseCreateCompany ||
            responseCreateCompany?.status !== 201 ||
            responseCreateCompany?.errors
          ) {
            localStorage.setItem(
              "company.data.create",
              JSON.stringify(companyForm)
            );
            messageErrors?.push({
              name: "company",
              message: "Tạo mới công ty thất bại.",
              data: companyForm,
            });
          } else {
            companyId = responseCreateCompany?.company?.company_id || -1;
            localStorage.setItem(
              "customer.data.create",
              JSON.stringify({
                ...form,
                company: {
                  ...form?.company,
                  company_id: companyId,
                },
              }) || `{}`
            );
          }
        } catch (error) {
          console.log("error when create company", error?.message);
        }
      }
    }
    let dataSubmit = selectObjectKeys(form?.customer, [
      "customer_name",
      "customer_phone",
      "customer_email",
      "customer_industry",
      "customer_industry_field",
      "customer_website",
      "customer_position",
      "customer_type",
      "customer_source",
      "customer_note",
      "customer_source_event",
      "customer_sale_followers",
      "customer_cs_followers",
      "customer_service_interestings",
    ]);

    dataSubmit = {
      ...dataSubmit,
      customer_status: "qualified-prospects",
      company_id: companyId,
      customer_contact_social: values?.customer?.customer_contact_social,
      customer_service_information: values?.customer_service_information,
    };

    dataSubmit = {
      ...dataSubmit,
    };
    const response = await CustomerApi.Create(dataSubmit);
    await sleep(500);
    setSubmit(false);
    if (!response || response?.errors) {
      messageErrors?.push({
        action: "create-customer",
        message:
          response?.errors?.[0]?.message || "Thêm mới khách hàng thất bại.",
      });
      return setNotification({
        open: true,
        message: messageErrors?.map((m) => m?.message).join(" & "),
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: "Thêm mới khách hàng thành công.",
      severity: "success",
    });
    queryClientRefetcher(queryClient, [
      "customers.list",
      "customers.statistical",
      "customers.statistics",
      "customers.all",
      "customer.customer-journeys",
      "company.detail",
      "companies.list",
      "contact.list",
      "contacts.statistic",
    ]);
    try {
      localStorage.removeItem("customer.data.create");
    } catch (error) {
      console.log("debug=[error-on-create-customer]:", error?.message);
    }
    if (messageErrors?.length > 0) {
      try {
        let dataCompany = JSON.parse(
          localStorage.getItem("company.data.create") || `{}`
        );
        if (!!dataCompany && !isObjEmpty(dataCompany)) {
          localStorage.setItem(
            "company.data.create",
            JSON.stringify({
              ...dataCompany,
              customerId: response?.customer?.customer_id,
            })
          );
        }
      } catch (error) {
        console.log(
          "debug:[error-on-retry-create-company-failed]:",
          error?.message
        );
      }
      return (
        <ErrorMessage
          data={messageErrors}
          customerId={response?.customer?.customer_id}
        />
      );
    }
    return navigate(`/customers/${response?.customer?.customer_id}`, {
      replace: true,
    });
  };
  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <CustomerDetailInformation employee={employee} />;
      case 1:
        return <BasicInfomation />;
      case 2:
        return <Explanation />;
      default:
        throw new Error();
    }
  };
  const handleReset = () => {
    const customer = localStorage.getItem("customer.data.create");
    try {
      let dataReset = JSON.parse(customer);
      methods.setValue("company", { ...dataReset?.company });
      methods.setValue("customer", { ...dataReset?.customer });
      methods.setValue("customer_service_information", {
        ...dataReset?.customer_service_information,
      });
      methods.setValue("currentStep", 1);
    } catch (err) {
      console.log(err?.message);
    }
    setOpenDialog({ open: false });
  };

  const handleSaveChange = (event) => {
    let dataSave = { ...methods.getValues() };
    dataSave = { ...dataSave, currentStep: activeStep + 1 };
    // collect data to savechange on localstorage
    try {
      localStorage.setItem(
        "customer.data.create",
        JSON.stringify(dataSave) || `{}`
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  useOnBeforeUnload(methods.formState.isDirty ? handleSaveChange : undefined);

  return (
    <Box>
      {openDialog?.open && (
        <Dialog fullWidth open={openDialog?.open}>
          <DialogTitle sx={{ m: "auto" }}>
            Đã có dữ liệu cũ, bạn có muốn khôi phục?
          </DialogTitle>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                localStorage.removeItem("customer.data.create");
                setOpenDialog({ open: false });
              }}
              sx={{ height: 36 }}
            >
              Không
            </Button>
            <Button
              variant="contained"
              onClick={handleReset}
              sx={{ height: 36, minWidth: 120 }}
            >
              Có
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Header />
      <Paper sx={{ p: 3 }}>
        <Grid container>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => {
                return (
                  <Step key={step.label}>
                    <StepLabel>
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => handleChangeStep(index)}
                        sx={{
                          color: "#000",
                          fontSize: { xs: 16, md: 18 },
                          fontWeight: "medium",
                        }}
                      >
                        {step?.label}
                      </Button>
                    </StepLabel>
                    <StepContent>
                      <FormProvider {...methods}>
                        <Box
                          component="form"
                          sx={{ width: "100%" }}
                          onSubmit={
                            index === 2
                              ? methods.handleSubmit(onSubmit)
                              : methods.handleSubmit(handleNext)
                          }
                        >
                          {getStepContent()}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: {
                                xs: "column",
                                md: "row",
                              },
                              justifyContent: "flex-end",
                              mt: 3,
                              gap: { xs: 1.5, md: 2 },
                            }}
                          >
                            {index !== 0 && (
                              <Button
                                disabled={submit}
                                variant="outlined"
                                onClick={handleBack}
                                size="small"
                                sx={{
                                  height: { xs: 36, md: 42 },
                                }}
                              >
                                Quay Lại
                              </Button>
                            )}
                            <Button
                              variant="contained"
                              type="submit"
                              size="small"
                              sx={{
                                height: { xs: 36, md: 42 },
                                minWidth: 150,
                              }}
                              disabled={submit}
                              startIcon={
                                submit && (
                                  <CircularProgress
                                    size={14}
                                    sx={{ color: "#777680" }}
                                  />
                                )
                              }
                            >
                              {index === 2
                                ? "Thêm Khách Hàng"
                                : "Lưu & Tiếp Tục"}
                            </Button>
                          </Box>
                        </Box>
                      </FormProvider>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
export default withSuspense(
  withPermission(Create, { feature: "customer", action: "create" }),
  MuiSkeleton["Loading"]
);
