import ContractApi from '@/apis/beta/Contract/ContractApi';
import DataGrid from '@/components/DataGrid';
import PopperAction from '@/components/PopperAction';
import SkeletonDataGrid from '@/components/Skeletons/DataGrid';
import { useNotificationContext } from "@/contexts/notification";
import { buildQueryFilterCondition, format_date_short } from '@/functions';
import withPermission from '@/hocs/withPermission';
import withSuspense from '@/hocs/withSuspense';
import useDebounce from '@/hooks/useDebounce';
import { AddOutlined } from '@mui/icons-material';
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import { Box, Button, Drawer, Grid, IconButton, Paper, Popper, Skeleton, Typography } from '@mui/material';
import React from 'react';
import Create from '../Create';
import Detail from '../Detail';
import Update from '../Update';
const Statistical = React.lazy(() => import("../../shared/Statistical"))

const List = () => {
    const { setNotification } = useNotificationContext()

    const [searchQuery, setSearchQuery] = React.useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 500)
    const filterableSampleContract = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === '') return '';
        const fieldQueries = [
            { name: 'contract_key', operator: "$contains", value: debouncedSearchQuery },
            { name: 'contracts.contract_number_id', operator: "$contains", value: debouncedSearchQuery },
        ]
        return buildQueryFilterCondition("$or", 0, fieldQueries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery])

    const [drawer, setDrawer] = React.useState({
        open: false,
        type: undefined,
        data: undefined
    })

    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 20
    });

    const [popper, setPopper] = React.useState({
        anchorEl: undefined,
        data: undefined
    })

    const initialColumns = [
        {
            field: 'contracts.contract_id', headerName: 'ID',
            width: 60,
            hideable: true,
            valueGetter: ({ row }) => row?.contract_id,
            operators: {
                type: 'number'
            },
            renderCell: ({ value, row }) =>
                <Typography className='react-link-table' onClick={() => {
                    if (drawer?.open) return;
                    return setDrawer({ open: true, type: 'detail', data: row })
                }}>{value}</Typography>
        }, {
            field: 'contract_number_id', headerName: 'Số Hợp Đồng Mẫu',
            width: 200,
            operators: { type: 'string' },
            renderCell: ({ value }) => value
        }, {
            field: 'contract_service', headerName: 'Dịch Vụ', width: 200,
            renderCell: ({ value }) => value?.label
        }, {
            field: 'contract_key',
            headerName: 'Từ Khoá Hợp Đồng', width: 350, filterable: true, operators: {
                type: 'string'
            },
            renderCell: ({ value }) => value || "---",
        }, {
            field: 'contract_created_at',
            headerName: 'Ngày Tạo', width: 150, filterable: true, operators: {
                type: 'date'
            },
            renderCell: ({ value }) => format_date_short(value) || "-"
        }, {
            field: 'action',
            headerName: 'Thao Tác',
            hideable: true,
            width: 80,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <IconButton
                            onClick={(event) => {
                                setPopper(prev => ({ ...prev, anchorEl: event?.target, data: row, actions: actions }))
                            }}
                            title='Hành động'
                        >
                            <MoreVertOutlined sx={{ m: 'auto' }} />
                        </IconButton>
                    </Box>
                )
            }
        }]
    const historyColumsHidden = JSON.parse(localStorage.getItem("sample-contracts.list.hiddenColumns")) || []
    const [columns, setColumns] = React.useState(initialColumns?.map((column) => {
        if (historyColumsHidden?.includes(column?.field)) {
            return { ...column, hide: true }
        }
        return { ...column }
    }) || initialColumns)

    const [actions] = React.useState([
        {
            order: 1,
            name: "open-contract-attachment",
            icon: "See",
            label: "Xem Hợp Đồng",
            handleClick: async (data) => {
                try {
                    const link  = data.link ? data.link : (data?.attachments[0]?.attachment_file_url ? `${process.env.REACT_APP_DOMAIN_FILE}${data?.attachments[0]?.attachment_file_url.replace("pub/","")}` : "#")

                    window.open(link, data.contract_id, "height=720, width=1080, left=100, top=100, resizable=yes, scrollbars=yes, toolbar=yes, menubar=no, location=no, directories=no, status=yes");
                } catch (e) {
                    console.log(e)
                }
            }
        }, {
            order: 2,
            name: "update",
            icon: "Edit",
            label: "Chỉnh Sửa",
            handleClick: (data) => {
                setDrawer(prev => ({ ...prev, open: true, data: data, type: "update" }))
            }
        }
    ])

    const handleClose = () => {
        setDrawer(prev => ({ ...prev, open: false, data: undefined, type: undefined }))
    }

    const getDrawerSampleContractModule = () => {
        switch (drawer?.type) {
            case "detail":
                if (!drawer?.data) return;
                return <Detail data={drawer?.data}
                    handleClose={handleClose}
                />
            case "create": return <Create handleClose={handleClose} callback={callbackContracts} />
            case "update": return <Update handleClose={handleClose}
                callback={callbackContracts}
                data={drawer?.data} />
            default: return setNotification({
                open: true,
                message: "Undefined your action. Please try again.",
                severity: 'error'
            })
        }
    }

    const { data, isFetching, refetch: callbackContracts } = ContractApi.List({
        page: page?.page + 1,
        pageSize: page?.pageSize,
        contractType: "SampleContract",
        query: null,
        searchQuery: filterableSampleContract() !== "" ? filterableSampleContract() : null
    });

    return (
        <Box>
            {(drawer?.open && drawer?.type !== undefined) &&
                <Drawer
                    anchor="right"
                    open={drawer?.open}
                    sx={{ width: 360, borderTopLeftRadius: '4px' }}>
                    {getDrawerSampleContractModule()}
                </Drawer>}

            {popper?.anchorEl !== undefined && <Popper anchorEl={popper?.anchorEl}
                open={Boolean(popper.anchorEl)} style={{ zIndex: 1000 }}>
                <PopperAction {...popper}
                    actions={actions}
                    handleClose={() => setPopper(prev => ({ ...prev, anchorEl: undefined, data: undefined }))} />
            </Popper>}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className="page-title" >
                    Hợp Đồng Mẫu
                </Typography>
                <Button variant="contained" sx={{ height: 42 }}
                    disabled={drawer?.open}
                    size="small"
                    onClick={() => setDrawer({
                        open: true,
                        type: 'create'
                    })}
                    startIcon={<AddOutlined />}>
                    Thêm Hợp Đồng Mẫu
                </Button>
            </Box>
            <Statistical data={[
                { label: "Số Lượng Hợp Đồng Mẫu", value: data?.report?.length }
            ]} />
            <Box sx={{ mt: 2 }}>
                <DataGrid
                    rows={data?.contracts?.map((contract) => ({
                        ...contract,
                        id: contract?.contract_id
                    })) || []}
                    columns={columns}
                    loading={isFetching}
                    componentsProps={{
                        toolbar: {
                            store: "sample-contracts.list.hiddenColumns",
                            columns: columns?.filter(col => !col?.hideable),
                            setColumns: setColumns,
                            initialColumns: initialColumns,
                            inputSearch: {
                                value: searchQuery,
                                onChange: setSearchQuery,
                                placeholder: "Nhập số hợp đồng hoặc từ khoá tìm kiếm..."
                            }
                        },
                        pagination: {
                            rowsPerPageOptions: [10, 20, 50, 100],
                            page: page?.page || 0,
                            pageSize: page?.pageSize,
                            onPageChange: (newPage) => setPage(prevState => ({ ...prevState, page: newPage })),
                            onPageSizeChange: (newPageSize) => setPage((prevState) => ({ ...prevState, pageSize: newPageSize }))
                        }
                    }}
                    rowCount={data?.pagination?.total}
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                />
            </Box>
        </Box>
    );
};

const SkeletonList = () => {
    return (
        <Box>
            <Typography className="page-title" sx={{ mb: 2 }}>
                Hợp Đồng Mẫu
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                {[1, 2].map(item =>
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={6} key={item}>
                        <Paper sx={{ p: 3 }}>
                            <Skeleton variant='text' sx={{ mb: 1, width: 180 }} />
                            <Skeleton variant='text' sx={{ width: 120, fontSize: 18 }} />
                        </Paper>
                    </Grid>
                )}
            </Grid>
            <SkeletonDataGrid filterEnabled={true} />
        </Box>
    )
}
export default withSuspense(withPermission(List, { feature: 'contract', action: 'sample-contract-index' }), SkeletonList)
