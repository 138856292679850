
import DataGrid from "@/components/DataGrid";
import { Box, Link, Typography } from "@mui/material";
// import _ from "lodash";
import DialogShowJsonData from "@components/DialogShowJsonData";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router";

export const PROCESS_ACTIONS = [
  { value: "create", label: "Tạo" },
  { value: "cancel", label: "Huỷ" },
  { value: "update", label: "Cập nhật" },
  { value: "confirm", label: "Duyệt" },
];
export const PROCESS_TYPES = [
  { value: "pn", label: "phiếu nhập" },
  { value: "pxb", label: "phiếu xuất bán" },
  { value: "px", label: "phiếu xuất mượn" },
  { value: "pdc", label: "phiếu luân chuyển" },
  // { value: "process", label: "phiếu" },
]

const ProcessesLog = (props) => {
  const navigate = useNavigate();
  const [dialogInfor, setDialogInfor] = useState({
    open: false,
    data: {},
    title: "Thông tin chi tiết"
  })
  const rows = props?.data?.warehouse_process_logs || []
  if (rows.length > 1) {
    rows.sort((a, b) => new Date(b.warehouse_process_update_created_at) - new Date(a.warehouse_process_update_created_at));
  }

  const [columns] = useState([
    {
      field: "warehouse_process_updater_id",
      headerName: "Id người thực hiện",
      width: 50,
    },
    {
      field: "warehouse_process_updater_name",
      headerName: "Tên người thực hiện",
      width: 150,
    },
    {
      field: "warehouse_process_update_type",
      headerName: "Thao tác",
      valueGetter: ({ value }) => {
        return PROCESS_ACTIONS?.find((x) => x.value === (value?.split('-')[0])?.toLowerCase())?.label + " " + PROCESS_TYPES?.find((x) => x.value === (value?.split('-')[1])?.toLowerCase())?.label
      },
      width: 250,
    },
    {
      field: "warehouse_process_id",
      headerName: "Mã phiếu",
      width: 150,
      renderCell: ({ row, value }) =>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
          }}
          onClick={() => navigate(
            `/warehouse/processes/${value}/detail`
          )}
        >
          <Link
            //   underline="hover"
            sx={{
              textDecoration: "underline",
            }}
            color="primary"
          >
            {row.warehouse_process_name || "---"}
          </Link>
        </Box>
        || "---",
    },
    {
      field: "warehouse_process_update_created_at",
      headerName: "Thời gian thực hiện",
      width: 150,
      renderCell: ({ value }) =>
        value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "---",
    },
    {
      field: "warehouse_process_update_description",
      headerName: "Mô tả",
      width: 150,
      renderCell: ({ value }) => {
        return (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
            onClick={() => setDialogInfor({ open: true, data: value })}
          >
            <Link
              //   underline="hover"
              sx={{
                textDecoration: "underline",
              }}
              color="primary"
            >
              {value || "---"}
            </Link>
          </Box>
        )
      }
    },
    {
      field: "metadata",
      headerName: "Thông tin",
      width: 100,
      renderCell: ({ value }) => {
        return (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
            onClick={() => setDialogInfor({ open: true, data: value })}
          >
            <Link
              //   underline="hover"
              sx={{
                textDecoration: "underline",
              }}
              color="primary"
            >
              Xem chi tiết
            </Link>
          </Box>
        )
      }
    },

  ]);
  const onClose = () => {
    setDialogInfor((prev) => ({ ...prev, open: false, data: {} }));
  }

  return (
    <Box sx={{ mt: 2 }}>
      <DialogShowJsonData
        open={dialogInfor.open}
        onClose={onClose}
        title={dialogInfor.title}
        metadata={dialogInfor.data}
      />
      <Typography
        sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: "medium", mb: 0.5 }}
      >
        Lịch Sử Thao Tác
      </Typography>
      <DataGrid
        columns={[...columns]}
        rows={rows?.map((item, index) => ({ ...item, id: index }))}
        components={{
          Toolbar: "disabled",
          Pagination: "disabled",
        }}
        paginationMode="client"
      />
    </Box>
  );
};

export default ProcessesLog;
