import { Grid } from "@mui/material";
import React from "react";
import BoxStatistical from "../../../Shared/Statistic/BoxStatistical";
import _ from "lodash";

const Statistic = ({ data, warehouse_items, server_ids, switch_ids }) => {
  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <BoxStatistical
          title={"Tổng Tủ Đang Sử Dụng"}
          value={_.sumBy(data?.warehouse_boxes, (row) =>
            _.sumBy(row?.warehouse_racks, (o) =>
              o.warehouse_rack_active ? 1 : 0
            )
          )}
        />
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <BoxStatistical
          title={"Số U trống"}
          value={
            _.sumBy(data?.warehouse_boxes, (row) =>
              _.sumBy(row?.warehouse_racks, (o) =>
                o.warehouse_rack_active ? o.warehouse_rack_height : 0
              )
            ) -
            _.sumBy(warehouse_items, (o) =>
              o.warehouse_box_item_reference_type === "WarehouseRack"
                ? o.warehouse_item_form_factor
                : 0
            )
          }
        />
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <BoxStatistical
          title={"Số Server Của Công Ty Lắp Đặt"}
          value={_.sumBy(warehouse_items, (o) =>
            o.warehouse_owner_name === "VNETWORK" &&
            o.warehouse_box_item_reference_type === "WarehouseRack" &&
            server_ids.includes(o.warehouse_model_id)
              ? 1
              : 0
          )}
        />
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <BoxStatistical
          title={"Số Switch Của Công Ty Lắp Đặt"}
          value={_.sumBy(warehouse_items, (o) =>
            o.warehouse_owner_name === "VNETWORK" &&
            o.warehouse_box_item_reference_type === "WarehouseRack" &&
            switch_ids.includes(o.warehouse_model_id)
              ? 1
              : 0
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Statistic;
