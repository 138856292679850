/* eslint-disable no-useless-escape */
import VcardApi from "@/apis/beta/Vcard/VcardApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import HeaderTitle from "@components/Title/HeaderTitle";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

const Form = () => {
  const params = useParams();

  const action = params?.id ? "Update" : "Create";

  const { data, isLoading } = useQueryWithCustomized(
    `get-vcard-${params?.id}`,
    () => VcardApi.FindOneVcard(params?.id)
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    register,
    setError,
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      avatar: "",
      file: {},
      location: "",
      description: "",
      position: "",
      phone_number: [
        {
          key: "Phone",
          value: "",
          disabled: true,
        },
        {
          key: "WhatsApp",
          value: "",
          disabled: true,
        },
      ],
      websites: [
        {
          key: "LinkedIn",
          value: "",
          disabled: true,
        },
        {
          key: "Telegram",
          value: "",
          disabled: true,
        },
        {
          key: "Company",
          value: "https://vnetwork.vn",
          disabled: true,
        },
      ],
    },
  });

  const navigate = useNavigate();

  const {
    notify,
    mutateAsync: createVcard,
    isLoading: createVcardLoading,
  } = useMutationWithNotification(VcardApi.CreateVcard);

  const { mutateAsync: updateVcard, isLoading: updateVcardLoading } =
    useMutationWithNotification(VcardApi.UpdateVcard);

  const isFetching = createVcardLoading || isLoading || updateVcardLoading;

  const onSubmit = async (values) => {
    const data = {
      ...values,
      phone_number: JSON.stringify(values?.phone_number),
      websites: JSON.stringify(values?.websites),
    };

    if (!values?.file) throw Error("Missing file avatar");

    let response;

    if (params?.id) {
      response = await updateVcard({ ...data, id: params?.id });
    } else {
      response = await createVcard(data);
    }

    if (!response?.errors) {
      navigate("/account/vcard");
      notify("success", `${action} vcard success`);
    }
  };

  const handleSelectAvatar = () => {
    const input = document.createElement("input");

    input.type = "file";

    input.click();

    input.onchange = (e) => {
      const file = e?.target?.files?.[0];

      if (!file?.type?.startsWith("image")) {
        return setError("avatar", { message: "Only file image!" });
      }

      if (file.size / 1000000 > 10) {
        return setError("avatar", { message: "Only accept files under 10MB!" });
      }

      if (watch("avatar")) {
        setValue("file", null);
        URL.revokeObjectURL(watch("avatar"));
      }

      setValue("avatar", URL.createObjectURL(file));
      setValue("file", file);

      input.remove();
    };
  };

  useEffect(() => {
    register("avatar", { required: { value: true, message: "Required!" } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data?.data) return;

    setValue("avatar", data?.data?.avatar);
    setValue("description", data?.data?.description);
    setValue("email", data?.data?.email);
    setValue("location", data?.data?.location);
    setValue("name", data?.data?.name);
    setValue("phone_number", JSON.parse(data?.data?.phone_number));
    setValue("websites", JSON.parse(data?.data?.websites));
    setValue("position", data?.data?.position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  return (
    <Box>
      <HeaderTitle>Vcard</HeaderTitle>

      <Paper
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        sx={{ mt: 2, p: 2 }}
      >
        <Box
          mb={4}
          mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box onClick={handleSelectAvatar} sx={{ cursor: "pointer" }}>
            <Box
              width={120}
              height={120}
              borderRadius="50%"
              border="1px solid #e5e7eb"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
            >
              {!watch("avatar") ? (
                <Button sx={{ width: "100%", height: "100%" }}>
                  <CameraAltIcon />
                </Button>
              ) : (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={watch("avatar")}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
            </Box>
            {errors?.avatar?.message && (
              <Typography textAlign="center" mt={2} color="#ef4444">
                {errors?.avatar?.message}
              </Typography>
            )}
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              rules={{
                required: { value: true, message: "Required!" },
              }}
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Name"
                  fullWidth
                  helperText={errors?.name?.message}
                  error={!!errors?.name}
                />
              )}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              rules={{
                required: { value: true, message: "Required!" },
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: "Email format invalid!",
                },
              }}
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Email"
                  fullWidth
                  helperText={errors?.email?.message}
                  error={!!errors?.email}
                />
              )}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              rules={{
                required: { value: true, message: "Required!" },
              }}
              name="position"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Position"
                  fullWidth
                  helperText={errors?.position?.message}
                  error={!!errors?.position}
                />
              )}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              rules={{
                required: { value: true, message: "Required!" },
              }}
              name="location"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Location"
                  fullWidth
                  helperText={errors?.location?.message}
                  error={!!errors?.location}
                />
              )}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Controller
              rules={{
                required: { value: true, message: "Required!" },
              }}
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Description"
                  fullWidth
                  helperText={errors?.description?.message}
                  error={!!errors?.description}
                />
              )}
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          <Typography>Phone Number</Typography>
          <Box>
            {watch("phone_number")?.map((_, index) => (
              <Box mt={2} key={_.value + index}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Controller
                      rules={{
                        required: { value: true, message: "Required!" },
                      }}
                      name={`phone_number[${index}].key`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          disabled={_.disabled}
                          label="Key"
                          fullWidth
                          helperText={
                            errors?.phone_number?.[index]?.key?.message
                          }
                          error={!!errors?.phone_number?.[index]?.key}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Controller
                      rules={{
                        required: { value: true, message: "Required!" },
                      }}
                      name={`phone_number[${index}].value`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label="Value"
                          fullWidth
                          helperText={
                            errors?.phone_number?.[index]?.value?.message
                          }
                          error={!!errors?.phone_number?.[index]?.value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </Box>

        <Box mt={2}>
          <Typography>Websites</Typography>
          <Box>
            {watch("websites")?.map((_, index) => (
              <Box mt={2} key={_.value + index}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Controller
                      rules={{
                        required: { value: true, message: "Required!" },
                      }}
                      name={`websites[${index}].key`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled={_.disabled}
                          size="small"
                          label="Key"
                          fullWidth
                          helperText={errors?.websites?.[index]?.key?.message}
                          error={!!errors?.websites?.[index]?.key}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Controller
                      rules={{
                        required: { value: true, message: "Required!" },
                      }}
                      name={`websites[${index}].value`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label="Value"
                          fullWidth
                          helperText={errors?.websites?.[index]?.value?.message}
                          error={!!errors?.websites?.[index]?.value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </Box>

        <Button
          sx={{ width: "100%", mt: 2 }}
          variant="contained"
          size="small"
          type="submit"
          disabled={isFetching}
          startIcon={isFetching && <CircularProgress size={14} />}
        >
          {action} Vcard
        </Button>
      </Paper>
    </Box>
  );
};

export default Form;
