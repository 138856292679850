import WarehouseProcessApi from "@/apis/beta/Warehouse/WarehouseProcessApi";
import WarehouseProcessLogApi from "@/apis/beta/Warehouse/WarehouseProcessLogApi";
import { WAREHOUSE_STATUS_BUY } from "@/libs/constants";
import withSuspense from "@/hocs/withSuspense";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import DatePicker from "@components/DatePicker";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useEmployeeContext } from "@contexts/employee";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

const FormWarehouseItemExport = ({
  refetchProcessLogs,
  modal,
  warehouse_process = null,
  callbackData,
  setModal,
}) => {
  const { control, getValues, handleSubmit } = useForm({
    defaultValues: modal.data || {},
  });
  const { employee } = useEmployeeContext();
  const onChangeWarrantyDate = (warrantyFrom = null, warrantyTo = null) => {
    const from = warrantyFrom || new Date(getValues("warranty_start_at"));
    let to = warrantyTo || new Date(getValues("warranty_expired_at"));

    if (from < to)
      return true;
    notify("error", "Ngày kết thúc bảo hành đang nhỏ hơn hoặc bằng ngày bảo hành vui lòng chọn lại");
    return false;
  };
  const [prevProcess, setPreprocess] = React.useState({})

  const { mutateAsync: UpdateProcessAsync } =
    useMutationWithNotification(WarehouseProcessApi.Update);

  const handle = async (form) => {

    // if (!response?.errors && warehouse_process) {
    //   const depreciation = response?.data;

    //   const warehouse_process_metadata = _.map(
    //     warehouse_process.warehouse_process_metadata,
    //     (o) => {
    //       const item = o;
    //       if (item.warehouse_item_id === depreciation.warehouse_item_id) {
    //         item.depreciation = depreciation;
    //       }
    //       return item;
    //     }
    //   );

    //   const updateWarehouseProcess = await WarehouseProcessApi.Update({
    //     id: warehouse_process?.warehouse_process_id,
    //     params: { warehouse_process_metadata: warehouse_process_metadata },
    //   });

    //   if (!updateWarehouseProcess?.errors) {
    //     notify("success", "Success");
    //     setModal({ show: false });
    //     callbackData();
    //     return;
    //   }
    // }

    // if (!response?.errors) {
    //   notify("success", "Success");
    //   setModal({ show: false });
    //   callbackData();
    // }
  };
  useEffect(() => {
    if (warehouse_process) {
      setPreprocess(warehouse_process)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { isLoading, notify } = useMutationWithNotification(handle);
  const onSubmit = async (data) => {
    const form = data;
    const process = modal.process;
    let checkItemParentFound = false;
    const warehouse_process_metadata = process?.warehouse_process_metadata.map((item) => {
      if (item?.warehouse_item_id === form?.warehouse_item_id) {
        // item["warranty_expired_at"] = form?.warranty_expired_at;
        // item["warranty_start_at"] = form?.warranty_start_at;
        item = form
        checkItemParentFound = true;
      }
      if (!checkItemParentFound) {
        item?.children?.forEach((child, index) => {
          if (child?.warehouse_item_id === form?.warehouse_item_id) {
            item["children"][index] = form;
            checkItemParentFound = true;
          }
        });
      }
      return item;
    })
    if (!checkItemParentFound) {
      notify("error", "Không tìm thấy thiết bị, linh kiện để cập nhật");
      return;
    }

    const response = await UpdateProcessAsync({
      id: process?.warehouse_process_id,
      params: { warehouse_process_metadata: warehouse_process_metadata },
    });

    if (!response?.errors) {
      const action_process = response?.data?.warehouse_process?.warehouse_process_code?.split("-")[0]?.toLowerCase();
      const warehouse_process_log = {
        warehouse_process_id: response?.data?.warehouse_process?.warehouse_process_id,
        warehouse_process_name: response?.data?.warehouse_process?.warehouse_process_code,
        warehouse_process_update_type: `UPDATE-${action_process?.toUpperCase()}`,
        warehouse_process_updater_id: employee?.employee_id,
        warehouse_process_updater_name: employee?.employee_name,
        metadata: {
          data: prevProcess
        },
        warehouse_process_update_description: `Cập nhật ngày bảo hành ${response?.data?.warehouse_process?.warehouse_process_code}`,
      }
      await WarehouseProcessLogApi.createProcessLog(warehouse_process_log);
      notify("success", "Success");
      if (callbackData) callbackData();
      if (refetchProcessLogs) refetchProcessLogs();
    }
  };

  return (
    <>
      <Dialog
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        open={modal.show}
        fullWidth={true}
        onClose={() => setModal({ show: false })}
        maxWidth={"sm"}
      >
        <DialogContent sx={{ minHeight: "400px" }}>
          <Grid container spacing={1}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Box>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                  Service_Tag/ Series Number:
                </Typography>
                <Typography sx={{ color: "#00000F" }}>
                  {modal.data?.warehouse_item_service_tag}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Box>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>Tên:</Typography>
                <Typography sx={{ color: "#00000F" }}>
                  {modal.data?.warehouse_item_name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box display="flex" gap={2} mt={2}>
                <Controller
                  control={control}
                  name={`warehouse_item_status`}
                  defaultValue={WAREHOUSE_STATUS_BUY?.[0]?.value}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Typography>Chọn tình trạng lúc bán</Typography>
                      <Select {...field} size="small" sx={{ mt: 1 }} fullWidth>
                        {WAREHOUSE_STATUS_BUY?.map(
                          (item) => {
                            if (item?.value !== 3) {
                              return (
                                <MenuItem key={item?.value} value={item?.value}>
                                  {item?.label}
                                </MenuItem>
                              )
                            }
                            return null
                          }
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                  Ngày bắt đầu bảo hành xuất bán
                </Typography>
                <Controller
                  name="warranty_start_at"
                  defaultValue={new Date()}
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <FormControl fullWidth>
                      <DatePicker
                        fullWidth
                        {...field}
                        onChange={(newValue) => {
                          if (onChangeWarrantyDate(new Date(newValue)))
                            onChange((newValue));
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                  Ngày kết thúc bảo hành xuất bán
                </Typography>
                <Controller
                  name="warranty_expired_at"
                  defaultValue={new Date()}
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <FormControl fullWidth>
                      <DatePicker
                        fullWidth
                        {...field}
                        onChange={(newValue) => {
                          if (onChangeWarrantyDate(null, new Date(newValue)))
                            onChange((newValue));
                          //   onChangeDepreciateDate();
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}
          >
            <Button
              disabled={isLoading}
              onClick={() => setModal({ show: false })}
              size="small"
              variant="outlined"
              sx={{ height: 36 }}
            >
              Huỷ Thao Tác
            </Button>
            <Button
              disabled={isLoading}
              size="small"
              type="submit"
              startIcon={
                isLoading && (
                  <CircularProgress
                    sx={{ m: "auto 0", color: "#777680" }}
                    size={14}
                  />
                )
              }
              variant="contained"
              sx={{ height: 36 }}
            >
              Cập Nhật Thông Tin Phiếu
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default withSuspense(
  FormWarehouseItemExport,
  MuiSkeleton["LinearProgress"]
);
