
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import BreadCrumb from "@components/shared/BreadCrumb";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import Form from "../Form";

const Create = () => {

    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>
                Quản lý Bandwidth
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Quản lý Bandwidth Rack", reactLink: "/warehouse/bandwidths/racks" },
                    { label: "Thêm đối soát rack" }
                ]}
            />
            <React.Suspense fallback={<CircularProgress size={24} sx={{ m: 'auto' }} />}>
                <Form type="create" />
            </React.Suspense>

        </Box>

    )
}
export default withSuspense(withPermission(Create, {
    feature: "warehouse",
    action: "bandwidth-rack-create"
}), MuiSkeleton["DataGrid"]) 