// import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseTypeApi from "@/apis/beta/Warehouse/WarehouseTypeApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDataGridParams from "@/hooks/useDataGridParams";
import { Filter } from "@/hooks/useFilters";
import Cell from "@components/DataGrid/Cell";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Button, Link, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import CreateNewTypes from "../Drawer/CreateNewTypes";
import HeaderTitle from "@components/Title/HeaderTitle";
import TypeGroup from "../TypeGroup";

const TypesV2 = () => {
  const query = ""

  const initialColumns = [
    {
      field: "warehouse_group_name",
      headerName: "",
      valueGetter: ({ row }) => row.warehouse_group.warehouse_group_name,
      sortable: false,
      width: 150,
    },
    {
      field: "warehouse_type_name",
      headerName: "Tên Loại",
      sortable: false,
      width: 250,
      renderCell: ({ value, row }) => {
        if (row.warehouse_type_children.length === 0) {
          return (
            <Link
              component={NavLink}
              to={`/warehouse/types/${row.warehouse_type_id}`}
            >
              <Typography className="react-link-table">
                {value.toUpperCase()}
              </Typography>
            </Link>
          );
        } else {
          <Cell value={`${value}`} />;
        }
      },
    },

    {
      field: "total_input",
      headerName: "Nhập Hàng",
      sortable: false,
      width: 200,
    },
    {
      field: "total_warehouse",
      headerName: "Ở Kho",
      sortable: false,
      cellClassName: "cty",
      width: 200,
    },
    {
      field: "total_idc",
      headerName: "Ở IDC",
      sortable: false,
      width: 200,
    },
    {
      field: "total_export",
      headerName: "Xuất",
      sortable: false,
      width: 200,
    },
  ];

  const [showDrawer, setShowDrawer] = useState(false);

  const { page } = useDataGridParams(initialColumns);

  const { data, refetch } = WarehouseTypeApi.index({
    page: page?.page + 1,
    pageSize: page?.pageSize || 20,
    query: Filter.stringify(query || ""),
  });

  // const { data: warehouse_items, isFetching: warehouse_items_fetch } =
  //   WarehouseItemApi.getAll();

  // const onPaginationModelChange = (model) => {
  //   setPage(model);
  // };

  const reFormatData = useMemo(() => {
    if (!data?.warehouse_types) return

    let parent = [];
    let child = [];

    // const handleCalTotalInput = (warehouse_type_id) => {
    //   // return (
    //   //   warehouse_items?.filter(
    //   //     (item) => item?.warehouse_type_id === warehouse_type_id
    //   //   )?.length || 0
    //   // );
    //   return handleCalTotalWarehouse(warehouse_type_id) + handleCalTotalExport(warehouse_type_id) + handleCalTotalIDC(warehouse_type_id)
    // };

    // const handleCalTotalChild = (key, child, warehouse_type_id) => {
    //   return child?.reduce((total, item) => {
    //     if (item?.warehouse_type_parent === warehouse_type_id) {
    //       total += item?.[key] || 0;
    //     }

    //     return total;
    //   }, 0);
    // };

    // const handleCalTotalWarehouse = (warehouse_type_id) => {
    //   return (
    //     warehouse_items?.filter(
    //       (item) =>
    //         item?.warehouse_type_id === warehouse_type_id &&
    //         item.warehouse_item_export === false &&
    //         item?.warehouse_owner_name === "VNETWORK" &&
    //         item?.warehouse_box_item_reference_type === "WarehouseBox"
    //     )?.length || 0
    //   );
    // };

    // const handleCalTotalIDC = (warehouse_type_id) => {
    //   return (
    //     warehouse_items?.filter(
    //       (item) =>
    //         item?.warehouse_type_id === warehouse_type_id &&
    //         item.warehouse_item_export === false &&
    //         item?.warehouse_owner_name === "VNETWORK" &&
    //         item?.warehouse_box_item_reference_type !== "WarehouseBox"
    //     )?.length || 0
    //   );
    // };

    // const handleCalTotalCustomer = (warehouse_type_id) => {
    //   return (
    //     warehouse_items?.filter(
    //       (item) =>
    //         item?.warehouse_type_id === warehouse_type_id &&
    //         item.warehouse_item_export === false &&
    //         item?.warehouse_owner_name !== "VNETWORK"
    //     )?.length || 0
    //   );
    // };

    // const handleCalTotalExport = (warehouse_type_id) => {
    //   return (
    //     warehouse_items?.filter(
    //       (item) =>
    //         item?.warehouse_type_id === warehouse_type_id &&
    //         item?.warehouse_item_export
    //     )?.length || 0
    //   );
    // };

    parent = data?.warehouse_types
      ?.filter((item) => item?.warehouse_type_parent === 0)
      ?.map((item) => {
        // const id = item.warehouse_type_id;

        // const total_input = handleCalTotalInput(id);
        // const total_warehouse = handleCalTotalWarehouse(id);
        // const total_idc = handleCalTotalIDC(id);
        // // const total_customer = handleCalTotalCustomer(id);
        // const total_export = handleCalTotalExport(id);

        return {
          ...item,
          id: item.warehouse_type_id,
          group: [`${item.warehouse_type_id}`],
          // total_input,
          // total_warehouse,
          // total_idc,
          // // total_customer,
          // total_export,
        };
      });

    child = [
      ...data?.warehouse_types?.reduce((final, item) => {
        final.push(...item?.warehouse_type_children);

        return final;
      }, []),
    ]?.map((item, i) => {
      // const id = item.warehouse_type_id;

      // const total_input = handleCalTotalInput(id);
      // const total_warehouse = handleCalTotalWarehouse(id);
      // const total_idc = handleCalTotalIDC(id);
      // // const total_customer = handleCalTotalCustomer(id);
      // const total_export = handleCalTotalExport(id);

      return {
        ...item,
        id: item.warehouse_type_id,
        group: [
          `${item.warehouse_type_parent}`,
          `${item.warehouse_type_parent}.${i + 1}`,
        ],
        // total_input,
        // total_warehouse,
        // total_idc,
        // // total_customer,
        // total_export,
      };
    });
    return [
      ...parent?.map((item) => ({
        ...item,
        // total_input:
        //   item?.total_input 
        //   +
        //   handleCalTotalChild("total_input", child, item?.warehouse_type_id),
        // total_warehouse:
        //   item?.total_warehouse +
        //   handleCalTotalChild(
        //     "total_warehouse",
        //     child,
        //     item?.warehouse_type_id
        //   ),
        // total_idc:
        //   item?.total_idc +
        //   handleCalTotalChild("total_idc", child, item?.warehouse_type_id),
        // // total_customer:
        // //   item?.total_customer +
        // //   handleCalTotalChild("total_customer", child, item?.warehouse_type_id),
        // total_export:
        //   item?.total_export +
        //   handleCalTotalChild("total_export", child, item?.warehouse_type_id),
      })),
      ...child,
    ];
  }, [data?.warehouse_types]);
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={2}>
        <HeaderTitle>Quản lý thể loại</HeaderTitle>
        <Button
          onClick={() => setShowDrawer(true)}
          variant="contained"
          size="small"
        >
          Thêm thể loại
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          gap: 1.5,
          mb: 2,
        }}
      >
      </Box>
      {reFormatData?.map((item, index) => {
        if (item?.warehouse_type_parent !== 0) return null;
        return (

          <React.Fragment key={index}>
            <TypeGroup type={item} index={index} types={reFormatData} />
          </React.Fragment>
        );
      })}
      {showDrawer && (
        <CreateNewTypes
          open={showDrawer}
          handleClose={() => setShowDrawer(false)}
          refetch={refetch}
          warehouse_types={data?.warehouse_types}
        />
      )}
    </Box>
  );
};

export default withSuspense(
  withPermission(TypesV2, { feature: "warehouse", action: "types-v2" }),
  MuiSkeleton["DataGrid"]
);
