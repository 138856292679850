import ManufactureApi from "@/apis/beta/Warehouse/ManufactureApi";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import withSuspense from "@/hocs/withSuspense";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import AutocompleteAddedNewValue from "@components/AutocompleteAddedNewValue";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

const CreateModel = ({
  warehouse_type,
  openDrawer,
  handleClose,
  callbackModel,
  type = "create",
  model,
}) => {
  const setting_slots = ["SERVER"].includes(warehouse_type?.warehouse_type_name)
    ? [
      { slot_name: "CPU", slot_number: 0, slot_max_capacity: 0 },
      { slot_name: "RAM", slot_number: 0, slot_max_capacity: 0 },
      { slot_name: "DISK", slot_number: 0, slot_max_capacity: 0 },
      { slot_name: "POWER SUPPLY", slot_number: 0, slot_max_capacity: 0 },
      { slot_name: "CARD NETWORK", slot_number: 0, slot_max_capacity: 0 },
    ]
    : [];

  const setting_specifications = [
    {
      specification_name: "FORM FACTOR",
      specification_value: "1",
      specification_unit: "U",
      warehouse_type_name: "SERVER",
      disabled: true,
    },
    {
      specification_name: "FORM FACTOR",
      specification_value: "1",
      specification_unit: "U",
      warehouse_type_name: "SWITCH",
      disabled: true,
    },
    {
      specification_name: "MODEL",
      specification_value: "",
      specification_unit: "",
      warehouse_type_name: "SSD",
      disabled: true,
    },
    {
      specification_name: "CAPACITY",
      specification_value: "0",
      specification_unit: "GB",
      warehouse_type_name: "SSD",
      disabled: true,
    },
    {
      specification_name: "FORM FACTOR",
      specification_value: "",
      specification_unit: "inch",
      warehouse_type_name: "SSD",
      disabled: true,
    },
    {
      specification_name: "SEQUENTIAL READ",
      specification_value: "0",
      specification_unit: "MB/s",
      warehouse_type_name: "SSD",
      disabled: true,
    },
    {
      specification_name: "SEQUENTIAL WRITE",
      specification_value: "0",
      specification_unit: "MB/s",
      warehouse_type_name: "SSD",
      disabled: true,
    },
    {
      specification_name: "Interface",
      specification_value: "",
      specification_unit: "",
      warehouse_type_name: "HDD",
      disabled: true,
    },
    {
      specification_name: "MODEL",
      specification_value: "",
      specification_unit: "",
      warehouse_type_name: "HDD",
      disabled: true,
    },
    {
      specification_name: "CAPACITY",
      specification_value: "0",
      specification_unit: "GB",
      warehouse_type_name: "HDD",
      disabled: true,
    },
    {
      specification_name: "FORM FACTOR",
      specification_value: "",
      specification_unit: "inch",
      warehouse_type_name: "HDD",
      disabled: true,
    },
    {
      specification_name: "SEQUENTIAL READ",
      specification_value: "0",
      specification_unit: "MB/s",
      warehouse_type_name: "HDD",
      disabled: true,
    },
    {
      specification_name: "SEQUENTIAL WRITE",
      specification_value: "0",
      specification_unit: "MB/s",
      warehouse_type_name: "HDD",
      disabled: true,
    },
    {
      specification_name: "Interface",
      specification_value: "",
      specification_unit: "",
      warehouse_type_name: "SAS",
      disabled: true,
    },
    {
      specification_name: "MODEL",
      specification_value: "",
      specification_unit: "",
      warehouse_type_name: "SAS",
      disabled: true,
    },
    {
      specification_name: "CAPACITY",
      specification_value: "0",
      specification_unit: "GB",
      warehouse_type_name: "SAS",
      disabled: true,
    },
    {
      specification_name: "FORM FACTOR",
      specification_value: "",
      specification_unit: "inch",
      warehouse_type_name: "SAS",
      disabled: true,
    },
    {
      specification_name: "SEQUENTIAL READ",
      specification_value: "0",
      specification_unit: "MB/s",
      warehouse_type_name: "SAS",
      disabled: true,
    },
    {
      specification_name: "SEQUENTIAL WRITE",
      specification_value: "0",
      specification_unit: "MB/s",
      warehouse_type_name: "SAS",
      disabled: true,
    },
    {
      specification_name: "CORES",
      specification_value: "0",
      specification_unit: "",
      warehouse_type_name: "CPU",
      disabled: true,
    },
    {
      specification_name: "THREADS",
      specification_value: "0",
      specification_unit: "",
      warehouse_type_name: "CPU",
      disabled: true,
    },
    {
      specification_name: "PROCESSOR SPEED",
      specification_value: "0",
      specification_unit: "GHz",
      warehouse_type_name: "CPU",
      disabled: true,
    },
    {
      specification_name: "PROCESSOR BASE FREQUENCY",
      specification_value: "0",
      specification_unit: "GHz",
      warehouse_type_name: "CPU",
      disabled: true,
    },
    {
      specification_name: "CLOCK SPEED",
      specification_value: "0",
      specification_unit: "Mhz",
      warehouse_type_name: "RAM",
      disabled: true,
    },
    {
      specification_name: "CAPACITY",
      specification_value: "0",
      specification_unit: "GB",
      warehouse_type_name: "RAM",
      disabled: true,
    },
    {
      specification_name: "FORM FACTOR",
      specification_value: "",
      specification_unit: "",
      warehouse_type_name: "RAM",
      disabled: true,
    },
    {
      specification_name: "DESCRIPTION",
      specification_value: "",
      specification_unit: "",
      warehouse_type_name: "CARD NETWORK",
      disabled: true,
    },
    {
      specification_name: "POWER SUPPLY",
      specification_value: "",
      specification_unit: "KW",
      warehouse_type_name: "NGUỒN",
      disabled: true,
    },
  ];

  const {
    mutateAsync: ManufactureCreate,
    isLoading: ManufactureCreateLoading,
  } = useMutationWithNotification(ManufactureApi.Create);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues:
      type === "create"
        ? {
          manufacture_id: "",
          slots: setting_slots,
          specifications: setting_specifications?.filter(
            (item) =>
              item?.warehouse_type_name ===
              warehouse_type?.warehouse_type_name
          ),
          warehouse_model_name: "",
          warehouse_type_id: warehouse_type?.warehouse_type_id,
        }
        : {
          ...model,
          manufacture_id: {
            value: model?.manufacture?.manufacture_id,
            label: model?.manufacture?.manufacture_name
          }
        },
  });

  const unit_slot = [
    {
      name: "DISK",
      capacity_unit: "GB",
      slot_unit: "CÁI",
    },
    {
      name: "POWER SUPPLY",
      capacity_unit: "K/w",
      slot_unit: "CÁI",
    },
    {
      name: "CPU",
      capacity_unit: "CORE",
      slot_unit: "CHIP",
    },
    {
      name: "RAM",
      capacity_unit: "GB",
      slot_unit: "THANH",
    },
    {
      name: "CARD NETWORK",
      capacity_unit: "GB",
      slot_unit: "CÁI",
    },
  ];

  const { data: manufactures, isLoading, refetch } = ManufactureApi.getAll();

  const {
    mutateAsync: createModel,
    isLoading: createModelPending,
    notify,
  } = useMutationWithNotification(WarehouseModelApi.Create);
  const { mutateAsync: updateModel, isLoading: updateModelPending } =
    useMutationWithNotification(WarehouseModelApi.Update);

  const isPending = createModelPending || updateModelPending;

  const submit = async (values) => {
    console.log(values)
    const api = type === "create" ? createModel : updateModel;

    const response = await api({
      ...values,
      id: model?.warehouse_model_id,
      specifications: values?.specifications?.map((item) => ({
        specification_name: item?.specification_name,
        specification_value: item?.specification_value,
        specification_unit: item?.specification_unit,
      })),
      slots: values?.slots?.map((item) => ({
        slot_name: item?.slot_name,
        slot_number: item?.slot_number,
        slot_max_capacity: item?.slot_max_capacity,
      })),
      manufacture_id: values?.manufacture_id?.value,
    });
    if (response?.data?.errors) {
      return notify("error", response?.data?.errors[0]?.message);
    }
    if (!response?.errors) {
      notify("success", `Success`);
      callbackModel();
      handleClose();
    }
  };

  const handleAddNewManufacture = async (newValue) => {
    const response = await ManufactureCreate({
      manufacture_name: newValue?.inputValue,
    });

    const { manufacture_name, manufacture_id } =
      response?.data?.data?.manufacture;

    setValue("manufacture_id", {
      label: manufacture_name,
      value: manufacture_id,
    });

    refetch();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      sx={{ p: 2 }}
      anchor="right"
      open={openDrawer}
      onClose={handleClose}
    >
      <Box component="form" onSubmit={handleSubmit(submit)}>
        <DialogTitle>
          {type === "create" ? "Thêm Model" : "Sửa Model"}
        </DialogTitle>

        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <Box display="flex" gap={2}>
              <Controller
                control={control}
                name="warehouse_model_name"
                rules={{ required: "Tên Model là bắt buộc" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="Tên Model"
                    error={!!errors?.warehouse_model_name}
                    helperText={errors?.warehouse_model_name?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="manufacture_id"
                rules={{ required: "Vui lòng chọn nhà sx" }}
                render={({ field: { onChange, value, ...field } }) => {
                  return (
                    <FormControl fullWidth>
                      <AutocompleteAddedNewValue
                        value={value}
                        onAddNewValue={handleAddNewManufacture}
                        onChange={onChange}
                        options={manufactures?.map((item) => ({
                          label: item?.manufacture_name,
                          value: item?.manufacture_id,
                        }))}
                        disabled={isLoading || ManufactureCreateLoading}
                        {...field}
                      />

                      {errors?.manufacture_id && (
                        <ErrorHelperText
                          message={errors?.manufacture_id?.message}
                        />
                      )}
                    </FormControl>
                  );
                }}
              />
            </Box>

            <Box>
              <Box mt={2}>
                <Typography>Slots:</Typography>
                {watch("slots")?.map((item, index) => (
                  <Box display="flex" gap={2} key={item?.slot_name}>
                    <Controller
                      control={control}
                      name={`slots[${index}].slot_name`}
                      rules={{ required: "Tên slots là bắt buộc" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mt: 2 }}
                          fullWidth
                          size="small"
                          label="Tên slot"
                          error={!!errors?.slots?.[index]?.slot_name}
                          helperText={
                            errors?.slots?.[index]?.slot_name?.message
                          }
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`slots[${index}].slot_number`}
                      rules={{ required: "Số slot tối đa là bắt buộc" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mt: 2 }}
                          type="number"
                          fullWidth
                          size="small"
                          label="Số slot tối đa"
                          error={!!errors?.slots?.[index]?.slot_number}
                          helperText={
                            errors?.slots?.[index]?.slot_number?.message
                          }
                          InputProps={{
                            min: 0,
                            endAdornment: (
                              <InputAdornment position="end">
                                {
                                  unit_slot?.find(
                                    (o) => o.name === item?.slot_name
                                  )?.slot_unit
                                }
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`slots[${index}].slot_max_capacity`}
                      rules={{ required: "Tổng capacity là bắt buộc" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mt: 2 }}
                          type="number"
                          fullWidth
                          size="small"
                          label="Tổng capacity tối đa"
                          error={!!errors?.slots?.[index]?.slot_max_capacity}
                          helperText={
                            errors?.slots?.[index]?.slot_max_capacity?.message
                          }
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            min: 0,
                            endAdornment: (
                              <InputAdornment position="end">
                                {
                                  unit_slot?.find(
                                    (o) => o.name === item?.slot_name
                                  )?.capacity_unit
                                }
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />

                    <IconButton
                      onClick={() =>
                        setValue("slots", [
                          ...watch("slots")?.filter((_, i) => i !== index),
                        ])
                      }
                      sx={{ flex: 1 }}
                    >
                      <DeleteOutlinedIcon color="error" />
                    </IconButton>
                  </Box>
                ))}
              </Box>

              <Button
                onClick={() =>
                  setValue("slots", [
                    ...watch("slots"),
                    { slot_max_capacity: "", slot_name: "", slot_number: "" },
                  ])
                }
                variant="contained"
                size="small"
                sx={{ mt: 2 }}
                startIcon={<AddOutlinedIcon />}
              >
                Thêm Slot
              </Button>

              <Button
                onClick={() => {
                  const copySlots = [...watch("slots")];
                  copySlots.pop();
                  setValue("slots", copySlots);
                }}
                variant="outlined"
                size="small"
                sx={{ mt: 2, ml: 2 }}
                startIcon={<DeleteOutlinedIcon />}
              >
                Xoá Slot Cuối Cùng
              </Button>
            </Box>

            <Box>
              <Box mt={2}>
                <Typography>Specifications:</Typography>
                {watch("specifications")?.map((item, index) => (
                  <Box display="flex" gap={2} key={item?.specification_name}>
                    <Controller
                      control={control}
                      name={`specifications[${index}].specification_name`}
                      rules={{ required: "Tên thông số kỹ thuật là bắt buộc" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mt: 2 }}
                          fullWidth
                          disabled={
                            watch("specifications")?.[index]
                              ?.specification_name === "FORM FACTOR"
                          }
                          size="small"
                          label="Tên thông số kỹ thuật"
                          error={
                            !!errors?.specifications?.[index]
                              ?.specification_name
                          }
                          helperText={
                            errors?.specifications?.[index]?.specification_name
                              ?.message
                          }
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`specifications[${index}].specification_value`}
                      rules={{ required: "Giá trị là bắt buộc" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mt: 2 }}
                          // type="number"
                          fullWidth
                          size="small"
                          label="Giá trị"
                          error={
                            !!errors?.specifications?.[index]
                              ?.specification_value
                          }
                          helperText={
                            errors?.specifications?.[index]?.specification_value
                              ?.message
                          }
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`specifications[${index}].specification_unit`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mt: 2 }}
                          fullWidth
                          size="small"
                          label="Đơn vị"
                        // error={
                        //   !!errors?.specifications?.[index]
                        //     ?.specification_unit
                        // }
                        // helperText={
                        //   errors?.specifications?.[index]?.specification_unit
                        //     ?.message
                        // }
                        />
                      )}
                    />

                    <IconButton
                      onClick={() =>
                        setValue("specifications", [
                          ...watch("specifications")?.filter(
                            (_, i) => i !== index
                          ),
                        ])
                      }
                      sx={{ flex: 1 }}
                    >
                      <DeleteOutlinedIcon color="error" />
                    </IconButton>
                  </Box>
                ))}
              </Box>

              <Button
                onClick={() =>
                  setValue("specifications", [
                    ...watch("specifications"),
                    {
                      specification_name: "",
                      specification_value: "",
                      specification_unit: "",
                    },
                  ])
                }
                variant="contained"
                size="small"
                sx={{ mt: 2 }}
                startIcon={<AddOutlinedIcon />}
              >
                Thêm Specification
              </Button>

              <Button
                onClick={() => {
                  const copySpecification = [...watch("specifications")];
                  copySpecification.pop();
                  setValue("specifications", copySpecification);
                }}
                variant="outlined"
                size="small"
                sx={{ mt: 2, ml: 2 }}
                startIcon={<DeleteOutlinedIcon />}
              >
                Xoá Specification Cuối Cùng
              </Button>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" size="small" onClick={handleClose}>
            Huỷ bỏ
          </Button>

          <Button
            disabled={isPending}
            startIcon={isPending && <CircularProgress size={14} />}
            variant="contained"
            size="small"
            type="submit"
          >
            {type === "create" ? "Thêm Model" : "Sửa Model"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default withSuspense(CreateModel, MuiSkeleton["LinearProgress"]);
