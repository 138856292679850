import { useEmployeeContext } from '@/contexts/employee';
import withAuth from '@/hocs/withAuth';
import withEmployee from '@/hocs/withEmployee';
import withLayout from "@/hocs/withLayout";
import { withSnackbar } from 'notistack';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardRoute from '../DashboardRoute';

const ProtectedRoute = ({ enqueueSnackbar }) => {
  const { employee } = useEmployeeContext();

  // React.useEffect(() => {
  //   cable.subscriptions.create(
  //     { channel: 'NotificationChannel' },
  //     {
  //       received(data) {
  //         enqueueSnackbar(data?.message);
  //       },
  //     }
  //   );

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Routes>
      <Route path='/*' element={<DashboardRoute employee={employee} />} />
    </Routes>
  );
};

export default withAuth(withLayout(withEmployee(withSnackbar(ProtectedRoute))));
