import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import CDNAnalytic from "@/components/Templates/Conversant(CDN)/Analytics"
import StorageAnalytic from "@/components/Templates/Storage/Analytics"
import ServiceApi from '@/apis/beta/Service/ServiceApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import TranscodingAnalytics from '@components/Templates/SwiftFederation/Transcoding'
import MlyticAnalytics from '@components/Templates/VNIS/Analytics'
import withPermission from '@/hocs/withPermission'
import ClusterAnalytic from '@components/Templates/ClusterService(k8s)/Analytics'

const ServiceAnalyticUIs = {
    Default: () => <>Dịch vụ đang trong quá trình phát triển</>,
    StorageLFDService: StorageAnalytic,
    ConversantService: CDNAnalytic,
    TranscodingService: TranscodingAnalytics,
    VnisService: MlyticAnalytics,
    ClusterService: ClusterAnalytic
}
const Analytic = () => {
    const { service_id } = useParams()
    const { data: service } = ServiceApi.Detail({ id: service_id })

    console.log(service)
    return (
        <Fragment>
            {React.createElement(ServiceAnalyticUIs[service?.service_creatable_type] || ServiceAnalyticUIs['Default'], {
                service,
            })}
        </Fragment>
    )
}

export default withSuspense(
    withPermission(Analytic, {
        feature: 'service',
        action: 'analytics'
    }), MuiSkeleton["GradientCircularProgress"])