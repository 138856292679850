import DataGridPreview from '@components/DataGridPreview';
import PopperAction from '@components/PopperAction';
import { Paper, Popper, styled } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid';
const StyledPopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.tooltip + 1
}));
const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
}

const GroupingRowDataGrid = ({ groupingColDef, getTreeDataPath, rows, initialColumns, actions, popper, setPopper }) => {


    return (
        <>
            <Paper
                sx={{
                    mt: 3,
                }}>
                <DataGridPreview
                    treeData
                    autoHeight
                    groupingColDef={groupingColDef}
                    getTreeDataPath={getTreeDataPath}
                    rows={rows || []}
                    hideAble={true}
                    disableSelectionOnClick={false}
                    options={{
                        name: 'Thống kê vật tư kho',
                        columns: initialColumns || [],
                    }}
                    disableColumnPinning={false}
                    initialState={{
                        pinnedColumns: { left: ["__tree_data_group__", "warehouse_box_name", "warehouse_rack_name"] },
                    }}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    disableColumnSelector={false}
                    hideFooter={false}
                />
            </Paper>
            {popper?.anchorEl !== undefined && (
                <StyledPopper anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)}>
                    <PopperAction
                        {...popper}
                        actions={actions}
                        handleClose={() =>
                            setPopper((prev) => ({ ...prev, anchorEl: undefined }))
                        }
                    />
                </StyledPopper>
            )}
        </>
    );
}
export default GroupingRowDataGrid;
