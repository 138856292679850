import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import withSuspense from "@/hocs/withSuspense";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const TicketRequest = ({ ticket_request_id = -1 }) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const { data: customer } = CustomerApi.Detail(watch("customer_id"));

  const {
    data: { services },
  } = CustomerApi.CustomerServices({
    customerId: parseInt(watch("customer_id")),
    page: 1,
    query: `filters[$and][0][tw_services.service_referrer_type][$ne]=Reducer::Override::Service`,
    pageSize: 50,
  });

  const servicesMemo = useMemo(() => {
    if (ticket_request_id === 1) {
      // POC
      return [
        ...(services || [])
          ?.filter((service) => !!service?.service_create_is_trial)
          ?.map((item) => ({
            service_id: item?.service_id,
            service_name: `${item?.service_id} - ${item?.service_name} - ${item?.service_status}`,
          })),
      ];
    }
    return [
      ...(services || [])?.map((item) => ({
        service_id: item?.service_id,
        service_name: `${item?.service_id} - ${item?.service_name} - (${item?.service_status})`,
      })),
      ...(
        customer?.customer_service_information?.customer_service_interestings ||
        []
      )?.map((item) => ({
        service_id: item,
        service_name: `${item} - dịch vụ đang quan tâm`,
      })),
    ];
  }, [
    ticket_request_id,
    services,
    customer?.customer_service_information?.customer_service_interestings,
  ]);

  useEffect(() => {
    if (customer?.customer_id) {
      setValue("customer_id", customer.customer_id);
      setValue(
        "ticket_cs",
        customer?.customer_service_information?.customer_cs_followers?.map(
          (employee) => employee?.employee_id
        ) || []
      );
      setValue(
        "ticket_sale",
        customer?.customer_service_information?.customer_sale_followers?.map(
          (employee) => employee?.employee_id
        ) || []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    if (typeof watch("service_id") !== "number") {
      setValue("product_name", watch("service_id"));
    } else {
      setValue("product_name", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("service_id")]);

  return (
    <Controller
      name="service_id"
      rules={{ required: "This field is required" }}
      control={control}
      render={({ field: { onChange, value } }) => {
        const selected =
          servicesMemo?.find((x) => x?.service_id === value) || null;

        return (
          <FormControl fullWidth>
            <SingleSelectCustomize
              input={{
                label: ` Dịch vụ
                                    ${
                                      !watch("customer_id")?.value
                                        ? "(vui lòng chọn khách hàng)"
                                        : "(đang sử dụng)"
                                    }`,
                placeholder: "Chọn dịch vụ...",
              }}
              onChange={(_, newValue) => onChange(newValue?.service_id || null)}
              disabled={
                !watch(`customer_id`) || parseInt(watch(`customer_id`) <= 0)
              }
              value={selected}
              formatCollection={{
                label: "service_name",
                value: "service_id",
              }}
              compareAttributes={["service_name"]}
              options={servicesMemo}
            />

            {errors?.service_id && (
              <ErrorHelperText message={errors?.service_id?.message} />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default withSuspense(TicketRequest, MuiSkeleton["TextField"]);
