import { format_date_short } from "@/functions";
import { CONTRACT_NUMBER_STATUS_TYPES } from "@/libs/constants";
import Cell from "@components/DataGrid/Cell";
import CellPricing from "@components/DataGrid/Cell/FormatterPricing";
import CellStatus from "@components/DataGrid/Cell/FormatterStatus";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const GET_INITIAL_COLUMNS = ({
  feature = "contracts", // from ["contracts", "partner-contracts","sample-contracts"]
  collections = {
    employees: [],
  },
  actions = [],
  setPopper
}) => {
  const ColumnOnlyCustomerContract = [
    {
      field: "contract_numbers.contract_number_owner",
      headerName: "Công Ty/ Chủ Thể",
      width: 300,
      valueGetter: ({ row }) =>
        row?.customer_company_name || row?.customer_name,
      operators: {
        type: "string",
      },
      renderCell: ({ value }) => (
        <Tooltip title={value} placement="top-end">
          <Typography sx={{ fontSize: 14 }}>{value}</Typography>
        </Tooltip>
      ),
    },
  ]; //  accept only customer contract
  const columns = [
    {
      field: "contracts.contract_id",
      headerName: "ID",
      width: 60,
      hideable: true,
      valueGetter: ({ row }) => row?.contract_id,
      operators: {
        type: "number",
      },
      renderCell: ({ value }) => (
        <Link to={`/${feature}/${value}`} replace>
          <Typography className="react-link-table">{value}</Typography>
        </Link>
      ),
    },
    {
      field: "contract_number_id",
      headerName: "Số Hợp Đồng",
      width: 220,
      operators: {
        type: "string",
      },
      renderCell: ({ value }) => value,
    },

    {
      field: "customer",
      headerName: "Công Ty / Cá Nhân",
      width: 300,
      valueGetter: ({ row }) =>
        row?.customer_company_name || row?.customer_name,
      renderCell: ({ value }) => <Cell content={value} />,
    },
    {
      field: "contract_key",
      headerName: "Từ Khoá",
      width: 300,
      operators: {
        type: "string",
      },
    },
    {
      field: "contract_value",
      headerName: "Giá Trị Hợp Đồng",
      width: 220,
      operators: { type: "price" },
      renderCell: ({ value }) => <CellPricing price={value} />,
    },
    {
      field: "contract_effective_date",
      headerName: "Ngày hiệu lực",
      width: 150,
      operators: {
        type: "date",
      },
      renderCell: ({ value }) => format_date_short(value) || "---",
    },
    {
      field: "contract_expiry_date",
      headerName: "Ngày hết hiệu lực",
      width: 150,
      operators: {
        type: "date",
      },
      renderCell: ({ value }) => format_date_short(value) || "---",
    },
    {
      field: "contracts.employee",
      headerName: "Nhân viên phụ trách",
      width: 200,
      operators: {
        type: "object",
        collections: collections?.employees || [],
      },
      valueGetter: ({ row }) => row?.employee_name,
      renderCell: ({ value }) => value || "",
    },
    {
      field: "contracts.contract_status",
      headerName: "Trạng Thái",
      width: 150,
      valueGetter: ({ row }) =>
        CONTRACT_NUMBER_STATUS_TYPES.find((stt) => stt.value === row?.contract_status),
      operators: {
        type: "object",
        collections: CONTRACT_NUMBER_STATUS_TYPES,
      },
      renderCell: ({ value }) => (
        <CellStatus component="muiChip" data={value} />
      ),
    },
    {
      field: "service.service_name",
      headerName: "Dịch Vụ",
      width: 300,
      valueGetter: ({ row }) => row?.service_name,
      renderCell: ({ value }) => value || ''
    },
    {
      field: "actions",
      headerName: "",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <IconButton
              size="small"
              onClick={(event) => {
                let newActions = actions;
                if (row?.contract_status === 1) {
                  newActions = newActions?.filter(
                    (a) => a?.name !== "cancel-contract"
                  );
                }
                if (!row?.link && row?.attachments?.length === 0) {
                  newActions = newActions?.filter(
                    (a) => !["download-contract-attachment", 'open-contract-attachment'].includes(a?.name)
                  );
                }
                if (row?.contract_status !== 0 && row?.contract_status !== 8) {
                  newActions = newActions?.filter(
                    (a) => !['retension-contract'].includes(a?.name)
                  );
                }
                setPopper((prev) => ({
                  ...prev,
                  anchorEl: event?.target,
                  data: row,
                  actions: newActions,
                }));
              }}
              title="Hành động"
            >
              <MoreVertOutlined fontSize="small" sx={{ m: "auto" }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return feature === "contracts"
    ? [
      ...columns.slice(0, 4),
      ...ColumnOnlyCustomerContract,
      ...columns.slice(4),
    ]
    : columns;
};
