import PlaybookApi from "@/apis/beta/Threshold/Playbook/PlaybookApi";
import PlaybookEdit from "@features/Module/Technical/Dialog/Playbook";
import PlaybookActionEdit from "@features/Module/Technical/Dialog/Playbook/PlaybookAction";
import { Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import ReactJson from "react-json-view";
import PlaybookActionList from "./PlaybookActionList";
const PlaybookInfo = ({threshold_id, playbook, refetch, bindOptions}) => {
    const isJSON = (string) => {
        if (typeof string !== "object") return false;
        try {
        JSON.parse(string);
        return true;
        } catch (error) {
        return false;
        }
    };
    const {data: playbook_actions, refetch:refetch_actions} = PlaybookApi.GetListAction({playbook_id: playbook?.playbook_id});
    const [showDialogCreatePlayBook ,setShowDialogCreatePlayBook] = React.useState(false);
    const [showDialogCreatePlayBookAction ,setShowDialogCreatePlayBookAction] = React.useState(false);
    const [showAction, setShowAction] = React.useState(false);
    const renderTextValue = (obj) => {
        return isJSON(obj) ? (
        <ReactJson collapsed src={JSON.parse(obj)} />
        ) : typeof obj === "object" ? (
        <ReactJson collapsed src={obj} />
        ) : (
        obj
        );
    };

    const handleEdit = () =>{
        setShowDialogCreatePlayBook(true)
    }
    
    return (
        <>
           {playbook && 
            <Paper sx={{
                p: 3, 
                mb: 1, 
            }}>
                    <Grid container spacing={2}>
                        {Object?.keys(playbook)
                        ?.filter((item) => playbook?.[item])
                        ?.map((item) => (
                            <Grid key={item} item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                {item
                                ?.split("_")
                                ?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))
                                .join(" ")}
                                :
                            </Typography>
                            <Typography sx={{ color: "#00000F" }}>
                                {renderTextValue(playbook?.[item])}
                            </Typography>
                            </Grid>
                        ))}
                    </Grid>
                        <Button variant="outlined"
                        size="small" 
                        sx={{
                            m: "auto", 
                            mt: 2 ,                        
                            borderRadius: 5,
                            ml: 1,
                            mr: 1        
                        }} 
                        onClick={() => handleEdit()}
                       
                        >
                            Edit Playbook
                        </Button>
                        <Button variant="contained"
                        size="small" 
                        sx={{ m: "auto", 
                            mt: 2,
                            borderRadius: 5, 
                            ml: 1,
                            mr: 1
                        }} 
                        onClick={() => setShowDialogCreatePlayBookAction(true)}
                        >
                            Thêm Action
                        </Button>
                        <Button variant="contained"
                        size="small" 
                        sx={{ m: "auto", 
                            mt: 2,
                            borderRadius: 5, 
                            ml: 1,
                            mr: 1
                        }} 
                        onClick={() => setShowAction(!showAction)}
                        >
                            {showAction? "Hide": "Show"} action ({playbook_actions?.length || 0})
                        </Button>

                    {showAction && <PlaybookActionList 
                        actions={playbook_actions || []} 
                        refetch={refetch_actions}
                        threshold_id={threshold_id}
                        playbook={playbook}
                    />}
            </Paper>
            }
            <PlaybookActionEdit
                open={showDialogCreatePlayBookAction}
                handleClose={() => setShowDialogCreatePlayBookAction(false)}
                playbook={playbook}
                refetch={refetch_actions}
                />
            <PlaybookEdit
                open={showDialogCreatePlayBook}
                handleClose={() => setShowDialogCreatePlayBook(false)}
                threshold_id={threshold_id}
                playbook={playbook}
                bindOptions={bindOptions}
                refetch={refetch}
            />
        </>
    );
};

export default PlaybookInfo;
