import ThresholdApi from "@/apis/beta/Threshold/ThresholdApi";
import { Box, Chip, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect } from "react";
import DomainInfo from "./DomainInfo";
import Playbooks from "./Playbooks";
const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
const DomainTab = ({threshold_elastic_id, elastic}) => {
    const [domain, setDomain] = React.useState(1);
    const {data:domains} = ThresholdApi.GetDomainsByElastic({elastic_id:threshold_elastic_id});
    useEffect(() => {
        if (domains?.length > 0 ){ setDomain(domains[0]?.metadata_domain_id)}
    },[domains])
    const handleChangeDomain = (event, newValue) => {
        setDomain(newValue);
    }
    return (
        <>
            <Box sx={{ 
                borderBottom: 1, 
                borderColor: 'divider',
                scrollbarWidth: 'thin',
                mb: 2
            }}>
                <Tabs value={domain} 
                onChange={handleChangeDomain} 
                aria-label="basic tabs example"
                variant="scrollable"
                >
                { domains?.length > 0 ?
                domains?.map(
                    (domain, index) => (
                    <Tab key = {index} label={domain?.domain_name} value={domain?.metadata_domain_id} {...a11yProps(index)} />
                    )
                ):
                <Tab label={'domain'} value={1} {...a11yProps(0)} />
                }
                </Tabs>
            </Box>
            {domains?.length > 0 && <>
                <DomainInfo domain={domains?.find(x => x?.metadata_domain_id === domain)} />
                <Playbooks domains = {domains} threshold_id={elastic?.threshold_elastic_referrer_id} />
            </>
            }
        </>
    );
};

export default DomainTab;
