import TicketApi from "@/apis/beta/Ticket/TicketApi";
import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useInfiniteQuery } from "react-query";
import { useParams } from "react-router";
import LogItem from "./LogItem";
import { InView } from "react-intersection-observer";

const Logs = () => {
  const { id } = useParams();

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      [`ticket.logs.${id}`],
      (pageParams) => {
        return TicketApi.Logs({
          ticket_id: id,
          pageSize: 10,
          page: pageParams.pageParam || 1,
        });
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage?.pagination?.page_next;
        },
      }
    );

  if (isLoading)
    return (
      <Box p={2}>
        <CircularProgress />
      </Box>
    );

  return (
    <Box maxHeight={500} overflow={"auto"}>
      {data?.pages
        ?.reduce((final, item) => {
          final.push(...item?.ticket_logs);
          return final;
        }, [])
        ?.map((item) => (
          <LogItem key={item?.ticket_log_id} item={item} />
        ))}

      <InView
        fallbackInView
        onChange={(InVidew) => {
          if (InVidew && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
          }
        }}
      >
        {({ ref }) => (
          <Box
            ref={ref}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ pb: 1 }}
          >
            {isFetchingNextPage && (
              <CircularProgress size={25} sx={{ mt: 2 }} />
            )}
          </Box>
        )}
      </InView>
    </Box>
  );
};

export default Logs;
