import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";
import queryString from "query-string";

class WarehouseDepreciationApi {
  index = ({ query = null, searchQuery = null, page = 1, pageSize = 20 }) => {
    const paramsQueryString = queryString.stringify(
      {
        page: page,
        page_size: pageSize,
      },
      {
        skipNull: true,
        arrayFormat: "bracket",
        skipEmptyString: true,
      }
    );
    return useQueryWithCustomized(
      [
        "warehouse.warehouse_item.depreciation.list",
        query,
        searchQuery,
        page,
        pageSize,
        paramsQueryString,
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/depreciations?${paramsQueryString} ${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${searchQuery}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_items: data?.data?.warehouse_items.map((o) => ({
                id: o.warehouse_item_id,
                ...o,
              })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            console.log(error.response);
            return {
              warehouse_items: [],
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  statistic = ({ query = null, searchQuery = null }) => {
    return useQueryWithCustomized(
      ["warehouse.warehouse_item.depreciation.statistic", query, searchQuery],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/depreciations/statistical_analysist?${
            query ? `filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${searchQuery}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.statistic;
          })
          .catch((error) => {
            console.log(error.response);
            return {
              statistic: {},
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
}

export default WarehouseDepreciationApi = new WarehouseDepreciationApi();
