import ContractApi from "@/apis/beta/Contract/ContractApi";
import DataGrid from "@/components/DataGrid";
import FormatTextNumeralVND from "@/components/shared/FormatTextNumeralVND";
import { format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import { CONTRACT_NUMBER_STATUS_TYPES } from "@/libs/constants";
import { CellLink, CellStatus } from "@components/DataGrid/Cell";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Toolbar from "./private/Toolbar";
import _ from "lodash";

const CustomerContract = () => {
  const { customer_id } = useParams();
  const [contractIds, setContractIds] = useState([])

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const filterableCustomerContract = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "")
      return null;
    let acceptFilters = [
      {
        name: "contract_id",
        condition: "$eq",
        value: parseInt(debouncedSearchQuery),
        disabled: !parseInt(debouncedSearchQuery),
      },
      {
        name: "contracts.contract_number_id",
        condition: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    const queryNumber = 0;
    let filter = "";
    acceptFilters?.forEach((field, index) => {
      if (field.disabled) return;
      filter += `&filters[$or][${queryNumber + index}][${field?.name}][${field?.condition
        }]=${field?.value}`;
    });
    return filter;
  }, [debouncedSearchQuery]);

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 25,
  });
  const [columns] = React.useState([
    {
      field: "contract_id",
      headerName: "ID",
      width: 60,
      valueGetter: ({ row }) => ({
        label: row?.contract_id,
        href: ["customer_contracts", row?.contract_id],
      }),
      renderCell: ({ value }) => (
        <CellLink
          data={value}
          options={{
            target: "_blank",
          }}
        />
      ),
    },
    // {
    //   field: "contract_number_id",
    //   headerName: "Mã Hợp Đồng",
    //   width: 250,
    // },
    {
      field: "service",
      headerName: "Dịch Vụ",
      width: 350,
      valueGetter: ({ row }) => ({
        label: row?.service_name,
        href: ["services", row?.service_id],
      }),
      renderCell: ({ value }) => <CellLink data={value} />,
    },
    {
      field: "contract_effective_date",
      headerName: "Ngày Bắt Đầu",
      width: 130,
      valueFormatter: ({ value }) => format_date_short(value),
    },
    {
      field: "contract_expiry_date",
      headerName: "Ngày Kết Thúc",
      width: 130,
      valueFormatter: ({ value }) => format_date_short(value),
    },
    {
      field: "contract_value",
      headerName: "Giá Trị HĐ",
      width: 170,
      renderCell: ({ value }) => <FormatTextNumeralVND value={value} />,
    },
    {
      field: "contract_status",
      headerName: "Trạng Thái",
      width: 150,
      valueGetter: ({ value }) =>
        CONTRACT_NUMBER_STATUS_TYPES.find((stt) => stt?.value === value),
      renderCell: ({ value }) => (
        <CellStatus component="muiChip" data={value} />
      ),
    },
    {
      field: "contract_created_at",
      headerName: "Ngày Tạo",
      width: 150,
      valueFormatter: ({ value }) => format_date_short(value),
    }
  ]);

  const { data, isFetching, refetch } = ContractApi.List({
    query: `&filters[$and][0][contracts.customer_owner][$eq]=${customer_id}`,
    searchQuery: filterableCustomerContract(),
    contractType: "CustomerContract",
    page: page?.page + 1,
    pageSize: page?.pageSize,
  });


  const rows = useMemo(() => {
    const uniques = _.groupBy(data?.contracts, (row) => {
      return [row?.contract_number_id?.split('/VNW')[0] + '/VNW'];
    });
    let rows = [];
    _.each(uniques, (values, keys) => {
      const childrens = values;
      const contract = values?.at(-1);
      rows.push({
        hierarchy: [contract?.contract_number_id?.split('/VNW')[0] + '/VNW'],
        id: contract?.contract_id,
        ...contract
      });
      if (childrens?.length > 1) {
        childrens?.filter(x => x?.contract_id !== contract?.contract_id)?.forEach((children) => {
          rows.push({
            hierarchy: [contract?.contract_number_id?.split('/VNW')[0] + '/VNW', children?.contract_number_id?.split('/VNW')[1]],
            ...children,
            id: children?.contract_id,
            className: "row-disabled",
          });
        });
      }
    });
    return rows
  }, [data?.contracts]);


  return (
    <Box sx={{ mt: "10px" }}>
      <DataGrid
        treeData
        groupingColDef={{
          headerName: 'Mã Hợp Đồng',
          width: 250
        }}
        getTreeDataPath={(row) => row?.hierarchy}
        rows={rows || []}
        columns={columns}
        loading={isFetching}
        components={{
          Toolbar: Toolbar
        }}
        componentsProps={{
          toolbar: {
            callback: refetch,
            contractIds,
            setContractIds,
            contracts: data?.contracts,
            inputSearch: {
              placeholder: "Nhập ID/ Mã hợp đồng tìm kiếm...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            page: page?.page || 0,
            pageSize: page?.pageSize,
            rowsPerPageOptions: [10, 25, 50],
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        disableSelectionOnClick
        disableColumnFilter
        rowCount={data?.pagination?.total}
        checkboxSelection
        isRowSelectable={({ row }) => {
          return row?.contract_status !== 0
        }}
        onSelectionModelChange={(props) => {
          setContractIds(preIds => [...props])
        }}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(CustomerContract, {
    feature: "customer",
    action: "customer-contract-index",
  }),
  MuiSkeleton["DataGrid"]
);
