import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import WarehouseRackApi from "@/apis/beta/Warehouse/WarehouseRackApi";
import useDataGridParams from "@/hooks/useDataGridParams";
import DataGridCustomize from "@components/DataGrid";
import PopperAction from "@components/PopperAction";
import { MoreVertOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Drawer,
  IconButton,
  LinearProgress,
  Link,
  Popper,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import FormRack from "../FormRack";
import { styled } from '@mui/material/styles';

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1
}));
const TableRack = ({ warehouse_box, refetchData, warehouse_items, refetchLogs }) => {
  const initialColumns = [
    {
      field: "warehouse_rack_name",
      headerName: "Tên Tủ",
      sortable: true,
      width: 150,
      renderCell: ({ value, row }) => (
        <Link
          component={NavLink}
          to={`/warehouse/racks/${row.warehouse_rack_id}`}
        >
          <Typography className="react-link-table">
            {value?.toUpperCase()}
          </Typography>
        </Link>
      ),
    },
    {
      field: "warehouse_rack_height",
      headerName: "Kích Thước (U)",
      sortable: true,
      width: 150,
    },
    {
      field: "warehouse_rack_used",
      headerName: "Sử dụng",
      width: 230,
      valueGetter: ({ row }) => {
        const used =
          _.sumBy(row.warehouse_items, (o) => o.warehouse_item_form_factor) ||
          0;
        return parseInt((used / row.warehouse_rack_height) * 100);
      },
      renderCell: ({ value, row }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ minWidth: 30 }}>
            <Typography variant="body2" color="text.secondary">{`${_.sumBy(
              row.warehouse_items,
              (o) => o.warehouse_item_form_factor
            )} U`}</Typography>
          </Box>
          <Box sx={{ width: "150px", mr: 1 }}>
            <LinearProgress variant="determinate" value={value} />
          </Box>
          <Box sx={{ minWidth: 30 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
              value
            )}%`}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "warehouse_rack_power_supply",
      headerName: "Nguồn điện tủ (Kw)",
      sortable: true,
      width: 150,
    },
    {
      field: "power_supply",
      headerName: "Nguồn điện sử dụng (Kw)",
      valueGetter: ({ row }) =>
        `${_.sumBy(warehouse_items, (o) =>
          o.warehouse_box_item_reference_id === row.warehouse_rack_id
            ? o?.capacity_power_supply / 2
            : 0
        )}`,
      sortable: true,
      width: 230,
    },
    {
      field: "warehouse_rack_uplink_limit",
      headerName: "Uplink giới hạn (Gb/s)",
      sortable: true,
      width: 200,
    },
    {
      field: "warehouse_rack_uplink_commit",
      headerName: "Uplink ký kết với nhà mạng (Gb/s)",
      sortable: true,
      width: 200,
      renderCell: ({ value }) =>
        value || 0,
    },
    // {
    //   field: "uplink_reality",
    //   headerName: "Uplink đã dùng (Gb/s)",
    //   sortable: true,
    //   width: 150,
    //   valueGetter: ({ row }) =>
    //   {
    //     // let sumUplink = 0;
    //     return `${_.sumBy(row.warehouse_items, "warehouse_box_item_uplinks")}`
    //       // (o) =>
    //       // // o.warehouse_box_item_reference_id === row.warehouse_rack_id
    //       //   // ? o?.warehouse_box_item_uplinks
    //       //   // : 0
    //       //   o?.warehouse_box_item_uplinks
    //     // )}`
    //     // return sumUplink
    //   },
    // },
    {
      field: "server_company",
      headerName: "Server Công Ty",
      valueGetter: ({ row }) =>
        `${_.sumBy(warehouse_items, (o) =>
          o.warehouse_owner_name === "VNETWORK" &&
          o.warehouse_box_item_reference_id === row.warehouse_rack_id &&
          _.includes(server_ids, o.warehouse_model_id)
            ? 1
            : 0
        )}`,
      sortable: true,
      width: 200,
    },
    {
      field: "switch_company",
      headerName: "Swicth Công Ty",
      valueGetter: ({ row }) =>
        `${_.sumBy(warehouse_items, (o) =>
          o.warehouse_owner_name === "VNETWORK" &&
          o.warehouse_box_item_reference_id === row.warehouse_rack_id &&
          _.includes(switch_ids, o.warehouse_model_id)
            ? 1
            : 0
        )}`,
      sortable: true,
      width: 200,
    },
    {
      field: "server_customer",
      headerName: "Server Khách Hàng",
      valueGetter: ({ row }) =>
        `${_.sumBy(warehouse_items, (o) =>
          o.warehouse_owner_name !== "VNETWORK" &&
          o.warehouse_box_item_reference_id === row.warehouse_rack_id &&
          _.includes(server_ids, o.warehouse_model_id)
            ? 1
            : 0
        )}`,
      sortable: true,
      width: 200,
    },
    {
      field: "switch_customer",
      headerName: "Swicth Khách Hàng",
      valueGetter: ({ row }) =>
        `${_.sumBy(warehouse_items, (o) =>
          o.warehouse_owner_name !== "VNETWORK" &&
          o.warehouse_box_item_reference_id === row.warehouse_rack_id &&
          _.includes(switch_ids, o.warehouse_model_id)
            ? 1
            : 0
        )}`,
      sortable: true,
      width: 200,
    },
    {
      field: "warehouse_rack_description",
      headerName: "Mô tả",
      sortable: true,
      width: 300,
    },
    {
      field: "warehouse_racks_started",
      headerName: "Ngày bắt đầu tủ",
      sortable: true,
      width: 300,
    },
    {
      field: "warehouse_rack_date_end",
      headerName: "Ngày kết thúc tủ",
      sortable: true,
      width: 300,
    },
    {
      field: "warehouse_racks_cancelled",
      headerName: "Ngày trả tủ",
      sortable: true,
      width: 300,
      renderCell: ({ value, row }) =>
        {
          if(!row?.warehouse_rack_active){
            return value
          }
          return ""
        },
    },
    {
      field: "warehouse_rack_active",
      headerName: "Trạng thái tủ",
      sortable: true,
      width: 300,
      renderCell: ({ value }) => (value ? <Chip label="Hoạt động" variant="outlined" color="success" size="small"/> : <Chip label="Ngưng hoạt động" variant="outlined" color="error" size="small"/>),
    },
    {
      field: "action",
      headerName: "Thao Tác",
      width: 80,
      renderCell: ({ row, value }) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            title="Thao Tác"
            onClick={(event) => {
              setPopper((prev) => ({
                ...prev,
                anchorEl: event?.target,
                data: row,
              }));
            }}
          >
            <MoreVertOutlined sx={{ m: "auto" }} />
          </IconButton>
        </Box>
      ),
    },
  ];

  const [popper, setPopper] = React.useState({
    anchorEl: undefined,
    data: undefined,
  });

  const [openDrawer, setOpenDrawer] = useState(false);

  const {
    columns,
    debouncedSearchQuery,
    page,
    searchQuery,
    setColumns,
    setPage,
    setSearchQuery,
  } = useDataGridParams(initialColumns);

  const { data: warehouse_models } = WarehouseModelApi.All();

  const { data, isFetching, refetch } = WarehouseRackApi.index({
    page: page?.page + 1 || 1,
    pageSize: page?.pageSize || 20,
    searchQuery: debouncedSearchQuery,
    warehouse_box_id: warehouse_box?.warehouse_box_id,
  });

  const server_ids = [...warehouse_models]
    ?.filter((item) => item?.warehouse_type_name === "SERVER")
    ?.map((item) => item?.warehouse_model_id);

  const switch_ids = [...warehouse_models]
    ?.filter((item) => item?.warehouse_type_name === "SWITCH")
    ?.map((item) => item?.warehouse_model_id);

  return (
    <Box>
      <Button
        variant="contained"
        size="small"
        sx={{ mt: 2 }}
        onClick={() => {
          setPopper({
            anchorEl: undefined,
            data: undefined,
            open: true,
          });
          setOpenDrawer(true);
        }}
      >
        Thêm Rack
      </Button>

      <DataGridCustomize
        sx={{ mt: 2 }}
        rows={data?.warehouse_racks || []}
        columns={columns}
        loading={isFetching}
        rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
        componentsProps={{
          toolbar: {
            store: `racks.list.hiddenColumns`,
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "racks",
            inputSearch: {
              placeholder: "Tìm kiếm,...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({
                ...prevState,
                pageSize: newPageSize,
              })),
          },
        }}
        
        initialState={{ pinnedColumns: {left:["warehouse_rack_name"], right: ['actions', 'action'] } }}
      />

      {popper?.anchorEl !== undefined && (
        <StyledPopper anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)}>
          <PopperAction
            {...popper}
            actions={[
              {
                name: "update",
                handleClick: () => {
                  setOpenDrawer(true);
                  setPopper((prev) => ({ ...prev, anchorEl: undefined }))
                },
                icon: "Edit",
                label: "Chỉnh Sửa",
              },
            ]}
            handleClose={() =>
              setPopper((prev) => ({ ...prev, anchorEl: undefined }))
            }
          />
        </StyledPopper>
      )}

      {openDrawer && (
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <FormRack
            callbackRefetch={() => {
              refetchData();
              refetch();
              refetchLogs();
            }}
            handleClose={() => setOpenDrawer(false)}
            type={popper?.data ? "update" : "create"}
            warehouse_box={warehouse_box}
            warehouse_rack={popper?.data}
          />
        </Drawer>
      )}
    </Box>
  );
};

export default TableRack;
