import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import ReportWatchApi from "@/apis/beta/Threshold/Report/ReportWatchApi";
import ThresholdApi from "@/apis/beta/Threshold/ThresholdApi";
import { useParams } from "react-router";
import { useNotificationContext } from "@contexts/notification";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const renderOption = (option) => {
  return (
    <>
    {option && 
      <MenuItem 
      {...option}
      key={option?.id} 
      sx={{ '&:hover': { background: "#f0f0f0" } }}>
        {option["key"]}
      </MenuItem>
    }
    </>
      
  );
};

function EditElastic({ open, handleClose, threshold ,elastic, refetch, handleResetState}) {
  const { id } = useParams();
  const {
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      uid: elastic ? elastic?.uid : "",
      query: elastic ? JSON?.stringify(elastic?.query) : "{}",
      metadata: elastic ? JSON?.stringify(elastic?.metadata) : "{}",
    },
  });
  const [autoCompleteValue, setAutoCompleteValue] = React.useState("");
  const { setNotification } = useNotificationContext();


  const onSubmit = async (formValues) => {
    if(!formValues?.uid?.replace(/\s/g, '')) {
      return setNotification({
        open: true,
        message: "UID cannot be empty.",
        severity: "error",
      })
    }
    let threshold_elastic_referrer_type = threshold?.threshold_bind?.split("::")[threshold?.threshold_bind?.split("::")?.length - 1]
    threshold_elastic_referrer_type = threshold_elastic_referrer_type.charAt(0).toUpperCase() + threshold_elastic_referrer_type.slice(1)
    const dataForm = {
      ...formValues,
      query: JSON.parse(formValues?.query),
      metadata: JSON.parse(formValues?.metadata),
      threshold_elastic_type: threshold?.threshold_scope?.split("-")[0],
      threshold_elastic_scope: threshold?.threshold_scope,
      threshold_elastic_bind: threshold?.threshold_bind,
      threshold_elastic_referrer_type: threshold_elastic_referrer_type,
    }
    if (action === "Add") {
      const response = await ThresholdApi.CreateElastic({
        threshold_id: id,
        elastic: dataForm
      })
      if (response?.errors) {
        return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: "error" })
      }
      if(refetch) refetch();
      if(handleClose) handleClose();
      return setNotification({ open: true, message: "Liên kết elastic thành công", severity: "success" })
    }else{
      const response = await ThresholdApi.UpdateElastic({
        threshold_id: id,
        elastic: dataForm
      })
      if (response?.errors) {
        return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: "error" })
      }
      if(refetch) refetch();
      if(handleClose) handleClose();
      return setNotification({ open: true, message: "Cập nhật liên kết elastic thành công", severity: "success" })
    }
  };
  const {
    isFetching,
    data,
  } = ReportWatchApi.MlyticList({
    organization_name: autoCompleteValue || ''
  });
  const action = elastic ? "Edit" : "Add";
  const onInputChange = (current_input) => {
    if (!current_input) return;
    if (!current_input?.target?.value) return;
    setAutoCompleteValue(current_input?.target?.value || '');
    onChangeUid(current_input?.target?.value || '');
  }
  const onAutocompleteOnChange = (value) => {
    if (!value?.organizationName) return;
    setAutoCompleteValue(value?.organizationName);
    onChangeUid(value?.customerId);
  }
  const onChangeUid = (value) => {
    setValue('uid', value);
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{action} Elastic</DialogTitle>
          <DialogContent>
            <Box mt={2}>
                  <Autocomplete
                        options={data?.Customers ? data?.Customers : []}
                        getOptionLabel={(option) => option?.organizationName}
                        value={{ organizationName: autoCompleteValue || watch('uid')}}
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id;
                        }}
                        renderOption={renderOption}
                        onChange={(_, newValue) => {
                            if (!newValue) {
                                setAutoCompleteValue('');
                                onChangeUid('');
                            } else {
                                onAutocompleteOnChange(newValue);
                            }
                        }}
                        onInputChange={onInputChange}
                        sx={{ width: '100%', mt: 2 }}
                        renderInput={(params) => <TextField {...params} 
                        label="uid" 
                        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()} />}
                  />
              <Controller
                name="query"
                control={control}
                rules={{
                  required: { message: "query is required", value: true },
                }}
                render={({ field: { onChange, ...field } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        multiline
                        {...field}
                        sx={{ mt: 2 }}
                        label="query"
                        placeholder="query"
                        minRows={3}
                        onChange={(e) => {
                          try {
                            JSON.parse(e?.target?.value?.trim());
                            clearErrors("query");
                          } catch (error) {
                            setError("query", {
                              message: "query is in valid json format",
                            });
                          }

                          onChange(e?.target?.value);
                        }}
                      />
                      {errors?.query?.message && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {errors?.query?.message}
                          </FormHelperText>
                        )}
                    </FormControl>
                  );
                }}
              />
              <Controller
                name="metadata"
                control={control}
                rules={{
                  required: { message: "metadata is required", value: true },
                }}
                render={({ field: { onChange, ...field } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        multiline
                        {...field}
                        label="metadata"
                        sx={{ mt: 2 }}
                        placeholder="metadata"
                        minRows={3}
                        onChange={(e) => {
                          try {
                            JSON.parse(e?.target?.value?.trim());
                            clearErrors("metadata");
                          } catch (error) {
                            setError("metadata", {
                              message: "metadata is in valid json format",
                            });
                          }

                          onChange(e?.target?.value);
                        }}
                      />
                      {errors?.metadata?.message && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {errors?.metadata?.message}
                          </FormHelperText>
                        )}
                    </FormControl>
                  );
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="outlined" onClick={handleClose}>
              Huỷ
            </Button>
            <Button
              size="small"
              disabled={isFetching}
              variant="contained"
              type="submit"
            >
              {isFetching ? <CircularProgress size={25} /> : action}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

export default EditElastic;
