import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import TransitionGroupCustomized from '@components/shared/TransitionGroup'
import { RemoveOutlined } from '@mui/icons-material'
import AddOutlined from '@mui/icons-material/AddOutlined'
import { Box, Collapse, FormControl, IconButton, TextField } from '@mui/material'

const ComponentCustomize = ({
    range = {},
    paymentMethod = 'tierd',
    setState,
    index = 0,
    ranges = []
}) => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            flexDirection: 'row',
            gap: 1.5,
        }}
        >
            <Collapse in={paymentMethod === 'progressive'} sx={{ minWidth: 231.5 }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1.5,
                }}>
                    <FormControl sx={{ minWidth: 100 }}>
                        <TextField size='small'
                            InputProps={{
                                inputComponent: NumericFormatCustom,
                            }}
                            placeholder='Từ...'
                            fullWidth
                            variant='outlined'
                            sx={{ bgcolor: "#FFF" }}
                            value={range?.range_start}
                            onChange={(event) => {
                                setState(preData => [
                                    ...preData?.slice(0, index),
                                    {
                                        ...preData[index],
                                        price_ranges: [
                                            ...preData[index]?.price_ranges?.slice(0, range?.index),
                                            {
                                                ...range,
                                                range_start: event?.target?.value
                                            },
                                            ...preData[index]?.price_ranges?.slice(range?.index + 1)
                                        ]
                                    },
                                    ...preData?.slice(index + 1)
                                ])

                            }}
                        />
                    </FormControl>
                    <FormControl sx={{ minWidth: 130 }}>
                        <TextField size='small'
                            InputProps={{
                                inputComponent: parseFloat(range?.range_end) >= UNLIMITED ? 'input' : NumericFormatCustom,
                            }}
                            placeholder='Đến...'
                            fullWidth
                            variant='outlined'
                            sx={{ bgcolor: "#FFF" }}
                            value={parseFloat(range?.range_end) >= UNLIMITED ? 'UNLIMITED' : range?.range_end}
                            onChange={(event) => {
                                setState(preData => [
                                    ...preData?.slice(0, index),
                                    {
                                        ...preData[index],
                                        price_ranges: [
                                            ...preData[index]?.price_ranges?.slice(0, range?.index),
                                            {
                                                ...range,
                                                range_end: event?.target?.value
                                            },
                                            ...preData[index]?.price_ranges?.slice(range?.index + 1)
                                        ]
                                    },
                                    ...preData?.slice(index + 1)
                                ])
                            }}
                        />
                    </FormControl>
                </Box>
            </Collapse>
            <FormControl fullWidth>
                <TextField size='small'
                    InputProps={{
                        inputComponent: NumericFormatCustom,
                    }}
                    fullWidth
                    variant='outlined'
                    sx={{ bgcolor: "#FFF" }}
                    value={range?.pricing}
                    onChange={(event) => {
                        setState(preData => [
                            ...preData?.slice(0, index),
                            {
                                ...preData[index],
                                price_ranges: [
                                    ...preData[index]?.price_ranges?.slice(0, range?.index),
                                    {
                                        ...range,
                                        pricing: event?.target?.value
                                    },
                                    ...preData[index]?.price_ranges?.slice(range?.index + 1)
                                ]
                            },
                            ...preData?.slice(index + 1)
                        ])
                    }}
                    placeholder='Giá...'
                />
            </FormControl>
            <Collapse in={(paymentMethod === 'progressive')} sx={{ minWidth: 32 }}>
                {(range?.index === 0) &&
                    <IconButton size='small' sx={{
                        border: '1px solid rgba(0, 0, 0, 0.18)',
                        width: 32,
                        minWidth: 32
                    }}
                        onClick={() => {
                            setState(preData => [
                                ...preData?.slice(0, index),
                                {
                                    ...preData[index],
                                    price_ranges: [
                                        ...preData[index]?.price_ranges,
                                        {
                                            range_id: -1,
                                            price_id: -1,
                                            range_start: 0,
                                            range_end: UNLIMITED,
                                            pricing: 0
                                        }]
                                },
                                ...preData?.slice(index + 1)
                            ])
                        }}
                    >
                        <AddOutlined fontSize='small' />
                    </IconButton>}
                {(ranges?.length === range?.index + 1 && range?.index !== 0) && <IconButton size='small' sx={{
                    border: '1px solid rgba(0, 0, 0, 0.18)',
                    width: 32,
                    minWidth: 32
                }}
                    onClick={() => {
                        setState(preData => [
                            ...preData?.slice(0, index),
                            {
                                ...preData[index],
                                price_ranges: [...preData[index]?.price_ranges?.slice(0, range?.index)]
                            },
                            ...preData?.slice(index + 1)
                        ])
                    }}
                >
                    <RemoveOutlined fontSize='small' />
                </IconButton>}
            </Collapse>
        </Box>
    )
}

const PriceExceed = ({ data, setState }) => {
    return (
        <TransitionGroupCustomized
            values={data?.price_ranges?.map((range, index) => {
                return {
                    range: {
                        ...range,
                        index
                    },
                }
            })}
            Component={ComponentCustomize}
            nestedPayload={{
                index: data?.id,
                paymentMethod: data?.payment_config_method,
                setState: setState,
                ranges: data?.price_ranges
            }}
        />
    )
}

export default PriceExceed