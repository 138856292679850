import { Box, Typography, IconButton, Dialog, Button, CircularProgress } from "@mui/material";
import Face6Icon from "@mui/icons-material/Face6";
import React, { useRef, useState } from "react";
import { PREFIX_OF_ATTACHMENT, REACT_APP_DOMAIN_FILE, styleLineClamp } from "@/libs/constants";
import { blue } from "@mui/material/colors";
import moment from "moment";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import TicketApi from "@/apis/beta/Ticket/TicketApi";
import { useConfirmContext } from "@contexts/confirm";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Editor from "@components/Editor";
import { useEmployeeContext } from "@contexts/employee";
import { useNotificationContext } from "@contexts/notification";
import { blobToFile, onAttachmentPushing } from "@/functions";

const CommentItem = ({ item }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);

  const { setNotification } = useNotificationContext()

  const editorRef = useRef()

  const { employee } = useEmployeeContext();

  let [commentTextEdit, setCommentTextEdit] = useState(
    item?.ticket_comment_text
  );

  const handleEditComment = async () => {
    if (!commentTextEdit?.trim()) return;

    const listBlobImage = await editorRef?.current?.getBlobImage()

    if (listBlobImage?.length > 0) {
      const results = await Promise.all(listBlobImage?.map(async (item) => {
        const file = await blobToFile(item, `image_comment_${Date.now()}`)
        const response = await onAttachmentPushing(file, PREFIX_OF_ATTACHMENT.ticket, setNotification)

        return {
          blobURL: item,
          pathImageServer: `${REACT_APP_DOMAIN_FILE}${response?.attachment_file_url}`
        }
      }))

      results.forEach(item => {
        commentTextEdit = commentTextEdit.replace(item?.blobURL, item?.pathImageServer)
      })

      editorRef?.current?.clearBlobImage()
    }

    const response = await TicketApi.EditComment({
      comment_id: item?.ticket_comment_id,
      values: { ticket_comment_text: commentTextEdit },
    });

    if (!response?.errors) {
      queryClient.refetchQueries([`ticket.comments.${id}`]);
      setShow(false);
    }
  };

  const { mutateAsync: DeleteComment, isLoading } = useMutationWithNotification(
    TicketApi.DeleteComment
  );

  const { mutateAsync: EditComment, isLoading: EditCommentLoading } =
    useMutationWithNotification(handleEditComment);

  const { showConfirmation } = useConfirmContext();

  const handleDeleteComment = async () => {
    const response = await DeleteComment({
      comment_id: item?.ticket_comment_id,
    });

    if (!response?.errors) {
      queryClient.refetchQueries([`ticket.comments.${id}`]);
    }
  };

  return (
    <Box
      width="100%"
      mr={2}
      sx={{ border: "1px solid #e0e0e0", borderRadius: 1, px: 1, mb: 1 }}
    >
      <Box display="flex" alignItems="center" gap={2} sx={{ p: '12px 0' }}>
        <Face6Icon color="primary" />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography sx={styleLineClamp(1)}>
            <span style={{ color: blue[500] }}>{item?.employee_name}</span>{" "}
            added a process - {moment(item?.updated_at).fromNow()}
          </Typography>

          {employee?.employee_id === item?.employee_id && (
            <Box alignItems="center">
              <IconButton onClick={() => setShow(true)}>
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => {
                  showConfirmation({
                    title: "Bạn có chắc muốn xoá comment này",
                    open: true,
                    description: "Thao tác này không thể khôi phục",
                    loading: isLoading,
                    handler: handleDeleteComment,
                  });
                }}
              >
                <DeleteOutlineIcon color="error" fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      <Box className="markdown" p={1}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {item?.ticket_comment_text}
        </ReactMarkdown>
      </Box>

      {show && (
        <Dialog
          maxWidth="xl"
          fullWidth
          open={show}
          onClose={() => setShow(false)}
        >
          <Box>
            <Box p={2}>
              <Typography sx={styleLineClamp(1)}>
                <span style={{ color: blue[500] }}>{item?.employee_name}</span>{" "}
                view a process - {moment(item?.updated_at).fromNow()}
              </Typography>
            </Box>
            <Editor
              ref={editorRef}
              model={commentTextEdit}
              onModelChange={(value) => setCommentTextEdit(value)}
            />
            <Box p={2} textAlign="right">
              <Button
                variant="contained"
                size="small"
                onClick={EditComment}
                disabled={EditCommentLoading}
                startIcon={EditCommentLoading && <CircularProgress size={14} />}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

export default CommentItem;
