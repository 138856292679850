import { Badge } from "@mui/material";
import {
  WORK_DAY_HALF,
  WORK_DAY_TYPE_NORMAL,
  WORK_DAY_TYPE_BUSSINESS,
  WORK_DAY_TYPE_LEAVE,
  WORK_DAY_ZERO,
  WORK_DAY_TYPE_HOLIDAY,
} from "../../shared";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarIcon from "@mui/icons-material/Star";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import SnowshoeingIcon from "@mui/icons-material/Snowshoeing";
import { PickersDay } from "@mui/x-date-pickers";
import FestivalIcon from "@mui/icons-material/Festival";
import React from "react";

function resolveIcon(objectHighLight) {
  let Icon;

  switch (objectHighLight?.work_date_type) {
    case WORK_DAY_TYPE_NORMAL:
      objectHighLight?.work_n_day === WORK_DAY_HALF
        ? (Icon = <StarHalfIcon color="warning" fontSize="20px" />)
        : (Icon = <StarIcon color="warning" fontSize="20px" />);
      break;
    case WORK_DAY_TYPE_BUSSINESS:
      Icon = <BusinessCenterIcon color="error" fontSize="20px" />;
      break;
    case WORK_DAY_TYPE_LEAVE:
      Icon = <SnowshoeingIcon color="info" fontSize="20px" />;
      break;
    case WORK_DAY_TYPE_HOLIDAY:
      Icon = <FestivalIcon color="success" fontSize="20px" />;
      break;
    default:
      Icon = <></>;
  }
  return Icon;
}

const HighLightDay = ({ others, highlightedDays }) => {
  const mapingDataServer = highlightedDays?.find((item) => {
    return new Date(item?.work_day_date).getDate() === others?.day.date();
  });

  const objectHighLight =
    !others.outsideCurrentMonth &&
    mapingDataServer?.work_n_day !== WORK_DAY_ZERO &&
    mapingDataServer;

  const Icon = resolveIcon(objectHighLight);

  return (
    <Badge key={others?.day.toString()} overlap="circular" badgeContent={Icon}>
      <PickersDay {...others} />
    </Badge>
  );
};

export default HighLightDay;
