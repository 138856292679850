import ContractApi from "@/apis/beta/Contract/ContractApi";
import FileApi from "@/apis/shared/File/FileApi";
import BorderLinearProgress from "@/components/BorderLinearProgress";
import { CONTRACTS_STATUS } from "@/libs/constants";
import { contractValidator } from "@/libs/validators";
import { MenuProps } from "@/styles/common";
import NumericFormatCustom from "@components/NumericFormatCustom";
import DatePicker from "@components/datepickers/DateRangPicker";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import InputFileCustomize from "@components/shared/InputFileCustomize";
import SingleDocument from "@components/shared/SingleDocument";
import { useNotificationContext } from "@contexts/notification";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ContractOwner from "./ContractOwner";
import ContractService from "./ContractService";
import { format_date_short } from "@/functions";

const ContractFormEdit = ({
  contract = {},
  handleClose,
  callbackContracts = () => console.log('nothing'),
}) => {
  const { setNotification } = useNotificationContext();
  const methods = useForm({
    resolver: vestResolver((props) =>
      contractValidator({
        ...props,
        document: file,
      })
    ),
    defaultValues: {
      contract_key: contract?.contract_key,
      contract_value: contract?.contract_value,
      effective_date: contract?.contract_effective_date,
      expiry_date: contract?.contract_expiry_date,
      contract_status: contract?.contract_status,
      contract_note: contract?.contract_note || "",
      contract_number_id: contract?.contract_number_id,
      contract_referrer_id: contract?.service_id || -1,
      customer_owner: contract?.customer_id || -1,
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = methods;
  const onChangeDates = (values) => {
    setValue(`effective_date`, values[0]);
    return setValue(`expiry_date`, values[1]);
  };

  const [submit, setSubmit] = useState(false);
  const [file, setFile] = useState(null);

  const onSubmit = async (data) => {
    let form = {
      ...data,
      effective_date: data?.effective_date?.$d ? format_date_short(data?.effective_date?.$d) : contract?.contract_effective_date,
      expiry_date: data?.expiry_date?.$d ? format_date_short(data?.expiry_date?.$d) : contract?.contract_expiry_date,
      contract_referrer_type: "Reducer::Override::Service",
    };
    setSubmit(true);
    if (!!file && !!file?.name) {
      const prefixPathname = "contracts/documents";
      const token = await FileApi.GetPresignedTokenAttachment({
        filename: {
          name: file?.name,
          folder: prefixPathname,
        },
      });
      if (!token.token || token.status !== 200) {
        setSubmit(false);
        return setNotification({
          open: true,
          message: "Lấy thông tin xử lý file thất bại",
          severity: "error",
        });
      }
      handleUploadFile((prevState) => ({
        ...prevState,
        loading: true,
        percen: 0,
      }));
      const uploaded = await FileApi.UploadAttachmentToStorage({
        token: token?.token,
        file: file,
        handleUploadFile: handleUploadFile,
      });
      handleUploadFile((prevState) => ({
        ...prevState,
        loading: false,
        percen: 0,
      }));
      if (!uploaded) {
        setSubmit(false);
        return setNotification({
          open: true,
          message: "Tải dữ liệu thất bại",
          severity: "error",
        });
      }

      form = {
        ...form,
        contract_attachment: Array.from([
          {
            attachment_file_name: file?.name,
            attachment_file_type: file?.type,
            attachment_file_size: file?.size,
            attachment_file_url: ["pub", prefixPathname, token?.filename].join(
              "/"
            ),
          },
        ]),
      };
    }
    await ContractApi.updateContractCustomer(contract?.contract_id, {
      contract: form,
    }).then((response) => {
      setSubmit(false);
      if (!response?.contract) {
        return setNotification({
          open: true,
          message: response?.errors?.[0]?.message || "Cập hợp đồng thất bại.",
          severity: "error",
        });
      }
      callbackContracts();
      setNotification({
        open: true,
        message: "Cập hợp đồng thành công.",
        severity: "success",
      });
      handleClose();
    });
  };

  const fileHandler = async (files) => {
    const extension = files[0].name.split(".")[1]?.toLowerCase();
    if (extension === undefined) {
      return setNotification({
        open: true,
        severity: "error",
        message: "Thể loại tập tin không được hỗ trợ",
      });
    }
    return setFile(files[0]);
  };

  const [uploadFile, handleUploadFile] = React.useState({
    loading: false,
    percen: 0,
  });

  return (
    <FormProvider {...methods}>
      <Box sx={{ p: 3, width: 600 }} component="form">
        <Typography
          sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: "medium", mb: 2 }}
        >
          Chỉnh sửa hợp đồng
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="contract_number_id"
              render={({ field }) => (
                <TextField
                  label="Số hợp đồng *"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                  error={!!errors?.contract_number_id}
                  helperText={errors?.contract_number_id?.message}
                  size="small"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <ContractOwner />
          </Grid>
          <Grid item xs={12}>
            <ContractService
              disabled={parseInt(watch(`customer_owner`)) <= 0}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="contract_key"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Từ Khoá *"
                  InputLabelProps={{ shrink: true }}
                  disabled={contract?.contract_status === 1}
                  error={!!errors?.contract_key}
                  helperText={errors?.contract_key?.message}
                  placeholder="Nhập từ khoá..."
                  size="small"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <DatePicker
                value={[
                  dayjs(watch(`effective_date`)),
                  dayjs(watch(`expiry_date`)),
                ]}
                handler={onChangeDates}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="contract_value"
              render={({ field }) => (
                <FormControl fullWidth>
                  <TextField
                    {...field}
                    label="Giá Trị Hợp Đồng *"
                    disabled={contract?.contract_status === 1}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    size="small"
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="contract_status"
              render={({ field: { value, onChange } }) => (
                <FormControl
                  fullWidth
                  disabled={contract?.contract_status === 1}
                >
                  <Select
                    size="small"
                    fullWidth
                    MenuProps={MenuProps}
                    value={value}
                    onChange={(event) => onChange(event?.target?.value)}
                  >
                    {CONTRACTS_STATUS.map((item) => (
                      <MenuItem
                        key={item?.value}
                        disabled={item?.value === 6}
                        sx={{ height: 38, fontSize: 14 }}
                        value={item?.value}
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Tệp Tin Đính Kèm Cũ"
              value={
                contract?.link
                  ? contract?.link
                  : contract?.attachments &&
                    contract?.attachments[0]?.attachment_file_url
                    ? `${process.env.REACT_APP_DOMAIN_FILE
                    }${contract?.attachments[0]?.attachment_file_url.replace(
                      "pub/",
                      ""
                    )}`
                    : "#"
              }
              InputProps={{
                sx: { bgcolor: "#9E9E9E1F" },
              }}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Tệp Tin Đính Kèm *</Typography>
            <InputFileCustomize fileHandler={fileHandler} />
            {!!errors?.file && (
              <ErrorHelperText message={errors?.file?.message} />
            )}
            {file && (
              <React.Fragment>
                <SingleDocument
                  document={file}
                  handleDelete={() => setFile(null)}
                />
                {uploadFile?.loading && (
                  <BorderLinearProgress value={uploadFile?.percen} />
                )}
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="contract_note"
              render={({ field }) => (
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Ghi chú"
                    multiline
                    {...field}
                    placeholder="Nhập ghi chú về hợp đồng..."
                  />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            disabled={submit}
            size="small"
            variant="outlined"
            sx={{ height: 36 }}
            onClick={handleClose}
          >
            Huỷ Thao Tác
          </Button>
          <Button
            disabled={submit}
            size="small"
            onClick={handleSubmit(onSubmit)}
            startIcon={
              submit && (
                <CircularProgress
                  sx={{ m: "auto 0", color: "#777680" }}
                  size={14}
                />
              )
            }
            variant="contained"
            sx={{ ml: 2, height: 36 }}
          >
            Cập nhật hợp đồng
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};
export default ContractFormEdit;
