import { DeleteOutlined } from '@mui/icons-material'
import { Grid, Box, FormControl, IconButton, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

const CustomerContactSocial = () => {
    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control: control,
        name: "customer_contact_social"
    })
    return (
        <Grid container spacing={2} >
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%', md: '40%' }
                }}>
                    <Typography sx={{ fontSize: 14, color: "#777680" }}>
                        Kênh Liên Hệ *
                    </Typography>
                    <Typography color="primary"
                        onClick={() => append({
                            contact_channel: "",
                            contact_channel_information: ""
                        })}
                        sx={{
                            fontSize: 14,
                            textDecoration: "underline",
                            ":hover": {
                                cursor: 'pointer'
                            }
                        }}>
                        + Thêm kênh
                    </Typography>
                </Box>
            </Grid>
            <Grid item xl={9} lg={9} md={8} sm={6} xs={12} >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    {fields && fields.map((field, index) => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                gap: 1.5,
                                borderBottom: {
                                    xs: '1px solid rgba(0, 0, 0, 0.18)',
                                    md: 'unset'
                                },
                                mb: 1.5
                            }} key={field.id}>
                                <Controller
                                    control={control}
                                    name={`customer_contact_social.[${index}].contact_channel`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl fullWidth sx={{
                                                maxWidth: {
                                                    xs: '100%',
                                                    md: '35%'
                                                }
                                            }}>
                                                <TextField
                                                    value={value}
                                                    onChange={onChange}
                                                    size="small"
                                                    placeholder="Ex: Facebook, Zalo,..."
                                                />
                                            </FormControl>
                                        )
                                    }} />
                                <Controller
                                    control={control}
                                    name={`customer_contact_social.[${index}].contact_channel_information`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <FormControl sx={{
                                                maxWidth: {
                                                    xs: 'calc(100% - 8px - 40px)',
                                                    md: 'calc((100% - 12px * 2 - 35% - 40px))'
                                                }
                                            }} fullWidth>
                                                <TextField
                                                    value={value}
                                                    onChange={onChange}
                                                    size="small"
                                                    placeholder="https://www.facebook.com..."
                                                />
                                            </FormControl>
                                        )
                                    }} />
                                <IconButton size='small'
                                    sx={{ width: 40, height: 40, m: 'auto 0 auto auto' }}
                                    onClick={() => remove(index)}>
                                    <DeleteOutlined fontSize='small' sx={{ ":hover": { color: "#ef5350" } }} />
                                </IconButton>
                            </Box>
                        )
                    })}
                </Box>
            </Grid>
        </Grid>
    )
}

export default CustomerContactSocial