import ThresholdApi from "@/apis/beta/Threshold/ThresholdApi";
import {
  THRESHOLD_BIND,
  THRESHOLD_INTERVALS,
  THRESHOLD_SCOPE,
  THRESHOLD_STATUS,
} from "@/libs/constants";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddWatches({
  open,
  handleClose,
  values,
  refetch,
  currentAlarmId,
  handleResetState,
}) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      watch_name: values?.watch_name || "",
      watch_description: values?.watch_description || "",
      watch_status: values?.watch_status || THRESHOLD_STATUS?.[0]?.value,
      watch_scope: values?.watch_scope || THRESHOLD_SCOPE?.[0]?.value,
      watch_bind: values?.watch_bind || THRESHOLD_BIND?.[0]?.value,
      watch_type: values?.watch_type || "",
      watch_interval: values?.watch_interval || THRESHOLD_INTERVALS?.[0]?.value,
      metadata:
        (typeof values?.metadata === "object"
          ? JSON.stringify(values?.metadata)
          : values?.metadata) || "",
    },
  });

  const { mutateAsync, isLoading } = useMutation(ThresholdApi.AddWatch, {
    onError: () => {
      setNotification({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    },
  });

  const { mutateAsync: EditWatchMutation, isLoading: EditWatchLoading } =
    useMutation(ThresholdApi.EditWatch, {
      onError: () => {
        setNotification({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      },
    });

  const { setNotification } = useNotificationContext();

  const onSubmit = async (formValues) => {
    const newValues = {
      ...formValues,
      metadata: JSON.parse(formValues?.metadata),
    };

    let response;

    if (!values) {
      response = await mutateAsync({ ...newValues, alarm_id: currentAlarmId });
    } else {
      response = await EditWatchMutation({
        ...newValues,
        watch_id: values?.watch_id,
      });
    }

    if (response?.errors) {
      return setNotification({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }

    setNotification({
      open: true,
      message: "Success",
      severity: "success",
    });

    handleResetState();
    handleClose();

    refetch();
  };

  const isFetching = isLoading || EditWatchLoading;
  const action = values ? "Edit" : "Add";

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{action} Watches</DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <Controller
                name="watch_name"
                control={control}
                rules={{
                  required: { message: "watch_name is required", value: true },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Watch Name"
                    error={!!errors.watch_name}
                    helperText={errors.watch_name?.message}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%" }}
                  />
                )}
              />

              <Controller
                name="watch_description"
                control={control}
                rules={{
                  required: {
                    message: "watch_description is required",
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Watch Description"
                    error={!!errors.watch_description}
                    helperText={errors.watch_description?.message}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%", mt: 2 }}
                  />
                )}
              />

              <Controller
                name="watch_type"
                control={control}
                rules={{
                  required: { message: "watch_type is required", value: true },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Watch Type"
                    error={!!errors.watch_type}
                    helperText={errors.watch_type?.message}
                    size="small"
                    variant="outlined"
                    sx={{ width: "100%", mt: 2 }}
                  />
                )}
              />

              <Controller
                name="metadata"
                control={control}
                rules={{
                  required: { message: "metadata is required", value: true },
                }}
                render={({ field: { onChange, ...field } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField multiline
                        {...field}
                        sx={{mt: 2}}
                        placeholder="Metadata"
                        minRows={3}
                        onChange={(e) => {
                          try {
                            JSON.parse(e?.target?.value?.trim());
                            clearErrors("metadata");
                          } catch (error) {
                            setError("metadata", {
                              message: "metadata is in valid json format",
                            });
                          }

                          onChange(e?.target?.value);
                        }}
                      />
                      {errors?.metadata?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.metadata?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
              />

              <Box mt={2}>
                <Typography fontSize={14}>Watch Status</Typography>
                <Controller
                  name="watch_status"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        error={!!errors.watch_status}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                        fullWidth
                      >
                        {THRESHOLD_STATUS?.map((item) => (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.watch_status?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.watch_status?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Typography fontSize={14}>Watch Scope</Typography>
                <Controller
                  name="watch_scope"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        error={!!errors.watch_scope}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                        fullWidth
                      >
                        {THRESHOLD_SCOPE?.map((item) => (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.watch_scope?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.watch_scope?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Typography fontSize={14}>Watch Bind</Typography>
                <Controller
                  name="watch_bind"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        error={!!errors.watch_bind}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                        fullWidth
                      >
                        {THRESHOLD_BIND?.map((item) => (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.watch_bind?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.watch_bind?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Typography fontSize={14}>Watch Interval</Typography>
                <Controller
                  name="watch_interval"
                  control={control}
                  defaultValues=""
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Select
                        {...field}
                        error={!!errors.watch_interval}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%", mt: 2 }}
                        fullWidth
                      >
                        {THRESHOLD_INTERVALS?.map((item) => (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.watch_interval?.message && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors?.watch_interval?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="outlined" onClick={handleClose}>
              Huỷ
            </Button>
            <Button size="small" disabled={isFetching} variant="contained" type="submit">
              {isFetching ? <CircularProgress size={25} /> : action}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

export default AddWatches;
