import { Button } from '@mui/material'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import withPermission from '@/hocs/withPermission'

const ButtonUpdateCustomer = () => {
    const { customer_id } = useParams()
    return (
        <Link to={`/customers/${customer_id}/update`} replace>
            <Button variant='contained'
                size='small'
                sx={{ height: { xs: 36, md: 42 } }}
            >
                Cập Nhật Thông Tin
            </Button>
        </Link>
    )
}

export default withPermission(ButtonUpdateCustomer, { feature: "customer", action: "update", type: "button" })