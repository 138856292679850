import React from "react";
import { CircularProgress, Grid, Paper } from "@mui/material";
import BoxStatistical from "./BoxStatistical";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";

const DeviceAnalysist = ({
  type,
  query = "",
  debouncedSearchQuery,
  device = true,
}) => {
  const { data: statistic, isFetching } = WarehouseItemApi.Statistic({
    query,
    searchQuery: debouncedSearchQuery,
    type: type,
  });

  if (isFetching) {
    return (
      <Paper sx={{ py: 4, px: 2, width: "100%" }}>
        <CircularProgress />
      </Paper>
    );
  }

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <BoxStatistical
          title={`Tổng ${device ? "thiết bị" : "linh kiện"}  `}
          value={statistic?.total}
        />
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <BoxStatistical
          title={`Số ${device ? "thiết bị" : "linh kiện"} có sẳn ở Kho`}
          value={statistic?.total_item_box}
        />
      </Grid>
      {device ? (
        <>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <BoxStatistical
              title={`Số ${device ? "thiết bị" : "linh kiện"} có sẳn ở IDC`}
              value={statistic?.total_item_rack}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <BoxStatistical
              title={`Số ${
                device ? "thiết bị chạy dịch vụ" : "linh kiện đang sử dụng"
              }  `}
              value={statistic?.total_item_active}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <BoxStatistical
              title={`Số linh kiện đang sử dụng `}
              value={statistic?.total_item_active + statistic?.total_item_rack}
            />
          </Grid>
        </>
      )}
      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <BoxStatistical
          title={`Số ${device ? "thiết bị" : "linh kiện"} thiết bị hư`}
          value={statistic?.total_item_broken}
        />
      </Grid>
    </Grid>
  );
};

export default DeviceAnalysist;
