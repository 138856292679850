import { TICKET_URGENCY } from "@/libs/constants";
import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import HighLight from "../HightLight";
import { blue } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

const Card = ({ item }) => {
  const navigate = useNavigate();

  return (
    <>
      <HighLight
        cardId={item?.ticket_id}
        column={item?.ticket_status?.ticket_status_id}
      />

      <div
        draggable
        onDragStart={(e) => {
          e.dataTransfer.setData("cardId", item?.ticket_id);
        }}
      >
        <Box
          sx={{
            p: 2,
            cursor: "pointer",
            border: "1px solid #ccc",
            borderRadius: "8px",
            backgroundColor: "#fff",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                "&:hover": {
                  color: blue[900],
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
              onClick={() => navigate(`/tickets/${item?.ticket_id}/detail`)}
              fontWeight={600}
              color="GrayText"
            >
              DC Ticket - {item?.ticket_id}
            </Typography>

            <Chip
              sx={{ minWidth: 100 }}
              size="small"
              fontSize={14}
              label={
                TICKET_URGENCY.find((p) => p.value === item?.ticket_urgency)
                  ?.label || ""
              }
              color={
                item?.ticket_urgency === 0
                  ? "success"
                  : item.ticket_urgency === 1
                  ? "warning"
                  : "error"
              }
            />
          </Box>

          <Typography mt={1} className="preserve-whitespace">
            {item?.ticket_content || "Không có nội dung mô tả"}
          </Typography>

          <Box display="flex" alignItems="center" gap={2} mt={2}>
            <Chip
              sx={{ minWidth: 100 }}
              size="small"
              fontSize={14}
              label={item?.employee?.employee_name}
              color="primary"
            />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Card;
