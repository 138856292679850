import { Collapse, List } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'

const TransitionGroupCustomized = ({
    children,// JSX Element
    values = [],
    Component = () => <></>,
    nestedPayload = {}
}) => {

    return (
        <>
            {children}
            <List dense sx={{ mt: '10px' }}>
                <TransitionGroup>
                    {values?.map((item, index) => {
                        return (
                            <Collapse key={index} sx={{ mb: 1.5 }}>
                                <Component {...item} {...nestedPayload} />
                            </Collapse>
                        )
                    })}
                </TransitionGroup>
            </List>
        </>
    )
}
export default TransitionGroupCustomized