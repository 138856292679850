import InvoiceApi from '@/apis/beta/Invoice/InvoiceApi'
import { format_date_short, format_numeral_price, isObjEmpty, removeObjectKeys } from '@/functions'
import withPermission from '@/hocs/withPermission'
import { INVOICE_PAYMENT_METHODS } from '@/libs/constants'
import ModalConfirm from '@components/shared/ModalConfirm'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import { Button, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
const PaymentTransaction = ({
    disabled = true,
    setDisabled,
    callback,
    ...invoice
}) => {
    const { watch, getValues, formState: { errors }, handleSubmit } = useFormContext()
    const [openModal, setOpenModal] = React.useState(false)
    const onSubmit = async () => {
        if (!isObjEmpty(errors)) {
            return {
                status: 404,
                message: Object.keys(errors)?.map((key) => {
                    return errors?.[key]?.message
                })?.join(' & ')
            }
        }
        let form = removeObjectKeys(getValues(), "commissions")
        form = {
            ...form,
            invoice_paid: format_date_short(form?.invoice_paid)
        }
        const transaction = await InvoiceApi.CreateTransaction({
            invoiceId: invoice?.invoice_id,
            data: form
        })
        if (!transaction || transaction?.errors) {
            return {
                message: transaction?.errors?.[0]?.message || "Thanh toán hoá đơn thất bại. Vui lòng thử lại!",
                status: 404
            }
        }

        return {
            message: `Thanh toán hoá đơn thành công.`,
            status: 200
        }
    }

    const handlerClick = () => {
        if (openModal) {
            setDisabled(false)
            return setOpenModal(false);
        }
        setDisabled(true)
        return setOpenModal(true)
    }
    return (
        <React.Fragment>
            {openModal && <ModalConfirm
                callback={callback}
                handleClose={handlerClick}
                handleSubmit={onSubmit}
                dialog={{
                    title: "Thanh Toán Hoá Đơn",
                    content: <Typography component="div">
                        Hoá đơn số
                        <Typography sx={{ display: 'inline', fontWeight: 'medium', m: 'auto 4px' }}>
                            [{invoice?.invoice_id}]
                        </Typography>
                        sẽ được thanh toán với số tiền
                        <Typography sx={{ display: 'inline', fontWeight: 'medium', m: 'auto 0 auto 4px', textDecoration: 'underline' }}>
                            đ
                        </Typography>
                        <Typography sx={{ display: 'inline', fontWeight: 'medium', m: 'auto 4px auto 0' }}>
                            {format_numeral_price(watch("transaction_amount"))}
                        </Typography>
                        với phương thức thanh toán là
                        <Typography sx={{ display: 'inline', fontWeight: 'medium', m: 'auto 4px' }}>
                            {INVOICE_PAYMENT_METHODS.find(method => method.value === watch("payment_method"))?.label}.
                        </Typography>
                        Thao tác sẽ không thể phục hồi, bạn có muốn thực hiện?
                    </Typography>
                }} />}
            <Button size='small'
                variant='contained'
                fullWidth
                disabled={disabled}
                onClick={handleSubmit(handlerClick)}
                startIcon={<PaidOutlinedIcon />}
            >
                Thanh toán hoá đơn
            </Button>
        </React.Fragment>
    )
}

export default withPermission(PaymentTransaction, { feature: "invoice", action: "invoice-transaction-create", type: "button" })