import JourneyApi from "@/apis/beta/Customer/Journey/JourneyApi";
import { queryClientRefetcher } from "@/functions";
import { useNotificationContext } from "@contexts/notification";
import { LockClockOutlined } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useQueryClient } from "react-query";

const Locked = ({ customerId, journeyId }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = useState(false);

  const lockedJourney = async () => {
    setSubmit(true);

    const response = await JourneyApi.Locked({
      customerId,
      journeyId,
    });
    setSubmit(false);

    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }

    queryClientRefetcher(queryClient, ["customers.journeys"]);
    setNotification({
      open: true,
      message: `Đóng quá trình [Journey - ${journeyId}] thành công.`,
      severity: "success",
    });
  };
  return (
    <Button
      size="small"
      variant="outlined"
      onClick={lockedJourney}
      startIcon={
        submit ? (
          <CircularProgress size={14} sx={{ color: "#777680" }} />
        ) : (
          <LockClockOutlined fontSize="small" />
        )
      }
    >
      Kết Thúc Tiến Trình Này | Failed
    </Button>
  );
};

export default Locked;
