import { CUSTOMER_SOURCES } from '@/libs/constants'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Grid, FormControl, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const CustomerSource = () => {
    const { control } = useFormContext()
    return (
        <Grid container spacing={2}>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Nguồn Khai Thác
                </Typography>
            </Grid>
            <Grid item xl={9} lg={9} md={8} sm={6} xs={12}>
                <Controller
                    control={control}
                    name="customer_source"
                    render={({ field: { value, onChange } }) => {
                        const selected = CUSTOMER_SOURCES.find(item => item?.value === value) || null
                        return (
                            <FormControl fullWidth>
                                <SingleSelectCustomize
                                    disabled
                                    onChange={(event, newValue) => onChange(newValue?.value || -1)}
                                    value={selected}
                                    options={CUSTOMER_SOURCES} />
                            </FormControl>
                        )
                    }} />
            </Grid> </Grid>
    )
}

export default CustomerSource