import InvoiceItemApi from "@/apis/beta/Invoice/InvoiceItemApi";
import { queryClientRefetcher } from "@/functions";
import withPermission from "@/hocs/withPermission";
import { BaseComponents } from "@components/Components";
import DatePicker from "@components/DatePicker";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const Create = ({ onClose, invoiceId, invoiceItem, customerId }) => {
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);
  const queryClient = useQueryClient();

  const [state, setState] = React.useState(false);

  const methods = useForm({
    defaultValues: {
      invoice_item_name: invoiceItem?.invoice_item_name,
      invoice_item_description: "",
      invoice_item_value: 1,
      invoice_item_subtotal: 0,
      invoice_item_deposit: 0,
      invoice_item_amount: 0,
      invoice_item_tax: true,
      invoice_item_pricing: 0,
      invoice_item_pricing_apply: 0,
      invoice_item_referrer_type: invoiceItem?.invoice_item_referrer_type,
      invoice_item_referrer_id: invoiceItem?.invoice_item_referrer_id,
      invoice_item_startdate: invoiceItem?.invoice_item_startdate,
      invoice_item_enddate: invoiceItem?.invoice_item_enddate,
    },
  });
  const { control, handleSubmit, watch, setValue } = methods;

  const onSubmit = async (values) => {
    setSubmit(true);
    let form = { ...values, service_id: values?.invoice_item_referrer_id };
    const response = await InvoiceItemApi.Create({
      invoiceId: invoiceId,
      data: form,
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    queryClientRefetcher(queryClient, [
      "invoices.list",
      "invoices.statistic",
      "service.invoices",
      "customer.invoices",
      "invoice.detail",
      "invoice.invoice_items",
      "invoice.informations",
      "invoice.transactions.list",
      "invoice.loggers",
    ]);
    setNotification({
      open: true,
      message:
        response?.data?.message || "Cập nhật thông tin hoá đơn thành công",
      severity: "success",
    });
    return onClose();
  };

  React.useEffect(() => {
    let subtotal =
      parseFloat(watch("invoice_item_value")) *
        parseFloat(watch("invoice_item_pricing_apply")) || 0;
    return setValue(`invoice_item_subtotal`, subtotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("invoice_item_value"), watch("invoice_item_pricing_apply")]);

  React.useEffect(() => {
    let amount =
      parseFloat(watch("invoice_item_subtotal")) -
        parseFloat(watch("invoice_item_deposit")) || 0;
    return setValue(`invoice_item_amount`, amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("invoice_item_subtotal"), watch("invoice_item_deposit")]);

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogTitle>
        Bạn Đang Thao Tác Thêm Dòng Trên Hoá Đơn [Invoice - {invoiceId}]
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: 1.5, mt: 2 }}
          >
            <FormControl fullWidth>
              <FormControlLabel
                label="Thêm Dòng Hoá Đơn Chiết Khấu (Miễn/ Giảm)"
                control={
                  <Checkbox
                    size="small"
                    checked={state}
                    onChange={(event) => {
                      setState(event?.target?.checked);
                      setValue(`invoice_item_pricing_apply`, 0);
                      return setValue(`invoice_item_value`, 1);
                    }}
                  />
                }
              />
            </FormControl>
            <Controller
              control={control}
              name="invoice_item_referrer_id"
              rules={{ required: "Chọn dịch vụ liên kết" }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FormControl fullWidth>
                    <BaseComponents.Service
                      name="invoice_item_referrer_id"
                      value={value}
                      onChange={onChange}
                      customerId={customerId}
                    />
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="invoice_item_name"
              rules={{ required: "Description đang để trống" }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      label="Invoice item name *"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      value={value}
                      onChange={onChange}
                      placeholder="Mô tả item hoá đơn..."
                      error={!!error}
                      helperText={error?.message || ""}
                    />
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="invoice_item_description"
              rules={{ required: "Description đang để trống" }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      label="Invoice item description *"
                      InputLabelProps={{ shrink: true }}
                      value={value}
                      onChange={onChange}
                      multiline
                      minRows={2}
                      InputProps={{
                        sx: { fontSize: 13 },
                      }}
                      placeholder="Mô tả item hoá đơn..."
                      error={!!error}
                      helperText={error?.message || ""}
                    />
                  </FormControl>
                );
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 1.5,
              }}
            >
              {!state && (
                <Controller
                  control={control}
                  name="invoice_item_value"
                  rules={{ required: "Thông số & Giá Trị *" }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <FormControl fullWidth>
                        <TextField
                          label="Value"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                          }}
                          size="small"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error?.message || ""}
                        />
                      </FormControl>
                    );
                  }}
                />
              )}
              {!state && (
                <Controller
                  control={control}
                  name="invoice_item_pricing_apply"
                  rules={{ required: "Đơn giá bán *" }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <FormControl fullWidth>
                        <TextField
                          label="Đơn giá bán *"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                          }}
                          size="small"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error?.message || ""}
                        />
                      </FormControl>
                    );
                  }}
                />
              )}
            </Box>
            {!state && (
              <Controller
                control={control}
                name="invoice_item_subtotal"
                rules={{ required: "Tạm tính" }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        label={
                          state
                            ? "Số Tiền Chiết Khấu (Miễn/ Giảm) *"
                            : "Tạm tính *"
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message || ""}
                      />
                    </FormControl>
                  );
                }}
              />
            )}
            <Controller
              control={control}
              name="invoice_item_deposit"
              rules={{ required: "Số Tiền Chiết Khấu (Miễn/ Giảm)" }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      label="Số Tiền Chiết Khấu (Miễn/ Giảm) *"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                      size="small"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message || ""}
                    />
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="invoice_item_amount"
              rules={{ required: "Thành tiền *" }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      label="Thành tiền *"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                      size="small"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message || ""}
                    />
                  </FormControl>
                );
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 1.5,
              }}
            >
              <Controller
                control={control}
                name="invoice_item_startdate"
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl fullWidth>
                      <DatePicker
                        label="Bắt đầu *"
                        value={value}
                        onChange={onChange}
                      />
                    </FormControl>
                  );
                }}
              />
              <Controller
                control={control}
                name="invoice_item_enddate"
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormControl fullWidth>
                      <DatePicker
                        label="Kết thúc *"
                        value={value}
                        onChange={onChange}
                      />
                    </FormControl>
                  );
                }}
              />
            </Box>
            <Controller
              control={control}
              name="invoice_item_tax"
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl fullWidth>
                    <FormControlLabel
                      label={state ? "Giảm trước thuế" : "VAT"}
                      control={
                        <Checkbox
                          size="small"
                          checked={value}
                          onChange={onChange}
                        />
                      }
                    />
                  </FormControl>
                );
              }}
            />
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          disable={submit}
          variant="outlined"
          onClick={onClose}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          size="small"
          disable={true}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Lưu Thay Đổi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withPermission(Create, {
  feature: "invoice",
  action: "invoice-item-update",
  type: "Modal",
});
