import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseProcessApi from "@/apis/beta/Warehouse/WarehouseProcessApi";
import { format_date_short } from "@/functions";
import { format_pricing_vnd } from "@/libs/constants";
import DatePicker from "@components/DatePicker";
import NumericFormatCustom from "@components/NumericFormatCustom";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { useEmployeeContext } from "@contexts/employee";
import WarehouseProcessLogApi from "@/apis/beta/Warehouse/WarehouseProcessLogApi";

const FormWarehouseItemDepreciate = ({
  refetchProcessLogs,
  modal,
  warehouse_process = null,
  callbackData,
  setModal,
}) => {
  const { control, getValues, setValue, handleSubmit } = useForm({
    defaultValues: modal.data.depreciation || {},
  });
  const {employee} = useEmployeeContext();
  const {
    data: { warehouse_item },
  } = WarehouseItemApi.Detail(modal.data?.warehouse_item_id);

  const onChangeDepreciateDate = () => {
    const from = new Date(getValues("depreciation_start"));
    let to = new Date(getValues("depreciation_end"));
    to.setDate(to.getDate() + 1);

    if (from >= to) return;

    const months =
      to.getMonth() -
      from.getMonth() +
      12 * (to.getFullYear() - from.getFullYear());

    setValue(
      "depreciation_unit",
      parseInt(
        Math.round(
          warehouse_item?.warehouse_item_price / (months === 0 ? 1 : months)
        )
      )
    );
  };

  const handle = async (form) => {
    const response = await WarehouseItemApi.updateDepreciation(
      warehouse_item.warehouse_item_id,
      form
    );

    if (!response?.errors && warehouse_process) {
      const depreciation = response?.data;

      const warehouse_process_metadata = _.map(
        warehouse_process.warehouse_process_metadata,
        (o) => {
          const item = o;
          if (item.warehouse_item_id === depreciation.warehouse_item_id) {
            item.depreciation = depreciation;
          }
          return item;
        }
      );

      const updateWarehouseProcess = await WarehouseProcessApi.Update({
        id: warehouse_process?.warehouse_process_id,
        params: { warehouse_process_metadata: warehouse_process_metadata },
      });

      if (!updateWarehouseProcess?.errors) {
        notify("success", "Success");
        setModal({ show: false });
        callbackData();
        return;
      }
    }

    if (!response?.errors) {
      notify("success", "Success");
      setModal({ show: false });
      callbackData();
    }
  };

  const { mutateAsync, isLoading, notify } =
    useMutationWithNotification(handle);

  const onSubmit = async (data) => {
    const form = data;
    form.warehouse_item_id = warehouse_item?.warehouse_item_id;
    form.depreciation_price = warehouse_item?.warehouse_item_price;
    mutateAsync(form);
    const action_process = modal?.process?.warehouse_process_code?.split("-")[0]?.toLowerCase();
      const warehouse_process_log = {
        warehouse_process_id: modal?.process?.warehouse_process_id,
        warehouse_process_name: modal?.process?.warehouse_process_code,
        warehouse_process_update_type: `UPDATE-${action_process?.toUpperCase()}`,
        warehouse_process_updater_id: employee?.employee_id,
        warehouse_process_updater_name: employee?.employee_name,
        metadata: {
          data: modal?.process
        },
        warehouse_process_update_description: `Cập nhật thời gian khấu hao ${modal?.process?.warehouse_process_code}`,
      }
      await WarehouseProcessLogApi.createProcessLog(warehouse_process_log);
      notify("success", "Success");
      if (callbackData) callbackData();
      if (refetchProcessLogs) refetchProcessLogs();
  };

  return (
    <>
      <Dialog
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        open={modal.show}
        fullWidth={true}
        onClose={() => setModal({ show: false })}
        maxWidth={"sm"}
      >
        <DialogContent sx={{ minHeight: "400px" }}>
          <Grid container spacing={1}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Box>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                  Service_Tag/ Series Number:
                </Typography>
                <Typography sx={{ color: "#00000F" }}>
                  {warehouse_item?.warehouse_item_service_tag}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Box>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>Tên:</Typography>
                <Typography sx={{ color: "#00000F" }}>
                  {modal.data?.warehouse_item_name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Box>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                  Ngày Nhập:
                </Typography>
                <Typography sx={{ color: "#00000F" }}>
                  {format_date_short(warehouse_item?.warehouse_item_import_at)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Box>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                  Tiền Nhập:
                </Typography>
                <Typography sx={{ color: "#00000F" }}>
                  {format_pricing_vnd(
                    Number(warehouse_item?.warehouse_item_price)
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                  Ngày bắt đầu khấu hao *
                </Typography>
                <Controller
                  name="depreciation_start"
                  defaultValue={new Date()}
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <FormControl fullWidth>
                      <DatePicker
                        fullWidth
                        {...field}
                        onChange={(newValue) => {
                          onChange(new Date(newValue));
                          onChangeDepreciateDate();
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                  Ngày kết thúc khấu hao *
                </Typography>
                <Controller
                  name="depreciation_end"
                  defaultValue={new Date()}
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <FormControl fullWidth>
                      <DatePicker
                        fullWidth
                        {...field}
                        onChange={(newValue) => {
                          onChange(new Date(newValue));
                          onChangeDepreciateDate();
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography sx={{ mb: 0.5, color: "#777680" }}>
                Giá trị khấu hao trung bình *
              </Typography>
              <Controller
                control={control}
                defaultValue={0}
                name="depreciation_unit"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <TextField
                      {...field}
                      size="small"
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                      sx={{ mt: 1 }}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}
          >
            <Button
              disabled={isLoading}
              onClick={() => setModal({ show: false })}
              size="small"
              variant="outlined"
              sx={{ height: 36 }}
            >
              Huỷ Thao Tác
            </Button>
            <Button
              disabled={isLoading}
              size="small"
              type="submit"
              startIcon={
                isLoading && (
                  <CircularProgress
                    sx={{ m: "auto 0", color: "#777680" }}
                    size={14}
                  />
                )
              }
              variant="contained"
              sx={{ height: 36 }}
            >
              Cập Nhật Khấu Hao
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default withSuspense(
  FormWarehouseItemDepreciate,
  MuiSkeleton["LinearProgress"]
);
