import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const TicketMeeting = () => {
    const { control } = useFormContext()
    return (
        <Controller
            name="meeting"
            control={control}
            render={({ field }) => (
                <TextField
                    {...field}
                    label="Hình thức meeting"
                    InputLabelProps={{
                        shrink: true
                    }}
                    size="small"
                    fullWidth
                    placeholder='Nhập link/ địa chỉ...'
                    helperText="Lưu ý: nếu online vui lòng điền link meeting vào còn offline thì điền địa chỉ vào đây"
                />
            )}
        />
    )
}

export default TicketMeeting