import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import ProductGroupApi from "@/apis/beta/Product/ProductGroupApi";
import { useNotificationContext } from "@/contexts/notification";
import { queryClientRefetcher, selectObjectKeys, sleep } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { SERVICES } from "@/libs/constants";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import CompanyInformation from "../../../Company/Detail";
import CustomerUpdates from "../CustomerUpdates";
import MetadataButtons from "./Buttons";
import CustomerCSFollowers from "./shared/CustomerCSFollowers";
import CustomerContactSocial from "./shared/CustomerContactSocial";
import CustomerEmail from "./shared/CustomerEmail";
import CustomerIndustry from "./shared/CustomerIndustry";
import CustomerName from "./shared/CustomerName";
import CustomerNote from "./shared/CustomerNote";
import CustomerPhone from "./shared/CustomerPhone";
import CustomerPosition from "./shared/CustomerPosition";
import CustomerSaleFollowers from "./shared/CustomerSaleFollowers";
import CustomerServiceInteresting from "./shared/CustomerServiceInteresting";
import CustomerSource from "./shared/CustomerSource";
import CustomerStatus from "./shared/CustomerStatus";
import CustomerContactInformation from "../../../CustomerContact/Detail";
const CustomerInformation = () => {
  const queryClient = useQueryClient();

  const [submit, setSubmit] = React.useState(null);
  const { customer_id } = useParams();
  const { data: customer } = CustomerApi.Detail(customer_id);
  const { data } = ProductGroupApi.List();

  const { setNotification } = useNotificationContext();

  const methods = useForm({
    defaultValues: {},
  });

  const onReset = async () => {
    setSubmit("reset");
    try {
      methods.reset({
        customer_name: customer?.customer_information?.name,
        customer_phone: customer?.customer_information?.phone_number,
        customer_gender: customer?.customer_information?.gender,
        customer_email: customer?.customer_information?.email,
        customer_position: customer?.customer_information?.position,
        customer_dob: customer?.customer_information?.birthday,
        customer_industry:
          customer?.customer_service_information?.customer_industry,
        customer_source:
          customer?.customer_service_information?.customer_source,
        customer_service_interestings:
          SERVICES.filter((item) =>
            customer?.customer_service_information?.customer_service_interestings?.includes(
              item?.value
            )
          ) || [],
        customer_sale_followers:
          customer?.customer_service_information?.customer_sale_followers || [],
        customer_cs_followers:
          customer?.customer_service_information?.customer_cs_followers || [],
        customer_note:
          customer?.customer_service_information?.customer_note || "",
        customer_contact_social: {
          contact_channel:
            customer?.customer_service_information?.customer_contact_social
              ?.contact_channel || "",
          contact_channel_information:
            customer?.customer_service_information?.customer_contact_social
              ?.contact_channel_information || "",
        },
      });
    } catch (error) {
      alert("Customer Information Exception: " + error.message)
    }
    await sleep(500);
    setSubmit(null);
  };

  useEffect(() => {
    methods.setValue("customer_name", customer?.customer_information?.name);
    methods.setValue(
      "customer_phone",
      customer?.customer_information?.phone_number
    );
    methods.setValue("customer_gender", customer?.customer_information?.gender);
    methods.setValue("customer_email", customer?.customer_information?.email);
    methods.setValue(
      "customer_position",
      customer?.customer_information?.position
    );
    methods.setValue("customer_dob", customer?.customer_information?.birthday);
    methods.setValue(
      "customer_industry",
      customer?.customer_service_information?.customer_industry
    );
    methods.setValue(
      "customer_industry_field",
      customer?.customer_service_information?.customer_industry_field
    );
    methods.setValue(
      "customer_source",
      customer?.customer_service_information?.customer_source
    );
    methods.setValue(
      "customer_service_interestings",
      data
        .filter((item) =>
          customer?.customer_service_information?.customer_service_interestings?.includes(
            item?.product_group_name?.toUpperCase()
          )
        )
        ?.map((item) => ({
          value: item?.product_group_name?.toUpperCase(),
          label: item?.product_group_name,
        })) || []
    );
    methods.setValue(
      "customer_sale_followers",
      customer?.customer_service_information?.customer_sale_followers || []
    );
    methods.setValue(
      "customer_cs_followers",
      customer?.customer_service_information?.customer_cs_followers || []
    );
    methods.setValue(
      "customer_note",
      customer?.customer_service_information?.customer_note || ""
    );
    methods.setValue(
      "customer_contact_social",
      customer?.customer_service_information?.customer_contact_social
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id, customer]);

  const onSubmit = async (values) => {
    setSubmit("submit");
    let form = {
      ...selectObjectKeys(values, [
        "customer_name",
        "customer_phone",
        "customer_gender",
        "customer_position",
        "customer_email",
        "customer_dob",
        "customer_industry",
        "customer_note",
        "customer_source",
        "customer_contact_social",
      ]),
      customer_sale_followers:
        values?.customer_sale_followers?.map(
          ({ employee_id }) => employee_id
        ) || [],
      customer_cs_followers:
        values?.customer_cs_followers?.map(({ employee_id }) => employee_id) ||
        [],
      customer_service_interestings: values?.customer_service_interestings?.map(
        (item) => item?.value
      ),
    };

    const response = await CustomerApi.Update({
      customerId: customer_id,
      data: form,
    });
    await sleep(500);
    setSubmit(null);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message:
          response?.errors?.[0]?.message ||
          "Cập nhật thông tin khách hàng thất bại.",
        severity: "error",
      });
    }
    queryClientRefetcher(queryClient, [
      "customers.list",
      "customers.statistical",
      "customers.detail",
      "customers.statistics",
      "customers.all",
      "company.company_updates",
    ]);
    return setNotification({
      open: true,
      message: response?.message || "Cập nhật thông tin khách hàng thành công.",
      severity: "success",
    });
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          gap: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          {customer?.customer_service_information?.cid > -1 && (
            <CompanyInformation
              customer={customer}
              companyId={customer?.customer_service_information?.cid}
            />
          )}
          <FormProvider {...methods}>
            <Paper
              component="form"
              sx={{
                display: "flex",
                width: "100%",
                p: 3,
                flexDirection: "column",
                gap: 1.5,
                mb: 2,
              }}
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              {customer?.customer_service_information?.customer_source ===
                "self" && (
                  <Box
                    sx={{ p: "20px", borderRadius: "14px", bgcolor: "#FF980014" }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <ErrorOutlineOutlinedIcon
                        sx={{
                          width: 24,
                          height: 24,
                          color: "#FF9800",
                          m: "auto 8px auto 0",
                        }}
                      />
                      <Typography sx={{ color: "#FF9800" }}>
                        SaaS Customer
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        flexWrap: "wrap",
                        textAlign: "justify",
                        ml: 4,
                        mt: 0.5,
                      }}
                    >
                      {
                        "Khách hàng tự đăng kí tài khoản trên VNETWORK Cloud Platform."
                      }
                    </Typography>
                  </Box>
                )}
              <CustomerStatus {...customer} />
              <CustomerName {...customer} />
              <CustomerEmail {...customer} />
              <CustomerPhone {...customer} />
              <CustomerPosition {...customer} />
              <CustomerIndustry />
              <CustomerServiceInteresting />
              <CustomerSource />
              <CustomerSaleFollowers />
              <CustomerCSFollowers />
              <CustomerContactSocial />
              <CustomerNote />
              {customer?.customer_service_information?.customer_status !==
                "cancelled" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: { xs: "column", md: "row" },
                      gap: 2,
                      mt: 1,
                    }}
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      disabled={!!submit}
                      onClick={onReset}
                      startIcon={
                        !!submit &&
                        submit === "reset" && (
                          <CircularProgress size={14} sx={{ color: "#777680" }} />
                        )
                      }
                      sx={{ height: { xs: 36, md: 42 }, minWidth: 150 }}
                    >
                      Huỷ thao tác
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      disabled={!!submit}
                      startIcon={
                        !!submit &&
                        submit === "submit" && (
                          <CircularProgress size={14} sx={{ color: "#777680" }} />
                        )
                      }
                      type="submit"
                      sx={{ height: { xs: 36, md: 42 }, minWidth: 150 }}
                    >
                      cập nhật
                    </Button>
                  </Box>
                )}
            </Paper>
          </FormProvider>
          {/* <CustomerContact {...customer}/> */}
          {/* <CustomerContactInformation {...customer}/> */}
        </Box>
        <Box sx={{ maxWidth: { xs: "100%", md: "20%" } }}>
          <MetadataButtons
            customer={customer}
            packages={[
              "ChangeStatus",
              "Switch",
              "AddressInformation",
              "UpdateCustomer",
              "SoftDelete",
              "AddContact",
            ]}
          />
        </Box>
      </Box>
      <CustomerUpdates
        customerId={customer?.customer_id}
        companyId={customer?.customer_service_information?.cid}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(CustomerInformation, { feature: "customer", action: "show" }),
  MuiSkeleton["GridInformation"]
);
