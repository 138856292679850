import { format_numeral_price } from '@/functions'
import { AddOutlined, DeleteOutlineOutlined } from '@mui/icons-material'
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import _ from 'lodash'
import ClusterConfig from './ServiceConfig'
import ServicePriceExceed from './ServicePriceExceed'

const VnisServicePreview = ({
    data: product = {}, remover, insert
}) => {
    return (
        <Box sx={{ p: '16px 24px', border: '1px solid rgba(0, 0, 0, 0.18)', borderRadius: '4px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.25, alignItems: 'center' }}>
                <Typography sx={{ fontSize: 14, fontWeight: 'medium' }} color="primary">
                    {product?.product_name}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 0.25 }}>
                    <IconButton size='small' onClick={() => insert(product)}>
                        <AddOutlined color="primary" fontSize='small' />
                    </IconButton>
                    <IconButton size='small' onClick={() => remover(product?.index)}>
                        <DeleteOutlineOutlined color="error" fontSize='small' />
                    </IconButton>
                </Box>
            </Box>
            <TableContainer component={Box} >
                <Table size='small' sx={{ minWidth: { xs: '100%', md: '100%' } }} aria-label="cluster-server-packages">
                    <TableHead>
                        <TableRow>
                            <TableCell>Node/ Gói</TableCell>
                            <TableCell align="right">Cấu hình/ thông số theo gói</TableCell>
                            <TableCell align="right">Mua thêm | Custom</TableCell>
                            <TableCell align="right">Đơn giá bán (theo tháng)</TableCell>
                            <TableCell align="left">ĐG vượt</TableCell>
                            <TableCell align="right">Tạm tính</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {product?.product_addons?.map((cluster, index) => (
                            <TableRow
                                key={index}
                            >
                                <TableCell component="th" scope="row">
                                    {cluster?.product_name}
                                </TableCell>
                                <TableCell align="right">
                                    <ClusterConfig
                                        informations={cluster?.product_informations || []}
                                        clusterQty={cluster?.product_value}
                                    />
                                </TableCell>
                                <TableCell align="right">{format_numeral_price(cluster?.product_value)}</TableCell>
                                <TableCell align="right">{format_numeral_price(cluster?.product_price)}</TableCell>
                                <TableCell align="right">
                                    <ServicePriceExceed product={cluster} />
                                </TableCell>
                                <TableCell align="right">
                                    {format_numeral_price(cluster?.product_value * cluster?.product_price)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', pr: { xs: 'auto', md: 2 }, mt: 0.75 }}>
                <Typography sx={{
                    fontSize: 18,
                    fontWeight: 'medium'
                }}>{
                        format_numeral_price(_.sumBy(product?.product_addons, (cluster) => cluster?.product_price * cluster?.product_value) + parseFloat(product?.product_price))
                    }</Typography>
            </Box>
            <Box sx={{ mt: 1.75 }}>
                <Typography sx={{ fontSize: 14, fontWeight: 'medium' }}>
                    Thanh Toán:
                    <Typography sx={{ fontSize: 14, display: 'inline-flex', ml: 1 }}>
                        {product?.service_management_billing?.service_billing_startdate}{" - "}
                        {product?.service_management_billing?.service_billing_enddate}
                    </Typography>
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 'medium' }}>
                    Gia Hạn:
                    <Typography sx={{ fontSize: 14, display: 'inline-flex', ml: 1 }}>
                        {product?.service_management_billing?.service_nextbill_startdate}{" - "}
                        {product?.service_management_billing?.service_nextbill_enddate}
                    </Typography>

                </Typography>
            </Box>
        </Box>
    )
}

export default VnisServicePreview