import HeaderTitle from "@components/Title/HeaderTitle";
import BreadCrumb from "@components/shared/BreadCrumb";

const Header = ({ id, ticket_name }) => {
  return (
    <>
      <HeaderTitle>{ticket_name}</HeaderTitle>
      <BreadCrumb
        data={[
          { label: "Danh sách Tickets", reactLink: "/tickets" },
          { label: `Ticket ${id}` },
        ]}
      />
    </>
  );
};

export default Header;
