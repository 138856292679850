import ThresholdApi from "@/apis/beta/Threshold/ThresholdApi";
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import PlaybookInfo from "../../../PlaybookInfo";
import PlaybookEdit from "@features/Module/Technical/Dialog/Playbook";
const Playbooks= ({threshold_id, domains}) => {
    const [showDialogCreatePlayBook ,setShowDialogCreatePlayBook] = React.useState(false);
    const {data: playbooks, refetch} = ThresholdApi.GetPlaybooks({threshold_id: threshold_id});
    return (
        <>
            <Paper sx={{ p: 3, mb: 1 }}>
                <Box
                onClick={() => {
                    setShowDialogCreatePlayBook(true);
                }}
                display="flex"
                alignItems="center"
                >
                <Typography>Playbook</Typography>
                <Chip
                    color="primary"
                    sx={{ ml: 2, cursor: "pointer" }}
                    label="Thêm playbook"
                />
                </Box>
            </Paper>
           {
            playbooks && playbooks?.map((playbook,index) => 
                <PlaybookInfo key={index} 
                    playbook={playbook}
                    refetch={refetch}
                    bindOptions={domains}
                    threshold_id={threshold_id}
                />
            )
           }
           {showDialogCreatePlayBook &&
            <PlaybookEdit
                open={showDialogCreatePlayBook}
                handleClose={() => setShowDialogCreatePlayBook(false)}
                threshold_id={threshold_id}
                bindOptions={domains}
                refetch={refetch}
            />
            }
        </>
    );
};

export default Playbooks;
