import ErrorHelperText from "@components/shared/ErrorHelperText";
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const SelectRacks = ({
  racks,
  racksLoading,
  control,
  row,
  setValuePosition,
  errors,
}) => {
  if (!racks || racksLoading) return <CircularProgress size={20} />;

  return (
    <Controller
      control={control}
      name={`rack-${row?.warehouse_item_id}`}
      rules={{ required: "Vui lòng chọn rack" }}
      render={({ field: { onChange, ...field } }) => (
        <FormControl fullWidth>
          {!racksLoading ? (
            <Select
              {...field}
              onChange={(e) => {
                onChange(e?.target?.value);
                setValuePosition("");
              }}
              fullWidth
              size="small"
            >
              {racks?.warehouse_racks?.map((item) => (
                <MenuItem key={item?.value} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Box>
              <CircularProgress size={20} />
            </Box>
          )}

          {!!errors?.[`rack-${row?.warehouse_item_id}`] && (
            <ErrorHelperText
              sx={{ mt: 1 }}
              message={errors?.[`rack-${row?.warehouse_item_id}`]?.message}
            />
          )}
        </FormControl>
      )}
    />
  );
};

export default SelectRacks;
