import { TextField } from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import React from 'react';

const MuiDateTimePicker = ({
    value,
    onChange,
    ...props
}) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
                value={value}
                onChange={(newValue) => {
                    onChange(newValue.$d);
                }}

                onError={console.log}
                minDate={moment('2020/01/01T00:00')}
                inputFormat="YYYY/MM/DD hh:mm a"
                mask="____/__/__ __:__ _M"
                renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} size="small" />}
                {...props}
            />
        </LocalizationProvider>
    )
}

export default MuiDateTimePicker