import MuiScrollTabs from "@components/shared/MuiScrollTabs";
import React from "react";
import { DisplaySettingsOutlined } from "@mui/icons-material";
import SegmentIcon from '@mui/icons-material/Segment';

const Header = () => {
    const initialTabs = [
        {
            value: "warehouse-item-server",
            label: "Mặc định",
            href: '',
            icon: DisplaySettingsOutlined
        },
        {
            value: "warehouse-item-processor",
            label: "Gộp theo dịch vụ",
            href: 'service-list',
            icon: SegmentIcon
        },
    ]
    return (
        <MuiScrollTabs prefix={`warehouse/resources`}
                                    initialTabs={initialTabs}
                                />
    )
}
export default Header;