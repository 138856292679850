import BreadCrumb from "@components/shared/BreadCrumb";
import { Box, Button, CircularProgress, Paper, Slide, Typography } from "@mui/material";
import React from "react";
import WarehouseDeviceTable from "./share/WarehouseDeviceTable";
import _ from "lodash";
import ResourceForm from "./share/ResourceForm";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";

const Create = () => {
    const [device, setDevice] = React.useState({
        open: false
    })
    const [resourceItems, setResourceItems] = React.useState([])

    return (
        <Box>
            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>
                Quản lý Tài Nguyên
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Quản lý Tài Nguyên", reactLink: "/warehouse/resources" },
                    { label: "Thêm Tài Nguyên" }
                ]}
            />

            <Box sx={{ p: 1, mt: 2 }}>
                <Button
                    variant='contained'
                    disabled={device?.open}
                    focusRipple={!device?.open}
                    onClick={() => setDevice({ open: true })}
                    size='small' sx={{ height: 42, mb: 2 }}>
                    Chọn Thiết Bị
                </Button>
            </Box>

            {(resourceItems?.length === 0 && !device?.open) && <Box onClick={() => setDevice({ open: true })} sx={{ display: 'flex', justifyContent: 'center', border: 1, borderRadius: '4px', borderColor: "#777680", height: 80 }}>
                <Typography color="#777680" sx={{ m: "auto" }}>Vui lòng thêm thiết bị để tạo tài nguyên</Typography>
            </Box>}
            <React.Suspense fallback={<CircularProgress size={16} disableShrink />}>
                {device?.open && <Slide in={device?.open} direction="down">
                    <Box>
                        <WarehouseDeviceTable setResourceItems={setResourceItems} idsExist={resourceItems.map(item => item?.warehouse_box_item?.warehouse_box_item_id)} handleClose={() => setDevice({ open: false })} />
                    </Box>
                </Slide>}
            </React.Suspense>
            <Paper>
                <React.Suspense fallback={<CircularProgress size={16} disableShrink />}>
                    <ResourceForm
                        resourceItems={resourceItems}
                        setResourceItems={setResourceItems}
                        refetchData={() => { console.log("log") }}
                        type="create"
                        handleClose={() => { console.log("log") }}
                    />
                </React.Suspense>
            </Paper>
        </Box>
    )
}

export default withSuspense(withPermission(Create, {
    feature: "warehouse",
    action: "resource-create"
}), MuiSkeleton["DataGrid"]) 