import JourneyApi from "@/apis/beta/Customer/Journey/JourneyApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import CreateJourney from "./shared/CreateJourney";
import { JourneyPackages } from "./shared/ViewMode";

const CustomerJourney = () => {
  const { customer_id: customerId } = useParams();

  const { data: journeys } = JourneyApi.List({ customerId });

  return (
    <Box>
      <CreateJourney />
      {journeys
        ?.filter((journey) => !journey?.customer_journey_locked)
        ?.map((journey, index) => {
          return <JourneyPackages.Detail key={index} journey={journey} />;
        })}
      <Grid container spacing={2} sx={{ mt: 1.5 }}>
        {journeys
          ?.filter((journey) => journey?.customer_journey_locked)
          ?.map((journey, index) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <JourneyPackages.Locked key={index} journey={journey} />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
export default withSuspense(
  withPermission(CustomerJourney, {
    feature: "customer",
    action: "customer-journey-index",
  }),
  MuiSkeleton["PaperJourney"]
);
