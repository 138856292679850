import { SERVICE_STATUS_TYPES, TICKET_URGENCY } from "@/libs/constants";
import { EditOutlined } from "@mui/icons-material";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import TICKET_VIEWS from "../../Shared/Forms/functions";

export const TICKET_STATUS = [
  {
    value: 1,
    label: "OPEN",
    muiChip: { variant: "customize-primary" },
  }, {
    value: 2,
    label: "WORK IN PROGRESS",
    muiChip: { variant: "customize-blueGrey" },
  }, {
    value: 3,
    label: "PENDING",
    muiChip: { variant: "customize-pending" },
  }, {
    value: 4,
    label: "COMPLETED",
    muiChip: { variant: "customize-success" },
  }, {
    value: 5,
    label: "CANCLED",
    muiChip: { variant: "customize-error" },
  }, {
    label: "CLOSED",
    value: 6,
    muiChip: { variant: "customize-error" },
  },
];

const TicketInfo = ({ ticket, edit = true, setEdit = () => { } }) => {
  const dataInfo = [
    {
      label: "Ticket ID",
      value: `Ticket - ${ticket?.ticket_id}`,
      component: 'Typography'
    },
    {
      label: "Khách hàng",
      hide: !ticket?.customer_id || ticket?.customer_id === -1,
      data: {
        value: ticket?.customer?.customer_company_name || ticket?.customer?.customer_name,
        to: `/customers/${ticket?.customer_id}`
      },
      component: 'Link'
    },
    {
      label: "Dịch vụ",
      hide: !ticket?.service_id || ticket?.service_id === -1,
      data: {
        value: ticket?.service?.service_name || ticket?.product_name || '',
        to: `/services/${ticket?.service_id}`
      },
      component: 'Link',
    },
    {
      label: "Sản phẩm",
      value: ticket?.product_name,
      component: 'Typography',
      hide: !ticket?.product_name
    },
    {
      label: "Trạng thái",
      data: SERVICE_STATUS_TYPES?.find((x) => x?.value === ticket?.service?.service_status),
      component: "muiChip",
      hide: !ticket?.service?.service_status
    },
    {
      label: "Ticket urgency",
      data: TICKET_URGENCY?.find((x) => x?.value === ticket?.ticket_urgency),
      component: "muiChip",
    },
    {
      label: "Ticket status",
      component: "muiChip",
      data: TICKET_STATUS?.find((x) => x?.value === ticket?.ticket_status_id),
    },
    {
      label: "Nguồn nhận thông tin",
      value: ticket?.ticket_receive_source,
      component: 'Typography',
    },
    {
      label: "Ticket",
      value: ticket?.ticket_request?.ticket_request_title,
      component: 'Typography',
    },
    {
      label: "Ticket type",
      value: ticket?.ticket_type?.ticket_type_title,
      component: 'Typography',
    },
    {
      label: "Kênh phản hồi",
      value: ticket?.ticket_channel_response,
      component: 'Typography',
      hide: !ticket?.ticket_channel_response
    },
    {
      label: "Nhân viên tạo ticket",
      value: ticket?.employee?.employee_name || '',
      component: 'Typography',
    }, {
      label: "Ngày bắt đầu sử dụng",
      component: 'Typography',
      value: moment(ticket?.service_start_date).format("DD/MM/YYYY HH:mm") || "---",
      hide: !ticket?.service_start_date
    }, {
      label: "Ngày kết thúc sử dụng",
      component: 'Typography',
      value: moment(ticket?.service_end_date).format("DD/MM/YYYY HH:mm") || "---",
      hide: !ticket?.service_end_date
    }, {
      label: "Ngày trả lời khách hàng",
      component: 'Typography',
      hide: !ticket?.ticket_time_repply,
      value:
        moment(ticket?.ticket_time_repply).format("DD/MM/YYYY HH:mm") || "---",
    }, {
      label: "Ngày hoàn thành",
      value: moment(ticket?.tikcet_time_release).format("DD/MM/YYYY HH:mm"),
      component: 'Typography',
      hide: !ticket?.tikcet_time_release
    }, {
      label: "Ngày phản hồi khách hàng",
      value: moment(ticket?.ticket_time_response).format("DD/MM/YYYY HH:mm"),
      component: 'Typography',
      hide: !ticket?.ticket_time_response
    }, {
      label: "Meeting",
      component: 'Link',
      value: {
        label: "Link trao đổi cuộc họp Online",
        to: ticket?.meeting
      },
      cols: 12,
      hide: !ticket?.meeting
    }, {
      label: "Mô tả Ticket",
      value: ticket?.ticket_content,
      component: 'Typography',
      cols: 12,
    }, {
      label: "Cách giải quyết",
      value: ticket?.ticket_solution,
      component: 'Typography',
      cols: 12,
      hide: !ticket?.ticket_solution
    }, {
      label: "Feedback",
      value: ticket?.ticket_feedback,
      component: 'Typography',
      cols: 12,
      hide: !ticket?.ticket_feedback
    }
  ];

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Grid container spacing={2}>

        {dataInfo?.map((attribute, index) => {
          if (attribute.hide) return null;
          const Component = TICKET_VIEWS[attribute?.component]
          if (!Component) return null
          return (
            <Grid item xs={12} md={attribute?.cols || 3} key={index}>
              <Typography sx={{ fontSize: 14, mb: 0.25 }} color="text.grey">{attribute?.label}</Typography>
              <Component {...attribute} {...attribute?.data} />
            </Grid>
          )
        })}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" pt={1.25} >
            <Button size="small" variant="contained"
              disabled={edit}
              startIcon={<EditOutlined fontSize="small" />} onClick={() => setEdit(true)}>
              Chỉnh sửa Ticket
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TicketInfo;
