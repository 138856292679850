import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import React from "react";
import { useParams } from "react-router-dom";
import Information from "./Information";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import { Box, Button } from "@mui/material";
import { Filter } from "@/hooks/useFilters";
import HeaderTitle from "@components/Title/HeaderTitle";
import TableRack from "./TableRack";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import PaperLoading from "../../Shared/Loading/PaperLoading";
import WarehouseBoxLogApi from "@/apis/beta/Warehouse/WarehouseBoxLogApi";
import BoxLog from "../BoxLogs";

const BoxesDetail = () => {
  const { id } = useParams();
  const [showLog, setShowLog] = React.useState(false);

  const { data, isLoading } = WarehouseBoxApi.show(id);
  const { data: boxLogs, refetch: refetchLogs } = WarehouseBoxLogApi.Show({ id: id })
  const {
    data: warehouse_items,
    isLoading: warehouse_items_fetch,
    refetch,
  } = WarehouseItemApi.getAll(
    Filter.stringify({
      $and: [
        { "warehouse_items.warehouse_item_form_factor": { $gt: 0 } },
        {
          "warehouse_box_items.warehouse_box_item_reference_type": {
            $eq: "WarehouseRack",
          },
        },
      ],
    })
  );

  const isFetching = isLoading || warehouse_items_fetch;

  if (isFetching) {
    return <PaperLoading />;
  }
  if (boxLogs?.length > 0) {
    boxLogs.sort((a, b) => new Date(b.warehouse_box_update_created_at) - new Date(a.warehouse_box_update_created_at))
  }
  return (
    <Box>
      <HeaderTitle>
        Chi tiết {data?.warehouse_box?.warehouse_box_name}
      </HeaderTitle>

      <Information
        callbackRefetch={refetch}
        refetchLogs={refetchLogs}
        data={data?.warehouse_box}
        warehouse_items={warehouse_items}
      />

      <TableRack
        warehouse_box={data?.warehouse_box}
        refetchData={refetch}
        refetchLogs={refetchLogs}
        warehouse_items={warehouse_items}
      />
      <Button
        sx={{ mt: 2 }}
        onClick={() => setShowLog(!showLog)}
        variant="contained"
        size="small"
        // startIcon={<ImportExportIcon />}
      >
        Hiện log
      </Button>
    
    {showLog && <BoxLog data = {boxLogs} />}

    </Box>
  );
};

export default withSuspense(
  withPermission(BoxesDetail, {
    feature: "warehouse",
    action: "boxes-detail",
  }),
  MuiSkeleton["GridInformation"]
);
