import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import { Box, FormControl, TextField, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const Committed = ({
    index = -1,
    field,
    update
}) => {

    const { control, setValue, watch } = useFormContext()
    return (
        <Controller
            name={`service_configoptions.[${index}].commitment.value`}
            control={control}
            render={({ field: { value, onChange } }) => {
                const isUnlimited = parseFloat(value) >= UNLIMITED
                return (
                    <FormControl fullWidth>
                        <TextField
                            label="Gói cố định (? GB/ Tháng) *"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                sx: {
                                    paddingRight: 0,
                                },
                                inputComponent: isUnlimited ? "input" : NumericFormatCustom,
                                endAdornment:
                                    <Box sx={{
                                        display: "flex",
                                        height: "40px",
                                        width: {
                                            xs: isUnlimited ? "calc(100%)" : "calc(100% / 3)",
                                            md: isUnlimited ? "calc(100%)" : "calc(100% / 4)",
                                        },
                                        transition: "width 0.75s ease-in-out",
                                        bgcolor: "rgba(0, 0, 0, 0.18)",
                                        borderTopRightRadius: "4px",
                                        borderBottomRightRadius: "4px",
                                        cursor: "pointer",
                                    }}
                                        onClick={() => {
                                            let usage = 1
                                            if (parseFloat(value) < UNLIMITED) {
                                                usage = UNLIMITED
                                            }
                                            return update(index, {
                                                ...field,
                                                commitment: {
                                                    ...field?.commitment,
                                                    value: usage
                                                }
                                            })
                                        }}
                                    >
                                        <Typography
                                            color="text.success"
                                            sx={{
                                                m: 'auto 8px auto auto',
                                                fontSize: 13,
                                                textTransform: 'uppercase',
                                                color: value === UNLIMITED ? green[800] : "#777680"
                                            }}>
                                            Unlimited
                                        </Typography>
                                    </Box>
                            }}
                            value={isUnlimited ? "UNLIMITED" : value}
                            onChange={(event) => {
                                onChange(event?.target?.value)
                                setValue(`service_configoptions.[${index}].commitment.pricing_amount`, parseFloat(event?.target?.value) * parseFloat(watch(`service_configoptions.[${index}].commitment.pricing_apply`)))
                            }}
                            placeholder='0'
                            fullWidth
                            size='small' />
                    </FormControl>
                )
            }} />
    )
}

export default Committed