import { LockClockOutlined, LockOpenOutlined } from "@mui/icons-material";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import JourneyExplaination from "../JourneyExplaination";
import { format_date_monent_js } from "@/functions";
import StyledBadge from "@features/Module/Question/shared/StyledBadge";
import JourneyUpdates from "../JourneyUpdates";
import JourneyStatus from "./JourneyStatus";

const Journey = ({ journey, mode = "edit" }) => {
  return (
    <Paper sx={{ mb: 1.5, p: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column-reverse",
            md: "row",
          },
        }}
      >
        <Typography component="span">
          {!journey?.customer_journey_locked && (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              variant="dot"
            >
              <Box sx={{ width: "8px" }} />
            </StyledBadge>
          )}
          <Typography
            component="span"
            sx={{ fontSize: 14, fontWeight: "medium", pl: "4px" }}
            color="text.black"
          >
            [Journey - {journey?.customer_journey_id}]:
          </Typography>
          <JourneyStatus journey={journey} />
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontSize: 12, m: "auto 0" }} color="text.grey">
            Cập nhật lần cuối:{" "}
            {format_date_monent_js(journey?.updated_at, "DD/MM/YYYY HH:mm")}
          </Typography>
          <IconButton size="small" sx={{ m: "auto 0" }}>
            {journey?.customer_journey_locked ? (
              <LockClockOutlined fontSize="small" />
            ) : (
              <LockOpenOutlined fontSize="small" />
            )}
          </IconButton>
        </Box>
      </Box>
      <JourneyExplaination journey={journey} onlyView={mode === "view"} />
      <JourneyUpdates journey={journey} mode={mode} />
    </Paper>
  );
};

export default Journey;
