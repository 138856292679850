import InvoiceItemApi from "@/apis/beta/Invoice/InvoiceItemApi";
import { format_date_time, queryClientRefetcher } from "@/functions";
import withPermission from "@/hocs/withPermission";
import DatePicker from "@components/DatePicker";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const Update = ({ onClose, invoiceId, invoiceItemId, data }) => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      invoice_item_description: data?.invoice_item_description,
      invoice_item_value: data?.invoice_item_value || 0,
      invoice_item_subtotal: data?.invoice_item_subtotal || 0,
      invoice_item_deposit: data?.invoice_item_deposit || 0,
      invoice_item_amount: data?.invoice_item_amount || 0,
      invoice_item_tax: data?.invoice_item_tax,
      invoice_item_pricing_apply: data?.invoice_item_pricing_apply || 0,
      invoice_item_startdate: moment(data?.invoice_item_startdate) || moment(),
      invoice_item_enddate: moment(data?.invoice_item_enddate) || moment(),
    },
  });

  const onSubmit = async (values) => {
    setSubmit(true);
    let form = {
      ...values,
      invoice_item_startdate: format_date_time(values?.invoice_item_startdate),
      invoice_item_enddate: format_date_time(values?.invoice_item_enddate),
    };
    const response = await InvoiceItemApi.Update({
      invoiceId: invoiceId,
      invoiceItemId: invoiceItemId,
      data: form,
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }

    queryClientRefetcher(queryClient, [
      "invoices.list",
      "invoices.statistic",
      "service_invoices.list",
      "customer.invoices",
      "invoice.detail",
      "invoice.invoice_items",
      "invoice.informations",
      "invoice.transactions.list",
      "invoice.loggers",
    ]);
    setNotification({
      open: true,
      message:
        response?.data?.message || "Cập nhật thông tin hoá đơn thành công",
      severity: "success",
    });
    return onClose();
  };
  React.useEffect(() => {
    const subtotal =
      parseFloat(watch("invoice_item_value")) *
        parseFloat(watch("invoice_item_pricing_apply")) || 0;
    return setValue(`invoice_item_subtotal`, subtotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("invoice_item_value"), watch("invoice_item_pricing_apply")]);

  React.useEffect(() => {
    const amount =
      parseFloat(watch("invoice_item_subtotal")) -
        parseFloat(watch("invoice_item_deposit")) || 0;
    return setValue(`invoice_item_amount`, amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("invoice_item_subtotal"), watch("invoice_item_deposit")]);

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogTitle>Chỉnh sửa thông tin hoá đơn</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: 1.5, mt: 2 }}
        >
          <Controller
            control={control}
            name="invoice_item_description"
            rules={{ required: "Description đang để trống" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    label="Invoice item description *"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    value={value}
                    onChange={onChange}
                    placeholder="Mô tả item hoá đơn..."
                    error={!!error}
                    helperText={error?.message || ""}
                  />
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="invoice_item_value"
            rules={{ required: "Thông số & Giá Trị *" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    label="Value"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    size="small"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message || ""}
                  />
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="invoice_item_pricing_apply"
            rules={{ required: "Đơn giá bán *" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    label="Đơn giá bán *"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    size="small"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message || ""}
                  />
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="invoice_item_subtotal"
            rules={{ required: "Tạm tính" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    label="Tạm tính *"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    size="small"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message || ""}
                  />
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="invoice_item_deposit"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    label="Giảm/ (Desposit) *"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    size="small"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message || ""}
                  />
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="invoice_item_amount"
            rules={{ required: "Thành tiền" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    label="Thành tiền *"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    size="small"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message || ""}
                  />
                </FormControl>
              );
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 1.5,
            }}
          >
            <Controller
              control={control}
              name="invoice_item_startdate"
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl fullWidth>
                    <DatePicker
                      label="Bắt đầu *"
                      value={value}
                      onChange={onChange}
                    />
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="invoice_item_enddate"
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl fullWidth>
                    <DatePicker
                      label="Kết thúc *"
                      value={value}
                      onChange={onChange}
                    />
                  </FormControl>
                );
              }}
            />
          </Box>
          <Controller
            control={control}
            name="invoice_item_tax"
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <FormControlLabel
                    label="VAT"
                    control={
                      <Checkbox
                        size="small"
                        checked={value}
                        onChange={onChange}
                      />
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          disable={submit}
          variant="outlined"
          onClick={onClose}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          size="small"
          disable={true}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Lưu Thay Đổi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withPermission(Update, {
  feature: "invoice",
  action: "invoice-item-update",
  type: "Modal",
});
