import React, { useState } from "react";
import _ from "lodash";
import { Box, Grid, Paper, Typography, CircularProgress } from "@mui/material";
import FormWarehouseItem from "./FormWarehouseItem";
import { useLocation } from "react-router-dom";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import { queryStringParse } from "@/functions";
import WarehouseBoxItemApi from "@/apis/beta/Warehouse/WarehouseBoxItemApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import WarehouseBoxLogApi from "@/apis/beta/Warehouse/WarehouseBoxLogApi";
import { useEmployeeContext } from "@contexts/employee";

const HighLight = ({ position }) => {
  return (
    <Box
      id="highlight"
      data-position={position}
      height={2}
      width="100%"
      bgcolor={"black"}
      sx={{ opacity: 0, transition: "all linear 0.2s" }}
    />
  );
};

const IDCInstalling = ({ wbRack, warehouseBoxItems }) => {
  const { search } = useLocation();
  const { data: warehouse_models } = WarehouseModelApi.All();
  const { employee } = useEmployeeContext();
  const {
    mutateAsync: UpdatePosition,
    isLoading,
    notify,
  } = useMutationWithNotification(async ({ id, params }) => {
    const response = await WarehouseBoxItemApi.Update(id, params);
    return response?.data;
  });

  const [warehouseBoxItem, setWarehouseBoxItem] = React.useState(
    warehouseBoxItems.length > 0
      ? {
        warehouse_item: _.find(warehouseBoxItems, {
          warehouse_item_id: parseInt(queryStringParse(search)["id"]),
        }),
      }
      : null
  );

  const reBuildData = React.useMemo(() => {
    let rack = _.range(1, wbRack?.warehouse_rack_height + 1).map((item) => {
      return {
        position: item,
        warehouse_item: null,
        u: 1,
      };
    });
    _.forEach(warehouseBoxItems, (item) => {
      _.remove(rack, (n) => n.position === item.warehouse_box_item_position);
      if (item.warehouse_item_form_factor === 2) {
        _.remove(
          rack,
          (n) => n.position === item.warehouse_box_item_position + 1
        );
      }
      rack.push({
        u: item.warehouse_item_form_factor,
        position: item.warehouse_box_item_position,
        warehouse_item: item,
        description: item.warehouse_box_item_description,
        id: item.warehouse_box_item_id,
      });
    });
    return _.reverse(_.orderBy(rack, ["position"], ["asc"]));
  }, [warehouseBoxItems, wbRack]);

  const [initial, setInitial] = useState(reBuildData);

  const getAllHighlight = () => {
    const highlight = document.querySelectorAll("#highlight");
    return Array.from(highlight);
  };

  const showNearHighlight = (e, allHightLight) => {
    return allHightLight?.reduce(
      (final, item) => {
        const offset = e?.clientY - item?.getBoundingClientRect()?.top;

        if (offset < 0 && offset > final?.offset) {
          final = {
            offset: offset,
            element: item,
          };
        }

        return final;
      },
      {
        offset: Number.NEGATIVE_INFINITY,
        element: allHightLight[allHightLight?.length - 1],
      }
    );
  };

  const clearHighLight = () => {
    const hightLights = getAllHighlight();
    hightLights?.forEach((item) => {
      item.style.opacity = 0;
    });
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("position", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    const allHightLight = getAllHighlight();
    const { element } = showNearHighlight(e, allHightLight);

    element.style.opacity = 1;
  };

  const handleDrop = (e) => {
    clearHighLight();

    let copy = [...initial];

    const allHightLight = getAllHighlight();
    const { element } = showNearHighlight(e, allHightLight);

    const position = parseInt(element?.dataset?.position);
    const current = parseInt(e.dataTransfer.getData("position"));

    const currentItem = copy.find((item) => item?.position === current);
    const newItem = copy.find((item) => item?.position === position);

    const nextIndexNewItem =
      copy?.findIndex((item) => item?.position === newItem.position) - 1;

    if (newItem?.warehouse_item) return;

    if (copy?.[nextIndexNewItem]?.warehouse_item && currentItem?.u > 1) {
      return;
    }

    if (position === wbRack?.warehouse_rack_height && currentItem?.u > 1) {
      return;
    }

    copy = copy?.map((item) => {
      if (item?.position === position) {
        return {
          ...currentItem,
          position,
        };
      }

      if (item?.position === current) {
        return {
          position: current,
          warehouse_item: null,
          u: 1,
        };
      }

      return item;
    });
    if (currentItem?.u > 1) {
      Array.from(Array(currentItem?.u).keys())?.forEach((num) => {
        if (num > 0) {
          copy = copy?.filter((item) => item?.position !== position + num);

          copy.splice(
            copy?.findIndex((item) => item?.position === current) || 0,
            0,
            {
              position: current + num,
              u: 1,
              warehouse_item: null,
            }
          );
        }
      });
    }
    UpdatePosition({
      id: currentItem?.warehouse_item?.warehouse_box_item_id,
      params: { warehouse_box_item_position: position },
    }).then((response) => {
      if (!response?.errors) {
        WarehouseBoxLogApi.createBoxLog({
          warehouse_box_name: wbRack?.warehouse_box?.warehouse_box_name,
          warehouse_box_id: wbRack?.warehouse_box?.warehouse_box_id,
          warehouse_box_update_type: "UPDATERACK-POSITION",
          metadata: {
            previous: {
              warehouse_box_item_position: currentItem.position,
              warehouse_box: {
                warehouse_box_name: wbRack?.warehouse_box?.warehouse_box_name,
                warehouse_box_id: wbRack?.warehouse_box?.warehouse_box_id,
              },
              u: currentItem?.u,
              warehouse_item: {
                warehouse_item_name: currentItem?.warehouse_item?.warehouse_item_name,
                warehouse_item_id: currentItem?.warehouse_item?.warehouse_item_id,
              }
            },
            new: {
              warehouse_box_item_position: position,
              warehouse_item: {
                warehouse_item_name: currentItem?.warehouse_item?.warehouse_item_name,
                warehouse_item_id: currentItem?.warehouse_item?.warehouse_item_id,
              },
              u: currentItem?.u,
              warehouse_box: {
                warehouse_box_name: wbRack?.warehouse_box?.warehouse_box_name,
                warehouse_box_id: wbRack?.warehouse_box?.warehouse_box_id,
              }
            }
          },
          warehouse_box_updater_id: employee?.employee_id,
          warehouse_box_updater_name: employee?.employee_name,
          warehouse_box_update_description: `Thay đổi vị trí SERVER/SWITCH ${currentItem?.warehouse_item?.warehouse_item_name} từ ${currentItem?.position} sang vị trí ${position}`,
        })
        notify("success", "Update vị trí thành công");
      }
    });

    setInitial(copy);
  };

  const handleClickShowWarehouseItem = (item) => {
    if (!item?.warehouse_item) return;

    window?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setWarehouseBoxItem(item);
  };

  return (
    <>
      {isLoading && <MuiSkeleton.LinearProgress />}
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Paper>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
              <Typography sx={{ m: "auto" }}>
                {wbRack?.warehouse_rack_name}
              </Typography>
            </Box>
            <Box
              sx={{
                p: "0 10px 0 5px",
              }}
              onDragOver={handleDragOver}
              onDragLeave={clearHighLight}
              onDrop={handleDrop}
            >
              {initial?.map((item, index) => {
                if (item?.u === 1) {
                  return (
                    <>
                      <Box key={index} sx={{ display: "flex", height: 24 }}>
                        <Box
                          sx={{
                            display: "flex",
                            minWidth: "5%",
                            width: "5%",
                            maxWidth: "5%",
                            justifyContent: "center",
                            mr: 1,
                            color: "#777680",
                          }}
                        >
                          <Typography
                            sx={{
                              m: "auto 0",
                              textAlign: "center",
                              fontSize: 14,
                            }}
                          >
                            {item?.position}
                          </Typography>
                        </Box>
                        <div
                          draggable={Boolean(item?.warehouse_item)}
                          style={{ width: "100%" }}
                          onDragStart={(e) =>
                            handleDragStart(e, item?.position)
                          }
                        >
                          <Box
                            sx={{
                              height: "95%",
                              display: "flex",
                              width: "95%",
                              border: 1,
                              borderColor: "#777680",
                              justifyContent: "center",
                              "&:hover": {
                                backgroundColor: "#2196f3",
                              },
                              cursor: "pointer",
                              borderTop: index !== 0 ? "none" : undefined,
                              bgcolor: item.warehouse_item
                                ? item.warehouse_item
                                  .warehouse_item_availability === false
                                  ? "#fbc02d"
                                  : warehouseBoxItem &&
                                    warehouseBoxItem.id === item.id
                                    ? "#2196f3"
                                    : "#1A237E"
                                : "none",
                            }}
                            onClick={() => handleClickShowWarehouseItem(item)}
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                m: "auto",
                                color: !!item?.warehouse_item
                                  ? "#FFFFFF"
                                  : "#777680",
                              }}
                            >
                              {item?.warehouse_item?.warehouse_item_service_tag
                                ? `${_.find(warehouse_models, {
                                  value:
                                    item?.warehouse_item
                                      ?.warehouse_model_id,
                                })?.warehouse_type?.warehouse_type_name
                                } ${item?.warehouse_item?.warehouse_item_name
                                } - S/N: ${item?.warehouse_item
                                  ?.warehouse_item_service_tag
                                }`
                                : item?.position}
                            </Typography>
                          </Box>
                        </div>
                      </Box>

                      <HighLight position={item?.position} />
                    </>
                  );
                }
                return (
                  <>
                    <Box key={index} sx={{ display: "flex", height: 24 * item?.u }}>
                      <Box
                        sx={{
                          minWidth: "5%",
                          width: "5%",
                          maxWidth: "5%",
                          justifyContent: "center",
                          mr: 1,
                          color: "#777680",
                        }}
                      >
                        {_.range(1, item.u + 1).map((num, index) => (
                          <Box
                            key={`installing.${num}`}
                            sx={{
                              height: 24,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                m: "auto 0",
                                textAlign: "center",
                                fontSize: 14,
                              }}
                            >
                              {item.position + item.u - (index + 1)}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                      <div
                        draggable={Boolean(item?.warehouse_item)}
                        style={{ width: "100%" }}
                        onDragStart={(e) => handleDragStart(e, item?.position)}
                      >
                        <Box
                          sx={{
                            height: "95%",
                            display: "flex",
                            width: "95%",
                            "&:hover": {
                              backgroundColor: "#2196f3",
                            },
                            "&:active": {
                              backgroundColor: "#2196f3",
                            },
                            justifyContent: "center",
                            cursor: "pointer",
                            border: 1,
                            borderColor: "#777680",
                            borderTop: index !== 0 ? "none" : undefined,
                            bgcolor: item.warehouse_item
                              ? item.warehouse_item
                                .warehouse_item_availability === false
                                ? "#fbc02d"
                                : warehouseBoxItem &&
                                  warehouseBoxItem.id === item.id
                                  ? "#2196f3"
                                  : "#1A237E"
                              : "none",
                          }}
                          onClick={() => handleClickShowWarehouseItem(item)}
                        >
                          <Typography
                            sx={{
                              fontSize: 14,
                              m: "auto",
                              color: !!item?.warehouse_item
                                ? "#FFFFFF"
                                : "#777680",
                            }}
                          >
                            {item?.warehouse_item?.warehouse_item_service_tag
                              ? `${_.find(warehouse_models, {
                                value:
                                  item?.warehouse_item?.warehouse_model_id,
                              })?.warehouse_type?.warehouse_type_name
                              } ${item?.warehouse_item?.warehouse_item_name
                              } - S/N: ${item?.warehouse_item
                                ?.warehouse_item_service_tag
                              }`
                              : item?.position}
                          </Typography>
                        </Box>
                      </div>
                    </Box>

                    <HighLight position={item?.position} />
                  </>
                );
              })}
            </Box>
          </Paper>
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
          <React.Suspense fallback={<CircularProgress disableShrink />}>
            {warehouseBoxItem?.warehouse_item && (
              <FormWarehouseItem
                key={warehouseBoxItem?.warehouse_item.warehouse_item_id}
                wbRack={wbRack}
                warehouseBoxItem={warehouseBoxItem?.warehouse_item}
              />
            )}
          </React.Suspense>
        </Grid>
      </Grid>
    </>
  );
};

export default IDCInstalling;
