import { AppBar, Button, Container, Dialog, FormControl, Grid, IconButton, InputLabel, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Slide, TextField, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CancelIcon from '@mui/icons-material/Cancel';
import DnsIcon from '@mui/icons-material/Dns';
import { useForm, Controller } from 'react-hook-form';
import ReportWatchApi from '@/apis/beta/Threshold/Report/ReportWatchApi';
import { useNotificationContext } from "@contexts/notification";
import Autocomplete from '@mui/material/Autocomplete';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const renderOption = (option) => {
    console.log(option)
    return (
        <MenuItem 
            {...option}
            key={option["data-option-index"]} 
            sx={{ '&:hover': { background: "#f0f0f0" } }}
        >
            {option["key"]}
        </MenuItem>
    );
};
const DialogAddReport = (props) => {
    const [mail, setMail] = useState('');
    const [domain, setDomain] = useState('');
    const { setNotification } = useNotificationContext();
    const { control, handleSubmit, formState: { errors } } = useForm(
        {
            defaultValues: {
                watch_name: '',
                watch_description: '',
                watch_bind: 'waf::report::weekly',
                watch_referrer_type: 'Customer',
                watch_scope: 'default',
                watch_status: 'active',
                watch_type: 'report',
            }
        });
    const [reportMetadata, setReportMetadata] = useState({
        mlytics_id: '',
        domains: [],
        owners: [],
        source_domains: '',
    });
    const watch_scopes = [
        { name: 'default', value: 'default' },
        { name: 'DetailOriented', value: 'DetailOriented' },
    ]
    const watch_binds = [
        { name: 'Mỗi tuần', value: 'waf::report::weekly' },
        { name: 'Mỗi tháng', value: 'waf::report::monthly' },
    ]


    const handleOnchangeMail = (value) => {
        setMail(value);
    }
    const handleOnchangeDomain = (value) => {
        setDomain(value);
    }
    const handleAddMail = () => {
        if (mail.replaceAll(" ", "") === "") return;
        if (reportMetadata.owners.includes(mail)) return;
        setReportMetadata({ ...reportMetadata, owners: [...reportMetadata.owners, mail] });
        setMail('');
    }
    const handleAddDomain = () => {
        if (domain.replaceAll(" ", "") === "") return;
        if (reportMetadata.domains.includes(domain)) return;
        setReportMetadata({ ...reportMetadata, domains: [...reportMetadata.domains, domain] });
        setDomain('');
    }
    const handleRemoveMail = (index) => {
        setReportMetadata({ ...reportMetadata, owners: reportMetadata.owners.filter((item, i) => i !== index) });
    }
    const handleRemoveDomain = (index) => {
        setReportMetadata({ ...reportMetadata, domains: reportMetadata.domains.filter((item, i) => i !== index) });
    }

    const handleOnchangeSource_domains = (value) => {
        setReportMetadata({ ...reportMetadata, source_domains: value });
    }
    const createReport = async (report) => {
        await ReportWatchApi.CreateReport({
            report: report,
        }).then((response) => {
            if (!response?.data.report) {
                return setNotification({
                    open: true,
                    message: response?.errors?.[0]?.message || "Tạo báo cáo mới thất bại.",
                    severity: "error",
                });
            }
            setNotification({
                open: true,
                message: "Tạo báo cáo mới thành công.",
                severity: "success",
            });
            if (props.refetch !== undefined) {
                props.refetch();
            }
            props.handleClose();
        });
    }

    const onSubmit = (data) => {
        data["metadata"] = reportMetadata;
        data["watch_interval"] = data.watch_bind.split("::")[2];
        createReport(data);
    }
    const [autoCompleteValue, setAutoCompleteValue] = useState('');
    const {
        data,
    } = ReportWatchApi.MlyticList({
        organization_name: autoCompleteValue || ''
    });
    const onInputChange = (current_input) => {
        if (!current_input) return;
        if (!current_input.target?.value) return;
        setAutoCompleteValue(current_input.target?.value || '');
        setReportMetadata({ ...reportMetadata, mlytics_id: current_input.target?.value || '' });
    }

    const onAutocompleteOnChange = (value) => {
        if (!value?.organizationName) return;
        setAutoCompleteValue(value.organizationName);
        setReportMetadata({ ...reportMetadata, mlytics_id: value.customerId });
    }

    return (
        <>
            <Dialog
                hideBackdrop
                fullScreen
                keepMounted
                open={props.open}
                onClose={props.handleClose}
                TransitionComponent={Transition}
                scroll='paper'
                onSubmit={handleSubmit(onSubmit)}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Thêm báo cáo
                        </Typography>
                        <Button
                            autoFocus color="inherit"
                            onClick={handleSubmit(onSubmit)}
                            type='submit'
                        >
                            Lưu thông tin
                        </Button>
                    </Toolbar>
                </AppBar>
                <Container component={'form'} maxWidth="sm" sx={{ mt: 2 }}>
                    <Controller
                        name='watch_name'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <>
                                <TextField
                                    id="outlined-basic"
                                    label="Tên người dùng"
                                    sx={{ mt: 2 }}
                                    variant="outlined"
                                    {...field}
                                />
                                {errors.watch_name?.type === 'required' && <p role="alert" style={{ color: 'red', fontSize: '12px', paddingLeft: "10px" }}>Cần nhập tên người dùng</p>}
                            </>
                        }
                    />
                    <Controller
                        name='watch_description'
                        control={control}
                        render={({ field }) =>
                            <>
                                <TextField
                                    id="outlined-basic"
                                    label="Mô tả"
                                    sx={{ mt: 2 }}
                                    variant="outlined"
                                    {...field}
                                />
                            </>
                        }
                    />
                    <Controller
                        name='watch_scope'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="watch-scope-select-label">Hình thức gửi báo cáo</InputLabel>
                                    <Select
                                        labelId="watch-scope-select-label"
                                        id="watch-scope-select"
                                        {...field}
                                        label="Hình thức gửi báo cáo"
                                        defaultChecked="default"
                                    >
                                        {watch_scopes.map((item) =>
                                            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                {errors.watch_scope?.type === 'required' && <p role="alert" style={{ color: 'red', fontSize: '12px', paddingLeft: "10px" }}>Cần chọn hình thức gửi báo cáo</p>}
                            </>
                        }
                    />
                    <Controller
                        name='watch_bind'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="label-watch-bind">Tần suất gửi</InputLabel>
                                    <Select
                                        labelId="label-watch-bind"
                                        id="watch-bind-select"
                                        {...field}
                                        label="Tần suất gửi"
                                        defaultChecked="waf::report::weekly"
                                    >
                                        {watch_binds.map((item) =>
                                            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                {errors.watch_bind?.type === 'required' && <p role="alert" style={{ color: 'red', fontSize: '12px', paddingLeft: "10px" }}>Cần chọn hình thức gửi báo cáo</p>}
                            </>
                        }
                    />
                    {/* <TextField id="outlined-basic" sx={{ mt: 2 }} 
                    label="Mlytics_id" 
                    variant="outlined" 
                    value={reportMetadata.mlytics_id}
                    onChange={(e) => handleOnchangeId(e.target.value)}
                /> */}

                    <Autocomplete
                        options={data?.Customers || []}
                        getOptionLabel={(option) => option.organizationName}
                        value={{ organizationName: autoCompleteValue }}
                        freeSolo
                        renderOption={renderOption}
                        onChange={(_, newValue) => {
                            if (!newValue) {
                                setAutoCompleteValue('');
                                setReportMetadata({ ...reportMetadata, mlytics_id: '' });
                            } else {
                                onAutocompleteOnChange(newValue);
                            }
                        }}
                        onInputChange={onInputChange}
                        sx={{ width: '100%', mt: 2 }}
                        renderInput={(params) => <TextField {...params} label="Mlytics_id" onKeyDown={(e) => e.key === "Enter" && e.preventDefault()} />}
                    />
                    <TextField id="outlined-basic" sx={{ mt: 2 }}
                        label="Source Domains"
                        variant="outlined"
                        value={reportMetadata.source_domains}
                        onChange={(e) => handleOnchangeSource_domains(e.target.value)}
                    />
                    <TextField
                        type='mail'
                        id="outlined-basic" label="Mail người liên hệ" sx={{ mt: 2 }} variant="outlined"
                        value={mail}
                        onChange={(e) => handleOnchangeMail(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddMail();
                            }
                        }
                        }
                    />
                    <Button variant='contained' sx={{ mt: 2, float: 'right' }}
                        onClick={() => handleAddMail()}
                    >
                        Thêm Mail
                    </Button>
                    <TextField id="outlined-basic" sx={{ mt: 2 }} label="Domain" variant="outlined"
                        value={domain}
                        onChange={(e) => handleOnchangeDomain(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddDomain();
                            }
                        }
                        }
                    />
                    <Button variant='contained' sx={{ mt: 2, float: 'right' }}
                        onClick={() => handleAddDomain()}
                    >Thêm Domain
                    </Button>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                Mail người liên hệ
                            </Typography>
                            <List dense={true}>
                                {
                                    reportMetadata.owners.map((mail, index) =>
                                        <ListItem
                                            key={index}
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveMail(index)}>
                                                    <CancelIcon />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <ContactMailIcon />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={mail}
                                            />
                                        </ListItem>
                                    )}
                            </List>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                Domains
                            </Typography>
                            <List dense={true}>
                                {
                                    reportMetadata.domains.map((domain, index) =>
                                        <ListItem
                                            key={index}
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveDomain(index)}>
                                                    <CancelIcon />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <DnsIcon />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={domain}
                                            />
                                        </ListItem>
                                    )}
                            </List>
                        </Grid>
                    </Grid>
                    <Button variant='contained' sx={{ mt: 2 }} type="submit">Thêm báo cáo</Button>
                </Container>

            </Dialog>
        </>
    );
}

export default DialogAddReport;
