import { Box, Button, CircularProgress, Tab, Tabs } from "@mui/material";
import React from "react";
import Header from "../Header";
import TableDefault from "./Table/TableDefault";
import TableAdvance from "./Table/TableAdvance";
import { useNavigate } from "react-router";
import { AddOutlined } from "@mui/icons-material";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import withSuspense from "@/hocs/withSuspense";

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const Cluster = () => {
    const [valueTab, setValueTab] = React.useState("table");
    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    const navigate = useNavigate();
    return (
        <Box>
            <Header />
            <Button
                variant='contained'
                size='small'
                sx={{
                    height: { xs: 36, md: 42 },
                    mt: 2,
                    mb: 2
                }}
                onClick={() => {
                    navigate("/warehouse/bandwidths/clusters/new")
                }}
                startIcon={<AddOutlined />}
            >
                Thêm Bandwidth Đối Soát Cụm
            </Button>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
                    <Tab label={`Hiển thị mặc định`} value={"table"} {...a11yProps(0)} />
                    <Tab label={`Hiển thị nâng cao`} value={"chart"} {...a11yProps(1)} />
                </Tabs>
                
            </Box>
            <React.Suspense fallback={<CircularProgress size={24} sx={{ m: 'auto' }} />}>
                    {valueTab === "table" ?
                        <TableDefault /> :
                        <TableAdvance />
                    }
                </React.Suspense>
        </Box>
    )
}
export default withSuspense(withPermission(Cluster, {
    feature: "warehouse",
    action: "bandwidth-cluster"
}), MuiSkeleton["DataGrid"]) 