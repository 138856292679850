import DriveApi from "@/apis/beta/Drives/DriveApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { useConfirmContext } from "@contexts/confirm";
import { useNotificationContext } from "@contexts/notification";
import { useUploadFile } from "@contexts/upload-file-context";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FolderIcon from "@mui/icons-material/Folder";
import ShareIcon from "@mui/icons-material/Share";
import { Box, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import CreateFolder from "../../Dialog/CreateFolder";
import DrivesShare from "../../Dialog/DrivesShare";
import React from "react";

const UploadButton = ({ prefix, refetch, isFetching }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showCreateFolder, setShowCreateFolder] = React.useState(false);
  const [showDialogShare, setShowDialogShare] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { setTask } = useUploadFile();

  const { setNotification } = useNotificationContext();
  const { showConfirmation } = useConfirmContext();

  const [searchParams] = useSearchParams();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const processFilesInChunks = async (files, chunkSize, prefix) => {
    const results = [];

    for (let i = 0; i < files.length; i += chunkSize) {
      const chunk = files.slice(i, i + chunkSize);

      const chunkResults = await Promise.allSettled(
        chunk
          .filter((item) => item.size > 0)
          .map(async (item) =>
            mutateAsync({
              file: item,
              key: `${prefix.replace("pub/drives/", "")}${
                item?.webkitRelativePath || item?.name
              }`,
            })
          )
      );

      results.push(...chunkResults);
    }

    return results;
  };

  const handleUploadFiles = (folder) => {
    setTask([]);
    setLoading(true);

    const inputEl = document.createElement("input");

    inputEl.webkitdirectory = folder;
    inputEl.mozdirectory = folder;
    inputEl.type = "file";
    inputEl.multiple = true;

    inputEl.click();

    inputEl.onchange = async (e) => {
      const files = e?.target?.files;

      if (files?.length > 1000) {
        inputEl.remove();
        setLoading(false);
        setNotification({
          open: true,
          message: "Chỉ được upload tối đa 1000 files một lúc",
          severity: "error",
        });

        return;
      }

      await processFilesInChunks([...files], 100, prefix);
      refetch();
      inputEl.remove();
      setLoading(false);
    };

    inputEl.oncancel = () => {
      inputEl.remove();
      setLoading(false);
    };

    handleClose();
  };

  const handleUploadFile = async ({ file, key }) => {
    try {
      setTask((prev) => [
        { name: file?.name, percent: 0, error: false, key, loading: true },
        ...prev,
      ]);

      const response = await DriveApi.PresignedUrl(key);

      if (response?.errors || !response?.data?.url) {
        setTask((prev) =>
          prev?.map((item) =>
            item?.key === key ? { ...item, error: true, loading: false } : item
          )
        );
      }

      await axios
        .put(response?.data?.url, file, {
          onUploadProgress: (p) => {
            const { loaded, total } = p;
            const percent = Math.floor((loaded * 100) / total);

            setTask((prev) =>
              prev?.map((item) =>
                item?.key === key ? { ...item, percent, retry: false } : item
              )
            );
          },
          headers: {
            "Content-Type": file.type,
          },
        })
        .catch((err) => {
          console.log(err);
          setTask((prev) =>
            prev?.map((item) =>
              item?.key === key
                ? { ...item, error: true, retry: false, loading: false }
                : item
            )
          );
        });

      setTask((prev) =>
        prev?.map((item) =>
          item?.key === key ? { ...item, loading: false } : item
        )
      );
    } catch (error) {
      console.log(error);
      setTask((prev) =>
        prev?.map((item) =>
          item?.key === key ? { ...item, error: true, loading: false } : item
        )
      );
    }
  };

  const { mutateAsync } = useMutationWithNotification(handleUploadFile);

  return (
    <Box mt={2}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          size="small"
          variant="contained"
          disabled={loading || !prefix}
          endIcon={<ArrowDropDownIcon />}
          startIcon={loading && <CircularProgress size={14} />}
        >
          Upload
        </Button>

        <Button
          onClick={() => {
            setShowCreateFolder(true);
            handleClose();
          }}
          size="small"
          variant="outlined"
          startIcon={<FolderIcon />}
        >
          New Folder
        </Button>

        {isFetching && (
          <Button
            disabled
            size="small"
            variant="outlined"
            startIcon={<CircularProgress size={14} />}
          >
            Loading...
          </Button>
        )}
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            showConfirmation({
              title: "Tính năng thử nghiệm",
              open: true,
              description:
                "Upload folder là chức năng thử nghiệm để tránh gây lỗi bạn nên up thư mục có dưới 1000 files",
              loading: false,
              handler: () => handleUploadFiles(true),
            })
          }
          sx={{ fontSize: 16 }}
        >
          <FolderIcon sx={{ mr: 2 }} />
          Upload a folder
        </MenuItem>

        <MenuItem
          onClick={() => handleUploadFiles(false)}
          sx={{ fontSize: 16 }}
        >
          <AttachFileIcon sx={{ mr: 2 }} />
          Upload a file
        </MenuItem>

        <MenuItem
          onClick={() => {
            setShowDialogShare(true);
            handleClose();
          }}
          sx={{ fontSize: 16 }}
        >
          <ShareIcon sx={{ mr: 2 }} />
          Share
        </MenuItem>
      </Menu>

      {showCreateFolder && (
        <CreateFolder
          open={showCreateFolder}
          handleClose={() => setShowCreateFolder(false)}
          prefix={prefix}
        />
      )}

      {showDialogShare && (
        <DrivesShare
          handleClose={() => setShowDialogShare(false)}
          open={showDialogShare}
          path={searchParams?.get("path")}
        />
      )}
    </Box>
  );
};

export default UploadButton;
