import MuiDateTimePicker from "@components/shared/MuiDateTimePicker";
import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const TicketTimeRelease = ({ ticket }) => {
    const { control } = useFormContext()
    if (!ticket) return null
    return (
        <Controller
            name="ticket_time_release"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
                <FormControl fullWidth>
                    <MuiDateTimePicker
                        {...field}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Ngày dự kiến hoàn thành *"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </FormControl>
            )}
        />
    )
}

export default TicketTimeRelease