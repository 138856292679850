import DayoffApi from "@/apis/beta/Employee/DayoffApi";
import ErrorHelperText from "@/components/shared/ErrorHelperText";
import { useEmployeeContext } from "@/contexts/employee";
import { useNotificationContext } from "@/contexts/notification";
import { queryClientRefetcher, sleep } from "@/functions";
import { vestResolver } from "@hookform/resolvers/vest";
import { Box, Button, CircularProgress, Paper } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import DayoffDepartment from "../DayoffDepartment";
import DayoffEmployee from "../DayoffEmployee";
import DayoffPosition from "../DayoffPosition";
import DayoffRangeTime from "../DayoffRangeTime";
import DayoffReason from "../DayoffReason";
import DayoffTitle from "../DayoffTitle";
import DayoffType from "../DayoffType";
import EmployeeDayoffLeave from "../EmployeeDayoffLeave";
import { dayoffValidator } from "./validator";

const DayoffForm = ({ type = "create", data }) => {
  const queryClient = useQueryClient();
  const { employee } = useEmployeeContext();
  let navigate = useNavigate();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);
  const methods = useForm({
    resolver: vestResolver((data) =>
      dayoffValidator({
        ...data,
        type: type,
        employee_day_off_left: employee?.employee_day_off_left,
        employee: employee?.employee_id,
      })
    ),
    defaultValues: {
      day_off_type: 0,
      day_off_note: "",
      day_off_range_time: [{ startdayoff: moment(), dayoff_option: 0 }],
    },
  });

  const { control, handleSubmit, reset } = methods;
  React.useEffect(() => {
    if (type === "create") return;
    reset({ ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const numberDayoffs = useWatch({
    control: control,
    name: "day_off_range_time",
  });

  const numberOfDayoff = () => {
    let numberDayoff = 0;
    _.forEach(numberDayoffs, (dayoff) => {
      if (dayoff?.dayoff_option <= 1) {
        return (numberDayoff += 0.5);
      }
      return (numberDayoff += 1);
    });
    return numberDayoff;
  };

  const onSubmit = async (values) => {
    setSubmit(true);
    let response = {};
    let form = {
      day_off_type: values?.day_off_type,
      day_off_note: values?.day_off_note || "",
      day_off_reason: values?.day_off_reason,
      day_off_status: 0,
      number_of_day_off: numberOfDayoff(),
      day_off_range_time: values?.day_off_range_time,
    };
    switch (type) {
      case "create":
        response = await DayoffApi.Create(form);
        break;
      case "update":
        await DayoffApi.Update(data?.day_off_id, form);
        break;
      case "cancel":
        response = await DayoffApi.Cancel(data?.day_off_id, data?.day_off_note);
        break;
      default:
        return setNotification({
          open: true,
          message: "thao tác không hợp lệ",
          severity: "error",
        });
    }
    await sleep(500);
    setSubmit(false);
    if (!response || (response.status !== 200 && response.status !== 201))
      return setNotification({
        open: true,
        message: response?.errors[0]?.message,
        severity: "error",
      });
    setNotification({
      open: true,
      message: `${
        type === "create"
          ? "Tạo phiếu nghỉ"
          : type === "update"
          ? "Cập nhật"
          : "Huỷ phiếu"
      } thành công`,
      severity: "success",
    });
    queryClientRefetcher(queryClient, ["all_day_offs.list", "day_off.detail"]);
    return handleClose();
  };

  const handleClose = () => {
    return navigate("/account/dayoffs", { replace: true });
  };

  return (
    <FormProvider {...methods}>
      <Paper
        sx={{
          p: "30px",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DayoffTitle type={type} />
        <DayoffEmployee employee={employee} />
        <DayoffPosition employee={employee} />
        <DayoffDepartment employee={employee} />
        <EmployeeDayoffLeave employee={employee} />
        {numberOfDayoff() > 3 && (
          <ErrorHelperText
            message={
              "Số ngày nghỉ của bạn đã vượt quá 3 ngày. Vui lòng liên hệ với leader và gửi mail nêu rõ lý do nghỉ phép cho Leader, HR và BOD để được xem xét."
            }
          />
        )}
        <DayoffType />
        <DayoffRangeTime type={type} />
        <DayoffReason type={type} />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 1.5, md: 2 },
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{ height: { xs: 40, md: 42 } }}
            onClick={handleClose}
            disabled={submit}
          >
            Huỷ Thao Tác
          </Button>
          <Button
            variant="contained"
            type="submit"
            size="small"
            sx={{ height: { xs: 40, md: 42 }, minWidth: 150 }}
            disabled={submit}
            startIcon={
              submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
            }
          >
            {type === "cancel"
              ? "huỷ phiếu"
              : type === "update"
              ? "cập nhật"
              : "tạo phiếu"}{" "}
          </Button>
        </Box>
      </Paper>
    </FormProvider>
  );
};

export default DayoffForm;
