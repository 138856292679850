import CustomerInvoiceApi from "@/apis/beta/Customer/CustomerInvoiceApi";
import DataGrid from "@/components/DataGrid";
import { CellPricing } from "@/components/DataGrid/Cell";
import { format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import { INVOICE_PAYMENT_METHODS, INVOICE_STATUS } from "@/libs/constants";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import InvoiceServices from "@features/Module/Invoice/List/InvoiceService";
import QuickView from "@features/Module/Invoice/List/QuickView";
import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ModalMergeInvoice from "./ModalMerge";
import Toolbar from "./Toolbar";

const InvoiceList = ({ customerId }) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [modalMerge, setModalMerge] = React.useState({
    open: false,
    invoice_ids: [],
  });
  const [anchorEl, setAnchorEl] = React.useState({
    ref: undefined,
    anchorEl: undefined,
    data: undefined,
  });

  const filterableCustomerInvoice = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const acceptFilters = [
      {
        name: "invoice_number",
        condition: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "invoices.invoice_id",
        condition: "$eq",
        value: parseInt(debouncedSearchQuery),
        disabled: !parseInt(debouncedSearchQuery),
      },
    ];
    const queryNumber = 0;
    let filter = "";
    acceptFilters?.forEach((field, index) => {
      if (field.disabled) return;
      filter += `&filters[$or][${queryNumber + index}][${field?.name}][${field?.condition
        }]=${field?.value}`;
    });
    return filter;
  }, [debouncedSearchQuery]);

  const [columns] = React.useState([
    {
      field: "invoice_id",
      headerName: "ID - Invoice",
      hideable: true,
      width: 100,
      renderCell: ({ value }) => (
        <Link to={`/invoices/${value}`}>
          <Typography className="react-link-table">{value}</Typography>
        </Link>
      ),
    },
    {
      field: "invoice_number",
      headerName: "Số Hoá Đơn",
      width: 150,
      renderCell: ({ value }) => value,
    },
    {
      field: "service_product_id",
      headerName: "Dịch Vụ",
      minWidth: 300,
      valueGetter: ({ row }) => row?.invoice_services || [],
      renderCell: ({ value }) => <InvoiceServices services={value} />,
    },
    {
      field: "invoice_total",
      headerName: "Tổng Tiền",
      width: 150,
      sortable: true,
      operators: { type: "price" },
      renderCell: ({ value }) => <CellPricing price={value} />,
    },
    {
      field: "invoice_metadata",
      headerName: "Nội Dung/ Mô Tả",
      minWidth: 300,
      renderCell: ({ value, row }) => {
        return (
          <Box
            sx={{ width: "100%" }}
            onClick={(event) => {
              setAnchorEl({
                refEl: event?.currentTarget,
                anchorEl: true,
                data: row,
              });
            }}
          >
            <Typography
              sx={{ fontSize: 14, textDecoration: "underline" }}
              color="primary"
            >
              {value}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "invoice_billing",
      headerName: "Thời Gian Áp Dụng",
      minWidth: 230,
      valueGetter: ({ row }) => {
        return `${format_date_short(
          row?.invoice_billing_startdate
        )} - ${format_date_short(row?.invoice_billing_enddate)}`;
      },
    },
    {
      field: "invoice_created_at",
      headerName: "Ngày Tạo",
      width: 150,
      renderCell: ({ value }) => format_date_short(value),
    },
    {
      field: "invoice_duedate",
      headerName: "Hạn Thanh Toán",
      width: 150,
      renderCell: ({ value }) => format_date_short(value),
    },
    {
      field: "invoice_paid",
      headerName: "Ngày Thanh Toán",
      width: 150,
      valueGetter: ({ row }) => {
        if (row?.invoice_status === 1) {
          return "";
        }
        return row?.invoice_paid;
      },
      renderCell: ({ value }) => (value ? format_date_short(value) : "---"),
    },
    {
      field: "invoices.invoice_status",
      headerName: "Trạng Thái",
      width: 150,
      valueGetter: ({ row }) =>
        INVOICE_STATUS.find((stt) => stt?.value === row?.invoice_status),
      renderCell: ({ value }) => (
        <Chip
          size="small"
          variant={`customize-${value?.chip}`}
          label={value?.name}
        />
      ),
    },
    {
      field: "invoices.invoice_type",
      headerName: "Hình Thức Thanh Toán",
      width: 180,
      valueGetter: ({ row }) =>
        INVOICE_PAYMENT_METHODS.find(
          (stt) => stt?.value === row?.invoice_payment_method
        ),
      renderCell: ({ value }) => <Chip size="small" label={value?.label} />,
    },
  ]);
  const { data, isFetching } = CustomerInvoiceApi.List({
    query: filterableCustomerInvoice(),
    customerId: customerId,
    page: page?.page + 1,
    pageSize: page?.pageSize,
  });

  return (
    <Box sx={{ mt: 2 }}>
      {Boolean(anchorEl?.anchorEl) && anchorEl?.data !== undefined && (
        <QuickView
          setAnchorEl={setAnchorEl}
          refEl={anchorEl?.refEl}
          invoice_id={anchorEl?.data?.invoice_id}
        />
      )}
      <DataGrid
        rows={
          data?.invoices?.map((invoice) => ({
            ...invoice,
            id: invoice?.invoice_id,
          })) || []
        }
        columns={columns}
        loading={isFetching}
        components={{
          Toolbar: Toolbar,
        }}
        getRowHeight={({ model }) => {
          const PADDING_OFFSET_Y = 8;
          const SERVICES =
            24 * (model?.invoice_services?.length || 0) + PADDING_OFFSET_Y;
          return Math.max(SERVICES, 52);
        }}
        componentsProps={{
          toolbar: {
            handleClick: () =>
              setModalMerge((prev) => ({ ...prev, open: true })),
            disabled: modalMerge?.invoice_ids?.length <= 1 ? true : false,
            searchQuery: searchQuery,
            setSearchQuery: setSearchQuery,
          },
          pagination: {
            page: page?.page || 0,
            pageSize: page?.pageSize || 5,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
            rowsPerPageOptions: [5, 10, 20],
          },
        }}
        disableSelectionOnClick
        isRowSelectable={({ row }) => {
          return row?.invoice_status !== 2;
        }}
        checkboxSelection
        onSelectionModelChange={(props) => {
          setModalMerge((prevState) => ({ ...prevState, invoice_ids: props }));
        }}
        rowCount={data?.pagination?.total}
      />
      {modalMerge?.open && (
        <ModalMergeInvoice
          data={modalMerge?.invoice_ids}
          handleClose={() =>
            setModalMerge((prev) => ({ ...prev, open: false }))
          }
        />
      )}
    </Box>
  );
};
export default withSuspense(
  withPermission(InvoiceList, {
    feature: "customer",
    action: "customer-invoice-index",
    type: "Block",
  }),
  MuiSkeleton["DataGrid"]
);
