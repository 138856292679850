import { useEmployeeContext } from "@contexts/employee";
import { AddOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { JourneyComponent } from "../JourneyComponent";
import JourneyApi from "@/apis/beta/Customer/Journey/JourneyApi";
import { useQueryClient } from "react-query";
import { queryClientRefetcher } from "@/functions";
import { useNotificationContext } from "@contexts/notification";

const CreateJourney = () => {
  const queryClient = useQueryClient();
  const { employee } = useEmployeeContext();
  const { setNotification } = useNotificationContext();
  const { customer_id: customerId } = useParams();

  const [isOpen, setOpen] = useState(false);
  const methods = useForm({
    defaultValues: {
      service_interestings: [],
      sale_tracking_id: [employee?.employee_id],
    },
  });
  const [submit, setSubmit] = useState(false);

  const onSubmit = async (values) => {
    setSubmit(true);
    const response = await JourneyApi.Create({
      customerId: customerId,
      data: { ...values },
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }

    queryClientRefetcher(queryClient, ["customers.journeys"]);
    setNotification({
      open: true,
      message: "Thêm mới quá trình bán hàng thành công.",
      severity: "success",
    });

    return setOpen(false);
  };

  return (
    <Fragment>
      <Collapse in={!isOpen}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
          <Button
            size="small"
            variant="outlined"
            disabled={isOpen}
            onClick={() => setOpen(!isOpen)}
            startIcon={<AddOutlined fontSize="small" />}
          >
            Thêm Quá Trình
          </Button>
        </Box>
      </Collapse>
      <Collapse in={isOpen}>
        <FormProvider {...methods}>
          <Paper
            component="form"
            sx={{ p: 2, mb: 2 }}
            elevation={8}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Typography
              sx={{
                fontWeight: "medium",
                mb: 0.5,
                textTransform: "capitalize",
              }}
            >
              Thêm mới quá trình bán hàng
            </Typography>
            <Grid container spacing={2}>
              {[
                {
                  key: "service_interestings",
                  label: "Dịch Vụ Khai Thác",
                  component: "ServiceInteresting",
                },
                {
                  key: "sale_tracking_id",
                  label: "Nhân Viên Phụ Trách",
                  component: "SaleTracking",
                  props: {
                    name: 'sale_tracking_id'
                  }
                },
              ]?.map((option, index) => {
                const Component = JourneyComponent[option?.component] || null;
                if (!Component) return null;
                return (
                  <Fragment key={index}>
                    <Grid item xs={12} md={3}>
                      <Typography
                        sx={{ fontSize: 14, color: "#777680", m: "auto 0" }}
                      >
                        {option?.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Controller
                        key={index}
                        control={methods.control}
                        name={option?.key}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FormControl fullWidth>
                              <Component value={value} onChange={onChange} {...option?.props} />
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 1.5,
                gap: 1.75,
              }}
            >
              <Button
                size="small"
                variant="outlined"
                disabled={submit}
                onClick={() => setOpen(false)}
              >
                Huỷ Thao Tác
              </Button>
              <Button
                size="small"
                variant="contained"
                type="submit"
                startIcon={
                  submit && (
                    <CircularProgress size={14} sx={{ color: "#777680" }} />
                  )
                }
                disabled={submit}
              >
                Tạo Mới
              </Button>
            </Box>
          </Paper>
        </FormProvider>
      </Collapse>
    </Fragment>
  );
};

export default CreateJourney;
