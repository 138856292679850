import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import WarehouseRackApi from "@/apis/beta/Warehouse/WarehouseRackApi";
import { Filter } from "@/hooks/useFilters";
import DeviceAnalysist from "@features/Module/WarehouseNew/Shared/Statistic";
import {
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { GridToolbarContainer, GridToolbarColumnsButton   
} from '@mui/x-data-grid';
import DataGridPreview, {
  getTreeDataPath
} from "@components/DataGridPreview";
import { GridTreeDataGroupingCell } from "@components/DataGridPreview/DataGridPro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
const TableWarehouseItemAccessory = ({ type = "device" }) => {
  const { data: warehouse_models } = WarehouseModelApi.All({ short: true });
  const { data: warehouse_boxes } = WarehouseBoxApi.getAll();

  const query =
    type === "device"
      ? {
        $and: [{ "warehouse_items.warehouse_item_form_factor": { $gt: 0 } }],
      }
      : {
        $and: [{ "warehouse_items.warehouse_item_form_factor": { $eq: 0 } }],
      };
  const { data: warehouse_items } = WarehouseItemApi.getAllWithProcess(
    Filter.stringify(query)
  );
  const { data: warehouse_racks } = WarehouseRackApi.getAll();

  const [result, setResult] = React.useState({ rows: [], columns: [] });

  const [warehouseTypes, setWarehouseTypes] = React.useState([]);

  const getData = () => {
    let columns = [
      {
        field: "total_input",
        headerName: "Đã nhập",
        sortable: true,
        width: 100,
      },
      {
        field: "total_export",
        headerName: "Đã bán",
        sortable: true,
        width: 100,
      },
      {
        field: "total",
        headerName: "Tổng thiết bị công ty(Đã nhập - bán)",
        sortable: true,
        width: 200,
      },
      {
        field: "total_warehouse",
        headerName: "Ở kho",
        sortable: true,
        width: 100,
      },
      {
        field: "total_idc",
        headerName: "Ở IDC",
        sortable: true,
        width: 100,
      },
    ];

    if (type !== "device") {
      columns.unshift({
        field: "manufacture",
        headerName: "Hãng",
        width: 150,
      },);

      columns.unshift({
        field: "capacity",
        headerName: "Capacity",
        width: 150,
        renderCell: ({ row, value }) => 
          value ?
          `${value} ${row?.capacity_unit}`: ""
      });
    }

    let data_warehouse_models = [];
    if (type === "device") {
      data_warehouse_models = warehouse_models?.filter(
        (o) =>
          o?.warehouse_type?.warehouse_group_id === 1 &&
          o?.warehouse_type?.warehouse_type_id !== 20
      );
    } else {
      data_warehouse_models = warehouse_models?.filter(
        (o) => o?.warehouse_type?.warehouse_group_id !== 1
      );
    }
    _.orderBy(warehouse_boxes, ["warehouse_box_type"], ["desc"])?.forEach(
      (element) => {
        const col = {
          field: `${element?.warehouse_box_name?.replaceAll(" ", "_")?.toLowerCase() + element?.warehouse_box_id}`,
          headerName: `${element?.warehouse_box_name?.replace(/IDC/g, "")}`,
          hide: element?.warehouse_box_type !== 0,
          cellClassName: element?.warehouse_box_type === 0 ? "box" : "rack",
          width:
            element?.warehouse_box_name?.length * 7 > 100
              ? element?.warehouse_box_name?.length * 7
              : 120,
        };
        columns?.push(col);
      }
    );

    let data_warehouse_types = _.uniqBy(
      data_warehouse_models,
      (o) => o?.warehouse_type_id
    );


    data_warehouse_types = _.map(data_warehouse_types, (o) => {
      const warehouse_type_parent = _.find(data_warehouse_models, {
        warehouse_type_id: o?.warehouse_type?.warehouse_type_parent,
      });

      return {
        ...o,
        warehouse_type_parent_name:
          warehouse_type_parent?.warehouse_type_name || "",
        warehouse_type_parent_id: warehouse_type_parent?.warehouse_type_id || 0,
      };
    });

    setWarehouseTypes(data_warehouse_types.map((o) => o?.warehouse_type_id));

    const data_warehouse_items = warehouse_items
      ?.filter(
        (i) =>
          i?.warehouse_owner_name === "VNETWORK" &&
          i?.warehouse_item_export === false
      )
      .map((i) => {
        const warehouse_box_name =
          i.warehouse_box_item_reference_type === "WarehouseBox"
            ? _.find(warehouse_boxes, {
              warehouse_box_id: i?.warehouse_box_item_reference_id,
            })?.warehouse_box_name
            : _.find(warehouse_racks, {
              warehouse_rack_id: i?.warehouse_box_item_reference_id,
            })?.warehouse_box_name;

        return {
          ...i,
          warehouse_box_name: warehouse_box_name,
        };
      });

    let rows = [];
    _.uniqBy(
      data_warehouse_types?.filter((o) => o?.warehouse_type?.warehouse_type_parent !== 0),
      (o) => o?.warehouse_type_parent_id
    );
    console.log(data_warehouse_types)
    console.log(data_warehouse_items)
    data_warehouse_types = data_warehouse_types?.filter((o, index) => {
      const total = data_warehouse_items?.filter(
        (i) => i?.warehouse_type_id === o?.warehouse_type_id
      );

      const item = {
        id: `${o?.warehouse_type_id}_${index}`,
        hierarchy: [`${o?.warehouse_type_name}`],
        warehouse_type_id: o?.warehouse_type_id,
        name: "",
        total: _.uniqBy(total, "warehouse_item_id")?.length,
      };

      if (o?.warehouse_type?.warehouse_type_parent === 0) {
        if (total?.length > 0) {
          warehouse_boxes?.forEach((element) => {
            item[`${element["warehouse_box_name"]?.replaceAll(" ", "_")?.toLowerCase() + element?.warehouse_box_id}`] = total?.filter(
              (i) => i?.warehouse_box_name === element["warehouse_box_name"]
            )?.length;
          });
          rows?.push(item);
        }
      }

      return item;
    });

    data_warehouse_models?.forEach((o, index) => {
      const data_warehouse_items = warehouse_items
        .filter(
          (i) =>
            i?.warehouse_owner_name === "VNETWORK" &&
            i?.warehouse_item_export === false
        )
        .map((i) => {
          const warehouse_box_name =
            i.warehouse_box_item_reference_type === "WarehouseBox"
              ? _.find(warehouse_boxes, {
                warehouse_box_id: i?.warehouse_box_item_reference_id,
              })?.warehouse_box_name
              : _.find(warehouse_racks, {
                warehouse_rack_id: i?.warehouse_box_item_reference_id,
              })?.warehouse_box_name;

          return ({
            ...i,
            warehouse_box_name: warehouse_box_name,
          });
        });

      const total = data_warehouse_items.filter(
        (i) => i?.warehouse_model_id === o?.warehouse_model_id
      );
      const modelSpecifications = o?.specifications?.filter((item) => {
        return item?.specification_name?.toLowerCase() === "capacity";
      })
      let item = {
        id: `${o?.warehouse_model_id}__${index}`,
        type_parent: `${o?.warehouse_type?.warehouse_type_parent}`,
        hierarchy: [`${o?.warehouse_type_name}`, `${o?.warehouse_model_name} - ${o?.manufacture.manufacture_name}`],
        warehouse_model_name: o?.warehouse_model_name,
        warehouse_model_id: o?.warehouse_model_id,
        manufacture: `${o?.manufacture.manufacture_name}`,
        warehouse_type_id: o?.warehouse_type_id,
        total: _.uniqBy(total, "warehouse_item_id")?.length,
        total_input: calculateToTalInput(o),
        total_warehouse: calculateTotalWarehouse(o),
        total_idc: calculateTotalIDC(o),
        total_export: calculateTotalExport(o),
      };
      if (type !== "device") {
        item["capacity"] = modelSpecifications?.length > 0 ? Number(modelSpecifications[0]?.specification_value) : "";
        item["capacity_unit"] = modelSpecifications?.length > 0 ? modelSpecifications[0]?.specification_unit : "";
      } 

      warehouse_boxes?.forEach((element) => {
        item[`${element["warehouse_box_name"]?.replaceAll(" ", "_")?.toLowerCase() + element?.warehouse_box_id}`] = _.uniqBy(total?.filter(
          (i) => i.warehouse_box_name === element["warehouse_box_name"]
        ),'warehouse_item_id')?.length;
      });
      if (o?.warehouse_type?.warehouse_type_parent === 0) {
        if (total?.length > 0) {
          rows?.push(item);
        }
      }
      if (o?.warehouse_type?.warehouse_type_parent !== 0 && o?.warehouse_type?.warehouse_type_parent === 3 && type !== "device") {
        if (!rows.some(row => row["hierarchy"][0] === item?.hierarchy[0] && row["hierarchy"]?.length === 1 && row["type_parent"] === 3)) {
          rows.push({
            warehouse_type_id: o?.warehouse_type?.warehouse_type_id,
            total: 0,
            // manufacturer_name: o?.manufacture.manufacture_name,
            id: item?.hierarchy[0] + `-${o?.warehouse_type?.warehouse_type_name}`,
            hierarchy: ["DISK", item?.hierarchy[0]]
          })
        }
        item?.hierarchy?.unshift("DISK")

        if (total?.length > 0) {
          rows?.push(item);
        }
      }
    });

    rows = _.uniqBy(rows, "id")
    let typeSingle = rows?.filter((item) => item["hierarchy"]?.length === 1)
    typeSingle?.forEach((item) => {
      const totalChildItem = rows?.filter((childItem) => childItem["hierarchy"][0] === item["hierarchy"][0] && childItem["hierarchy"]?.length === 2)
      if (totalChildItem?.length > 0) {
        Object.keys(totalChildItem[0]).forEach((key) => {
          if (key !== "id" && key !== "hierarchy" && key !== "capacity" && key !== "manufacture" && key !== "warehouse_type_id") {
            item[key] = totalChildItem?.reduce((total, itemChild) => total + itemChild?.[key] || 0, 0)
          }
        })
      }
    })
    if (type !== "device" && rows.some(row => row["hierarchy"][0] === "DISK")) {
      let child = rows?.filter((item) => item["hierarchy"][0] === "DISK" && item["hierarchy"]?.length === 2)
      child?.forEach((item) => {
        const totalChildItem = rows?.filter((childItem) => childItem["hierarchy"][1] === item["hierarchy"][1] && childItem["hierarchy"]?.length === 3)
        if (totalChildItem?.length > 0) {
          Object.keys(totalChildItem[0]).forEach((key) => {
            if (key !== "id" && key !== "hierarchy" && key !== "capacity" && key !== "manufacture" && key !== "warehouse_type_id") {
              item[key] = totalChildItem?.reduce((total, itemChild) => total + itemChild?.[key] || 0, 0)
            }
          })
        }
      })
      child = child.filter((item) => item?.total > 0)
      rows = rows.filter((item) => item?.total > 0)
      const itemParent = {
        warehouse_type_id: 3,
        hierarchy: ["DISK"],
        total: child?.reduce((total, item) => total + item?.total, 0),
        id: "DISK",
      }
      Object.keys(child[0]).forEach((key) => {
        if (key !== "id" && key !== "hierarchy" && key !== "capacity" && key !== "manufacture" && key !== "total" && key !== "warehouse_type_id") {
          itemParent[key] = child?.reduce((total, item) => total + item?.[key], 0)
        }
      })
      rows.push(itemParent)
    }
    setResult({
      columns: columns,
      rows: _.orderBy(
        rows,
        ["total", "hierarchy", "warehouse_type_id"],
        ["desc", "asc", "desc"]
      ),
    });
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const groupingColDef = React.useCallback(
    () => ({
      field: "__tree_data_group__",
      headerName: 'Group',
      width: 275,

      renderCell: ({row, ...params}) => {
        const { rowNode } = params;
        if(row?.manufacture){
          params = {
            ...params,
            rowNode: {
              ...params["rowNode"],
              groupingKey: row?.warehouse_model_name
            }
          }
        }
        return (
          <GridTreeDataGroupingCell
            total={
              rowNode?.children?.length
            }
            {...params}
            
          />

        );
      },
    }),
    []
  );
  const calculateToTalInput = (row) => {
    return calculateTotalExport(row) + 
    calculateTotalIDC(row) + 
    calculateTotalWarehouse(row)
  }
  const calculateTotalExport = (row) => {
     const items_exported = _.filter(
      warehouse_items,
      (o) =>
        o?.warehouse_model_id === row?.warehouse_model_id &&
        o?.warehouse_item_export === true 
        && o?.warehouse_process_type === 3 
        && o?.warehouse_process_status === 'completed'
    )
    return _.uniqBy(items_exported, "warehouse_item_id")?.length
  }
  const calculateTotalIDC = (row) => {
    const item_idc = _.filter(
      warehouse_items,
      (o) =>
        o?.warehouse_model_id === row?.warehouse_model_id &&
        o?.warehouse_item_export === false &&
        o?.warehouse_owner_name === "VNETWORK" &&
        o?.warehouse_box_item_reference_type !== "WarehouseBox"
    )
    return _.uniqBy(item_idc,"warehouse_item_id")?.length
  }

  const calculateTotalWarehouse = (row) => {
    const item_warehouse = _.filter(
      warehouse_items,
      (o) =>
        o?.warehouse_model_id === row?.warehouse_model_id &&
        o?.warehouse_item_export === false &&
        o?.warehouse_owner_name === "VNETWORK" &&
        o?.warehouse_box_item_reference_type === "WarehouseBox" &&
        o?.warehouse_item_parent === 0
    )
    return _.uniqBy(item_warehouse,"warehouse_item_id")?.length
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        {warehouseTypes.length > 0 ? (
          <React.Suspense
            fallback={<CircularProgress size={24} sx={{ m: "auto" }} />}
          >
            <DeviceAnalysist
              key={warehouseTypes.join(",")}
              type={warehouseTypes}
              query={""}
              device={type === "device" ? true : false}
              filterableSearch={() => ""}
            />
          </React.Suspense>
        ) : (
          <></>
        )}
      </Box>
      <Paper
        sx={{
          mt: 3,
          "& .box": {
            backgroundColor: "#b9d5ff91",
            fontWeight: "600",
            textAlign: "center",
          },
          "& .rack": {
            backgroundColor: "#ff943975",
            fontWeight: "600",
            textAlign: "center",
          },
        }}
      >
        <DataGridPreview
          treeData
          autoHeight
          groupingColDef={groupingColDef}
          getTreeDataPath={getTreeDataPath}
          rows={result?.rows || []}
          hideAble = {true}
          disableSelectionOnClick={false}
          options={{
            name: 'Thống kê vật tư kho',
            columns: result?.columns || [],
          }}
          disableColumnPinning={false}
          initialState={{
            sorting: [{ field: "capacity", sort: "asc" }],
            pinnedColumns: { left: ["__tree_data_group__", "capacity"] },
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          disableColumnSelector = {false}
          hideFooter={false}
        />
      </Paper>

    </LocalizationProvider>
  );
};

export default TableWarehouseItemAccessory;

