import InvestmentApi from "@/apis/beta/Warehouse/InvestmentApi";
import { format_date_short } from "@/functions";
import { format_pricing_vnd } from "@/libs/constants";
import BreadCrumb from "@components/shared/BreadCrumb";
import { Box, Button, CircularProgress, Drawer, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import _ from "lodash";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import TableResourceService from "./share/TableResourceService";
import TableWarehouseItem from "./share/TableWarehouseItem";
import Update from "../Update";
const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const Detail = () => {
    const params = useParams();
    const { data: { investment }, refetch } = InvestmentApi.show(params?.id);

    const [valueTab, setValueTab] = React.useState("resource_services");

    const [openDrawer, setOpenDrawer] = React.useState(false)



    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    return (
        <Box>
            <React.Suspense fallback={<CircularProgress size={16} disableShrink />}>
            {openDrawer &&
                    <Drawer
                        anchor="right"
                        open={openDrawer}
                        sx={{ width: 600 }}
                        onClose={()=> setOpenDrawer(false)}
                    >
                        <Update investment={investment} refetchData={refetch} handleClose={() => setOpenDrawer(false)}  />
                    </Drawer>}
            </React.Suspense>

            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>
                Quản lý Cụm Đầu Tư
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Quản lý Cụm Đầu Tư", reactLink: "/warehouse/investments" },
                    { label: "Chi Tiết  Cụm Đầu Tư " }
                ]}
            />
            <Box>
                <Paper sx={{ mt: 2, p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Box>
                                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                    Tên Cụm Đầu Tư
                                </Typography>
                                <Typography sx={{ color: "#00000F" }}>{investment?.investment_name.toUpperCase()}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Box>
                                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                    Chi Phí Khác
                                </Typography>
                                <Typography sx={{ color: "#00000F" }}>{format_pricing_vnd(investment?.investment_cost_other)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Box>
                                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                    Tổng Đầu Tư
                                </Typography>
                                <Typography sx={{ color: "#00000F" }}>{
                                    format_pricing_vnd(_.sumBy(investment?.warehouse_resource_services, o => o.warehouse_resource_service_price) + investment?.investment_cost_other)
                                }</Typography>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Box>
                                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                    Trình Trạng
                                </Typography>
                                <Typography sx={{ color: "#00000F" }}>
                                    {investment?.investment_status === "active" ? "Hoạt Động" : "Hết Hạn"}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Box>
                                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                    Thời Gian Tạo
                                </Typography>
                                <Typography sx={{ color: "#00000F" }}>{format_date_short(investment?.investment_created_at)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Box>
                                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                    Thời Gian Bắt Đầu
                                </Typography>
                                <Typography sx={{ color: "#00000F" }}>{format_date_short(investment?.investment_start)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Box>
                                <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                    Thời Gian Kết Thúc
                                </Typography>
                                <Typography sx={{ color: "#00000F" }}>{format_date_short(investment?.investment_end)}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='outlined' size='small'
                            disabled={true}
                            sx={{ height: { xs: 36, md: 42 } }}>Hết Hạn Đầu Tư</Button>
                        <Button
                            variant='contained'
                            disabled={openDrawer}
                            onClick={() => setOpenDrawer(true)}
                            size='small' sx={{ ml: 2, height: { xs: 36, md: 42 } }}>Sửa Đầu Tư</Button>
                    </Box>
                </Paper>
                <Box sx={{ mt: 3 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
                            <Tab label={`Tài Nguyên (${investment?.warehouse_resource_services?.length})`} value={"resource_services"} {...a11yProps(0)} />
                            <Tab label={`Thiết Bị (${_.uniq(_.flatten(investment?.warehouse_resource_services.map(o => o.warehouse_resource_service_metadata))).length})`} value={"warehouse_items"} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <React.Suspense fallback={<CircularProgress size={16} disableShrink />}>
                        {
                            (valueTab === "resource_services") ?
                                <TableResourceService refetchData={refetch} investment={investment} />
                                : <TableWarehouseItem ids={_.uniq(_.flatten(investment?.warehouse_resource_services.map(o => o.warehouse_resource_service_metadata)))} />
                        }
                    </React.Suspense>
                </Box>
            </Box>
        </Box>
    )
}
export default withSuspense(withPermission(Detail, {
    feature: "warehouse",
    action: "investment-detail"
}), MuiSkeleton["DataGrid"])