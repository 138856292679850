import AttachmentOutlined from "@mui/icons-material/AttachmentOutlined";
import { Box, Button, Collapse, TextField, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";

const DragDropFile = ({ handler, attachmentAccept }) => {
  const ref = React.useRef();

  const [isDrag, setDrag] = React.useState(false);

  const handleSelect = (files = []) => {
    try {
      handler(files[0]);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDrag(true);
    } else if (e.type === "dragleave") {
      setDrag(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleSelect(e.dataTransfer.files);
    }
  };

  const handleClick = () => {
    if (!ref.current) return console.log("undefined click");
    ref.current?.querySelector("input[type='file']").click();
  };

  useEffect(() => {
    document.addEventListener("dragenter", handleDrag);
    document.addEventListener("dragleave", handleDrag);
    document.addEventListener("dragover", handleDrag);
    document.addEventListener("drop", handleDrop);

    return () => {
      document.removeEventListener("dragenter", handleDrag);
      document.removeEventListener("dragleave", handleDrag);
      document.removeEventListener("dragover", handleDrag);
      document.removeEventListener("drop", handleDrop);
    };
  }, []);

  return (
    <Fragment>
      <TextField
        placeholder="Kéo & Thả tập tin vào đây để tải lên hoặc"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            return handleClick();
          }
        }}
        size="small"
        ref={ref}
        onChange={(e) => handleSelect(e.target.files)}
        InputProps={{
          type: "file",
          sx: {
            pr: 0,
            border: isDrag && "1px solid #009",
          },
          inputProps: !!attachmentAccept
            ? {
                accept: attachmentAccept,
              }
            : {},
          endAdornment: (
            <Button
              variant="contained"
              startIcon={<AttachmentOutlined />}
              size="small"
              disabled={isDrag}
              onClick={handleClick}
              sx={{
                height: "100%",
                boxShadow: "none",
                width: "50%",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                fontSize: 12,
                bgcolor: "#1e237f",
              }}
            >
              Chọn Tập Tin Đính Kèm
            </Button>
          ),
        }}
        sx={{
          cursor: "pointer",
          display: { xs: "none", md: "unset" },
        }}
        fullWidth
      />
      <Collapse in={isDrag}>
        <Box
          sx={{
            minHeight: 80,
            width: "100%",
            bgcolor: "rgba(0,0,0,0.08)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ m: "auto 0" }}>Thả tập tin vào đây...</Typography>
        </Box>
      </Collapse>
    </Fragment>
  );
};

export default DragDropFile;
