import withPermission from '@/hocs/withPermission'
import { Box, Typography } from '@mui/material'
import React from 'react'
import SampleContractForm from '../shared/SampleContractForm'

const Update = ({
    data,
    callback,
    handleClose
}) => {
    return (
        <Box sx={{ p: 3, width: 550, minWidth: 360, height: "100vh" }}>
            <Typography sx={{ textTransform: 'uppercase', mb: 2 }}>Chỉnh Sửa Hợp Đồng Mẫu</Typography>
            <SampleContractForm type="update"
                data={data}
                callback={callback}
                handleClose={handleClose}
            />
        </Box>
    )
}

export default withPermission(Update, { feature: 'contract', action: 'sample-contract-update' })