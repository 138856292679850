import React from "react";
import InvoiceMainTabs from "../InvoiceMainTabs";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <InvoiceMainTabs />
      {children}
    </React.Fragment>
  );
};

export default Layout;
