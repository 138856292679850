import WarehouseBoxLogApi from "@/apis/beta/Warehouse/WarehouseBoxLogApi";
import WarehouseRackApi from "@/apis/beta/Warehouse/WarehouseRackApi";
import {
  WAREHOUSE_RACK_ACTIVE_TYPES,
  WAREHOUSE_RACK_SIZE_U,
} from "@/libs/constants";
import { MenuProps } from "@/styles/common";
import DatePicker from "@components/DatePicker";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import { useEmployeeContext } from "@contexts/employee";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

const FormRack = ({
  handleClose,
  warehouse_box,
  callbackRefetch,
  warehouse_rack,
  type,
}) => {
  const { setNotification } = useNotificationContext();
  const {employee} = useEmployeeContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: type === "update" ? warehouse_rack : {},
  });

  const [rack_active, setRack_active] = React.useState(
    type === "update" ? warehouse_rack?.warehouse_rack_active : true
  );

  const [submit, setSubmit] = React.useState(false);

  const onSubmit = async (data) => {
    setSubmit(true);
    const form = data;
    // form.warehouse_rack_height = form.warehouse_rack_height;
    form.warehouse_rack_name = form.warehouse_rack_name.toUpperCase();
    form.warehouse_box_id = warehouse_box?.warehouse_box_id;
    if (type === "create") {
      await WarehouseRackApi.create(form).then((response) => {
        const data = response.data;
        if (data.status !== 201) {
          setNotification({
            open: true,
            message: data.errors[0].message,
            severity: "error",
          });
          setSubmit(false);
          return;
        }
        const log = {
          warehouse_box_name: warehouse_box.warehouse_box_name,
          warehouse_box_id: warehouse_box.warehouse_box_id,
          warehouse_box_update_type: "CREATERACK-NEW",
          metadata: {warehouse_rack:data?.data.warehouse_rack},
          warehouse_box_updater_id: employee?.employee_id,
          warehouse_box_updater_name: employee?.employee_name,
          warehouse_box_update_description: "Tạo thêm rack cho idc",
        }
        WarehouseBoxLogApi.createBoxLog(log);
        setNotification({
          open: true,
          message: "Tạo tủ rack thành công.",
          severity: "success",
        });
        callbackRefetch();
        handleClose();
      });
    } else {
      if (
        (form.warehouse_rack_active === "false" ||
          !form.warehouse_rack_active) &&
        form.warehouse_rack_description.length < 3
      ) {
        return [
          setNotification({
            open: true,
            message: "Vui lòng điền lý do ở mô tả.",
            severity: "error",
          }),
          setSubmit(false),
        ];
      }
      await WarehouseRackApi.update(
        warehouse_rack.warehouse_rack_id,
        form
      ).then((response) => {
        const data = response.data;
        if (data.status !== 200) {
          setNotification({
            open: true,
            message: data.errors[0].message,
            severity: "error",
          });
          setSubmit(false);
          return;
        }
        console.log(data);
        const log = {
          warehouse_box_name: warehouse_box.warehouse_box_name,
          warehouse_box_id: warehouse_box.warehouse_box_id,
          warehouse_box_update_type: "UPDATERACK-INFO",
          metadata: {
            warehouse_rack:{
              previous: warehouse_rack,
              new: form
            }
          },
          warehouse_box_update_description: "Cập nhật thông tin rack cho idc",
          warehouse_box_updater_id: employee?.employee_id,
          warehouse_box_updater_name: employee?.employee_name,
        }
        WarehouseBoxLogApi.createBoxLog(log);
        setNotification({
          open: true,
          message: "Cập nhật tủ rack thành công.",
          severity: "success",
        });
        callbackRefetch();
        handleClose();
      });
    }
  };

  return (
    <Box sx={{ p: 3, width: 450 }}>
      <Box>
        <Typography
          sx={{ fontSize: { xs: 20, md: 24 }, fontWeight: "medium", mb: 2 }}
        >
          {type === "update" ? "Cập nhật" : "Thêm"} Tủ Rack
        </Typography>
        <Box>
          <Typography sx={{ mb: 0.5, mt: 1 }}> Tên Tủ Rack: *</Typography>
          <Controller
            control={control}
            name="warehouse_rack_name"
            rules={{
              required: "Vui lòng điền tên tủ",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.warehouse_rack_name}
                helperText={errors?.warehouse_rack_name?.message}
                placeholder="Vui lòng nhập tên tủ..."
                InputProps={{ sx: { height: 40, fontSize: 14 } }}
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            )}
          />
        </Box>
        <Box>
          <Typography sx={{ mb: 0.5, mt: 1 }}> Nguồn điện (Kw): *</Typography>
          <Controller
            control={control}
            name="warehouse_rack_power_supply"
            rules={{
              required: "Vui lòng điền nguồn điện",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                error={!!errors?.warehouse_rack_power_supply}
                helperText={errors?.warehouse_rack_power_supply?.message}
                placeholder="Vui lòng điền nguồn điện..."
                InputProps={{ sx: { height: 40, fontSize: 14 } }}
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            )}
          />
        </Box>
        <Box>
          <Typography sx={{ mb: 0.5, mt: 1 }}> Uplink giới hạn rack (Gb/s): *</Typography>
          <Controller
            control={control}
            name="warehouse_rack_uplink_limit"
            rules={{
              required: "Vui lòng điền Uplink tủ",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                error={!!errors?.warehouse_rack_uplink_limit}
                helperText={errors?.warehouse_rack_uplink_limit?.message}
                placeholder="Vui lòng điền uplink tủ..."
                InputProps={{ sx: { height: 40, fontSize: 14 } }}
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            )}
          />
        </Box>
        <Box>
          <Typography sx={{ mb: 0.5, mt: 1 }}> Uplink ký kết với nhà mạng (Gb/s): *</Typography>
          <Controller
            control={control}
            name="warehouse_rack_uplink_commit"
            rules={{
              required: "Vui lòng điền Uplink ký kết với nhà mạng",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                error={!!errors?.warehouse_rack_uplink_commit}
                helperText={errors?.warehouse_rack_uplink_commit?.message}
                placeholder="Vui lòng điền uplink đã ký kết với nhà mạng..."
                InputProps={{ sx: { height: 40, fontSize: 14 } }}
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            )}
          />
        </Box>
        <Box>
          <Typography sx={{ mb: 0.5, mt: 1 }}> Kích Thước (U)*</Typography>
          <Controller
            control={control}
            name="warehouse_rack_height"
            rules={{
              required: "Vui lòng chọn kích thước",
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <Select
                  MenuProps={MenuProps}
                  size="small"
                  value={value}
                  onChange={(event) => {
                    onChange(event?.target?.value);
                  }}
                >
                  {
                  WAREHOUSE_RACK_SIZE_U?.map((item) => {
                    return (
                      <MenuItem
                        sx={{ fontSize: 14, height: 38 }}
                        value={item?.value}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })
                  }
                </Select>
              </FormControl>
            )}
          />
          {!!errors?.warehouse_rack_height && (
            <ErrorHelperText message={errors?.warehouse_rack_height?.message} />
          )}
        </Box>
        {type !== "create" && (
          <>
            <Box>
              <Typography sx={{ mb: 0.5, mt: 1 }}> Kích Hoạt *</Typography>
              <Controller
                control={control}
                name="warehouse_rack_active"
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth>
                    <Select
                      MenuProps={MenuProps}
                      size="small"
                      value={value}
                      onChange={(event) => {
                        setRack_active(event?.target?.value);
                        onChange(event?.target?.value);
                      }}
                    >
                      {WAREHOUSE_RACK_ACTIVE_TYPES?.map((item) => {
                        return (
                          <MenuItem
                            sx={{ fontSize: 14, height: 38 }}
                            value={item?.value}
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </Box>
          </>
        )}
      </Box>
      <Box>
        <Typography sx={{ mb: 0.5, mt: 1 }}>
          {" "}
          Thời gian bắt đầu sử dụng: *
        </Typography>
        <Controller
          name="warehouse_racks_started"
          defaultValue={
            type !== "create"
              ? warehouse_rack?.warehouse_racks_started
              : new Date()
          }
          control={control}
          render={({ field: props }) => (
            <FormControl fullWidth>
              <DatePicker
                fullWidth
                {...props}
                onChange={(newValue) => {
                  props.onChange(new Date(newValue));
                }}
              />
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Typography sx={{ mb: 0.5, mt: 1 }}>
          {" "}
          Thời gian kết thúc rack: *
        </Typography>
        <Controller
          name="warehouse_rack_date_end"
          defaultValue={
            type !== "create"
              ? warehouse_rack?.warehouse_rack_date_end
              : new Date()
          }
          control={control}
          render={({ field: props }) => (
            <FormControl fullWidth>
              <DatePicker
                fullWidth
                {...props}
                onChange={(newValue) => {
                  props.onChange(new Date(newValue));
                }}
              />
            </FormControl>
          )}
        />
      </Box>
      {rack_active === "false" || !rack_active ? (
        <Box>
          <Typography sx={{ mb: 0.5, mt: 1 }}>
            {" "}
            Thời gian trả rack: *
          </Typography>
          <Controller
            name="warehouse_racks_cancelled"
            defaultValue={
              type !== "create"
                ? warehouse_rack?.warehouse_racks_cancelled
                : new Date()
            }
            control={control}
            render={({ field: props }) => (
              <FormControl fullWidth>
                <DatePicker
                  fullWidth
                  {...props}
                  onChange={(newValue) => {
                    props.onChange(new Date(newValue));
                  }}
                />
              </FormControl>
            )}
          />
        </Box>
      ) : null}

      <Box>
        <Typography sx={{ mb: 0.5, mt: 1 }}> Mô tả: </Typography>
        <Controller
          control={control}
          name="warehouse_rack_description"
          defaultValue={""}
          render={({ field }) => (
            <FormControl fullWidth>
              <TextField multiline {...field} placeholder="Nhập mô tả ..." />
            </FormControl>
          )}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          disabled={submit}
          size="small"
          variant="outlined"
          sx={{ height: 36 }}
          onClick={handleClose}
        >
          Huỷ Thao Tác
        </Button>
        <Button
          disabled={submit}
          size="small"
          onClick={handleSubmit(onSubmit)}
          startIcon={
            submit && (
              <CircularProgress
                sx={{ m: "auto 0", color: "#777680" }}
                size={14}
              />
            )
          }
          variant="contained"
          sx={{ ml: 2, height: 36 }}
        >
          {type === "update" ? "Cập nhật" : "Thêm"} Tủ
        </Button>
      </Box>
    </Box>
  );
};

export default FormRack;
