import { format_date_short, format_number_usage, format_numeral_price } from "@/functions"
import { SERVICE_STATUS_TYPES } from "@/libs/constants"
import { CellPricing, CellStatus } from "@components/DataGrid/Cell"
import _ from "lodash"
import Contract from "../shared/Service/ServiceContract"
import ServiceActtions from "../shared/Service/ServiceActions"
import ServiceButtons from "./private/Buttons"

const VNISButtons = [
    'CreateNote',
    'RegisterService',
    'CancelService',
    'CreateInvoiceRenew',
    'CreateInvoiceStatement',
]

const VNISActions = [
    'service-start-date',
    'service-edit',
    'service-date-renew',
    'service-active',
    'service-next-billing',
    'service-current-billing',
    'service-cancel',
    'service-price-amount',
    'service-price-recurring',
    'service-commissions',
    'service-create-invoice-renew',
    'service-information'
]

const buildVNISServices = ({ services = [] }) => {
    return services?.map((service) => {
        return ({
            ...(_.pick(service, [
                'service_id',
                'service_amount',
                'service_status',
                'service_recurring',
                'service_start_date',
                'service_stopped_at',
                'service_referrer_id',
                'service_referrer_type',
                'service_create_is_trial',
                'service_employee_commissions',
                'service_metadata',
                'service_billing_enddate',
                'service_billing_startdate',
                'service_nextbill_enddate',
                'service_nextbill_startdate'
            ])),
            hide: service?.service_amount <= 0,
            service_name: service?.service_product_name?.toUpperCase(),
        })
    })
}
const buildVNISService = ({ service = {} }) => {
    return {
        ...(_.pick(service, [
            'service_id',
            'service_amount',
            'service_status',
            'service_recurring',
            'service_start_date',
            'service_stopped_at',
            'service_referrer_id',
            'service_referrer_type',
            'service_create_is_trial',
            'service_employee_commissions',
            'service_metadata',
            'service_billing_enddate',
            'service_billing_startdate',
            'service_nextbill_enddate',
            'service_nextbill_startdate',
        ])),
        hide: service?.service_product_id === 131,
        service_name: service?.service_product_name?.toUpperCase(),
    }
}
const buildVNISServiceFinnaly = ({ addons, service }) => {
    return {
        ...(_.pick(service, [
            'service_status',
            'service_start_date',
            'service_stopped_at',
            'service_employee_commissions',
            'service_id',
            'service_referrer_id',
            'service_referrer_type',
            'service_create_is_trial',
            'service_billing_enddate',
            'service_billing_startdate',
            'service_nextbill_enddate',
            'service_nextbill_startdate',
        ])),
        classNames: 'row-service-finally',
        service_name: 'Cấu Hình Hiện Tại',
        service_metadata: {
            domains: service?.service_metadata?.domains + (_.sumBy(addons, (addon) => addon?.service_metadata?.domains)),
            dns_requests: service?.service_metadata?.dns_requests + (_.sumBy(addons, (addon) => addon?.service_metadata?.dns_requests)),
            origin_shield_requests: service?.service_metadata?.origin_shield_requests + (_.sumBy(addons, (addon) => addon?.service_metadata?.origin_shield_requests)),
            origin_shield_traffics: service?.service_metadata?.origin_shield_traffics + (_.sumBy(addons, (addon) => addon?.service_metadata?.origin_shield_traffics)),
        },
        service_amount: service?.service_amount + (_.sumBy(addons, (addon) => addon?.service_amount)),
        service_recurring: service?.service_recurring + (_.sumBy(addons, (addon) => addon?.service_recurring)),
    }

}

const clusterRows = ({ services = [], service = {} }) => {
    return [
        buildVNISService({ service }),
        ...buildVNISServices({ services }),
        buildVNISServiceFinnaly({ addons: services, service }),
    ]
}

const INITIAL_COLUMNS = [
    {
        field: "id",
        headerName: ".",
        width: 60,
    },
    {
        field: "service_name",
        headerName: "Sản Phẩm",
        width: 300,
    },
    {
        field: "service_metadata.domains",
        headerName: "Domains",
        width: 100,
        valueGetter: ({ row }) => row?.service_metadata?.domains,
        renderCell: ({ value }) => !!value && format_number_usage(value)
    },
    {
        field: "service_metadata.origin_shield_traffics",
        headerName: "Origin Shield Traffics",
        width: 155,
        valueGetter: ({ row }) => row?.service_metadata?.origin_shield_traffics,
        renderCell: ({ value }) => !!value && format_number_usage(value)
    },
    {
        field: "service_metadata.origin_shield_requests",
        headerName: "Origin Shield Requests",
        width: 170,
        valueGetter: ({ row }) => row?.service_metadata?.origin_shield_requests,
        renderCell: ({ value }) => !!value && format_number_usage(value)
    },
    {
        field: "service_metadata.dns_requests",
        headerName: "DNS Requests",
        width: 120,
        valueGetter: ({ row }) => row?.service_metadata?.dns_requests,
        renderCell: ({ value }) => !!value && format_numeral_price(value)
    },
    {
        field: "sale_employee_ids",
        headerName: "Người Hưởng Hoa Hồng",
        width: 220,
        valueGetter: ({ row }) =>
            row?.service_employee_commissions
                ?.map((e) => e?.employee_name)
                ?.join(", "),
    },
    {
        field: "service_amount",
        width: 200,
        headerName: "Đơn Giá Bán (Theo tháng *) ",
        renderCell: ({ value }) => value && <CellPricing price={value} />,
    },
    {
        field: "service_recurring",
        width: 200,
        headerName: "Đơn Giá Gia Hạn (Theo tháng *) ",
        renderCell: ({ value }) => value && <CellPricing price={value} />,
    },
    {
        field: "services.service_status",
        headerName: "Trạng Thái",
        width: 140,
        valueGetter: ({ row }) =>
            SERVICE_STATUS_TYPES?.find((stt) => stt?.value === row?.service_status),
        renderCell: ({ value }) => {
            return <CellStatus component="muiChip" data={value} />;
        },
    },
    {
        field: "service_start_date",
        headerName: "Ngày Bắt Đầu Sử Dụng DV",
        width: 200,
        valueFormatter: ({ value }) => !!value ? format_date_short(value) : '',
    },
    {
        field: "service_stopped_at",
        headerName: "Ngày Kết Thúc DV",
        width: 200,
        valueFormatter: ({ value }) => !!value ? format_date_short(value) : '',
    },
    {
        field: "service_billing_startdate",
        headerName: "Chu Kỳ Thanh Toán Hiện Tại",
        width: 200,
        valueGetter: ({ row }) => {
            if (row?.service_create_is_trial) return `TRIAL`;
            if (!row?.service_billing_startdate || !row?.service_billing_enddate)
                return ``;
            return `(${format_date_short(
                row?.service_billing_startdate
            )} - ${format_date_short(row?.service_billing_enddate)})`;
        },
    }, {
        field: "service_nextbill_startdate",
        width: 220,
        headerName: "Chu Kỳ Gia Hạn",
        valueGetter: ({ row }) => {
            if (row?.service_create_is_trial) return `TRIAL`;
            if (!row?.service_nextbill_startdate || !row?.service_nextbill_enddate)
                return ``;
            return `(${format_date_short(
                row?.service_nextbill_startdate
            )} - ${format_date_short(row?.service_nextbill_enddate)})`;
        },
    }, {
        field: "service_contract_name",
        headerName: "Hợp Đồng Hiện Tại",
        minWidth: 350,
        valueGetter: ({ row }) => {
            return {
                ui: row?.service_referrer_type === 'Reducer::Override::Service' ? "SubContract" : 'Contract',
                service_id: row?.service_id,
                service_referrer_id: row?.service_referrer_id
            }
        },
        renderCell: ({ value }) => {
            const ContractUI = Contract[value?.ui]
            if (!ContractUI) return null
            return <ContractUI {...value} />
        }
    }, {
        field: "actions",
        headerName: "",
        width: 40,
        renderCell: ({ row }) => <ServiceActtions service={row} actions={[...VNISActions]} />
    },
]

const VNISLibs = {
    columns: INITIAL_COLUMNS,
    rows: clusterRows,
    activities: VNISButtons,
    actions: VNISActions,
    module: {
        Component: ServiceButtons,
        actions: ['ServiceCreateAddon']
    }
}
export default VNISLibs