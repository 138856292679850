import ReportWatchApi from '@/apis/beta/Threshold/Report/ReportWatchApi';
import DataGridFilter from "@/components/DataGrid/Filter";
import useFilters, { Filter } from "@/hooks/useFilters";
import ItemMenuOptions from '@components/TechnicalReportService/itemMenuOptions';
import { AddOutlined } from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Button, Chip, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useLocation } from "react-router-dom";
import DialogAddReport from './Dialog/DialogAddReport';
import DialogEditReportData from './Dialog/DialogEditReportData';
import DialogHistoryReport from './Dialog/DialogHistoryReport';
//new
import DataGrid from "@/components/DataGrid";

import {
  format_date_short,
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useMutationWithNotification from '@/hooks/useMutationWithNotification';
import TaskStatus from '@components/TechnicalReportService/taskstatus';
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import DialogConfirmChangeStatus from './Dialog/DialogConfirmChangeStatus';
import DialogDateTimePicker from './Dialog/DialogDatePicker';
import DialogResendReport from './Dialog/DialogResendReport';

//
const Report = () => {
  const location = useLocation();
  const [openAddReportDialog, setOpenAddReportDialog] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  //filter
  const [query, updateQuery] = useFilters("watches.filters", location);
  const [searchQuery, setSearchQuery] = useState("");
  // const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [state, setState] = useState({
    action: undefined,
    payload: undefined
  })
  const onClose = () => setState({ action: undefined, payload: undefined })

  const { setNotification } = useNotificationContext();
  const [idEdit, setIdEdit] = useState(null);


  const [dataCustomer, setDataCustomer] = useState(
    {
      mlytics_id: "",
      source_domains: "",
      owners: [],
      domains: []
    }
  );
  const historyColumsHidden =
    JSON.parse(localStorage.getItem("watches.hiddenColumns")) || [];

  const {
    isFetching,
    data,
    refetch: callbackReports,
  } = ReportWatchApi.List({
    page: pageNumber + 1,
    pageSize: pageSize,
    query: (query?.$and || query?.$or) ? Filter.stringify(query) : null,
    searchQuery: searchQuery
    // filterableReport() !== "" ? filterableReport() : null,
  });
  const {
    notify,
  } = useMutationWithNotification();

  const initialColumns = [
    {
      field: "watch_id",
      headerName: "ID",
      width: 60,
      valueGetter: ({ row }) => row?.watch_id,
    },
    {
      field: "watch_name",
      headerName: "Tên khách hàng",
      width: 200,
      operators: { type: "string" },
      valueGetter: ({ row }) => row?.watch_name,
    },
    {
      field: "watch_referrer_type",
      headerName: "Loại Khách hàng",
      width: 175,
      operators: { type: "string" },
      valueGetter: ({ row }) => row?.watch_referrer_type,
    },
    {
      field: "watches.watch_interval",
      headerName: "Tần suất gửi",
      width: 100,
      valueGetter: ({ row }) => row?.watch_interval,
      operators: { type: "string" },
    },
    {
      field: "watches.watch_scope",
      headerName: "Hình thức gửi",
      width: 150,
      valueGetter: ({ row }) => row?.watch_scope,
      operators: { type: "string" },
    },
    {
      field: "watches.watch_created_at",
      headerName: "Ngày đăng ký dịch vụ",
      width: 200,
      operators: {
        type: "date",
      },
      valueGetter: ({ row }) => row?.watch_created_at,
      renderCell: ({ row, value }) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ fontSize: 14, m: "auto 0" }}
          >
            {format_date_short(value)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "watches.watch_status",
      headerName: "Trạng thái",
      width: 150,
      // hideable: true,
      valueGetter: ({ row }) =>
        row.watch_status,
      renderCell: ({ value }) =>
        <Chip size="small" label={value} color={value === "active" ? "success" : "error"} variant='outlined' />,
      operators: {
        type: "string",
      },
    },
    {
      field: "watches.report_date",
      headerName: "Ngày gửi gần nhất",
      width: 150,
      valueGetter: ({ row }) =>
        row?.report_date,
      renderCell: ({ value }) =>
        value
    },
    {
      field: "watches.report_status",
      headerName: "Trạng thái gửi",
      width: 150,
      valueGetter: ({ row }) =>
        row.report_status,
      renderCell: ({ value }) =>
        value
    },
    {
      field: "watches.report_file_status",
      headerName: "Trạng thái file",
      width: 150,
      valueGetter: ({ row }) =>
        row?.report_file_status,
      renderCell: ({ value }) =>
        value
    },
    {
      field: "action",
      headerName: "Tùy chỉnh",
      width: 100,
      hideable: false,
      valueGetter: ({ row }) =>
        row.action,
      renderCell: ({ value }) =>
        value
    }
  ];
  const [columns, setColumns] = React.useState(
    initialColumns?.map((column) => {
      if (historyColumsHidden?.includes(column?.field)) {
        return { ...column, hide: true };
      }
      return { ...column };
    }) || initialColumns
  );
  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });

  const handleOpenHistoryDialog = (watch_id, watch_name, watch_uuid, watch_interval) => {
    ReportWatchApi.GetReportHistory(watch_uuid).then((res) => {
      if (res?.data?.tasks) {
        setState(preState => ({
          ...preState,
          payload: {
            ...preState?.payload,
            tasks: res?.data?.tasks
          }
        }));
      }
    })
    return setState(preState => ({
      action: "histories",
      payload: {
        tasks: preState?.payload?.tasks || [{ task_completed_at: "" }],
        watch_uuid,
        watch_interval,
        watch_name
      }
    }));
  }
  const handleSetDataDialog = useCallback(async (uuid) => {
    await ReportWatchApi.GetReport(uuid).then((response) => {
      if (!response?.data.report) {
        return setNotification({
          open: true,
          message: response?.errors?.[0]?.message || "Load thông tin thất bại.",
          severity: "error",
        });
      }
      setDataCustomer(response?.data?.report?.metadata);
      setIdEdit(uuid);
    });
    return setState({ action: "edit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddMail = useCallback((mail) => {
    if (mail.replaceAll(" ", "") === "") return;
    if (dataCustomer.owners.includes(mail)) {
      return;
    }
    setDataCustomer((prev) => ({ ...prev, owners: [...prev.owners, mail] }));
  }, [dataCustomer.owners]);
  const handleAddDomain = useCallback((domain) => {
    if (domain.replaceAll(" ", "") === "") return;
    if (dataCustomer.domains.includes(domain)) return;

    setDataCustomer((prev) => ({ ...prev, domains: [...prev.domains, domain] }));
  }, [dataCustomer.domains]);
  const handleRemoveMail = useCallback((mailIndex) => {
    setDataCustomer((prev) => ({ ...prev, owners: prev.owners.filter((item, i) => i !== mailIndex) }));
  }, []);
  const handleRemoveDomain = useCallback((domainIndex) => {
    setDataCustomer((prev) => ({ ...prev, domains: prev.domains.filter((item, i) => i !== domainIndex) }));
  }, []);
  const handleOnchangeId = useCallback((value) => {
    setDataCustomer((prev) => ({ ...prev, mlytics_id: value }));
  }, [])
  const handleOnchangeSource_domains = useCallback((value) => {
    setDataCustomer((prev) => ({ ...prev, source_domains: value }));
  }, [])
  const handleExportReport = async (url, fileName) => {
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = `${fileName}`;
    await fetch(url)
      .then(response => {
        if (response.status !== 200) throw new Error('File not found');
        return response.blob()
      })
      .then(blob => {
        const url = URL.createObjectURL(blob);
        anchorElement.href = url;
        anchorElement.dispatchEvent(new MouseEvent('click'));
      }).catch(err => notify("error", err?.message));
  }
  const handOpenAddReport = () => {
    setOpenAddReportDialog(true);
  }
  const handleCloseAddReport = () => {
    setOpenAddReportDialog(false);
  }

  const handleChangePageSize = (pageSize) => {
    setPageSize(pageSize);
  }
  const handleChangePageNumber = (number) => {
    setPageNumber(number);
  }
  data?.reportList?.sort((a, b) => {
    return a?.watch_id - b?.watch_id;
  })

  const handleAcceptChangeStatus = async () => {
    const { watch_id, watch_status } = state?.payload

    if (!watch_status || !watch_id) {
      return setNotification({ open: true, message: "Không tìm thấy dữ liệu để thao tác", severity: 'error' })
    }
    const nextStatus = watch_status === "active" ? "inactive" : "active"
    const message = nextStatus === "active" ? "Đăng ký" : "Huỷ đăng ký"
    await ReportWatchApi.UpdateReport(watch_id, {
      report: {
        watch_status: nextStatus
      }
    })
      .then((res) => {
        if (res?.data?.report) {
          if (callbackReports !== undefined) {
            callbackReports();
          }
          return setNotification({
            open: true,
            message: `${message} báo cáo thành công`,
            severity: "success",
          })
        }
        return setNotification({
          open: true,
          message: res?.errors?.[0]?.message || `Thực hiện ${message} thất bại`,
          severity: "error",
        })
      })
      .catch((error) => {
        return setNotification({
          open: true,
          message: error?.response?.data?.errors[0]?.message || `Thực hiện ${message} thất bại`,
          severity: "error",
        })
      })
    return onClose()
  }

  const handleAcceptResendReport = () => {
    setNotification({
      open: true,
      message: `Đã gửi báo cho ${state?.payload?.watch_name} thành công`,
      severity: "success",
    })
    onClose();
  }

  const mountComponent = () => {
    const params = {
      refetch: callbackReports,
      handleAddMail: handleAddMail,
      handleAddDomain: handleAddDomain,
      handleRemoveMail: handleRemoveMail,
      handleRemoveDomain: handleRemoveDomain,
      handleOnchangeId: handleOnchangeId,
      handleOnchangeSource_domains: handleOnchangeSource_domains,
      handleClose: onClose,
      idEdit: idEdit,
      open: true,
      dataCustomer: dataCustomer,
      setNotification: setNotification,
      handleExportReport: handleExportReport,
      handleAcceptResend: handleAcceptResendReport,
      handleAcceptChangeStatus: handleAcceptChangeStatus,
      ...state?.payload
    }


    switch (state?.action) {
      case "edit": return <DialogEditReportData {...params} />;
      case "histories": return <DialogHistoryReport {...params} />;
      case "view": return <DialogDateTimePicker {...params} />
      case "resend": return <DialogResendReport {...params} />;
      case "change-status": return <DialogConfirmChangeStatus {...params} />;
      default: return null
    }
  }
  return (
    <Box sx={{ width: "100%", height: "100vh", display: "block", mb: 2 }}>
      {mountComponent()}
      <Box
        sx={{
          mb: 2,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 1.5,
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: "medium",
            fontSize: { xs: 18, md: 24 },
          }}
        >
          Quản Lý Báo Cáo (REPORT WAF)
        </Typography>
        {/* <Link to="/technical/report" replace> */}
        <Button
          size="small"
          variant="outlined"
          sx={{
            minWidth: { xs: "100%", md: 150 },
            height: { xs: 36, md: 42 },
          }}
          startIcon={<AddOutlined fontSize="small" />}
          onClick={() => handOpenAddReport()}
        >
          Thêm Báo Cáo
        </Button>
        {/* </Link> */}
      </Box>
      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "watches.filters",
          filterables: filterables,
        }}
      />
      <DataGrid
        rows={
          data?.reportList?.map((watch) => {
            const options = {
              name: "Tùy chọn",
              children: [
                {
                  iconElement: <ReplayIcon />,
                  tittle: "Gửi lại báo cáo",
                  function: () => setState({
                    action: 'resend',
                    payload: {
                      watch_id: watch?.watch_uuid,
                      uuid: watch?.watch_uuid,
                      watch_name: watch?.watch_name,
                      watch_bind: watch?.watch_bind,
                    }
                  })
                },
                {
                  iconElement: watch.watch_status === 'active' ? <NotificationsOffIcon /> : <NotificationsActiveIcon />,
                  tittle: watch.watch_status === 'active' ? "Hủy đăng ký báo cáo" : "Đăng ký báo cáo",
                  function: () => setState({
                    action: 'change-status',
                    payload: {
                      watch_id: watch?.watch_uuid,
                      uuid: watch?.watch_uuid,
                      watch_status: watch?.watch_status,
                      watch_name: watch?.watch_name,
                    }
                  })
                },
                {
                  iconElement: <HistoryToggleOffIcon />,
                  tittle: "Lịch sử báo cáo",
                  function: () => handleOpenHistoryDialog(watch?.watch_id, watch?.watch_name, watch?.watch_uuid, watch?.watch_interval)
                },
                {
                  iconElement: <AssessmentIcon />,
                  tittle: "Xem báo cáo",
                  function: () => setState({
                    action: 'view',
                    payload: {
                      watch_uuid: watch?.watch_uuid,
                      uuid: watch?.watch_uuid,
                      watch_name: watch?.watch_name,
                    }
                  })
                },
                {
                  iconElement: <ContactEmergencyIcon />,
                  tittle: "Sửa thông tin",
                  function: () => handleSetDataDialog(watch?.watch_uuid)
                }
              ]
            }
            const taskStatusElement = <TaskStatus uuid={watch?.watch_uuid} query="status" />
            const taskDateElement = <TaskStatus uuid={watch?.watch_uuid} query="date" />
            const taskFileElement = <TaskStatus uuid={watch?.watch_uuid} query="checkFile" />
            return {
              ...watch,
              id: watch?.watch_id,
              report_date: taskDateElement || "None",
              report_status: taskStatusElement || "None",
              report_file_status: taskFileElement || "None",
              action:
                <ItemMenuOptions options={options} />
            }
          }) || []
        }
        columns={columns}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            store: "watches.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "report",
            inputSearch: {
              placeholder:
                "Nhập tên khách hàng, ...",
              value: searchQuery,
              onChange: setSearchQuery
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: pageNumber || 0,
            pageSize: pageSize || 10,
            onPageChange: (newPage) =>
              handleChangePageNumber(newPage),
            onPageSizeChange: (newPageSize) =>
              handleChangePageSize(newPageSize)
          },
        }}
        rowCount={data?.pagination?.total}
      />
      <DialogAddReport
        refetch={callbackReports}
        open={openAddReportDialog}
        handleClose={handleCloseAddReport}
      />
    </Box>
  );
}

// export default Report;
export default withSuspense(
  withPermission(Report, { feature: "technical", action: "reports" }),
  MuiSkeleton["DataGrid"]
);

