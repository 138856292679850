import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

const ConfirmDialog = ({
  container,
  component: Component,
  cancelMessage = "Để Sau",
  acceptMessage = "Đồng Ý",
  closeMessage = "Đóng",
  loading = false,
  validated = true,
  children,
  disabledButtonComfirm = false,
  ...props
}) => {
  return (
    <Dialog
      open={true}
      scroll="paper"
      onClose={props.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={props.size}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      {Component ? (
        <Component
          cancelMessage={cancelMessage}
          acceptMessage={acceptMessage}
          closeMessage={closeMessage}
          loading={loading}
          validated={validated}
          {...props}
        />
      ) : container ? (
        <React.Fragment>{container}</React.Fragment>
      ) : (
        <React.Fragment>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="span" sx={{ fontSize: 13 }}>
                {props.description}
              </Typography>
            </DialogContentText>
          </DialogContent>

          {children}

          {props.callback && validated ? (
            <DialogActions>
              <Button onClick={props.close} color="primary">
                {cancelMessage}
              </Button>
              <Button
                onClick={props.callback}
                variant="contained"
                color="primary"
                disabled={loading || disabledButtonComfirm}
              >
                {loading && <CircularProgress size={20} className="mr-2" />}
                {acceptMessage}
              </Button>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={props.close} color="primary">
                {closeMessage}
              </Button>
            </DialogActions>
          )}
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
