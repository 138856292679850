import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";


class RackBandwidthItemApi {
  async create({ rack_bandwidth_id, rack_bandwidth_items }) {
    const response = await requestWithToken(
      `${API_WAREHOUSE_V1_URL}/rack_bandwidth_items`,
      "POST",
      {
        rack_bandwidth: {
          rack_bandwidth_id: rack_bandwidth_id,
          rack_bandwidth_items: rack_bandwidth_items
        }
      }
    );
    return response;
  }
  async update({ rack_bandwidth_id, rack_bandwidth_items }) {
    const response = await requestWithToken(
      `${API_WAREHOUSE_V1_URL}/rack_bandwidth_items`,
      "PUT",
      {
        rack_bandwidth: {
          rack_bandwidth_id: rack_bandwidth_id,
          rack_bandwidth_items: rack_bandwidth_items
        }
      }
    );
    return response;
  }
  getALLGrow = () =>
    useQueryWithCustomized(
      ["rack_bandwidth_items.all.grow"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/rack_bandwidth_items/get_grow`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.rack_bandwidth_items
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );
  GetRacksByYear = ({ year }) =>
    useQueryWithCustomized(
      ["rack_bandwidth_items.all.year",
        year
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/rack_bandwidth_items/get_racks_by_year?year=${year}`,
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_racks
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );
}
export default RackBandwidthItemApi = new RackBandwidthItemApi();
