import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";
import { encodeBase64, requestWithToken } from "@/functions";


class DatacenterBandwidthApi {
    index = ({
        query = null,
        searchQuery = null,
        page = 1,
        pageSize = 20,
    }) =>
        useQueryWithCustomized(
            ["warehouse.datacenter_bandwidths.list", query, searchQuery, page, pageSize],
            async () => {
                return await requestWithToken(`${API_WAREHOUSE_V1_URL}/datacenter_bandwidths?${query ? `&filters=${encodeBase64(query)}` : ''}${searchQuery ? `&queries=${(searchQuery)}` : ''}&page=${page}&page_size=${pageSize}`)
                    .then(({ data }) => {
                        if (data?.error) return data;

                        return {
                            datacenter_bandwidths: data?.data?.datacenter_bandwidths.map(o => ({
                                ...o,
                                id: o.datacenter_bandwidth_id,

                            })
                            ),
                            pagination: data?.data?.pagination
                        };
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );

    create = (params) => requestWithToken(`${API_WAREHOUSE_V1_URL}/datacenter_bandwidths`, 'POST', { datacenter_bandwidth: params });
    update = (id, params) => requestWithToken(`${API_WAREHOUSE_V1_URL}/datacenter_bandwidths/${id}`, 'PUT', { datacenter_bandwidth: params });
    show = (id, callback) =>
        useQueryWithCustomized(
            ['warehouse.datacenter_bandwidths.detail', id],
            async () => {
                return await requestWithToken(`${API_WAREHOUSE_V1_URL}/datacenter_bandwidths/${id}`)
                    .then(({ data }) => {
                        if (data?.errors) return callback(data.errors);

                        return {
                            datacenter_bandwidth: data?.data?.datacenter_bandwidth,
                        };
                    })
                    .catch((error) => {
                        return {
                            datacenter_bandwidth: null,
                        };
                    });
            },
            {
                staleTime: 10 * 60 * 1000,
                notifyOnChangePropsExclusions: ['isStale'],
                suspense: true,
            }
        );
}
export default DatacenterBandwidthApi = new DatacenterBandwidthApi();
