import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const CustomerNote = () => {
  const { control } = useFormContext();
  return (
    <Grid container spacing={2}>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
        <Typography sx={{ fontSize: 14, color: "#777680" }}>Ghi chú</Typography>
      </Grid>
      <Grid item xl={9} lg={9} md={8} sm={6} xs={12}>
        <Controller
          control={control}
          name="customer_note"
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl fullWidth>
                <TextField
                  multiline
                  minRows={3}
                  size="small"
                  value={value}
                  onChange={onChange}
                  placeholder="Thông tin ghi chú khách hàng..."
                />
              </FormControl>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CustomerNote;
