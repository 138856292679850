import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import { getDataGridRowHeight } from "@/functions";
import useDebounce from "@/hooks/useDebounce";
import { Filter } from "@/hooks/useFilters";
import DataGridCustomize from "@components/DataGrid";
import Cell from "@components/DataGrid/Cell";
import CellConfig from "@components/DataGrid/CellConfig2";
import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import _ from "lodash";

const WarehouseDeviceTable = ({
  handleClose,
  setResourceItems,
  idsExist = [],
}) => {
  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const query = {
    $and: [
      { "warehouse_items.warehouse_item_form_factor": { $gt: 0 } },
      {
        "warehouse_box_items.warehouse_box_item_reference_type": {
          $eq: "WarehouseRack",
        },
      },
    ],
  };

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1500);
  const filterableSearch = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    return debouncedSearchQuery.trim();
  }, [debouncedSearchQuery]);

  const { data, isFetching } = WarehouseItemApi.Index({
    page: page?.page + 1 || 1,
    pageSize: page?.pageSize || 20,
    query: Filter.stringify(query),
    searchQuery: filterableSearch() !== "" ? filterableSearch() : null,
  });

  const { data: warehouse_boxes } = WarehouseBoxApi.getAll();

  const columns = [
    {
      field: "warehouse_item_name",
      headerName: "Tên  thiết bị",
      width: 250,
      renderCell: ({ row }) => (
        <Box>
          <Typography sx={{ fontSize: 14 }}>
            {row.warehouse_item_name}
          </Typography>
          {idsExist?.includes(row.id) && (
            <Typography sx={{ fontSize: 12 }} color="error">
              Đã chọn
            </Typography>
          )}
        </Box>
      ),
    },
    {
      field: "warehouse_item_service_tag",
      headerName: "Service Tag / Serial Number",
      width: 250,
    },
    ,
    {
      field: "capacity",
      headerName: "Capacity",
      width: 250,
      sortable: true,
      renderCell: ({ value }) => value && <CellConfig configures={value} />,
    },
    {
      field: "warehouse_box_name",
      headerName: "Kho / Datacenter",
      valueGetter: ({ row }) => {
        if (
          row.warehouse_box_item &&
          row.warehouse_box_item.warehouse_box_item_reference_type ===
            "WarehouseBox"
        ) {
          const box = _.find(warehouse_boxes, {
            warehouse_box_id:
              row.warehouse_box_item.warehouse_box_item_reference_id,
          });
          return box;
        } else if (
          row.warehouse_box_item &&
          row.warehouse_box_item.warehouse_box_item_reference_type ===
            "WarehouseRack" &&
          row.warehouse_rack
        ) {
          const box = _.find(warehouse_boxes, {
            warehouse_box_id: row.warehouse_rack?.warehouse_box_id,
          });
          return box;
        }
        return "";
      },
      renderCell: ({ value, row }) => (
        <>
          {value ? (
            value.warehouse_box_type === 0 ? (
              <>{value.warehouse_box_name}</>
            ) : (
              <Cell content={value.warehouse_box_name.toUpperCase()} />
            )
          ) : (
            ""
          )}
        </>
      ),
      width: 250,
    },
    {
      field: "warehouse_box_rack",
      headerName: "Tủ Rack",
      valueGetter: ({ row }) => {
        if (
          row.warehouse_box_item &&
          row.warehouse_box_item.warehouse_box_item_reference_type ===
            "WarehouseRack" &&
          row.warehouse_rack
        ) {
          return row.warehouse_rack;
        }
        return null;
      },
      renderCell: ({ value, row }) => (
        <>
          {value ? (
            <Cell content={value.warehouse_rack_name.toUpperCase()} />
          ) : (
            ""
          )}
        </>
      ),
      width: 200,
    },
    {
      field: "warehouse_box_position",
      headerName: "Vị Trí",
      valueGetter: ({ row }) => {
        if (
          row.warehouse_box_item &&
          row.warehouse_box_item.warehouse_box_item_reference_type ===
            "WarehouseRack" &&
          row.warehouse_rack
        ) {
          return (
            row.warehouse_box_item?.warehouse_box_item_position +
            (row.warehouse_item_form_factor === 0 ||
            row.warehouse_item_form_factor === 1
              ? ""
              : ` - ${
                  row.warehouse_box_item?.warehouse_box_item_position +
                  (row.warehouse_item_form_factor - 1)
                }`)
          );
        }
        return "";
      },
      width: 200,
    },
    {
      field: "resource",
      headerName: "Loại Tài Nguyên",
      valueGetter: ({ row }) => {
        if (
          row.warehouse_box_item &&
          row.warehouse_box_item.warehouse_box_item_reference_type ===
            "WarehouseRack" &&
          row.warehouse_rack
        ) {
          return row.warehouse_box_item.warehouse_resources
            .map((o) => o.warehouse_resource_type)
            .join(" ,");
        }
        return "Không có";
      },
      renderCell: ({ value }) => <Cell content={value.toUpperCase()} />,
      width: 150,
    },
    {
      field: "services",
      headerName: "Dịch Vụ",
      valueGetter: ({ row }) => {
        return row.warehouse_resource_services
          .map((o) => o.warehouse_resource_service_name)
          .join(" , ");
      },
      renderCell: ({ value }) => <Cell content={value.toUpperCase()} />,
      width: 150,
    },
    {
      field: "warehouse_box_item_service_deploy",
      headerName: "Dịch Vụ Deploy",
      valueGetter: ({ row }) =>
        row?.warehouse_box_item?.warehouse_box_item_service_deploy,
      renderCell: ({ value }) => <Cell content={value.toUpperCase()} />,
      width: 150,
    },
  ];

  const [deviceSelecteds, setDeviceSelected] = React.useState([]);

  const onSubmit = () => {
    setResourceItems((prev) => [...prev, ...deviceSelecteds]);
    handleClose();
  };

  return (
    <Box>
      <Paper sx={{ p: 1 }}>
        <DataGridCustomize
          checkboxSelection
          rows={
            data?.warehouse_items.map((item) => ({
              ...item,
              id: item?.warehouse_box_item?.warehouse_box_item_id,
            })) || []
          }
          columns={columns}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRows = data.warehouse_items.filter((row) =>
              selectedIDs.has(row?.warehouse_box_item?.warehouse_box_item_id)
            );
            setDeviceSelected(selectedRows);
          }}
          isRowSelectable={({ row }) =>
            row?.warehouse_box_item?.warehouse_resources.length > 0
              ? false
              : !idsExist?.includes(row?.id)
          }
          loading={isFetching}
          getRowHeight={(params) =>
            getDataGridRowHeight(
              [params?.model?.children, params?.model?.warehouse_model?.slots],
              params?.model?.capacity
            )
          }
          componentsProps={{
            toolbar: {
              feature: "warehouse-items",
              inputSearch: {
                placeholder:
                  "Nhập Service Tag / Serial Number, tên thiết bị, IDC,Rack tìm kiếm...",
                value: searchQuery,
                onChange: setSearchQuery,
              },
            },
            pagination: {
              page: page?.page || 0,
              pageSize: page?.pageSize || 20,
              rowsPerPageOptions: [10, 20, 50, 100],
              onPageChange: (newPage) => {
                setPage((prevState) => ({ ...prevState, page: newPage + 1 }));
              },
              onPageSizeChange: (newPageSize) =>
                setPage((prevState) => ({
                  ...prevState,
                  pageSize: newPageSize,
                  page: 1,
                })),
            },
          }}
          rowCount={data.pagination ? data?.pagination?.total : 0}
        />
        <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            variant="text"
            onClick={handleClose}
            sx={{ height: { xs: 36, md: 42 } }}
          >
            Huỷ Thao Tác
          </Button>

          <Button
            size="small"
            variant="outlined"
            onClick={onSubmit}
            disabled={deviceSelecteds?.length === 0}
            sx={{ height: { xs: 36, md: 42 }, ml: 2 }}
          >
            Thêm Thiết Bị
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};
export default WarehouseDeviceTable;
