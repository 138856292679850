import {
    TICKET_URGENCY
} from "@/libs/constants";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
const TicketUrgency = () => {
    const { control, formState: { errors } } = useFormContext()
    return (
        <Controller
            name="ticket_urgency"
            rules={{ required: "This field is required" }
            }
            control={control}
            render={({ field: { onChange, value } }) => {
                const selected =
                    TICKET_URGENCY.find((x) => x?.value === value) || null;

                return (
                    <FormControl fullWidth>
                        <SingleSelectCustomize
                            input={{
                                label: 'Ticket Urgency *',
                                placeholder: 'Chọn độ ưu tiên...'
                            }}
                            onChange={(_, newValue) => {
                                onChange(newValue?.value || null);
                            }}
                            value={selected}
                            options={TICKET_URGENCY}
                        />

                        {errors?.ticket_urgency && (
                            <ErrorHelperText
                                message={errors?.ticket_urgency?.message}
                            />
                        )}
                    </FormControl>
                );
            }}
        />
    )
}

export default TicketUrgency