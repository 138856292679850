import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";

const ItemExportMuiScrollTabs = ({ initialTabs = [], activeTab, setActiveTab}) => {
    const handleChangeActiveTab = (value) => {
      setActiveTab(value);
    }
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", mb: { xs: 2, md: 3 } }}>
      <Tabs
        component={"nav"}
        value={activeTab}
        aria-label="tabs-with-scroll"
        variant="scrollable"
        allowScrollButtonsMobile
        scrollButtons
      >
        {initialTabs?.map((tab, index) => (
          <Tab
            key={index}
            value={tab?.value}
            disabled={activeTab === tab?.value}
            onClick = {() => handleChangeActiveTab(tab?.value)}
            label={
                <Box sx={{ display: "flex" }}>
                  {tab?.icon &&
                    React.createElement(tab?.icon, {
                      sx: {
                        fontSize: { xs: 18, md: 20 },
                        m: "auto 4px auto 0",
                        color: activeTab === tab?.value && "#1a237e",
                      },
                    })}
                  <Typography
                    sx={{
                      fontWeight: "medium",
                      fontSize: { xs: 14, md: 16 },
                    }}
                    color={activeTab === tab?.value ? "primary" : "information"}
                  >
                    {tab?.label || "Tab name"}
                  </Typography>
                </Box>
            }
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default ItemExportMuiScrollTabs;
