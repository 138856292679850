import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import React from "react";
import Form from "../Shared/Form";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const Update = () => {
  return <Form />;
};

export default withSuspense(
  withPermission(Update, { feature: "vcard", action: "update" }),
  MuiSkeleton["GridInformation"]
);
