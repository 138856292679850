import DatePicker from "@components/datepickers/DateRangPicker";
import { Box, FormControl, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

const ServiceNextBill = () => {
  const { watch, setValue } = useFormContext();

  const onChangeDates = (newValues) => {
    setValue(
      `service_management_billing.service_billing_startdate`,
      newValues[0]
    );

    return setValue(
      `service_management_billing.service_billing_enddate`,
      newValues[1]
    );
  };
  return (
    <Box sx={{ width: { xs: "100%", md: "cacl((100% - 16px) / 2)" } }}>
      <Typography sx={{ fontSize: 14, mb: 1.25 }} color="text.grey">
        Chu Kỳ Thanh Toán Đầu Tiên *
      </Typography>
      <FormControl fullWidth>
        <DatePicker
          value={[
            watch("service_management_billing.service_billing_startdate"),
            watch("service_management_billing.service_billing_enddate"),
          ]}
          handler={onChangeDates}
        />
      </FormControl>
    </Box>
  );
};

export default ServiceNextBill;
