import React from "react";
import { Lan, Storage, TrendingUp } from "@mui/icons-material/";
import {
  Box,
  Grid,
  CardContent,
  Card,
  Typography,
  Button,
  CardActions,
} from "@mui/material";
import HeaderTitle from "@components/Title/HeaderTitle";
import BreadCrumb from "@components/shared/BreadCrumb";
import { Link } from "react-router-dom";

const WarehouseDashboard = () => {
  const modules = [
    {
      name: "Quản lý Kho",
      description: "",
      color: "#e0e8ff",
      icon: Storage,
      disabled: false,
      actions: [
        {
          name: "Thiết Bị Công Ty",
          href: "/warehouse-items/server/[1]",
          disabled: false,
        },
        {
          name: "Thiết Bị Khách Hàng ",
          href: "/warehouse/customer-items",
          disabled: false,
        },
        {
          name: "Thiết Bị Đã Bán ",
          href: "/warehouse/items-export",
          disabled: false,
        },
        {
          name: "Quản Lý Thể Loại",
          href: "/warehouse/types",
          disabled: false,
        },
        {
          name: "Quản Lý Thể Loại V2",
          href: "/warehouse/types-v2",
          disabled: false,
        },
        {
          name: "Quản Lý Phiếu Kho",
          href: "/warehouse/processes",
          disabled: false,
        },
        {
          name: "Quản Lý  Khấu Hao",
          href: "/warehouse/depreciations",
          disabled: false,
        },
        {
          name: "Quản Lý IDC",
          href: "/warehouse/boxes",
          disabled: false,
        },
      ],
    },
    {
      name: "Tài Nguyên Dịch Vụ",
      description: "",
      color: "#e0e8ff",
      icon: Lan,
      disabled: false,
      actions: [
        {
          name: "Quản Lý Tài Nguyên",
          href: "/warehouse/resources",
          disabled: false,
        },
        {
          name: "Quản Lý Cụm Đầu Tư",
          href: "/warehouse/investments",
          disabled: false,
        },
        {
          name: "Quản Lý Bandwidths",
          href: "/warehouse/bandwidths/datacenters",
          disabled: false,
        },
      ],
    },
    {
      name: "Report",
      description: "",
      color: "#e0e8ff",
      icon: TrendingUp,
      disabled: false,
      actions: [
        {
          name: "Thống Kê Vật Tư Kho",
          href: "/warehouse/report-items/devices",
          disabled: false,
        },
        {
          name: "Thống Kê Tủ Rack",
          href: "/warehouse/report-racks",
          disabled: false,
        },
        {
          name: "Thống Kê Tài Nguyên",
          href: "/warehouse/report-resources",
          disabled: false,
        },
        {
          name: "Thống Kê Cụm Đầu Tư",
          href: "/warehouse/report-investments",
          disabled: false,
        },
      ],
    },
  ];

  return (
    <Box>
      <Box>
        <HeaderTitle>Warehouse Dashboard</HeaderTitle>
      </Box>

      <BreadCrumb
        data={[
          { label: "Quản Lý Kho" },
          { label: "Dashboard", reactLink: "/warehouse" },
        ]}
      />
      <Grid container spacing={2}>
        {modules.map((item, index) => {
          return (
            <Grid item 
            xl={4} 
            lg={4} 
            md={6} 
            sm={12} 
            xs={12} 
            key={index}>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  "*::selection": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <CardContent>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        my: "auto",
                        mr: 2,
                        p: 2,
                        bgcolor: item.color,
                        borderRadius: 1,
                      }}
                    >
                      {item.icon &&
                        React.createElement(item.icon, {
                          color: "#000333",
                        })}
                    </Box>
                    <Box sx={{ my: "auto" }}>
                      <Typography
                        color="primary"
                        sx={{
                          my: "auto",
                          fontWeight: "bold",

                          textTransform: "uppercase",
                        }}
                      >
                        {item.name}
                      </Typography>
                      {item.description && (
                        <Typography sx={{ my: "auto", color: "#000333" }}>
                          {item.description}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </CardContent>
                <CardActions>
                  <Box display="flex" gap={1} flexWrap="wrap">
                    {item.actions.map((action, index) => {
                      return (
                        <Link
                          key={index}
                          to={action.href}
                          style={{ width: "calc((100% - 8px )/ 2)" }}
                        >
                          <Button
                            disabled={action.disabled}
                            sx={
                              action.disabled && {
                                opacity: 0.5,
                                filter: "grayscale(100%)",
                                cursor: "default",
                                pointerEvent: "none",
                              }
                            }
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontWeight: 500,
                                textTransform: "uppercase",
                              }}
                            >
                              {action.name}
                            </Typography>
                          </Button>
                        </Link>
                      );
                    })}
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default WarehouseDashboard;
