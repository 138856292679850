import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import BreadCrumb from "@components/shared/BreadCrumb";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Typography } from "@mui/material";
import React from "react";
import Form from "../Form";
import { useParams } from "react-router";
import ClusterBandwidthApi from "@/apis/beta/Warehouse/ClusterBandwidthApi";


const Detail = () => {
    const params = useParams();
    const {data: {cluster_bandwidth}, refetch} =  ClusterBandwidthApi.show(params?.id)
    return(
        <Box>
            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>
                Quản lý Bandwidth
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Quản lý Bandwidth Cụm", reactLink: "/warehouse/bandwidths/clusters" },
                    { label: "Chi tiết đối soát Bandwidth Cụm" }
                ]}
            /> 
            <Form data_cluster_bandwidth={cluster_bandwidth} type="detail" refetchData={refetch}/>
        </Box>
    )
}
export default withSuspense(withPermission(Detail, {
    feature: "warehouse",
    action: "bandwidth-cluster-detail"
}), MuiSkeleton["DataGrid"]) 