import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Typography } from "@mui/material";
import Header from "./Header";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import VcardApi from "@/apis/beta/Vcard/VcardApi";
import useDataGridParams from "@/hooks/useDataGridParams";
import DataGridCustomize from "@components/DataGrid";
import ButtonAction from "./Button";
import dayjs from "dayjs";

const List = () => {
  const initialColumns = [
    {
      field: "created_at",
      headerName: "Ngày Tạo",
      width: 300,
      renderCell: ({ row }) => {
        return (
          <Typography fontSize={14}>
            {dayjs(row?.created_at)?.format("DD/MM/YYYY - hh:mm:ss")}
          </Typography>
        );
      },
    },
    { field: "name", headerName: "Tên", width: 250 },
    { field: "position", headerName: "Chức Vụ", width: 250 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "location", headerName: "Địa chỉ", width: 400 },
    { field: "description", headerName: "Mô tả bản thân", width: 400 },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: ({ row }) => {
        return <ButtonAction refetch={refetch} row={row} />;
      },
    },
  ];

  const {
    columns,
    debouncedSearchQuery,
    page,
    searchQuery,
    setColumns,
    setPage,
    setSearchQuery,
  } = useDataGridParams(initialColumns);

  const { data, isFetching, refetch } = useQueryWithCustomized(
    `get-list-vcard-${JSON.stringify({ ...page, q: debouncedSearchQuery })}`,
    () =>
      VcardApi.GetVcardsForEmployee({
        ...page,
        q: debouncedSearchQuery,
        page: page?.page + 1,
      })
  );

  const preview_vcard_link = `https://${
    process.env.NODE_ENV === "development"
      ? "dc.vnetwork.dev"
      : "ebiz.vnetwork.vn"
  }/preview/vcf`;

  return (
    <Box>
      <Header />

      <DataGridCustomize
        onCellClick={({ row }) =>
          window.open(`${preview_vcard_link}/${row?.id}`, "_blank")
        }
        rows={data?.data || []}
        columns={columns}
        loading={isFetching}
        rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
        componentsProps={{
          toolbar: {
            store: "vcard.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "vcard",
            inputSearch: {
              placeholder: "Tìm kiếm,...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(List, { feature: "vcard", action: "index" }),
  MuiSkeleton["DataGrid"]
);
