import DragDropFile from '@components/shared/DragDropFile'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import AttactmentItem from '@features/Module/Ticket/Detail/OverviewTicket/Attachments/AttactmentItem'
import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Fragment, useEffect } from 'react'

const TicketAttachments = ({
    setAttachments,
    attachments = [],
    isLoading,
    setSearchParams
}) => {
    const handleDragFile = (file) => {
        setSearchParams({ activeTab: "attachments" });
        setAttachments((prev) => [{ file }, ...prev]);
    };
    useEffect(() => {
        const handlePaste = (e) => {
            if (!e.clipboardData.files?.[0]) {
                return;
            }

            e.preventDefault();
            setAttachments((prev) => [{ file: e.clipboardData.files?.[0] }, ...prev]);
        };

        document.addEventListener("paste", handlePaste);

        return () => {
            document.removeEventListener("paste", handlePaste);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Fragment>
            <Box sx={{ p: 2, border: `1px solid ${grey[300]}`, mb: 1.25 }}>
                {isLoading && <MuiSkeleton.LinearProgress />}
                <Typography sx={{ fontWeight: 'medium', p: "6px 0" }}>
                    Tệp Đính Kèm
                </Typography>
                <DragDropFile handler={handleDragFile} />
                {attachments?.length > 0 && (
                    <Box display="flex" flexDirection="column" gap={2} mt={2}>
                        {attachments?.map(({ file }, index) => (
                            <AttactmentItem
                                item={{
                                    ticket_attactment_name: file?.name,
                                    ticket_attachment_size: file?.size,
                                    ticket_attachment_type: file?.type,
                                }}
                                handleDelete={() =>
                                    setAttachments(attachments?.filter((_, i) => i !== index))
                                }
                                isPreview={false}
                                key={index}
                            />
                        ))}
                    </Box>
                )}
            </Box>
        </Fragment>
    )
}

export default TicketAttachments