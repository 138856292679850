import { encodeBase64, requestSimple, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import {
  API_AUTOMATION,
} from "@/libs/constants";
class PlaybookApi {
  CreatePlaybook = ({playbook}) =>
    requestWithToken(
      `${API_AUTOMATION}/api/workers/threshold/playbooks/`,
      "POST", {
      playbook: playbook,
    });

  UpdatePlaybook = ({id,playbook}) =>
      requestWithToken(
        `${API_AUTOMATION}/api/workers/threshold/playbooks/${id}`,
        "PUT", {
        playbook: playbook,
      });

  CreatePlaybookAction = ({id,playbook_action}) =>
      requestWithToken(
        `${API_AUTOMATION}/api/workers/threshold/playbooks/${id}/actions`,
        "POST", {
        playbook_action: playbook_action,
      });

  UpdatePlaybookAction = ({id, action_id, playbook_action}) =>
      requestWithToken(
        `${API_AUTOMATION}/api/workers/threshold/playbooks/${id}/actions/${action_id}`,
        "PUT", {
        playbook_action: playbook_action,
      });

  GetListAction = ({ playbook_id }) => {
    return useQueryWithCustomized(
      [
        JSON.stringify([
          "actions",
          playbook_id
        ]),
      ],
      async () => {
        const response = await requestWithToken(
          `${API_AUTOMATION}/api/workers/threshold/playbooks/${playbook_id}/actions`,
        );
        return response?.data ||  [] ;
      },
      {
        staleTime: 10 * 60 * 1000,
      }
    );  
    };
}

export default new PlaybookApi();
