import React from "react";
import { ArrowForward } from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Link, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import _ from "lodash";

const HistoryProcess = ({ warehouse_processes = [] }) => {
  return (
    <Box>
      <Typography sx={{ color: "#00000F", mb: 2 }}>Phiếu Liên Quan</Typography>
      <Timeline>
        {_.orderBy(warehouse_processes, (o) => o.warehouse_process_id).map(
          (o) => {
            return (
              <TimelineItem>
                <TimelineOppositeContent sx={{ m: "auto 0" }}>
                  <Link
                    component={NavLink}
                    to={`/warehouse/processes/${o.warehouse_process_id}/detail`}
                  >
                    {o.warehouse_process_code}
                  </Link>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography>
                    {o.warehouse_process_from} <ArrowForward />{" "}
                    {o.warehouse_process_to}
                  </Typography>
                  <Typography sx={{ color: "#777680" }}>
                    {o.warehouse_process_reason}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          }
        )}
      </Timeline>
    </Box>
  );
};
export default HistoryProcess;
