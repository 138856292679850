import TicketApi from "@/apis/beta/Ticket/TicketApi";
import FileApi from "@/apis/shared/File/FileApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { PREFIX_OF_ATTACHMENT, arrayNumberUnique } from "@/libs/constants";
import { TICKETS } from "@features/Module/Ticket/Create";
import AttachmentUploadProcess from "@features/Module/Ticket/Detail/OverviewTicket/Attachments/AttachmentUploadProcess";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import TicketAttachments from "../TicketAttachments";
import { TICKET_UIS } from "../functions";
import { queryClientRefetchScope } from "@/functions";

const TicketForm = ({
  defaultValues = { ...TICKETS.VALUES },
  ticket = undefined,
  onSubmit = async () => {},
  onClose = () => {},
}) => {
  const queryClient = useQueryClient();

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      service_id: defaultValues?.service_id || defaultValues?.product_name,
    },
    reValidateMode: "onChange",
  });
  const { setValue, handleSubmit } = methods;

  const [searchParams, setSearchParams] = useSearchParams();
  const [attachments, setAttachments] = useState([]);

  const onAttachmentPushing = async (file) => {
    const token = await FileApi.GetPresignedTokenAttachment({
      filename: {
        name: `${Date.now()}-${file?.name}`,
        folder: PREFIX_OF_ATTACHMENT?.ticket,
      },
    });

    if (!token || token?.status !== 200) {
      return notify("error", "Upload file thất bại.");
    }

    const uploaded = await FileApi.UploadAttachmentToStorage({
      token: token?.token,
      file: file,
      handleUploadFile: () => {},
      callback: (percen, file) => {
        setAttachments((prev) => [
          ...prev.map(({ file: f, percen: p }) => {
            return f.name === file?.name
              ? { file, percen }
              : { file: f, percen: p };
          }),
        ]);
      },
    });

    if (!uploaded) {
      return notify("error", "Tải dữ liệu thất bại.");
    }

    return (
      {
        attachment_file_name: file?.name,
        attachment_file_url: [
          PREFIX_OF_ATTACHMENT.ticket,
          token?.filename,
        ].join("/"),
        attachment_file_type: file?.type,
        attachment_file_size: file?.size,
      } || {}
    );
  };

  const uploadAttachment = async (ticket_id) => {
    if (attachments?.length > 0) {
      const responseFileAttachments = await Promise.all(
        attachments?.map(({ file }) => onAttachmentPushing(file))
      );

      if (responseFileAttachments?.length > 0) {
        await Promise.all(
          responseFileAttachments?.map((item) =>
            TicketApi.AddAttachment({
              ticket_id: ticket_id,
              values: {
                ticket_attachment_path: item?.attachment_file_url,
                ticket_attachment_type: item?.attachment_file_type,
                ticket_attachment_size: item?.attachment_file_size,
                ticket_attactment_name: item?.attachment_file_name,
              },
            })
          )
        );

        setAttachments([]);

        setSearchParams({ activeTab: "attachments" });

        document
          .getElementById("ticket-overview")
          ?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  };

  const submit = async (values) => {
    try {
      const newValues = {
        ...values,
        service_id:
          typeof values?.service_id === "number" ? values?.service_id : null,
        ticket_technical: arrayNumberUnique(
          values?.ticket_technical?.map((item) => item?.employee_id || item)
        ),
      };

      let response = await onSubmit({ ...newValues });
      const ticket_id = response?.data?.ticket?.ticket_id || ticket?.ticket_id;
      await uploadAttachment(ticket_id);
      if (!response?.errors) {
        notify("success", "Success");
        queryClientRefetchScope(queryClient, "ticket");
        queryClient.refetchQueries([`ticket.logs.${ticket?.ticket_id}`]);
        queryClient.refetchQueries([`ticket.attachments.${ticket?.ticket_id}`]);
      }
      return onClose();
    } catch (error) {
      notify("error", "Something went wrong" + error?.message);
    }
  };

  const [settings, changeSettings] = useState(
    ticket?.ticket_request_settings || TICKETS.UIS
  );
  const { mutateAsync, notify, isLoading } =
    useMutationWithNotification(submit);

  useEffect(() => {
    if (!!searchParams.get(`customer_id`)) {
      setValue(`customer_id`, searchParams.get(`customer_id`));
      setValue(`ticket_request_id`, 3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get(`customer_id`)]);

  const [locked] = useState(!!searchParams.get(`customer_id`));

  return (
    <FormProvider {...methods}>
      <TicketAttachments
        ticket={ticket}
        setAttachments={setAttachments}
        attachments={attachments}
        isLoading={isLoading}
        setSearchParams={setSearchParams}
      />
      <Paper
        component="form"
        onSubmit={handleSubmit(mutateAsync)}
        sx={{ p: 2 }}
      >
        <Typography
          sx={{ fontWeight: "medium", pb: 2, fontSize: { xs: 16, md: 18 } }}
        >
          Thông Tin Ticket
        </Typography>
        <Grid container spacing={2}>
          {settings?.map((ticketBanner) => (
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {ticketBanner?.uis?.map((ticketUi) => {
                  const TicketComponent = TICKET_UIS[ticketUi || "Default"];
                  if (!TicketComponent) return null;

                  const ticketProps = {
                    setUIs: changeSettings,
                    locked,
                    ticket,
                    ticket_request_id: parseInt(
                      methods.watch(`ticket_request_id`)
                    ),
                  };
                  return (
                    <Grid item xs={12} key={ticketUi}>
                      <TicketComponent {...ticketProps} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            justifyContent: "flex-end",
            pt: 2,
          }}
        >
          <Button onClick={onClose} variant="outlined" size="small">
            Cancel
          </Button>
          <Button
            startIcon={isLoading && <CircularProgress size={14} />}
            disabled={isLoading || !methods?.formState?.isDirty}
            variant="contained"
            size="small"
            type="submit"
          >
            {!ticket ? "New" : "Update"} Ticket
          </Button>
        </Box>
      </Paper>
      <Box
        sx={{
          position: "fixed",
          top: 16,
          right: 16,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {isLoading &&
          attachments?.length > 0 &&
          attachments?.map(({ file, percen }) => (
            <AttachmentUploadProcess
              key={file?.name}
              name={file?.name}
              percent={percen}
            />
          ))}
      </Box>
    </FormProvider>
  );
};

export default TicketForm;
