import React from "react";

import WarehouseProcessApi from "@/apis/beta/Warehouse/WarehouseProcessApi";
import { useNotificationContext } from "@contexts/notification";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import withPermission from "@/hocs/withPermission";
import { useEmployeeContext } from "@contexts/employee";
import WarehouseProcessLogApi from "@/apis/beta/Warehouse/WarehouseProcessLogApi";

const Storekeeper = ({ warehouseProcess, callbackProcess,refetchProcessLogs }) => {
  const { setNotification } = useNotificationContext();
  const [isConfirm, setConfirm] = React.useState("pending");
  const [storekeeper_feedback, setStorkeeperFeedback] = React.useState("");
  const [submit, setSubmit] = React.useState(false);
  const { employee } = useEmployeeContext();
  const onSubmit = async () => {
    setSubmit(true);
    const form = {
      warehouse_process_link: window.location.href,
      warehouse_process_storekeeper_feedback: storekeeper_feedback,
      warehouse_process_storekeeper: isConfirm,
    };
    if (isConfirm !== "approved") {
      setSubmit(false);
      return
    };
    await WarehouseProcessApi.Approved(
      warehouseProcess?.warehouse_process_id,
      form
    ).then(async (response) => {
      const data = response.data;
      if (data.status !== 200) {
        setNotification({
          open: true,
          message: data.errors[0].message,
          severity: "error",
        });
        setSubmit(false);
        return;
      }

      setSubmit(false);
      const action_process = data?.data?.warehouse_process?.warehouse_process_code.split("-")[0].toLowerCase();
      const warehouse_process_log = {
        warehouse_process_id: data?.data.warehouse_process?.warehouse_process_id,
        warehouse_process_name: data?.data.warehouse_process?.warehouse_process_code,
        warehouse_process_update_type: `CONFIRM-${action_process.toUpperCase()}`,
        warehouse_process_updater_id: employee?.employee_id,
        warehouse_process_updater_name: employee?.employee_name,
        metadata: {previous: warehouseProcess, newData: data?.data.warehouse_process},
        warehouse_process_update_description: `Thủ kho duyệt phiếu ${data?.data.warehouse_process?.warehouse_process_code} với phản hồi: ${storekeeper_feedback}`,
      }
      await WarehouseProcessLogApi.createProcessLog(warehouse_process_log);
      callbackProcess();
      refetchProcessLogs();
      setNotification({
        open: true,
        message: "Duyệt phiếu thành công.",
        severity: "success",
      });

    });
  };

  return (
    <>
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography
          sx={{
            textTransform: "uppercase",
            mb: 1,
          }}
        >
          Xác nhận của thủ kho
        </Typography>
        <FormControl>
          <RadioGroup
            row
            value={isConfirm}
            onChange={(event) => {
              setConfirm(event.target.value);
            }}
          >
            <FormControlLabel
              control={<Radio size="small" value={"pending"} />}
              label={"Chưa duyệt"}
            />
            <FormControlLabel
              control={<Radio size="small" value={"approved"} />}
              label={"Duyệt"}
            />
          </RadioGroup>
        </FormControl>
        <Typography sx={{ mb: 0.5, mt: 1.5 }}>Phản hồi</Typography>
        <TextField
          multiline
          placeholder="Nhập thông tin..."
          onChange={(event) => setStorkeeperFeedback(event?.target?.value)}
          value={storekeeper_feedback}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            size="small"
            onClick={onSubmit}
            variant="outlined"
            startIcon={
              submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
            }
            disabled={submit}
            sx={{ height: 36 }}
          >
            xác nhận
          </Button>
        </Box>
      </Paper>
    </>
  );
};
export default withPermission(Storekeeper, {
  action: "process-storekeeper-approved",
  feature: "warehouse",
  type: "button",
});
