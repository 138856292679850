import ClusterBandwidthApi from "@/apis/beta/Warehouse/ClusterBandwidthApi";
import React from "react";
import _ from "lodash";
import DataGridProCustomize from "@components/DataGridProCustomize";
import { Box } from "@mui/material";

const TableAdvance = () => {
    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 20
    });


    const { data, refetch } = ClusterBandwidthApi.index({
        page: page?.page + 1 || 1,
        pageSize: page?.pageSize || 20,
        query: "",
        searchQuery: ""
    })


    const { data: clusters } = ClusterBandwidthApi.clusters()

    const reFormatDataNew = React.useCallback(() => {
        let results = []
        let columnGrouping = []
        let colunmsTable = [
            {
                field: 'cluster',
                headerName: 'Cụm',
                sortable: false,
                
                width: 200,
            }
        ]
        let data_clusters = []
        _.forEach(data?.cluster_bandwidths, (o, index) => {
            columnGrouping.push(
                {
                    groupId: `${index}`,
                    headerName: `${o.cluster_bandwidth_name}`,
                    cellClassName: "month",
                    children: [{ field: `bw_limit.${o?.cluster_bandwidth_id}` }, { field: `bw_uplink.${o?.cluster_bandwidth_id}` }, { field: `bw_max.${o?.cluster_bandwidth_id}` }, { field: `volume.${o?.cluster_bandwidth_id}` }]
                }
            )
            _.forEach(o?.cluster_bandwidth_items , (i) => {
                data_clusters.push({
                    id: i?.cluster_bandwidth_item_id,
                    cluster: `${i?.cluster_bandwidth_item_name}`,
                    ...i
                })
            })

            colunmsTable.push(
                {
                    field: `bw_limit.${o?.cluster_bandwidth_id}`,
                    headerName: 'BW Limit (Gbps)',
                    sortable: false,
                    width: 200,
                },
                {
                    field: `bw_uplink.${o?.cluster_bandwidth_id}`,
                    headerName: 'BW Uplink (Switch)',
                    sortable: false,
                    width: 200,
                },
                {
                    field: `bw_max.${o?.cluster_bandwidth_id}`,
                    headerName: 'Max Bandwidth (Gbps)',
                    sortable: false,
                    width: 250,
                },
                {
                    
                    field: `volume.${o?.cluster_bandwidth_id}`,
                    headerName: 'Customers Volume(GB)',
                    sortable: false,
                    width: 250,
                }
            )
        })


        _.forEach(_.uniqBy(data_clusters, o=> o.cluster_bandwidth_item_name), o => {
            let obj_cluster = {
                id: o.cluster_bandwidth_item_name,
                cluster: o.cluster_bandwidth_item_name
            }

            _.forEach(data_clusters.filter(c=> c.cluster === o.cluster), c => {
                obj_cluster[`bw_limit.${c?.cluster_bandwidth_id}`] = c?.cluster_bandwidth_item_bw_limit || 0
                obj_cluster[`bw_uplink.${c?.cluster_bandwidth_id}`] = c?.cluster_bandwidth_item_bw_uplink || 0
                obj_cluster[`bw_max.${c?.cluster_bandwidth_id}`] = c?.cluster_bandwidth_item_max || 0
                obj_cluster[`volume.${c?.cluster_bandwidth_id}`] = c?.cluster_bandwidth_item_customer_volume || 0
            })

            results.push(obj_cluster)


        })
        return {
            results: results,
            columnGrouping: columnGrouping,
            colunmsTable: colunmsTable
        }
    }, [clusters])


    return (
        <Box
            sx={{
                "& .MuiDataGrid-columnHeader--filledGroup": {
                    "&:nth-of-type(even)": {
                        backgroundColor: "#b9d5ff91",
                    },
                    "&:nth-of-type(Odd)": {
                        backgroundColor: "#607d8b",
                    }
                },
                "& .MuiDataGrid-columnHeader--emptyGroup": {
                    backgroundColor: "#ff943975"
                }
            }}
        >
            <DataGridProCustomize
                initialState={{
                    pinnedColumns: { left: ['cluster'] } ,
                }}
                rows={reFormatDataNew()["results"]}
                columns={reFormatDataNew()["colunmsTable"]}
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={reFormatDataNew()["columnGrouping"]}
                rowCount={reFormatDataNew().length}
            />
        </Box>
    )
}
export default TableAdvance;