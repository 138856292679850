import { requestWithToken , encodeBase64} from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";

class ClusterBandwidthApi {
    index = ({
        query = null,
        searchQuery = null,
        page = 1,
        pageSize = 20,
    }) =>
        useQueryWithCustomized(
            ["warehouse.cluster_bandwidths.list", query, searchQuery, page, pageSize],
            async () => {
                return await requestWithToken(`${API_WAREHOUSE_V1_URL}/cluster_bandwidths?${query ? `&filters=${encodeBase64(query)}` : ''}${searchQuery ? `&queries=${(searchQuery)}` : ''}&page=${page}&page_size=${pageSize}`)
                    .then(({ data }) => {
                        if (data?.error) return data;

                        return {
                            cluster_bandwidths: data?.data?.cluster_bandwidths.map(o => ({
                                ...o,
                                id: o.cluster_bandwidth_id,

                            })
                            ),
                            pagination: data?.data?.pagination
                        };
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
    );
     
    create = (params) => requestWithToken(`${API_WAREHOUSE_V1_URL}/cluster_bandwidths`, 'POST', { cluster_bandwidth: params });

    clusters = () => useQueryWithCustomized(
        ["warehouse.clusters.all"],
        async () => {
            return await requestWithToken(`${API_WAREHOUSE_V1_URL}/cluster_bandwidths/clusters`)
                .then(({ data }) => {
                    if (data?.error) return data;
                    return data?.data?.clusters?.map((o)=> ({
                        value: o.cluster_bandwidth_item_name,
                        label: o.cluster_bandwidth_item_name,
                        ...o
                    }))
                })
                .catch((error) => {
                    return error.response;
                });
        },
        {
            suspense: true,
            notifyOnChangePropsExclusions: ['isStale']
        }
    )

    show = (id, callback) =>
    useQueryWithCustomized(
        ['warehouse.bandwidths.cluster.detail', id],
        async () => {
            return await requestWithToken(`${API_WAREHOUSE_V1_URL}/cluster_bandwidths/${id}`)
                .then(({ data }) => {
                    if (data?.errors) return callback(data.errors);

                    return {
                        cluster_bandwidth: data?.data?.cluster_bandwidth,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return {
                        cluster_bandwidth: null,
                    };
                });
        },
        {
            staleTime: 10 * 60 * 1000,
            notifyOnChangePropsExclusions: ['isStale'],
            suspense: true,
        }
    );

    update = (params, id) => requestWithToken(`${API_WAREHOUSE_V1_URL}/cluster_bandwidths/${id}`, 'PUT', { cluster_bandwidth: params });
}
export default  ClusterBandwidthApi = new ClusterBandwidthApi();