import { requestWithToken } from "@/functions";
import {
  API_PUBLIC_URL,
  API_V3_URL,
  REACT_APP_DOMAIN_FILE,
} from "@/libs/constants";
import axios from "axios";
import queryString from "query-string";

class VcardApi {
  GetVcardsForEmployee = async (values) => {
    const response = await requestWithToken(
      `${API_V3_URL}/vcard?${queryString.stringify(values)}`
    );
    return response?.data;
  };

  CreateVcard = async (values) => {
    try {
      const type_file =
        values?.file?.name?.split(".")?.[
        values?.file?.name?.split(".")?.length - 1
        ];

      const key = `vcard_avatar_${Date.now()}.${type_file}`;

      const response = await this.PresignedUrl(key);

      if (response?.errors || !response?.data?.url) {
        return {
          errors: [{ message: "Error when PresignedUrl!" }],
        };
      }

      await axios.put(response?.data?.url, values?.file);

      const data = await requestWithToken(`${API_V3_URL}/vcard`, "POST", {
        ...values,
        avatar: `${REACT_APP_DOMAIN_FILE}drives/avatars/${key}`,
      });

      return data?.data;
    } catch {
      return {
        errors: [{ message: "Something went wrong!" }],
      };
    }
  };

  FindOneVcard = async (id) => {
    if (!id) return;
    const response = await requestWithToken(`${API_PUBLIC_URL}/vcfs/${id}`);
    return response?.data;
  };

  UpdateVcard = async (values) => {
    const response = await requestWithToken(
      `${API_V3_URL}/vcard/${values?.id}`,
      "PUT",
      { ...values }
    );
    return response?.data;
  };

  DeleteVcard = async (values) => {
    const response = await requestWithToken(
      `${API_V3_URL}/vcard/${values?.id}`,
      "DELETE"
    );
    return response?.data;
  };

  PresignedUrl = async (key) => {
    const response = await requestWithToken(
      `${API_V3_URL}/vcard/avatars`,
      "POST",
      { key }
    );
    return response?.data;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new VcardApi();
