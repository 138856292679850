import React from "react";

export const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const ToggleActionButton = (action) => {
    setState((prevState) => ({ ...prevState, action: action }));
  };
  const TogglePopperAction = (action) => {
    setState((prevState) => ({ ...prevState, popperAction: action }));
  };

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = React.useState({
    action: {
      open: false,
      ref: React.useRef(null),
    },
    popperAction: {
      open: false,
      ref: React.useRef(null),
    },
    TogglePopperAction: TogglePopperAction,
    ToggleActionButton: ToggleActionButton,
  });

  return (
    <AppContext.Provider value={{ ...state }}>{children}</AppContext.Provider>
  );
};

export const useAppContext = () => {
  return React.useContext(AppContext);
};
