import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import { getMuiDataGridRowHeight } from "@/functions";
import useDebounce from "@/hooks/useDebounce";
import { Filter } from "@/hooks/useFilters";
import { format_pricing_vnd } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import Cell from "@components/DataGrid/Cell";
import CellConfig from "@components/DataGrid/CellConfig2";
import CellSpecification from "@components/DataGrid/CellSpecification";
import { Box, Link } from "@mui/material";
import _ from "lodash";
import React from "react";
import { NavLink } from "react-router-dom";

const TableWarehouseItem = ({ ids = [] }) => {
    const [urlParams, setUrlParams] = React.useState(
        {
            page: 1,
            page_size: 20,
        }
    )

    const defaultFilters = [
        {
            "warehouse_box_items.warehouse_item_id": {
                $in: ids
            }
        },
    ]

    const query = Filter.formatArrayFiltersToObject("$and", defaultFilters);

    const { data: warehouse_boxes } = WarehouseBoxApi.getAll()

    const [searchQuery, setSearchQuery] = React.useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 1500)
    const filterableSearch = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === '') return '';
        return debouncedSearchQuery.trim()
    }, [debouncedSearchQuery])

    const { data, isFetching } = WarehouseItemApi.Index({
        page: urlParams?.page || 1,
        pageSize: urlParams?.page_size || 20,
        query: Filter.stringify(query),
        searchQuery: filterableSearch() !== '' ? filterableSearch() : null,
    })

    const initialColumns = [
        {
            field: 'warehouse_item_name', headerName: 'Tên ',
            width: 250,
            renderCell: ({ value, row }) =>
                <Link component={NavLink} to={`/warehouse/items/${row.warehouse_item_id}/detail`} > <Cell content={value} /></Link>
        },
        {
            field: 'warehouse_item_service_tag',
            headerName: 'Service Tag / Serial Number',
            sortable: false,
            width: 250,
            renderCell: ({ value, row }) =>
                <Cell content={value} />
        },
        {
            field: 'warehouse_item_price',
            headerName: 'Tiền Thiết Bị',
            renderCell: ({ value }) => format_pricing_vnd(value),
            width: 250,
        },
        {
            field: "capacity", headerName: "Capacity",
            width: 250,
            sortable: true,
            renderCell: ({ value }) => (value && <CellConfig configures={value} />)
        },
        {
            field: 'warehouse_box_name',
            headerName: 'Kho / Datacenter',
            valueGetter: ({ row }) => {
                if (row.warehouse_box_item && row.warehouse_box_item.warehouse_box_item_reference_type === "WarehouseBox") {
                    const box = _.find(warehouse_boxes, { warehouse_box_id: row.warehouse_box_item.warehouse_box_item_reference_id })
                    return box
                }
                else if (row.warehouse_box_item && row.warehouse_box_item.warehouse_box_item_reference_type === "WarehouseRack" && row.warehouse_rack) {
                    const box = _.find(warehouse_boxes, { warehouse_box_id: row.warehouse_rack?.warehouse_box_id })
                    return box
                }
                return null
            },
            renderCell: ({ value, row }) =>
                <>
                    {value ? (value.warehouse_box_type === 0) ? <>{value.warehouse_box_name}</> : <Link component={NavLink} to={`/warehouse/boxes/${value.warehouse_box_id}`} > <Cell content={value.warehouse_box_name.toUpperCase()} /> </Link> : ""}
                </>
            ,
            width: 200,
        },
        {
            field: 'warehouse_box_rack',
            headerName: 'Tủ Rack',
            valueGetter: ({ row }) => {
                if (row.warehouse_box_item && row.warehouse_box_item.warehouse_box_item_reference_type === "WarehouseRack" && row.warehouse_rack) {
                    return row.warehouse_rack
                }
                return null
            },
            renderCell: ({ value, row }) =>
                <>
                    {value ? <Link component={NavLink} to={`/warehouse/racks/${value.warehouse_rack_id}?id=${row.warehouse_item_id}`} > <Cell content={value.warehouse_rack_name.toUpperCase()} /> </Link> : ""}
                </>,
            width: 200,
        },
        {
            field: 'warehouse_box_position',
            headerName: 'Vị Trí',
            valueGetter: ({ row }) => {
                if (row.warehouse_box_item && row.warehouse_box_item.warehouse_box_item_reference_type === "WarehouseRack" && row.warehouse_rack) {
                    return row.warehouse_box_item?.warehouse_box_item_position + ((row.warehouse_item_form_factor === 0 || row.warehouse_item_form_factor === 1) ? "" : ` - ${row.warehouse_box_item?.warehouse_box_item_position + (row.warehouse_item_form_factor - 1)}`)
                }
                return ""
            },
            width: 200,
        },
        {
            field: 'product',
            headerName: 'Dịch Vụ',
            valueGetter: ({ row }) => {
                if (row.warehouse_box_item && row.warehouse_box_item.warehouse_box_item_reference_type === "WarehouseRack" && row.warehouse_rack) {
                    return row.warehouse_resource_services.map(o => o.warehouse_resource_service_name).join(" ,")
                }
                return ""
            },
            renderCell: ({ value }) =>
                <Cell content={value.toUpperCase()} />,
            width: 150,
        },
        {
            field: 'deploy',
            headerName: 'Dịch Vụ Deploy',
            valueGetter: ({ row }) => row.warehouse_box_item?.warehouse_box_item_service_deploy || "",
            renderCell: ({ value }) =>
                <Cell content={value.toUpperCase()} />,
            width: 150,
        },
        {
            field: 'host_name',
            headerName: 'Host Name',
            valueGetter: ({ row }) => row.warehouse_box_item?.warehouse_box_item_hostname || "",
            renderCell: ({ value }) =>
                <Cell content={value.toUpperCase()} />,
            width: 150,
        },
        {
            field: "processor", headerName: "Processor",
            width: 300,
            valueGetter: ({ row }) => {
                let cpus = _.filter(row.children, o => { return ["CPU"].includes(o?.warehouse_type?.warehouse_type_name) })
                cpus = _.map(cpus, o => {
                    return {
                        label: `${o.warehouse_type?.warehouse_type_name} ${o.warehouse_item_name}`,
                        value: o.total
                    }
                })

                return cpus
            },
            renderCell: ({ value }) => <CellSpecification items={value} />
        },
        {
            field: "storage", headerName: "Storage",
            width: 300,
            sortable: true,
            valueGetter: ({ row }) => {
                let storages = _.filter(row.children, o => { return ["SSD", "SAS", "HDD", "NVME"].includes(o?.warehouse_type?.warehouse_type_name) })
                storages = _.map(storages, o => {
                    return {
                        label: `${o.warehouse_item_name}`,
                        value: o.total
                    }
                })

                return storages
            },
            renderCell: ({ value }) => <CellSpecification items={value} />
        }, {
            field: "memory", headerName: "Memory",
            width: 300,
            sortable: true,
            valueGetter: ({ row }) => {
                let rams = _.filter(row.children, o => { return ["RAM"].includes(o?.warehouse_type?.warehouse_type_name) })
                rams = _.map(rams, o => {
                    return {
                        label: `${o.warehouse_item_name} `,
                        value: o.total
                    }
                })

                return rams
            },
            renderCell: ({ value }) => <CellSpecification items={value} />
        },
        {
            field: "network", headerName: "NETWORK",
            width: 300,
            sortable: true,
            valueGetter: ({ row }) => {
                let networks = _.filter(row.children, o => { return ["CARD NETWORK", "MODULE"].includes(o?.warehouse_type?.warehouse_type_name) })
                networks = _.map(networks, o => {
                    return {
                        label: `${o.warehouse_item_name} `,
                        value: o.total
                    }
                })

                return networks
            },
            renderCell: ({ value }) => <CellSpecification items={value} />
        }
    ]

    return (
        <Box>
            <DataGridCustomize
                rows={data?.warehouse_items || []}
                columns={initialColumns?.filter(col => !col?.hide)}
                loading={isFetching}
                getRowHeight={(params) => getMuiDataGridRowHeight(params?.model?.capacity, ["capacity_cpu", "capacity_ram", "capacity_storage", "capacity_power_supply"], 2)}
                componentsProps={{
                    toolbar: {
                        store: "warehouse.resource.list.hiddenColumns",
                        initialColumns: initialColumns,
                        feature: 'warehouse-resource-service',
                        actions: [
                        ],
                        inputSearch: {
                            placeholder: "Nhập Service Tag / Serial Number, tên thiết bị, IDC,Rack tìm kiếm...",
                            value: searchQuery,
                            onChange: setSearchQuery
                        }
                    },
                    pagination: {
                        page: urlParams?.page - 1 || 0,
                        pageSize: urlParams.page_size,
                        rowsPerPageOptions: [10, 20, 50, 100],
                        onPageChange: (newPage) => {
                            setUrlParams(prevState => ({ ...prevState, page: newPage + 1 }))
                        },
                        onPageSizeChange: (newPageSize) => setUrlParams(prevState => ({ ...prevState, page_size: newPageSize, page: 1 }))
                    }
                }}
                rowCount={data?.pagination?.total || 0}
            />
        </Box>
    )
}
export default TableWarehouseItem;