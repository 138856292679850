import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import withSuspense from "@/hocs/withSuspense";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { WAREHOUSE_STATUS_BUY } from "@/libs/constants";

import DatePicker from "@components/DatePicker";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AddChildItemFormExport from "../AddchildItemFormExport";

const initialWarehouseItem = () => ({
  warehouse_item_status: "",
  warranty_start_at: moment(Date.now())?.format("YYYY-MM-DD"),
  warranty_expired_at: moment(Date.now())?.format("YYYY-MM-DD"),
});

const AddWarehouseFormExport = ({
  onClose,
  onChange,
  data,
  disableChooseOwner = false,
  types,
}) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: data || initialWarehouseItem(),
  });

  const [showModalAddItem, setShowModalAddItem] = useState(false);

  const warehouse_model_id = watch("warehouse_model_id");

  const { data: warehouses_group } =
    WarehouseModelApi.All();

  const {
    isLoading: CheckItemsExistLoading,
  } = useMutationWithNotification(WarehouseItemApi.CheckItemsExist);

  const handleSaveItem = async (values) => {
    let newValues = { ...values };


    if (values?.is_tags === "yes") {
      delete newValues?.["total"];
    } else {
      delete newValues?.["service_tags"];
    }
    onChange &&
      onChange({
        ...newValues,
        warehouse_group_id: watch("warehouse_group_id"),
      });

    onClose();
  };


  const warehouse_model = warehouses_group?.find(
    (item) => item?.warehouse_model_id === warehouse_model_id
  );

  useEffect(() => {
    setValue(`warehouse_item_status`, WAREHOUSE_STATUS_BUY?.[0]?.value);
    setValue(`warehouse_group_id`, warehouse_model?.warehouse_group_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse_model_id, warehouses_group, JSON.stringify(watch("children"))]);


  return (
    <Box
      mt={2}
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(handleSaveItem)();
      }}
    >

      <Box display="flex" gap={2} mt={2}>
      </Box>

      <Box display="flex" gap={2} mt={2}>
        <Controller
          control={control}
          name={`warehouse_item_status`}
          defaultValue={WAREHOUSE_STATUS_BUY?.[0]?.value}
          render={({ field }) => (
            <FormControl fullWidth>
              <Typography>Chọn tình trạng lúc bán*</Typography>
              <Select {...field} size="small" sx={{ mt: 1 }} fullWidth>
                {WAREHOUSE_STATUS_BUY?.map(
                  (item) => {
                    if (item?.value !== 3) {
                      return (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      )
                    }
                    return null;
                  }
                )}
              </Select>
            </FormControl>
          )}
        />
      </Box>

      <Box mt={2} display="flex" gap={2}>
        <Controller
          control={control}
          name={`warranty_start_at`}

          rules={{ required: "Vui lòng chọn ngày hiệu lực bảo hành" }}
          render={({ field }) => (
            <FormControl fullWidth>
              <Typography>Ngày bắt bảo hành xuất bán *</Typography>
              <Box mt={1}>
                <DatePicker {...field} />
              </Box>

              {errors?.warranty_start_at && (
                <ErrorHelperText
                  message={errors?.warranty_start_at?.message}
                />
              )}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name={`warranty_expired_at`}
          rules={{ required: "Vui lòng chọn ngày hết hiệu lực bảo hành" }}
          render={({ field }) => (
            <FormControl fullWidth>
              <Typography>Ngày kết thúc bảo hành xuất bán *</Typography>
              <Box mt={1}>
                <DatePicker {...field} />
              </Box>

              {errors?.warranty_expired_at && (
                <ErrorHelperText
                  message={errors?.warranty_expired_at?.message}
                />
              )}
            </FormControl>
          )}
        />
      </Box>


      {showModalAddItem && (
        <AddChildItemFormExport
          onClose={() => setShowModalAddItem(false)}
          onChange={(values) => {
            setValue("children", [...watch("children"), values]);
          }}
        />
      )}

      <Box mt={2}>
        <Button
          type="submit"
          size="small"
          variant="contained"
          disabled={CheckItemsExistLoading}
          startIcon={CheckItemsExistLoading && <CircularProgress size={14} />}
        >
          {data ? "Lưu" : "Thêm"} Item
        </Button>
      </Box>
    </Box>
  );
};

export default withSuspense(AddWarehouseFormExport, MuiSkeleton["LinearProgress"]);
