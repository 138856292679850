import WarehouseDepreciationApi from "@/apis/beta/Warehouse/WarehouseDepreciationApi";
import { format_pricing_vnd } from "@/libs/constants";
import PaperLoading from "@features/Module/WarehouseNew/Shared/Loading/PaperLoading";
import BoxStatistical from "@features/Module/WarehouseNew/Shared/Statistic/BoxStatistical";
import { Grid } from "@mui/material";
import React from "react";

const DepreciationStatistical = ({ searchQuery, query }) => {
  const { data: statistic, isLoading } = WarehouseDepreciationApi.statistic({
    query: query,
    searchQuery: searchQuery,
  });

  if (isLoading) {
    return <PaperLoading />;
  }

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
        <BoxStatistical title={"Thiết Bị"} value={statistic?.device || 0} />
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
        <BoxStatistical title={"Linh Kiện"} value={statistic?.part || 0} />
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
        <BoxStatistical
          title={"Tổng Tiền Nhập"}
          value={format_pricing_vnd(Number(statistic?.input_price))}
        />
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
        <BoxStatistical
          title={"Khấu Hao Hàng Tháng"}
          value={format_pricing_vnd(Number(statistic?.monthly_depreciation))}
        />
      </Grid>
    </Grid>
  );
};
export default DepreciationStatistical;
