import { Typography } from "@mui/material"
import People from "../../Detail/People"
import TicketContent from "./TicketContent"
import TicketCustomer from "./TicketCustomer"
import TicketFeedback from "./TicketFeedback"
import TicketMeeting from "./TicketMeeting"
import TicketRequest from "./TicketRequest"
import TicketService from "./TicketService"
import TicketSolution from "./TicketSolution"
import TicketSource from "./TicketSource"
import TicketStatus from "./TicketStatus"
import TicketTimeRelease from "./TicketTimeRelease"
import TicketTimeReply from "./TicketTimeReply"
import TicketTimeRequest from "./TicketTimeRequest"
import TicketTimeResponse from "./TicketTimeResponse"
import TicketType from "./TicketType"
import TicketUrgency from "./TicketUrgency"
import { Link } from "react-router-dom"
import { CellStatus } from "@components/DataGrid/Cell"
import TicketServiceStartdate from "./ServiceStartDate"

export const TICKET_UIS = {
    Default: undefined,
    'ticket_receive_source': TicketSource,
    'ticket_request_id': TicketRequest,
    'customer_id': TicketCustomer,
    'ticket_type_id': TicketType,
    'service_id': TicketService,
    'ticket_content': TicketContent,
    'ticket_urgency': TicketUrgency,
    'ticket_time_request': TicketTimeRequest,
    'ticket_time_reply': TicketTimeReply,
    'ticket_time_release': TicketTimeRelease,
    'ticket_time_response': TicketTimeResponse,
    'meeting': TicketMeeting,
    'ticket_status': TicketStatus,
    'ticket_technical': People,
    'ticket_solution': TicketSolution,
    'ticket_feedback': TicketFeedback,
    'service_start_date': TicketServiceStartdate,
    'service_end_date': TicketServiceStartdate,
}

export const ticketBuilderTitle = (ticketColumn, ticketValue = 0) => {
    switch (ticketColumn) {
        case 'ticket_time_request':
            return {
                3: 'Thời gian dự kiến trao đổi với khách hàng', //
                2: 'Ngày gặp vấn đề',
            }?.[ticketValue] || 'Ngày khách hàng Raise Ticket'
        default:
            break;
    }
}
const TICKET_VIEWS = {
    Typography: ({ value, ...props }) => <Typography sx={{}}>{value}</Typography>,
    Link: ({ value = '', to = '/' }) => <Link to={to}>
        <Typography sx={{}} color="primary">
            {value}
        </Typography>
    </Link>,
    muiChip: ({ ...props }) => <CellStatus {...props} />
}

export default TICKET_VIEWS