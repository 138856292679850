import ServiceApi from "@/apis/beta/Service/ServiceApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import ClusterPrice from "@components/Templates/ClusterService(k8s)/Price";
import VnisPrice from "@components/Templates/VNIS/Price";
import SwiftCDNPrice from "@components/Templates/SwiftFederation/ContentDeliveryNetwork(CDN)/ServicePackage";
import SwiftStoragePrice from "@components/Templates/SwiftFederation/Storage(LFD)/ServicePackage";
import SwiftTransPrice from "@components/Templates/SwiftFederation/Trans(VMS-LMS)/ServicePackage";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import React from "react";
import { useParams } from "react-router-dom";

const SERVICE_PRICE_UIS = {
  TranscodingService: SwiftTransPrice,
  ConversantService: SwiftCDNPrice,
  StorageLFDService: SwiftStoragePrice,
  ClusterService: ClusterPrice,
  VnisService: VnisPrice,
  Default: () => <>Dịch vụ đang phát triển...</>
}

const ServicePricingConfigures = () => {
  const { service_id: id } = useParams();
  const { data: service } = ServiceApi.Detail({ id })
  const { data: servicePrice } = ServiceApi.PricingAndConfigoptions({ id });

  return (
    <React.Fragment>
      {React.createElement(SERVICE_PRICE_UIS[service?.service_creatable_type || "Default"], {
        ...servicePrice,
        service
      })}
    </React.Fragment>
  );
};

export default withSuspense(
  withPermission(ServicePricingConfigures, {
    feature: "service",
    action: "pricing",
  }),
  MuiSkeleton["Box"]
);
