import BreadCrumb from "@components/shared/BreadCrumb";
import { Box, Typography } from "@mui/material";
import React from "react";
import Form from "../Form";
import { useParams } from "react-router";
import DatacenterBandwidthApi from "@/apis/beta/Warehouse/DatacenterBandwidthApi";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const Detail = () => {
    const params = useParams();
    const {data: {datacenter_bandwidth}, refetch} = DatacenterBandwidthApi.show(params?.id)

    return (
        <Box>
           <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>
                Quản lý Bandwidth
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Quản lý Bandwidth Datacenter", reactLink: "/warehouse/bandwidths/datacenters" },
                    { label: "Chi Tiết  Hoá đơn đối soát " }
                ]}
            /> 
            <Form type="update" data_datacenter_bandwidth={datacenter_bandwidth} refetchData={refetch}/>
        </Box>
    )
}
export default withSuspense(withPermission(Detail, {
    feature: "warehouse",
    action: "bandwidth-datacenter-detail"
}), MuiSkeleton["DataGrid"]) 