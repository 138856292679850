import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
const TicketContent = ({ ticket_request_id }) => {
    const { control, formState: { errors } } = useFormContext()

    return (
        <Controller
            name="ticket_content"
            rules={{ required: "This field is required" }}
            control={control}
            render={({ field }) => (
                <TextField
                    label={ticket_request_id !== 3
                        ? "Mô tả Ticket"
                        : "Các nội dung cần có cho buổi trao đổi:"}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    size="small"
                    multiline
                    rows={8}
                    placeholder={
                        ticket_request_id !== 3
                            ? "Mô tả vấn đề khách hàng đang gặp phải..."
                            : "Các nội dung cần có cho buổi trao đổi:"
                    }
                    helperText={errors?.ticket_content?.message}
                    error={!!errors?.ticket_content}
                />
            )}
        />
    )
}

export default TicketContent