import TicketTypeApi from '@/apis/beta/Ticket/TicketTypeApi';
import withSuspense from '@/hocs/withSuspense';
import ErrorHelperText from '@components/shared/ErrorHelperText';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize';
import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const TicketType = () => {
    const { control, formState: { errors } } = useFormContext()
    const { data: ticket_types } = TicketTypeApi.ListTicketType()

    return (

        <Controller
            name="ticket_type_id"
            rules={{ required: "This field is required" }}
            control={control}
            render={({ field: { onChange, value } }) => {
                const selected =
                    ticket_types?.find(
                        (x) => x?.ticket_type_id === value
                    ) || null;

                return (
                    <FormControl fullWidth>
                        <SingleSelectCustomize
                            input={{
                                label: 'Ticket Type *',
                                placeholder: "Chọn loại ticket..."
                            }}
                            onChange={(_, newValue) =>
                                onChange(newValue?.ticket_type_id || null)
                            }
                            value={selected}
                            formatCollection={{
                                label: "ticket_type_title",
                                value: "ticket_type_id",
                            }}
                            compareAttributes={["ticket_type_title"]}
                            options={ticket_types}
                        />

                        {errors?.ticket_type_id && (
                            <ErrorHelperText
                                message={errors?.ticket_type_id?.message}
                            />
                        )}
                    </FormControl>
                );
            }}
        />
    )
}

export default withSuspense(TicketType, MuiSkeleton["TextField"])