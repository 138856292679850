import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import WarehouseItemDataGridDeviceExported from "../../Shared/WarehouseItemDataGridDeviceExported";
import WarehouseItemExportedMainTabs from "./WarehouseItemExportedMainTabs";

const ItemsExport = () => {
    const [activeTab, setActiveTab] = React.useState("devices");
    const [searchParams] = useSearchParams();
    const [page, setPage] = React.useState({
        page: Number(searchParams?.get("page")) || 0,
        pageSize: Number(searchParams?.get("page_size")) || 20,
    })
    useEffect(() => {
        if (searchParams.get("item") === "accessories") {
            setActiveTab("accessories")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onResetPage = () => {
        setPage({ page: 0, pageSize: 20 });
    }
    const onChangeTab = (value) => {
        setActiveTab(value);
    }

    return (
        <>
            <WarehouseItemExportedMainTabs onChangeTab={onChangeTab} activeTab={activeTab} onResetPage={onResetPage} />
            <WarehouseItemDataGridDeviceExported
                page={page}
                setPage={setPage}
                resetPage={onResetPage}
                showFilter={true}
                types_id={activeTab === "devices" ? [1, 2] : []}
                API={WarehouseItemApi.ItemsExported}
                analysist={false}
                activeTab={activeTab}
            />
        </>
    );
};

export default withPermission(
    withSuspense(ItemsExport, MuiSkeleton["DataGrid"]),
    {
        feature: "warehouse",
        action: "items-export",
    }
);
