import React from "react";
import _ from "lodash";
import InvestmentApi from "@/apis/beta/Warehouse/InvestmentApi";
import { useNotificationContext } from "@contexts/notification";
import WarehouseResoureServiceApi from "@/apis/beta/Warehouse/WarehouseResoureServiceApi";
import { Box, Chip, CircularProgress, Drawer, IconButton, Link, Popper } from "@mui/material";
import { Lens, MoreVertOutlined } from "@mui/icons-material";
import { format_date_short } from "@/functions";
import CellConfig from "@components/DataGrid/CellConfig2";
import { NavLink } from "react-router-dom";
import Cell from "@components/DataGrid/Cell";
import { format_pricing_vnd } from "@/libs/constants";
import { green, grey } from "@mui/material/colors";
import DataGridCustomize from "@components/DataGrid";
import PopperAction from "@components/PopperAction";
import ConfirmDialog from "./ConfirmDialog";
import QuickViewFormResourceService from "./QuickViewFormResourceService";


const TableResourceService = ({ investment = {}, refetchData = () => null }) => {
    const [urlParams, setUrlParams] = React.useState(
        {
            page: 1,
            page_size: 20,
        }
    )

    const { data: { warehouse_resource_services, pagination }, isFetching, refetch } = InvestmentApi.resourceServices(
        {
            investment_id: investment?.investment_id,
            query: "",
            searchQuery: "",
            page: urlParams.page,
            pageSize: urlParams.page_size
        }
    )

    const { setNotification } = useNotificationContext();

    const [confirm, ToggleConfirm] = React.useState({ show: false });

    const [popper, setPopper] = React.useState({
        anchorEl: undefined,
        data: undefined
    })

    const onCancelled = async (data) => {
        await WarehouseResoureServiceApi.cancelled(data?.warehouse_resource_service_id).then((response) => {
            const data = response.data;
            if (data.status !== 200) {
                setNotification({ open: true, message: data.errors[0].message, severity: "error" });
                return;
            }

            setNotification({ open: true, message: "Huỷ tài nguyên dịch vụ thành công.", severity: "success" });
            refetch();
            refetchData();
        })
    }

    const [openDrawer, setOpenDrawer] = React.useState({ show: false, data: null })

    const onClickItem = (row) => {
        setOpenDrawer({ show: true, data: row })
    }

    const [actions] = React.useState([
        {
            name: "delete",
            handleClick: (data) => {
                ToggleConfirm({
                    show: true,
                    callback: () => {
                        onCancelled(data)
                    },
                    title: 'Huỷ Tài Nguyên Chạy Dịch Vụ',
                    description: `Bạn có muốn huỷ tài nguyên dịch vụ ${data?.warehouse_resource_service_code} - ${data?.warehouse_resource_service_name}?`,
                })

            },
            icon: "Delete",
            label: "Huỷ"
        },
    ])

    const initialColumns = [
        {
            field: 'warehouse_resource_type',
            headerName: 'Loại Tài Nguyên',
            valueGetter: ({ row }) => row?.warehouse_resource?.warehouse_resource_type.toUpperCase(),
            renderCell: ({ row, value }) => (
                <Link component={NavLink} to={`/warehouse/resources/${row?.warehouse_resource.warehouse_resource_id}/information`} > <Cell content={value.toUpperCase()} /></Link>
            ),
            width: 200,
        },
        {
            field: 'warehouse_resource_service_price',
            headerName: 'Tiền đầu tư',
            renderCell: ({ value, row }) => {
                return (row?.warehouse_resource?.warehouse_resource_type === "virturalization") ?
                    <Chip
                        label={(value !== 0) ? format_pricing_vnd(value) : "Nhập Tiền Phân Bổ Tài Nguyên"}
                        color={(value !== 0) ? 'primary' : 'error'}
                        onClick={() => onClickItem(row)}
                    />
                    : format_pricing_vnd(value)
            },
            width: 250,
        },
        {
            field: 'warehouse_resource_service_name',
            headerName: 'Dịch Vụ',
            valueGetter: ({ value }) => value.toUpperCase(),
            renderCell: ({ row, value }) => (
                <Link component={NavLink} to={`/warehouse/resources/${row?.warehouse_resource.warehouse_resource_id}/services`} > <Cell content={value.toUpperCase()} /></Link>
            ),
            width: 200,
        },
        {
            field: 'warehouse_resource_service_status',
            headerName: 'Trạng Thái',
            renderCell: ({ value }) => <Lens sx={{ m: "auto", color: value === "active" ? green[900] : grey[500] }} />,
            width: 120,
        },
        {
            field: 'warehouse_resource_config',
            headerName: 'Cấu Hình',
            width: 350,
            valueGetter: ({ row, value }) => {
                const config = _.find(row?.warehouse_resource_configs, { warehouse_resource_config_type: "config" })

                return {
                    capacity_cpu: config?.warehouse_resource_config_cpu,
                    capacity_ram: config?.warehouse_resource_config_ram,
                    capacity_storage: config?.warehouse_resource_config_storage
                }
            },
            renderCell: ({ value }) => (value && <CellConfig configures={value} />)
        },
        {
            field: 'warehouse_resource_config_used',
            headerName: 'Cấu Hình Khách Hàng Sử Đụng',
            width: 350,
            valueGetter: ({ row, value }) => {
                const config = _.find(row?.warehouse_resource_configs, { warehouse_resource_config_type: "used" })

                return {
                    capacity_cpu: config?.warehouse_resource_config_cpu,
                    capacity_ram: config?.warehouse_resource_config_ram,
                    capacity_storage: config?.warehouse_resource_config_storage
                }
            },
            renderCell: ({ value }) => (value && <CellConfig configures={value} />)
        },
        {
            field: 'warehouse_resource_service_created_at',
            width: 200,
            headerName: 'Ngày Tạo',
            valueGetter: ({ value }) => (format_date_short(value)),
        },
        {
            field: "id",
            headerName: "Thao Tác",
            width: 80,
            renderCell: ({ row }) => (
                <>
                    {row.warehouse_resource_service_status === "active" ?
                        <Box sx={{
                            width: '100%', display: 'flex', justifyContent: 'center',
                        }}>
                            <IconButton
                                title="Thao Tác"
                                onClick={(event) => {

                                    setPopper(prev => ({ ...prev, anchorEl: event?.target, data: row }))
                                }}
                            >
                                <MoreVertOutlined sx={{ m: 'auto' }} />
                            </IconButton>
                        </Box> : null}
                </>)
        },
    ]



    return (
        <Box>
            <React.Suspense fallback={<CircularProgress size={16} disableShrink />}>
                {openDrawer.show &&
                    <Drawer
                        anchor="right"
                        open={openDrawer.show}
                        sx={{ width: 400 }}
                        onClose={() => setOpenDrawer({ show: false, data: null })}
                    >
                        <QuickViewFormResourceService data={openDrawer.data} refetchData={refetchData} refetchDataTable={refetch} handleClose={() => setOpenDrawer({
                            show: false, data: null
                        })} />
                    </Drawer>}

            </React.Suspense>

            <DataGridCustomize
                rows={warehouse_resource_services || []}
                columns={initialColumns?.filter(col => !col?.hide)}
                loading={isFetching}
                componentsProps={{
                    toolbar: {
                        store: "warehouse.resource.list.hiddenColumns",
                        initialColumns: initialColumns,
                        feature: 'warehouse-resource-service',
                        actions: [
                        ],
                    },
                    pagination: {
                        page: urlParams?.page - 1 || 0,
                        pageSize: urlParams.page_size,
                        rowsPerPageOptions: [1, 10, 20, 50, 100],
                        onPageChange: (newPage) => {
                            setUrlParams(prevState => ({ ...prevState, page: newPage + 1 }))
                        },
                        onPageSizeChange: (newPageSize) => setUrlParams(prevState => ({ ...prevState, page_size: newPageSize, page: 1 }))
                    }
                }}
                rowCount={pagination.total || 0}
            />
            {popper?.anchorEl !== undefined && <Popper anchorEl={popper?.anchorEl}
                open={Boolean(popper.anchorEl)}>
                <PopperAction
                    {...popper}
                    actions={actions}
                    handleClose={() => setPopper(prev => ({ ...prev, anchorEl: undefined }))} />
            </Popper>}
            {confirm.show && (
                <ConfirmDialog
                    close={() => ToggleConfirm({ show: false })}
                    title={confirm.title}
                    description={confirm.description}
                    callback={() => {
                        if (confirm.callback) confirm.callback();
                        ToggleConfirm({ show: false });
                    }}
                />
            )}
        </Box>
    )
}
export default TableResourceService;