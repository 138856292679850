import HeaderTitle from "@components/Title/HeaderTitle";
import { Box, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <HeaderTitle>Danh sách Vcard</HeaderTitle>

      <Link to="/account/vcard/new">
        <Button variant="contained" size="small">
          Thêm Vcard
        </Button>
      </Link>
    </Box>
  );
};

export default Header;
