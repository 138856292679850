import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import {
    Box,
    Chip,
    Collapse,
    FormControl,
    Grid,
    IconButton,
    Paper,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import TypeTemplate from "../Template";

const TypeGroup = ({ type = {}, index = 0, types = [], color = "default" }) => {
    const { control, } = useForm({
        defaultValues: {
            type_name: types[index]?.warehouse_type_name,
            total_input: types[index]?.total_input,
            total_warehouse: types[index]?.total_warehouse,
            total_idc: types[index]?.total_idc,
            total_export: types[index]?.total_export
        },
    });
    const Component = TypeTemplate;
    const [open, setCollapse] = useState(false);

    return (
        <>
            <Paper sx={{
                p: 3,
                mb: 2.5,
                backgroundColor: open ? "rgba(0, 0, 0, 0.03)" : "rgba(255, 255, 255, 0.05)",
            }}>
                <Box
                    sx={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                        mb: 1.5,
                        gap: 2,
                    }}
                    component="form"
                >
                    <Grid container spacing={2}>
                        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                            <Controller
                                control={control}
                                name="type_name"
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            sx={{
                                                minWidth: 200,
                                                // maxWidth: 200
                                                width: "100%"
                                            }}
                                        >
                                            <TextField
                                                value={`${value} ${type?.warehouse_type_children?.length > 0 ? `(${type?.warehouse_type_children?.length})` : ""}`}
                                                size="small"
                                                label="Tên loại"
                                                InputLabelProps={{ shrink: true }}
                                                placeholder="Nhập tên loại..."
                                            />
                                        </FormControl>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}
                        sx={{
                            display: "flex",
                            // justifyContent: "space-between",
                            alignItems: "center",
                        }}>
                            <Chip size="small"
                                sx={{
                                    // width: "100%",
                                    minWidth: 200,
                                    maxWidth: 200
                                }}
                                label={`Đơn vị: ${types[index]?.warehouse_type_unit}`} variant="outlined" />
                            <IconButton size="small" onClick={() => setCollapse(!open)}>
                                {React.createElement(
                                    open ? VisibilityOffOutlined : VisibilityOutlined,
                                    {
                                        fontSize: "small",
                                    }
                                )}
                            </IconButton>
                        </Grid>
                        
                    </Grid>
                    
                    
                </Box>
                {/* <Box
                    sx={{
                        display: "flex",
                        // justifyContent: "space-between",
                        // mb: 1.5,
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <Chip size="small"
                        sx={{
                            // width: "100%",
                            minWidth: 100,
                            maxWidth: 100
                        }}
                        label={`Đã nhập: ${types[index]?.total_input}`} variant="outlined" />
                    <Chip size="small"
                        sx={{
                            // width: "100%",
                            minWidth: 100,
                            maxWidth: 100
                        }}
                        label={`Ở kho: ${types[index]?.total_warehouse}`} variant="outlined" color="error" />
                    <Chip size="small"
                        sx={{
                            // width: "100%",
                            minWidth: 100,
                            maxWidth: 100
                        }}
                        label={`Ở IDC: ${types[index]?.total_idc}`} variant="outlined" />
                    <Chip size="small"
                        sx={{
                            // width: "100%",
                            minWidth: 100,
                            maxWidth: 100
                        }}
                        label={`Xuất: ${types[index]?.total_export}`} variant="outlined" />
                </Box> */}
                <Collapse
                    in={open}>
                    <>
                        {type?.warehouse_type_children?.length !== 0 ?
                            type?.warehouse_type_children?.map((item, index) => {
                                const position = types.findIndex((type) => type?.warehouse_type_id === item?.warehouse_type_id)
                                return (
                                    <React.Fragment key={index}>
                                        <TypeGroup type={item} index={position} types={types} color="child" />
                                    </React.Fragment>
                                );
                            }) :
                            <Paper sx={{
                                p: 3,
                                mb: 2.5,
                                mt: 2,
                                backgroundColor: "rgba(255, 255, 255, 0.05)"
                            }}>
                                {!!Component && <Component type={types[index]} />}
                            </Paper>
                        }
                    </>
                </Collapse>
            </Paper>
        </>
    );
};

export default TypeGroup;