import MuiScrollTabs from "@components/shared/MuiScrollTabs";
import { Hub, Lan } from "@mui/icons-material";
import AllInboxIcon from '@mui/icons-material/AllInbox';

const Header = () => {
    const initialTabs = [
        {
            value: "datacenter",
            label: "Nhà Mạng",
            href: 'datacenters',
            icon: Lan
        },
        {
            value: "cluster",
            label: "Cụm",
            href: 'clusters',
            icon: Hub
        },
        {
            value: "rack",
            label: "RACK",
            href: 'racks',
            icon: AllInboxIcon
        },

    ]

    return (
        <MuiScrollTabs prefix={`warehouse/bandwidths`}
                    initialTabs={initialTabs}
                />
    )
}
export default Header;