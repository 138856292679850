import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";

class ProviderApi {
  getAll = () =>
    useQueryWithCustomized(
      ["warehouse.providers.all"],
      async () => {
        return await requestWithToken(`${API_WAREHOUSE_V1_URL}/providers/list`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.providers?.map((o) => ({
              value: o.provider_name,
              label: o.provider_name,
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );
}

export default ProviderApi = new ProviderApi();
