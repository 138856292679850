import { Box, CircularProgress } from "@mui/material";
import React from "react";
import Header from "../Header";
import TableWarehouseItem from "../TableWarehouseItem";
import TableWarehouseItemAccessory from "../TableWarehouseItemAccessory";


const Others = () => {
    return(
        <Box>
            <Header/>
            <React.Suspense fallback={<CircularProgress size={24} sx={{ m: 'auto' }} />}>
            <TableWarehouseItemAccessory key={"other"} type={"other"} />
            {/* <TableWarehouseItem key={"other"} type={"other"} /> */}
            </React.Suspense>
        </Box>
    )
}
export default Others;