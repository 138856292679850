import ServiceApi from "@/apis/beta/Service/ServiceApi";
import { activeTab } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import MuiScrollTabs from "@components/shared/MuiScrollTabs";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useLayoutContext } from "@contexts/layout";
import {
  AnalyticsOutlined,
  MailOutlined,
  ReceiptOutlined as ReceiptIcon,
  DisplaySettingsOutlined as ServiceInformation,
  PaymentOutlined as ServicePaymentIcon,
  // MultipleStopOutlined as SubscriptionIcon,
  QueryStatsOutlined as UsageIcon,
} from "@mui/icons-material";
import React from "react";
import { useParams } from "react-router-dom";

const TAB_RESOURCES = {
  analytics: ['ConversantService', 'StorageLFDService', 'TranscodingService', 'ClusterService', 'VnisService'],
  pricing: ['ConversantService', 'StorageLFDService', 'TranscodingService', 'ClusterService', 'VnisService'],
  usages: ['ConversantService', 'StorageLFDService', 'TranscodingService', 'ClusterService', 'VnisService'],
}

const isAddon = (creatable) => creatable === 'Reducer::Override::Service'

const unvalidPermission = ({
  yourPermissions = [],
  module = '',
  tabname = ''
}) => {
  return !activeTab({
    collections: yourPermissions,
    feature: module,
    action: tabname,
  })
}
const unvalidResource = (tabname = '', creatable_type = '') => {
  return !TAB_RESOURCES?.[tabname]?.includes(creatable_type)
}
const unRegisted = (uuid) => !uuid || !uuid?.trim()

const ServiceTabs = () => {
  const { permissions } = useLayoutContext();

  const serviceCollections = React.useMemo(() => {
    let arrs = [];
    permissions?.forEach((collection) => {
      if (["service"].includes(collection.feature)) {
        arrs.push(...collection?.actions);
      }
    });
    return arrs;
  }, [permissions]);

  const { service_id } = useParams();
  const { data } = ServiceApi.Detail({ id: service_id });

  const tabIsHidden = (tabname = '') => {
    return unRegisted(data?.service_creatable_uuid) ||
      isAddon(data?.service_referrer_type) ||
      unvalidPermission({ yourPermissions: serviceCollections, module: "service", tabname: tabname }) ||
      unvalidResource(tabname, data?.service_creatable_type)
  }

  const [INITIAL_SERVICE_TABS] = React.useState([
    {
      value: "information",
      label: "Thông Tin Cơ Bản",
      href: "",
      icon: ServiceInformation,
      hidden: !activeTab({
        collections: serviceCollections,
        feature: "service",
        action: "show",
      }),
    },
    {
      value: "pricing",
      label: "Cấu Hình Đối Soát",
      href: "pricing",
      icon: ServicePaymentIcon,
      hidden: tabIsHidden("pricing")
    },
    {
      value: "mailer",
      label: "QL Gửi Mail Dịch Vụ",
      href: "mailers",
      icon: MailOutlined,
      hidden: isAddon(data?.service_referrer_type) ||
        !activeTab({ collections: serviceCollections, feature: "service", action: "service-mailer-index" }),
    },
    {
      value: "usage",
      label: "Thông Số Sử Dụng",
      href: "usages",
      icon: UsageIcon,
      hidden: tabIsHidden("usages")
    },
    {
      value: "analytics",
      label: "Analytics",
      href: "analytics",
      icon: AnalyticsOutlined,
      hidden: tabIsHidden("analytics")
    },
    {
      value: "invoices",
      label: "hoá đơn dịch vụ",
      href: "invoices",
      icon: ReceiptIcon,
      hidden:
        data?.service_referrer_type === "Reducer::Overide::Service" ||
        !activeTab({ collections: serviceCollections, feature: "service", action: "service-invoice-index" }),
    },
  ]);

  return (
    <React.Fragment>
      <MuiScrollTabs
        prefix={`services/${service_id}`}
        initialTabs={INITIAL_SERVICE_TABS?.filter((tab) => !tab.hidden)}
      />
    </React.Fragment>
  );
};

export default withSuspense(ServiceTabs, MuiSkeleton["ScrollTabs"]);
