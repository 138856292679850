import DatacenterBandwidthApi from "@/apis/beta/Warehouse/DatacenterBandwidthApi";
import { format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { format_pricing_vnd } from "@/libs/constants";
import Cell from "@components/DataGrid/Cell";
import DataGridProCustomize from "@components/DataGridProCustomize";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { AddOutlined } from "@mui/icons-material";
import { Box, Button, Link, Paper, Typography } from "@mui/material";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../Header";


const Datacenter = () => {
    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 20
    });

    const { data } = DatacenterBandwidthApi.index({
        page: page?.page + 1 || 1,
        pageSize: page?.pageSize || 20,
        query: "",
        searchQuery: ""
    })


    const initialColumns = [
        {
            field: 'name',
            headerName: 'Tháng',
            renderCell: ({ value, row }) => {
                if (row.group.length === 1) {
                    return (
                        <Link component={NavLink} to={`/warehouse/bandwidths/datacenters/${row?.datacenter_bandwidth_id}`} >
                            <Typography className='react-link-table'>
                                {value}
                            </Typography>
                        </Link>
                    )
                } else {
                    <Cell value={`${value}`} />
                }
            },
            sortable: false,
            width: 150,
        },
        {
            field: 'item_name',
            headerName: 'IDC',
            sortable: false,
            width: 300,
        },
        {
            field: 'bw_commit',
            headerName: 'BW COMMIT (Gbps)',
            sortable: false,
            width: 200,
        },
        {
            field: 'bandwidth',
            headerName: 'Bandwidth (Gbps)',
            sortable: false,
            width: 200,
        },
        {
            field: 'amount',
            headerName: 'Tổng Tiền',
            sortable: false,
            width: 200,
            valueGetter: ({ value }) => format_pricing_vnd(value)
        },
        {
            field: 'range_date',
            headerName: 'Thời gian',
            sortable: false,
            width: 250,
            valueGetter: ({ row }) => (row?.date_start && row?.date_end) ? `${format_date_short(row?.date_start)} - ${format_date_short(row?.date_end)}` : ""
        }

    ]

    const reFormatData = React.useCallback(() => {
        let newData = []
        data?.datacenter_bandwidths.forEach((item, index) => {
            let datacenters = []
            let bandwidth = 0;
            let bw_commit = 0;
            let amount = 0;
            item.datacenter_bandwidth_items.forEach((ii, index_ii) => {
                datacenters.push(ii?.datacenter_bandwidth_item_name)
                bandwidth = bandwidth + ii?.datacenter_bandwidth_item_total
                bw_commit = bw_commit + ii?.datacenter_bandwidth_item_commit
                amount = amount + ii?.datacenter_bandwidth_item_amount
                newData.push(
                    {
                        id: `${item?.datacenter_bandwidth_id}.${index_ii}`,
                        group: [`${item?.datacenter_bandwidth_id}`, `${index_ii + 1}`],
                        name: item?.datacenter_bandwidth_name,
                        item_name: ii?.datacenter_bandwidth_item_name,
                        bw_commit: ii?.datacenter_bandwidth_item_commit,
                        bandwidth: ii?.datacenter_bandwidth_item_total,
                        amount: ii?.datacenter_bandwidth_item_amount,
                        datacenter_bandwidth_id: item?.datacenter_bandwidth_id,
                        date_start: null,
                        date_end: null,
                    }
                )
            })

            newData.push(
                {
                    id: item?.datacenter_bandwidth_id,
                    group: [`${item?.datacenter_bandwidth_id}`],
                    name: item?.datacenter_bandwidth_name,
                    item_name: datacenters.join(" , "),
                    bw_commit: bw_commit,
                    bandwidth: bandwidth,
                    amount: amount,
                    datacenter_bandwidth_id: item?.datacenter_bandwidth_id,
                    date_start: item?.datacenter_bandwidth_date_start,
                    date_end: item?.datacenter_bandwidth_date_end,
                }
            )
        })
        return newData
    }, [data?.datacenter_bandwidths, page])

    const onPaginationModelChange = (model) => {
        setPage(model)
    }

    const navigate = useNavigate();

    return (
        <Box >
            <Header />
            <Button
                variant='contained'
                size='small'
                sx={{
                    height: { xs: 36, md: 42 },
                    mt: 2,
                    mb: 2
                }}
                onClick={() => {
                    navigate("/warehouse/bandwidths/datacenters/new")
                }}
                startIcon={<AddOutlined />}
            >
                Thêm Bandwidth Đối Soát IDC
            </Button>
            <Paper>
                <DataGridProCustomize
                    rows={reFormatData()}
                    columns={initialColumns}
                    paginationMode="server"
                    pagination
                    treeData
                    defaultGroupingExpansionDepth={0}
                    initialState={{
                        pagination: { paginationModel: { pageSize: page?.pageSize } },
                    }}
                    pageSize={page?.pageSize || 20}
                    pageSizeOptions={[10, 20, 50, 100]}
                    getTreeDataPath={(row) => row?.group}
                    onPaginationModelChange={onPaginationModelChange}
                    page={page?.page || 0}
                    rowCount={data?.pagination?.total}
                />

            </Paper>
        </Box >
    )
}
export default withSuspense(withPermission(Datacenter, {
    feature: "warehouse",
    action: "bandwidth-datacenter"
}), MuiSkeleton["DataGrid"]) 