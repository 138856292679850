import { useEffect, useState } from "react";
import useDebounce from "./useDebounce";
import { useNavigate, useSearchParams } from "react-router-dom";
import qs from "query-string";
import { DEFAULT_OPS, Filter } from "./useFilters";

const useDataGridParams = (
  initialColumns,
  pageSize = 20,
  filterKey = "",
  disableSearchPageParams = false,
  resetPage = null,
  addParams = null
) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [page, setPage] = useState({
    page: Number(searchParams.get("page")) || 0,
    pageSize,
  });
  const [columns, setColumns] = useState(initialColumns);
  const [searchQuery, setSearchQuery] = useState("");
  const [query, updateQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (disableSearchPageParams) return;
    const search = [
      addParams,
      page ? qs.stringify(page) : null,
      debouncedSearchQuery ? qs.stringify({ q: debouncedSearchQuery }) : null,
      searchParams.get("path")
        ? qs.stringify({ path: searchParams.get("path") })
        : null,
      // query,
      typeof (query) === "string" ? query : !query ? "" : Filter.stringify(query),
    ]
      .filter((item) => item)
      .join("&");

    navigate(`?${search}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([page, debouncedSearchQuery, query, addParams])]);

  useEffect(() => {
    if (!filterKey) return;

    if (
      !localStorage.getItem(filterKey) ||
      localStorage.getItem(filterKey) === "null"
    ) {
      try {
        localStorage.removeItem(filterKey);
      } catch (error) {
        console.warn('Remove key in localStorage failed with key = ' + filterKey)
      }
      return;
    }

    const search = decodeURIComponent(
      escape(atob(localStorage.getItem(filterKey) || ""))
    );

    const filter = Filter.parse(qs?.parse(search, DEFAULT_OPS))?.filters || {};
    updateQuery(filter);
  }, [filterKey]);

  useEffect(() => {
    if (!query) return;
    if (
      debouncedSearchQuery.trim()?.length <= 0 &&
      Object?.keys(query)?.length <= 0
    )
      return;

    setPage({
      page: 0,
      pageSize,
    });
    if (resetPage) resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, query]);
  return {
    page,
    setPage,
    columns,
    setColumns,
    searchQuery,
    setSearchQuery,
    debouncedSearchQuery,
    disableSearchPageParams,
    query,
    updateQuery,
  };
};

export default useDataGridParams;
