import ClusterService from "@components/Templates/ClusterService(k8s)/ServiceView";
import VNIS from "@components/Templates/VNIS/ServiceView";
import CloudService from "./CloudService";
import DeviceLiquidation from "./DeviceLiqudation";
// import EmailService from "./EmailService";
import HostingService from "./HostingService";
import SwiftCDNService from "@components/Templates/SwiftFederation/ContentDeliveryNetwork(CDN)/ServiceView";
import SwiftStorageService from "@components/Templates/SwiftFederation/Storage(LFD)/ServiceView";
import SwiftTransService from "@components/Templates/SwiftFederation/Trans(VMS-LMS)/ServiceView";
import DomainPAService from "@components/Templates/Domain/PAVietnam/ServiceView";
import SSLService from "@components/Templates/SSL/ServiceView";
import EmailService from "@components/Templates/EmailService/ServiceView";
import ServiceVPS from "@components/Templates/VPS(CloudServer)/ServiceView"


const Templates = {
  CloudService: ServiceVPS,
  ColocationService: CloudService,
  VnisService: VNIS,
  // EmailService: EmailService,
  HostingService: HostingService,
  DeviceLiquidation: DeviceLiquidation,
  DedicatedService: DeviceLiquidation,
  VBMService: DeviceLiquidation,
  // DomainService: DomainService,
  // SSLService: DomainService,
  ClusterService: ClusterService,
  ConversantService: SwiftCDNService,
  StorageLFDService: SwiftStorageService,
  TranscodingService: SwiftTransService,
  TransmuxingService: SwiftTransService,
  RecordingService: SwiftTransService,
  DomainService: DomainPAService,
  SSLService: SSLService,
  EmailService: EmailService,
};

export default Templates;
