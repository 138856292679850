import ServicePriceApi from '@/apis/beta/Service/ServicePriceApi'
import { format_date_short, queryClientRefetchScope } from '@/functions'
import { useNotificationContext } from '@contexts/notification'
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useMemo } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import SWITCH_CDN_COMMIT from './Commit'

export const BANDWIDTHS = {
    global: 'Băng thông quốc tế',
    local: 'Băng thông trong nước',
    tw: 'Taiwan',
    cn: 'China'
}

export const translates = (name = '') => {
    return BANDWIDTHS[name]
}

const Price = ({ serviceId = -1, commitments = [] }) => {
    const queryClient = useQueryClient()
    const { setNotification } = useNotificationContext()
    const { mutateAsync, isLoading } = useMutation(ServicePriceApi.ChangePackageConfig)

    const methods = useForm({
        defaultValues: useMemo(() => {
            return {
                service_configoptions: commitments?.map((pack) => {
                    const commit = pack?.commitment || undefined
                    return {
                        unit: pack?.unit,
                        name: pack?.name,
                        id: pack?.id,
                        commitment: {
                            ...(_.pick(commit, ['id', 'commitment_available', 'prepaid', 'value', 'pricing_apply', 'pricing_amount', 'term'])),
                            startdate: !!commit?.startdate ? dayjs(commit?.startdate) : null,
                            enddate: !!commit?.enddate ? dayjs(commit?.enddate) : null,
                        }
                    }
                })
            }
        }, [commitments])
    })

    const { fields, update } = useFieldArray({
        control: methods.control,
        name: "service_configoptions",
    });

    const rebuildServicePackageConfigs = (packageConfigs = []) => {
        return packageConfigs?.map((configoption) => {
            return ({
                service_configoption_id: configoption?.id || configoption?.service_configoption_id || -1,
                name: configoption?.name || 'Unknown',
                method: configoption?.method || 'tierd',
                package_config: {
                    service_commitment_configure_id: configoption?.commitment?.service_commitment_configure_id,
                    value: configoption?.commitment?.value || 0,
                    pricing_apply: configoption?.commitment?.pricing_apply || 0,
                    pricing_amount: configoption?.commitment?.pricing_amount || 0,
                    commitment_available: configoption?.commitment?.commitment_available || 0,
                    startdate: format_date_short(configoption?.commitment?.startdate?.$d) || format_date_short(configoption?.commitment?.startdate),
                    enddate: format_date_short(configoption?.commitment?.enddate?.$d) || format_date_short(configoption?.commitment?.enddate),
                    unit: 'gb'
                }
            })
        })
    }

    const onSubmit = async (values) => {
        const response = await mutateAsync({
            serviceId,
            data: rebuildServicePackageConfigs(values?.service_configoptions || [])
        })
        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: 'error' })
        }
        queryClientRefetchScope(queryClient, 'service')
        return setNotification({
            open: true, message: 'Thay đổi gói cố định thành công', severity: 'success'
        })
    }
    return (
        <FormProvider {...methods}>
            <Box
                component="form"
                onSubmit={methods.handleSubmit(onSubmit)}
                sx={{
                    p: 2,
                    border: '1px solid rgba(0,0,0,0.18)',
                    borderRadius: '4px'
                }}>
                <Typography sx={{ fontWeight: 'medium', mb: 1.25 }}>
                    Gói Cố Định
                </Typography>
                {fields?.map((field, index) => {
                    return (
                        <Grid container spacing={2} key={field.id} mb={2}>
                            {index !== 0 && <Grid item xs={12}>
                                <Divider light sx={{ mr: { xs: 0, md: 6.75 } }} />
                            </Grid>}
                            <Grid item xs={12} md={3}>
                                <Typography
                                    color="text.grey"
                                    sx={{
                                        fontSize: 14,
                                    }}>
                                    {translates(field?.name)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={12} md={2.5} xl={2.5}>
                                        <SWITCH_CDN_COMMIT.Prepaid index={index} />
                                    </Grid>
                                    <Grid item xs={12} md={3} xl={3}>
                                        <SWITCH_CDN_COMMIT.Committed index={index} field={field} update={update} />
                                    </Grid>
                                    <Grid item xs={12} md={3} xl={3}>
                                        <SWITCH_CDN_COMMIT.Price index={index} />
                                    </Grid>
                                    <Grid item xs={12} md={3} xl={3}>
                                        <SWITCH_CDN_COMMIT.PriceSubtotal index={index} />
                                    </Grid>
                                    <Grid item xs={12} md={5.5} />
                                    <Grid item xs={12} md={6}>
                                        <SWITCH_CDN_COMMIT.Dates index={index} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} />
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={11.5}>
                                {methods?.formState?.isDirty && <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}>
                                    <Button
                                        disabled={isLoading}
                                        startIcon={isLoading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                                        type='submit'
                                        variant='contained'
                                        size='small'
                                    >
                                        Thay Đổi Gói
                                    </Button>
                                </Box>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
    )
}

export default Price