import WarehouseTypeApi from "@/apis/beta/Warehouse/WarehouseTypeApi";
import { Box, Button } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import Information from "./Information";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import TableModel from "./TableModel";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import PaperLoading from "../../Shared/Loading/PaperLoading";

const TypesDetail = () => {
  const params = useParams();

  const {
    data: { warehouse_type },
    isLoading,
  } = WarehouseTypeApi.Show(params?.id);

  const [openDrawer, setOpenDrawer] = React.useState(false);

  if (isLoading) return <PaperLoading />;

  return (
    <Box>
      <Button
        variant="contained"
        size="small"
        onClick={() => setOpenDrawer(true)}
      >
        Thêm Model
      </Button>

      <Information warehouse_type={warehouse_type} />

      <TableModel
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        type_id={params?.id}
        warehouse_type={warehouse_type}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(TypesDetail, {
    feature: "warehouse",
    action: "types-detail",
  }),
  MuiSkeleton["DataGrid"]
);
