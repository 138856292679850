import WarehouseBoxItemApi from "@/apis/beta/Warehouse/WarehouseBoxItemApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseOwnerApi from "@/apis/beta/Warehouse/WarehouseOwnerApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { WAREHOUSE_STATUS_BUY } from "@/libs/constants";
import HeaderTitle from "@components/Title/HeaderTitle";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router";
import HistoryProcess from "./HistoryProcess";
import InformationItem from "./InfomationItem";
import TableChild from "./TableChild";
import ItemLogs from "./ItemLogs";

const ItemsDetail = () => {
  const params = useParams();

  const { setNotification } = useNotificationContext();

  const { setValue, control, watch } = useForm({
    defaultValues: {
      warehouse_owner_name: "",
      warehouse_item_status: "",
      warehouse_item_availability: "",
      warehouse_box_item_description: "",
    },
  });

  const {
    data: { warehouse_item },
    refetch: refetch_item,
    isFetching: warehouse_item_fetch,
  } = WarehouseItemApi.Detail(params?.id);

  const {
    data: warehouse_owners,
    refetch: refetchOwner,
    isFetching: warehouse_owners_fetch,
  } = WarehouseOwnerApi.getAll();

  const { data: warehouse_item_logs, refetch: refetch_item_logs } = WarehouseItemApi.GetLogs({ warehouse_item_id: warehouse_item?.warehouse_item_id })

  const onChangeOwner = async (value) => {
    await WarehouseItemApi.Update(warehouse_item?.warehouse_item_id, {
      warehouse_owner_name: value,
    }).then((response) => {
      const data = response.data;
      if (data.status !== 200) {
        setNotification({
          open: true,
          message: data.errors[0].message,
          severity: "error",
        });
        return;
      }
      refetch();
      setNotification({
        open: true,
        message: "Cập nhật thành công.",
        severity: "success",
      });
      refetchOwner();
    });
  };

  const onChangeStatus = async (value) => {
    const from = {
      warehouse_item_status: value,
    };
    if (value === 3) {
      from.warehouse_item_availability = true;
    }

    await WarehouseItemApi.Update(warehouse_item?.warehouse_item_id, from).then(
      (response) => {
        const data = response.data;
        if (data.status !== 200) {
          setNotification({
            open: true,
            message: data.errors[0].message,
            severity: "error",
          });
          return;
        }
        refetch();
        setNotification({
          open: true,
          message: "Cập nhật thành công.",
          severity: "success",
        });
        refetchOwner();
      }
    );
  };

  const changeUpdateDescription = async (value) => {
    await WarehouseBoxItemApi.Update(
      warehouse_item?.warehouse_box_item?.warehouse_box_item_id,
      { warehouse_box_item_description: value }
    ).then((response) => {
      const data = response.data;
      if (data.status !== 200) {
        setNotification({
          open: true,
          message: data.errors[0].message,
          severity: "error",
        });
        return;
      }
      setNotification({
        open: true,
        message: "Cập nhật ghi chú thành công.",
        severity: "success",
      });
      refetch();
    });
  };

  const { mutateAsync: updateOwner, isLoading: updateOwnerLoading } =
    useMutation(onChangeOwner);

  const { mutateAsync: updateStatus, isLoading: updateStatusLoading } =
    useMutation(onChangeStatus);

  const {
    mutateAsync: updateDescription,
    isLoading: updateDescriptionLoading,
  } = useMutation(changeUpdateDescription);

  const navigate = useNavigate();

  useEffect(() => {
    setValue(
      "warehouse_box_item_description",
      warehouse_item?.warehouse_box_item?.warehouse_box_item_description
    );

    setValue("warehouse_item_status", warehouse_item?.warehouse_item_status);

    setValue(
      "warehouse_owner_name",
      warehouse_owners?.find(
        (item) =>
          item?.warehouse_owner_id === warehouse_item?.warehouse_owner_id
      )?.label
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse_item, warehouse_owners]);

  const isUpdating =
    updateStatusLoading || updateOwnerLoading || updateDescriptionLoading;

  const isFetching = warehouse_item_fetch || warehouse_owners_fetch;
  const refetch = () => {
    refetch_item_logs()
    refetch_item()
  }
  return (
    <Box>
      <HeaderTitle>Thông tin chi tiết</HeaderTitle>

      <Paper
        sx={{
          p: 4,
          mt: 2,
          display: "flex",
          gap: 4,
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {isUpdating || isFetching ? (
          <CircularProgress size={20} />
        ) : (
          <KeyboardBackspaceOutlinedIcon onClick={() => navigate(-1)} />
        )}
        <Typography>
          {isUpdating || isFetching ? "Loading..." : "Trở về trang trước"}
        </Typography>
      </Paper>

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xl={9} lg={9} md={9} sm={6} xs={12}>
            <Paper sx={{ p: 2 }}>
              <InformationItem warehouse_item={warehouse_item} />
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <Typography sx={{ mb: 0.5, color: "#777680" }}>
                      Mô Tả/ Ghi chú{" "}
                    </Typography>
                    <Controller
                      control={control}
                      name="warehouse_box_item_description"
                      render={({ field: { onBlur, ...field } }) => (
                        <TextField
                          multiline
                          {...field}
                          onBlur={(e) => {
                            const value = watch(
                              "warehouse_box_item_description"
                            );

                            if (
                              value?.trim() !==
                              warehouse_item?.warehouse_box_item
                                ?.warehouse_box_item_description
                            )
                              updateDescription(value);
                          }}
                          disabled={isUpdating}
                          placeholder="Nhập thông tin ghi chú..."
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Box width="100%">
                    <Typography sx={{ color: "#777680" }}>Sở Hữu:</Typography>
                    <Controller
                      control={control}
                      name="warehouse_owner_name"
                      render={({ field: { onChange, ...field } }) => (
                        <Select
                          {...field}
                          onChange={(e) => {
                            const value = e?.target?.value;
                            onChange(value);
                            updateOwner(value);
                          }}
                          fullWidth
                          disabled={isUpdating}
                          size="small"
                          sx={{ width: 200, mt: 1 }}
                        >
                          {warehouse_owners?.map((item) => (
                            <MenuItem key={item?.value} value={item?.value}>
                              {item?.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Box width="100%">
                    <Typography sx={{ color: "#777680" }}>
                      Trình Trạng:
                    </Typography>

                    <Controller
                      control={control}
                      name="warehouse_item_status"
                      render={({ field: { onChange, ...field } }) => (
                        <Select
                          {...field}
                          onChange={(e) => {
                            const value = e?.target?.value;
                            onChange(value);
                            updateStatus(value);
                          }}
                          fullWidth
                          disabled={isUpdating}
                          size="small"
                          sx={{ width: 200, mt: 1 }}
                        >
                          {WAREHOUSE_STATUS_BUY?.map((item) => {
                            return (
                              <MenuItem key={item?.value} value={item?.value}>
                                {item?.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TableChild
                    refetchItem={() => {
                      refetch()
                    }
                    }
                    warehouse_item={warehouse_item}
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ItemLogs
                    data={warehouse_item_logs}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Paper sx={{ p: 2, height: "100%" }}>
              <HistoryProcess
                warehouse_processes={warehouse_item?.warehouse_processes}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withSuspense(
  withPermission(ItemsDetail, {
    feature: "warehouse",
    action: "items-detail",
  }),
  MuiSkeleton["GridInformation"]
);
