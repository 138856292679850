import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import queryString from "query-string";
import { Controller, useForm } from "react-hook-form";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import withSuspense from "@/hocs/withSuspense";

const AddCords = ({ open, onClose, onChange, params }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      warehouse_model_id: "",
      total_quantity: 0,
      total: 0,
    },
  });

  const { data, isLoading } = useQueryWithCustomized(
    [`get-cords-${JSON.stringify(params)}`],
    () => WarehouseItemApi.Cords(queryString.stringify({ ...params }))
  );

  const { mutateAsync: FindCords, isLoading: FindCordsLoading } =
    useMutationWithNotification(WarehouseItemApi.FindCords);

  const handleAddCord = async (values) => {
    const response = await FindCords({
      id: values?.warehouse_model_id,
      paramsQueryString: queryString?.stringify(
        params
          ? params
          : {
            warehouse_box_item_reference_id: 1,
            warehouse_box_item_reference_type: "WarehouseBox",
          }
      ),
    });

    if (!response?.error) {
      onChange &&
        onChange({ ...response?.warehouse_item, total: Number(values?.total) });
      onClose();
    }
  };

  const warehouse_model_id = watch("warehouse_model_id");

  useEffect(() => {
    if (!data) return;

    setValue(
      "warehouse_model_id",
      data?.warehouse_items?.[0]?.warehouse_model_id
    );

    if (warehouse_model_id) {
      setValue(
        "total_quantity",
        data?.warehouse_items?.find(
          (item) => item?.warehouse_model_id === warehouse_model_id
        )?.total || 0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, warehouse_model_id]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Thêm Dây</DialogTitle>
      <Box component="form" onSubmit={handleSubmit(handleAddCord)}>
        <DialogContent>
          <Box>
            <Controller
              control={control}
              name="warehouse_model_id"
              render={({ field }) => (
                <FormControl fullWidth>
                  <Select
                    {...field}
                    size="small"
                    fullWidth
                    disabled={isLoading}
                  >
                    {data?.warehouse_items?.map((item) => (
                      <MenuItem
                        key={item?.warehouse_model_id}
                        value={item?.warehouse_model_id}
                      >
                        {item?.warehouse_model_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="total_quantity"
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  fullWidth
                  size="small"
                  sx={{ mt: 2 }}
                  disabled
                  label="Số lượng còn lại"
                />
              )}
            />

            <Controller
              control={control}
              name="total"
              rules={{
                required: "Vui lòng nhập số lượng",
                min: { value: 1, message: "Số lượng phải lớn hơn 1" },
                validate: (value) => {
                  return parseInt(value) > parseInt(watch("total_quantity"))
                    ? "Số lượng lấy lớn hơn số lượng còn lại trong kho"
                    : true;
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  fullWidth
                  size="small"
                  sx={{ mt: 2 }}
                  label="Số lượng lấy *"
                  error={errors?.total}
                  helperText={errors?.total?.message}
                />
              )}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            size="small"
            type="submit"
            disabled={FindCordsLoading}
            startIcon={FindCordsLoading && <CircularProgress size={14} />}
          >
            Thêm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default withSuspense(AddCords, MuiSkeleton["LinearProgress"]);
