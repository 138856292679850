import { BaseComponents } from '@components/Components'
import ErrorHelperText from '@components/shared/ErrorHelperText'
import { FormControl } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const TicketCustomer = ({ locked = false }) => {
    const { control, formState: { errors } } = useFormContext()

    return (
        <Controller
            name="customer_id"
            rules={{ required: "This field is required" }}
            control={control}
            render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                    <BaseComponents.Customer
                        name="customer_id"
                        disabled={locked}
                        onChange={onChange}
                        value={value}
                    />

                    {errors?.customer_id && (
                        <ErrorHelperText
                            message={errors?.customer_id?.message}
                        />
                    )}
                </FormControl>
            )}
        />
    )
}

export default TicketCustomer