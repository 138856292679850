import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import React from "react";
import _ from "lodash";
import { format_pricing_vnd } from "@/libs/constants";
import { format_date_short } from "@/functions";
import { Box, Button, Link, Typography } from "@mui/material";
import CellConfig from "@components/DataGrid/CellConfig2";
import { NavLink } from "react-router-dom";

const QuickViewWarehouseItem = ({data, handleClose})=> { 
    const {data: warehouse_boxes } = WarehouseBoxApi.getAll()
    const { data: { warehouse_item }, refetch } = WarehouseItemApi.Show(data?.warehouse_item_id);

    const collectWarehouseItemData = () => {
        return [
          { label: "Service Tag / Serial Number", value: warehouse_item?.warehouse_item_service_tag },
          { label: "Tên Thiết Bị", value: warehouse_item?.warehouse_item_name },
          { label: "Kích Thước", value: `${warehouse_item?.warehouse_item_form_factor} U` },
          { label: "Datacenter", value: _.find(warehouse_boxes, {warehouse_box_id: warehouse_item.warehouse_rack?.warehouse_box_id})?.label},
          { label: "Tủ Rack", value: warehouse_item?.warehouse_rack?.warehouse_rack_name },
          { label: "Vị Trí", value: 
          (warehouse_item.warehouse_box_item && warehouse_item.warehouse_box_item.warehouse_box_item_reference_type === "WarehouseRack" && warehouse_item.warehouse_rack ) ? 
          warehouse_item.warehouse_box_item?.warehouse_box_item_position + (( warehouse_item.warehouse_item_form_factor === 0 || warehouse_item.warehouse_item_form_factor === 1) ? "" :` - ${warehouse_item.warehouse_box_item?.warehouse_box_item_position + (warehouse_item.warehouse_item_form_factor - 1)}` )
          : ""
          },
          { label: "Giá Nhập", value: format_pricing_vnd(warehouse_item?.warehouse_item_price) },
          { label: "Ngày Nhập", value: format_date_short(warehouse_item?.warehouse_item_import_at) },
      ]
  }

    return (
        <Box sx={{ height: "100vh", width: 450, p: 3 }}>
            <Typography sx={{
                textTransform: "uppercase",
                mb: 2
            }}>
                Thông Tin Thiết Bị
            </Typography>
            {collectWarehouseItemData()?.map((item, index) =>
                <Box key={index} sx={{ display: 'flex', mb: { xs: 1, md: 2 } }}>
                    <Typography sx={{ color: "#9e9e9e", minWidth: '50%' }}>
                        {item?.label}
                    </Typography>
                    <Typography sx={{ width: '100%' }}>
                        {item?.value}
                    </Typography>
                </Box>
            )}
            {warehouse_item?.capacity && <>
                <CellConfig configures={warehouse_item?.capacity} />
            </>}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button sx={{ height: 36 }} variant="text" onClick={handleClose}>
                    Đóng
                </Button>
                <Link
                    component={NavLink}
                    to={`/warehouse/items/${warehouse_item.warehouse_item_id}/detail`}
                >
                    <Button
                        sx={{ height: 36, ml: 2 }} variant="outlined" size='small'>
                        Xem Chi Tiết Thiết Bị
                    </Button>
                </Link>
               
            </Box>
        </Box>
    )
}
export default QuickViewWarehouseItem;