import WarehouseGroupApi from "@/apis/beta/Warehouse/WarehouseGroupApi";
import WarehouseTypeApi from "@/apis/beta/Warehouse/WarehouseTypeApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { WAREHOUSE_TYPE_UNIT } from "@/libs/constants";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

const CreateNewTypes = ({ open, handleClose, refetch, warehouse_types }) => {
  const { data, isFetching: warehouse_group_fetch } = WarehouseGroupApi.List();

  const { mutateAsync, isLoading, notify } = useMutationWithNotification(
    WarehouseTypeApi.Create
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      warehouse_group_id: "",
      warehouse_type_parent: 0,
      warehouse_type_unit: "",
      warehouse_type_name: "",
    },
  });

  useEffect(() => {
    setValue("warehouse_group_id", data?.[0]?.value);
    setValue("warehouse_type_unit", WAREHOUSE_TYPE_UNIT?.[0]?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isFetching = warehouse_group_fetch;

  const onSubmit = async (values) => {
    const response = await mutateAsync(values);

    if (!response.errors) notify("success", "Tạo mới thể loại thành công");
    refetch();
    handleClose();
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Box p={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Typography fontWeight="600" variant="h5" textAlign="center">
          Thêm thể loại mới
        </Typography>

        <Box mt={2} display="flex" flexDirection="column">
          <Controller
            control={control}
            name="warehouse_group_id"
            render={({ field }) => (
              <FormControl>
                <Typography>Chọn nhóm</Typography>
                <Select
                  {...field}
                  size="small"
                  sx={{ width: 300, mt: 1 }}
                  disabled={isFetching}
                >
                  {data?.map((item) => (
                    <MenuItem value={item?.value} key={item?.value}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="warehouse_type_parent"
            render={({ field }) => (
              <FormControl sx={{ mt: 2 }}>
                <Typography>Chọn thể loại cha</Typography>
                <Select
                  {...field}
                  size="small"
                  sx={{ width: 300, mt: 1 }}
                  disabled={isFetching}
                >
                  <MenuItem value={0}>Không có --</MenuItem>
                  {warehouse_types
                    ?.filter((item) => item?.warehouse_type_parent === 0)
                    ?.map((item) => (
                      <MenuItem
                        value={item?.warehouse_type_id}
                        key={item?.warehouse_type_id}
                      >
                        {item?.warehouse_type_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="warehouse_type_unit"
            render={({ field }) => (
              <FormControl sx={{ mt: 2 }}>
                <Typography>Đơn vị</Typography>
                <Select {...field} size="small" sx={{ width: 300, mt: 1 }}>
                  {WAREHOUSE_TYPE_UNIT?.map((item) => (
                    <MenuItem value={item?.value} key={item?.value}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="warehouse_type_name"
            rules={{ required: { message: "Bắt buộc", value: true } }}
            render={({ field }) => (
              <FormControl sx={{ mt: 2 }}>
                <Typography>Tên loại</Typography>
                <TextField
                  {...field}
                  size="small"
                  sx={{ mt: 1 }}
                  error={!!errors?.warehouse_type_name}
                  helperText={errors?.warehouse_type_name?.message}
                />
              </FormControl>
            )}
          />

          <Button
            fullWidth
            size="small"
            type="submit"
            sx={{ mt: 2 }}
            variant="contained"
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={14} />}
          >
            Thêm Loại
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CreateNewTypes;
