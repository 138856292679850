import ProductApi from '@/apis/beta/Product/ProductApi';
import { buildQueryFilterCondition, format_date_short } from '@/functions';
import { UNLIMITED } from '@/libs/constants';
import { useStateArrayContext } from '@contexts/stateArray';
import { Box, Button, Collapse, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import DataProducts from './DataProducts';
import Product from './Product';
import ServiceBilling from './ServiceBilling';

const modules = {
    ProductVnis: Product
}

const MlyticService = ({
    closer,
    saver
}) => {

    const [inserting, setInserting] = useState(false)
    const {
        state: products,
        update,
        insert,
        removeIndex,
        clear
    } = useStateArrayContext()
    const [billing, setBilling] = useState({
        service_billing_startdate: moment(),
        service_billing_enddate: moment(),
        service_nextbill_startdate: moment(),
        service_nextbill_enddate: moment(),
        service_control_startdate: moment(),
        service_control_enddate: moment(),
    })


    const MLYTIC_SCOPES = [
        {
            module: "ProductVnis",
            queries: buildQueryFilterCondition('$and', 0,
                [
                    { name: "product_groups.product_group_id", operator: "$eq", value: 15 },
                    { name: "product_type", operator: "$eq", value: "package" },
                    { name: "tw_products.product_hidden", operator: "$eq", value: 0 },
                ]),
            props: {
                label: "Chọn gói VNIS (*)",
                onSelect: (payload) => {
                    clear()
                    onSelect(payload, 'service')
                }
            }
        },
        {
            module: "ProductVnis",
            queries: buildQueryFilterCondition('$and', 0,
                [
                    { name: "product_groups.product_group_id", operator: "$eq", value: 21 },
                    { name: "product_type", operator: "$ne", value: "node" },
                    { name: "product_type", operator: "$contains", value: 'vnis' },
                    { name: "tw_products.product_hidden", operator: "$eq", value: 0 },
                    {
                        name: 'tw_products.product_id',
                        operator: '$nin',
                        value: products?.filter(product => product?.service_type !== 'service')?.map(product => product?.product_id)?.join(',')
                    }
                ]),
            props: {
                label: "VNIS Addons",
                disabled: products?.length === 0,
                onSelect: (payload) => onSelect(payload, 'addon')
            }
        }
    ]
    const onSelect = async (payload, scope = 'service') => {
        if (!payload || !payload?.product_id) return;
        setInserting(true)
        const productVnis = await ProductApi.GetProduct({ id: payload?.product_id })
        const productInformations = productVnis?.product?.product_informations || []
        if (!!productVnis?.product) {
            insert({
                ...productVnis?.product,
                product_value: 1,
                product_price: productVnis?.product?.product_pricing_configures?.pricing,
                service_price_exceeds: productVnis?.product?.price_exceed_ranges || [{ start: 0, end: UNLIMITED, pricing: 0 }],
                payment_config_method: 'tierd',
                service_type: scope
            })
        }
        if (scope === 'service') {
            const PRODUCT_VNIS_ADDON_IDS = [395, 397, 396, 398]
            await Promise.all(PRODUCT_VNIS_ADDON_IDS.map(async (id) => {
                const temp = await ProductApi.GetProduct({ id })
                insert({
                    ...temp?.product,
                    product_value: 0,
                    product_informations: productInformations?.find((item) => temp?.product?.product_type === item?.product_infomation_type) || [],
                    product_price: temp?.product?.product_pricing_configures?.pricing,
                    service_price_exceeds: (temp?.product?.price_exceed_ranges && temp?.product?.price_exceed_ranges?.length > 0) ?
                        temp?.product?.price_exceed_ranges : [{ start: 0, end: UNLIMITED, pricing: 0 }],
                    payment_config_method: 'tierd',
                    service_type: 'addon',
                    service_unit: productInformations?.find((item) => temp?.product?.product_type === item?.product_infomation_type)?.warehouse_capacity_unit || 'qty',
                })
            }));
        }
        setInserting(false)
    }

    const rebuilder = (data = [], billing = {}) => {
        return data?.map((product) => {
            return {
                ...product,
                service_management_billing: billing
            }
        })
    }
    const rebuildServiceCluster = () => {
        let serviceBilling = {
            service_billing_startdate: format_date_short(
                billing?.service_billing_startdate?.$d ||
                billing?.service_billing_startdate
            ),
            service_billing_enddate: format_date_short(
                billing?.service_billing_enddate?.$d
            ),
            service_nextbill_startdate: format_date_short(
                billing?.service_nextbill_startdate?.$d
            ),
            service_nextbill_enddate: format_date_short(
                billing?.service_nextbill_enddate?.$d
            ),
            service_control_startdate: format_date_short(
                billing?.service_control_startdate?.$d
            ),
            service_control_enddate: format_date_short(
                billing?.service_control_enddate?.$d
            )
        };
        try {
            let vnisService = {
                ...products?.find((product) => product?.service_type === 'service'),
                service_management_billing: serviceBilling,
                product_addons: rebuilder(products?.filter((product) => product?.service_type !== 'service'), serviceBilling),
            }
            return vnisService

        } catch (error) {
            return {}
        }
    }

    const ok = () => {
        saver(rebuildServiceCluster())
        closer()
        clear()
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', gap: 1.25 }}>
                {MLYTIC_SCOPES.map((scope) => {
                    const Component = modules[scope?.module] || undefined
                    if (!Component) return null
                    const componentProps = {
                        ...scope,
                        ...scope?.props
                    }
                    return <Component {...componentProps} />
                })}
            </Box>

            <Typography sx={{ fontSize: 14, mt: 1.25, fontWeight: 'medium', mb: 0.75 }}>
                {products?.find((product) => product?.service_type === 'service')?.product_name}
            </Typography>
            <DataProducts products={products} remove={removeIndex} update={update} isFetching={inserting} />
            <Collapse in={products && products?.length > 0}>
                <ServiceBilling billing={billing} setBilling={setBilling} />
            </Collapse>
            <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end', gap: 2 }}>
                <Button size='small'
                    onClick={() => {
                        clear()
                        closer()
                    }}
                >
                    Huỷ Thao Tác
                </Button>
                <Button size='small' variant='outlined' onClick={ok}>
                    Thêm Sản Phẩm
                </Button>
            </Box>
        </Box>
    )
}

export default MlyticService