import { useNotificationContext } from "@/contexts/notification";
import {
  convertMonthToBillingCycle,
  format_date_short,
  queryClientRefetcher,
} from "@/functions";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import ProductAddon from "./ProductAddon";
import ServiceAddonQty from "./ServiceAddonQty";
import ServiceAmount from "./ServiceAmount";
import ServiceBilling from "./ServiceBilling";
import ServiceNextBill from "./ServiceNextBill";
import ServicePricing from "./ServicePricing";
import ServicePricingApply from "./ServicePricingApply";
import ServicePricingMinimum from "./ServicePricingMin";
import ServiceStartdate from "./ServiceStartdate";
import serviceAddonValidator from "./validator";

const ServiceAddonForm = ({
  service,
  handleClose,
  defaultValues,
  onSubmit,
  type = "create",
}) => {
  const queryClient = useQueryClient();

  const [submit, setSubmit] = React.useState(false);
  const { setNotification } = useNotificationContext();
  const methods = useForm({
    resolver: vestResolver(serviceAddonValidator),
    defaultValues: defaultValues,
  });
  const { control, handleSubmit } = methods;

  const handleClickSubmit = async (values) => {
    setSubmit(true);
    let form = { ...values };
    const duedate = dayjs(
      values?.service_management_billing?.service_billing_enddate
    ).add(1, "days");
    const startdate = dayjs(
      values?.service_management_billing?.service_billing_startdate
    );
    const months = duedate.diff(startdate, "months");

    form = {
      ...form,
      service_start_date: format_date_short(form?.service_start_date?.$d),
      service_duedate: format_date_short(form?.service_duedate?.$d),
      service_billing_cycle: convertMonthToBillingCycle(months),
      service_management_billing: {
        service_billing_enddate: format_date_short(
          values?.service_management_billing?.service_billing_enddate?.$d
        ),
        service_billing_startdate: format_date_short(
          values?.service_management_billing?.service_billing_startdate?.$d
        ),
        service_nextbill_enddate: format_date_short(
          values?.service_management_billing?.service_nextbill_enddate?.$d
        ),
        service_nextbill_startdate: format_date_short(
          values?.service_management_billing?.service_nextbill_startdate?.$d
        ),
      },
    };
    const response = await onSubmit(form);
    setSubmit(false);
    queryClientRefetcher(queryClient, [
      "service.informations",
      "services.addons.list",
      "service.logger",
      "service.invoices",
      "customer.invoices",
    ]);
    handleClose();
    return setNotification({
      open: true,
      message: response?.message,
      severity: response?.status,
    });
  };
  return (
    <FormProvider {...methods}>
      <Box
        sx={{ p: 3, mb: 3 }}
        component="form"
        onSubmit={handleSubmit(handleClickSubmit)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: 18,
                textTransform: "uppercase",
                fontWeight: "medium",
              }}
            >
              {type === "create"
                ? "Thêm sản phẩm/ dịch vụ mua thêm"
                : "Chỉnh sửa sản phẩm/ dịch vụ mua thêm"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "medium", mb: 1 }}>
              SẢN PHẨM/ DỊCH VỤ CHÍNH
            </Typography>
            <TextField
              disabled
              value={service?.service_name}
              size="small"
              placeholder="Chưa xác định..."
              sx={{
                bgcolor: "#E3E1EC",
              }}
              fullWidth
            />
            <Divider sx={{ m: "16px 0" }} />
            <Typography
              sx={{
                mb: 0.5,
                fontSize: 14,
                color: "#777680",
              }}
            >
              Sản Phẩm/ Dịch Vụ Mua Thêm *
            </Typography>
            <ProductAddon service={service} />
            <Collapse in={parseInt(methods.watch(`service_product_id`)) === -1}>
              <Skeleton variant="rectangular" sx={{ height: 100 }} />
            </Collapse>
            <Collapse in={parseInt(methods.watch(`service_product_id`)) > 0}>
              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                    gap: 2,
                  }}
                >
                  <ServiceStartdate />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <ServiceBilling />
                  <ServiceNextBill />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <ServicePricing />
                  <ServicePricingMinimum />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                    gap: 2,
                  }}
                >
                  <ServicePricingApply />
                  <ServiceAddonQty />
                </Box>
                <ServiceAmount />
                <Controller
                  name={"service_note"}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth>
                      <TextField
                        label="Ghi chú"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        multiline
                        minRows={3}
                        placeholder="Nhập ghi chú..."
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="service_tax"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      sx={{ pl: 0 }}
                      control={
                        <Checkbox
                          component="span"
                          size="small"
                          checked={value}
                          onChange={onChange}
                        />
                      }
                      label="Áp Dụng Thuế"
                      labelPlacement="end"
                    />
                  )}
                />
              </React.Fragment>
            </Collapse>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <Button
            disabled={submit}
            size="small"
            onClick={handleClose}
            sx={{
              height: {
                xs: 36,
                md: 42,
              },
            }}
            variant="text"
          >
            Huỷ Thao Tác
          </Button>
          <Button
            sx={{
              height: {
                xs: 36,
                md: 42,
              },
              minWidth: 150,
            }}
            type="submit"
            size="small"
            disabled={submit}
            startIcon={
              submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
            }
            variant="contained"
          >
            {type === "create" ? "Thêm mới" : "Cập nhật"}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default ServiceAddonForm;
