import ProductGroupApi from '@/apis/beta/Product/ProductGroupApi';
import withSuspense from '@/hocs/withSuspense';
import ErrorHelperText from '@components/shared/ErrorHelperText';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize';
import { FormControl, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const CustomerServiceInterest = () => {
    const { control, formState: { errors } } = useFormContext();

    const { data } = ProductGroupApi.List()

    return (
        <React.Fragment>
            <Typography sx={{ mb: 0.5, color: "#777680", fontSize: 14 }}>
                Dịch Vụ Quan Tâm *
            </Typography>
            <Controller
                control={control}
                name="customer.customer_service_interestings"
                render={({ field: { value, onChange } }) => {
                    return (
                        <FormControl fullWidth>
                            <MultipleSelectCustomize
                                limitTags={2}
                                options={data?.map((item) => ({
                                    label: item?.product_group_name,
                                    value: item?.product_group_name?.toUpperCase()
                                })) || []}
                                value={value}
                                onChange={(event, value) => {
                                    onChange(value)
                                }}
                                input={{
                                    placeholder: "Nhập tên nhóm sản phẩm/ dịch vụ tìm kiếm..."
                                }}
                                sx={{
                                    "&.MuiAutocomplete-option": {
                                        textTransform: "uppercase"
                                    }
                                }}
                            />
                            {!!errors?.customer?.customer_service_interestings && <ErrorHelperText message={errors?.customer?.customer_service_interestings?.message} />}
                        </FormControl>
                    )
                }} />
        </React.Fragment>
    )
}

export default withSuspense(CustomerServiceInterest, MuiSkeleton["TextField"])