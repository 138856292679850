import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const Prepaid = ({
    index
}) => {
    const { control } = useFormContext()

    return (
        <Controller
            name={`service_configoptions.[${index}].commitment.prepaid`}
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox
                                onChange={onChange}
                                size="small" checked={value} />}
                            label="Trả trước"
                        />
                    </FormControl>
                );
            }}
        />
    )
}

export default Prepaid