import DatePicker from "@components/datepickers/DateRangPicker";
import { Box, FormControl } from "@mui/material";
import dayjs from "dayjs";

const PrivateToolbar = ({ dates = [], onChangeDates, searchQuery }) => {
  return (
    <Box
      sx={{
        mt: 1.25,
        mr: 2,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        flexDirection: { xs: "column", md: "row" },
        gap: 1.25,
      }}
    >
      <FormControl
        fullWidth
        sx={{
          maxWidth: { xs: "100%", md: 410 },
        }}
      >
        <DatePicker
          value={[dayjs(dates?.gte), dayjs(dates?.lte)]}
          handler={(newValues) => {

            console.log(newValues)
            onChangeDates({
              gte: newValues[0],
              lte: newValues[1],
            });
          }}
        />
      </FormControl>
    </Box>
  );
};

export default PrivateToolbar;
