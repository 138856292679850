import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";

const queryString = require("query-string");

class WarehouseResourceApi {
  getAll = () =>
    useQueryWithCustomized(
      ["warehouse.resources.all"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_resources/list`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_resources?.map((o) => ({
              value: o.warehouse_resource_id,
              label: o.warehouse_resource_name,
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );

  index = ({ query = null, searchQuery = null, page = 1, pageSize = 20 }) => {
    const paramsQueryString = queryString.stringify(
      {
        page: page,
        page_size: pageSize,
      },
      {
        skipNull: true,
        arrayFormat: "bracket",
        skipEmptyString: true,
      }
    );
    return useQueryWithCustomized(
      [
        "warehouse.warehouse_resources.list",
        query,
        searchQuery,
        page,
        pageSize,
        paramsQueryString,
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_resources?${paramsQueryString} ${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${searchQuery}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_resources: data?.data?.warehouse_resources.map((o) => ({
                id: o.warehouse_resource_id,
                ...o,
              })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            console.log(error.response);
            return {
              warehouse_resources: [],
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  create = (params) =>
    requestWithToken(`${API_WAREHOUSE_V1_URL}/warehouse_resources`, "POST", {
      warehouse_resource: params,
    });
  update = (id, params) =>
    requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_resources/${id}`,
      "PUT",
      { warehouse_resource: params }
    );

  show = (id, callback) =>
    useQueryWithCustomized(
      ["warehouse_resource.detail", id],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_resources/${id}`
        )
          .then(({ data }) => {
            if (data?.errors) return callback(data.errors);

            return {
              warehouse_resource: data?.data?.warehouse_resource,
            };
          })
          .catch((error) => {
            console.log(error);
            return {
              warehouse_rack: null,
            };
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        notifyOnChangePropsExclusions: ["isStale"],
        suspense: true,
      }
    );
}
export default WarehouseResourceApi = new WarehouseResourceApi();
