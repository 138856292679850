
import DataGrid from "@/components/DataGrid";
import DialogShowJsonData from "@components/DialogShowJsonData";
import { Box, Link, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router";

export const BOX_ACTIONS = [
  { value: "create", label: "Tạo" },
  { value: "cancel", label: "Huỷ" },
  { value: "update", label: "Cập nhật" },
  { value: "confirm", label: "Xác nhận" },
  { value: "createrack", label: "Tạo crack" },
  { value: "updaterack", label: "Cập nhật" },
];
export const BOX_TYPES = [
  { value: "new", label: "mới" },
  { value: "idc", label: "idc" },
  { value: "rack", label: "tủ rack" },
  { value: "description", label: "mô tả" },
  { value: "ip", label: "ip" },
  { value: "deploy", label: "tên" },
  { value: "status", label: "trạng thái" },
  { value: "hostname", label: "hosname" },
  { value: "position", label: "vị trí" },
  { value: "uplink", label: "uplink" },
  { value: "owner", label: "sở hữu" },
  { value: "info", label: "thông tin" },
  // { value: "process", label: "phiếu" },
]

const BoxLog = ( props ) => {
    const navigate = useNavigate();
    const [dialogInfor,setDialogInfor] = useState({
        open: false,
        data: {},
        title: "Thông tin chi tiết"
    }) 
    
    const rows = props?.data?.warehouse_box_logs || []
    if (rows && rows?.length > 1){
        rows.sort((a, b) => new Date(b.warehouse_box_update_created_at) - new Date(a.warehouse_box_update_created_at));
    }

  const [columns] = useState([
    {
      field: "warehouse_box_updater_id",
      headerName: "Id người thực hiện",
      width: 150,
    },
    {
      field: "warehouse_box_updater_name",
      headerName: "Tên người thực hiện",
      width: 150,
    },
    {
      field: "warehouse_box_update_type",
      headerName: "Thao tác",
      valueGetter: ({ value }) => {
        return BOX_ACTIONS?.find((x) => x.value === (value.split('-')[0])?.toLowerCase())?.label + " " + BOX_TYPES?.find((x) => x.value === (value.split('-')[1])?.toLowerCase())?.label
      },
      width: 250,
    },
    {
        field: "warehouse_box_id",
        headerName: "Mã idc",
        width: 150,
        renderCell: ({ row,value }) =>  
            <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
            onClick = {() => navigate(
                    `/warehouse/boxes/${value}?page=0&pageSize=20&q=&path&`
                  )}
          >
            <Link
              sx={{
                  textDecoration: "underline",
              }}
              color="primary"
            >
              {row.warehouse_box_name || "---"}
            </Link>
          </Box>
            || "---",
    },
    {
      field: "warehouse_box_update_created_at",
      headerName: "Thời gian thực hiện",
      width: 150,
      renderCell: ({ value }) =>
        value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "---",
    },
    {
      field: "metadata",
      headerName: "Mô Tả",
      width: 100,
      renderCell: ({ value }) => {
        return (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
            onClick={() => setDialogInfor({ open: true, data: value })}
          >
            <Link
              sx={{
                textDecoration: "underline",
              }}
              color="primary"
            >
              Xem chi tiết
            </Link>
          </Box>
        )
      }
    },
    
  ]);
  const onClose = () => {
    setDialogInfor((prev) => ({ ...prev, open: false, data: {} }));
  }

  return (
    <Box sx={{ mt: 2 }}>
      <DialogShowJsonData
        open={dialogInfor.open}
        onClose={onClose}
        title={dialogInfor.title}
        metadata={dialogInfor.data}
      />
      <Typography
        sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: "medium", mb: 0.5 }}
      >
        Lịch Sử Thao Tác
      </Typography>
      <DataGrid
        columns={[...columns]}
        rows={rows?.map((item, index) => ({ ...item, id: index }))}
        components={{
          Toolbar: "disabled",
          Pagination: "disabled",
        }}
        paginationMode="client"
      />
    </Box>
  );
};

export default BoxLog;
