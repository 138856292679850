import { format_date_short, format_numeral_price } from "@/functions";
import DataGridCustomize from "@components/DataGrid";
import { AddOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { Fragment, useState } from "react";
import { Form } from "../../../shared";

export const COUNTRIES = [
  { value: "vi", label: "vi-VN", currency: "đ" },
  { value: "en", label: "en-US", currency: "$" },
];

const Package = ({ data }) => {
  const columns = [
    {
      field: "id",
      headerName: ".No",
      width: 60,
    },
    {
      field: "pricing",
      headerName: "Đơn Giá Niêm Yết",
      width: 150,
      renderCell: ({ value }) => format_numeral_price(value),
    },
    {
      field: "pricing_minimum",
      headerName: "Đơn Giá Min",
      width: 150,
      renderCell: ({ value }) => format_numeral_price(value),
    },
    {
      field: "pricing_apply_startdate",
      headerName: "Thời Gian Áp Dụng",
      width: 300,
      renderCell: ({ value, row }) =>
        `${format_date_short(value)} - ${format_date_short(
          row?.pricing_apply_duedate
        )}`,
    },
    {
      field: "employee_name",
      headerName: "Nhân Viên Thay Đổi",
      width: 180,
    },
    {
      field: "updated_at",
      headerName: "Cập Nhật Lần Cuối",
      width: 180,
      renderCell: ({ value }) => format_date_short(value),
    },
  ];
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <Box sx={{ display: "flex", mb: 1.5, justifyContent: "flex-end" }}>
        <Button
          size="small"
          variant="contained"
          disabled={open}
          onClick={() => setOpen(true)}
          startIcon={<AddOutlined fontSize="small" />}
        >
          Thêm Đơn Giá Mới
        </Button>
        {open && <Form.AddPricing onClose={() => setOpen(false)} />}
      </Box>
      <DataGridCustomize
        columns={columns}
        components={{
          Toolbar: "disabled",
          Pagination: "disabled",
        }}
        rows={data?.pricings?.map((pricing, index) => ({
          ...pricing,
          id: index + 1,
        }))}
      />
    </Fragment>
  );
};
export default Package;
