import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNotificationContext } from "@contexts/notification";
import PlaybookApi from "@/apis/beta/Threshold/Playbook/PlaybookApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PlaybookEdit({ open, handleClose, threshold_id ,playbook , refetch, isFetching, bindOptions}) {
  const {
    mutateAsync: createPlaybook,
    isLoading: createPlaybookPending,
    notify,
  } = useMutationWithNotification(PlaybookApi.CreatePlaybook);
  const {
    mutateAsync: updatePlaybook,
    isLoading: updatePlaybookPending,
  } = useMutationWithNotification(PlaybookApi.UpdatePlaybook);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    clearErrors,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
        playbook_name: playbook ? playbook?.playbook_name : "",
        // playbook_description: playbook ? playbook?.playbook_description : "",
        playbook_type: playbook ? playbook?.playbook_type : "default",
        playbook_scope: playbook ? playbook?.playbook_scope : "default",
        playbook_bind: playbook ? playbook?.playbook_bind?.replace("waf::elk::threshold::","") : "",
        playbook_status: playbook ? playbook?.playbook_status : "active",
        playbook_referrer_type: playbook ? playbook?.playbook_referrer_type : "Threshold",
        metadata: playbook ? JSON?.stringify(playbook?.metadata) : "{}"
    },
  });
  const { setNotification } = useNotificationContext();


  const onSubmit = async (formValues) => {
    if (playbook) {
      const response = await updatePlaybook({
        id: playbook?.playbook_id,
        playbook: {
              ...formValues,
              playbook_bind: `waf::elk::threshold::${formValues?.playbook_bind}`,
              metadata: JSON.parse(formValues.metadata),
              playbook_referrer_id: threshold_id,
            },
      }).then((data) => data).catch((error) => {
        return {error: error}
      });
      console.log(response)
      if (response?.error) {
        return notify("error", "Đã tồn tại playbook này");
      }
      if (!response?.error) {
        notify("success", `Cập nhật playbook thành công`);
        refetch();
        handleClose();
      } 
    }else{
      const response = await createPlaybook({
        playbook: {
              ...formValues,
              playbook_bind: `waf::elk::threshold::${formValues?.playbook_bind}`,
              metadata: JSON.parse(formValues.metadata),
              playbook_referrer_id: threshold_id,
            },
      }).then((data) => data).catch((error) => {
        return {error: error}
      });
      console.log(response)
      if (response?.error) {
        return notify("error", "Đã tồn tại playbook này");
      }
      if (!response?.error) {
        notify("success", `Thêm playbook thành công`);
        refetch();
        handleClose();
        resetData();
      }  
    }
  };
  const resetData = () => {
    if (playbook) return;
    setValue("playbook_name", "");
    setValue("playbook_type", "default");
    setValue("playbook_scope", "default");
    setValue("playbook_bind", "");
    setValue("playbook_status", "active");
    setValue("playbook_referrer_type", "Threshold");
    setValue("metadata", "{}");
    clearErrors();
  }
  const action = playbook ? "Edit" : "Add";
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          handleClose();
          resetData();
        }}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{action} Playbook</DialogTitle>
          <DialogContent>
            <Box mt={2}>
                <Controller
                    name="playbook_name"
                    control={control}
                    rules={{
                    required: { message: "Playbook name is required", value: true },
                    }}
                    render={({ field: { onChange, ...field } }) => {
                    return (
                        <FormControl fullWidth>
                        <TextField
                            multiline
                            {...field}
                            sx={{ mt: 2 }}
                            label="Playbook name"
                            size="small"
                            placeholder="Playbook name"
                            onChange={(e) => {
                            onChange(e?.target?.value);
                            }}
                        />
                        {errors?.playbook_name?.message && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                {errors?.playbook_name?.message}
                            </FormHelperText>
                            )}
                        </FormControl>
                    );
                    }}
                />
                
                <Controller
                    name="playbook_type"
                    control={control}
                    rules={{
                    required: { message: "Playbook type is required", value: true },
                    }}
                    render={({ field: { onChange, ...field } }) => {
                    return (
                        <FormControl sx={{ mt: 2 }} fullWidth>
                        <InputLabel id="playbook_scope">Playbook type</InputLabel>
                        <Select 
                            labelId="playbook_type"
                            onChange={onChange} 
                            value={field.value} 
                            size="small"
                            label="Playbook type"
                            placeholder="Playbook type"
                          >
                              {[{
                                label: "default",
                                value: "default"
                                }]?.map((item,index) => <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>)} 
                          </Select>
                        {errors?.playbook_type?.message && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                {errors?.playbook_type?.message}
                            </FormHelperText>
                            )}
                        </FormControl>
                    );
                    }}
                />
                <Controller
                    name="playbook_scope"
                    control={control}
                    rules={{
                    required: { message: "Playbook scope is required", value: true },
                    }}
                    render={({ field: { onChange, ...field } }) => {
                    return (
                        <FormControl sx={{ mt: 2 }} fullWidth>
                        <InputLabel id="playbook_scope">Playbook scope</InputLabel>
                          <Select 
                            labelId="playbook_scope"
                            onChange={onChange} 
                            value={field.value} 
                            size="small"
                            label="Playbook scope"
                            placeholder="Playbook scope"
                          >
                              {[{
                                label: "default",
                                value: "default"
                                }]?.map((item,index) => <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>)} 
                          </Select>
                        {errors?.playbook_scope?.message && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                {errors?.playbook_scope?.message}
                            </FormHelperText>
                            )}
                        </FormControl>
                    );
                    }}
                />
                <Controller
                    name="playbook_bind"
                    control={control}
                    rules={{
                    required: { message: "Playbook bind is required", value: true },
                    }}
                    render={({ field: { onChange, ...field } }) => {
                    return (
                        <FormControl sx={{ mt: 2 }} fullWidth>
                          <InputLabel id="playbook_bind">Playbook bind</InputLabel>
                          <Select 
                            labelId="playbook_bind"
                            onChange={onChange} 
                            value={field.value} 
                            size="small"
                            label="Playbook bind"
                            placeholder="Playbook bind"
                          >
                              {bindOptions?.map((item,index) => <MenuItem key={index} value={item?.domain_name}>{item?.domain_name}</MenuItem>)} 
                          </Select>
                        {errors?.playbook_bind?.message && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                {errors?.playbook_bind?.message}
                            </FormHelperText>
                            )}
                        </FormControl>
                    );
                    }}
                />
                <Controller
                    name="playbook_status"
                    control={control}
                    rules={{
                    required: { message: "Playbook status is required", value: true },
                    }}
                    render={({ field: { onChange, ...field } }) => {
                    return (
                        <FormControl sx={{ mt: 2 }} fullWidth>
                          <InputLabel id="playbook_status">Playbook status</InputLabel>
                          <Select 
                            labelId="playbook_status"
                            onChange={onChange} 
                            value={field.value} 
                            size="small"
                            label="Playbook status"
                            placeholder="Playbook status"
                          >
                              <MenuItem value="active" selected>Active</MenuItem>
                              <MenuItem value="inactive">Inactive</MenuItem>
                          </Select>
                        {errors?.playbook_status?.message && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                {errors?.playbook_status?.message}
                            </FormHelperText>
                            )}
                        </FormControl>
                    );
                    }}
                />
                
                
                <Controller
                    name="metadata"
                    control={control}
                    rules={{
                    required: { message: "Playbook metadata is required", value: true },
                    }}
                    render={({ field: { onChange, ...field } }) => {
                    return (
                        <FormControl fullWidth>
                        <TextField
                            multiline
                            // {...field}
                            value={field.value}
                            label="Playbook metadata"
                            sx={{ mt: 2 }}
                            // placeholder="{}"
                            minRows={3}
                            onChange={(e) => {
                              try {
                                  JSON.parse(e?.target?.value?.trim());
                                  clearErrors("metadata");
                              } catch (error) {
                                  setError("metadata", {
                                  message: "Playbook metadata is in valid json format",
                                  });
                              }
                              onChange(e?.target?.value);
                            }}
                        />
                        {errors?.metadata?.message && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                {errors?.metadata?.message}
                            </FormHelperText>
                            )}
                        </FormControl>
                    );
                    }}
                />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="outlined" onClick={() =>{
              handleClose()
              resetData()
              }}>
              Huỷ
            </Button>
            <Button
              size="small"
              disabled={createPlaybookPending || updatePlaybookPending || !isDirty}
              variant="contained"
              type="submit"
            >
              {createPlaybookPending || updatePlaybookPending? <CircularProgress size={25} /> : action}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

export default PlaybookEdit;
