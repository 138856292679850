import CustomerApi from '@/apis/beta/Customer/CustomerApi';
import MuiScrollTabs from '@/components/shared/MuiScrollTabs';
import { buildQueryFilterCondition } from '@/functions';
import withSuspense from '@/hocs/withSuspense';
import { Filter } from '@/hooks/useFilters';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import { useCustomerContext } from '@contexts/customer';
import {
    DoneOutlined as CustomerActiveIcon,
    AcUnitOutlined as CustomerCoolLeadIcon,
    Groups3Outlined as CustomerQualifiedProspectIcon,
    StopCircleOutlined as CustomerStopIcon,
    HourglassTopOutlined as CustomerTrialIcon
} from '@mui/icons-material';
import React from 'react';

const CustomerTabs = ({
    prefix
}) => {
    const { queryString, queryContext } = useCustomerContext()

    const filterableCustomers = React.useCallback(() => {
        if (!queryString || queryString?.trim() === '') return ``

        const queries = [
            {
                name: 'customers.customer_id', operator: "$eq",
                disabled: !parseInt(queryString), value: parseInt(queryString)
            }, {
                name: 'customers.customer_name', operator: "$contains",
                value: queryString
            }, {
                name: 'company_name', operator: "$contains",
                value: queryString
            }, {
                name: 'customers.customer_email', operator: "$contains",
                value: queryString
            }
        ]
        return buildQueryFilterCondition('$or', 0, queries)
    }, [queryString])

    const { data } = CustomerApi.Statistical({
        query: Filter.stringify(queryContext),
        searchQuery: filterableCustomers()
    })

    const initialTabs = [
        {
            value: "qualified-prospects",
            label: `Tiềm Năng (${data?.qualified_prospects})`,
            href: '',
            icon: CustomerQualifiedProspectIcon
        },
        {
            value: "active",
            label: `Đã Khai Thác (${data?.active})`,
            href: 'active',
            icon: CustomerActiveIcon
        },
        {
            value: "coollead",
            label: `cool - lead (${data?.cool_lead})`,
            href: 'coollead',
            icon: CustomerCoolLeadIcon
        }, {
            value: "trial",
            label: `trial (${data?.trial})`,
            href: 'trial',
            icon: CustomerTrialIcon
        }, {
            value: "stop",
            label: `stop (${data?.stop})`,
            href: 'stop',
            icon: CustomerStopIcon
        },
    ]

    return (
        <MuiScrollTabs initialTabs={initialTabs} prefix={prefix} />
    )
}

export default withSuspense(CustomerTabs, MuiSkeleton["ScrollTabs"])