import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import TicketRequestApi from "@/apis/beta/Ticket/TicketRequestApi";
import TicketTypeApi from "@/apis/beta/Ticket/TicketTypeApi";
import withSuspense from "@/hocs/withSuspense";
import {
  PREFIX_OF_ATTACHMENT,
  TICKET_RECEIVE_SOURCE,
  TICKET_URGENCY,
  arrayNumberUnique,
} from "@/libs/constants";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TicketStatusApi from "@/apis/beta/Ticket/TicketStatusApi";
import MuiDateTimePicker from "@components/shared/MuiDateTimePicker";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import TicketApi from "@/apis/beta/Ticket/TicketApi";
import People from "@features/Module/Ticket/Detail/People";
import moment from "moment";
import { useQueryClient } from "react-query";
import { BaseComponents } from "@components/Components";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import _ from "lodash";
import DragDropFile from "@components/shared/DragDropFile";
import AttactmentItem from "@features/Module/Ticket/Detail/OverviewTicket/Attachments/AttactmentItem";
import FileApi from "@/apis/shared/File/FileApi";
import { useNotificationContext } from "@contexts/notification";
import { useSearchParams } from "react-router-dom";
import AttachmentUploadProcess from "@features/Module/Ticket/Detail/OverviewTicket/Attachments/AttachmentUploadProcess";

const CreateTicket = ({ handleClose, refetch, ticket }) => {
  const defaultValues = {
    product_name: "",
    ticket_receive_source:
      ticket?.ticket_receive_source || TICKET_RECEIVE_SOURCE?.[0]?.value,
    customer_id: "",
    ticket_request_id: ticket?.ticket_request?.ticket_request_id || "",
    ticket_type_id: ticket?.ticket_type?.ticket_type_id || "",
    ticket_urgency: ticket?.ticket_urgency || TICKET_URGENCY?.[0]?.value,
    service_id: "",
    ticket_status_id: ticket?.ticket_status?.ticket_status_id || "",

    ticket_time_release: ticket?.ticket_time_release || null,
    ticket_time_request: ticket?.ticket_time_request || moment(),
    ticket_time_repply: ticket?.ticket_time_repply || moment(),
    ticket_time_response: ticket?.ticket_time_response || null,

    ticket_sale: ticket?.ticket_sale || [],
    ticket_cs: ticket?.ticket_cs || [],
    ticket_technical: ticket?.ticket_technical || [],

    ticket_content: ticket?.ticket_content || "",
    ticket_solution: ticket?.ticket_solution || "",
    ticket_note: ticket?.ticket_note || "",
    ticket_feedback: ticket?.ticket_feedback || "",
    ticket_channel_response: ticket?.ticket_channel_response || "",

    meeting: ticket?.meeting || "",
  };

  const [, setSearchParams] = useSearchParams();

  const { setNotification } = useNotificationContext();

  const [attachments, setAttachments] = useState([]);

  const methods = useForm({
    defaultValues,
  });

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = methods;

  const queryClient = useQueryClient();

  const { data: customer } = CustomerApi.Detail(watch("customer_id"));

  const { data: ticket_requests } = TicketRequestApi.ListTicketRequest();

  const { data: ticket_types } = TicketTypeApi.ListTicketType();

  const { data: ticket_statuses } = TicketStatusApi.ListTicketStatus();

  const ticket_request_id = watch("ticket_request_id");

  const {
    data: { services },
  } = CustomerApi.CustomerServices({
    customerId: parseInt(watch("customer_id")),
    page: 1,
    pageSize: 15,
  });

  const onAttachmentPushing = async (file) => {
    const token = await FileApi.GetPresignedTokenAttachment({
      filename: {
        name: `${Date.now()}-${file?.name}`,
        folder: PREFIX_OF_ATTACHMENT.ticket,
      },
    });

    if (!token || token?.status !== 200) {
      return setNotification({
        open: true,
        message: "Upload hợp đồng thất bại.",
        severity: "error",
      });
    }

    const uploaded = await FileApi.UploadAttachmentToStorage({
      token: token?.token,
      file: file,
      handleUploadFile: () => { },
      callback: (percen, file) => {
        setAttachments((prev) => [
          ...prev.map(({ file: f, percen: p }) => {
            return f.name === file?.name
              ? { file, percen }
              : { file: f, percen: p };
          }),
        ]);
      },
    });

    if (!uploaded) {
      return setNotification({
        open: true,
        message: "Tải dữ liệu thất bại",
        severity: "error",
      });
    }

    return (
      {
        attachment_file_name: file?.name,
        attachment_file_url: [
          PREFIX_OF_ATTACHMENT.ticket,
          token?.filename,
        ].join("/"),
        attachment_file_type: file?.type,
        attachment_file_size: file?.size,
      } || {}
    );
  };

  const uploadAttachment = async (ticket_id) => {
    if (attachments?.length > 0) {
      const responseFileAttachments = await Promise.all(
        attachments?.map(({ file }) => onAttachmentPushing(file))
      );

      if (responseFileAttachments?.length > 0) {
        await Promise.all(
          responseFileAttachments?.map((item) =>
            TicketApi.AddAttachment({
              ticket_id: ticket_id,
              values: {
                ticket_attachment_path: item?.attachment_file_url,
                ticket_attachment_type: item?.attachment_file_type,
                ticket_attachment_size: item?.attachment_file_size,
                ticket_attactment_name: item?.attachment_file_name,
              },
            })
          )
        );

        setAttachments([]);

        setSearchParams({ activeTab: "attachments" });

        document
          .getElementById("ticket-overview")
          ?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  };

  const createTicketHandler = async (values) => {
    try {
      const newValues = {
        ...values,
        service_id:
          typeof values?.service_id === "number" ? values?.service_id : null,
        ticket_sale:
          customer?.customer_service_information?.customer_cs_followers?.map(
            (item) => item?.employee_id
          ) || [],
        ticket_cs:
          customer?.customer_service_information?.customer_sale_followers?.map(
            (item) => item?.employee_id
          ) || [],
        ticket_technical: arrayNumberUnique(
          values?.ticket_technical?.map((item) => item?.employee_id || item)
        ),
      };

      let ticket_id = null;
      let response = null;

      if (!ticket) {
        response = await TicketApi.CreateTicket(newValues);
        ticket_id = response?.data?.ticket?.ticket_id;
      } else {
        console.log(newValues);
        response = await TicketApi.UpdateTicket({
          ticket_id: ticket?.ticket_id,
          values: _.omit(newValues, ["ticket_cs", "ticket_sale"]),
        });
        ticket_id = ticket?.ticket_id;
      }

      await uploadAttachment(ticket_id);

      if (!response?.errors) {
        notify("success", "Success");
        refetch();
        queryClient.refetchQueries([`ticket.logs.${ticket?.ticket_id}`]);
        queryClient.refetchQueries([`ticket.attachments.${ticket?.ticket_id}`]);
        handleClose && handleClose();
      }
    } catch (error) {
      notify("error", "Something went wrong");
    }
  };

  const { mutateAsync, notify, isLoading } =
    useMutationWithNotification(createTicketHandler);

  const servicesMemo = useMemo(() => {
    return [
      ...(services || [])?.map((item) => ({
        service_id: item?.service_id,
        service_name: `${item?.service_id} - ${item?.service_name} - ${item?.service_status}`,
      })),
      ...(
        customer?.customer_service_information?.customer_service_interestings ||
        []
      )?.map((item) => ({
        service_id: item,
        service_name: `${item} - dịch vụ đang quan tâm`,
      })),
    ];
  }, [
    services,
    customer?.customer_service_information?.customer_service_interestings,
  ]);

  const handleDragFile = (file) => {
    setSearchParams({ activeTab: "attachments" });
    setAttachments((prev) => [{ file }, ...prev]);
  };

  useEffect(() => {
    if (ticket) return;

    if (ticket_statuses?.[0]) {
      const { ticket_status_id } = ticket_statuses?.[0];
      setValue("ticket_status_id", ticket_status_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket_statuses, ticket]);

  useEffect(() => {
    if (!ticket) return;
    setValue("ticket_status_id", ticket?.ticket_status?.ticket_status_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket?.ticket_status]);

  useEffect(() => {
    if (typeof watch("service_id") !== "number") {
      setValue("product_name", watch("service_id"));
    } else {
      setValue("product_name", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("service_id")]);

  useEffect(() => {
    const handlePaste = (e) => {
      if (!e.clipboardData.files?.[0]) {
        return;
      }

      e.preventDefault();
      setAttachments((prev) => [{ file: e.clipboardData.files?.[0] }, ...prev]);
    };

    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  useEffect(() => {
    if (ticket_request_id === 3 && !ticket) {
      setValue(
        "ticket_content",
        "1. Tên công ty, website: \n2. Thành phần meeting (tên, vị trí cv): \n3. Pain points của KH là gì?: \n4. Hệ thống họ đang dùng gì của đối thủ nào?: \n5. Ghi chú đặc biệt của KH (nếu có): \n6. Ngân sách của khách hàng:\n7. Thời gian dự kiến bán hàng:"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket_request_id]);

  return (
    <FormProvider {...methods}>
      <Box p={2}>
        {isLoading && <MuiSkeleton.LinearProgress />}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" fontWeight={600}>
            {ticket ? "Update" : "New"} Ticket
          </Typography>
          <IconButton>
            <TaskAltIcon color="success" />
          </IconButton>
        </Box>

        <Box>
          <Box mt={2}>
            <DragDropFile handler={handleDragFile} />
          </Box>

          {attachments?.length > 0 && (
            <Box display="flex" flexDirection="column" gap={2} mt={2}>
              {attachments?.map(({ file }, index) => (
                <AttactmentItem
                  item={{
                    ticket_attactment_name: file?.name,
                    ticket_attachment_size: file?.size,
                    ticket_attachment_type: file?.type,
                  }}
                  handleDelete={() =>
                    setAttachments(attachments?.filter((_, i) => i !== index))
                  }
                  isPreview={false}
                  key={index}
                />
              ))}
            </Box>
          )}
        </Box>

        <Box component="form" onSubmit={handleSubmit(mutateAsync)}>
          <Box
            display="flex"
            gap={2}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Box flex={1}>
              <Grid container mt={2}>
                <Grid xs={4}>
                  <Typography>Nguồn nhận ticket </Typography>
                </Grid>

                <Grid xs={8}>
                  <Controller
                    name="ticket_receive_source"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <Select {...field} size="small" fullWidth>
                          {TICKET_RECEIVE_SOURCE?.map((item) => (
                            <MenuItem key={item?.value} value={item?.value}>
                              {item?.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              {!ticket && (
                <Grid container mt={2}>
                  <Grid xs={4}>
                    <Typography>Khách hàng đang gặp vấn đề</Typography>
                  </Grid>

                  <Grid xs={8}>
                    <Controller
                      name="customer_id"
                      rules={{ required: "This field is required" }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl fullWidth>
                          <BaseComponents.Customer
                            name="customer_id"
                            onChange={onChange}
                            value={value}
                          />

                          {errors?.customer_id && (
                            <ErrorHelperText
                              message={errors?.customer_id?.message}
                            />
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container mt={2}>
                <Grid xs={4}>
                  <Typography>Ticket Request</Typography>
                </Grid>

                <Grid xs={8}>
                  <Controller
                    name="ticket_request_id"
                    rules={{ required: "This field is required" }}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      const selected =
                        ticket_requests?.find(
                          (x) => x?.ticket_request_id === value
                        ) || null;

                      return (
                        <FormControl fullWidth>
                          <SingleSelectCustomize
                            onChange={(_, newValue) =>
                              onChange(newValue?.ticket_request_id || null)
                            }
                            value={selected}
                            formatCollection={{
                              label: "ticket_request_title",
                              value: "ticket_request_id",
                            }}
                            compareAttributes={["ticket_request_title"]}
                            options={ticket_requests}
                          />

                          {errors?.ticket_request_id && (
                            <ErrorHelperText
                              message={errors?.ticket_request_id?.message}
                            />
                          )}
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid xs={4}>
                  <Typography>Ticket Type</Typography>
                </Grid>

                <Grid xs={8}>
                  <Controller
                    name="ticket_type_id"
                    rules={{ required: "This field is required" }}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      const selected =
                        ticket_types?.find(
                          (x) => x?.ticket_type_id === value
                        ) || null;

                      return (
                        <FormControl fullWidth>
                          <SingleSelectCustomize
                            onChange={(_, newValue) =>
                              onChange(newValue?.ticket_type_id || null)
                            }
                            value={selected}
                            formatCollection={{
                              label: "ticket_type_title",
                              value: "ticket_type_id",
                            }}
                            compareAttributes={["ticket_type_title"]}
                            options={ticket_types}
                          />

                          {errors?.ticket_type_id && (
                            <ErrorHelperText
                              message={errors?.ticket_type_id?.message}
                            />
                          )}
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
              </Grid>

              {!ticket && (
                <Grid container mt={2}>
                  <Grid xs={4}>
                    <Typography>
                      Dịch vụ{" "}
                      {!watch("customer_id")?.value
                        ? "(vui lòng chọn khách hàng)"
                        : "(đang sử dụng)"}
                    </Typography>
                  </Grid>

                  <Grid xs={8}>
                    <Controller
                      name="service_id"
                      rules={{ required: "This field is required" }}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        const selected =
                          servicesMemo?.find((x) => x?.service_id === value) ||
                          null;

                        return (
                          <FormControl fullWidth>
                            <SingleSelectCustomize
                              onChange={(_, newValue) =>
                                onChange(newValue?.service_id || null)
                              }
                              disabled={
                                !watch(`customer_id`) ||
                                parseInt(watch(`customer_id`) <= 0)
                              }
                              value={selected}
                              formatCollection={{
                                label: "service_name",
                                value: "service_id",
                              }}
                              compareAttributes={["service_name"]}
                              options={servicesMemo}
                            />

                            {errors?.service_id && (
                              <ErrorHelperText
                                message={errors?.service_id?.message}
                              />
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container mt={2}>
                <Grid xs={4}>
                  <Typography>
                    {ticket_request_id !== 3
                      ? "Mô tả Ticket"
                      : "Các nội dung cần có cho buổi trao đổi:"}
                  </Typography>
                </Grid>

                <Grid xs={8}>
                  <Controller
                    name="ticket_content"
                    rules={{ required: "This field is required" }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        multiline
                        rows={8}
                        placeholder={
                          ticket_request_id !== 3
                            ? "Mô tả vấn đề khách hàng đang gặp phải"
                            : "Các nội dung cần có cho buổi trao đổi:"
                        }
                        helperText={errors?.ticket_content?.message}
                        error={!!errors?.ticket_content}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid xs={4}>
                  <Typography>Ticket Urgency</Typography>
                </Grid>

                <Grid xs={8}>
                  <Controller
                    name="ticket_urgency"
                    rules={{ required: "This field is required" }}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      const selected =
                        TICKET_URGENCY.find((x) => x?.value === value) || null;

                      return (
                        <FormControl fullWidth>
                          <SingleSelectCustomize
                            onChange={(_, newValue) => {
                              onChange(newValue?.value || null);
                            }}
                            value={selected}
                            options={TICKET_URGENCY}
                          />

                          {errors?.ticket_urgency && (
                            <ErrorHelperText
                              message={errors?.ticket_urgency?.message}
                            />
                          )}
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid xs={4}>
                  <Typography>
                    {ticket_request_id !== 3
                      ? "Ngày khách hàng Raise Ticket"
                      : "Thời gian dự kiến trao đổi với khách hàng"}
                  </Typography>
                </Grid>

                <Grid xs={8}>
                  <Controller
                    name="ticket_time_request"
                    control={control}
                    render={({ field: { onChange, value, ...field } }) => (
                      <FormControl fullWidth>
                        <MuiDateTimePicker
                          {...field}
                          onChange={onChange}
                          value={value}
                          fullWidth
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid xs={4}>
                  <Typography>Ngày trả lời</Typography>
                </Grid>

                <Grid xs={8}>
                  <Controller
                    name="ticket_time_repply"
                    control={control}
                    render={({ field: { onChange, value, ...field } }) => (
                      <FormControl fullWidth>
                        <MuiDateTimePicker
                          {...field}
                          onChange={onChange}
                          value={value}
                          fullWidth
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              {ticket_request_id === 3 && (
                <Grid container mt={2}>
                  <Grid xs={4}>
                    <Typography>Hình thức meeting</Typography>
                  </Grid>

                  <Grid xs={8}>
                    <Controller
                      name="meeting"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          fullWidth
                          helperText="Lưu ý: nếu online vui lòng điền link meeting vào còn offline thì điền địa chỉ vào đây"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container mt={2}>
                <Grid xs={4}>
                  <Typography>Trạng thái Ticket</Typography>
                </Grid>

                <Grid xs={8}>
                  <Controller
                    name="ticket_status_id"
                    rules={{ required: "This field is required" }}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      const selected =
                        ticket_statuses?.find(
                          (x) => x?.ticket_status_id === value
                        ) || null;

                      return (
                        <FormControl fullWidth>
                          <SingleSelectCustomize
                            onChange={(_, newValue) =>
                              onChange(newValue?.ticket_status_id || null)
                            }
                            value={selected}
                            formatCollection={{
                              label: "ticket_status_title",
                              value: "ticket_status_id",
                            }}
                            compareAttributes={["ticket_status_title"]}
                            options={ticket_statuses?.filter((item) => {
                              if (!ticket) {
                                return item;
                              }

                              const status = ticket_statuses?.find(
                                (x) => x?.ticket_status_id === value
                              );

                              return status?.ticket_status_next_accept?.includes(
                                item?.ticket_status_id
                              );
                            })}
                          />

                          {errors?.ticket_status_id && (
                            <ErrorHelperText
                              message={errors?.ticket_status_id?.message}
                            />
                          )}
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box flex={1}>
              <People ticket={ticket} />

              {ticket_request_id !== 3 && (
                <>
                  <Grid container mt={2}>
                    <Grid xs={4}>
                      <Typography>Ngày dự kiến hoàn thành</Typography>
                    </Grid>

                    <Grid xs={8}>
                      <Controller
                        name="ticket_time_release"
                        control={control}
                        render={({ field: { onChange, value, ...field } }) => (
                          <FormControl fullWidth>
                            <MuiDateTimePicker
                              {...field}
                              onChange={onChange}
                              value={value}
                              fullWidth
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid xs={4}>
                      <Typography>Ngày phản hồi khách hàng</Typography>
                    </Grid>

                    <Grid xs={8}>
                      <Controller
                        name="ticket_time_response"
                        control={control}
                        render={({ field: { onChange, value, ...field } }) => (
                          <FormControl fullWidth>
                            <MuiDateTimePicker
                              {...field}
                              onChange={onChange}
                              value={value}
                              fullWidth
                            />

                            <Typography
                              fontSize={13}
                              mt={1}
                              px={2}
                              color="GrayText"
                            >
                              Ngày phản hồi với khách hàng đã xử lý xong ticket
                              để khách hàng kiểm tra xác nhận
                            </Typography>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid container mt={2}>
                <Grid xs={4}>
                  <Typography>
                    {ticket_request_id === 3
                      ? "Recording của buổi meeting"
                      : "Giải pháp / cách giải quyết"}
                  </Typography>
                </Grid>

                <Grid xs={8}>
                  <Controller
                    name="ticket_solution"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        multiline
                        rows={4}
                        placeholder={
                          ticket_request_id === 3
                            ? "Recording của buổi meeting"
                            : "Giải pháp / cách giải quyết"
                        }
                        helperText={
                          ticket_request_id === 3
                            ? "Vui lòng điền link buổi record vào đây"
                            : "Giải pháp / cách giải quyết"
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid xs={4}>
                  <Typography>
                    {ticket_request_id === 3
                      ? "Follow up với khách hàng sau meeting"
                      : "Ghi chú"}
                  </Typography>
                </Grid>

                <Grid xs={8}>
                  <Controller
                    name="ticket_note"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        multiline
                        rows={4}
                        placeholder={
                          ticket_request_id === 3
                            ? "Follow up với khách hàng sau meeting"
                            : "Ghi chú"
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {ticket && (
                <>
                  <Grid container mt={2}>
                    <Grid xs={4}>
                      <Typography>Feedback của khách hàng</Typography>
                    </Grid>

                    <Grid xs={8}>
                      <Controller
                        name="ticket_feedback"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            multiline
                            rows={4}
                            placeholder="Feedback"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Box>

          <Box display="flex" gap={2}>
            <Button
              startIcon={isLoading && <CircularProgress size={14} />}
              disabled={isLoading}
              variant="contained"
              size="small"
              type="submit"
              sx={{ mt: 2, flex: 1 }}
            >
              {!ticket ? "New" : "Update"} Ticket
            </Button>

            <Button
              onClick={handleClose}
              variant="outlined"
              size="small"
              sx={{ mt: 2, flex: 1 }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          position: "fixed",
          top: 16,
          right: 16,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {isLoading &&
          attachments?.length > 0 &&
          attachments?.map(({ file, percen }) => (
            <AttachmentUploadProcess
              key={file?.name}
              name={file?.name}
              percent={percen}
            />
          ))}
      </Box>
    </FormProvider>
  );
};

export default withSuspense(CreateTicket, MuiSkeleton["DataGrid"]);
