import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import WarehouseOwnerApi from "@/apis/beta/Warehouse/WarehouseOwnerApi";
import withSuspense from "@/hocs/withSuspense";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { CAPACYTIES, WAREHOUSE_STATUS_BUY } from "@/libs/constants";
import DatePicker from "@components/DatePicker";
import MultiSelectTextInput from "@components/MultiSelectTextInput";
import NumericFormatCustom from "@components/NumericFormatCustom";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import TableAccessory from "../../../TableAccessory";
import AddChildItemForm from "../AddChildItemForm";
import CellSpecification from "@components/DataGrid/CellSpecification";

const initialWarehouseItem = () => ({
  is_tags: "yes",
  service_tags: [],
  warehouse_model_id: "",
  warehouse_item_form_factor: "",
  warehouse_item_name: "",
  warehouse_owner_name: "",
  warehouse_item_price: "0",
  warehouse_item_import_at: moment(Date.now())?.format("YYYY-MM-DD"),
  warehouse_item_status: "",
  warehouse_item_warranty_start_at: moment(Date.now())?.format("YYYY-MM-DD"),
  warehouse_item_warranty_expired_at: moment(Date.now())?.format("YYYY-MM-DD"),
  capacity: {
    capacity_cpu: 0,
    capacity_ram: 0,
    capacity_storage: 0,
    capacity_power_supply: 0,
  },
  warehouse_item_description: "",
  warehouse_group_id: null,
  children: [],
  total: 0,
  id: Math.random(),
});

const AddWarehouseForm = ({
  onClose,
  onChange,
  data,
  disableChooseOwner = false,
  types,
}) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    clearErrors,
  } = useForm({
    defaultValues: data || initialWarehouseItem(),
  });

  const [showModalAddItem, setShowModalAddItem] = useState(false);

  const is_tags = watch("is_tags");

  const warehouse_model_id = watch("warehouse_model_id");

  const { data: warehouses_group, isLoading: warehouses_group_fetch } =
    WarehouseModelApi.All();

  const { data: warehouse_owner, isLoading: warehouse_owner_fetch } =
    WarehouseOwnerApi.getAll();

  const {
    mutateAsync: CheckItemsExist,
    isLoading: CheckItemsExistLoading,
    notify,
  } = useMutationWithNotification(WarehouseItemApi.CheckItemsExist);

  const handleAddItem = async (values) => {
    let newValues = { ...values };

    if (values?.service_tags?.length > 0) {
      const response = await CheckItemsExist({
        service_tags: values?.service_tags,
      });

      if (response?.data?.warehouse_items?.length > 0) {
        return notify(
          "error",
          "Service tags exist: " +
          response?.data?.warehouse_items
            .map((item) => item?.warehouse_item_name)
            .join(", ")
        );
      }
    }

    if (values?.is_tags === "yes") {
      delete newValues?.["total"];
    } else {
      delete newValues?.["service_tags"];
    }

    onChange &&
      onChange({
        ...newValues,
        warehouse_group_id: watch("warehouse_group_id"),
      });

    onClose();
  };

  const handleRemoveItemChild = (id) => {
    let cloneChild = [...watch("children")];
    cloneChild = cloneChild?.filter((item) => item?.id !== id);
    setValue("children", cloneChild);
  };

  const handleEditChild = (data) => {
    let cloneChild = [...watch("children")];
    cloneChild = cloneChild?.map((item) =>
      item?.id === data?.id ? data : item
    );
    setValue("children", cloneChild);
  };

  const handleCapacity = (warehouse_models) => {
    return warehouse_models?.reduce(
      (final, warehouse_model) => {
        const total = warehouse_model?.total || 1;

        final["capacity_ram"] +=
          warehouse_model?.warehouse_type_name === "RAM"
            ? parseInt(
              warehouse_model?.specifications?.find(
                (item) => item?.specification_name === "CAPACITY"
              )?.specification_value
            ) * total
            : 0;

        final["capacity_cpu"] +=
          parseInt(
            warehouse_model?.specifications?.find(
              (item) => item?.specification_name === "CORES"
            )?.specification_value
          ) * total || 0;

        final["capacity_storage"] += ["SAS", "HDD", "SSD"].includes(
          warehouse_model?.warehouse_type_name
        )
          ? parseInt(
            warehouse_model?.specifications?.find(
              (item) => item?.specification_name === "CAPACITY"
            )?.specification_value
          ) * total
          : 0;

        final["capacity_power_supply"] += parseInt(
          warehouse_model?.specifications?.find(
            (item) => item?.specification_name === "POWER SUPPLY"
          )?.specification_value * total || 0
        );

        return final;
      },
      {
        capacity_ram: 0,
        capacity_cpu: 0,
        capacity_storage: 0,
        capacity_power_supply: 0,
      }
    );
  };

  const warehouse_model = warehouses_group?.find(
    (item) => item?.warehouse_model_id === warehouse_model_id
  );

  useEffect(() => {
    const warehouse_item_form_factor =
      parseInt(
        warehouse_model?.specifications?.find(
          (item) => item?.specification_name === "FORM FACTOR"
        )?.specification_value
      ) || 0;

    const capacity = !warehouse_model
      ? {}
      : handleCapacity([
        warehouse_model,
        ...warehouses_group
          ?.filter((item) =>
            watch("children")
              ?.map((item) => item?.warehouse_model_id)
              ?.includes(item?.warehouse_model_id)
          )
          ?.map((item) => {
            const warehouse_item = watch("children")?.find(
              (child) =>
                child?.warehouse_model_id === item?.warehouse_model_id
            );

            return {
              ...item,
              total:
                warehouse_item?.is_tags === "yes"
                  ? warehouse_item?.service_tags?.length
                  : Number(warehouse_item?.total),
            };
          }),
      ]);

    const warehouse_item_name = warehouse_model?.label;

    if (warehouse_item_name) {
      clearErrors("warehouse_item_name");
    }

    setValue(`warehouse_item_name`, warehouse_item_name);
    setValue(`warehouse_item_form_factor`, warehouse_item_form_factor);
    setValue(`capacity`, capacity);
    setValue(`warehouse_item_status`, WAREHOUSE_STATUS_BUY?.[0]?.value);
    setValue(`warehouse_group_id`, warehouse_model?.warehouse_group_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse_model_id, warehouses_group, JSON.stringify(watch("children"))]);

  useEffect(() => {
    if (warehouse_owner) {
      setValue(
        `warehouse_owner_name`,
        warehouse_owner?.[0]?.warehouse_owner_name
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse_owner]);

  return (
    <Box
      mt={2}
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(handleAddItem)();
      }}
    >
      <Box>
        <Controller
          control={control}
          name={`is_tags`}
          render={({ field }) => {
            return (
              <FormControl>
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Có service tag / series number"
                  />

                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="Không service tag / series number"
                  />
                </RadioGroup>
              </FormControl>
            );
          }}
        />

        {is_tags === "no" ? (
          <Controller
            control={control}
            name={`total`}
            rules={{
              required: "Vui lòng nhập số lượng",
              min: { value: 1, message: "Số lượng phải lớn hơn 0" },
            }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  type="number"
                  size="small"
                  fullWidth
                  label="Nhập số lượng hệ thống sẽ tự cấp service tag"
                  sx={{ mt: 2 }}
                  helperText={errors?.total?.message}
                  error={!!errors?.total}
                />
              );
            }}
          />
        ) : (
          <Controller
            control={control}
            name={`service_tags`}
            rules={{ required: "Vui lòng nhập service tag / series number" }}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <MultiSelectTextInput
                    {...field}
                    label="Nhập service tag / series number nhấn tab để thêm"
                    sx={{
                      mt: 2,
                    }}
                  />

                  {errors?.service_tags?.message && (
                    <ErrorHelperText
                      sx={{ mt: 1 }}
                      message={errors?.service_tags?.message}
                    />
                  )}
                </FormControl>
              );
            }}
          />
        )}
      </Box>

      <Box display="flex" gap={2} mt={2}>
        <Controller
          control={control}
          name={`warehouse_model_id`}
          rules={{ required: "Vui lòng chọn models" }}
          render={({ field }) => {
            const groupType = _.groupBy(
              types
                ? warehouses_group?.filter((item) =>
                  types?.includes(item?.warehouse_type_id)
                )
                : data?.warehouse_group_id
                  ? warehouses_group?.filter(
                    (item) =>
                      item?.warehouse_group_id === data?.warehouse_group_id
                  )
                  : warehouses_group?.filter(
                    (item) => item?.warehouse_group_id !== 1
                  ),
              (item) => item?.warehouse_type_name
            );

            return (
              <FormControl fullWidth>
                <Typography>Chọn model *</Typography>
                <Select
                  {...field}
                  size="small"
                  sx={{ mt: 1 }}
                  fullWidth
                  disabled={warehouses_group_fetch}
                >
                  {Object.keys(groupType)?.map((item) => [
                    <ListSubheader>{item}</ListSubheader>,
                    ...groupType?.[item]?.map((item) => (
                      <MenuItem key={item?.value} value={item?.value}>
                        {/* {item?.label} */}
                        {!types?.includes(1) && !types?.includes(2) ? item?.label + " - " + item?.manufacture?.manufacture_name : item?.label}
                      </MenuItem>
                    )),
                  ])}
                </Select>

                {errors?.warehouse_model_id && (
                  <ErrorHelperText
                    message={errors?.warehouse_model_id?.message}
                  />
                )}
              </FormControl>
            );
          }}
        />

        {watch("warehouse_group_id") === 1 && (
          <Controller
            control={control}
            name="warehouse_item_form_factor"
            render={({ field }) => (
              <FormControl fullWidth>
                <Typography>Số U</Typography>
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled
                  sx={{ mt: 1 }}
                />
              </FormControl>
            )}
          />
        )}

        {/* <Controller
          control={control}
          name={`warehouse_item_name`}
          rules={{ required: "Vui lòng điền tên mặt hàng" }}
          render={({ field }) => (
            <FormControl fullWidth>
              <Typography>Tên hàng *</Typography>
              <TextField
                {...field}
                size="small"
                fullWidth
                sx={{ mt: 1 }}
                helperText={errors?.warehouse_item_name?.message}
                error={!!errors?.warehouse_item_name}
              />
            </FormControl>
          )}
        /> */}

        {!disableChooseOwner && (
          <Controller
            control={control}
            name={`warehouse_owner_name`}
            rules={{ required: "Vui lòng chọn sở hữu" }}
            render={({ field }) => (
              <FormControl fullWidth>
                <Typography>Chọn sở hữu *</Typography>
                <Select
                  {...field}
                  size="small"
                  sx={{ mt: 1 }}
                  fullWidth
                  disabled={warehouse_owner_fetch}
                >
                  {warehouse_owner?.map((item) => (
                    <MenuItem key={item?.value} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>

                {errors?.warehouse_owner_name?.message && (
                  <ErrorHelperText
                    message={errors?.warehouse_owner_name?.message}
                  />
                )}
              </FormControl>
            )}
          />
        )}
      </Box>

      <Box display="flex" gap={2} mt={2}>
        <Controller
          control={control}
          name={`warehouse_item_price`}
          rules={{ required: "Vui lòng nhập giá bán" }}
          render={({ field }) => (
            <FormControl fullWidth>
              <Typography>Giá nhập *</Typography>
              <TextField
                {...field}
                size="small"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                error={!!errors?.warehouse_item_price}
                helperText={errors?.warehouse_item_price?.message}
                sx={{ mt: 1 }}
              />
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name={`warehouse_item_import_at`}
          rules={{ required: "Vui lòng chọn ngày nhập" }}
          render={({ field }) => (
            <FormControl fullWidth>
              <Typography>Chọn ngày nhập *</Typography>
              <Box mt={1}>
                <DatePicker {...field} />
              </Box>

              {errors?.warehouse_item_import_at && (
                <ErrorHelperText
                  message={errors?.warehouse_item_import_at?.message}
                />
              )}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name={`warehouse_item_status`}
          defaultValue={WAREHOUSE_STATUS_BUY?.[0]?.value}
          render={({ field }) => (
            <FormControl fullWidth>
              <Typography>Chọn tình trạng *</Typography>
              <Select {...field} size="small" sx={{ mt: 1 }} fullWidth>
                {WAREHOUSE_STATUS_BUY?.filter((item) => item?.value !== 3)?.map(
                  (item) => (
                    <MenuItem key={item?.value} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          )}
        />
      </Box>

      <Box mt={2} display="flex" gap={2}>
        <Controller
          control={control}
          name={`warehouse_item_warranty_start_at`}
          rules={{ required: "Vui lòng chọn hiệu lực bảo hành" }}
          render={({ field }) => (
            <FormControl fullWidth>
              <Typography>Chọn ngày hiệu lực bảo hành *</Typography>
              <Box mt={1}>
                <DatePicker {...field} />
              </Box>

              {errors?.warehouse_item_warranty_start_at && (
                <ErrorHelperText
                  message={errors?.warehouse_item_warranty_start_at?.message}
                />
              )}
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name={`warehouse_item_warranty_expired_at`}
          rules={{ required: "Vui lòng chọn ngày hết hiệu lực bảo hành" }}
          render={({ field }) => (
            <FormControl fullWidth>
              <Typography>Chọn ngày hết hiệu lực bảo hành *</Typography>
              <Box mt={1}>
                <DatePicker {...field} />
              </Box>

              {errors?.warehouse_item_warranty_expired_at && (
                <ErrorHelperText
                  message={errors?.warehouse_item_warranty_expired_at?.message}
                />
              )}
            </FormControl>
          )}
        />
      </Box>

      <Box mt={2} display="flex" gap={2}>
        {CAPACYTIES?.map((config) => (
          <Controller
            control={control}
            name={`capacity.${config?.key}`}
            key={config.key}
            render={({ field: { value, ...field } }) =>
              value ? (
                <TextField
                  {...field}
                  value={value || 0}
                  disabled
                  label={`${config?.label.toUpperCase()}`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {config?.unit}
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  type="number"
                />
              ) : null
            }
          />
        ))}
      </Box>

      <Grid sx={{ width: "100%" }}>
        {warehouse_model?.slots?.length > 0 && (
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Box>
              <Typography sx={{ color: "#777680" }}>Slots:</Typography>
              <CellSpecification
                items={warehouse_model.slots.map((o) => ({
                  label: `${o.slot_name} ${o.slot_max_capacity && o.slot_max_capacity !== 0
                    ? `- Max Capacity ${o.slot_max_capacity}`
                    : ""
                    } `,
                  value: o.slot_number,
                }))}
              />
            </Box>
          </Grid>
        )}
        {warehouse_model?.specifications?.length > 0 && (
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Box>
              <Typography sx={{ color: "#777680" }}>
                Thông số kỹ thuật:
              </Typography>
              <CellSpecification
                items={warehouse_model?.specifications.map((o) => ({
                  label: `${o.specification_name}`,
                  value: ` ${o.specification_value} ${o.specification_unit || ""
                    }`,
                }))}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <Box mt={2}>
        <Controller
          control={control}
          name={`warehouse_item_description`}
          render={({ field }) => (
            <FormControl fullWidth>
              <Typography>Mô tả</Typography>
              <TextField {...field} multiline minRows={3} size="small" />
            </FormControl>
          )}
        />
      </Box>

      {watch("warehouse_group_id") === 1 && (
        <Box mt={2}>
          <Button
            onClick={() => setShowModalAddItem(true)}
            variant="outlined"
            startIcon={<AddIcon />}
            size="small"
          >
            Thêm linh kiện
          </Button>
          <TableAccessory
            warehouse_item_children={watch("children")}
            handleRemoveItemChild={handleRemoveItemChild}
            handleEditChild={handleEditChild}
          />
        </Box>
      )}

      {showModalAddItem && (
        <AddChildItemForm
          onClose={() => setShowModalAddItem(false)}
          onChange={(values) => {
            delete values?.["warehouse_owner_name"];
            setValue("children", [...watch("children"), values]);
          }}
        />
      )}

      <Box mt={2}>
        <Button
          type="submit"
          size="small"
          variant="contained"
          disabled={CheckItemsExistLoading}
          startIcon={CheckItemsExistLoading && <CircularProgress size={14} />}
        >
          {data ? "Lưu" : "Thêm"} Item
        </Button>
      </Box>
    </Box>
  );
};

export default withSuspense(AddWarehouseForm, MuiSkeleton["LinearProgress"]);
