import withPermission from "@/hocs/withPermission";
import {
  TICKET_RECEIVE_SOURCE,
  TICKET_URGENCY
} from "@/libs/constants";
import moment from "moment";
import TicketForm from "../Shared/Forms/TicketForm";
import TicketHeader from "./TicketHeader";
import TicketApi from "@/apis/beta/Ticket/TicketApi";
import { useNavigate, useSearchParams } from "react-router-dom";
const TICKET_UIS = [
  {
    position: 'right',
    uis: ['ticket_receive_source', 'ticket_request_id', 'ticket_type_id', 'service_id', 'ticket_urgency', 'ticket_content', 'ticket_time_request', 'ticket_time_reply', 'ticket_status']
  }, {
    position: 'left',
    uis: ['ticket_technical', 'ticket_time_release', 'ticket_time_response', 'ticket_solution', 'ticket_feedback']
  }
]

const TICKET_VALUES = {
  product_name: "",
  ticket_receive_source: TICKET_RECEIVE_SOURCE?.[0]?.value,
  customer_id: -1,
  ticket_request_id: -1,
  ticket_type_id: 8,
  ticket_urgency: TICKET_URGENCY?.[0]?.value,
  service_id: -1,
  ticket_status_id: 1,
  ticket_time_release: null,
  ticket_time_request: moment(),
  ticket_time_repply: moment(),
  ticket_time_response: null,
  ticket_sale: [],
  ticket_cs: [],
  ticket_technical: process.env === 'production' ? [15] : [11],
  ticket_content: "",
  ticket_solution: "",
  ticket_note: "",
  ticket_feedback: "",
  ticket_channel_response: "",
  meeting: "",
}


export const TICKETS = {
  UIS: TICKET_UIS,
  VALUES: TICKET_VALUES
}

const CreateTicket = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate()
  const onSubmit = async (ticket) => {
    return await TicketApi.CreateTicket({
      ...ticket,
      metadata: {
        journey_id: searchParams.get('journey_id'),
        journey_update_id: searchParams.get('journey_update_id'),
      }
    });
  }

  return (
    <>
      <TicketHeader />
      <TicketForm onSubmit={onSubmit} onClose={() => navigate('/tickets', { replace: true })} />
    </>
  );
};

export default withPermission(CreateTicket, {
  feature: 'ticket',
  action: 'create'
});
