import NumericFormatCustom from '@components/NumericFormatCustom'
import { useStateArrayContext } from '@contexts/stateArray'
import { Box, FormControl, TextField, Typography } from '@mui/material'
import _ from 'lodash'

const Footer = () => {
    const {
        state: products,
        updateAttribute
    } = useStateArrayContext()

    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            p: '16px 24px',
            flexDirection: 'column',
            gap: 0.75
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 2
            }}>
                <Typography sx={{
                    fontSize: 14,
                    fontWeight: 'medium'
                }}>
                    Giá bán gói cố định:
                </Typography>
                <FormControl fullWidth sx={{ width: 300 }}>
                    <TextField size='small'
                        InputProps={{
                            inputComponent: NumericFormatCustom
                        }}
                        value={products?.find(x => x?.service_type === 'service')?.product_price || 0}
                        onChange={(event) => {
                            updateAttribute({
                                product_price: event?.target?.value
                            }, 0)
                        }}
                    />
                </FormControl>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 2
            }}>
                <Typography sx={{
                    fontSize: 14,
                    fontWeight: 'medium',
                }}>
                    Giá Addons:
                </Typography>
                <FormControl fullWidth sx={{ width: 300 }}>
                    <TextField
                        size='small'
                        disabled
                        InputProps={{
                            inputComponent: NumericFormatCustom
                        }}
                        value={_.sumBy(products, (product) => {
                            if (product?.service_type === 'service') return 0;
                            return parseFloat(product?.product_value) * parseFloat(product?.product_price)
                        })}
                    />
                </FormControl>
            </Box>
            <Typography sx={{ fontSize: 12, float: "right", textAlign: 'end' }} color="text.grey">
                Nếu chỉ có đơn giá bán của toàn bộ cấu hình bạn nên đặt giá bán addon về 0
            </Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 2
            }}>
                <Typography sx={{
                    fontSize: 14,
                    fontWeight: 'medium',
                }}>
                    Giá bán gói:
                </Typography>
                <FormControl fullWidth sx={{ width: 300 }}>
                    <TextField size='small'
                        disabled
                        InputProps={{
                            inputComponent: NumericFormatCustom
                        }}
                        value={_.sumBy(products, (product) => {
                            if (product?.service_type === 'service') return parseFloat(product?.product_price) || 0;
                            return parseFloat(product?.product_value) * parseFloat(product?.product_price)
                        })}
                    />
                </FormControl>
            </Box>
        </Box>
    )
}

export default Footer