import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";


class WarehouseGroupApi {
    List = () => useQueryWithCustomized(
        ["warehouse.groups.all"],
        async () => {
            return await requestWithToken(`${API_WAREHOUSE_V1_URL}/warehouse_groups`)
                .then(({ data }) => {
                    if (data?.error) return data;
                    return data?.data?.warehouse_groups?.map(o=> ({
                        value: o.warehouse_group_id,
                        label: o.warehouse_group_name,
                        ...o
                    }))
                })
                .catch((error) => {
                    return error.response;
                });
        },
        {
            // suspense: true,
            // notifyOnChangePropsExclusions: ['isStale']
        }
    );

    Types = (group_id) => useQueryWithCustomized(
        ["warehouse.groups.type.all", group_id],
        async () => {
            return await requestWithToken(`${API_WAREHOUSE_V1_URL}/warehouse_groups/${group_id}/get_warehouse_types`)
                .then(({ data }) => {
                    if (data?.error) return data;
                    return data?.data?.warehouse_types?.map(o=> ({
                        value: o.warehouse_type_id,
                        label: o.warehouse_type_name,
                        ...o
                    }))
                })
                .catch((error) => {
                    return error.response;
                });
        },
        {
            suspense: true,
            notifyOnChangePropsExclusions: ['isStale']
        }
    );
}

export default WarehouseGroupApi = new WarehouseGroupApi()