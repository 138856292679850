import { BaseComponents } from "@components/Components";
import DatePicker from "@components/DatePicker";
import { TextField } from "@mui/material";
import ExplainationResult from "./ExplainationResult";

export const JourneyComponent = {
  SaleTracking: BaseComponents.SaleTracking,
  ServiceInteresting: BaseComponents.ServiceInteresting,
  TextField: ({ ...props }) => {
    return (
      <TextField {...props} size="small" InputLabelProps={{ shrink: true }} />
    );
  },
  DatePicker: ({ ...props }) => {
    return <DatePicker {...props} />;
  },
  ExplainationResult: ExplainationResult,
};
