import React, { useEffect, useState } from "react";
import LoginIcon from "@mui/icons-material/Login";
// import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import MemoryIcon from "@mui/icons-material/Memory";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import Attachments from "./Attachments";
import Logs from "./Logs";
import Comments from "./Comments";
import { useSearchParams } from "react-router-dom";

const initialTabs = [
  {
    value: "logs",
    label: "Logs",
    icon: LoginIcon,
    component: Logs
  },
  {
    value: "attachments",
    label: "Attachments",
    icon: AttachmentIcon,
    component: Attachments
  },
  {
    value: "processing",
    label: "Processing",
    icon: MemoryIcon,
    component: Comments
  },
];

const OverviewTicket = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState(initialTabs[0]);


  useEffect(() => {
    setActiveTab(initialTabs?.find(x => x?.value === searchParams.get("activeTab")) || initialTabs[0]);
    if (searchParams.get("activeTab")) {
      document
        .getElementById("ticket-overview")
        ?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("activeTab")]);

  return (
    <Box p={2} id="ticket-overview">
      <Tabs
        component={"nav"}
        value={activeTab}
        aria-label="tabs-with-scroll"
        variant="scrollable"
        allowScrollButtonsMobile
        scrollButtons
        onChange={(_, newValue) => {
          setActiveTab(newValue);
          setSearchParams({ activeTab: newValue?.value });
        }}
      >
        {initialTabs?.map((tab, index) => (
          <Tab
            key={index}
            value={tab}
            disabled={activeTab?.value === tab?.value}
            label={
              <Box sx={{ display: "flex", gap: 1 }}>
                <tab.icon
                  color={activeTab?.value === tab?.value ? "primary" : "information"}
                />
                <Typography
                  sx={{
                    fontWeight: "medium",
                    fontSize: { xs: 14, md: 16 },
                  }}
                  color={activeTab?.value === tab?.value ? "primary" : "information"}
                >
                  {tab?.label || "Tab name"}
                </Typography>
              </Box>
            }
          />
        ))}
      </Tabs>

      <Box mt={2}>{React.createElement(activeTab?.component, {
      })}</Box>
    </Box>
  );
};

export default OverviewTicket;
