import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import React from "react";
import _ from "lodash";
import CellConfig from "@components/DataGrid/CellConfig2";
import { IconButton, Link , Box, Popper, Typography} from "@mui/material";
import { NavLink } from "react-router-dom";
import { MoreVertOutlined } from "@mui/icons-material";
import Cell from "@components/DataGrid/Cell";
import DataGridProCustomize from "@components/DataGridProCustomize";
import PopperAction from "@components/PopperAction";
import { getMuiDataGridRowHeight } from "@/functions";
const DeviceTable = ({ warehouse_resource =  {}, resourceItems, setResourceItems,  type = "create", service_tag = null }) => {
    const {data: warehouse_boxes } = WarehouseBoxApi.getAll()
    const [popper, setPopper] = React.useState({
        anchorEl: undefined,
        data: undefined
    })

    const [actions] = React.useState([
        {
            name: "delete",
            handleClick: (data) => {
                removeResourceItems(data)
            },
            icon: "Delete",
            label: "Xoá"
        },
    ])
    const removeResourceItems = (data) => {
        setResourceItems(prevRows =>
            [...prevRows?.filter((item, index) => item?.warehouse_item_id !== data?.warehouse_item_id)] || [])
    }

    const initialColumns = [
        {
            field: 'warehouse_item_name',
            headerName: 'Tên thiết bị',
            width: 250,
            // renderCell: ({row}) => <DrawerWarehouseItem items={[row]}/>
        },
        {
            field: 'warehouse_item_service_tag',
            headerName: 'Service Tag / Serial Number',
            width: 250,
            renderCell: ({ value }) => 
                <Typography 
                    sx={{
                       color: service_tag && service_tag === value ?  "Red": null,
                       fontSize: 14
                    }}
                >
                    {value}
                </Typography>
        },
        , {
            field: "capacity",
            headerName: "Capacity",
            width: 250,
            sortable: false,
            renderCell: ({ value }) => (value && <CellConfig configures={value} />)
        },
        {
            field: 'warehouse_box_name',
            headerName: 'Kho / Datacenter',
            valueGetter: ({ row }) => {
                if (row.warehouse_box_item && row.warehouse_box_item.warehouse_box_item_reference_type === "WarehouseBox") {
                    const box = _.find(warehouse_boxes, { warehouse_box_id: row.warehouse_box_item.warehouse_box_item_reference_id })
                    return box
                }
                else if (row.warehouse_box_item && row.warehouse_box_item.warehouse_box_item_reference_type === "WarehouseRack" && row.warehouse_rack) {
                    const box = _.find(warehouse_boxes, { warehouse_box_id: row.warehouse_rack?.warehouse_box_id })
                    return box
                }
                return ""
            },
            renderCell: ({ value, row }) =>
                <>
                    {value ? (value.warehouse_box_type === 0) ? <>{value.warehouse_box_name}</> : <Cell content={value.warehouse_box_name.toUpperCase()} /> : ""}
                </>
            ,
            width: 250,
        },
        {
            field: 'warehouse_box_rack',
            headerName: 'Tủ Rack',
            valueGetter: ({row}) =>  {
                if( row.warehouse_box_item && row.warehouse_box_item.warehouse_box_item_reference_type === "WarehouseRack" && row.warehouse_rack ){
                    return row.warehouse_rack
                }
                return null
            },
            renderCell: ({ value, row }) =>
                <>
                {value ? <Link  component={NavLink} to={`/warehouse/racks/${value.warehouse_rack_id}?id=${row.warehouse_item_id}`} > <Cell content={value.warehouse_rack_name.toUpperCase()}/> </Link> : ""}
                </>,
            width: 200,
        },
        {
            field: 'warehouse_box_position',
            headerName: 'Vị Trí',
            valueGetter: ({ row }) => {
                if (row.warehouse_box_item && row.warehouse_box_item.warehouse_box_item_reference_type === "WarehouseRack" && row.warehouse_rack) {
                    return row.warehouse_box_item?.warehouse_box_item_position + ((row.warehouse_item_form_factor === 0 || row.warehouse_item_form_factor === 1) ? "" : ` - ${row.warehouse_box_item?.warehouse_box_item_position + (row.warehouse_item_form_factor - 1)}`)
                }
                return ""
            },
            width: 200,
        },
        {
            field: 'product',
            headerName: 'Dịch Vụ',
            valueGetter: ({ row }) => {
                return row.warehouse_resource_services.map(o=> o.warehouse_resource_service_name).join(" ,")
            },
            renderCell: ({ value }) =>
                {value && <Cell content={value.toUpperCase()} />},
            width: 150,
        },
        {
            field: 'cluster',
            headerName: 'Cụm Đầu Tư',
            valueGetter: ({row}) => row.warehouse_resource_services.map(o=> o.warehouse_resource_service_code).join(" ,"),
            renderCell: ({ value }) =>
                {value && <Cell content={value.toUpperCase()} />},
            width: 150,
        },
        {
            field: 'warehouse_box_item_service_deploy',
            headerName: 'Dịch Vụ Deploy',
            valueGetter: ({row}) => row?.warehouse_box_item?.warehouse_box_item_service_deploy,
            renderCell: ({ value }) =>
                {value && <Cell content={value.toUpperCase()} />},
            width: 150,
        },
        {
            field: "id",
            headerName: "Thao Tác",
            hide: (["create", "edit"].includes(type) ) ? false : true,
            width: 80,
            renderCell: ({ row }) =>(<>
                {
                    
                    (row.warehouse_resource_services?.length > 0 && warehouse_resource?.warehouse_resource_type === "dedicate" ) ? 
                    ""
                    :
                <Box sx={{
                    width: '100%', display: 'flex', justifyContent: 'center',
                  }}>
                    <IconButton
                      title="Thao Tác"
                      onClick={(event) => {
                        setPopper(prev => ({ ...prev, anchorEl: event?.target, data: row }))
                      }}
                    >
                      <MoreVertOutlined sx={{ m: 'auto' }} />
                    </IconButton>
                  </Box>
            }
            </>)
        },
    ]

    return(
        <>
            <DataGridProCustomize
                rows={resourceItems.map(o => ({...o, id: o["warehouse_box_item"]["warehouse_box_item_id"] }))}
                columns={initialColumns?.filter(col => !col?.hide)}
                getRowHeight={(params) => getMuiDataGridRowHeight(params?.model?.capacity, ["capacity_cpu", "capacity_ram", "capacity_storage", "capacity_power_supply"], 2)}
                pagination
                initialState={{
                    pagination: { paginationModel: { pageSize: 20 } },
                }}
                pageSize={20}
                pageSizeOptions={[10, 20, 50, 100]}
                rowCount={resourceItems.length}
            />
            {popper?.anchorEl !== undefined && <Popper anchorEl={popper?.anchorEl}
                open={Boolean(popper.anchorEl)}>
                <PopperAction
                    {...popper}
                    actions={actions}
                    handleClose={() => setPopper(prev => ({ ...prev, anchorEl: undefined }))} />
            </Popper>}
        </>
    )
}
export default DeviceTable;