import React from 'react';
// eslint-disable-next-line no-unused-vars
import { decodeBase64 } from '../functions';


const queryString = require('query-string');

const DEFAULT_OPS = {
	skipNull: true,
	arrayFormat: 'bracket',
	skipEmptyString: true,
};

//sort[0]=title:asc&sort[1]=slug:desc

class OrderUtils {
	parse = (hash) => {
		const object = {};
		retloop: for (var input in hash) {
			const value = hash[input];
			const parts = input.split('[');
			if (parts[0] !== 'sort') {
				continue;
			}

			let last = object;

			for (let i in parts) {
				let part = parts[i];

				if (part.split('').pop() === ']') {
					part = part.slice(0, -1);
				}

				if (Number(i) === Number(parts.length - 1)) {
					last[part] = value;

					continue retloop;
				} else if (!last.hasOwnProperty(part)) {
					last[part] = {};
				}

				last = last[part];
			}
		}
		return object;
	}

	stringify = (query) => {
		return Object.entries(query)
			.map((k) => {
				return `sort[${k[0]}]=${k[1]}`
			})
			.flat()
			.join('&');
	};
}

const rebuildDefaultOrdered = (key) => {
	switch (key) {
		case "invoices.list.sorters":
			return `sort[0]=invoice_duedate:asc`
		case "management.customers.potential.list.sorters":
			return `sort[0]=customer_id:desc`
		default:
			return ``
	}
}

export const Order = new OrderUtils();

const useOrders = (key) => {

	const orders = decodeBase64(localStorage.getItem(key) || '') || rebuildDefaultOrdered(key)

	const [state, setState] = React.useState(() => {
		return Order.parse(queryString?.parse(orders, DEFAULT_OPS))?.['sort'] || {};
	});

	return [state, setState];
};

export default useOrders;
