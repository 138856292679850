import Dates from "./Dates";
import Committed from "./Committed";
import Prepaid from "./Prepaid";
import Price from "./Price";
import PriceSubtotal from "./PriceSubtotal";
import Terminal from "./Terminal";

const COMMITMENT = {
    Committed,
    Terminal,
    Prepaid,
    PriceSubtotal,
    Price,
    Dates
}
export default COMMITMENT