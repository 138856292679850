import ProviderApi from "@/apis/beta/Provider/ProviderApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import { formatBytes, format_date_short } from "@/functions";
import useDataGridParams from "@/hooks/useDataGridParams";
import { CAPACYTIES, WAREHOUSE_STATUS_BUY } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import Cell from "@components/DataGrid/Cell";
import CellConfig from "@components/DataGrid/CellConfig2";
import DialogShowJsonData from "@components/DialogShowJsonData";
import { Box, Link } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Filters from "../../WarehouseDepreciations/Depreciations/Filters";
import DeviceAnalysist from "../Statistic";

const WarehouseItemDataGridDeviceExported = ({
  page = { page: 0, pageSize: 20 },
  setPage = null,//must be callback
  resetPage = null,//must be callback
  types_id,
  name,
  customer = false,
  analysist = true,
  analysist_length = false,
  item_exports = false,
  API = WarehouseItemApi.Index,
  showFilter = true,
  activeTab
}) => {
  const view = "default";
  const [dialogInfor, setDialogInfor] = useState({
    open: false,
    data: {},
    title: "Thông tin người mua"
  })
  const initialColumns = [
    {
      field: "warehouse_item_type",
      headerName: "Loại",
      minWidth: 120,
      valueGetter: ({ row }) =>
        `${_.find(warehouse_models, {
          warehouse_model_id: parseInt(
            row?.warehouse_model.warehouse_model_id
          ),
        })?.warehouse_type?.warehouse_type_name
        }`,
      renderCell: ({ value, row }) => <Cell content={value?.toUpperCase()} />,
    },
    {
      field: "warehouse_item_name",
      headerName: "Tên",
      minWidth: 150,
      resizable: true,
      renderCell: ({ value, row }) => (
        <Link
          component={NavLink}
          to={
            view === "default"
              ? `/warehouse/items/${row.warehouse_item_id}/detail`
              : `/warehouse/assemble-items/${row.warehouse_item_id}/edit`
          }
        >
          {" "}
          <Cell content={value} />
        </Link>
      ),
    },
    {
      field: "warehouse_process",
      headerName: "Người mua",
      minWidth: 150,
      valueGetter: ({ value, row }) => {
        return value?.warehouse_process_to || ""
      },
      renderCell: ({ value }) =>
        <Cell content={value} />
      ,
    },
    {
      field: "warehouse_item_service_tag",
      headerName: "Service Tag / Serial Number",
      sortable: false,
      width: 250,
      renderCell: ({ value, row }) => <Cell content={value} />,
    },
    {
      field: "capacity",
      headerName: "Capacity",
      width: 200,
      sortable: true,
      renderCell: ({ value }) => value && <CellConfig configures={value} />,
    },
    {
      field: "warehouse_item_status",
      headerName: "Tình trạng lúc nhập",
      minWidth: 150,
      renderCell: ({ value }) =>
        _.find(WAREHOUSE_STATUS_BUY, {
          value: parseInt(value),
        })?.label
      ,
    },
    {
      field: "details",
      headerName: "Chi tiết sản phẩm",
      width: 100,
      renderCell: ({ value }) =>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            alignItems: "center",
          }}
          onClick={() => onOpenDialogData(value)}
        >
          <Link
            sx={{
              textDecoration: "underline",
            }}
            color="primary"
          >
            Xem chi tiết
          </Link>
        </Box>
    },
    {
      field: "warehouse_process_export",
      headerName: "Phiếu kho",
      minWidth: 150,
      valueGetter: ({ row }) => {
        const value = row?.warehouse_process
        return value?.warehouse_process_code || ""
      },
      renderCell: ({ value, row }) =>
        // 
        <Link
          component={NavLink}
          to={`/warehouse/processes/${row?.warehouse_process?.warehouse_process_id}/detail`}>
          <Cell content={value} />
        </Link>
      ,
    },
    {
      field: "warehouse_process_created_at",
      headerName: "Ngày tạo phiếu",
      minWidth: 150,
      valueGetter: ({ row }) => {
        const value = row?.warehouse_process
        return value?.warehouse_process_created_at || null
      },
      renderCell: ({ value }) =>
        <Cell content={value ? format_date_short(value) : ""} />
      ,
    },
    {
      field: "warranty_start_at",
      headerName: "Ngày bắt đầu bảo hành xuất bán",
      width: 200,
      valueGetter: ({ row }) => {
        const value = row.warehouse_process
        const metaData = value?.warehouse_process_metadata || []
        let item = metaData?.filter((item) => item?.warehouse_item_id === row?.warehouse_item_id) || []
        if (item?.length === 0) {
          item = metaData?.map((item_parent) => {
            return item_parent?.children?.filter((item_child) => item_child?.warehouse_item_id === row?.warehouse_item_id)[0] || null
          })
        }
        item = item.filter((item) => item !== null)
        const warranty_start = item[0]?.warranty_start_at || "";
        return warranty_start || null
      },
      renderCell: ({ value }) => <Cell content={value ? format_date_short(value) : ""} />,
    },
    {
      field: "warnanty_expired_at",
      headerName: "Ngày kết thúc bảo hành xuất bán",
      width: 200,
      valueGetter: ({ row }) => {
        const value = row?.warehouse_process
        const metaData = value?.warehouse_process_metadata || []
        let item = metaData?.filter((item) => item?.warehouse_item_id === row?.warehouse_item_id) || []
        if (item?.length === 0) {
          item = metaData?.map((item_parent) => {
            return item_parent?.children?.filter((item_child) => item_child?.warehouse_item_id === row?.warehouse_item_id)[0] || null
          })
        }
        item = item.filter((item) => item !== null)

        const warranty_end = item[0]?.warranty_expired_at || "";

        return warranty_end || null
      },
      renderCell: ({ value, row }) => <Cell content={value ? format_date_short(value) : ""} />,
    },

    {
      field: "provider_id",
      headerName: "Nhà Cung Cấp",
      hide: true,
      valueGetter: ({ row }) =>
        _.find(providers, { provider_id: row.provider_id })?.provider_name,
      renderCell: ({ value, row }) => <Cell content={value.toUpperCase()} />,
      width: 150,
    },
    {
      field: "warehouse_item_form_factor",
      headerName: "Form Factor",
      hide: true,
      sortable: true,
      valueGetter: ({ row }) =>
        row.warehouse_item_form_factor === 0
          ? ""
          : row.warehouse_item_form_factor + " U",
      width: 150,
    },
    {
      field: "warehouse_item_status_from_process",
      headerName: "Tình Trạng lúc bán",
      valueGetter: ({ row }) => {
        const value = row?.warehouse_process
        const metaData = value?.warehouse_process_metadata || []
        // const item = metaData?.filter((item) => item?.warehouse_item_id === row?.warehouse_item_id) || []
        let item = metaData?.filter((item) => item?.warehouse_item_id === row?.warehouse_item_id) || []
        if (item?.length === 0) {
          item = metaData?.map((item_parent) => {
            return item_parent?.children?.filter((item_child) => item_child?.warehouse_item_id === row?.warehouse_item_id)[0] || null
          })
        }
        item = item.filter((item) => item !== null)
        const status = item[0]?.warehouse_item_status || "";
        return _.find(WAREHOUSE_STATUS_BUY, {
          value: parseInt(status),
        })?.label
      },
      width: 150,
    },
  ];

  const {
    columns,
    debouncedSearchQuery,
    // page,
    searchQuery,
    setColumns,
    // setPage,
    setSearchQuery,
    query,
    updateQuery,
  } = useDataGridParams(initialColumns, page?.pageSize || 20, "", false, resetPage, `item=${activeTab}`);

  const params = {
    type: types_id,
    page: page?.page + 1 || 1,
    pageSize: page?.pageSize || 20,
    searchQuery: debouncedSearchQuery,
    query,
  };

  if (customer) {
    params.customer = customer;
  }

  const { data, isFetching } = API(params);

  const { data: providers } = ProviderApi.getAll();
  const { data: warehouse_models } = WarehouseModelApi.All({ short: true });
  const loadDetails = () => {
    let details = {};
    data.warehouse_items.map((row, index) => {
      //processorc
      const cpus = _.filter(row.children, (o) => {
        return ["CPU"].includes(o?.warehouse_type?.warehouse_type_name);
      });
      const load_cpus = _.map(cpus, (o) => (
        `${o.total} X ${o.warehouse_item_name} `
      ));
      ///memory
      const rams = _.filter(row.children, (o) => {
        return ["RAM"].includes(o?.warehouse_type?.warehouse_type_name);
      });
      const load_rams = _.map(rams, (o) => (
        `${o.total} X ${o.warehouse_item_name} `));
      //////storages
      const storages = _.filter(row.children, (o) => {
        return ["SSD", "SAS", "HDD", "NVME"].includes(
          o?.warehouse_type?.warehouse_type_name
        );
      });
      const load_storages = _.map(storages, (o) => (
        `${o.total} X ${o.warehouse_item_name}`
      ));
      ///capacity
      const rebuildConfigServer = () => {
        if(!row?.capacity) return
        return CAPACYTIES?.map((o) => ({ ...o, value: row?.capacity[`${o?.key}`] || 0 }));
      };
      const isEmptyConfig =
        rebuildConfigServer()?.filter(({ value }) => value === 0)?.length === 4;

      const capacity = isEmptyConfig ? "Không có cấu hình" : rebuildConfigServer()?.map((config, index) => {
        if (config?.value !== 0)
          return (
            ["STORAGE", "RAM"].includes(config?.label)
              ? `${config?.label}: ${formatBytes(config?.value)}`
              : `${config?.label}: ${config?.value} ${config?.unit}`
          );
        else {
          return null;
        }
      })
      let caps = null;
      if (typeof (capacity) === 'object' && capacity?.length > 0) { caps = capacity?.filter((o) => o !== null && o !== undefined); }

      ///slots
      const slots = row?.warehouse_model?.slots?.filter((o) =>
        ["RAM", "DISK"].includes(o["slot_name"])
      );
      const load_slots = slots.map((o) => {
        if (o.slot_name === "RAM") {
          const rams = _.sumBy(
            _.filter(row.children, (o) => {
              return ["RAM"].includes(o?.warehouse_type?.warehouse_type_name);
            }),
            (o) => o?.total
          );
          return `${o.slot_name}: ${o?.slot_number - rams}`;
        } else {
          const storages = _.sumBy(
            _.filter(row.children, (o) => {
              return ["SSD", "SAS", "HDD", "NVME"].includes(
                o?.warehouse_type?.warehouse_type_name
              );
            }),
            (o) => o?.total
          );
          return `${o.slot_name}: ${o?.slot_number - storages}`;
        }
      });
      // const description = row?.warehouse_item_description;
      details = {
        processor: load_cpus,
        memory: load_rams,
        storage: load_storages,
        capacity: caps ? caps : capacity,
        slot: load_slots,
        description: row?.warehouse_item_description
      };
      data.warehouse_items[index]["details"] = details
    }
    )
  }
  if (data?.warehouse_items) {
    loadDetails()
  }
  const onOpenDialogData = (value) => {
    setDialogInfor({ open: true, data: value })
  }
  const onClose = () => {
    setDialogInfor((prev) => ({ ...prev, open: false, data: {} }));
  }
  console.log(data);
  if (data?.warehouse_items) {
    //fix key id error same keys
    data.warehouse_items = data?.warehouse_items?.map((row, index) => {
      return {
        ...row,
        id: index
      }
    })
  }
  return (
    <Box>
      <DialogShowJsonData
        open={dialogInfor.open}
        onClose={onClose}
        title={dialogInfor.title}
        metadata={dialogInfor.data}
      // configKey = {{warehouse_process_to: "Người Nhận",
      //   warehouse_item_warranty_start_at: "Ngày bắt đầu bảo hành",
      //   warehouse_item_warranty_expired_at: "Ngày kết thúc bảo hành",
      // }}
      />
      {analysist && (
        <DeviceAnalysist
          debouncedSearchQuery={debouncedSearchQuery}
          type={types_id}
          device
          query={query}
        />
      )}

      {showFilter && (
        <Filters
          show={
            {
              warehouse_type: true,
              warehouse_model: true,
            }
          }
          updateQuery={updateQuery}
          query={query}
          types_ids={types_id}
          useFilterModel
          customer={customer}
        />
      )}

      <DataGridCustomize
        rows={data?.warehouse_items || []}
        columns={columns}
        loading={isFetching}
        getRowHeight={({ model }) => {
          const ROW_HEIGHT = 24;
          const PADDING_Y = 8;
          return Math.max(
            Object.keys(
              _.omit(model?.capacity, [
                "capacity_updated_at",
                "capacity_created_at",
                "warehouse_item_id",
                "capacity_id",
              ])
            )?.filter((item) => model?.capacity[item]).length *
            ROW_HEIGHT +
            PADDING_Y,
            52
          );
        }}
        rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
        componentsProps={{
          toolbar: {
            store: `${name}.list.hiddenColumns`,
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: name,
            inputSearch: {
              placeholder: "Tìm kiếm,...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        sx={{ mt: 2 }}
      />

    </Box>
  );
};

export default WarehouseItemDataGridDeviceExported;
