import InvoiceApi from "@/apis/beta/Invoice/InvoiceApi";
import FormatTextNumeralVND from "@/components/shared/FormatTextNumeralVND";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import PopperAction from "@components/PopperAction";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import {
  Box,
  Collapse,
  FormControlLabel,
  Paper,
  Popper,
  Switch,
  Typography
} from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PrivateToolbar from "./PrivateToolbar";
import { Table } from "./Table";

export const InvoiceItemList = () => {
  const { invoice_id } = useParams();

  const [popper, setPopper] = React.useState({
    anchorEl: undefined,
    data: undefined,
    actions: [],
  });
  const handleClosePopper = () =>
    setPopper((prev) => ({
      ...prev,
      anchorEl: undefined,
      data: undefined,
      actions: [],
    }));

  const { data: invoice } = InvoiceApi.Information({ invoiceId: invoice_id });
  const { data: items } = InvoiceApi.InvoiceItems({
    invoiceId: invoice_id,
  });

  const [mode, setMode] = useState(localStorage.getItem('setting.invoice.viewMode') && !!localStorage.getItem('setting.invoice.viewMode')?.match(/(grouping|full-line)/) ? localStorage.getItem('setting.invoice.viewMode') : "full-line");

  return (
    <Box aria-label={`Invoice.Items`} sx={{ mt: 2 }}>
      <Typography sx={{ fontSize: 18, fontWeight: "medium", mb: 0.5 }}>
        Thông Tin Chi Tiết Hoá Đơn
      </Typography>
      <Box sx={{ mb: 2 }}>
        <FormControlLabel
          label="Nhóm Dòng Hoá Đơn Theo Dịch Vụ"
          control={
            <Switch
              checked={mode === "grouping"}
              size="small"
              onChange={() => {
                localStorage.setItem('setting.invoice.viewMode', mode === "full-line" ? "grouping" : "full-line")
                setMode(mode === "full-line" ? "grouping" : "full-line")
              }
              }
            />}
        />
      </Box>
      {popper?.anchorEl !== undefined && popper?.actions?.length > 0 && (
        <Popper anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)}>
          <PopperAction {...popper} handleClose={handleClosePopper} />
        </Popper>
      )}
      <Paper
        sx={{
          minWidth: {
            xl: 920,
            md: 700,
            sm: 500,
            xs: 300,
          },
        }}
      >
        {invoice?.invoice_status === 1 && (
          <PrivateToolbar
            items={items}
            invoice={invoice}
          />
        )}
        <Collapse in={mode === 'grouping'}>
          <Table.Premium data={items} invoice={invoice} />
        </Collapse>
        <Collapse in={mode === 'full-line'}>
          <Table.Standard data={items} invoice={invoice} />
        </Collapse>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 3 }}>
          <Box sx={{ width: 300 }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <Typography sx={{ color: "#777680" }}>Tạm Tính:</Typography>
              <FormatTextNumeralVND
                sx={{ fontSize: 16 }}
                value={_.sumBy(items, (item) => item["invoice_item_amount"])}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <Typography sx={{ color: "#777680" }}>@Thuế:</Typography>
              <FormatTextNumeralVND
                sx={{ fontSize: 16 }}
                value={_.sumBy(items, (item) => {
                  if (item.invoice_item_tax)
                    return item?.invoice_item_amount * 0.1;
                  return 0;
                })}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: "#777680" }}>Tổng Hoá Đơn:</Typography>
              <FormatTextNumeralVND
                sx={{ fontSize: 16, fontWeight: "medium" }}
                value={_.sumBy(items, (item) => {
                  if (item.invoice_item_tax)
                    return (
                      item?.invoice_item_amount * 0.1 +
                      item?.invoice_item_amount
                    );
                  return item?.invoice_item_amount;
                })}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box >
  );
};

export default withSuspense(
  withPermission(InvoiceItemList, {
    feature: "invoice",
    action: "invoice-item-index",
    type: "none",
  }),
  MuiSkeleton["DataGrid"]
);
