import InvoiceApi from "@/apis/beta/Invoice/InvoiceApi";
import DataGrid from "@/components/DataGrid";
import { CellLink, CellPricing, CellStatus } from "@/components/DataGrid/Cell";
import DataGridFilter from "@/components/DataGrid/Filter";
import {
  buildQueryFilterCondition,
  dayjsCompare,
  format_date_short,
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";
import useOrders, { Order } from "@/hooks/useOrders";
import {
  INITIALIZE_SERVICE_GROUPS,
  INVOICE_PAYMENT_METHODS,
  INVOICE_STATUS_TYPES,
} from "@/libs/constants";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { CheckCircleOutline } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import { useLocation } from "react-router-dom";
import InvoiceServices from "./InvoiceService";
import QuickView from "./QuickView";
import Statistical from "./Statistical";

const List = ({ store = "invoices.list.filters" }) => {
  const location = useLocation();

  const [sort, updateSort] = useOrders(`invoices.list.sorters`, location);
  const [query, updateQuery] = useFilters(store, location);

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [anchorEl, setAnchorEl] = React.useState({
    ref: undefined,
    anchorEl: undefined,
    data: undefined,
  });

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const filterableInvoice = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    let fieldQueries = [
      {
        name: "invoices.invoice_id",
        operator: "$eq",
        disabled: !parseInt(debouncedSearchQuery),
        value: parseInt(debouncedSearchQuery),
      },
      {
        name: "invoice_number",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "customers.customer_id",
        operator: "$eq",
        disabled: !parseInt(debouncedSearchQuery),
        value: parseInt(debouncedSearchQuery),
      },
      {
        name: "customers.customer_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];

    // eslint-disable-next-line no-useless-escape
    const regex = new RegExp(/[0-9]{1,2}[.-\/]{1}[0-9]{1,2}[.-\/]{1}[0-9]{4}/);
    if (
      debouncedSearchQuery?.match(regex) &&
      debouncedSearchQuery?.match(regex)?.length > 0
    ) {
      fieldQueries.push({
        name: "service_management_billings.service_billing_enddate",
        operator: "$eq",
        value: debouncedSearchQuery,
      });
    }
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const initialColumns = [
    {
      field: "invoices.invoice_id",
      headerName: "ID - Invoice",
      width: 100,
      hideable: false,
      sortable: true,
      valueGetter: ({ row }) => ({
        label: row?.invoice_id,
        href: ["invoices", row?.invoice_id],
      }),
      operators: { type: "number" },
      renderCell: ({ value }) => <CellLink data={value} />,
    },
    {
      field: "invoice_number",
      headerName: "Số Hoá Đơn",
      width: 100,
      hide: true,
      operators: { type: "string" },
    },
    {
      field: "invoices.customer_id",
      headerName: "Tên Khách Hàng",
      width: 250,
      valueGetter: ({ row }) => ({
        label: `${row?.customer_company_name || row?.customer_name}`,
        href: ["customers", row?.customer_id, "finance"],
      }),
      renderCell: ({ value }) => <CellLink data={value} />,
    },
    {
      field: "service_product_id",
      headerName: "Dịch Vụ",
      minWidth: 300,
      valueGetter: ({ row }) => row?.invoice_services || [],
      renderCell: ({ value }) => <InvoiceServices services={value} />,
    },
    {
      field: "invoice_total",
      headerName: "Tổng Tiền",
      width: 150,
      sortable: true,
      operators: { type: "price" },
      renderCell: ({ value }) => <CellPricing price={value} />,
    },
    {
      field: "invoice_metadata",
      headerName: "Nội Dung/ Mô Tả",
      minWidth: 300,
      renderCell: ({ value, row }) => {
        return (
          <Box
            sx={{ width: "100%" }}
            onClick={(event) => {
              setAnchorEl({
                refEl: event?.currentTarget,
                anchorEl: true,
                data: row,
              });
            }}
          >
            <Typography
              sx={{ fontSize: 14, textDecoration: "underline" }}
              color="primary"
            >
              {value}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "invoice_billing",
      headerName: "Thời Gian Áp Dụng",
      minWidth: 230,
      valueGetter: ({ row }) => {
        return `${format_date_short(
          row?.invoice_billing_startdate
        )} - ${format_date_short(row?.invoice_billing_enddate)}`;
      },
    },
    {
      field: "tw_services.service_stopped_at",
      headerName: "Ngày Kết Thúc Dịch Vụ",
      width: 200,
      hide: true,
      operators: { type: "date" },
      renderCell: ({ row }) => (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            p: "4px",
          }}
        >
          {row?.invoice_services?.map((item, index) => {
            return (
              <Tooltip title={item?.service_name} key={index}>
                <Typography sx={{ fontSize: 14 }}>
                  {item?.service_billing_enddate
                    ? format_date_short(item?.service_billing_enddate)
                    : ""}
                </Typography>
              </Tooltip>
            );
          })}
        </Box>
      ),
    },
    {
      field: "send_email",
      width: 100,
      headerName: "Gửi Email",
      renderCell: ({ value }) => (
        <>
          {value ? (
            <CheckCircleOutline
              sx={{ fontSize: 20, color: "#357a38", mr: 0.5 }}
            />
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      field: "invoices.invoice_status",
      headerName: "Trạng Thái",
      width: 160,
      valueGetter: ({ row }) => {
        return INVOICE_STATUS_TYPES.find((t) => t.value === row.invoice_status);
      },
      sortable: true,
      operators: {
        type: "object",
        collections: INVOICE_STATUS_TYPES,
      },
      renderCell: ({ value }) => (
        <CellStatus component="muiChip" data={value} />
      ),
    },
    {
      field: "invoice_duedate",
      headerName: "Hạn Thanh Toán",
      sortable: true,
      width: 150,
      operators: { type: "date" },
      renderCell: ({ row, value }) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            color={
              row?.invoice_status !== 1
                ? "unset"
                : dayjsCompare(dayjs(), dayjs(value), "d") > 0
                ? "custom.error"
                : "unset"
            }
            sx={{ fontSize: 14, m: "auto 0" }}
          >
            {format_date_short(value)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "invoice_tax",
      headerName: "Thuế",
      width: 150,
      operators: { type: "price" },
      renderCell: ({ value }) => <CellPricing price={value} />,
    },
    {
      field: "invoice_subtotal",
      headerName: "Tạm Tính",
      width: 150,
      operators: { type: "price" },
      renderCell: ({ value }) => <CellPricing price={value} />,
    },
    {
      field: "invoice_created_at",
      headerName: "Ngày Tạo",
      width: 150,
      hide: true,
      sortable: true,
      valueFomatter: ({ value }) => format_date_short(value),
      operators: { type: "date" },
    },
    {
      field: "invoice_paid",
      headerName: "Ngày Thanh Toán",
      width: 150,
      sortable: true,
      operators: { type: "date" },
      valueFomatter: ({ value }) => (value ? format_date_short(value) : ""),
    },
    {
      field: "invoice_description",
      headerName: "Mô Tả",
      width: 150,
      hide: true,
    },
    {
      field: "invoices.commissions",
      headerName: "Nhân Viên Phụ Trách",
      width: 250,
      hide: true,
      valueGetter: ({ row }) => row?.employee_name || "---",
    },
    {
      field: "invoices.invoice_payment_method",
      headerName: "Phương thức thanh toán",
      width: 200,
      hide: true,
      operators: {
        type: "object",
        collections: INVOICE_PAYMENT_METHODS,
      },
      valueGetter: ({ row }) => {
        return (
          INVOICE_PAYMENT_METHODS?.find(
            (meth) => meth?.value === row?.invoice_payment_method
          )?.label || "---"
        );
      },
    },
  ];

  const hiddenColumnOnLocalStorage =
    JSON.parse(localStorage.getItem("invoices.list.hiddenColumns")) || [];

  const [columns, setColumns] = React.useState(
    initialColumns?.map((column) => {
      if (hiddenColumnOnLocalStorage?.includes(column?.field)) {
        return { ...column, hide: true };
      }
      return { ...column };
    }) || initialColumns
  );

  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });

  const { data, isFetching } = InvoiceApi.List({
    sorters: Order.stringify(sort),
    page: page?.page + 1,
    pageSize: page?.pageSize || 20,
    query: Filter.stringify(query),
    searchQuery: filterableInvoice() !== "" ? filterableInvoice() : null,
  });

  return (
    <Box>
      {Boolean(anchorEl?.anchorEl) && anchorEl?.data !== undefined && (
        <QuickView
          setAnchorEl={setAnchorEl}
          refEl={anchorEl?.refEl}
          invoice_id={anchorEl?.data?.invoice_id}
        />
      )}
      <Statistical
        page={page}
        searchQuery={filterableInvoice()}
        query={Filter.stringify(query)}
      />
      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: store,
          filterables: [
            ...filterables,
            {
              field: "tw_services.service_product_id",
              label: "Nhóm Dịch Vụ",
              operators: {
                type: "object",
                operators: ["$in"],
                collections: INITIALIZE_SERVICE_GROUPS,
              },
            },
          ],
        }}
      />
      <DataGrid
        slots={{
          sorters: {
            key: `invoices.list.sorters`,
            sortables: sort,
            callback: updateSort,
          },
        }}
        rows={
          data?.invoices?.map((invoice) => ({
            ...invoice,
            id: invoice?.invoice_id,
          })) || []
        }
        columns={columns}
        loading={isFetching}
        getRowHeight={({ model }) => {
          const PADDING_OFFSET_Y = 8;
          const SERVICES =
            24 * (model?.invoice_services?.length || 0) + PADDING_OFFSET_Y;
          return Math.max(SERVICES, 52);
        }}
        componentsProps={{
          toolbar: {
            store: "invoices.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            inputSearch: {
              placeholder:
                "Nhập ID, số hoá đơn/ tên khách hàng/ ngày kết thúc dịch vụ tìm kiếm...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        getRowClassName={({ row }) => {
          if (row?.invoice_status !== 1) return ``;
          const today = dayjs();
          let classNames = ``;

          if (dayjsCompare(row?.invoice_duedate, today, "d") < 0) {
            classNames = `row-warning`;
          }
          return clsx(`grid-dc-vnetwork`, classNames);
        }}
        rowCount={data?.pagination?.total}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(List, {
    feature: "invoice",
    action: "index",
  }),
  MuiSkeleton["DataGrid"]
);
