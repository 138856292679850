import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseBoxLogApi from "@/apis/beta/Warehouse/WarehouseBoxLogApi";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import { useEmployeeContext } from "@contexts/employee";
import { useNotificationContext } from "@contexts/notification";
import { Box, Grid, Paper, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useMutation } from "react-query";

const Information = ({ data, callbackRefetch, warehouse_items, refetchLogs }) => {
  const { setNotification } = useNotificationContext();

  const { data: warehouse_models } = WarehouseModelApi.All();
  const [rack_ids] = React.useState(
    _.map(data.warehouse_racks, (o) => o.warehouse_rack_id)
  );
  const [switch_ids] = React.useState(
    _.map(
      _.filter(warehouse_models, (o) => o.warehouse_type_name === "SWITCH"),
      (o) => o.warehouse_model_id
    )
  );
  const [server_ids] = React.useState(
    _.map(
      _.filter(warehouse_models, (o) => o.warehouse_type_name === "SERVER"),
      (o) => o.warehouse_model_id
    )
  ); 
  const prevDecription = data?.warehouse_box_description;

  const {employee} = useEmployeeContext();
  // console.log(employee);
  const changeUpdateDescription = async (event) => {
    if (event?.target?.value?.trim() === data?.warehouse_box_description)
      return;

    await WarehouseBoxApi.update(data?.warehouse_box_id, {
      warehouse_box_description: event.target.value,
    }).then(async (response) => {
      const dataReq = response.data;
      if (dataReq.status !== 200) {
        setNotification({
          open: true,
          message: data.errors[0].message,
          severity: "error",
        });
        return;
      }
      await WarehouseBoxLogApi.createBoxLog({
        warehouse_box_name: data?.warehouse_box_name,
        warehouse_box_id: data?.warehouse_box_id,
        warehouse_box_update_type: "UPDATE-DESCRIPTION",
        metadata: {previous: {warehouse_box_description: prevDecription}, new: {warehouse_box_description: event.target.value}},
        warehouse_box_updater_id: employee?.employee_id,
        warehouse_box_updater_name: employee?.employee_name,
        warehouse_box_update_description: "Cập nhật mô tả IDC ",
      })
      setNotification({
        open: true,
        message: "Cập nhật thành công.",
        severity: "success",
      });
      callbackRefetch();
      refetchLogs();
    });
  };

  const { mutateAsync: updateDesscription, isLoading } = useMutation(
    changeUpdateDescription
  );

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>Tên IDC</Typography>
            <Typography sx={{ color: "#00000F" }}>
              {data?.warehouse_box_name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>Khu Vực</Typography>
            <Typography sx={{ color: "#00000F" }}>
              {data?.warehouse_box_region}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Số Tủ Rack
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {data?.warehouse_racks?.length}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Tổng Nguồn (Kw)
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {_.sumBy(data?.warehouse_racks, (o) =>
                o.warehouse_rack_active ? o.warehouse_rack_power_supply : 0
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Tổng Nguồn Thiết Bị Sử Dụng (Kw)
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {_.sumBy(warehouse_items, (o) =>
                rack_ids.includes(o.warehouse_box_item_reference_id)
                  ? o.capacity_power_supply
                  : 0
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Số Thiết Bị
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {_.sumBy(warehouse_items, (o) =>
                rack_ids.includes(o.warehouse_box_item_reference_id) ? 1 : 0
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Số Server Công Ty
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {_.sumBy(warehouse_items, (o) =>
                rack_ids.includes(o.warehouse_box_item_reference_id) &&
                o.warehouse_owner_name === "VNETWORK" &&
                _.includes(server_ids, o.warehouse_model_id)
                  ? 1
                  : 0
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Số Swicth Công Ty
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {_.sumBy(warehouse_items, (o) =>
                rack_ids.includes(o.warehouse_box_item_reference_id) &&
                o.warehouse_owner_name === "VNETWORK" &&
                _.includes(switch_ids, o.warehouse_model_id)
                  ? 1
                  : 0
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Số Server Khách Hàng
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {_.sumBy(warehouse_items, (o) =>
                rack_ids.includes(o.warehouse_box_item_reference_id) &&
                o.warehouse_owner_name !== "VNETWORK" &&
                _.includes(server_ids, o.warehouse_model_id)
                  ? 1
                  : 0
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Số Swicth Khách Hàng
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {_.sumBy(warehouse_items, (o) =>
                rack_ids.includes(o.warehouse_box_item_reference_id) &&
                o.warehouse_owner_name !== "VNETWORK" &&
                _.includes(switch_ids, o.warehouse_model_id)
                  ? 1
                  : 0
              )}
            </Typography>
          </Box>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Mô Tả/ Ghi chú{" "}
            </Typography>
            <TextField
              multiline
              fullWidth
              defaultValue={data?.warehouse_box_description}
              placeholder="Nhập thông tin ghi chú..."
              onBlur={(e) => {
                updateDesscription(e);
              }}
              disabled={isLoading}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Information;
