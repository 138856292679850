import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { FormControl } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const SWIFT_FEDERATION_OPTIONALS = [
    { value: 'default', label: 'Số giờ sử dụng' },
    { value: 'stream', label: 'Theo luồng' },
]

const PaymentOptional = () => {
    const { control } = useFormContext()
    return (
        <Controller
            control={control}
            name="payment_config.payment_config_optional"
            render={({ field: { value, onChange } }) =>
                <FormControl fullWidth>
                    <SingleSelectCustomize
                        value={SWIFT_FEDERATION_OPTIONALS?.find(x => x?.value === value) || undefined}
                        options={SWIFT_FEDERATION_OPTIONALS}
                        onChange={(_, newValue) => onChange(newValue?.value || '')}
                        input={{
                            label: 'Loại đối soát *',
                            placeholder: 'Chọn loại đối soát...'
                        }}
                    />
                </FormControl>
            }
        />

    )
}

export default PaymentOptional