import ProductApi from '@/apis/beta/Product/ProductApi'
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const Product = ({ query, searchQuery }) => {
    const { control, setValue } = useFormContext()
    const { data: { products }, isFetching } = ProductApi.List({
        query, searchQuery
    })
    return (
        <Controller
            name='service_product_id'
            control={control}
            render={({ field: { value, onChange } }) => {
                const selected = products.find(product => product?.product_id === value) || null
                return (
                    <SingleSelectCustomize
                        mode='server'
                        isLoading={isFetching}
                        options={products || []}
                        value={selected}
                        formatCollection={{
                            value: 'product_id',
                            label: 'product_name'
                        }}
                        compareAttributes={['product_name']}
                        onChange={(_, newValue) => {
                            onChange(newValue?.product_id || -1)
                            setValue(`service_product_name`, newValue?.product_name)
                            setValue(`service_price`, newValue?.pricing?.pricing)
                            setValue(`product_type`, newValue?.product_type)
                        }}
                        input={{
                            label: 'Mua Thêm *',
                            placeholder: 'Chọn sản phẩm dịch vụ mua thêm...'
                        }}
                    />
                )
            }}
        />
    )
}

export default withSuspense(withPermission(Product, { feature: 'product', action: 'index', type: 'Block' }), MuiSkeleton["TextField"])