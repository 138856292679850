import { UNLIMITED } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import { useStateArrayContext } from '@contexts/stateArray'
import { Box, FormControl, TextField } from '@mui/material'
import React from 'react'

const ServicePriceExceed = ({ data }) => {
    const { updateAttribute } = useStateArrayContext()

    if (!data?.service_range_enabled) {
        return (
            <Box sx={{ width: '100%' }}>
                <FormControl fullWidth>
                    <TextField
                        size='small'
                        InputProps={{
                            inputComponent: NumericFormatCustom
                        }}
                        value={data?.service_price_exceeds?.[0]?.pricing}
                        placeholder='0'
                        onChange={(event) => {
                            updateAttribute({
                                service_price_exceeds: [{ range_start: 0, range_end: UNLIMITED, pricing: event?.target?.value }]
                            }, data?.id)
                        }}
                    />
                </FormControl>
            </Box>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.75,
            height: '100%',
            width: '100%',
            p: '6px 0'
        }}>
            {data?.service_price_exceeds?.map((price_exceed, index) => {
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5 }} key={index}>
                        <Box sx={{
                            display: 'flex', gap: 0.75,
                            minWidth: '70%'
                        }}>
                            <FormControl fullWidth>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        inputComponent: NumericFormatCustom
                                    }}
                                    value={data?.service_price_exceeds?.[index]?.range_start}
                                    placeholder='0'
                                    onChange={(event) => {
                                        updateAttribute({
                                            service_price_exceeds: [
                                                ...data?.service_price_exceeds?.slice(0, index),
                                                { ...price_exceed, range_start: event?.target?.value },
                                                ...data?.service_price_exceeds?.slice(index + 1),
                                            ]
                                        }, data?.id)
                                    }}
                                    error={index > 0 && parseFloat(price_exceed?.range_start) !== parseFloat(data?.service_price_exceeds?.[index - 1]?.range_end)}
                                    helperText={(index > 0 && parseFloat(price_exceed?.range_start) !== parseFloat(data?.service_price_exceeds?.[index - 1]?.range_end)) ? "Không khớp mốc" : ""}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        inputComponent: parseFloat(data?.service_price_exceeds?.[index]?.range_end) >= UNLIMITED ? 'input' : NumericFormatCustom
                                    }}
                                    value={price_exceed?.range_end >= UNLIMITED ? 'UNLIMITED' : price_exceed?.range_end}
                                    placeholder='0'
                                    onChange={(event) => {
                                        updateAttribute({
                                            service_price_exceeds: [
                                                ...data?.service_price_exceeds?.slice(0, index),
                                                { ...price_exceed, range_end: event?.target?.value },
                                                ...data?.service_price_exceeds?.slice(index + 1),
                                            ]
                                        }, data?.id)
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <FormControl fullWidth>
                            <TextField
                                size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom
                                }}
                                value={data?.service_price_exceeds?.[index]?.pricing}
                                placeholder='0'
                                onChange={(event) => {
                                    updateAttribute({
                                        service_price_exceeds: [
                                            ...data?.service_price_exceeds?.slice(0, index),
                                            { ...price_exceed, pricing: event?.target?.value },
                                            ...data?.service_price_exceeds?.slice(index + 1),
                                        ]
                                    }, data?.id)
                                }}
                            />
                        </FormControl>

                    </Box>

                )
            })}

        </Box>
    )
}

export default ServicePriceExceed