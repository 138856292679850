import { UNLIMITED } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { FormControl, TextField } from "@mui/material";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

const ServiceAmount = () => {
  const { watch } = useFormContext();

  const serviceAmount = useMemo(() => {
    const qty = parseInt(watch(`service_addon_qty`));
    if (qty >= UNLIMITED) return watch(`service_pricing_apply`);
    return watch(`service_pricing_apply`) * qty;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(`service_pricing_apply`), watch(`service_addon_qty`)]);

  return (
    <FormControl fullWidth sx={{ mb: 1.5 }}>
      <TextField
        label="Tạm tính *"
        InputLabelProps={{
          shrink: true,
        }}
        disabled
        size="small"
        placeholder="Tổng tạm tính..."
        value={serviceAmount}
        InputProps={{
          inputComponent: NumericFormatCustom,
        }}
      />
    </FormControl>
  );
};

export default ServiceAmount;
