import ServiceApi from '@/apis/beta/Service/ServiceApi'
import ItemPaperStatistical from '@/components/shared/ItemPaperStatistical'
import withPermission from '@/hocs/withPermission'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { Grid } from '@mui/material'
import React from 'react'
const Statistical = () => {

    const { data } = ServiceApi.Statistic()

    const formatToArray = () => {
        return [
            { label: "Tổng Dịch Vụ", value: data?.total },
            { label: "Kích Hoạt", value: data?.active },
            { label: "Chờ Xử Lý", value: data?.pending },
            { label: "Dùng Thử", value: data?.trial },
            { label: "Đã Huỷ", value: data?.cancelled },
        ]
    }
    return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
            {formatToArray().map((item, index) =>
                <Grid item xs={12} sm={6} md={2.4} key={index}>
                    <ItemPaperStatistical {...item} />
                </Grid>
            )}
        </Grid>
    )
}

export default withSuspense(
    withPermission(
        Statistical, {
        feature: "service",
        action: "statistic",
        type: "Block"
    }),
    MuiSkeleton["Statistical"]
)