import React from 'react'
import SubcontractForm from '../shared/Form'

const Update = ({
    data,
    callback,
    handleClose
}) => {
    return (
        <SubcontractForm
            type="update"
            data={data}
            callback={callback}
            handleClose={handleClose} />
    )
}

export default Update