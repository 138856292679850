import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";


class RackBandwidthApi {
    ListAll = (
        { year }
    ) =>
        useQueryWithCustomized(
            ["warehouse.datacenter_bandwidths.list",
                year
            ],
            async () => {
                return await requestWithToken(`${API_WAREHOUSE_V1_URL}/rack_bandwidths/list?year=${year}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return {
                            rack_bandwidths: data?.data?.rack_bandwidths?.map(o => (
                                {
                                    ...o,
                                    id: o.rack_bandwidth_id,
                                })
                            ),
                        };
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );
    async create(data) {
        const response = await requestWithToken(
            `${API_WAREHOUSE_V1_URL}/rack_bandwidths`,
            "POST",
            { rack_bandwidth: data }
        );
        return response?.data;
    }
    Show = ({ id }) =>
        useQueryWithCustomized(
            ["warehouse.datacenter_bandwidths.list", id],
            async () => {
                return await requestWithToken(`${API_WAREHOUSE_V1_URL}/rack_bandwidths/${id}`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.rack_bandwidth
                        // pagination: data?.data?.pagination
                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );
    GetYears = () =>
        useQueryWithCustomized(
            ["warehouse.datacenter_bandwidths.year"],
            async () => {
                return await requestWithToken(`${API_WAREHOUSE_V1_URL}/rack_bandwidths/years`)
                    .then(({ data }) => {
                        if (data?.error) return data;
                        return data?.data?.years

                    })
                    .catch((error) => {
                        return error.response;
                    });
            },
            {
                suspense: true,
                staleTime: 10 * 60 * 1000,
            }
        );
}
export default RackBandwidthApi = new RackBandwidthApi();
