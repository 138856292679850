import { PRODUCT_UNITS } from '@/libs/constants'
import NumericFormatCustom from '@components/NumericFormatCustom'
import { useStateArrayContext } from '@contexts/stateArray'
import { FormControl, TextField, Typography } from '@mui/material'

const ServiceQty = ({ data }) => {
    const methods = useStateArrayContext()
    return (
        <FormControl fullWidth>
            <TextField
                size='small'
                value={data?.product_value}
                InputProps={{
                    inputComponent: NumericFormatCustom,
                    endAdornment: <Typography sx={{ fontSize: 14, fontWeight: 'medium' }}>
                        {PRODUCT_UNITS?.slice(1)?.find(unit => unit?.value === data?.service_unit)?.label}
                    </Typography>
                }}
                onChange={(event) => {
                    methods.updateAttribute({
                        product_value: event?.target?.value
                    }, data?.id)
                }}
            />
        </FormControl>
    )
}

export default ServiceQty