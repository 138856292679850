import BreadCrumb from "@components/shared/BreadCrumb";
import MuiScrollTabs from "@components/shared/MuiScrollTabs";
import { Dns, Info } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router";

const Header = () => {
    const params =  useParams();
    const initialTabs = [
        {
            value: "information",
            label: "Thông Tin",
            href: 'information',
            icon: Info
        },
        {
            value: "services",
            label: "Tài Nguyên Dịch Vụ",
            href: 'services',
            icon: Dns
        },
    ]
    return(
        <>
            <Typography sx={{
                fontSize: { xs: 18, md: 24 },
                fontWeight: 'medium'
            }}>
                Quản lý Tài Nguyên
            </Typography>
            <BreadCrumb
                data={[
                    { label: "Quản lý Tài Nguyên", reactLink: "/warehouse/resources" },
                    { label: "Thông Tin Tài Nguyên" }
                ]}
            />
             <MuiScrollTabs prefix={`warehouse/resources/${params?.id}`}
                                    initialTabs={initialTabs}
                                />
        </>
       
    )
}
export default Header