import React, { useRef } from "react";
import { Box, LinearProgress } from "@mui/material";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";

export const LoadingOverlay = () => {
  return (
    <GridOverlay>
      <Box style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
};

const DataGridProCustomize = (props) => {
  const ref = useRef();

  React.useEffect(() => {
    if (!ref?.current) return;
    ref?.current
      ?.querySelectorAll(
        "div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )[0]
      ?.setAttribute("style", "display: none;");
  }, [props]);

  return (
    <Box ref={ref}>
      <DataGridPro
        size="small"
        sx={{
          ".MuiTablePagination-selectLabel , .MuiTablePagination-displayedRows":
            {
              mt: 2,
            },
          ".MuiDataGrid-overlayWrapper": {
            top: "50%",
          },
          width: "100%",
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            height: "5px",
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
            background: "#f0f0f0",
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
            backgroundColor: "#000333",
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
            background: "#000333",
          },
        }}
        {...props}
        columns={props.columns.map((col) => ({
          ...col,
          sortable: col?.filterable || col?.sortable,
        }))}
        autoHeight
        components={{
          LoadingOverlay: LoadingOverlay,
        }}
        componentsProps={{
          pagination: {
            ...props?.componentsProps?.pagination,
          },
          toolbar: {
            ...props?.componentsProps?.toolbar,
          },
        }}
        disableRowSelectionOnClick
        hideFooter={props?.disabled?.includes("Pagination")}
        hideFooterPagination={props?.disabled?.includes("Pagination")}
      />
    </Box>
  );
};
export default DataGridProCustomize;
