import SubcontractApi from "@/apis/beta/Contract/SubcontractApi";
import { format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { CONTRACT_NUMBER_STATUS_TYPES } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import { CellLink, CellPricing, CellStatus } from "@components/DataGrid/Cell";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import FileDownloadOutlined from "@mui/icons-material/FileDownloadOutlined";
import { Box, IconButton } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useParams } from "react-router";


const List = () => {
    const params = useParams();

    const [page, setPage] = React.useState({
        page: 0,
        pageSize: 25
    });

    const { data, isFetching } = SubcontractApi.List({ id: params?.contract_id, query: "" })

    const onOpenViewFile = (data) => {
        try {
            const link = data.link ? data.link : (data?.subcontract_attachments[0]?.attachment_file_url ? `${process.env.REACT_APP_DOMAIN_FILE}${data?.subcontract_attachments[0]?.attachment_file_url.replace("pub/", "")}` : "#")
            window.open(link, data.contract_id, "height=720, width=1080, left=100, top=100, resizable=yes, scrollbars=yes, toolbar=yes, menubar=no, location=no, directories=no, status=yes");
        } catch (e) {
            console.log(e)
        }
    }

    const [columns] = React.useState([
        {
            field: 'index',
            hideable: true,
            headerName: '.No',
            width: 80,
        }, {
            field: 'subcontracts.subcontract_code',
            headerName: 'Mã Phụ Lục Hợp Đồng (PLHĐ)',
            width: 220,
            valueGetter: ({ row }) => {
                return {
                    label: row?.subcontract_code,
                    href: ["customer_contracts", row?.contract_id, 'subcontracts', row?.subcontract_id],
                };
            },
            renderCell: ({ value }) => <CellLink data={value} />
        }, {
            field: 'subcontract_name',
            headerName: 'Tên PLHĐ',
            width: 300,
        }, {
            field: 'subcontract_value',
            headerName: 'Giá Trị PLHĐ',
            width: 150,
            renderCell: ({ value }) => <CellPricing price={value} />
        }, {
            field: 'subcontract_status', headerName: 'Trạng Thái', width: 130,
            valueGetter: ({ row }) => _.find(CONTRACT_NUMBER_STATUS_TYPES, stt => stt.value === row?.subcontract_status),
            renderCell: ({ value }) => <CellStatus component="muiChip" data={value} />
        }, {
            field: 'subcontract_created_at', headerName: 'Ngày Tạo', width: 120,
            renderCell: ({ value }) => value ? format_date_short(value) : "-"
        }, {
            field: 'subcontract_expiry_date', headerName: 'Hiệu Lực', width: 200,
            renderCell: ({ row }) => {
                if (!row?.subcontract_expiry_date || !row?.subcontract_effective_date) return '';
                return `${format_date_short(row?.subcontract_effective_date)} - ${format_date_short(row?.subcontract_expiry_date)}`
            }
        }, {
            field: 'action',
            headerName: '',
            width: 60,
            renderCell: ({ row }) => {
                if (![0, 8].includes(row?.subcontract_status)) return null;
                return (
                    <IconButton size="small" onClick={() => onOpenViewFile(row)}>
                        <FileDownloadOutlined fontSize="small" color="primary" />
                    </IconButton>
                )
            },
        },
    ])

    return (
        <Box sx={{ mt: 2 }}>
            <DataGridCustomize
                rows={data?.subcontracts?.map((contract, index) => ({
                    ...contract,
                    index: index + 1,
                    id: contract?.subcontract_id
                })) || []}
                columns={columns}
                loading={isFetching}
                components={{
                    Toolbar: "disabled"
                }}
                componentsProps={{
                    pagination: {
                        rowsPerPageOptions: [5, 10, 25, 50],
                        page: page?.page || 0,
                        pageSize: page?.pageSize,
                        onPageChange: (newPage) => setPage(prevState => ({ ...prevState, page: newPage })),
                        onPageSizeChange: (newPageSize) => setPage((prevState) => ({ ...prevState, pageSize: newPageSize }))
                    }
                }}
                rowCount={data?.pagination?.total}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
            />
        </Box>
    )
}

export default withSuspense(withPermission(List, {
    feature: 'subcontract',
    action: 'index',
    type: 'Block'
}), MuiSkeleton["DataGrid"]);
