import { Box, LinearProgress, Paper, Typography } from "@mui/material";
import React from "react";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value || 0
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const AttachmentUploadProcess = ({ name, percent }) => {
  return (
    <Paper
      sx={{
        p: 2,
        width: 500,
        maxWidth: "100%",
      }}
    >
      <Typography
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
          fontSize: 14,
        }}
      >
        {name}
      </Typography>
      <LinearProgressWithLabel value={percent} />
    </Paper>
  );
};

export default AttachmentUploadProcess;
