import BreadCrumb from '@components/shared/BreadCrumb'
import { Typography } from '@mui/material'
import React from 'react'

const TicketHeader = () => {
    return (
        <>
            <Typography sx={{
                fontWeight: 'medium',
                fontSize: {
                    xs: 18,
                    md: 24
                }
            }}>
                Tạo Ticket
            </Typography>
            <BreadCrumb data={[
                { label: "Danh sách tickets", reactLink: '/tickets' },
                { label: "Tạo ticket mới" },
            ]} />
        </>
    )
}

export default TicketHeader