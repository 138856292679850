import { Box, Typography } from "@mui/material";
import Card from "../Card";
import React, { useMemo, useState } from "react";
import HighLight from "../HightLight";
import { indigo } from "@mui/material/colors";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import TicketApi from "@/apis/beta/Ticket/TicketApi";
import TicketStatusApi from "@/apis/beta/Ticket/TicketStatusApi";

const Column = ({ column, tickets, setTickets, columns }) => {
  const [active, setActive] = useState(false);

  const { mutateAsync } = useMutationWithNotification(TicketApi.UpdateTicket);
  const { mutateAsync: updateTicketStatus } = useMutationWithNotification(
    TicketStatusApi.Update
  );

  const getHighLight = () => {
    const allHightLight = [
      ...document.querySelectorAll(
        `[data-column="${column?.ticket_status_id}"]`
      ),
    ];

    return allHightLight;
  };

  const getNearHightLight = (e, allHightLight) => {
    return allHightLight?.reduce(
      (final, item) => {
        const offset = e?.clientY - item?.getBoundingClientRect()?.top;

        if (offset < 0 && offset > final?.offset) {
          final = {
            offset: offset,
            element: item,
          };
        }

        return final;
      },
      {
        offset: Number.NEGATIVE_INFINITY,
        element: allHightLight[allHightLight?.length - 1],
      }
    );
  };

  const clearHighLight = () => {
    const hightLights = getHighLight();
    hightLights?.forEach((item) => {
      item.style.opacity = 0;
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setActive(true);

    const hightLights = getHighLight();
    const { element } = getNearHightLight(e, hightLights);

    if (element) {
      element.style.opacity = 1;
    }
  };

  const handleDragLeave = () => {
    setActive(false);
    clearHighLight();
  };

  const handleDragEnd = (e) => {
    const cardId = Number(e.dataTransfer.getData("cardId"));
    let cardsCopy = [...tickets];

    const findCard = tickets?.find((item) => item?.ticket_id === cardId);

    const cardTransfer = {
      ...findCard,
      ticket_status: column,
    };

    if (cardTransfer) {
      const { element } = getNearHightLight(e, getHighLight());
      const newId = Number(element.id?.split("hightlight-")[1]);

      if (newId !== cardId) {
        cardsCopy = cardsCopy?.filter((item) => item?.ticket_id !== cardId);

        if (newId === -1) {
          cardsCopy.push(cardTransfer);
        } else {
          cardsCopy.splice(
            cardsCopy.findIndex((item) => item.ticket_id === newId) || 0,
            0,
            cardTransfer
          );
        }
      }
    }

    setTickets(cardsCopy);
    clearHighLight();
    setActive(false);

    if (
      findCard?.ticket_status?.ticket_status_id !== column?.ticket_status_id
    ) {
      mutateAsync({
        ticket_id: cardId,
        values: {
          ticket_status_id: column?.ticket_status_id,
        },
      });
    }

    const newOrder = cardsCopy
      ?.filter(
        (p) => p?.ticket_status?.ticket_status_id === column?.ticket_status_id
      )
      ?.map((t) => t?.ticket_id);

    updateTicketStatus({
      ticket_status_id: column?.ticket_status_id,
      values: {
        ticket_status_order_ticket: newOrder,
      },
    });

    if (
      findCard?.ticket_status?.ticket_status_id !== column?.ticket_status_id
    ) {
      const newOrder = cardsCopy
        ?.filter(
          (p) =>
            p?.ticket_status?.ticket_status_id ===
            findCard?.ticket_status?.ticket_status_id
        )
        ?.map((t) => t?.ticket_id);

      updateTicketStatus({
        ticket_status_id: findCard?.ticket_status?.ticket_status_id,
        values: {
          ticket_status_order_ticket: newOrder,
        },
      });
    }
  };

  const filterTickets = useMemo(() => {
    let filter = tickets?.filter(
      (t) => t?.ticket_status?.ticket_status_id === column?.ticket_status_id
    );
    return filter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets, column?.ticket_status_order_ticket]);

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDragEnd}
      style={{ minWidth: 450 }}
    >
      <Box
        sx={{
          p: 2,
          height: "calc(100vh - 120px)",
          border: active ? `1px dashed ${indigo[900]}` : "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <Typography fontWeight={600} textAlign="center">
          {column?.ticket_status_title}
        </Typography>

        <Box
          py={2}
          overflow="auto"
          height="100%"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {filterTickets?.map((item) => (
            <Card key={item?.ticket_id} item={item} />
          ))}
          <HighLight cardId="-1" column={column?.ticket_status_id} />
        </Box>
      </Box>
    </div>
  );
};

export default Column;
