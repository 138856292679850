import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { GridCellEditStopReasons, GridOverlay } from "@mui/x-data-grid";
import _ from "lodash";
import React from "react";
import DataGridPro from "./DataGridPro";
import NoRowsOverlay from "./NoRowsOverlay";
import RowWithProductExpand from "./RowWithProductExpand";
export const { default: Toolbar } = require("../DataGrid/Toolbar");

export const DataGridPreviewRowWithProductExpand = RowWithProductExpand;
export const getTreeDataPath = (row) => row.hierarchy;

export const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

export const isNavigationKey = (key) =>
    key === 'Home' ||
    key === 'End' ||
    key.indexOf('Arrow') === 0 ||
    key.indexOf('Page') === 0 ||
    key === ' ';


export const LoadingOverlay = () => {
    return (
        <GridOverlay>
            <Box style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </Box>
        </GridOverlay>
    );
};


const DataGridPreview = (
    {
        scope,
        rows,
        treeData,
        isFetching,
        height,
        autoHeight,
        getTreeDataPath,
        groupingColDef,
        sx = {},
        options = {},
        initialState,
        ...props
    }
) => {

    const fetchNextPageDebounced = React.useCallback(
        _.debounce(() => options.infinite?.fetchNextPage(), 500),
        []
    );

    return (
        <>
            <Box sx={sx}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                width: '100%',
                                height:
                                    !autoHeight &&
                                    (height ||
                                        40 +
                                        (rows?.length === 0 ? 40 : 0) +
                                        (options.padding ?? 0) +
                                        40 * rows?.length +
                                        (rows?.length === 0 ? 0 : options.infinite ? 0 : 0)),
                            }}
                        >
                            <DataGridPro
                                apiRef={options.apiRef}
                                size='small'
                                rows={rows || []}
                                treeData={treeData}
                                getTreeDataPath={treeData && getTreeDataPath}
                                groupingColDef={treeData && groupingColDef}
                                columns={options.columns}
                                loading={isFetching}
                                autoHeight={autoHeight}
                                components={{
                                    LoadingOverlay: LoadingOverlay,
                                    NoRowsOverlay: NoRowsOverlay,
                                    Toolbar: Toolbar
                                }}
                                componentsProps={{
                                    toolbar: {
                                        callback: options.callback,
                                    },
                                }}

                                sx={{
                                    border: 'none',
                                    borderRadius: 0,
                                    '.MuiDataGrid-columnHeaders': {
                                        borderRadius: 0,
                                        backgroundColor: options.containered
                                            ? '#F8F8F8'
                                            : 'grey.200',
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontSize: 13,
                                        },
                                    },
                                    '.MuiDataGrid-cellContent': {
                                        fontSize: 13,
                                    },
                                }}
                                headerHeight={40}
                                rowHeight={40}
                                checkboxSelection={options.checkboxSelection}
                                onSelectionModelChange={options.onSelectionModelChange}
                                onCellClick={options.onCellClick}
                                editMode='row'
                                onCellEditStop={(params, event) => {
                                    if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                                        event.defaultMuiPrevented = true;
                                    }
                                }}
                                processRowUpdate={options.processRowUpdate}
                                experimentalFeatures={{ newEditingApi: true }}
                                rowModesModel={options.rowModesModel}
                                onRowModesModelChange={options.handleRowModesModelChange}
                                disableSelectionOnClick
                                disableColumnPinning
                                disableColumnFilter
                                disableColumnSelector
                                hideFooter
                                initialState={initialState}
                                {...props}
                            />
                        </Box>
                        {options.infinite && (
                            <Box
                                sx={{
                                    py: 1,
                                    bgcolor: '#ffffff',
                                    borderTop: `1px solid ${grey[300]}`,
                                    cursor: options.infinite?.hasNextPage ? 'pointer' : 'default',
                                    '&:hover': options.infinite?.hasNextPage && {
                                        bgcolor: grey[200],
                                    },
                                    borderBottomLeftRadius: 4,
                                    borderBottomRightRadius: 4,
                                }}
                                onClick={() =>
                                    options.infinite?.hasNextPage && fetchNextPageDebounced()
                                }
                            >
                                <Box sx={{ display: 'flex' }}>
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            m: 'auto',
                                            color: grey[700],
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        {options.infinite?.hasNextPage
                                            ? `bấm để tải thêm (${options.total - rows.length
                                            } ${options.name.toLowerCase()})`
                                            : `bạn đã xem hết danh sách (${rows.length
                                            } ${options.name.toLowerCase()})`}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
export default DataGridPreview;