import WarehouseResourceApi from "@/apis/beta/Warehouse/WarehouseResourceApi";
import WarehouseResoureServiceApi from "@/apis/beta/Warehouse/WarehouseResoureServiceApi";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import FormResourceSevice from "../FormResourceSevice";
import { Paper } from "@mui/material";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";


const UpdateService = () => {
    const params =  useParams();
    const {search} = useLocation();
    const searchParams = new URLSearchParams(search);
    const navigate = useNavigate();
    const {data: {warehouse_resource} , refetch: refetchData } = WarehouseResourceApi.show(params?.id)
    const {data: {warehouse_resource_service} , refetch: refetchService } = WarehouseResoureServiceApi.show(params?.id, params?.service_id)
    const {  refetch: refetchResourceSeviceList } = WarehouseResoureServiceApi.index({
        resource_id: params?.id,
        page: 1,
        pageSize: 10
    })
    return (
        <Paper sx={{mt: 3, p: 3 }}>
        <FormResourceSevice 
            key={warehouse_resource_service?.warehouse_resource_service_id}
            warehouse_resource={warehouse_resource}
            warehouse_resource_service={warehouse_resource_service}
            type={"edit"} 
            refetchData={refetchData} 
            refetchResourceSevice={refetchService}
            handleClose={()=> 
                {
                    refetchResourceSeviceList();
                    navigate(`/warehouse/resources/${params?.id}/services`)}
                }
            service_tag={searchParams?.get("service_tag")}
        />
        </Paper>
    )
}
export default withSuspense(withPermission(UpdateService, {
    feature: "warehouse",
    action: "resource-service-update"
}), MuiSkeleton["DataGrid"]) 