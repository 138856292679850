import React from "react";
import { Dialog, Paper } from "@mui/material";
import AddWarehouseFormExport from "../AddWarehouseFormExport";

const AddChildItemFormExport = ({ onChange, onClose, data }) => {
  return (
    <Dialog open={true} maxWidth="lg" fullWidth onClose={onClose}>
      <Paper sx={{ px: 4, pb: 2 }}>
        <AddWarehouseFormExport
          onChange={onChange}
          onClose={onClose}
          data={data}
          disableChooseOwner
        />
      </Paper>
    </Dialog>
  );
};

export default AddChildItemFormExport;
