import { UNLIMITED } from "@/libs/constants";
import NumericFormatCustom from "@components/NumericFormatCustom";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const ServiceAddonQty = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name="service_addon_qty"
      render={({ field: { value, onChange } }) => {
        const isUnlimited = parseInt(value) >= UNLIMITED;
        return (
          <FormControl fullWidth sx={{ mb: 1.5 }}>
            <TextField
              label="Số lượng mua *"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              placeholder="0..."
              value={value}
              onChange={onChange}
              InputProps={{
                sx: {
                  paddingRight: 0,
                },
                inputComponent: NumericFormatCustom,
                endAdornment: (
                  <Box
                    sx={{
                      display: "flex",
                      height: "40px",
                      width: {
                        xs: isUnlimited ? "calc(100%)" : "calc(100% / 3)",
                        md: isUnlimited ? "calc(100%)" : "calc(100% / 4)",
                      },
                      transition: "width 0.75s",
                      bgcolor: "rgba(0, 0, 0, 0.18)",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (isUnlimited) {
                        return onChange(0);
                      }
                      return onChange(UNLIMITED);
                    }}
                  >
                    <Typography
                      sx={{
                        m: "auto 8px auto auto",
                        fontSize: 13,
                        textTransform: "uppercase",
                        color: isUnlimited ? green[800] : "#777680",
                      }}
                    >
                      Unlimited
                    </Typography>
                  </Box>
                ),
              }}
              error={!!errors?.service_addon_qty}
              helperText={errors?.service_addon_qty?.message}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default ServiceAddonQty;
