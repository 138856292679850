import SubcontractApi from "@/apis/beta/Contract/SubcontractApi";
import { format_date_short } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import { CONTRACT_NUMBER_STATUS_TYPES } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Button, Chip, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useNavigate, useParams } from "react-router";


const List = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20
  });

  const { data, isFetching } = SubcontractApi.List({ id: params?.partner_contract_id, query: "" })

  const onOpenViewFile = (data) => {
    try {
      const link = data.link ? data.link : (data?.subcontract_attachments[0]?.attachment_file_url ? `${process.env.REACT_APP_DOMAIN_FILE}${data?.subcontract_attachments[0]?.attachment_file_url.replace("pub/", "")}` : "#")
      window.open(link, data.contract_id, "height=720, width=1080, left=100, top=100, resizable=yes, scrollbars=yes, toolbar=yes, menubar=no, location=no, directories=no, status=yes");
    } catch (e) {
      console.log(e)
    }
  }

  const [columns] = React.useState([
    {
      field: 'subcontracts.subcontract_id',
      hideable: true,
      headerName: 'ID',
      width: 150,
      valueGetter: ({ row }) => row?.subcontract_id,
      renderCell: ({ value }) => value
    }, {
      field: 'subcontracts.subcontract_code',
      headerName: 'Mã Phụ Lục Hợp Đồng (PLHĐ)',
      valueGetter: ({ row }) => row?.subcontract_code,
      width: 220,
      renderCell: ({ value }) => value
    }, {
      field: 'subcontract_name', headerName: 'Tên PLHĐ', width: 250,
      renderCell: ({ value }) => value

    }, {
      field: 'subcontract_created_at', headerName: 'Ngày Tạo', width: 180,
      renderCell: ({ value }) => value ? format_date_short(value) : "-"
    }, {
      field: 'subcontract_status', headerName: 'Trạng Thái', width: 180,
      valueGetter: ({ row }) => _.find(CONTRACT_NUMBER_STATUS_TYPES, stt => stt.value === row?.subcontract_status),
      renderCell: ({ value }) =>
        <Chip size='verysmall'
          variant={`customize-${value?.chip}`}
          label={value?.label || "---"} />
    }, {
      field: 'file',
      headerName: 'File',
      renderCell: ({ row }) => {
        return <Button size="small" onClick={() => { onOpenViewFile(row) }}>File</Button>
      },
      width: 180,
    },
  ])

  return (
    <Box sx={{ mt: 2 }}>
      <DataGridCustomize
        rows={data?.subcontracts?.map((contract) => ({
          ...contract,
          id: contract?.subcontract_id
        })) || []}
        columns={columns}
        loading={isFetching}
        components={{
          Toolbar: "disabled"
        }}
        componentsProps={{
          pagination: {
            rowsPerPageOptions: [5, 10, 25, 50],
            page: page?.page || 0,
            pageSize: page?.pageSize,
            onPageChange: (newPage) => setPage(prevState => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) => setPage((prevState) => ({ ...prevState, pageSize: newPageSize }))
          }
        }}
        rowCount={data?.pagination?.total}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
      />
    </Box>
  )
}

export default withSuspense(List, MuiSkeleton["DataGrid"]);
