import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";

class WarehouseOwnerApi {
  getAll = () =>
    useQueryWithCustomized(
      ["warehouse.owner.all"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_owners/list`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_owners?.map((o) => ({
              value: o.warehouse_owner_name,
              label: o.warehouse_owner_name,
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        // suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );
}

export default WarehouseOwnerApi = new WarehouseOwnerApi();
