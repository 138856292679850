import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDataGridParams from "@/hooks/useDataGridParams";
import { Filter } from "@/hooks/useFilters";
import DataGridCustomize from "@components/DataGrid";
import Cell from "@components/DataGrid/Cell";
import HeaderTitle from "@components/Title/HeaderTitle";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Button, Grid, Link } from "@mui/material";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import SearchDevice from "./SearchDevice";
import Statistic from "./Statistic";
import { useState } from "react";
import CreateNewIDC from "./Dialog/CreateNewIDC";
import BoxLog from "../BoxLogs";
import WarehouseBoxLogApi from "@/apis/beta/Warehouse/WarehouseBoxLogApi";

const Boxes = () => {
  const initialColumns = [
    {
      field: "warehouse_box_name",
      headerName: "DataCenter",
      sortable: true,
      resizable: true,
      minWidth: 300,
      renderCell: ({ value, row }) => (
        <Link
          component={NavLink}
          to={`/warehouse/boxes/${row.warehouse_box_id}`}
        >
          <Cell content={value.toUpperCase()} />
        </Link>
      ),
    },
    {
      field: "warehouse_box_region",
      headerName: "Khu Vực",
      sortable: true,
      width: 200,
      renderCell: ({ value }) => <Cell content={value.toUpperCase()} />,
    },
    {
      field: "rack",
      headerName: "Số Tủ Rack",
      valueGetter: ({ row }) =>
        `${_.sumBy(row.warehouse_racks, (o) =>
          o.warehouse_rack_active ? 1 : 0
        )}`,
      sortable: true,
      width: 150,
    },

    {
      field: "total_u_used",
      headerName: "U Trống ",
      valueGetter: ({ row }) => {
        const rack_ids = _.map(row.warehouse_racks, (o) => o.warehouse_rack_id);
        return `${
          _.sumBy(row.warehouse_racks, (o) =>
            o.warehouse_rack_active ? o.warehouse_rack_height : 0
          ) -
          _.sumBy(warehouse_items, (o) =>
            o.warehouse_box_item_reference_type === "WarehouseRack" &&
            rack_ids.includes(o.warehouse_box_item_reference_id)
              ? o.warehouse_item_form_factor
              : 0
          )
        }`;
      },
      sortable: true,
      width: 150,
    },
    {
      field: "server_company",
      headerName: "Server Công Ty",
      sortable: true,
      valueGetter: ({ row }) => {
        const rack_ids = _.map(row.warehouse_racks, (o) => o.warehouse_rack_id);
        return `${_.sumBy(warehouse_items, (o) =>
          _.includes(server_ids, o.warehouse_model_id) &&
          o.warehouse_owner_name === "VNETWORK" &&
          o.warehouse_box_item_reference_type === "WarehouseRack" &&
          rack_ids.includes(o.warehouse_box_item_reference_id)
            ? 1
            : 0
        )}`;
      },
      width: 200,
    },
    {
      field: "switch_company",
      headerName: "Switch Công Ty",
      sortable: true,
      valueGetter: ({ row }) => {
        const rack_ids = _.map(row.warehouse_racks, (o) => o.warehouse_rack_id);
        return `${_.sumBy(warehouse_items, (o) =>
          _.includes(switch_ids, o.warehouse_model_id) &&
          o.warehouse_owner_name === "VNETWORK" &&
          o.warehouse_box_item_reference_type === "WarehouseRack" &&
          rack_ids.includes(o.warehouse_box_item_reference_id)
            ? 1
            : 0
        )}`;
      },
      width: 200,
    },
    {
      field: "server_customer",
      headerName: "Server Khách Hàng",
      sortable: true,
      valueGetter: ({ row }) => {
        const rack_ids = _.map(row.warehouse_racks, (o) => o.warehouse_rack_id);
        return `${_.sumBy(warehouse_items, (o) =>
          _.includes(server_ids, o.warehouse_model_id) &&
          o.warehouse_owner_name !== "VNETWORK" &&
          o.warehouse_box_item_reference_type === "WarehouseRack" &&
          rack_ids.includes(o.warehouse_box_item_reference_id)
            ? 1
            : 0
        )}`;
      },
      width: 200,
    },
    {
      field: "switch_customer",
      headerName: "Switch Khách Hàng",
      sortable: true,
      valueGetter: ({ row }) => {
        const rack_ids = _.map(row.warehouse_racks, (o) => o.warehouse_rack_id);
        return `${_.sumBy(warehouse_items, (o) =>
          _.includes(switch_ids, o.warehouse_model_id) &&
          o.warehouse_owner_name !== "VNETWORK" &&
          o.warehouse_box_item_reference_type === "WarehouseRack" &&
          rack_ids.includes(o.warehouse_box_item_reference_id)
            ? 1
            : 0
        )}`;
      },
      width: 200,
    },
    {
      field: "total_power",
      headerName: "Tổng Nguồn điện (Kw)",
      valueGetter: ({ row }) =>
        `${_.sumBy(row.warehouse_racks, (o) =>
          o.warehouse_rack_active ? o.warehouse_rack_power_supply : 0
        )}`,
      sortable: true,
      width: 200,
    },
    {
      field: "total_u",
      headerName: "Tổng U",
      valueGetter: ({ row }) =>
        `${_.sumBy(row.warehouse_racks, (o) =>
          o.warehouse_rack_active ? o.warehouse_rack_height : 0
        )}`,
      sortable: true,
      width: 200,
    },
    {
      field: "warehouse_box_description",
      headerName: "Mô tả",
      sortable: true,
      renderCell: ({ value }) => <Cell content={value} />,
      width: 250,
    },
  ];

  const {
    columns,
    debouncedSearchQuery,
    page,
    searchQuery,
    setColumns,
    setPage,
    setSearchQuery,
  } = useDataGridParams(initialColumns);

  const [showDialogCreateNewIDC, setShowDialogCreateNewIDC] = useState(false);

  const {
    data: warehouse_boxes,
    isLoading: warehouse_boxes_fetch,
    refetch,
  } = WarehouseBoxApi.index({
    type: 1,
    page: page?.page + 1,
    pageSize: page?.pageSize || 20,
    searchQuery: debouncedSearchQuery,
  });
  // const { data: warehouse_box_logs, refetch: refetch_logs } = WarehouseBoxLogApi.Index();

  const { data: warehouse_models, isLoading: warehouse_models_fetch } =
    WarehouseModelApi.All();

  const { data: warehouse_items, isLoading: warehouse_items_fetch } =
    WarehouseItemApi.getAll(
      Filter.stringify({
        $and: [
          { "warehouse_items.warehouse_item_form_factor": { $gt: 0 } },
          {
            "warehouse_box_items.warehouse_box_item_reference_type": {
              $eq: "WarehouseRack",
            },
          },
        ],
      })
    );

  const server_ids = [...(warehouse_models || [])]
    ?.filter((item) => item?.warehouse_type_name === "SERVER")
    ?.map((item) => item?.warehouse_model_id);

  const switch_ids = [...(warehouse_models || [])]
    ?.filter((item) => item?.warehouse_type_name === "SWITCH")
    ?.map((item) => item?.warehouse_model_id);

  const isFetching =
    warehouse_boxes_fetch || warehouse_models_fetch || warehouse_items_fetch;

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={2}>
        <HeaderTitle>Quản lý IDC</HeaderTitle>
        <Button
          onClick={() => setShowDialogCreateNewIDC(true)}
          size="small"
          variant="contained"
        >
          Thêm IDC
        </Button>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Statistic
          data={warehouse_boxes}
          warehouse_items={warehouse_items}
          server_ids={server_ids}
          switch_ids={switch_ids}
        />

        <Grid container spacing={2}>
          <Grid item lg={9} xs={12}>
            <DataGridCustomize
              rows={warehouse_boxes?.warehouse_boxes || []}
              columns={columns}
              loading={isFetching}
              rowCount={
                warehouse_boxes?.pagination?.total_pages * page?.pageSize || 0
              }
              componentsProps={{
                toolbar: {
                  store: `boxes.list.hiddenColumns`,
                  columns: columns?.filter((col) => !col?.hideable),
                  setColumns: setColumns,
                  initialColumns: initialColumns,
                  feature: "boxes",
                  inputSearch: {
                    placeholder: "Tìm kiếm,...",
                    value: searchQuery,
                    onChange: setSearchQuery,
                  },
                },
                pagination: {
                  rowsPerPageOptions: [10, 20, 50, 100],
                  page: page?.page || 0,
                  pageSize: page?.pageSize || 20,
                  onPageChange: (newPage) =>
                    setPage((prevState) => ({ ...prevState, page: newPage })),
                  onPageSizeChange: (newPageSize) =>
                    setPage((prevState) => ({
                      ...prevState,
                      pageSize: newPageSize,
                    })),
                },
              }}
            />
          </Grid>

          <SearchDevice />
        </Grid>
      </Box>

      {showDialogCreateNewIDC && (
        <CreateNewIDC
          open={showDialogCreateNewIDC}
          handleClose={() => setShowDialogCreateNewIDC(false)}
          refetch={refetch}
          // refetchLogs={refetch_logs}
        />
      )}
      {/* <BoxLog data = {warehouse_box_logs} /> */}
    </Box>
  );
};

export default withSuspense(
  withPermission(Boxes, { feature: "warehouse", action: "boxes" }),
  MuiSkeleton["DataGrid"]
);
