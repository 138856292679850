import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_AUTOMATION } from "@/libs/constants";
import queryString from "query-string";

class Threshold {
  Index = ({ query = null, page = 1, pageSize = 20 }) => {
    const paramsQueryString = queryString.stringify(
      {
        page: page,
        page_size: pageSize,
      },
      {
        skipNull: true,
        arrayFormat: "bracket",
        skipEmptyString: true,
      }
    );

    return useQueryWithCustomized(
      [
        JSON.stringify([
          "warehouse.warehouse_item.list",
          query,
          page,
          pageSize,
          paramsQueryString,
        ]),
      ],
      async () => {
        const response = await requestWithToken(
          `${API_AUTOMATION}/api/workers/thresholds?${paramsQueryString} ${query ? `&filters=${encodeBase64(query)}` : ""
          }`
        );

        return response?.data?.data;
      },
      {
        // suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  CreateThreshold = async (values) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds`,
      "POST",
      values
    );
    return response?.data;
  };

  GetAlarms = async () => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms`
    );
    return response.data;
  };

  FindOneThreshold = async (id) => {
    if (!id) return null;
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/${id}`
    );
    return response?.data;
  };

  Destroy = async (id) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/${id}`,
      "DELETE"
    );
    return response?.data;
  };

  DestroyAlarm = async ({ threshold_id, alarm_id }) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms/${threshold_id}/${alarm_id}`,
      "DELETE"
    );

    return response?.data;
  };

  AddAlarmInThreshold = async ({ threshold_id, values }) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms/add_alarm_in_threshold/${threshold_id}`,
      "PUT",
      { ...values }
    );

    return response?.data;
  };

  EditAlarm = async (values) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms/${values?.alarm_id}`,
      "PUT",
      values
    );

    return response?.data;
  };

  UpdateThreshold = async ({ threshold_id, values }) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/${threshold_id}`,
      "PUT",
      values
    );

    return response;
  };

  AddWatch = async (values) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms/watches/add_watch_in_alarm`,
      "POST",
      values
    );

    return response?.data;
  };

  DestroyWatch = async ({ alarm_id, watch_id }) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms/watches/destroy_watch_in_alarm`,
      "DELETE",
      {
        alarm_id,
        watch_id,
      }
    );

    return response?.data;
  };

  EditWatch = async (values) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms/watches/edit_watch`,
      "PUT",
      values
    );

    return response?.data;
  };

  AddChannel = async (values) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms/channels/add_channel_in_alarm`,
      "POST",
      values
    );

    return response?.data;
  };

  EditChannel = async (values) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms/channels/edit_channel`,
      "PUT",
      values
    );

    return response?.data;
  };

  DestroyChannel = async ({ alarm_id, channel_id }) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms/channels/destroy_channel`,
      "DELETE",
      { alarm_id, channel_id }
    );

    return response?.data;
  };

  AddSubscriber = async (values) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms/channels/subscribers/add_subscribers_in_channel`,
      "POST",
      values
    );

    return response?.data;
  };

  EditSubscriber = async (values) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms/channels/subscribers/edit_subscribers`,
      "PUT",
      values
    );

    return response?.data;
  };

  DestroySubscriber = async ({ channel_id, subscriber_id }) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/alarms/channels/subscribers/destroy_subscribers`,
      "DELETE",
      { channel_id, subscriber_id }
    );

    return response?.data;
  };

  CloneThreshold = async ({ threshold_id }) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/thresholds/clone_threshold`,
      "POST",
      { threshold_id }
    );

    return response?.data;
  };
  
  GetElastic = (threshold_id) => {
    return useQueryWithCustomized(
      [
        "threshold.threshold_elastic_metadata.show",
        threshold_id
      ],
      async () => {
        return await requestWithToken(
      `${API_AUTOMATION}/api/workers/threshold/threshold_elastic_metadata/${threshold_id}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data;
          })
          .catch((error) => {
            return {
              data: {},
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  }
  CreateElastic = async ({threshold_id, elastic}) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/threshold/threshold_elastic_metadata/create/${threshold_id}`,
      "POST",
      {elastic: elastic}
    );
    return response?.data;
  };

  UpdateElastic = async ({threshold_id, elastic}) => {
    const response = await requestWithToken(
      `${API_AUTOMATION}/api/workers/threshold/threshold_elastic_metadata/update/${threshold_id}`,
      "PUT",
      {elastic: elastic}
    );
    return response?.data;
  };

  GetDomainsByElastic = ({elastic_id}) => {
    return useQueryWithCustomized(
      [
        JSON.stringify([
          "threshold.threshold_elastic_metadata.domains",
          elastic_id
        ]),
      ],
      async () => {
        const response = await requestWithToken(
          `${API_AUTOMATION}/api/workers/threshold/threshold_elastic_metadata/${elastic_id}/domains/`
        );

        return response?.data?.domains;
      },
      {
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  GetPlaybooks = ({threshold_id}) => {
    return useQueryWithCustomized(
      [
        JSON.stringify([
          "threshold.playbooks",
          threshold_id
        ]),
      ],
      async () => {
        const response = await requestWithToken(
          `${API_AUTOMATION}/api/workers/thresholds/${threshold_id}/playbooks/`
        );

        return response?.data;
      },
      {
        staleTime: 10 * 60 * 1000,
      }
    );
  };
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new Threshold();
