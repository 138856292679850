import WarehouseBoxLogApi from "@/apis/beta/Warehouse/WarehouseBoxLogApi";
import WarehouseRackApi from "@/apis/beta/Warehouse/WarehouseRackApi";
import { format_date_short } from "@/functions";
import { useEmployeeContext } from "@contexts/employee";
import { useNotificationContext } from "@contexts/notification";
import { Box, Chip, Grid, Paper, TextField, Typography } from "@mui/material";
import { useMutation } from "react-query";

const Information = ({ warehouse_rack, callbackRefetch }) => {
  const { setNotification } = useNotificationContext();
  const {employee} = useEmployeeContext();
  const changeUpdateDescription = async (event) => {
    await WarehouseRackApi.update(warehouse_rack.warehouse_rack_id, {
      warehouse_rack_description: event.target.value,
    }).then((response) => {
      const data = response.data;
      if (data.status !== 200) {
        setNotification({
          open: true,
          message: data.errors[0].message,
          severity: "error",
        });
        return;
      }
      WarehouseBoxLogApi.createBoxLog({
        warehouse_box_name: warehouse_rack?.warehouse_box?.warehouse_box_name,
        warehouse_box_id: warehouse_rack?.warehouse_box?.warehouse_box_id,
        warehouse_box_update_type: "UPDATERACK-DESCRIPTION",
        metadata: {prevData: {warehouse_rack_description: warehouse_rack?.warehouse_rack_description}, newData: {warehouse_rack_description: event.target.value}},
        warehouse_box_updater_id: employee?.employee_id,
        warehouse_box_updater_name: employee?.employee_name,
        warehouse_box_update_description: "Cập nhật mô tả/ghi chú rack ",
      })
      callbackRefetch();
      setNotification({
        open: true,
        message: "Cập nhật ghi chú thành công.",
        severity: "success",
      });
    });
  };

  const { mutateAsync: updateDesscription, isLoading } = useMutation(
    changeUpdateDescription
  );

  return (
    <>
      <Paper sx={{ p: 1, mb: 2, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xl={3} lg={3} md={3.5} sm={6} xs={12}>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Tên Kho /IDC:
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {warehouse_rack?.warehouse_box?.warehouse_box_name}
            </Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={3.5} sm={6} xs={12}>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>Tên:</Typography>
            <Typography sx={{ color: "#00000F" }}>
              {warehouse_rack?.warehouse_rack_name}
            </Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Kích Thước (U):
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {warehouse_rack?.warehouse_rack_height}
            </Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={3.5} sm={6} xs={12}>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Nguồn Điện (Kw):
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {warehouse_rack?.warehouse_rack_power_supply}
            </Typography>
          </Grid>

          <Grid item xl={3} lg={3} md={3.5} sm={6} xs={12}>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Ngày Tạo:
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {format_date_short(warehouse_rack?.warehouse_rack_created_at)}
            </Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={3.5} sm={6} xs={12}>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Ngày kết thúc tủ:
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              { warehouse_rack?.warehouse_rack_date_end ?format_date_short(warehouse_rack?.warehouse_rack_date_end) :""}
            </Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={3.5} sm={6} xs={12}>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Trạng thái tủ:
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {warehouse_rack?.warehouse_rack_active? <Chip variant="outlined" color="success" label="Đang hoạt động"/> : <Chip variant="outlined" color="error" label="Ngừng hoạt động"/> }
            </Typography>
          </Grid>
          {!warehouse_rack?.warehouse_rack_active &&
            <Grid item xl={3} lg={3} md={3.5} sm={6} xs={12}>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>
              Ngày trả tủ:
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
            {format_date_short(warehouse_rack?.warehouse_racks_cancelled)}
            </Typography>
          </Grid>
          }
        </Grid>
        <Box>
          <Typography sx={{ mb: 0.5, color: "#777680" }}>
            Mô Tả/ Ghi chú Rack
          </Typography>
          <TextField
            defaultValue={warehouse_rack?.warehouse_rack_description}
            placeholder="Nhập thông tin ghi chú..."
            multiline
            minRows={3}
            size="small"
            disabled={isLoading}
            onBlur={(e) => {
              if (
                e.target.value?.trim() !==
                warehouse_rack?.warehouse_rack_description
              ) {
                updateDesscription(e);
              }
            }}
          />
        </Box>
      </Paper>
    </>
  );
};
export default Information;
