import ContractApi from "@/apis/beta/Contract/ContractApi";
import DataGrid from "@/components/DataGrid";
import DataGridFilter from "@/components/DataGrid/Filter";
import PopperAction from "@/components/PopperAction";
import { buildQueryFilterCondition, format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Popper,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { AddOutlined, MoreVertOutlined } from "@mui/icons-material";
import Create from "../Create";
import Update from "../Update";
import { CellLink, CellPricing } from "@components/DataGrid/Cell";
const Statistical = React.lazy(() => import("../../shared/Statistical"));

const List = () => {
  const location = useLocation();
  const [query, updateQuery] = useFilters(
    "partner_contracts.list.filters",
    location
  );

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [popper, setPopper] = React.useState({
    anchorEl: undefined,
    data: undefined,
  });
  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const filterablePartnerContract = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "contracts.contract_number_id",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "contract_key",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const [actions] = React.useState([
    {
      order: 1,
      name: "open-contract-attachment",
      icon: "See",
      label: "Xem Hợp Đồng",
      handleClick: async (data) => {
        try {
          const link = data.link
            ? data.link
            : data?.attachments[0]?.attachment_file_url
            ? `${
                process.env.REACT_APP_DOMAIN_FILE
              }${data?.attachments[0]?.attachment_file_url.replace("pub/", "")}`
            : "#";

          window.open(
            link,
            data.contract_id,
            "height=720, width=1080, left=100, top=100, resizable=yes, scrollbars=yes, toolbar=yes, menubar=no, location=no, directories=no, status=yes"
          );
        } catch (e) {
          console.log(e);
        }
      },
    },
    {
      order: 2,
      name: "update",
      icon: "Edit",
      label: "Chỉnh Sửa",
      handleClick: (data) => {
        setDrawer((prev) => ({
          ...prev,
          open: true,
          data: data,
          type: "update",
        }));
      },
    },
  ]);

  const initialColumns = [
    {
      field: "contracts.contract_id",
      headerName: "ID",
      width: 60,
      hideable: true,
      operators: {
        type: "number",
      },
      valueGetter: ({ row }) => {
        return {
          label: `${row?.contract_id}`,
          href: ["partner_contracts", row?.contract_id],
        };
      },
      renderCell: ({ value }) => <CellLink data={value} />,
    },
    {
      field: "contract_number_id",
      headerName: "Số Hợp Đồng",
      width: 200,
      operators: { type: "string" },
      renderCell: ({ value }) => value,
    },
    {
      field: "contract_service",
      headerName: "Dịch Vụ",
      width: 200,
      renderCell: ({ value }) => value?.label,
    },
    {
      field: "contract_key",
      headerName: "Từ Khoá Hợp Đồng",
      width: 350,
      filterable: true,
      operators: {
        type: "string",
      },
      renderCell: ({ value }) => value || "---",
    },
    {
      field: "contract_value",
      headerName: "Giá Trị Hợp Đồng",
      width: 220,
      operators: { type: "price" },
      renderCell: ({ row, value }) => <CellPricing price={value} />,
    },
    {
      field: "contract_effective_date",
      headerName: "Ngày hiệu lực",
      width: 150,
      operators: {
        type: "date",
      },
      renderCell: ({ value }) => format_date_short(value) || "---",
    },
    {
      field: "contract_expiry_date",
      headerName: "Ngày hết hiệu lực",
      width: 150,
      operators: {
        type: "date",
      },
      renderCell: ({ value }) => format_date_short(value) || "---",
    },
    {
      field: "contract_created_at",
      headerName: "Ngày Tạo",
      width: 150,
      filterable: true,
      operators: {
        type: "date",
      },
      renderCell: ({ value }) => format_date_short(value) || "-",
    },
    {
      field: "action",
      headerName: "Thao Tác",
      hideable: true,
      width: 80,
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <IconButton
              onClick={(event) => {
                setPopper((prev) => ({
                  ...prev,
                  anchorEl: event?.target,
                  data: row,
                  actions: actions,
                }));
              }}
              title="Hành động"
            >
              <MoreVertOutlined sx={{ m: "auto" }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const historyColumsHidden =
    JSON.parse(localStorage.getItem("partner_contracts.list.hiddenColumns")) ||
    [];
  const [columns, setColumns] = React.useState(
    initialColumns?.map((column) => {
      if (historyColumsHidden?.includes(column?.field)) {
        return { ...column, hide: true };
      }
      return { ...column };
    }) || initialColumns
  );

  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });

  const [drawer, setDrawer] = React.useState({
    open: false,
    type: undefined,
    data: undefined,
  });

  const {
    data,
    isFetching,
    refetch: callbackContracts,
  } = ContractApi.List({
    page: page?.page + 1,
    pageSize: page?.pageSize,
    contractType: "PartnerContract",
    query: Filter.stringify(query),
    searchQuery:
      filterablePartnerContract() !== "" ? filterablePartnerContract() : null,
  });

  const handleClose = () => {
    setDrawer({
      open: false,
      type: undefined,
      data: undefined,
    });
  };
  const getDrawerPartnerContractModule = () => {
    switch (drawer?.type) {
      case "create":
        return (
          <Create handleClose={handleClose} callback={callbackContracts} />
        );
      case "update":
        return (
          <Update
            handleClose={handleClose}
            callback={callbackContracts}
            contract={drawer?.data}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Box>
      {popper?.anchorEl !== undefined && (
        <Popper anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)} style={{ zIndex: 1000 }}>
          <PopperAction
            {...popper}
            actions={actions}
            handleClose={() =>
              setPopper((prev) => ({
                ...prev,
                anchorEl: undefined,
                data: undefined,
              }))
            }
          />
        </Popper>
      )}
      {drawer?.open && drawer?.type !== undefined && (
        <Drawer
          anchor="right"
          open={drawer?.open}
          sx={{ width: 360, borderTopLeftRadius: "4px" }}
        >
          {getDrawerPartnerContractModule()}
        </Drawer>
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography className="page-title">Hợp Đồng Đối Tác</Typography>
        <Button
          variant="contained"
          sx={{ height: 42 }}
          disabled={drawer?.open}
          size="small"
          onClick={() =>
            setDrawer({
              open: true,
              type: "create",
            })
          }
          startIcon={<AddOutlined />}
        >
          Thêm Hợp Đồng Đối Tác
        </Button>
      </Box>
      <Statistical
        data={[
          { label: "Số Lượng Hợp Đồng", value: data?.report?.length },
          // { label: "Số Lượng Hợp Đồng", value: format_numeral_price(data?.report?.total), endText: true },
        ]}
      />
      <Box sx={{ mt: 2 }}>
        <DataGridFilter
          callback={updateQuery}
          filters={{
            key: "partner_contracts.list.filters",
            filterables: filterables,
          }}
        />
      </Box>
      <Box>
        <DataGrid
          rows={
            data?.contracts?.map((contract) => ({
              ...contract,
              id: contract?.contract_id,
            })) || []
          }
          columns={columns}
          loading={isFetching}
          componentsProps={{
            toolbar: {
              store: "partner_contracts.list.hiddenColumns",
              columns: columns?.filter((col) => !col?.hideable),
              setColumns: setColumns,
              initialColumns: initialColumns,
              inputSearch: {
                placeholder: "Nhập số hợp đồng hoặc từ khoá tìm kiếm...",
                value: searchQuery,
                onChange: setSearchQuery,
              },
            },
            pagination: {
              rowsPerPageOptions: [10, 20, 50, 100],
              page: page?.page || 0,
              pageSize: page?.pageSize,
              onPageChange: (newPage) =>
                setPage((prevState) => ({ ...prevState, page: newPage })),
              onPageSizeChange: (newPageSize) =>
                setPage((prevState) => ({
                  ...prevState,
                  pageSize: newPageSize,
                })),
            },
          }}
          rowCount={data?.pagination?.total}
          disableSelectionOnClick
          disableColumnFilter
          disableColumnSelector
        />
      </Box>
    </Box>
  );
};

export default withSuspense(
  withPermission(List, {
    feature: "contract",
    action: "partner-contract-index",
  }),
  MuiSkeleton["DataGrid"]
);
