import { INDUSTRIES } from '@/libs/constants'
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { Collapse, FormControl, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const CustomerIndustry = () => {
    const { control, watch } = useFormContext()
    return (
        <Grid container spacing={2}>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Lĩnh Vực/ Ngành Nghề
                </Typography>
            </Grid>
            <Grid item xl={9} lg={9} md={8} sm={6} xs={12}>
                <Controller
                    control={control}
                    name="customer_industry"
                    render={({ field: { value, onChange } }) => {
                        const selected = INDUSTRIES.find(item => item?.value === value) || null
                        return (
                            <FormControl fullWidth>
                                <SingleSelectCustomize
                                    onChange={(event, newValue) => onChange(newValue?.value || -1)}
                                    value={selected}
                                    input={{
                                        placeholder: "Chọn lĩnh vực ngành nghề..."
                                    }}
                                    options={INDUSTRIES}
                                />
                            </FormControl>
                        )
                    }} />
                <Collapse in={watch(`customer_industry`) !== ""}>
                    <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        maxRows={3}
                        size='small'
                        value={
                            INDUSTRIES.find(item => item?.value === watch(`customer_industry`))?.childrens?.map(({ label }) => label)?.join(", ")
                        }
                    />
                </Collapse>
            </Grid>
        </Grid>
    )
}

export default CustomerIndustry