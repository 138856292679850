import withPermission from "@/hocs/withPermission";
import React from "react";
import _ from "lodash";
import ReportApi, { rowItemsResolver } from "@/apis/beta/Report/ReportApi";
import DataGridPreview, { DataGridPreviewRowWithProductExpand, getTreeDataPath } from "@components/DataGridPreview";
import { GridTreeDataGroupingCell } from "@components/DataGridPreview/DataGridPro";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";

export const rowInvoiceTotalResolver = (rows, row, rowNode, key) => {
  return [
    _.map(rowItemsResolver(rows, 'quarter', rowNode), (item) =>
      _.sum(_.map(item[key], (item) => item.service_amount))
    ),
    _.map(rowItemsResolver(rows, 'month', rowNode), (item) =>
      _.sum(_.map(item[key], (item) => item.service_amount))
    ),
    _.map(row[key], (item) => item.service_amount),
  ];
};

const initialDataGridStyles = {
  p: 2,
}
const Service = () => {
  const month = new Date().getMonth()
  const { data, isFetching } = ReportApi.MonthlyServiceRenewable(
    month
  );

  const rows = data?.rows || [];

  const [columns] = React.useState([
    {
      field: 'registered',
      headerName: 'Bán Mới',
      sortable: false,
      filterable: false,
      resizable: false,
      width: 100,
      valueGetter: ({ row, rowNode }) => {
        const items = [
          _.map(
            rowItemsResolver(rows, 'quarter', rowNode),
            (item) => item.registered?.length
          ),
          _.map(
            rowItemsResolver(rows, 'month', rowNode),
            (item) => item.registered?.length
          ),
          [row.registered?.length],
        ];

        return _.sum(items[rowNode.depth]);
      },
      renderCell: (params) => (
        <DataGridPreviewRowWithProductExpand
          collections={rows}
          collectionKey='registered'
          {...params}
        />
      ),
    },
    {
      field: 'renewed',
      headerName: 'Gia Hạn',
      sortable: false,
      filterable: false,
      resizable: false,
      width: 100,
      valueGetter: ({ row, rowNode }) => {
        const items = [
          _.map(
            rowItemsResolver(rows, 'quarter', rowNode),
            (item) => item.renewed?.length
          ),
          _.map(
            rowItemsResolver(rows, 'month', rowNode),
            (item) => item.renewed?.length
          ),
          [row.renewed?.length],
        ];

        return _.sum(items[rowNode.depth]);
      },
      renderCell: (params) => (
        <DataGridPreviewRowWithProductExpand
          collections={rows}
          collectionKey='renewed'
          {...params}
        />
      ),
    },
    {
      field: 'cancelled',
      headerName: 'Huỷ',
      sortable: false,
      filterable: false,
      resizable: false,
      width: 100,
      valueGetter: ({ row, rowNode }) => {
        const items = [
          _.map(
            rowItemsResolver(rows, 'quarter', rowNode),
            (item) => item.cancelled?.length
          ),
          _.map(
            rowItemsResolver(rows, 'month', rowNode),
            (item) => item.cancelled?.length
          ),
          [row.cancelled?.length],
        ];

        return _.sum(items[rowNode.depth]);
      },
      renderCell: (params) => (
        <DataGridPreviewRowWithProductExpand
          collections={rows}
          collectionKey='cancelled'
          {...params}
        />
      ),
    },
    {
      field: 'trial',
      headerName: 'Dùng Thử',
      sortable: false,
      filterable: false,
      resizable: false,
      width: 100,
      valueGetter: ({ row, rowNode }) => {
        const items = [
          _.map(
            rowItemsResolver(rows, 'quarter', rowNode),
            (item) => item.trial?.length
          ),
          _.map(
            rowItemsResolver(rows, 'month', rowNode),
            (item) => item.trial?.length
          ),
          [row.trial?.length],
        ];

        return _.sum(items[rowNode.depth]);
      },
      renderCell: (params) => (
        <DataGridPreviewRowWithProductExpand
          collections={rows}
          collectionKey='trial'
          {...params}
        />
      ),
    },
    {
      field: 'suppend',
      headerName: 'Suppend',
      sortable: false,
      filterable: false,
      resizable: false,
      width: 100,
      valueGetter: ({ row, rowNode }) => {
        const items = [
          _.map(
            rowItemsResolver(rows, 'quarter', rowNode),
            (item) => item.suppend?.length
          ),
          _.map(
            rowItemsResolver(rows, 'month', rowNode),
            (item) => item.suppend?.length
          ),
          [row.suppend?.length],
        ];

        return _.sum(items[rowNode.depth]);
      },
      renderCell: (params) => (
        <DataGridPreviewRowWithProductExpand
          collections={rows}
          collectionKey='suppend'
          {...params}
        />
      ),
    }
  ])

  const groupingColDef = React.useCallback(
    () => ({
      headerName: 'Group',
      width: 275,
      renderCell: (params) => {
        const { rowNode } = params;

        const items = [
          _.map(
            rowItemsResolver(rows, 'quarter', rowNode),
            (item) =>
              _.flatten([item.cancelled, item.renewed, item.registered])
                .length
          ),
          _.map(
            rowItemsResolver(rows, 'month', rowNode),
            (item) =>
              _.flatten([item.cancelled, item.renewed, item.registered])
                .length
          ),
        ];

        return (
          <GridTreeDataGroupingCell
            total={_.sum(items[rowNode.depth])}
            {...params}
          />
        );
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <DataGridPreview
        treeData
        autoHeight
        groupingColDef={groupingColDef}
        getTreeDataPath={getTreeDataPath}
        scope='datacentral::reports.index'
        rows={rows}
        isFetching={isFetching}
        options={{
          name: 'Thống kê Dịch Vụ',
          path: '/beta/reports/services',
          columns: columns,
        }}
        sx={initialDataGridStyles}
      />
    </>
  )
}
export default withSuspense(withPermission(Service, {
  feature: "report",
  action: "report-service"
}), MuiSkeleton["DataGrid"]) 