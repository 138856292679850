import CustomerApi from '@/apis/beta/Customer/CustomerApi';
import DataGridFilter from '@/components/DataGrid/Filter';
import { buildQueryFilterCondition } from '@/functions';
import withPermission from '@/hocs/withPermission';
import withSuspense from '@/hocs/withSuspense';
import useDebounce from '@/hooks/useDebounce';
import useFilters, { Filter } from '@/hooks/useFilters';
import { INDUSTRIES, SERVICES } from '@/libs/constants';
import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import BaseDataGridCustomer, { LoadingCustomer, BaseCustomerComponents } from '@components/ManagementCustomer';
import { useCustomerContext } from '@contexts/customer';
import CustomerServices from '../CustomerService';
import {
    format_date_short,
    format_numeral_price,
} from "@/functions";
const initialState = { page: 0, pageSize: 10 }
const INITIAL_STATUS = "stop"
const CustomerStop = () => {

    const location = useLocation();
    const [query, updateQuery] = useFilters(`customers.list.filters`, location);

    const { queryString } = useCustomerContext()
    const debouncedSearchQuery = useDebounce(queryString, 500)

    const filterableCustomer = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === '') return ''
        const fieldQueries = [
            {
                name: 'customers.customer_id', operator: "$eq",
                disabled: !parseInt(debouncedSearchQuery), value: parseInt(debouncedSearchQuery)
            }, {
                name: 'customers.customer_name', operator: "$contains",
                value: debouncedSearchQuery
            }, {
                name: 'company_name', operator: "$contains",
                value: debouncedSearchQuery
            }, {
                name: 'customers.customer_email', operator: "$contains",
                value: debouncedSearchQuery
            }]
        return buildQueryFilterCondition("$or", 0, fieldQueries)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery])


    const [page, setPage] = React.useState(initialState);

    const { data, isFetching } = CustomerApi.CustomerJourneys({
        page: page?.page + 1,
        pageSize: page?.pageSize,
        query: Filter.stringify(query),
        searchQuery: filterableCustomer(),
        type: INITIAL_STATUS
    })
    const [filterables] = React.useState([
        {
            field: "customers.customer_id",
            label: "ID Khách Hàng",
            operators: {
                type: "number"
            }
        }, {
            field: "customers.customer_name",
            label: "Tên Khách Hàng",
            operators: {
                type: "string"
            }
        },
        {
            field: "customer_service_informations.customer_industry",
            label: "Lĩnh Vực",
            operators: {
                type: "object",
                collections: INDUSTRIES
            }
        }, {
            field: "customer_service_informations.customer_service_interestings",
            label: "SP/ DV Quan Tâm",
            operators: {
                type: "object",
                collections: SERVICES
            }
        }
    ])

    return (
        <Box>
            {/* {data?.employees && data.employees.length !== 0 &&  */}
            <DataGridFilter callback={updateQuery}
                filters={{
                    key: `customers.list.filters`,
                    filterables: filterables,
                    exportFeature: "ManageCustomerStop"
                }} 
                />
                {/* } */}
            <BaseDataGridCustomer
                status={INITIAL_STATUS}
                columns={[
                    {
                        field: "service_product_id",
                        headerName: "Sản phẩm/dịch vụ đã sử dụng",
                        minWidth: 300,
                        valueGetter: ({ row }) => row?.services || [],
                        renderCell: ({ value }) => <CustomerServices services={value} />,
                    },
                    // {
                    //     field: 'customer_note',
                    //     headerName: 'Ghi chú',
                    //     width: 250,
                    //     valueGetter: ({ row }) => row?.customers?.map((item) => item?.customer_note) || [],
                    //     renderCell: ({ value }) => (
                    //         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    //             <BaseCustomerComponents.Service services={value} />
                    //         </div>
                    //     )
                    // },
                    {
                        field: 'amount',
                        headerName: 'Đơn giá',
                        width: 150,
                        valueGetter: ({ row }) =>
                            row?.services?.map((item) =>
                                item?.service_amount
                                    ? `đ` + format_numeral_price(item?.service_amount)
                                    : " "
                            ) || [],
                        renderCell: ({ value }) => (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <BaseCustomerComponents.Service services={value} />
                            </div>
                        )
                    },
                    {
                        field: 'start_date',
                        headerName: 'Ngày bắt đầu',
                        width: 200,
                        valueGetter: ({ row }) =>
                            row?.services?.map((item) =>
                                item?.service_start_date
                                    ? format_date_short(item?.service_start_date)
                                    : " "
                            ) || [],
                        renderCell: ({ value }) => (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <BaseCustomerComponents.Service services={value} />
                            </div>
                        )
                    },
                    {
                        field: 'stopped_at',
                        headerName: 'Ngày kết thúc',
                        width: 200,
                        valueGetter: ({ row }) =>
                            row?.services?.map((item) =>
                                item?.service_stopped_at
                                    ? format_date_short(item?.service_stopped_at)
                                    : " "
                            ) || [],
                        renderCell: ({ value }) => (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <BaseCustomerComponents.Service services={value} />
                            </div>
                        )
                    },
                    {
                        field: 'customer_reason',
                        headerName: 'Lý do',
                        width: 150,
                        valueGetter: ({ row }) => row?.services?.map((item) => item?.service_update_last?.service_update_description) || [],
                        renderCell: ({ value }) => (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <BaseCustomerComponents.Service services={value} />
                            </div>
                        )
                    },
                ]}
                getRowHeight={({ model }) => {
                    const PADDING_OFFSET_Y = 8
                    const HEIGHT = 24 * (model?.services?.length || 0) + PADDING_OFFSET_Y
                    return Math.max(HEIGHT, 52)
                }}
                // search={queryString}
                // handlerSearch={onChangeQueryString}
                page={page}
                enableSale={true}
                handlerPage={setPage}
                isFetching={isFetching}
                customers={data?.customers}
                total={data?.pagination?.total}
            />
        </Box>
    );
};

export default withSuspense(withPermission(CustomerStop, { feature: 'customer', action: 'customer-stop' }), LoadingCustomer);