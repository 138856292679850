import InvestmentApi from "@/apis/beta/Warehouse/InvestmentApi";
import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseRackApi from "@/apis/beta/Warehouse/WarehouseRackApi";
import WarehouseTypeApi from "@/apis/beta/Warehouse/WarehouseTypeApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { Filter } from "@/hooks/useFilters";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import React from "react";
import _ from "lodash";
import { Box, Link, Paper, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import Cell from "@components/DataGrid/Cell";
import DataGridProCustomize from "@components/DataGridProCustomize";


const ReportRack = () => {
    const [data, setData] = React.useState([])
    const [collumns, setCollumns] = React.useState([])
    const { data: warehouse_racks } = WarehouseRackApi.getAll();
    const { data: warehouse_types } = WarehouseTypeApi.All();
    const [server_ids] = React.useState([1].concat(warehouse_types?.filter(o => o.warehouse_type_parent === 1).map(o => o.warehouse_type_id)))
    const [switch_ids] = React.useState([2].concat(warehouse_types?.filter(o => o.warehouse_type_parent === 2).map(o => o.warehouse_type_id)))
    const { data: resource_services } = InvestmentApi.overview();

    const query = {
        $and: [
            { "warehouse_items.warehouse_item_form_factor": { $gt: 0 } }
        ]
    }
    const { data: warehouse_items } = WarehouseItemApi.getAll(Filter.stringify(query));
    const { data: warehouse_boxes } = WarehouseBoxApi.getAll();

    const getData = () => {
        let rows = []

        const items_of_service = _.flatten(resource_services?.filter(o => o.warehouse_resource_service_status === "active").map(o => {
            return o?.warehouse_items.map(i => ({
                ...i,
                warehouse_resource_service_code: o.warehouse_resource_service_code,
                warehouse_resource_service_name: o.warehouse_resource_service_name
            }))
        }
        ))
        warehouse_boxes?.forEach((item, item_index) => {
            const record = {
                id: `${item_index + 1}`,
                type: "box",
                group: [item.warehouse_box_name.replace(/IDC/g, "")],
                warehouse_box_name: item.warehouse_box_name.replace(/IDC/g, ""),
                total_switch: 0,
                total_switch_customer: 0,
                warehouse_rack_height: 0,
                total_server_available: 0,
                u_empty: 0,
                total_server_customer: 0,
                total_server: 0,
                total_items: 0,
                services: [],
                w_items: []
            }

            if (item.warehouse_box_type === 1) {
                warehouse_racks.filter(o => o.warehouse_box_id === item.warehouse_box_id).forEach((rack, rack_index) => {
                    const w_items = warehouse_items.filter(o => o.warehouse_box_item_reference_type === "WarehouseRack" && rack?.warehouse_rack_id === o.warehouse_box_item_reference_id)

                    if (w_items.length > 0) {

                        const items_of_service_in_rack = items_of_service.filter(o => o.warehouse_box_item_reference_type === "WarehouseRack" && rack?.warehouse_rack_id === o.warehouse_box_item_reference_id)
                        const sum_switch = w_items.filter(o => _.includes(switch_ids, o.warehouse_type_id) && o.warehouse_owner_name === "VNETWORK")
                        const sum_switch_customer = w_items.filter(o => _.includes(switch_ids, o.warehouse_type_id) && o.warehouse_owner_name !== "VNETWORK")
                        const sum_server_customer = w_items.filter(o => _.includes(server_ids, o.warehouse_type_id) && o.warehouse_owner_name !== "VNETWORK")
                        const total_server = w_items.filter(o => _.includes(server_ids, o.warehouse_type_id) && o.warehouse_owner_name === "VNETWORK")
                        const total_server_available = total_server.filter(o => !o.warehouse_item_availability)
                        const ids = items_of_service_in_rack.map(o => o.warehouse_item_id)
                        const total_server_service = _.sumBy(total_server, o => _.includes(ids, o.warehouse_item_id) ? 1 : 0)
                        record.total_switch = record.total_switch + sum_switch.length
                        record.total_switch_customer = record.total_switch_customer + sum_switch_customer.length
                        record.warehouse_rack_height = record.warehouse_rack_height + rack?.warehouse_rack_height
                        record.u_empty = record.u_empty + (rack?.warehouse_rack_height - _.sumBy(w_items, o => o.warehouse_item_form_factor))
                        record.total_server_available = record.total_server_available + total_server_available.length
                        record.total_items = record.total_items + w_items?.length
                        record.total_server_customer = record.total_server_customer + sum_server_customer?.length
                        record.total_server = record.total_server + total_server?.length

                        rows.push({
                            id: `${item_index + 1}.${rack_index + 1}`,
                            type: "rack",
                            group: [item.warehouse_box_name.replace(/IDC/g, ""), rack_index + 1],
                            warehouse_rack_id: rack?.warehouse_rack_id,
                            warehouse_rack_name: rack?.warehouse_rack_name,
                            warehouse_rack_height: rack?.warehouse_rack_height,
                            total_server_available: total_server_available.length,
                            total_server: total_server?.length,
                            total_switch: sum_switch?.length,
                            total_switch_customer: sum_switch_customer?.length,
                            total_server_customer: sum_server_customer?.length,
                            u_empty: rack?.warehouse_rack_height - _.sumBy(w_items, o => o.warehouse_item_form_factor),
                            services: _.uniq(items_of_service_in_rack.map(o => o.warehouse_resource_service_name)),
                            w_items: w_items,
                            total_server_service: total_server_service
                        })
                    }

                })

                if (record.total_items > 0) {
                    rows.push(record)
                }
            }
        });

        setData(rows)
        setCollumns([
            {
                field: 'warehouse_rack_name',
                headerName: "Tủ Rack",
                width: 120,
                renderCell: ({ value, row }) => (
                    <>{row.warehouse_rack_id && <Link component={NavLink} to={`/warehouse/racks/${row.warehouse_rack_id}`} >
                        <Typography className='react-link-table'>
                            {value}
                        </Typography>
                    </Link>}</>
                ),
            },
            {
                field: 'warehouse_rack_height',
                headerName: "Kích Thước",
                width: 100,
            },
            {
                field: 'u_empty',
                headerName: "Số U trống",
                width: 100,
            },

            {
                field: 'total_server_available',
                headerName: "Server có sẳn",
                cellClassName: 'available',
                width: 140,
            },
            {
                field: 'total_server',
                headerName: "Server CTY",
                cellClassName: 'cty',
                width: 120,
            },
            {
                field: 'total_switch',
                headerName: "Switch CTY",
                cellClassName: 'cty',
                width: 120,
            },
            {
                field: 'total_server_customer',
                headerName: "Server KH",
                cellClassName: 'customer',
                width: 120,
            },
            {
                field: 'total_switch_customer',
                headerName: "Switch KH",
                cellClassName: 'customer',
                width: 120,
            },
            {
                field: 'services',
                headerName: "Dịch Vụ đang chạy",
                width: 200,
                valueGetter: ({ row }) => row.services.join(" , "),
                renderCell: ({ value }) => { value && <Cell content={value} /> },

            },


        ])
    }

    React.useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Box>
            <Box sx={{
                '& .customer': {
                    backgroundColor: '#b9d5ff91',
                    fontWeight: '600',
                },
                '& .cty': {
                    backgroundColor: '#ff943975',
                    fontWeight: '600',
                },
                '& .available': {
                    backgroundColor: '#80d8ff',
                    fontWeight: '600',
                },

            }}>
                <Paper sx={{ mt: 3 }}>
                    <DataGridProCustomize
                        rows={data}
                        columns={collumns}
                        loading={false}
                        getTreeDataPath={(row) => row?.group}
                        treeData
                        defaultGroupingExpansionDepth={1}
                        disableColumnFilter={true}
                    />
                </Paper>
            </Box>
        </Box>
    )
}

export default withSuspense(withPermission(ReportRack, {
    feature: "warehouse",
    action: "report-rack"
}), MuiSkeleton["DataGrid"]) 