import { AppContext } from "@contexts/app";
import React from "react";
import _ from "lodash";
import { Box, ClickAwayListener, Paper, Popper, Typography } from "@mui/material";
import ProductTable from "./ProductTable";

const RowWithProductExpand = ({
  value,
  row,
  rowNode,
  collectionKey,
  collections,
}) => {
  const { popperAction, TogglePopperAction } = React.useContext(AppContext);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ref = React.useRef();

  const items = React.useMemo(
    () => [
      _.map(
        collections?.filter((item) => item.quarter === rowNode.groupingKey),
        (item) => item[collectionKey]
      ),
      _.map(
        collections?.filter((item) => item.month === rowNode.groupingKey),
        (item) => item[collectionKey]
      ),
      [row[collectionKey]],
    ],
    [collectionKey, collections, row, rowNode.groupingKey]
  );

  return (
    <>
      <Typography
        ref={ref}
        sx={{
          fontSize: 13,
          cursor: value !== 0 && 'pointer',
        }}
        onClick={value !== 0 ? () => {
          TogglePopperAction({
            ref: ref,
            open: true,
            items: _.flatten(items[rowNode.depth]),
          });
        } : undefined}
      >
        {value}
      </Typography>
      {popperAction.open && popperAction.ref === ref && (
        <Popper
          open={true}
          anchorEl={popperAction.ref.current}
          role={undefined}
          disablePortal={false}
          placement='bottom-start'
          style={{ zIndex: 9999 }}
        >
          <Paper>
            <ClickAwayListener
              onClickAway={(event) => {
                if (
                  popperAction.ref.current &&
                  popperAction.ref.current.contains(event.target)
                )
                  return;

                TogglePopperAction({ ...popperAction, open: false });
              }}
            >
              <Box
                sx={{
                  borderRadius: 1,
                  overflow: 'hidden',
                  maxHeight: '1000px',
                  '.MuiTableCell-root': { bgcolor: '#FFFFFF' },
                }}
              >
                <ProductTable items={popperAction.items} />
              </Box>
            </ClickAwayListener>
          </Paper>
        </Popper>
      )}
    </>
  );
}

export default RowWithProductExpand;