import Layout from "./Layout";
// warehouse-items
import Customer from "./WarehouseItems/Customer";
import Items from "./WarehouseItems/Items";
import ItemsDetail from "./WarehouseItems/ItemsDetail";
// warehouse-types
import TypesDetail from "./WarehouseTypes/Detail";
import Types from "./WarehouseTypes/Types";
// warehouse-boxes
import Boxes from "./WarehouseBoxes/Boxes";
import BoxesDetail from "./WarehouseBoxes/BoxesDetail";
// warehouse-racks
import RacksDetail from "./WarehouseRacks/RacksDetail";
// warehouse-processes
import ProcessesDetail from "./WarehouseProcesses/ProcessDetail";
import Processes from "./WarehouseProcesses/Processes";
import ProcessesNew from "./WarehouseProcesses/ProcessesNew";
import ProcessesUpdate from "./WarehouseProcesses/ProcessesUpdate";
// warehouse-depreciations
import Depreciations from "./WarehouseDepreciations/Depreciations";

import AssembleItem from "./AssembleItem";
import Report from "./Report";

import Bandwidth from "./Bandwidth";
import Dashboard from "./Dashboard";
import Investment from "./Investment";
import Resource from "./Resource";
import ItemsExport from "./WarehouseItems/ItemsExport";
import TypesV2 from "./WarehouseTypes/TypesV2";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Layout,// warehouse-items
  Customer,
  Items,
  ItemsDetail, // warehouse-types
  Types,
  TypesDetail,
  TypesV2,// warehouse-boxes
  Boxes,
  BoxesDetail,
  // warehouse-racks
  RacksDetail,
  // warehouse-processes
  Processes,
  ProcessesDetail,
  ProcessesNew,
  ProcessesUpdate,
  Report,
  AssembleItem,
  Bandwidth,
  Investment,
  // warehouse-depreciations
  Depreciations,
  Resource,
  Dashboard,

  ItemsExport,
};
