import withPermission from '@/hocs/withPermission';
import { Box} from '@mui/material';
import React from 'react';

const Information = React.lazy(() => import("./Infomation"))

const Detail = ({ customer_id }) => {
    const renderCustomerContactInformation = () => {
        switch (customer_id > 0) {
            case true: return <Information customer_id={customer_id} />
            default: return null
        }
    }


    return (
        <Box>
            {renderCustomerContactInformation()}
        </Box>
    )
}

export default withPermission(Detail, { feature: "customercontact", action: "customer-contact-show", type: "element" }) 