import AssigneesApi from '@/apis/beta/Assignees/AssigneesApi';
import EmployeeApi from '@/apis/beta/Employee/EmployeeApi';
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize';
import { useNotificationContext } from "@contexts/notification";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

const Create = () => {
    let navigate = useNavigate();
    const { setNotification } = useNotificationContext();

    const [title, setTitle] = useState('');
    const [assignee] = useState('');
    const [leaders, setLeaders] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [error, setError] = useState('');

    const employeesQuery = EmployeeApi.ListEmployees();

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
        setError('');
    };
    const handleLeadersChange = (event, newValue) => {
        const selectedLeaders = newValue.map(option => option.employee_id);
        setLeaders(selectedLeaders);
    };
    const handleEmployeesChange = (event, newValue) => {
        const selectedEmployees = newValue.map(option => option.employee_id);
        setEmployee(selectedEmployees);
    };
    const handleSubmit = async () => {
        if (!title) {
            setError('Field is required!');
            return;
        }
        try {
            const updatedAssignee = {
                ...assignee,
                assignee_compoment: "Business",
                assignee_name: title,
                employees: employee,
                leaders: leaders
            };
            await AssigneesApi.Create(updatedAssignee);
            setNotification({
                open: true,
                message: 'Thêm mới thành công!',
                severity: 'success'
            });
            navigate("/assignee_business", { replace: true });
        } catch (error) {
            console.error('Error creating assignee:', error);
        }
    };
    const handleCancle = () => {
        return navigate("/assignee_business", { replace: true });
    };
    return (
        <React.Fragment>
            <Typography
                sx={{
                    fontWeight: 'medium',
                    mt: 1.5,
                    fontSize: '1.5rem'
                }}
                color="primary"
            >
                Thêm thông báo mới
            </Typography>
            <Box sx={{ mt: 3 }}>
                <FormControl fullWidth sx={{
                    maxWidth: {
                        xs: "100%",
                        md: "35%",
                    },
                }}>
                    <TextField
                        id="title"
                        label="Tiêu đề"
                        variant="outlined"
                        value={title}
                        size='small'
                        onChange={handleTitleChange}
                        fullWidth
                        error={!!error}
                        helperText={error}
                    />
                    <Typography
                        sx={{
                            fontWeight: 'normal',
                            mt: 1.5
                        }}
                        color="black"
                    >
                        Chọn người kiểm duyệt
                    </Typography>
                    <FormControl fullWidth sx={{
                        mt: 2
                    }}>
                        <MultipleSelectCustomize
                            limitTags={3}
                            value={employeesQuery.data.filter(emp => leaders.includes(emp.employee_id))}
                            options={employeesQuery.data || []}
                            formatCollection={{
                                value: "employee_id",
                                label: "employee_name",
                            }}
                            compareAttributes={["employee_name"]}
                            onChange={handleLeadersChange}
                            sx={{
                                "&.MuiAutocomplete-option": {
                                    textTransform: "uppercase",
                                },
                            }}
                            mode="server"
                        />
                    </FormControl>
                    <Typography
                        sx={{
                            fontWeight: 'normal',
                            mt: 1.5
                        }}
                        color="black"
                    >
                        Chọn nhân viên
                    </Typography>
                    <FormControl fullWidth sx={{
                        mt: 2
                    }}>
                        <MultipleSelectCustomize
                            limitTags={3}
                            value={employeesQuery.data.filter(emp => employee.includes(emp.employee_id))}
                            options={employeesQuery.data || []}
                            formatCollection={{
                                value: "employee_id",
                                label: "employee_name",
                            }}
                            compareAttributes={["employee_name"]}
                            onChange={handleEmployeesChange}
                            sx={{
                                "&.MuiAutocomplete-option": {
                                    textTransform: "uppercase",
                                },
                            }}
                            mode="server"
                        />
                    </FormControl>
                </FormControl>
                <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
                    <Button variant="contained" size='small' onClick={handleSubmit}
                        sx={{
                            width: '10%',
                            '&:hover': {
                                bgcolor: '#4FA4F0',
                            }
                        }}
                    >
                        Thêm mới
                    </Button>
                    <Button variant="outlined" size='small' onClick={handleCancle}
                        sx={{
                            width: '6%',
                            '&:hover': {
                                bgcolor: '#4FA4F0',
                            }
                        }}
                    >Hủy</Button>
                </Stack>
            </Box>
        </React.Fragment>
    );
};

export default withSuspense(
    withPermission(Create, {
        feature: "businessnotification",
        action: "create"
    }),
    MuiSkeleton["DataGrid"]
);

