import ClusterBandwidthApi from "@/apis/beta/Warehouse/ClusterBandwidthApi";
import WarehouseResoureServiceApi from "@/apis/beta/Warehouse/WarehouseResoureServiceApi";
import DatePicker from "@components/DatePicker";
import MuiCurrency from "@components/shared/CurrencyInputCustomize";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import { useNotificationContext } from "@contexts/notification";
import { Add, Clear } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

const month_current = {
  start: moment().startOf("month")?.format("YYYY-MM-DD"),
  end: moment().endOf("month")?.format("YYYY-MM-DD"),
};

const Form = ({
  type = "create",
  data_cluster_bandwidth = {},
  refetchData,
}) => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      cluster_bandwidth_items:
        type !== "create"
          ? data_cluster_bandwidth?.cluster_bandwidth_items.map((o) => ({
            ...o,
            id: o.cluster_bandwidth_item_id,
          }))
          : [],
    },
  });

  const { setNotification } = useNotificationContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "cluster_bandwidth_items",
  });

  const { data: warehouse_resource_services } =
    WarehouseResoureServiceApi.getAll();

  const handleAddCluster = () => {
    setValue(
      "cluster_bandwidth_items",
      _.map(
        _.uniq(
          warehouse_resource_services.map(
            (o) => o.warehouse_resource_service_code
          )
        ),
        (o, index) => ({
          cluster_bandwidth_item_name: `${o}`,
          cluster_bandwidth_item_bw_limit: 0,
          cluster_bandwidth_item_bw_uplink: 0,
          cluster_bandwidth_item_max: 0,
          cluster_bandwidth_item_customer_volume: 0,
        })
      )
    );
  };

  React.useEffect(() => {
    if (type === "create") {
      handleAddCluster();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse_resource_services]);

  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/warehouse/bandwidths/clusters");
  };

  const [submit, setSubmit] = React.useState(false);

  const onSubmit = async (data) => {
    setSubmit(true);
    if (type === "create") {
      await ClusterBandwidthApi.create(data).then((response) => {
        const data = response.data;
        if (data.status !== 201) {
          setNotification({
            open: true,
            message: data.errors[0].message,
            severity: "error",
          });
          setSubmit(false);
          return;
        }
        setNotification({
          open: true,
          message: "Tạo đối soát thành công.",
          severity: "success",
        });
        handleClose();
      });
    } else {
      await ClusterBandwidthApi.update(
        data,
        data_cluster_bandwidth?.cluster_bandwidth_id
      ).then((response) => {
        const data = response.data;
        if (data.status !== 200) {
          setNotification({
            open: true,
            message: data.errors[0].message,
            severity: "error",
          });
          setSubmit(false);
          return;
        }
        setNotification({
          open: true,
          message: "Cập đối soát thành công.",
          severity: "success",
        });
        refetchData();
        handleClose();
      });
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xl={12}>
          <Box sx={{ p: 2 }}>
            <Typography
              sx={{ fontSize: { xs: 20, md: 24 }, fontWeight: "medium", mb: 2 }}
            >
              {type === "create" ? "Thêm" : "Chi tiết"} đối soát bandwidth các
              cụm
            </Typography>
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ mb: 0.5, mt: 1 }}>
                    {" "}
                    Thời gian bắt đầu đối soát: *
                  </Typography>
                  <Controller
                    name="cluster_bandwidth_date_start"
                    control={control}
                    defaultValue={
                      type === "create"
                        ? month_current.start
                        : type !== "create"
                          ? data_cluster_bandwidth?.cluster_bandwidth_date_start
                          : new Date()
                    }
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl
                          fullWidth
                          sx={{
                            ".MuiIconButton-edgeEnd": {
                              m: "unset",
                            },
                          }}
                        >
                          <DatePicker
                            value={value}
                            disabled={type !== "create"}
                            onChange={onChange}
                          />
                          {!!errors?.cluster_bandwidth_date_start && (
                            <ErrorHelperText
                              message={
                                errors?.cluster_bandwidth_date_start?.message
                              }
                            />
                          )}
                        </FormControl>
                      );
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ mb: 0.5, mt: 1 }}>
                    {" "}
                    Thời gian kết thúc đối soát: *
                  </Typography>
                  <Controller
                    name="cluster_bandwidth_date_end"
                    control={control}
                    defaultValue={
                      type === "create"
                        ? month_current.end
                        : type !== "create"
                          ? data_cluster_bandwidth?.cluster_bandwidth_date_end
                          : new Date()
                    }
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormControl
                          fullWidth
                          sx={{
                            ".MuiIconButton-edgeEnd": {
                              m: "unset",
                            },
                          }}
                        >
                          <DatePicker
                            disabled={type !== "create"}
                            value={value}
                            onChange={onChange}
                          />
                          {!!errors?.cluster_bandwidth_date_end && (
                            <ErrorHelperText
                              message={
                                errors?.cluster_bandwidth_date_end?.message
                              }
                            />
                          )}
                        </FormControl>
                      );
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TableContainer>
                  <TableHead>
                    <TableRow>
                      <TableCell width={"5%"} sx={{ textAlign: "center" }}>
                        STT
                      </TableCell>
                      <TableCell width={"15%"}>Cụm</TableCell>
                      <TableCell width={"20%"}>BW Limit (Gbps)</TableCell>
                      <TableCell width={"20%"}>
                        BW Uplink theo Switch (Gbps)
                      </TableCell>
                      <TableCell width={"20%"}>Max Bandwidth (Gbps)</TableCell>
                      <TableCell width={"20%"}>Customers Volume(GB)</TableCell>
                      <TableCell width={"10%"}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell width={"5%"} sx={{ textAlign: "center" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell width={"15%"}>
                          <Controller
                            name={`cluster_bandwidth_items[${index}][cluster_bandwidth_item_name]`}
                            defaultValue={
                              row?.cluster_bandwidth_item_name || ""
                            }
                            control={control}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <TextField
                                  multiline
                                  {...field}
                                  placeholder="Tên Cụm"
                                />
                              </FormControl>
                            )}
                          />
                        </TableCell>
                        <TableCell width={"20%"}>
                          <Controller
                            name={`cluster_bandwidth_items[${index}][cluster_bandwidth_item_bw_limit]`}
                            defaultValue={row?.cluster_bandwidth_item_bw_limit}
                            control={control}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <MuiCurrency
                                  defaultValue={
                                    row?.cluster_bandwidth_item_bw_limit || 0
                                  }
                                  {...field}
                                />
                              </FormControl>
                            )}
                          />
                        </TableCell>
                        <TableCell width={"20%"}>
                          <Controller
                            name={`cluster_bandwidth_items[${index}][cluster_bandwidth_item_bw_uplink]`}
                            defaultValue={row?.cluster_bandwidth_item_bw_uplink}
                            control={control}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <MuiCurrency
                                  defaultValue={
                                    row?.cluster_bandwidth_item_bw_uplink || 0
                                  }
                                  {...field}
                                />
                              </FormControl>
                            )}
                          />
                        </TableCell>
                        <TableCell width={"20%"}>
                          <Controller
                            name={`cluster_bandwidth_items[${index}][cluster_bandwidth_item_max]`}
                            defaultValue={row?.cluster_bandwidth_item_max}
                            control={control}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <MuiCurrency
                                  defaultValue={
                                    row?.cluster_bandwidth_item_max || 0
                                  }
                                  {...field}
                                />
                              </FormControl>
                            )}
                          />
                        </TableCell>
                        <TableCell width={"20%"}>
                          <Controller
                            name={`cluster_bandwidth_items[${index}][cluster_bandwidth_item_customer_volume]`}
                            defaultValue={
                              row?.cluster_bandwidth_item_customer_volume
                            }
                            control={control}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <MuiCurrency
                                  defaultValue={
                                    row?.cluster_bandwidth_item_customer_volume ||
                                    0
                                  }
                                  {...field}
                                />
                              </FormControl>
                            )}
                          />
                        </TableCell>
                        <TableCell width={"10%"}>
                          <Button
                            startIcon={<Clear />}
                            disabled={index === 0}
                            sx={{
                              px: 0,
                              minWidth: 42,
                              minHeight: 36,
                              ".MuiButton-startIcon": { m: 0 },
                            }}
                            onClick={() => remove(index)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key="add">
                      <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                        <Button
                          variant="outlined"
                          startIcon={<Add />}
                          sx={{
                            px: 3,
                            minWidth: 42,
                            minHeight: 36,
                            ".MuiButton-startIcon": { m: 0 },
                          }}
                          onClick={() => append()}
                        >
                          Thêm Dòng
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </TableContainer>
              </Grid>
              <Box
                sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}
              >
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ height: 36 }}
                  onClick={handleClose}
                >
                  Huỷ Thao Tác
                </Button>
                <Button
                  disabled={submit}
                  size="small"
                  onClick={handleSubmit(onSubmit)}
                  startIcon={
                    submit && (
                      <CircularProgress
                        sx={{ m: "auto 0", color: "#777680" }}
                        size={14}
                      />
                    )
                  }
                  variant="contained"
                  sx={{ ml: 2, height: 36 }}
                >
                  {type === "create" ? "Thêm" : "Cập Nhật"} đối soát các cụm
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Form;
