import React from "react";
import _ from "lodash";
import { Box, Grid, Typography, Link } from "@mui/material";
import { NavLink } from "react-router-dom";
import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import ProviderApi from "@/apis/beta/Provider/ProviderApi";
import { format_date_short } from "@/functions";
import { format_pricing_vnd } from "@/libs/constants";
import CellConfig from "@components/DataGrid/CellConfig2";
import CellSpecification from "@components/DataGrid/CellSpecification";

const InformationItem = ({ warehouse_item = {} }) => {
  const { data: warehouse_boxes } = WarehouseBoxApi.getAll();
  const { data: providers } = ProviderApi.getAll();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ color: "#777680" }}>
              Tên {warehouse_item?.warehouse_type?.warehouse_type_name}:
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {warehouse_item?.warehouse_item_name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ color: "#777680" }}>
              Serial Number / Service Tag:
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {warehouse_item?.warehouse_item_service_tag}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ color: "#777680" }}>Đơn Vị:</Typography>
            <Typography sx={{ color: "#00000F" }}>
              {warehouse_item?.warehouse_type?.warehouse_type_unit}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ color: "#777680" }}>Có Sẳn:</Typography>
            <Typography sx={{ color: "#00000F" }}>
              {warehouse_item?.warehouse_item_availability ? "No" : "Yes"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ color: "#777680" }}>Ngày Mua:</Typography>
            <Typography sx={{ color: "#00000F" }}>
              {format_date_short(warehouse_item?.warehouse_item_import_at)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ color: "#777680" }}>
              Thời Gian Bắt Đầu Bảo Hành:
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {format_date_short(
                warehouse_item?.warehouse_item_warranty_start_at
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ color: "#777680" }}>
              Thời Gian Kết Thúc Bảo Hành:
            </Typography>
            <Typography sx={{ color: "#00000F" }}>
              {format_date_short(
                warehouse_item?.warehouse_item_warranty_expired_at
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ color: "#777680" }}>Giá Nhập:</Typography>
            <Typography sx={{ color: "#00000F" }}>
              {format_pricing_vnd(warehouse_item?.warehouse_item_price)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ color: "#777680" }}>Nhà Cung Cấp:</Typography>
            <Typography sx={{ color: "#00000F" }}>
              {
                _.find(providers, { provider_id: warehouse_item.provider_id })
                  ?.provider_name
              }
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ color: "#777680" }}>Capacity:</Typography>
            {warehouse_item?.capacity && (
              <CellConfig configures={warehouse_item?.capacity} />
            )}
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box>
            <Typography sx={{ color: "#777680" }}>
              {warehouse_item?.warehouse_rack ? "IDC" : "Kho"}:
            </Typography>
            {warehouse_item?.warehouse_rack ? (
              <Link
                component={NavLink}
                to={`/warehouse/boxes/${warehouse_item.warehouse_rack?.warehouse_box_id}`}
              >
                {" "}
                {
                  _.find(warehouse_boxes, {
                    warehouse_box_id:
                      warehouse_item.warehouse_rack?.warehouse_box_id,
                  })?.label
                }
              </Link>
            ) : (
              _.find(warehouse_boxes, {
                warehouse_box_id:
                  warehouse_item.warehouse_box_item
                    .warehouse_box_item_reference_id,
              })?.label
            )}
          </Box>
        </Grid>
        {warehouse_item?.warehouse_rack && (
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Box>
              <Typography sx={{ color: "#777680" }}>Tủ Rack:</Typography>

              <Link
                to={`/warehouse/racks/${warehouse_item?.warehouse_rack?.warehouse_rack_id}`}
                component={NavLink}
              >
                <Typography className="react-link">
                  {warehouse_item?.warehouse_rack?.warehouse_rack_name}
                </Typography>
              </Link>
            </Box>
          </Grid>
        )}
        {warehouse_item?.warehouse_rack && (
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Box>
              <Typography sx={{ color: "#777680" }}>Vị Trí:</Typography>
              {warehouse_item?.warehouse_box_item?.warehouse_box_item_position +
                ([0, 1].includes(warehouse_item.warehouse_item_form_factor)
                  ? ""
                  : ` - ${warehouse_item?.warehouse_box_item
                    ?.warehouse_box_item_position +
                  (warehouse_item.warehouse_item_form_factor - 1)
                  }`)}
            </Box>
          </Grid>
        )}
        {warehouse_item?.warehouse_item_parent && (
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Box>
              <Typography sx={{ color: "#777680" }}>Thiết Bị:</Typography>
              <Link
                component={NavLink}
                to={`/warehouse/items/${warehouse_item?.warehouse_item_parent?.warehouse_item_id}/detail`}
              >
                {" "}
                {warehouse_item?.warehouse_item_parent
                  ?.warehouse_item_service_tag || ""}
              </Link>
            </Box>
          </Grid>
        )}

        {warehouse_item?.warehouse_model.slots.length > 0 && (
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Box>
              <Typography sx={{ color: "#777680" }}>Slots:</Typography>
              <CellSpecification
                items={warehouse_item?.warehouse_model.slots.map((o) => ({
                  label: `${o.slot_name} ${o.slot_max_capacity && o.slot_max_capacity !== 0
                      ? `- Max Capacity ${o.slot_max_capacity}`
                      : ""
                    } `,
                  value: o.slot_number,
                }))}
              />
            </Box>
          </Grid>
        )}
        {warehouse_item?.warehouse_model.specifications.length > 0 && (
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Box>
              <Typography sx={{ color: "#777680" }}>
                Thông số kỹ thuật:
              </Typography>
              <CellSpecification
                items={warehouse_item?.warehouse_model.specifications.map(
                  (o) => ({
                    label: `${o.specification_name}`,
                    value: ` ${o.specification_value} ${o.specification_unit || ""
                      }`,
                  })
                )}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default InformationItem;
