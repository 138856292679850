import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNotificationContext } from "@contexts/notification";
import PlaybookApi from "@/apis/beta/Threshold/Playbook/PlaybookApi";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ACTION_KEYS = [
    {
        key: "SwiftFederation.EdgeActions-AccessControl_blacklist",
        label: "SwiftFederation.EdgeActions-AccessControl_blacklist",
        select: true,
    },
    {
        key: "AlibabaCloud.EdgeActions-AccessControl_blacklist",
        label: "AlibabaCloud.EdgeActions-AccessControl_blacklist",
        select: false
    },
]

function PlaybookActionEdit({ open, handleClose ,playbook , refetch, action}) {
  const {
    mutateAsync: createAction,
    isLoading: createPending,
    notify,
  } = useMutationWithNotification(PlaybookApi.CreatePlaybookAction);
  const {
    mutateAsync: updateAction,
    isLoading: updatePending,
  } = useMutationWithNotification(PlaybookApi.UpdatePlaybookAction);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    clearErrors,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
        action_name: action ? action?.action_name : "",
        action_description: action ? action?.action_description : "",
        action_note: action ? action?.action_note : "",
        action_key: action ? action?.action_key : "",
        action_status: action ? action?.action_status : "active",
        metadata: action ? JSON?.stringify(action?.metadata) : "{}"
    },
  });
  const { setNotification } = useNotificationContext();


  const onSubmit = async (formValues) => {
    console.log(formValues)
    if (action) {
      const response = await updateAction({
        id: playbook?.playbook_id,
        action_id: action?.playbook_action_id,
        playbook_action: {
              ...formValues,
              metadata: JSON.parse(formValues.metadata),
            },
      }).then((data) => data).catch((error) => {
        return {error: error}
      });
      console.log(response)
      if (response?.error) {
        return notify("error", "Đã tồn tại action này");
      }
      if (!response?.error) {
        notify("success", `Cập nhật action thành công`);
        refetch();
        handleClose();
      } 
    }else{
      const response = await createAction({
        id: playbook?.playbook_id,
        playbook_action: {
              ...formValues,
              playbook_id: playbook?.playbook_id,
              metadata: JSON.parse(formValues.metadata),
            },
      }).then((data) => data).catch((error) => {
        return {error: error}
      });
      console.log(response)
      if (response?.error) {
        return notify("error", "Đã tồn tại action này");
      }
      if (!response?.error) {
        notify("success", `Thêm action thành công`);
        refetch();
        handleClose();
        resetData();
      }  
    }
  };
  const resetData = () => {
    if (action) return;
    setValue("action_name", "");
    setValue("action_description", "");
    setValue("action_note", "");
    setValue("action_status", "active");
    setValue("metadata", "{}");
    clearErrors();
  }
  const editAction = action ? "Edit" : "Add";
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          handleClose();
          resetData();
        }}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{editAction} Playbook action</DialogTitle>
          <DialogContent>
            <Box mt={2}>
                <Controller
                    name="action_name"
                    control={control}
                    rules={{
                    required: { message: "Action name is required", value: true },
                    }}
                    render={({ field: { onChange, ...field } }) => {
                    return (
                        <FormControl fullWidth>
                        <TextField
                            multiline
                            {...field}
                            sx={{ mt: 2 }}
                            label="Action name"
                            size="small"
                            placeholder="Action name"
                            onChange={(e) => {
                            onChange(e?.target?.value);
                            }}
                        />
                        {errors?.action_name?.message && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                {errors?.action_name?.message}
                            </FormHelperText>
                            )}
                        </FormControl>
                    );
                    }}
                />
                
                <Controller
                    name="action_description"
                    control={control}
                    render={({ field: { onChange, ...field } }) => {
                    return (
                        <FormControl fullWidth>
                        <TextField
                            multiline
                            {...field}
                            sx={{ mt: 2 }}
                            label="Action description"
                            size="small"
                            placeholder="action description"
                            onChange={(e) => {
                            onChange(e?.target?.value);
                            }}
                        />
                        </FormControl>
                    );
                    }}
                />
                <Controller
                    name="action_note"
                    control={control}
                    render={({ field: { onChange, ...field } }) => {
                    return (
                        <FormControl fullWidth>
                        <TextField
                            multiline
                            {...field}
                            sx={{ mt: 2 }}
                            label="Action note"
                            size="small"
                            placeholder="action note"
                            onChange={(e) => {
                            onChange(e?.target?.value);
                            }}
                        />
                        </FormControl>
                    );
                    }}
                />
                <Controller
                    name="action_key"
                    control={control}
                    rules={{
                    required: { message: "action key is required", value: true },
                    }}
                    render={({ field: { onChange, ...field } }) => {
                    return (
                        <FormControl sx={{ mt: 2 }} fullWidth>
                          <InputLabel id="action_key">Action key</InputLabel>
                          <Select 
                            labelId="action_key"
                            onChange={onChange} 
                            value={field.value} 
                            size="small"
                            label="action key"
                            placeholder="action key"
                          >
                              {ACTION_KEYS?.map((item,index) => <MenuItem key={index} value={item?.key} selected={item?.select}>{item?.label}</MenuItem>)} 
                          </Select>
                        {errors?.action_key?.message && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                {errors?.action_key?.message}
                            </FormHelperText>
                            )}
                        </FormControl>
                    );
                    }}
                />
                <Controller
                    name="action_status"
                    control={control}
                    rules={{
                    required: { message: "Action status is required", value: true },
                    }}
                    render={({ field: { onChange, ...field } }) => {
                    return (
                        <FormControl sx={{ mt: 2 }} fullWidth>
                          <InputLabel id="action_status_select">Action status</InputLabel>
                          <Select 
                            labelId="action_status_select"
                            onChange={onChange} 
                            value={field.value} 
                            size="small"
                            label="Action status"
                            placeholder="Action status"
                          >
                              <MenuItem value="active" selected>Active</MenuItem>
                              <MenuItem value="inactive">Inactive</MenuItem>
                          </Select>
                        {errors?.playbook_status?.message && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                {errors?.playbook_status?.message}
                            </FormHelperText>
                            )}
                        </FormControl>
                    );
                    }}
                />
                <Controller
                    name="metadata"
                    control={control}
                    rules={{
                    required: { message: "Action metadata is required", value: true },
                    }}
                    render={({ field: { onChange, ...field } }) => {
                    return (
                        <FormControl fullWidth>
                        <TextField
                            multiline
                            value={field.value}
                            label="Action metadata"
                            sx={{ mt: 2 }}
                            minRows={3}
                            onChange={(e) => {
                              try {
                                  JSON.parse(e?.target?.value?.trim());
                                  clearErrors("metadata");
                              } catch (error) {
                                  setError("metadata", {
                                  message: "Action metadata is in valid json format",
                                  });
                              }
                              onChange(e?.target?.value);
                            }}
                        />
                        {errors?.metadata?.message && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                {errors?.metadata?.message}
                            </FormHelperText>
                            )}
                        </FormControl>
                    );
                    }}
                />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="outlined" onClick={() =>{
              handleClose()
              resetData()
              }}>
              Huỷ
            </Button>
            <Button
              size="small"
              disabled={createPending || updatePending || !isDirty}
              variant="contained"
              type="submit"
            >
              {createPending || updatePending? <CircularProgress size={25} /> : editAction}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

export default PlaybookActionEdit;
