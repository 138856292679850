import ProductGroupApi from '@/apis/beta/Product/ProductGroupApi'
import withSuspense from '@/hocs/withSuspense'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import MultipleSelectCustomize from '@components/shared/MultipleSelectCustomize'
import { Grid, FormControl, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const CustomerServiceInteresting = () => {
    const { control } = useFormContext();
    const { data } = ProductGroupApi.List()

    return (
        <Grid container spacing={2}>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Dịch Vụ Quan Tâm
                </Typography>
            </Grid>
            <Grid item xl={9} lg={9} md={8} sm={6} xs={12}>

                <Controller
                    control={control}
                    name="customer_service_interestings"
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FormControl fullWidth>
                                <MultipleSelectCustomize
                                    limitTags={2}
                                    options={data?.map((item) => ({
                                        label: item?.product_group_name,
                                        value: item?.product_group_name?.toUpperCase()
                                    })) || []}
                                    value={value}
                                    compareAttributes={['label']}
                                    onChange={(event, value) => onChange(value)}
                                    input={{
                                        placeholder: "Nhập tên dịch vụ tìm kiếm..."
                                    }}
                                    sx={{
                                        "&.MuiAutocomplete-option": {
                                            textTransform: "uppercase"
                                        }
                                    }}
                                />
                            </FormControl>
                        )
                    }} />
            </Grid>
        </Grid>
    )
}

export default withSuspense(CustomerServiceInteresting, MuiSkeleton["TextField"])