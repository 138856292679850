import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";

class WarehouseProcessLogApi {
  Index = () => {
    return useQueryWithCustomized(
      ["warehouse_box_logs"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_box_logs`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_box_logs: data?.data?.warehouse_box_logs,
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            console.log(error.response);
            return {
              warehouse_box_logs: [],
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  async createBoxLog(data) {
    const response = await requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_box_logs`,
      "POST",
      data
    );
    return response?.data;
  }
  Show = ({ id }) => {
    return useQueryWithCustomized(
      [
        "warehouse_box_logs",
        id
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_box_logs/${id}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_box_logs: data?.data?.warehouse_box_logs,
            };
          })
          .catch((error) => {
            console.log(error.response);
            return {
              warehouse_box_logs: [],
            };
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new WarehouseProcessLogApi();
