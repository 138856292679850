import TicketRequestApi from '@/apis/beta/Ticket/TicketRequestApi';
import withSuspense from '@/hocs/withSuspense';
import ErrorHelperText from '@components/shared/ErrorHelperText';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize';
import { TICKETS } from '@features/Module/Ticket/Create';
import { FormControl } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const TicketRequest = ({
    locked = false,
    ticket_request_id,
    ticket,
    setUIs = () => alert('Not found UI setting.')
}) => {
    const { control, formState: { errors }, setValue } = useFormContext()
    const { data: ticket_requests } = TicketRequestApi.ListTicketRequest();

    useEffect(() => {
        if (!ticket_requests || !ticket_request_id) return;
        const ticketRequest = ticket_requests?.find(ticketRequest => ticketRequest?.ticket_request_id === ticket_request_id)
        if (!ticketRequest) return;
        if (ticketRequest?.ticket_request_id === 3 && !ticket) {
            setValue(
                "ticket_content",
                "1. Tên công ty, website: \n2. Thành phần meeting (tên, vị trí cv): \n3. Pain points của KH là gì?: \n4. Hệ thống họ đang dùng gì của đối thủ nào?: \n5. Ghi chú đặc biệt của KH (nếu có): \n6. Ngân sách của khách hàng:\n7. Thời gian dự kiến bán hàng:"
            );
        }
        return setUIs(ticketRequest?.ticket_request_settings || TICKETS?.UIS)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticket_request_id]);

    return (
        <Controller
            name="ticket_request_id"
            rules={{ required: "This field is required" }}
            control={control}
            render={({ field: { onChange, value } }) => {
                const selected =
                    ticket_requests?.find(
                        (x) => x?.ticket_request_id === value
                    ) || null;
                return (
                    <FormControl fullWidth>
                        <SingleSelectCustomize
                            input={{
                                label: 'Ticket Request *',
                                placeholder: 'Chọn loại request...'
                            }}
                            onChange={(_, newValue) => {
                                onChange(newValue?.ticket_request_id || null)

                            }}
                            disabled={locked}
                            value={selected}
                            formatCollection={{
                                label: "ticket_request_title",
                                value: "ticket_request_id",
                            }}
                            compareAttributes={["ticket_request_title"]}
                            options={ticket_requests}
                        />

                        {errors?.ticket_request_id && (
                            <ErrorHelperText
                                message={errors?.ticket_request_id?.message}
                            />
                        )}
                    </FormControl>
                );
            }}
        />
    )
}

export default withSuspense(TicketRequest, MuiSkeleton["TextField"])