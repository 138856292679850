import Devices from "./ReportItem/Devices"
import Others from "./ReportItem/Others"
import ReportRack from "./ReportRack";
import ReportResource from "./ReportResource";
import ReportInvestment from "./ReportInvestment";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Devices,
    Others,
    ReportRack,
    ReportResource,
    ReportInvestment
}