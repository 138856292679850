import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useParams } from "react-router";
import WarehouseItemDataGridDevice from "../../Shared/WarehouseItemDataGridDevice";
import WarehouseItemDataGridAccessory from "../../Shared/WarehouseItemDataGridAccessory";
import { Box } from "@mui/material";

const Home = () => {
  const params = useParams();

  const type_device = [1, 2];

  const ComponentRender = JSON.parse(params?.types_id)?.some((item) =>
    type_device?.includes(item)
  )
    ? WarehouseItemDataGridDevice
    : WarehouseItemDataGridAccessory;
  return (
    <Box>
      <ComponentRender
        types_id={JSON.parse(params?.types_id)}
        name={params?.name}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(Home, { feature: "warehouse", action: "items" }),
  MuiSkeleton["DataGrid"]
);
