import DataGrid from '@/components/DataGrid';
import DataGridFilter from '@/components/DataGrid/Filter';
import { BUSINESS_STATUS_TYPES, BUSINESS_TYPES, DAYOFF_STATUS_CONFIRM_TYPES } from "@/libs/constants";
import { CellLink } from '@components/DataGrid/Cell';
import { MoreVertOutlined } from '@mui/icons-material';
import { Box, Button, Chip, Drawer, IconButton, Popper, Typography, Collapse } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import PopperAction from "../../PopperAction";
import FormatTextNumeralVND from '../../shared/FormatTextNumeralVND';
import Detail from '../Detail';
import BusinessTimer from './BusinessTimer';
import ModalConfirmMultiple from "./ModalConfirmMultiple";

const BusinessListShared = ({
    feature = "account",
    filter, // account or management dayoffs
    updateQuery,
    data = [],
    employee = {},
    employees = [],
    inspector = {},
    pagination = {},
    isFetching = true,
    initialActions = [],
    inputSearch
}) => {
    const [buttonConfirm, setButtonConfirm] = React.useState({
        open: false,
        businessIds: []
    })

    const [popper, setPopper] = React.useState({
        anchorEl: undefined,
        data: undefined,
        actions: []
    })

    const handleClosePopper = () =>
        setPopper(prev => ({ ...prev, anchorEl: undefined, data: undefined, actions: [] }))

    const [drawer, setDrawer] = React.useState({
        open: false,
        data: undefined
    })

    const formatEmployeeCollections = employees?.map(e =>
        ({ value: e?.employee_id, label: e?.employee_name })
    ) || []

    const initialColumns = [
        {
            field: 'businesses.business_id', headerName: 'Mã Phiếu',
            width: 120,
            hideable: true,
            operators: { type: 'number' },
            valueGetter: ({ row }) => {
                return ({
                    label: `IBN - ${row?.business_id}`,
                    href: ["businesses", row?.business_id]
                })
            },
            renderCell: ({ value }) => <CellLink data={value} />
        }, {
            field: 'businesses.employee_id', headerName: 'Tên Nhân Viên',
            width: 200,
            hide: feature === "account",
            valueGetter: ({ row }) => row?.business_employee_name,
            operators: feature === "account" ? null : {
                type: 'object',
                collections: formatEmployeeCollections
            }
        }, {
            field: 'businesses.business_type', headerName: 'Loại Phiếu',
            width: 200,
            // hide: true,
            valueGetter: ({ row }) => {
                return BUSINESS_TYPES?.find((item) => item?.value === row?.business_type)?.label
            },
            operators: {
                type: 'object',
                collections: BUSINESS_TYPES
            }
        }, {
            field: 'overtime', headerName: 'Làm Ngoài Giờ',
            width: 120,
            hide: true,
            valueGetter: ({ row }) => {
                let flag = false
                _.forEach(row?.business_items, item => {
                    if (["x1", "x2"]?.includes(item.business_item_type)) return flag = true;
                })
                return flag ? "Có" : "Không"
            },
            renderCell: ({ value }) => value
        }, {
            field: 'businesses.business_startdate',
            headerName: 'Thời Gian',
            width: 250,
            valueGetter: ({ row }) => {
                return ({
                    business_startdate: row?.business_startdate,
                    business_enddate: row?.business_enddate
                })
            },
            operators: {
                type: 'date',
            },
            renderCell: ({ value }) => <BusinessTimer {...value} />
        }, {
            field: 'business_place', headerName: 'Nơi công tác',
            minWidth: 300,
            operators: { type: 'string' },
        }, {
            field: 'cash_support', headerName: 'Tiền Hỗ Trợ',
            width: 120,
            hide: true,
            operators: { type: "price" },
            valueGetter: ({ row }) => {
                let total = 0;
                _.forEach(row?.business_items, item => {
                    total += parseFloat(item.business_item_amount)
                })
                return total
            },
            renderCell: ({ value }) => <FormatTextNumeralVND value={value} />
        }, {
            field: 'businesses.business_leader_confirm', headerName: 'Trưởng Phòng',
            width: 120,
            operators: {
                type: "object",
                collections: DAYOFF_STATUS_CONFIRM_TYPES
            },
            valueGetter: ({ row }) => {
                return DAYOFF_STATUS_CONFIRM_TYPES?.find(t => t?.value === row?.business_leader_confirm)
            },
            renderCell: ({ value }) => <Typography sx={{ fontSize: 14 }} color={`custom.${value?.color}`}>{value?.label}</Typography>,
        }, {
            field: 'business_bod_confirm', headerName: 'BOD/ BOM',
            width: 120,
            operators: {
                type: "object",
                collections: DAYOFF_STATUS_CONFIRM_TYPES
            },
            valueGetter: ({ row }) => {
                return DAYOFF_STATUS_CONFIRM_TYPES?.find(t => t?.value === row?.business_bod_confirm)
            },
            renderCell: ({ value }) => <Typography sx={{ fontSize: 14 }} color={`custom.${value?.color}`}>{value?.label}</Typography>,
        }, {
            field: 'businesses.business_status', headerName: 'Trạng Thái',
            width: 150,
            operators: {
                type: "object",
                collections: BUSINESS_STATUS_TYPES,
                initQueries: [
                    { condition: "$and", filter: { 'businesses.business_status': { $eq: 0 } } },
                ]
            },
            valueGetter: ({ row }) => {
                return BUSINESS_STATUS_TYPES.find(t => t?.value === row?.business_status)
            },
            renderCell: ({ value }) =>
                <Chip size='small'
                    label={value?.label}
                    variant={`customize-${value?.chip}`}
                />
        }, {
            field: 'business_content',
            headerName: 'Nội Dung',
            width: 150,
            renderCell: ({ value }) => value,
        },
        {
            field: 'actions',
            headerName: '',
            hideable: true,
            width: 50,
            renderCell: ({ row }) => {
                if (row?.business_status !== 0) return null;
                let actions = initialActions;
                if (employee?.employee_id !== row?.employee_id || row?.business_leader_confirm || row?.business_bod_confirm) {
                    actions = actions?.filter(f => !["update", "business-cancel"].includes(f?.name))
                }
                if (!inspector?.leaders?.includes(employee?.employee_id) || row?.business_leader_confirm) {
                    actions = actions?.filter(f => f?.name !== "business-leader-confirm")
                }
                if (!inspector?.master_inspectors?.includes(employee?.employee_id) || row?.business_bod_confirm) {
                    actions = actions?.filter(f => f?.name !== "business-bod-confirm")
                }
                if (actions?.length === 0) return null;
                return (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <IconButton
                            size='small'
                            onClick={(event) => {
                                setPopper(prev => ({ ...prev, anchorEl: event?.target, data: row, actions: actions }))
                            }}
                            title='Hành động'
                        >
                            <MoreVertOutlined sx={{ m: 'auto' }} fontSize="small" />
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    const historyColumsHidden = JSON.parse(localStorage.getItem(filter?.columnsHidden) || `[]`) || []

    const [columns, setColumns] = React.useState(initialColumns?.map((column) => {
        if (historyColumsHidden?.includes(column?.field)) {
            return { ...column, hide: true }
        }
        return { ...column }
    }) || initialColumns)

    const filterables = columns?.filter((column) => !!column?.operators && !column?.hide)
        .map((column) => {
            return ({
                field: column?.key ? column?.key : column?.field,
                label: column?.headerName,
                operators: column?.operators
            })
        })

    return (
        <React.Fragment>
            <Collapse in={buttonConfirm?.businessIds?.length > 1}>
                <Box sx={{
                    display: 'flex',
                    mb: 2,
                    justifyContent: 'flex-end'
                }}>
                    <Button size='small'
                        variant='contained'
                        disabled={buttonConfirm?.businessIds?.length <= 1}
                        onClick={() => setButtonConfirm(prev => ({ ...prev, open: true }))}
                    >
                        Duyệt Phiếu Công Tác
                    </Button>
                </Box>
            </Collapse>
            {(buttonConfirm?.open && buttonConfirm?.businessIds?.length > 1) &&
                <ModalConfirmMultiple
                    businesses={data?.filter(business => buttonConfirm?.businessIds?.includes(business?.business_id))}
                    onClose={() => setButtonConfirm({ open: false, businessIds: [] })}
                />
            }
            {(popper?.anchorEl !== undefined && popper?.actions?.length > 0) && <Popper anchorEl={popper?.anchorEl}
                open={Boolean(popper.anchorEl)}
                sx={{ zIndex: 100 }}
            >
                <PopperAction {...popper} handleClose={handleClosePopper} />
            </Popper>
            }
            {
                (drawer?.open && drawer?.data !== undefined) &&
                <Drawer
                    anchor="right"
                    open={drawer?.open}
                    sx={{ width: 360, borderTopLeftRadius: '4px' }}
                >
                    <Detail
                        data={{
                            ...drawer?.data,
                            employee: employees?.find(e => e?.employee_id === drawer?.data?.employee_id),
                        }}
                        handleClose={() => setDrawer({ open: false, data: undefined })}
                    />
                </Drawer>
            }

            {
                feature !== "account" && <DataGridFilter callback={updateQuery}
                    filters={{
                        key: filter?.filter,
                        filterables: filterables,
                        exportFeature: "BusinessesExport"
                    }} />
            }
            <Box>
                <DataGrid
                    rows={data?.map((business) => ({ ...business, id: business?.business_id })) || []}
                    columns={columns}
                    loading={isFetching}
                    componentsProps={{
                        toolbar: {
                            store: filter?.columnsHidden,
                            columns: columns?.filter(col => !col?.hideable),
                            setColumns: setColumns,
                            initialColumns: initialColumns,
                            feature: 'dayoff',
                            inputSearch: inputSearch
                        },
                        pagination: { ...pagination }
                    }}
                    rowCount={pagination?.total}
                    disableSelectionOnClick
                    isRowSelectable={({ row }) => {
                        if (inspector?.master_inspectors?.includes(employee?.employee_id)) {
                            return !row?.business_bod_confirm
                        } else {
                            if (inspector?.leaders?.includes(employee?.employee_id)) return !(row?.business_leader_confirm);
                            return false;
                        }
                    }}
                    checkboxSelection={
                        feature === "management-businesses" && (inspector?.master_inspectors?.includes(employee?.employee_id) || inspector?.leaders?.includes(employee?.employee_id))
                    }
                    onSelectionModelChange={(props) => {
                        setButtonConfirm(prevState => ({ ...prevState, businessIds: props }))
                    }}
                    initialState={{
                        pinnedColumns: { right: ['actions'] },
                    }}
                />
            </Box>
        </React.Fragment>
    )
}

export default BusinessListShared