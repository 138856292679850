import ContractApi from "@/apis/beta/Contract/ContractApi";
import { format_date_short } from "@/functions";
import { useNotificationContext } from "@contexts/notification";
import { vestResolver } from "@hookform/resolvers/vest";
import { Box, Button, CircularProgress, Paper } from "@mui/material";
import moment from "moment";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ContractCustomer from "../ContractCustomer";
import ContractNote from "../ContractNote";
import ContractNumber from "../ContractNumber";
import ContractServices from "../ContractServices";
import ContractStatus from "../ContractStatus";
import ContractTimer from "../ContractTimer";
import ContractTitle from "../ContractTitle";
import contractServiceValidator from "./suite";

const CustomerContractForm = ({
  type = "create",
  defaultValues,
  defaultAttachment,
  contractDisabled = true,
  callback = () => console.log("nothing to recall..."),
}) => {
  let navigate = useNavigate();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(null);

  const methods = useForm({
    resolver: vestResolver(contractServiceValidator),
    defaultValues: defaultValues,
  });

  const onSubmit = async (values) => {
    setSubmit("submit");
    if (values?.services?.length === 0) {
      return setNotification({
        open: true,
        message: "Vui lòng thêm dịch vụ.",
        severity: "error",
      });
    }

    const duedate = moment(values?.expiry_date).format("DD/MM/YYYY");
    const endofMonth = moment(values?.expiry_date)
      .endOf("month")
      .format("DD/MM/YYYY");

    const isRound = duedate === endofMonth;
    let form = {
      customer_owner: values?.contract_customer_id,
      effective_date: format_date_short(values?.effective_date),
      expiry_date: format_date_short(values?.expiry_date),
      contract_status: values?.contract_status,
      contract_value: values?.contract_value,
      contract_note: values?.contract_note,
      contract_metadata: {
        products: [
          ...values?.services?.map((service) => ({
            ...service,
            service_extra_fields: {
              ...service?.service_extra_fields,
              round: !isRound,
            },
          })),
        ],
      },
    };

    const response =
      type === "update"
        ? await ContractApi.Update({
          contractId: values?.contract_id,
          data: form,
        })
        : await ContractApi.Create({ contract: form });
    setSubmit(null);
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        severity: "error",
        message:
          response?.errors[0]?.message ||
          "Failed when create customer contract.",
      });
    }
    setNotification({
      open: true,
      severity: "success",
      message:
        response?.message ||
        `${type === "create" ? "Thêm mới" : "Cập nhật"
        } hợp đồng khách hàng thành công.`,
    });
    if (type === "update") {
      if (!!callback) {
        callback();
      }
      localStorage.removeItem(`contract.update.data`);
      if (response?.contract?.service_id) {
        return navigate(`/services/${response?.contract?.service_id}`);
      }
    } else {
      try {
        localStorage.removeItem(`contract.create.data`);
        const customerId = localStorage.getItem(`contracts.create.redirects`);
        if (!!customerId) {
          localStorage.removeItem(`contracts.create.redirects`);
          return navigate(`/customers/${customerId}/contracts`);
        }
      } catch (error) {
        console.log(error?.message);
      }
    }
    return navigate(`/customers/${form?.customer_owner}/contracts`);
  };

  const onReset = async () => {
    try {
      setSubmit("reset");
      methods.reset({ ...defaultValues });
      setSubmit(null);
    } catch (error) {
      console.log(error?.message);
    }
    return setNotification({
      open: true,
      message: "Resetted!",
      severity: "success",
    });
  };

  return (
    <FormProvider {...methods}>
      <Paper
        sx={{
          p: { xs: 2, md: 3 },
        }}
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <ContractTitle type={type} />
          <ContractCustomer disabled={type !== "create"} />
          {type !== "create" && <ContractNumber />}
          <ContractTimer disabled={contractDisabled} />
          <ContractServices disabled={type !== "create" || contractDisabled} />
          {type !== "create" && <ContractStatus disabled={contractDisabled} />}
          <ContractNote disabled={contractDisabled} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              disabled={!!submit || contractDisabled}
              onClick={onReset}
              size="small"
              startIcon={
                !!submit &&
                submit === "reset" && (
                  <CircularProgress size={14} sx={{ color: "#777680" }} />
                )
              }
              sx={{
                height: { xs: 36, md: 42 },
                minWidth: { xs: "100%", md: 150 },
              }}
            >
              Huỷ Thao Tác
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={!!submit || contractDisabled}
              size="small"
              startIcon={
                !!submit &&
                submit === "submit" && (
                  <CircularProgress size={14} sx={{ color: "#777680" }} />
                )
              }
              sx={{
                height: { xs: 36, md: 42 },
                minWidth: { xs: "100%", md: 150 },
              }}
            >
              {type === "create" ? "Thêm " : "cập nhật "}
              Hợp Đồng
            </Button>
          </Box>
        </Box>
      </Paper>
    </FormProvider>
  );
};

export default CustomerContractForm;
