import { buildQueryFilterCondition } from "@/functions";
import { THRESHOLD_BINDS, THRESHOLD_INTERVALS, THRESHOLD_STATUS } from "@/libs/constants";
import { FormControl, MenuItem, Paper, Select, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

const ThresholdFilter = ({ updateQuery }) => {
  const { control, watch } = useForm({
    defaultValues: {
      "thresholds-threshold_bind": "",
      "thresholds-threshold_status": "",
      "thresholds-threshold_interval": "",
    },
  });

  useEffect(() => {
    const fieldQueries = Object?.keys(watch())
      ?.filter((key) => watch(key))
      ?.map((key) => ({
        name: key?.replace("-", "."),
        operator: "$eq",
        value: watch(key),
      }));

    updateQuery(buildQueryFilterCondition("$and", 0, fieldQueries));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watch())]);

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        alignItems: "center",
        gap: 2,
        flexWrap: "wrap",
      }}
    >
      <Controller
        control={control}
        name="thresholds-threshold_bind"
        render={({ field }) => (
          <FormControl fullWidth sx={{ flex: 1 }}>
            <Typography>Bind</Typography>
            <Select {...field} size="small" sx={{ mt: 2 }} fullWidth>
              {THRESHOLD_BINDS?.map((item) => (
                <MenuItem key={item?.value} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="thresholds-threshold_status"
        render={({ field }) => (
          <FormControl fullWidth sx={{ flex: 1 }}>
            <Typography>Trạng Thái</Typography>
            <Select {...field} size="small" sx={{ mt: 2 }} fullWidth>
              {THRESHOLD_STATUS?.map((item) => (
                <MenuItem key={item?.value} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="thresholds-threshold_interval"
        render={({ field }) => (
          <FormControl fullWidth sx={{ flex: 1 }}>
            <Typography>Interval</Typography>
            <Select {...field} size="small" sx={{ mt: 2 }} fullWidth>
              {THRESHOLD_INTERVALS?.map((item) => (
                <MenuItem key={item?.value} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Paper>
  );
};

export default ThresholdFilter;
