import { format_pricing_vnd } from "@/libs/constants";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import _ from "lodash";
import moment from "moment";
import { NavLink } from "react-router-dom";

const ProductTable = ({ items }) => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 200 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  Khách Hàng
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  Hoá Đơn
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  Dịch Vụ
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  Thành Tiền
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  Ngày tạo
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  Ngày paid
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody >
            {_.sortBy(items, (item) => moment(item.invoice_created_at))?.map(
              (item, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ border: 'none', py: 0.25 }}>
                    <Typography
                      component={NavLink}
                      to={`/customers/${item.service_customer_id}`}
                      sx={{ fontSize: 13, color: blue[800] }}
                      target='_blank'
                    >
                      {item.service_customer_id}-{item.company_name ? item.company_name : item.customer_name}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none', py: 0.25 }}>
                    <Typography
                      component={NavLink}
                      to={`/invoices/${item.invoice_id}`}
                      sx={{ fontSize: 13, color: blue[800] }}
                      target='_blank'
                    >
                      {(item.invoice_number !== '' && item.invoice_number) ||
                        item.invoice_id}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none', py: 0.25 }}>
                    <Typography
                      component={NavLink}
                      to={`/services/${item.service_id}`}
                      sx={{ fontSize: 13, color: blue[800] }}
                      target='_blank'
                    >
                      {item.service_id} - {item.service_name}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none', py: 0.25 }}>
                    <Typography sx={{ fontSize: 13, color: 'grey.700' }}>
                      {format_pricing_vnd(item.invoice_total || item.service_amount)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none', py: 0.25 }}>
                    <Typography sx={{ fontSize: 13, color: 'grey.700' }}>
                      {moment(item.invoice_created_at || item.service_created_at).format('DD/MM/YYYY')}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none', py: 0.25 }}>
                    <Typography sx={{ fontSize: 13, color: 'grey.700' }}>
                      {item.invoice_paid ? moment(item.invoice_paid).format('DD/MM/YYYY') : <></>}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
export default ProductTable;