import Blank from "./Blank";
import { Swiftfederation } from "./SwiftFederation";
import Colocation from "./Colocation";
import { Domain } from "./Domain";
import VPS from "./VPS";
import EmailServer from "./EmailServer";
import EGPlatform from "./EGPlatform";
import DeviceLiquidation from "./DeviceLiquidation";
import CloudServicek8s from "../../ClusterService(k8s)/CreateView";
import VnisUI from "../../VNIS/CreateView";


const ServiceTemplates = {
  Blank: Blank,
  "SwiftFederation.ContentDeliveryNetwork":
    Swiftfederation.ContentDeliveryNetwork,
  "SwiftFederation.Default": Swiftfederation.Default,
  "Domain.PaVietnam": Domain.PaVietnam,
  "Domain.ResellerClub": Domain.ResellerClub,
  Colocation: Colocation,
  CloudServer: VPS,
  CloudService: CloudServicek8s,
  VnisService: VnisUI,
  VPS: VPS,
  VNIS: VnisUI,
  EmailServer: EmailServer,
  EGPlatform: EGPlatform,
  DedicatedServer: DeviceLiquidation,
  BareMetalServer: DeviceLiquidation,
  DeviceLiquidation: DeviceLiquidation,
  "Other.DeviceLiquidation": DeviceLiquidation,
  SSLService: EmailServer
};

export default ServiceTemplates;
