import { Box, IconButton, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import AddWarehouseForm from "./AddWarehouseForm";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { initialTabsTypeWarehouse } from "@features/Module/WarehouseNew/Shared/Tabs";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";

const WarehouseItemForm = ({ onClose, onChange }) => {
  const [data, setData] = useState(initialTabsTypeWarehouse[0]?.data);

  return (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight="600" fontSize={18}>
          Thêm Hàng
        </Typography>

        <IconButton onClick={onClose}>
          <DeleteOutlineIcon color="error" />
        </IconButton>
      </Box>

      <Box mt={2}>
        <SingleSelectCustomize
          options={initialTabsTypeWarehouse}
          value={initialTabsTypeWarehouse?.find((item) => item?.data === data)}
          onChange={(_, value) => {
            setData(value?.data);
          }}
          input={{
            label: "Loại Hàng",
            placeholder: "Chọn Loại Hàng",
          }}
        />
      </Box>

      <AddWarehouseForm
        types={JSON.parse(data)}
        onClose={onClose}
        onChange={onChange}
      />
    </Paper>
  );
};

export default WarehouseItemForm;
