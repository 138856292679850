import JourneyApi from "@/apis/beta/Customer/Journey/JourneyApi";
import withSuspense from "@/hocs/withSuspense";
import NumericFormatCustom from "@components/NumericFormatCustom";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useNotificationContext } from "@contexts/notification";
import { Box, Button, FormControl, Grid } from "@mui/material";
import { Fragment, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { JourneyComponent } from "../JourneyComponent";
import Locked from "../Locked";
import ViewMore from "../ViewMore";

const JourneyExplaination = ({ journey, onlyView = false }) => {
  const { setNotification } = useNotificationContext();
  const { data: explaination } = JourneyApi.Explaination({
    customerId: journey?.customer_id,
    journeyId: journey?.customer_journey_id,
  });

  const methods = useForm({
    defaultValues: {
      ...explaination,
    },
  });

  const onSubmit = async (values) => {
    const response = await JourneyApi.UpdateExplaination({
      customerId: journey?.customer_id,
      journeyId: journey?.customer_journey_id,
      data: { ...values },
    });
    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors?.[0]?.message,
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: response?.data?.message || "Thao tác thành công.",
      severity: "success",
    });
  };

  const explainationOptions = [
    {
      key: "service_interestings",
      label: "Dịch Vụ Tiếp Cận/ Khai Thác",
      component: "ServiceInteresting",
      hideable: false,
      props: {
        input: {
          label: "Dịch Vụ Tiếp Cận/ Khai Thác",
          placeholder: "Nhập tên dịch vụ tìm kiếm...",
        },
      },
    },
    {
      key: "sale_tracking_id",
      label: "Sale Tracking",
      component: "SaleTracking",
      hideable: false,
      props: {
        name: 'sale_tracking_id',
        input: {
          label: "Sale Tracking",
        },
      },
    },
    {
      key: "explaination_result",
      label: "Trạng Thái",
      component: "ExplainationResult",
      hideable: false,
      props: {
        input: {
          label: "Trạng Thái",
        },
        disabled: true,
      },
    },
    {
      key: "explaination_service_created_id",
      label: "Dịch Vụ Được Mở Trên Hệ Thống",
      component: "ExplainationServiceResult",
      hideable: journey?.service_referrer_id === -1,
    },
    {
      key: "provider_explaination",
      component: "TextField",
      hideable: true,
      props: {
        label: "Khách hàng đang dùng nhà cung cấp nào?",
      },
    },
    {
      key: "budget_explaination",
      component: "TextField",
      hideable: true,
      props: {
        label: "Budget (chi phí/ tháng)",
        InputProps: {
          inputComponent: NumericFormatCustom,
        },
      },
    },
    {
      key: "influencer_explaination",
      component: "TextField",
      hideable: true,
      props: {
        label: "Người có khả năng đưa ra quyết định",
      },
    },
    {
      key: "problem_explaination",
      component: "TextField",
      props: {
        label: "Vấn đề của khách hàng",
        multiline: true,
        placeholder: "Vấn đề của khách hàng...",
      },
      hideable: true,
    },
    {
      key: "expected_explaination",
      component: "TextField",
      props: {
        label: "Mong đợi/ mong muốn",
        multiline: true,
        placeholder: "Mong đợi của khách hàng...",
      },
      hideable: true,
    },
    {
      key: "expected_date",
      component: "DatePicker",
      hideable: true,
      props: {
        label: "Thời gian bán hàng dự tính",
      },
    },
  ];

  const [mode, setMode] = useState(onlyView);

  return (
    <FormProvider {...methods}>
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {explainationOptions?.map((option, index) => {
          if (!mode && option?.hideable) return null;
          const Component = JourneyComponent[option?.component];
          if (!Component) return null;
          return (
            <Fragment key={index}>
              <Grid item xs={12} md={4}>
                <Controller
                  name={option?.key}
                  control={methods.control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <FormControl fullWidth sx={{ m: "auto 0" }}>
                        <Component
                          value={value}
                          onChange={onChange}
                          {...option?.props}
                        />
                      </FormControl>
                    );
                  }}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
      {!journey?.customer_journey_locked && !onlyView && (
        <Box
          sx={{
            mt: 1.5,
            display: "flex",
            justifyContent: "flex-end",
            gap: 1.5,
          }}
        >
          <Locked
            customerId={journey?.customer_id}
            journeyId={journey?.customer_journey_id}
          />
          <ViewMore open={mode} swap={setMode} />
          <Button
            size="small"
            variant="contained"
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
          >
            Cập Nhật
          </Button>
        </Box>
      )}
    </FormProvider>
  );
};

export default withSuspense(JourneyExplaination, MuiSkeleton["LinearProgress"]);
