import { Paper, Typography } from '@mui/material'
import React from 'react'

const ItemPaperStatistical = ({
    label,
    value,
    endText
}) => {
    return (
        <Paper sx={{ p: 2 }}>
            <Typography sx={{ color: '#777680', mb: 1 }}>{label}</Typography>
            <Typography sx={{ color: '#00000F', fontWeight: "bold", fontSize: { xs: 20, md: 28 } }}>
                {value || 0}
                {endText && <span style={{ textDecoration: 'underline' }}>đ</span>}
            </Typography>
        </Paper>
    )
}

export default ItemPaperStatistical