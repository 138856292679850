import { AppBar, Button, Container, Dialog, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, MenuItem, Slide, TextField, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CancelIcon from '@mui/icons-material/Cancel';
import DnsIcon from '@mui/icons-material/Dns';
import ReportWatchApi from '@/apis/beta/Threshold/Report/ReportWatchApi';
import Autocomplete from '@mui/material/Autocomplete';
import withSuspense from '@/hocs/withSuspense';
import MuiSkeleton from '@components/shared/MuiSkeleton';
const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const renderOption = (option) => {
  return (
    <MenuItem
      {...option}
      key={option["data-option-index"]}
      sx={{ '&:hover': { background: "#f0f0f0" } }}
    >
      {option["key"]}
    </MenuItem>

  );
};
const DialogEditReportData = (props) => {
  const [mail, setMail] = React.useState('');
  const [domain, setDomain] = React.useState('');
  const handleOnchangeMail = (value) => {
    setMail(value);
  }
  const handleOnchangeDomain = (value) => {
    setDomain(value);
  }
  const handleAddMail = () => {
    props.handleAddMail(mail);
    setMail('');
  }
  const handleAddDomain = () => {
    props.handleAddDomain(domain);
    setDomain('');
  }
  const handleRemoveMail = (index) => {
    props.handleRemoveMail(index);
  }
  const handleRemoveDomain = (index) => {
    props.handleRemoveDomain(index);
  }
  const handleOnchangeId = (value) => {
    props.handleOnchangeId(value);
  }
  const handleOnchangeSource_domains = (value) => {
    props.handleOnchangeSource_domains(value);
  }
  const onSubmit = async (data) => {
    await ReportWatchApi.UpdateReport(props.idEdit, {
      report: {
        metadata: data
      }
    }).then((response) => {
      if (!response?.data.report) {
        return props.setNotification({
          open: true,
          message: response?.errors?.[0]?.message || "Cập nhật thất bại.",
          severity: "error",
        });
      }
      props.handleClose();
      return props.setNotification({
        open: true,
        message: response?.errors?.[0]?.message || `Cập nhật thành công id ${props.idEdit}.`,
        severity: "success",
      });
    }).catch((error) => {
      return props.setNotification({
        open: true,
        message: error?.response?.data?.errors[0]?.message || "Cập nhật thất bại.",
        severity: "error",
      });
    })
  }
  const [autoCompleteValue, setAutoCompleteValue] = useState('');
  const {
    data,
  } = ReportWatchApi.MlyticList({
    organization_name: autoCompleteValue || ''
  });

  const onInputChange = (current_input) => {
    if (!current_input) return;
    if (!current_input.target?.value) return;
    setAutoCompleteValue(current_input.target?.value || '');
    handleOnchangeId(current_input.target?.value || '');
  }

  const onAutocompleteOnChange = (value) => {
    if (!value?.organizationName) return;
    setAutoCompleteValue(value.organizationName);
    handleOnchangeId(value.customerId);
  }
  return (
    <>
      <Dialog
        hideBackdrop
        fullScreen
        keepMounted
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
        scroll='paper'
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Thông tin
            </Typography>
            <Button autoFocus color="inherit"
              onClick={() => onSubmit(props.dataCustomer)}
            >
              Lưu chỉnh sửa
            </Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="sm" sx={{ p: 10 }}>
          <TextField
            type='mail'
            id="outlined-basic" label="Mail người liên hệ" sx={{ mt: 2 }} variant="outlined"
            value={mail}
            onChange={(e) => handleOnchangeMail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAddMail();
              }
            }}
          />
          <Button variant='contained' sx={{ mt: 2 }}
            onClick={() => handleAddMail()}
          >
            Thêm Mail
          </Button>
          {/* <TextField id="outlined-basic" sx={{ mt: 2 }} 
                label="ID" 
                variant="outlined" 
                value={props.dataCustomer.mlytics_id}
                onChange={(e) => handleOnchangeId(e.target.value)}
                /> */}
          <Autocomplete
            options={data?.Customers}
            getOptionLabel={(option) => option?.organizationName}
            value={{ organizationName: autoCompleteValue || props.dataCustomer.mlytics_id }}
            freeSolo
            renderOption={renderOption}
            onChange={(_, newValue) => {
              if (!newValue) {
                setAutoCompleteValue('');
                handleOnchangeId('');
              } else {
                onAutocompleteOnChange(newValue);
              }
            }}
            onInputChange={onInputChange}
            sx={{ width: '100%', mt: 2 }}
            renderInput={(params) => <TextField {...params} label="Mlytics_id" onKeyDown={(e) => e.key === "Enter" && e.preventDefault()} />}
          />
          <TextField id="outlined-basic" sx={{ mt: 2 }}
            label="Source Domains"
            variant="outlined"
            value={props.dataCustomer.source_domains}
            onChange={(e) => handleOnchangeSource_domains(e.target.value)}
          />
          <TextField id="outlined-basic" sx={{ mt: 2 }} label="Domain" variant="outlined"
            value={domain}
            onChange={(e) => handleOnchangeDomain(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAddDomain();
              }
            }}
          />
          <Button variant='contained' sx={{ mt: 2 }}
            onClick={() => handleAddDomain()}
          >Thêm Domain</Button>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Mail người liên hệ
              </Typography>
              <Demo>
                <List dense={true}>
                  {
                    props.dataCustomer.owners.map((mail, index) =>
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveMail(index)}>
                            <CancelIcon />
                          </IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <ContactMailIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={mail}
                        />
                      </ListItem>
                    )}
                </List>
              </Demo>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Domains
              </Typography>
              <Demo>
                <List dense={true}>
                  {
                    props.dataCustomer.domains.map((domain, index) =>
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveDomain(index)}>
                            <CancelIcon />
                          </IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <DnsIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={domain}
                        />
                      </ListItem>
                    )}
                </List>
              </Demo>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
}

export default withSuspense(DialogEditReportData, MuiSkeleton["LinearProgress"]);
