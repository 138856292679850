import { Box, Chip, Stack, Typography } from '@mui/material'
import React from 'react'

const CellSpecification = ({ items }) => {

    return (
        <Box sx={{
            height: 'auto',
            p: '4px',
            width: '100%',
            overflow: "hidden",
            justifyContent: "center",
        }}>
            {items.length > 0 ? 
            <Stack direction='row' spacing={0.5} sx={{ flexWrap: 'wrap' }}>
                {items.map((item, index) => {
                    return (
                        <Chip
                            title={`${( item.value ? (item.value + " X "): '')}  ${item?.label} ${item.unit || ""}`}
                            key={index}
                            size='small'
                            label={`${( item.value ? (item.value + " X "): '')}  ${item?.label} ${item.unit || ""}`}
                            variant="outlined"
                            color={`default`}
                        />
                    )
                })}
            </Stack>
            : 
            <Typography sx={{ color: "#777680", fontSize: 14 }}>{ "Không Có"}</Typography>
            }
        </Box>
    )
}

export default CellSpecification;