import ServiceConfigoptionApi from '@/apis/beta/Service/ServiceConfigoptionApi'
import { queryClientRefetchScope } from '@/functions'
import withSuspense from '@/hocs/withSuspense'
import { UNLIMITED } from '@/libs/constants'
import DataGridCustomize from '@components/DataGrid'
import MuiSkeleton from '@components/shared/MuiSkeleton'
import { useNotificationContext } from '@contexts/notification'
import { Box, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import PaymentMethod from './PaymentMethod'
import ClusterPriceExceed from './PriceExceed'
import PriceSave from './PriceSave'
import PriceUnit from './PriceUnit'

const Price = ({ service }) => {
    const { setNotification } = useNotificationContext()
    const queryClient = useQueryClient()

    const { data: { configoptions } } = ServiceConfigoptionApi.ConfigoptionPrices({ serviceId: service?.service_id })

    const [columns] = useState([
        { field: 'name', headerName: 'Row Labels', width: 350 },
        {
            field: 'unit', headerName: 'Đơn Vị Tính', width: 200,
            renderCell: ({ row }) => <PriceUnit data={row} setState={setState} />
        },
        {
            field: 'method', headerName: 'Đối Soát Luỹ Tiến', width: 150,
            renderCell: ({ row }) => <PaymentMethod data={row} setState={setState} />
        },
        {
            field: 'price_ranges', headerName: 'Giá Đốt Soát Vượt', width: 500,
            renderCell: ({ row }) => <ClusterPriceExceed data={row} setState={setState} />
        }
    ])

    const builders = useMemo(() => {
        return configoptions?.map((configoption, index) => {
            return {
                id: index,
                configoption_id: configoption?.service_configoption_id || -1,
                name: configoption?.name,
                unit: configoption?.unit || 'qty',
                payment_config_method: configoption?.payment_config_method || 'tierd',
                price_ranges: configoption?.price_ranges || [{
                    range_id: -1,
                    price_id: -1,
                    range_start: 0,
                    range_end: UNLIMITED,
                    pricing: 0
                }]
            }
        })
    }, [configoptions])

    const [rows, setState] = useState([...builders])
    useEffect(() => {
        return setState(builders)
    }, [builders])

    // submit payload 
    const { mutateAsync, isLoading } = useMutation(ServiceConfigoptionApi.UpdateConfigoptionPrices)
    const onSubmit = async () => {
        const response = await mutateAsync({
            serviceId: service?.service_id,
            configoptions: rows
        })

        if (!response || response?.errors) {
            return setNotification({ open: true, message: response?.errors?.[0]?.message, severity: 'error' })
        }
        queryClientRefetchScope(queryClient, 'service')

        return setNotification({ open: true, message: 'Oke', severity: 'success' })
    }

    return (
        <>
            <Typography sx={{ fontWeight: 'medium', mb: 0.75, fontSize: 14 }}>
                Bảng Giá Đối Soát Vượt
            </Typography>
            <Box sx={{
                pointerEvents: (isLoading) ? 'none' : 'unset',
                opacity: (isLoading) ? 0.3 : 1
            }}>
                <DataGridCustomize
                    loading={isLoading}
                    columns={columns}
                    rows={rows}
                    getRowHeight={() => 'auto'}
                    components={{
                        Toolbar: PriceSave,
                        Pagination: 'disabled'
                    }}
                    componentsProps={{
                        toolbar: {
                            onSubmit: onSubmit,
                            disabled: (isLoading)
                        }
                    }}
                />
            </Box>
        </>
    )
}

export default withSuspense(Price, MuiSkeleton["DataGrid"])