import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseTypeApi from "@/apis/beta/Warehouse/WarehouseTypeApi";
import useDataGridParams from "@/hooks/useDataGridParams";
import { Filter } from "@/hooks/useFilters";
import DataGridCustomize from "@components/DataGrid";
import Cell from "@components/DataGrid/Cell";
import CellSpecification from "@components/DataGrid/CellSpecification";
import PopperAction from "@components/PopperAction";
import { MoreVertOutlined } from "@mui/icons-material";
import { Box, IconButton, Popper } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import CreateModel from "../CreateModel";
import { Typography } from "@mui/material";

const TableModel = ({ warehouse_type, type_id, openDrawer, setOpenDrawer }) => {
  const initialColumns = [
    {
      field: "warehouse_model_id",
      headerName: "STT",
      hideable: false,
      width: 300,
    },
    {
      field: "warehouse_model_name",
      headerName: "Model",
      width: 300,
      renderCell: ({ value, row }) => <Cell content={value.toUpperCase()} />,
    },
    {
      field: "manufacture_name",
      headerName: "Nhà Sản Xuất",
      width: 300,
      renderCell: ({ row }) => (
        <Typography fontSize={14}>
          {row?.manufacture?.manufacture_name}
        </Typography>
      ),
    },

    {
      field: "total_input",
      headerName: "Nhập Hàng",
      valueGetter: ({ row }) =>
        _.filter(
          warehouse_items,
          (o) => o.warehouse_model_id === row.warehouse_model_id
        ).length,
      sortable: false,
      width: 200,
    },
    {
      field: "total_export",
      headerName: "Xuất",
      valueGetter: ({ row }) =>
        _.filter(
          warehouse_items,
          (o) =>
            o.warehouse_model_id === row.warehouse_model_id &&
            o.warehouse_item_export === true
        ).length,
      sortable: false,
      width: 200,
    },
    {
      field: "total_warehouse",
      headerName: "Ở Kho",
      sortable: false,
      cellClassName: "cty",
      valueGetter: ({ row }) =>
        _.filter(
          warehouse_items,
          (o) =>
            o.warehouse_model_id === row.warehouse_model_id &&
            o.warehouse_item_export === false &&
            o.warehouse_owner_name === "VNETWORK" &&
            o.warehouse_box_item_reference_type === "WarehouseBox"
        ).length,
      width: 200,
    },
    {
      field: "total_idc",
      headerName: "Ở IDC",
      valueGetter: ({ row }) =>
        _.filter(
          warehouse_items,
          (o) =>
            o.warehouse_model_id === row.warehouse_model_id &&
            o.warehouse_item_export === false &&
            o.warehouse_owner_name === "VNETWORK" &&
            o.warehouse_box_item_reference_type !== "WarehouseBox"
        ).length,
      sortable: false,
      width: 200,
    },
    {
      field: "total_customer",
      headerName: "Khách Hàng",
      valueGetter: ({ row }) =>
        _.filter(
          warehouse_items,
          (o) =>
            o.warehouse_model_id === row.warehouse_model_id &&
            o.warehouse_item_export === false &&
            o.warehouse_owner_name !== "VNETWORK"
        ).length,
      sortable: false,
      width: 200,
    },
    {
      field: "specification",
      headerName: "Thống số kỹ thuật",
      width: 600,
      valueGetter: ({ row }) => {
        let specifications = row?.specifications;
        specifications = _.map(specifications, (o) => ({
          label: `${o.specification_name} : ${o.specification_value || ""}${o.specification_unit || ""
            }  `,
          value: null,
        }));
        return specifications;
      },
      renderCell: ({ value }) => <CellSpecification items={value} />,
    },
    {
      field: "slots",
      headerName: "Slots",
      width: 600,
      valueGetter: ({ row }) => {
        let slots = row?.slots;
        slots = _.map(row?.slots, (o) => ({
          label: `${o.slot_name} ${o.slot_max_capacity > 0
            ? `- Max Capacity ${o.slot_max_capacity}`
            : ""
            }`,
          value: o.slot_number,
        }));
        return slots;
      },
      renderCell: ({ value }) => <CellSpecification items={value} />,
    },
    {
      field: "id",
      headerName: "Thao Tác",
      width: 80,
      renderCell: ({ row, value }) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            title="Thao Tác"
            onClick={(event) => {
              setPopper({ anchorEl: event?.target, data: row });
            }}
          >
            <MoreVertOutlined sx={{ m: "auto" }} />
          </IconButton>
        </Box>
      ),
    },
  ];

  const {
    page,
    columns,
    debouncedSearchQuery,
    searchQuery,
    setColumns,
    setPage,
    setSearchQuery,
  } = useDataGridParams(initialColumns);

  const {
    data,
    isFetching,
    refetch: callbackModel,
  } = WarehouseTypeApi.Models({
    page: page?.page + 1 || 1,
    type_id: type_id,
    pageSize: page?.pageSize || 20,
    searchQuery: debouncedSearchQuery,
  });

  const [popper, setPopper] = useState({
    anchorEl: undefined,
    data: undefined,
  });

  const [actions] = useState([
    {
      name: "update",
      handleClick: () => {
        setOpenDrawer(true);
      },
      icon: "Edit",
      label: "Chỉnh Sửa",
    },
  ]);

  const query = {
    $and: [{ "warehouse_types.warehouse_type_id": { $eq: type_id } }],
  };

  const { data: warehouse_items } = WarehouseItemApi.getAll(
    Filter.stringify(query)
  );

  return (
    <>
      {openDrawer && (
        <CreateModel
          type={popper.data ? "update" : "create"}
          model={popper.data}
          warehouse_type={warehouse_type}
          openDrawer={openDrawer}
          handleClose={() => {
            setOpenDrawer(false);
            setPopper({
              anchorEl: undefined,
              data: undefined,
            });
          }}
          callbackModel={() => callbackModel()}
        />
      )}

      <DataGridCustomize
        rows={data?.warehouse_models || []}
        columns={columns}
        loading={isFetching}
        rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
        componentsProps={{
          toolbar: {
            store: `models.list.hiddenColumns`,
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "models",
            inputSearch: {
              placeholder: "Tìm kiếm,...",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        sx={{ mt: 2 }}
      />

      {popper?.anchorEl !== undefined && (
        <Popper anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)}>
          <PopperAction
            {...popper}
            actions={actions}
            handleClose={() =>
              setPopper((prev) => ({ ...prev, anchorEl: undefined }))
            }
          />
        </Popper>
      )}
    </>
  );
};

export default TableModel;
