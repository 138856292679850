import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";

const Information = ({ warehouse_type }) => {
  return (
    <>
      {warehouse_type && (
        <Paper sx={{ p: 3, mb: 2, mt: 2 }}>
          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "row",
                    md: "column",
                  },
                  gap: 0.5,
                }}
              >
                <Typography sx={{ color: "#777680" }}>ID:</Typography>
                <Typography sx={{ color: "#00000F" }}>
                  {warehouse_type?.warehouse_type_id}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "row",
                    md: "column",
                  },
                  gap: 0.5,
                }}
              >
                <Typography sx={{ color: "#777680" }}>Tên Loại:</Typography>
                <Typography sx={{ color: "#00000F" }}>
                  {warehouse_type?.warehouse_type_name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "row",
                    md: "column",
                  },
                  gap: 0.5,
                }}
              >
                <Typography sx={{ color: "#777680" }}>Đơn Vị:</Typography>
                <Typography sx={{ color: "#00000F" }}>
                  {warehouse_type?.warehouse_type_unit}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "row",
                    md: "column",
                  },
                  gap: 0.5,
                }}
              >
                <Typography sx={{ color: "#777680" }}>Nhóm:</Typography>
                <Typography sx={{ color: "#00000F" }}>
                  {warehouse_type?.warehouse_group?.warehouse_group_name}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};
export default Information;
