import { requestWithToken, encodeBase64 } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";
class CustomerApi {
  List = ({ query = null, searchQuery = null, page = 1, pageSize = 20 }) => {
    return useQueryWithCustomized(
      ["customers.list", query, page, searchQuery, pageSize],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}&page=${page || 1
          }&page_size=${pageSize || 20}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              pagination: data?.data?.pagination,
              customers: data?.data?.customers || [],
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Statistical = ({ query = null, searchQuery = null }) => {
    return useQueryWithCustomized(
      ["customers.statistical", query, searchQuery],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/statistics?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Detail = (customerId) => {
    return useQueryWithCustomized(
      ["customers.detail", customerId],
      async () => {
        if (!customerId) return null;

        return await requestWithToken(`${API_V3_URL}/customers/${customerId}`)
          .then(({ data }) => {
            return data?.data?.customer;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Create = async (data) => {
    return await requestWithToken(
      `${API_V3_URL}/customers`,
      "POST",
      {
        customer: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Update = async ({ customerId = null, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}`,
      "PUT",
      {
        customer: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  UpdateExplaination = async ({ customerId = null, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/explaination`,
      "PUT",
      {
        customer: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  UpdateAddressInformation = async ({ customerId = null, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/address_information`,
      "PUT",
      {
        customer: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Statistics = () => {
    return useQueryWithCustomized(
      ["customers.statistics"],
      async () => {
        return await requestWithToken(`${API_V3_URL}/customers/statistics`)
          .then(({ data }) => {
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  MarkSpam = async (customerId) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/markspam`,
      "POST",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  CustomerDivide = ({ query = null, page = 1, pageSize = 10 }) => {
    return useQueryWithCustomized(
      ["customer-divide.list", query, page, pageSize],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/divide${query ? `?filters=${encodeBase64(query)}` : "?"
          }&page=${page || 1}&page_size=${pageSize || 20}`
        )
          .then(({ data }) => {
            if (data.error) return data;
            return {
              customers: data?.data?.customers,
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
  // deprecated
  // ReportCustomer = (customerId) => {
  //     return useQueryWithCustomized(
  //         ["customers.report-customer", customerId],
  //         async () => {
  //             return await requestWithToken(`${API_V3_URL}/customers/${customerId}/reports`)
  //                 .then(({ data }) => {
  //                     return data?.data?.reports;
  //                 })
  //                 .catch((error) => {
  //                     return error.response;
  //                 });
  //         },
  //         {
  //             staleTime: 10 * 60 * 1000
  //         }
  //     )
  // }

  CustomerInvoiceStatistics = (customerId) => {
    return useQueryWithCustomized(
      ["customers-invoices.statistics", customerId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/customer_invoices/statistic`
        )
          .then(({ data }) => {
            if (data.error) return data;
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  CustomerServices = ({
    customerId = -1,
    page = 1,
    pageSize = 10,
    query = null,
    searchQuery = null,
  }) => {
    return useQueryWithCustomized(
      [
        "customers.customer-services",
        customerId,
        page,
        pageSize,
        query,
        searchQuery,
      ],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/customer_services?page=${page || 1
          }&page_size=${pageSize || 5}${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}`
        )
          .then(({ data }) => {
            if (data.error) return data;
            return {
              services: data?.data?.services || [],
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  ListCustomer = () => {
    return useQueryWithCustomized(
      ["customers.all"],
      async () => {
        return await requestWithToken(`${API_V3_URL}/customers/list`)
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.customers;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  CustomerJourneys = ({
    page = 1,
    pageSize = 10,
    query = null,
    searchQuery = null,
    type = "potential",
  }) => {
    return useQueryWithCustomized(
      ["customer.customer-journeys", page, pageSize, query, searchQuery, type],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/customer_journeys?type=${type}&page=${page}&page_size=${pageSize}${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              customers: data?.data?.customers || [],
              employees: data?.data?.employees || [],
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Report = async ({ query = null, type = "potential" }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/report?type=${type}${!!query ? `&filters=${encodeBase64(query)}` : ""
      }`
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Cancelled = async ({ customerId = -1 }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}`,
      "DELETE",
      {},
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  Mailer = ({ customerId = -1 }) => {
    return useQueryWithCustomized(
      ["customer.customer-mailer", customerId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/customer_mailers`
        )
          .then(({ data }) => {
            if (data?.errors) return data;
            return data?.data?.customer_mailer;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  UpdateMailer = async ({ customerId = -1, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/customer_mailers`,
      "PUT",
      {
        customer_mailer: data,
      },
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  // csi customer service information
  UpdateCSI = async ({ customerId = -1, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/customer_service_information`,
      "PUT",
      {
        customer_service_information: data,
      },
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  ServiceInteresting = ({ customerId = -1 }) => {
    return useQueryWithCustomized(
      ["customers.service-interesting", customerId],
      async () => {
        return await requestWithToken(`${API_V3_URL}/customers/${customerId}`)
          .then(({ data }) => {
            if (data.error) return data;
            return {
              interestings:
                data?.data?.customer?.customer_service_information
                  ?.customer_service_interestings || [],
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  findServices = async (customerId) => {
    if (!customerId) return null;

    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/services`,
      "GET",
      {},
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return {
          services: data.data.services.map((o) => ({
            ...o,
            value: o.service_id,
            label: `(${o.service_id}) ${o.service_name} (${o.service_status})`,
          })),
        };
      })
      .catch((error) => {
        return error.response;
      });
  };

  ChangeCustomerType = async ({ customerId = -1, data = {} }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/change_customer_type`,
      "PUT",
      {
        customer_service_information: data,
      },
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  CustomerUpdates = ({ customerId = -1 }) => {
    return useQueryWithCustomized(
      ["customer.customer_updates", customerId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/customer_updates`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.customer_updates || [];
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Free = async ({ customerId = -1, reason = "" }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/cancel`,
      "PUT",
      {
        customer: {
          reason,
        },
      },
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  ChangeCreditCommitment = async ({ customerId, commitmentCredit = 0 }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/change_credit_commitment`,
      "PUT",
      {
        customer: {
          customer_credit_commitment: commitmentCredit,
        },
      },
      {}
    )
      .then(({ data }) => {
        if (data.error) return data;
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  CreditCommitment = ({ customerId = -1 }) => {
    return useQueryWithCustomized(
      ["customer.credit.commitment", customerId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/credit_commitment`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.customer || {};
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Trial = ({ query = null, searchQuery = null, page = 1, pageSize = 20 }) => {
    return useQueryWithCustomized(
      ["customers.trial", query, page, searchQuery, pageSize],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/trial?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${encodeBase64(searchQuery)}` : ""}&page=${page || 1
          }&page_size=${pageSize || 20}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              pagination: data?.data?.pagination,
              customers: data?.data?.customers || [],
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CustomerApi();
