import { Grid, Paper, Typography } from "@mui/material";
import ReactJson from "react-json-view";
import React from "react";

const DataInfo = ({ data, children }) => {
  const isJSON = (string) => {
    if (typeof string !== "string") return false;

    try {
      JSON.parse(string);
      return true;
    } catch (error) {
      return false;
    }
  };

  const renderTextValue = (obj) => {
    return isJSON(obj) ? (
      <ReactJson collapsed src={JSON.parse(obj)} />
    ) : typeof obj === "object" ? (
      <ReactJson collapsed src={obj} />
    ) : (
      obj
    );
  };

  return (
    <>
    {data &&
    <Paper sx={{ p: 3, mb: 1 }}>
      <Grid container spacing={2}>
        {Object.keys(data)
          ?.filter((item) => data?.[item])
          ?.map((item) => (
            <Grid key={item} item xl={3} lg={3} md={3} sm={6} xs={12}>
              <Typography sx={{ mb: 0.5, color: "#777680" }}>
                {item
                  ?.split("_")
                  ?.map((item) => item?.charAt(0).toUpperCase() + item.slice(1))
                  .join(" ")}
                :
              </Typography>
              <Typography sx={{ color: "#00000F" }}>
                {renderTextValue(data?.[item])}
              </Typography>
            </Grid>
          ))}
      </Grid>

      {children}
    </Paper>}
  </>
  );
};

export default DataInfo;
