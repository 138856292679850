import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { useEmployeeContext } from "@/contexts/employee";
import { useNotificationContext } from "@/contexts/notification";
import {
  queryClientRefetcher,
  removeObjectKeys,
  selectObjectKeys,
  sleep,
} from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import { SERVICES } from "@/libs/constants";
import { customerValidatorByStep } from "@/libs/validators";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { vestResolver } from "@hookform/resolvers/vest";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ErrorMessage from "../Create/ErrorMessage";
import BasicInfomation from "./BasicInformation";
import CustomerDetailInformation from "./CustomerDetail";
import Header from "./Header";

const Update = () => {
  const queryClient = useQueryClient();

  const { customer_id } = useParams();
  const { data: customer } = CustomerApi.Detail(customer_id);

  const navigate = useNavigate();
  const { employee } = useEmployeeContext();
  const { setNotification } = useNotificationContext();
  const [submit, setSubmit] = React.useState(false);
  const [defaultSteps] = React.useState([
    { label: "Thông Tin Người Liên Hệ & Doanh Nghiệp" },
    { label: "Thông Tin Chi Tiết" },
  ]);

  const DEFAULT_CUSTOMER_DATA = {
    customer_email: customer?.customer_information?.email,
    customer_name: customer?.customer_information?.name,
    customer_phone: customer?.customer_information?.phone_number,
    customer_position: customer?.customer_information?.position,
    customer_dob: !customer?.customer_information?.birthday
      ? moment()
      : moment(customer?.customer_information?.birthday),
    customer_cs_followers:
      customer?.customer_service_information?.customer_cs_followers || [],
    customer_sale_followers:
      customer?.customer_service_information?.customer_sale_followers || [],
    customer_service_interestings: SERVICES?.filter((item) =>
      customer?.customer_service_information?.customer_service_interestings?.includes(
        item.value
      )
    ),
    customer_industry:
      customer?.customer_service_information?.customer_industry,
    customer_industry_field:
      customer?.customer_service_information?.customer_industry_field,
    customer_website: customer?.customer_service_information?.customer_website,
    customer_source: customer?.customer_service_information?.customer_source,
    customer_source_event:
      customer?.customer_service_information?.customer_source_event || "",
    customer_note: customer?.customer_service_information?.customer_note,
    customer_contact_social:
      customer?.customer_service_information?.customer_contact_social,
  };

  const methods = useForm({
    resolver: vestResolver((props) =>
      customerValidatorByStep({
        ...props,
        type: "update",
        preCustomerEmail: customer?.customer_information?.email,
      })
    ),
    reValidateMode: "onChange",
    defaultValues: {
      currentStep: 1,
      customer: DEFAULT_CUSTOMER_DATA,
    },
  });
  const [steps, setSteps] = React.useState(defaultSteps);

  React.useEffect(() => {
    return setSteps(defaultSteps);
  }, [defaultSteps]);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = async () => {
    setSubmit(true);
    await sleep(300);
    try {
      handleSaveChange();
      methods.setValue("currentStep", methods.watch("currentStep") + 1);
      setActiveStep((prevStep) => prevStep + 1);
    } catch (error) {
      error?.console.log(error?.message);
    }
    setSubmit(false);
  };
  const handleBack = () => {
    methods.setValue("currentStep", methods.watch("currentStep") - 1);
    return setActiveStep((prevStep) => prevStep - 1);
  };

  const handleChangeStep = (nextStep) => {
    handleSaveChange();
    methods.setValue("currentStep", nextStep + 1);
    return setActiveStep(nextStep);
  };
  const onSubmit = async (values) => {
    setSubmit(true);
    let messageErrors = [];
    let form = { ...values };
    let dataSubmit = selectObjectKeys(form?.customer, [
      "customer_name",
      "customer_phone",
      "customer_email",
      "customer_industry",
      "customer_industry_field",
      "customer_dob",
      "customer_website",
      "customer_position",
      "customer_type",
      "customer_source",
      "customer_note",
      "customer_source_event",
    ]);

    dataSubmit = {
      ...dataSubmit,
      customer_sale_followers: values?.customer?.customer_sale_followers?.map(
        ({ employee_id }) => employee_id
      ),
      customer_cs_followers: values?.customer?.customer_cs_followers?.map(
        ({ employee_id }) => employee_id
      ),
      customer_service_interestings:
        values?.customer?.customer_service_interestings?.map(
          ({ value }) => value
        ),
      customer_contact_social: values?.customer?.customer_contact_social,
    };

    const response = await CustomerApi.Update({
      customerId: customer_id,
      data: dataSubmit,
    });
    setSubmit(false);
    if (!response || response?.errors) {
      messageErrors?.push({
        action: "update-customer",
        message:
          response?.errors?.[0]?.message || "Thêm mới khách hàng thất bại.",
      });
      return setNotification({
        open: true,
        message: messageErrors?.map((m) => m?.message).join(" & "),
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: "Thêm mới khách hàng thành công.",
      severity: "success",
    });

    queryClientRefetcher(queryClient, [
      "customers.detail",
      "customer.customer_updates",
      "customers.list",
      "customers.statistical",
      "customers.statistics",
      "customers.all",
      "customer.customer-journeys",
      "company.detail",
    ]);
    if (messageErrors?.length > 0) {
      return (
        <ErrorMessage
          data={messageErrors}
          customerId={response?.customer?.customer_id}
        />
      );
    } else {
      return navigate(`/customers/${customer_id}`, { replace: true });
    }
  };
  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <CustomerDetailInformation employee={employee} />;
      case 1:
        return <BasicInfomation />;
      default:
        throw new Error();
    }
  };
  const handleSaveChange = (event) => {
    let dataSave = { ...methods.getValues() };
    if (
      JSON.stringify(removeObjectKeys(dataSave, ["currentStep"])) ===
      JSON.stringify(DEFAULT_CUSTOMER_DATA)
    )
      return;
    dataSave = { ...dataSave, currentStep: activeStep + 1 };
    // collect data to savechange on localstorage
    try {
      localStorage.setItem(
        "customer.data.update",
        JSON.stringify(dataSave) || `{}`
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <Box>
      <Header />
      <Paper sx={{ p: 3 }}>
        <Grid container>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => {
                return (
                  <Step key={step.label}>
                    <StepLabel>
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => handleChangeStep(index)}
                        sx={{
                          color: "#000",
                          fontSize: { xs: 16, md: 18 },
                          fontWeight: "medium",
                        }}
                      >
                        {step?.label}
                      </Button>
                    </StepLabel>
                    <StepContent>
                      <FormProvider {...methods}>
                        <Box
                          component="form"
                          sx={{ width: "100%" }}
                          onSubmit={
                            index === 1
                              ? methods.handleSubmit(onSubmit)
                              : methods.handleSubmit(handleNext)
                          }
                        >
                          {getStepContent()}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              mt: 3,
                              gap: 2,
                            }}
                          >
                            {index !== 0 && (
                              <Button
                                disabled={submit}
                                variant="outlined"
                                onClick={handleBack}
                                size="small"
                                sx={{
                                  height: { xs: 36, md: 42 },
                                }}
                              >
                                Quay Lại
                              </Button>
                            )}
                            <Button
                              variant="contained"
                              type="submit"
                              size="small"
                              sx={{
                                height: { xs: 36, md: 42 },
                                minWidth: 150,
                              }}
                              disabled={submit}
                              startIcon={
                                submit && (
                                  <CircularProgress
                                    size={14}
                                    sx={{ color: "#777680" }}
                                  />
                                )
                              }
                            >
                              {index === 1 ? "Cập Nhật" : "Lưu & Tiếp Tục"}
                            </Button>
                          </Box>
                        </Box>
                      </FormProvider>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
export default withSuspense(
  withPermission(Update, { feature: "customer", action: "update" }),
  MuiSkeleton["Loading"]
);
