import { Box, Chip, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const MultiSelectTextInput = ({ onChange, value, label, sx }) => {
  const [tags, setTags] = useState(Array.isArray(value) ? value : []);
  const textField = React.useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      handleSplitTags(e);
    }
  };

  const handleSplitTags = (e) => {
    if (!e?.target?.value?.trim()) return;
    setTags([...tags, ...e.target.value?.split(" ")]);
    e.target.value = "";

    textField.current.querySelector("input").focus();
  };

  const handleDelete = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
  };

  useEffect(() => {
    if (!Array.isArray(tags)) return;
    onChange && onChange(tags || []);
  }, [JSON.stringify(tags)]);

  useEffect(() => {
    if (!Array.isArray(value)) return;
    setTags(value || []);
  }, [JSON.stringify(value)]);

  return (
    <Box sx={sx}>
      <TextField
        label={label}
        size="small"
        fullWidth
        onKeyDown={handleKeyDown}
        ref={textField}
      />

      {tags?.length > 0 && (
        <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
          {tags?.map((item, index) => (
            <Chip
              key={index}
              label={item}
              variant="outlined"
              onDelete={() => handleDelete(index)}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default MultiSelectTextInput;
