import List from "./List";
import Create from "./Create";
import Detail from "./Detail";
import Information from "./Detail/Information"
import Service from "./Detail/Service";
import UpdateService from "./Detail/Service/UpdateService"
import ServiceList from "./ServiceList";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    List,
    Create,
    Detail,
    Information,
    Service,
    UpdateService,
    ServiceList
}