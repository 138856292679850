import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotificationAlert from "@/components/NotificationAlert";
import { NotificationProvider } from "@/contexts/notification";
import theme from "@/styles/theme";
import Auth from "../Auth";
import Releases from "../Releases";
import ProtectedRoute from "./ProtectedRoute";
import { AppProvider } from "@/contexts/app";
import Account from "@features/Module/Account";
import ConfirmContextProvider from "@contexts/confirm";
import ReactQueryCustomizeProvider from "@components/Provider/ReactQueryCustomizeProvider";
import { PreviewFileContextProvider } from "@contexts/preview-file";

import "@/styles/fonts.scss";
import "@/styles/global.scss";
import { UploadFileContextProvider } from "@contexts/upload-file-context";

const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <AppProvider>
            <NotificationProvider>
              <ReactQueryCustomizeProvider>
                <ConfirmContextProvider>
                  <PreviewFileContextProvider>
                    <UploadFileContextProvider>
                      <Routes>
                        <Route path="/releases" element={<Releases />} />
                        <Route path="/auth/login" element={<Auth.Login />} />
                        <Route
                          exact
                          path="/employee_submit_info"
                          element={<Account.SubmitInformation />}
                        />
                        <Route path="/*" element={<ProtectedRoute />} />
                      </Routes>
                    </UploadFileContextProvider>
                  </PreviewFileContextProvider>
                </ConfirmContextProvider>
                <NotificationAlert />
              </ReactQueryCustomizeProvider>
            </NotificationProvider>
          </AppProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
