import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";

class WarehouseRackApi {
  WarehoseBoxItem = async (id) =>
    await requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_racks/${id}/warehouse_box_items`
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return { warehouse_box_items: data?.data?.warehouse_box_items };
      })
      .catch((error) => {
        console.log(error.response);
        return { warehouse_box_items: [] };
      });

  index = ({
    query = null,
    searchQuery = null,
    page = 1,
    pageSize = 20,
    warehouse_box_id = null,
  }) =>
    useQueryWithCustomized(
      [
        "warehouse.warehouse_racks.list",
        query,
        searchQuery,
        page,
        pageSize,
        warehouse_box_id,
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_racks?${
            query ? `&filters=${encodeBase64(query)}` : ""
          }${
            searchQuery ? `&queries=${searchQuery}` : ""
          }&page=${page}&page_size=${pageSize}&warehouse_box_id=${warehouse_box_id}`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return {
              warehouse_racks: data?.data?.warehouse_racks.map((o) => ({
                id: o.warehouse_rack_id,
                ...o,
              })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        // suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );

  create = (params) =>
    requestWithToken(`${API_WAREHOUSE_V1_URL}/warehouse_racks`, "POST", {
      warehouse_rack: params,
    });
  update = (id, params) =>
    requestWithToken(`${API_WAREHOUSE_V1_URL}/warehouse_racks/${id}`, "PUT", {
      warehouse_rack: params,
    });

  show = (id, callback) =>
    useQueryWithCustomized(
      ["warehouse_racks.detail", id],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_racks/${id}`
        )
          .then(({ data }) => {
            if (data?.errors) return callback(data.errors);

            return {
              warehouse_rack: data?.data?.warehouse_rack,
            };
          })
          .catch((error) => {
            console.log(error);
            return {
              warehouse_rack: null,
            };
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        notifyOnChangePropsExclusions: ["isStale"],
        suspense: true,
      }
    );

  getAll = () =>
    useQueryWithCustomized(
      ["warehouse.racks.all"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/warehouse_racks/list`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.warehouse_racks?.map((o) => ({
              value: o.warehouse_rack_id,
              label: o.warehouse_rack_name,
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );
  listRackBW = () => useQueryWithCustomized(
    ["warehouse.racks.listRackBW"],
    async () => {
      return await requestWithToken(
        `${API_WAREHOUSE_V1_URL}/warehouse_racks/list_rack_bw`
      )
        .then(({ data }) => {
          if (data?.error) return data;
          return data?.data?.warehouse_racks?.map((o) => ({
            value: o.warehouse_rack_id,
            label: o.warehouse_rack_name,
            ...o,
          }));
        })
        .catch((error) => {
          return error.response;
        });
    },
    {
      suspense: true,
      notifyOnChangePropsExclusions: ["isStale"],
    }
  );
}

export default WarehouseRackApi = new WarehouseRackApi();
