import ProductApi from "@/apis/beta/Product/ProductApi";
import ServiceApi from "@/apis/beta/Service/ServiceApi";
import DataGrid from "@/components/DataGrid";
import { CellLink, CellPricing, CellStatus } from "@/components/DataGrid/Cell";
import DataGridFilter from "@/components/DataGrid/Filter";
import { buildQueryFilterCondition, format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import useFilters, { Filter } from "@/hooks/useFilters";
import {
  INITIALIZE_SERVICE_GROUPS,
  SERVICE_STATUS_TYPES,
} from "@/libs/constants";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import Statistical from "./../Statistical";

const List = () => {
  const location = useLocation();
  const [query, updateQuery] = useFilters("services.list.filters", location);

  const [page, setPage] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const { data: { products } } = ProductApi.ListProducts({});

  const initialColumns = [
    {
      field: "tw_services.service_id",
      headerName: "ID",
      width: 60,
      disableReorder: true,
      hideable: true,
      valueGetter: ({ row }) => ({
        label: row?.service_id,
        href: ["services", row?.service_id],
      }),
      sortable: true,
      operators: { type: "number" },
      renderCell: ({ value }) => <CellLink data={value} />,
    },
    {
      field: "tw_services.service_product_id",
      headerName: "Sản Phẩm",
      sortable: true,
      minWidth: 300,
      operators: {
        type: "object",
        collections: products?.map((product) => ({
          value: product?.product_id,
          label: `${product?.product_group_name} - ${product?.product_name}`,
        })),
      },
      valueGetter: ({ row }) => row?.service_name,
    },
    {
      field: "tw_services.service_customer_id",
      headerName: "Khách Hàng",
      width: 200,
      valueGetter: ({ row }) => {
        let label = row?.customer_company_name;
        if (!label || label === "") {
          label = row?.customer_name;
        }
        return {
          label,
          href: ["customers", row?.service_customer_id],
        };
      },
      renderCell: ({ value }) => <CellLink data={value} />,
    },
    {
      field: "service_domain",
      headerName: "Domain/ IP",
      width: 150,
    },
    {
      field: "service_amount",
      headerName: "Đơn Giá",
      width: 150,
      operators: { type: "price" },
      sortable: true,
      renderCell: ({ value, row }) => {
        if (row?.service_create_is_trial) return "Trial";
        return <CellPricing price={value} />;
      },
    },
    {
      field: "created_at",
      headerName: "Ngày Tạo",
      width: 140,
      hide: true,
      renderCell: ({ value }) => format_date_short(value),
      operators: { type: "date" },
    },
    {
      field: "service_management_billings.service_billing_enddate",
      headerName: "Chu kỳ DV hiện tại",
      width: 200,
      valueGetter: ({ row }) => {
        if (row?.service_create_is_trial) return "Trial";
        if (!row?.service_billing_startdate || !row?.service_billing_enddate)
          return ``;
        if (row?.service_status === "trial" || row?.service_status === "stop")
          return ``;
        return `(${format_date_short(
          row?.service_billing_startdate
        )} - ${format_date_short(row?.service_billing_enddate)})`;
      },
      operators: {
        type: "date",
      },
    },
    {
      field: "service_management_billings.service_nextbill_startdate",
      headerName: "Ngày Gia Hạn Tiếp Theo",
      width: 200,
      valueGetter: ({ row }) => {
        if (row?.service_creatable_type === 'DeviceLiquidation') return "";
        if (row?.service_create_is_trial) return "Trial";
        if (!row?.service_nextbill_startdate || !row?.service_nextbill_enddate)
          return ``;
        if (row?.service_status === "trial" || row?.service_status === "stop")
          return ``;
        return `(${format_date_short(
          row?.service_nextbill_startdate
        )} - ${format_date_short(row?.service_nextbill_enddate)})`;
      },
      operators: {
        type: "date",
      },
    },
    {
      field: "service_start_date",
      headerName: "Ngày Bắt Đầu DV",
      width: 140,
      hide: true,
      renderCell: ({ value }) => format_date_short(value),
      operators: { type: "date" },
    },
    {
      field: "service_stopped_at",
      headerName: "Ngày Kết Thúc DV",
      width: 140,
      operators: { type: "date" },
      valueGetter: ({ row }) => {
        if (row?.service_status !== "stop") return ``;
        if (!row?.service_stopped_at) return ``;
        return format_date_short(row?.service_stopped_at);
      },
    },
    {
      field: "tw_services.service_status",
      headerName: "Trạng Thái",
      width: 150,
      sortable: true,
      operators: { type: "object", collections: SERVICE_STATUS_TYPES || [] },
      valueGetter: ({ row }) =>
        SERVICE_STATUS_TYPES.find((stt) => stt?.value === row?.service_status),
      renderCell: ({ value }) => (
        <CellStatus component="muiChip" data={value} />
      ),
    },
    {
      field: "service_note",
      headerName: "Ghi Chú",
      width: 250,
      operators: { type: "string" },
    },
  ];

  const historyColumsHidden =
    JSON.parse(localStorage.getItem("services.list.hiddenColumns")) || [];

  const [columns, setColumns] = React.useState(
    initialColumns?.map((column) => {
      if (historyColumsHidden?.includes(column?.field)) {
        return { ...column, hide: true };
      }
      return { ...column };
    }) || initialColumns
  );

  const filterables = columns
    .filter((column) => !!column?.operators && !column?.hide)
    .map((column) => {
      return {
        field: column?.key ? column.key : column.field,
        label: column?.headerName,
        operators: column?.operators,
      };
    });

  const filterableService = React.useCallback(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === "") return "";
    const fieldQueries = [
      {
        name: "tw_services.service_id",
        operator: "$eq",
        disabled: !parseInt(debouncedSearchQuery),
        value: parseInt(debouncedSearchQuery),
      },
      {
        name: "tw_services.service_product_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "tw_service_metadata.ip",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "tw_service_metadata.domain",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "personal_informations.name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "personal_informations.email",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
      {
        name: "companies.company_name",
        operator: "$contains",
        value: debouncedSearchQuery,
      },
    ];
    return buildQueryFilterCondition("$or", 0, fieldQueries);
  }, [debouncedSearchQuery]);

  const serviceQueries = useMemo(() => {

    const queryLength = Filter.stringify(query)?.split("&")?.length || 0
    const fieldQueries = [
      {
        name: "tw_services.service_referrer_type",
        operator: "$ne",
        value: "Reducer::Override::Service",
      }, {
        name: "tw_services.service_create_is_trial",
        operator: "$eq",
        value: 0,
      },
      {
        name: "tw_services.service_status",
        operator: "$nin",
        value: 'waiting-confirm'
      },
      {
        name: "tw_services.service_creatable_type",
        operator: "$nin",
        value: 'DeviceLiquidation'
      },
    ];
    return [
      Filter.stringify(query),
      buildQueryFilterCondition("$and", queryLength, fieldQueries)
    ]?.join('&')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const { data, isFetching } = ServiceApi.List({
    page: page?.page + 1,
    pageSize: page?.pageSize || 10,
    query: serviceQueries,
    searchQuery: filterableService(),
  });

  return (
    <Box>
      <Statistical />
      <DataGridFilter
        callback={updateQuery}
        filters={{
          key: "services.list.filters",
          filterables: [
            ...Array.from(filterables),
            {
              field: `service_product_id`,
              label: "Nhóm Dịch Vụ",
              operators: {
                type: "object",
                operators: ["$in"],
                collections: INITIALIZE_SERVICE_GROUPS,
              },
            },
          ],
        }}
      />
      <Box>
        <DataGrid
          rows={
            data?.services?.map((service) => ({
              ...service,
              id: service?.service_id,
              product:
                service?.service_product_group +
                " - " +
                service?.service_product,
            })) || []
          }
          columns={columns}
          loading={isFetching}
          componentsProps={{
            toolbar: {
              store: "services.list.hiddenColumns",
              columns: columns?.filter((col) => !col?.hideable),
              setColumns: setColumns,
              initialColumns: initialColumns,
              inputSearch: {
                placeholder:
                  "Nhập ID dịch vụ/ ID khách hàng hoặc Domain/IP tìm kiếm...",
                value: searchQuery,
                onChange: setSearchQuery,
              },
            },
            pagination: {
              rowsPerPageOptions: [10, 20, 50, 100],
              page: page?.page || 0,
              pageSize: page?.pageSize,
              onPageChange: (newPage) =>
                setPage((prevState) => ({ ...prevState, page: newPage })),
              onPageSizeChange: (newPageSize) =>
                setPage((prevState) => ({
                  ...prevState,
                  pageSize: newPageSize,
                })),
            },
          }}
          rowCount={data?.pagination?.total}
        />
      </Box>
    </Box>
  );
};
export default withSuspense(
  withPermission(List, { feature: "service", action: "index" }),
  MuiSkeleton["DataGrid"]
);
