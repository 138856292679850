import MuiDateTimePicker from "@components/shared/MuiDateTimePicker";
import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const TicketTimeReply = () => {
    const { control } = useFormContext()
    return (
        <Controller
            name="ticket_time_repply"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
                <FormControl fullWidth>
                    <MuiDateTimePicker
                        {...field}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Ngày trả lời"
                    />
                </FormControl>
            )}
        />
    )
}

export default TicketTimeReply