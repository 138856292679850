import { format_date_short } from "@/functions";
import { WAREHOUSE_STATUS_BUY, format_pricing_vnd } from "@/libs/constants";
import Cell from "@components/DataGrid/Cell";
import DataGridProCustomize from "@components/DataGridProCustomize";
import { useConfirmContext } from "@contexts/confirm";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import AddChildItemForm from "../Form/WarehouseItemForm/AddChildItemForm";

const TableAccessory = ({
  warehouse_item_children,
  handleRemoveItemChild,
  handleEditChild,
}) => {
  const { showConfirmation } = useConfirmContext();
  const [showModalEditChild, setShowModalEditChild] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "Thao Tác",
      width: 120,
      renderCell: ({ row }) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <IconButton
            onClick={() =>
              showConfirmation({
                title: "Bạn có chắc muốn xoá linh kiện này ra khỏi thiết bị",
                open: true,
                description: "Cảnh báo trước khi xoá",
                loading: false,
                handler: () => handleRemoveItemChild(row?.id),
              })
            }
          >
            <DeleteOutlineOutlined color="error" />
          </IconButton>

          <IconButton
            onClick={() => {
              setPopper({
                data: row,
              });

              setShowModalEditChild(true);
            }}
          >
            <EditOutlined color="info" />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "warehouse_item_name",
      headerName: "Linh Kiện",
      width: 300,
    },
    {
      field: "total",
      headerName: "Số lượng",
      valueGetter: ({ row }) =>
        !_.isEmpty(row?.service_tags)
          ? row?.service_tags?.length
          : parseInt(row?.total),
      width: 150,
    },
    {
      field: "service_tags",
      headerName: "Service_Tag/ Series Number",
      valueGetter: ({ row }) =>
        !_.isEmpty(row?.service_tags) ? row?.service_tags.join(" ,") : "",
      renderCell: ({ value }) => <Cell content={value} />,
      width: 300,
    },
    {
      field: "warehouse_item_status",
      headerName: "Trình Trạng",
      valueGetter: ({ row }) =>
        _.find(WAREHOUSE_STATUS_BUY, {
          value: parseInt(row?.warehouse_item_status),
        })?.label,
      width: 150,
    },
    {
      field: "warehouse_item_price",
      headerName: "Giá Mua",
      valueGetter: ({ row }) =>
        format_pricing_vnd(Number(row?.warehouse_item_price)),
      width: 200,
    },
    {
      field: "warehouse_item_warranty_start_at",
      headerName: "Ngày Hiệu Lực Bảo Hành",
      valueGetter: ({ row }) =>
        format_date_short(row?.warehouse_item_warranty_start_at),
      width: 200,
    },
    {
      field: "warehouse_item_warranty_expired_at",
      headerName: "Ngày hết Bảo Hành",
      valueGetter: ({ row }) =>
        format_date_short(row?.warehouse_item_warranty_expired_at),
      width: 200,
    },
  ];

  const [popper, setPopper] = useState({
    anchorEl: undefined,
    data: undefined,
  });
  return (
    <>
      <DataGridProCustomize
        sx={{
          "& .cty": {
            backgroundColor: "#ff943975",
            fontWeight: "600",
          },
          mt: 2,
        }}
        rows={warehouse_item_children || []}
        columns={columns}
        getRowId={() => Math.random()}
      />

      {showModalEditChild && (
        <AddChildItemForm
          onChange={(values) => handleEditChild(values)}
          data={popper?.data}
          onClose={() => {
            setShowModalEditChild(false);
          }}
        />
      )}
    </>
  );
};

export default TableAccessory;
