import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const Holiday = () => {
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      data: [
        {
          holiday_date: dayjs(Date.now()),
          holiday_work_n_day: 2,
          holiday_name: "",
        },
      ],
    },
  });

  const navigate = useNavigate();

  const { mutateAsync, isLoading, notify } = useMutationWithNotification(
    WorkDayApi.SyncHoliday
  );

  const syncHoliday = async (values) => {
    const response = await mutateAsync({
      holiday: values?.data?.map((item) => ({
        ...item,
        holiday_date: item?.holiday_date?.format("DD/MM/YYYY"),
      })),
    });

    if (response?.errors)
      return notify(
        "error",
        response?.errors?.[0]?.message || "Something went wrong"
      );

    notify("success", "Saved");

    navigate("/workdays/holiday")
  };

  return (
    <Box>
      <Box onSubmit={handleSubmit(syncHoliday)} component="form" p={2}>
        <Typography
          sx={{
            fontSize: { xs: 18, md: 24 },
            fontWeight: "medium",
          }}
        >
          Thêm Ngày Nghỉ
        </Typography>

        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography fontSize={14}>Chọn ngày nghỉ</Typography>

          <Box mt={2}>
            {watch("data")?.map((_, index) => (
              <LocalizationProvider dateAdapter={AdapterDayjs} key={index}>
                <Box display="flex" alignItems="center" mb={2} flexWrap="wrap">
                  <Controller
                    control={control}
                    name={`data[${index}].holiday_date`}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ mr: 2, flex: 1 }}
                            label="Ngày nghĩ"
                          />
                        )}
                        inputFormat="DD/MM/YYYY"
                      />
                    )}
                  />

                  <Tabs
                    value={watch("data")?.[index]?.holiday_work_n_day}
                    onChange={(_, value) => {
                      setValue(`data.${index}.holiday_work_n_day`, value);
                    }}
                    variant="standard"
                    scrollButtons="auto"
                    sx={{ mr: 3, flex: 1 }}
                  >
                    <Tab label="Buổi sáng" value={0} />
                    <Tab label="Buổi chiều" value={1} />
                    <Tab label="Cả Ngày" value={2} />
                  </Tabs>

                  <Controller
                    control={control}
                    name={`data[${index}].holiday_name`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Ghi chú"
                        sx={{ mr: 2, flex: 1 }}
                      />
                    )}
                  />

                  <Button
                    onClick={() => {
                      if (watch("data")?.length === 1) return;
                      setValue("data", [
                        ...watch("data")?.filter((_, i) => i !== index),
                      ]);
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
              </LocalizationProvider>
            ))}
          </Box>

          <Button
            onClick={() =>
              setValue("data", [
                ...watch("data"),
                {
                  holiday_date: dayjs(Date.now()),
                  holiday_name: "",
                  holiday_work_n_day: 0,
                },
              ])
            }
            variant="outlined"
            size="small"
          // sx={{ mt: 2 }}
          >
            <AddIcon />
          </Button>

          <Box mt={4}>
            <Button
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={14} />}
              type="submit"
              variant="contained"
              size="small"
            >
              Lưu
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default withSuspense(
  withPermission(Holiday, {
    feature: "workdays",
    action: "sync-holiday",
  }),
  MuiSkeleton["GridInformation"]
);
