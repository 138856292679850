import { format_numeral_price } from "@/functions";
import { UNLIMITED } from "@/libs/constants";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useFormContext } from "react-hook-form";

const Overview = ({ products = [] }) => {
  const { watch } = useFormContext();

  const amount = React.useMemo(() => {
    let tt = 0;
    const startdate = watch(`effective_date`);
    const enddate = watch(`expiry_date`);

    const roundMonth = dayjs(enddate)
      .startOf("day")
      .add(1, "days")
      .diff(dayjs(startdate).startOf("day"), "months");

    const roundendofMonth = dayjs(startdate).add(roundMonth, "months");
    const days = dayjs(enddate).diff(roundendofMonth, "days") + 1;

    if (roundMonth < 0 || (roundMonth === 0 && days <= 0)) return 0;
    products.forEach((product) => {
      switch (product?.product_template) {
        case "Domain.PaVietnam":
        case "Other.DeviceLiquidation":
        case "Domain.ResellerClub":
          tt += parseFloat(product?.product_pricing_amount);
          break;
        case "SwiftFederation.ContentDeliveryNetwork":
          if (product.product_payment_type === "postpaid") {
            tt += 0;
            break;
          }
          const configoptions = product?.product_configoptions || [];
          configoptions?.forEach((configoption) => {
            if (parseFloat(configoption?.value) > 0) {
              const term = parseInt(configoption?.commitment_term || 1);
              let configoptionPrice = 0;
              if (parseFloat(configoption?.value) >= UNLIMITED) {
                configoptionPrice = parseFloat(
                  configoption?.pricing_amount || 0
                );
                tt += configoptionPrice * Math.round(roundMonth / term);
                if (days > 0) {
                  tt += Math.round(configoptionPrice / term) * days;
                }
              } else {
                configoptionPrice =
                  parseFloat(configoption?.value) *
                  parseFloat(configoption?.pricing_apply);
                tt += configoptionPrice * Math.round(roundMonth / term);
                if (days > 0) {
                  tt += Math.round(configoptionPrice / term) * days;
                }
              }
            } else {
              tt += 0;
            }
          });
          break;

        case "SwiftFederation.Default":
          tt += product?.product_pricing_amount;
          break;
        default:
          if (
            product?.product_billing_cycle === "lifetime" ||
            product?.product_billing_cycle === "onetime"
          ) {
            tt += parseFloat(product?.product_pricing_amount);
            product?.product_addons?.forEach((addon) => {
              tt += parseFloat(addon?.qty) * parseFloat(addon?.pricing_apply);
            });
          } else {
            if (product.product_paid) {
              const productPricingPerMonth = parseFloat(
                product?.product_pricing_amount
              );

              tt += productPricingPerMonth * roundMonth;
              let dayOfMonth = 30;

              if (days > 0) {
                tt +=
                  (Math.round((productPricingPerMonth / dayOfMonth) * 100) /
                    100) *
                  days;
              }

              product?.product_addons?.forEach((addon) => {
                const addonPricingPerMonth = Math.round(
                  parseFloat(addon?.qty) * parseFloat(addon?.pricing_apply)
                );
                tt +=
                  addonPricingPerMonth * roundMonth +
                  (Math.round((addonPricingPerMonth / dayOfMonth) * 100) /
                    100) *
                    days;
              });
            }
          }
      }
      // not add
    });
    return tt;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, watch(`effective_date`), watch(`expiry_date`)]);

  return (
    <Box
      sx={{
        mt: 2,
        mb: 2,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Box sx={{ m: "auto 0" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            borderBottom: "1px solid rgba(0, 0, 0, 0.18)",
            mb: 1.5,
          }}
        >
          <Typography>Tạm Tính: </Typography>
          <Box
            sx={{
              minWidth: 200,
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography component="div">
              <Typography
                sx={{
                  display: "inline-flex",
                  textDecoration: "underline",
                }}
              >
                đ
              </Typography>
              {format_numeral_price(amount)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            borderBottom: "1px solid rgba(0, 0, 0, 0.18)",
            mb: 1.5,
          }}
        >
          <Typography sx={{ fontSize: 14, color: "#777680" }}>
            @Thuế:{" "}
          </Typography>
          <Box
            sx={{
              minWidth: 200,
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography component="div" sx={{ color: "#777680" }}>
              <Typography
                sx={{
                  fontSize: 14,
                  display: "inline-flex",
                  color: "#777680",
                  textDecoration: "underline",
                }}
              >
                đ
              </Typography>
              {format_numeral_price(amount * 0.1)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            borderBottom: "1px solid rgba(0, 0, 0, 0.18)",
          }}
        >
          <Typography sx={{ fontSize: 18 }}>Thành Tiền: </Typography>
          <Box
            sx={{
              minWidth: 200,
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              component="div"
              sx={{
                fontSize: 18,
              }}
            >
              <Typography
                sx={{
                  display: "inline-flex",
                  textDecoration: "underline",
                }}
              >
                đ
              </Typography>
              {format_numeral_price(amount + amount * 0.1)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;
