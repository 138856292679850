import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ServiceListIcon from "@mui/icons-material/FormatListBulletedOutlined";
import React from "react";
import ItemExportMuiScrollTabs from "../ItemExportMuiScrollTabs";
const WarehouseItemExportedMainTabs = (props) => {
  const handleChangeTab = (value) => {
    props.onChangeTab(value);
    if (props.onResetPage) props.onResetPage();//must be callback
  }
  const initialTabs = [
    {
      value: "devices",
      label: "Thiết bị",
      icon: ServiceListIcon,
    },
    {
      value: "accessories",
      label: "Linh Kiện",
      icon: SettingsSuggestIcon,
    }
  ];
  return (
    <ItemExportMuiScrollTabs
      activeTab = {props.activeTab}
      setActiveTab = {handleChangeTab}
      initialTabs={initialTabs?.filter((tab) => !tab?.hidden)}
    />
  );
};

export default WarehouseItemExportedMainTabs;
