import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import ReactJson from "react-json-view";
import React, { useEffect } from "react";
import ThresholdApi from "@/apis/beta/Threshold/ThresholdApi";
import EditElastic from "@features/Module/Technical/Dialog/Elastic";
import DomainTab from "./Domains";

const ElasticInfo = ({
    data_threshold , 
    setHaveElastic = () => {}, 
    setAutoCompleteValue,
    autoCompleteValue
}) => {
    const [showDialogEditElastic, setShowDialogEditElastic] = React.useState(false);
    const {data, refetch} = ThresholdApi.GetElastic(data_threshold?.threshold_id);
    const isJSON = (string) => {
        if (typeof string !== "string") return false;
        try {
        JSON.parse(string);
        return true;
        } catch (error) {
        return false;
        }
    };

    const renderTextValue = (obj) => {
        return isJSON(obj) ? (
        <ReactJson collapsed src={JSON.parse(obj)} />
        ) : typeof obj === "object" ? (
        <ReactJson collapsed src={obj} />
        ) : (
        obj
        );
    };
    useEffect(() => {
        if (data?.elastic) {
            setHaveElastic({
                ...data?.elastic
            })   
        }
    }, [data?.elastic])
    return (
        <>
            <Box
                sx={{ mb: 1 }}
                onClick={() => {
                if (!data?.elastic) setHaveElastic(null);
                setShowDialogEditElastic(true);
                }}
                display="flex"
                alignItems="center"
            >
                <Typography>Elastic</Typography>
                <Chip
                color="primary"
                sx={{ ml: 2, cursor: "pointer" }}
                label= {`${!data?.elastic ? "Liên kết" : "Cập nhật"} Elastic`}
                />
            </Box>
            {data?.elastic && 
            <>
                <Paper sx={{ p: 3, mb: 1 }}>
                    <Grid container spacing={2}>
                        {Object?.keys(data?.elastic)
                        ?.filter((item) => data?.elastic?.[item])
                        ?.map((item) => (
                            <Grid key={item} item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <Typography sx={{ mb: 0.5, color: "#777680" }}>
                                {item
                                ?.split("_")
                                ?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))
                                .join(" ")}
                                :
                            </Typography>
                            <Typography sx={{ color: "#00000F" }}>
                                {typeof(data?.elastic?.[item]) !== "object" ? data?.elastic?.[item] : renderTextValue(data?.elastic?.[item])}
                            </Typography>
                            </Grid>
                        ))}
                    </Grid>   
                </Paper>
                {data?.elastic && <DomainTab threshold_elastic_id = {data?.elastic?.threshold_elastic_id} elastic={data?.elastic}/>}
            </>
            }
            <EditElastic
                    elastic={
                        data?.elastic
                        }
                    threshold={
                        data_threshold
                    }
                    refetch={refetch}
                    autoCompleteValue = {
                        autoCompleteValue
                    }
                    setAutoCompleteValue = {
                        setAutoCompleteValue
                    }
                    open={showDialogEditElastic}
                    handleClose={() => {
                        setShowDialogEditElastic(false);
                    }}
                />
        </>
       
    );
};

export default ElasticInfo;
