import WarehouseBoxItemApi from "@/apis/beta/Warehouse/WarehouseBoxItemApi";
import WarehouseBoxLogApi from "@/apis/beta/Warehouse/WarehouseBoxLogApi";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseOwnerApi from "@/apis/beta/Warehouse/WarehouseOwnerApi";
import {
  WAREHOUSE_ITEM_STOCK_STATUS,
  WAREHOUSE_STATUS_BUY,
} from "@/libs/constants";
import { MenuProps } from "@/styles/common";
import CellConfig from "@components/DataGrid/CellConfig2";
import CellSpecification from "@components/DataGrid/CellSpecification";
import DataGridProCustomize from "@components/DataGridProCustomize";
import MuiCurrency from "@components/shared/CurrencyInputCustomize";
import { useEmployeeContext } from "@contexts/employee";
import { useNotificationContext } from "@contexts/notification";
import PaperLoading from "@features/Module/WarehouseNew/Shared/Loading/PaperLoading";
import { Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { NavLink } from "react-router-dom";

const FormWarehouseItem = ({ warehouseBoxItem, wbRack }) => {
  const {
    data: { warehouse_item },
    refetch,
    isLoading,
  } = WarehouseItemApi.Show(warehouseBoxItem?.warehouse_item_id);
  const { employee } = useEmployeeContext();
  const { data: warehouse_owners } = WarehouseOwnerApi.getAll();
  const [prevOwner, setPrevOwner] = React.useState(warehouse_item?.warehouse_owner_name);
  const { setNotification } = useNotificationContext();
  const reFormatData = () => {
    return warehouse_item?.children.map((o, index) => ({ ...o, id: index }));
  };

  const columns = [
    {
      field: "warehouse_type",
      headerName: "Loại",
      valueGetter: ({ row, value }) =>
        `${row?.warehouse_type?.warehouse_type_name}`,
      width: 120,
    },
    {
      field: "warehouse_item_name",
      headerName: "Model",
      valueGetter: ({ row, value }) => `${value}`,
      width: 300,
    },
    {
      field: "total",
      headerName: "Số lượng",
      valueGetter: ({ value }) => (value ? value : 1),
      width: 150,
    },
  ];

  const [values, setValue] = React.useState(
    warehouse_item.warehouse_resource_services.map(
      (o) => o.warehouse_resource_service_name
    )
  );

  const [ips, setIps] = React.useState(
    warehouse_item?.warehouse_box_item?.warehouse_box_item_metadata_ips || []
  );

  const createLog = async (description, type, metadata) => {
    const data = {
      warehouse_box_name: wbRack?.warehouse_box?.warehouse_box_name,
      warehouse_box_id: wbRack?.warehouse_box?.warehouse_box_id,
      warehouse_box_update_type: type,
      metadata: metadata,
      warehouse_box_updater_id: employee?.employee_id,
      warehouse_box_updater_name: employee?.employee_name,
      warehouse_box_update_description: description,
    };
    await WarehouseBoxLogApi.createBoxLog(data);
  }
  const handleChange = async (value) => {
    setValue(value);
    await WarehouseBoxItemApi.Update(
      warehouse_item?.warehouse_box_item?.warehouse_box_item_id,
      { warehouse_box_item_services: value }
    ).then((response) => {
      const data = response.data;
      if (data.status !== 200) {
        setNotification({
          open: true,
          message: data.errors[0].message,
          severity: "error",
        });
        return;
      }
      // createLog("Đổi thuộc tiêm", "thay đổi", value);
      setNotification({
        open: true,
        message: "Cập nhật thành công.",
        severity: "success",
      });
      refetch();
    });
  };

  const handleChangeIp = async (value) => {
    setIps(value);
    await WarehouseBoxItemApi.Update(
      warehouse_item?.warehouse_box_item?.warehouse_box_item_id,
      { warehouse_box_item_metadata_ips: value }
    ).then((response) => {
      const data = response.data;
      if (data.status !== 200) {
        setNotification({
          open: true,
          message: data.errors[0].message,
          severity: "error",
        });
        return;
      }
      createLog("Đổi IP", "UPDATERACK-IP", {
        dataChange: {
          previous: {
            warehouse_box_item_metadata_ips: warehouse_item.warehouse_box_item.warehouse_box_item_metadata_ips
          },
          new: {
            warehouse_box_item_metadata_ips: value
          }
        },
        // data:{
        //   warehouse_item
        // }
      });
      setNotification({
        open: true,
        message: "Cập nhật thành công.",
        severity: "success",
      });
      refetch();
    });
  };

  const onChangeStatus = async (value) => {
    await WarehouseItemApi.Update(warehouseBoxItem?.warehouse_item_id, {
      warehouse_item_availability: value,
    }).then((response) => {
      const data = response.data;
      if (data.status !== 200) {
        setNotification({
          open: true,
          message: data.errors[0].message,
          severity: "error",
        });
        return;
      }
      createLog("Đổi trạng thái thiết bị", "UPDATERACK-STATUS", {
        dataChange: {
          previous: {
            warehouse_item_availability: warehouse_item.warehouse_item_availability
          },
          new: {
            warehouse_item_availability: value
          }
        },
        // data:{
        //   warehouse_item
        // }
      });
      setNotification({
        open: true,
        message: "Cập nhật thành công.",
        severity: "success",
      });
      refetch();
    });
  };
  const info = [
    { value: "warehouse_box_item_uplinks", label: "UPLINK" },
    { value: "warehouse_box_item_service_deploy", label: "DEPLOY" },
    { value: "warehouse_box_item_hostname", label: "HOSTNAME" },
    { value: "warehouse_box_item_description", label: "DESCRIPTION" },
  ]

  const changeUpdate = async (param) => {
    await WarehouseBoxItemApi.Update(
      warehouse_item?.warehouse_box_item?.warehouse_box_item_id,
      param
    ).then((response) => {
      const data = response.data;
      if (data.status !== 200) {
        setNotification({
          open: true,
          message: data.errors[0].message,
          severity: "error",
        });
        return;
      }
      createLog(`Cập nhật rack ${info.find(o => o.value === Object.keys(param)[0])?.label.toLowerCase()}`, `UPDATERACK-${info.find(o => o.value === Object.keys(param)[0])?.label}`, {
        dataChange: {
          previous: {
            warehouse_item
          },
          new: {
            param
          }
        },
        // data:{
        //   warehouse_item
        // }
      });
      setNotification({
        open: true,
        message: "Cập nhật thành công.",
        severity: "success",
      });
      refetch();
    });
  };
  const onChangeOwner = async (value) => {
    await WarehouseItemApi.Update(warehouseBoxItem?.warehouse_item_id, {
      warehouse_owner_name: value,
    }).then((response) => {
      const data = response.data;
      if (data.status !== 200) {
        setNotification({
          open: true,
          message: data.errors[0].message,
          severity: "error",
        });
        return;
      }
      refetch();
      createLog("Cập nhật sở hữu", "UPDATERACK-OWNER", {
        dataChange: {
          newData: {
            warehouse_box_item_reference_id: warehouseBoxItem.warehouse_box_item_reference_id,
            warehouse_item_name: warehouse_item.warehouse_item_name,
            warehouse_item_id: warehouse_item.warehouse_item_id,
            warehouse_owner_name: value
          },
          prevData: {
            warehouse_box_item_reference_id: warehouseBoxItem.warehouse_box_item_reference_id,
            warehouse_item_name: warehouse_item.warehouse_item_name,
            warehouse_item_id: warehouse_item.warehouse_item_id,
            warehouse_owner_name: prevOwner
          }
        },
      });
      setPrevOwner(value);
      setNotification({
        open: true,
        message: "Cập nhật thành công.",
        severity: "success",
      });
    });
  };

  if (isLoading) return <PaperLoading />;

  return (
    <>
      {warehouse_item && (
        <Paper>
          <Box sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ color: "#777680" }}>
                    Tên Thết Bị:
                  </Typography>
                  <Link
                    to={`/warehouse/items/${warehouse_item.warehouse_item_id}/detail`}
                    component={NavLink}
                  >
                    <Typography
                      className="react-link"
                      sx={{ color: "#00000F" }}
                    >
                      {warehouse_item?.warehouse_item_name}
                    </Typography>
                  </Link>
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ color: "#777680" }}>
                    Serial Number/ Service Tag:
                  </Typography>
                  {/* <Link  to={`/warehouse/items/${warehouse_item.warehouse_item_id}/detail`} component={NavLink}> */}
                  <Typography className="react-link">
                    {warehouse_item?.warehouse_item_service_tag}
                  </Typography>
                  {/* </Link> */}
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ color: "#777680" }}>
                    Form Factor (U):
                  </Typography>
                  <Typography sx={{ color: "#00000F" }}>
                    {warehouse_item?.warehouse_item_form_factor}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ color: "#777680" }}>
                    Trạng Thái Mua:
                  </Typography>
                  <Typography sx={{ color: "#00000F" }}>
                    {
                      _.find(WAREHOUSE_STATUS_BUY, {
                        value: parseInt(warehouse_item.warehouse_item_status),
                      })?.label
                    }
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ color: "#777680" }}>Capacity:</Typography>
                  {warehouse_item?.capacity && (
                    <CellConfig configures={warehouse_item?.capacity} />
                  )}
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ color: "#777680" }}>Tủ Rack:</Typography>
                  {warehouse_item?.warehouse_rack?.warehouse_rack_name}
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ color: "#777680" }}>Vị Trí:</Typography>
                  {warehouse_item?.warehouse_box_item
                    ?.warehouse_box_item_position +
                    (warehouse_item.warehouse_item_form_factor === 1
                      ? ""
                      : ` - ${warehouse_item?.warehouse_box_item
                        ?.warehouse_box_item_position +
                      (warehouse_item.warehouse_item_form_factor - 1)
                      }`)}
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                {warehouse_item?.warehouse_model.slots.length > 0 && (
                  <Box>
                    <Typography sx={{ color: "#777680" }}>Slots:</Typography>
                    <CellSpecification
                      items={warehouse_item?.warehouse_model.slots.map((o) => ({
                        label: `${o.slot_name} ${o.slot_max_capacity && o.slot_max_capacity !== 0
                          ? `- Max Capacity ${o.slot_max_capacity}`
                          : ""
                          } `,
                        value: o.slot_number,
                      }))}
                    />
                  </Box>
                )}
              </Grid>
              {warehouse_item?.children.length > 0 && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <DataGridProCustomize
                    rows={reFormatData()}
                    columns={columns}
                    disabled={["Pagination", "Toolbar"]}
                  />
                </Grid>
              )}

              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ color: "#777680", mb: 0.5 }}>
                    Cụm Đầu Tư:
                  </Typography>
                  <Autocomplete
                    multiple
                    size={"small"}
                    options={warehouse_item.warehouse_resource_services.map(
                      (o) => o.warehouse_resource_service_code
                    )}
                    value={warehouse_item.warehouse_resource_services.map(
                      (o) => o.warehouse_resource_service_code
                    )}
                    disabled
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          size="small"
                          variant="outlined"
                          key={index}
                          value={option}
                          deleteIcon={
                            <Delete
                              fontSize="small"
                              sx={{ height: 14, color: "#EF5350 !important" }}
                            />
                          }
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder="Cụm Đầu Tư Đang Chạy..."
                          sx={{ bgcolor: "#FFF" }}
                        />
                      );
                    }}
                    sx={{ maxWidth: "100%", flexWrap: "wrap" }}
                  />
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ color: "#777680", mb: 0.5 }}>
                    Dịch Vụ:
                  </Typography>
                  <Autocomplete
                    multiple
                    size={"small"}
                    id="tags-filled"
                    options={warehouse_item.warehouse_resource_services.map(
                      (o) => o.warehouse_resource_service_name
                    )}
                    limitTags={100}
                    disabled
                    freeSolo
                    filterSelectedOptions
                    onChange={(e, newValue, reason) => {
                      const newTags = _.difference(newValue, values)[0];
                      if (reason === "selectOption") {
                        return handleChange(newValue);
                      }
                      if (reason === "removeOption") {
                        return handleChange(newValue);
                      }
                      const tags = _.difference(newTags.split(/,|\n| /), "");
                      handleChange([...values, ...tags]);
                    }}
                    value={values}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          size="small"
                          variant="outlined"
                          key={index}
                          value={option}
                          deleteIcon={
                            <Delete
                              fontSize="small"
                              sx={{ height: 14, color: "#EF5350 !important" }}
                            />
                          }
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder="Dịch Vụ Đang Chạy..."
                          sx={{ bgcolor: "#FFF" }}
                        />
                      );
                    }}
                    sx={{ maxWidth: "100%", flexWrap: "wrap" }}
                  />
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ color: "#777680", mb: 0.5 }}>
                    IPs:
                  </Typography>
                  <Autocomplete
                    multiple
                    size={"small"}
                    id="tags-filled"
                    options={[]}
                    limitTags={100}
                    freeSolo
                    filterSelectedOptions
                    onChange={(e, newValue, reason) => {
                      if (reason === "removeOption") {
                        return handleChangeIp(newValue);
                      }
                      const newTags = _.difference(newValue, ips)[0];
                      const tags = _.difference(newTags.split(/,|\n|\t| /), "");
                      handleChangeIp([...ips, ...tags]);
                    }}
                    value={ips}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          size="small"
                          variant="outlined"
                          key={index}
                          value={option}
                          deleteIcon={
                            <Delete
                              fontSize="small"
                              sx={{ height: 14, color: "#EF5350 !important" }}
                            />
                          }
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder="Nhập IP..."
                          sx={{ bgcolor: "#FFF" }}
                        />
                      );
                    }}
                    sx={{ maxWidth: "100%", flexWrap: "wrap" }}
                  />
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ color: "#777680" }}>
                    Trạng Thái Thiết Bị:
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      MenuProps={MenuProps}
                      size="small"
                      value={warehouse_item?.warehouse_item_availability}
                      onChange={(event) => {
                        onChangeStatus(event?.target?.value);
                      }}
                    >
                      {WAREHOUSE_ITEM_STOCK_STATUS?.map((item) => {
                        return (
                          <MenuItem
                            sx={{ fontSize: 14, height: 36 }}
                            value={item?.value === "true" ? true : false}
                          >
                            {item?.value === "true" ? "Active" : "UnActive"}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ color: "#777680" }}>Sở Hữu:</Typography>
                  <Select
                    size="small"
                    defaultValue={
                      warehouse_owners?.find(
                        (item) =>
                          item?.warehouse_owner_id ===
                          warehouse_item?.warehouse_owner_id
                      )?.value || ""
                    }
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      onChangeOwner(e?.target?.value);
                    }}
                  >
                    {warehouse_owners?.map((item) => {
                      return (
                        <MenuItem
                          sx={{ fontSize: 14, height: 36 }}
                          value={item?.value}
                          key={item?.warehouse_owner_id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </Grid>

              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ mb: 0.5, color: "#777680" }}>
                    Uplink (Gb/s)
                  </Typography>
                  <FormControl fullWidth>
                    <MuiCurrency
                      defaultValue={
                        warehouse_item?.warehouse_box_item
                          ?.warehouse_box_item_uplinks || 0
                      }
                      onChange={() => { }}
                      onBlur={(e) => {
                        changeUpdate({
                          warehouse_box_item_uplinks:
                            e?.target?.value?.split(",")?.join("") || 0,
                        });
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ mb: 0.5, color: "#777680" }}>
                    Dịch Vụ Deploy
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    minRows={3}
                    defaultValue={
                      warehouse_item?.warehouse_box_item
                        ?.warehouse_box_item_service_deploy
                    }
                    placeholder="Nhập dịch vụ Deploy ..."
                    onBlur={(e) => {
                      if (
                        e.target?.value !==
                        warehouse_item?.warehouse_box_item
                          ?.warehouse_box_item_service_deploy
                      ) {
                        changeUpdate({
                          warehouse_box_item_service_deploy: e.target.value,
                        });
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Box>
                  <Typography sx={{ mb: 0.5, color: "#777680" }}>
                    Note Rack/ Host name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    minRows={3}
                    defaultValue={
                      warehouse_item?.warehouse_box_item
                        ?.warehouse_box_item_hostname
                    }
                    placeholder="Nhập  Note Rack/ Host name ..."
                    onBlur={(e) => {
                      if (
                        e?.target?.value !==
                        warehouse_item?.warehouse_box_item
                          ?.warehouse_box_item_hostname
                      ) {
                        changeUpdate({
                          warehouse_box_item_hostname: e.target.value,
                        });
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography sx={{ mb: 0.5, color: "#777680" }}>
                    Mô Tả/ Ghi chú{" "}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    minRows={3}
                    defaultValue={
                      warehouse_item?.warehouse_box_item
                        ?.warehouse_box_item_description
                    }
                    placeholder="Nhập thông tin ghi chú..."
                    onBlur={(e) => {
                      if (
                        e?.target?.value !==
                        warehouse_item?.warehouse_box_item
                          ?.warehouse_box_item_description
                      )
                        changeUpdate({
                          warehouse_box_item_description: e.target.value,
                        });
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
    </>
  );
};
export default FormWarehouseItem;
