import TicketStatusApi from '@/apis/beta/Ticket/TicketStatusApi';
import withSuspense from '@/hocs/withSuspense';
import ErrorHelperText from '@components/shared/ErrorHelperText';
import MuiSkeleton from '@components/shared/MuiSkeleton';
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize';
import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
const TicketStatus = ({ ticket }) => {
    const { control, formState: { errors } } = useFormContext()
    const { data: ticket_statuses } = TicketStatusApi.ListTicketStatus();

    return (
        <Controller
            name="ticket_status_id"
            rules={{ required: "This field is required" }}
            control={control}
            render={({ field: { onChange, value } }) => {
                const selected =
                    ticket_statuses?.find(
                        (x) => x?.ticket_status_id === value
                    ) || null;

                return (
                    <FormControl fullWidth>
                        <SingleSelectCustomize
                            input={{
                                label: 'Trạng thái Ticket *',
                                placeholder: 'Chọn trạng thái ticket...'
                            }}
                            onChange={(_, newValue) =>
                                onChange(newValue?.ticket_status_id || null)
                            }
                            value={selected}
                            formatCollection={{
                                label: "ticket_status_title",
                                value: "ticket_status_id",
                            }}
                            compareAttributes={["ticket_status_title"]}
                            options={ticket_statuses?.filter((item) => {
                                if (!ticket) {
                                    return item;
                                }

                                const status = ticket_statuses?.find(
                                    (x) => x?.ticket_status_id === value
                                );

                                return status?.ticket_status_next_accept?.includes(
                                    item?.ticket_status_id
                                );
                            })}
                        />

                        {errors?.ticket_status_id && (
                            <ErrorHelperText
                                message={errors?.ticket_status_id?.message}
                            />
                        )}
                    </FormControl>
                );
            }}
        />
    )
}

export default withSuspense(TicketStatus, MuiSkeleton["TextField"])