import { Grid, Typography } from '@mui/material'
import React from 'react'

const CustomerName = ({ customer_information }) => {
    return (
        <Grid container spacing={2} >
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                    Họ & Tên
                </Typography>
            </Grid>
            <Grid item xl={9} lg={9} md={8} sm={6} xs={12} >
                <Typography sx={{ textTransform: "uppercase" }}>
                    {customer_information?.name}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default CustomerName