import InvoiceApi from "@/apis/beta/Invoice/InvoiceApi";
import InvoiceNoteApi from "@/apis/beta/Invoice/InvoiceNoteApi";
import DataGrid from "@/components/DataGrid";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";

export const INVOICE_ACTIONS = [
  { value: "sent-mailer", label: "Gửi Email" },
  { value: "payment-invoice", label: "Thanh Toán Hoá Đơn" },
  { value: "invoice-update", label: "Cập Nhật Hoá Đơn" },
  { value: "add-note", label: "Thêm Ghi Chú" },
];

export const INVOICE_MAILER_STATUS_TYPES = [
  { value: "pending", label: "Chưa gửi", chip: "pending" },
  { value: "completed", label: "Đã gửi", chip: "success" },
];
export const INVOICE_UPDATE_TYPES = [
  { value: "ITEM-UPDATE", label: "Chỉnh sửa invoice item" },
  { value: "ADDON-CREATED", label: "Tạo hoá đơn dịch vụ addon" },
  { value: "INVOICE-PAIDBILL", label: "Thanh toán hoá đơn" },
  { value: "INVOICE-PAIDBILL-USING-CREDIT", label: "Thanh toán hoá đơn bằng Credit" },
  { value: 'INVOICE-CANCELLED', label: 'Huỷ hoá đơn' },
  { value: 'INVOICE-UPDATE', label: 'Cập nhật hoá đơn' },
  { value: "SERVICE-RENEW", label: "Tạo hoá đơn gia hạn", },
  { value: 'ADD-ITEM', label: 'Thêm dòng trong hoá đơn' },
  { value: "ITEM-DELETE", label: "Xoá dòng trong hoá đơn" },
  { value: "UPDATE-ITEM", label: "Chỉnh sửa dòng trong hoá đơn" },
  { value: "MANUAL-GENERATE-STATEMENT", label: "Manual tạo hoá đơn đối soát" },
];

export const INVOICE_MAILER_REFERRER_TYPES = [
  { value: "StatementInvoice", label: "Đối Soát", chip: "warning" },
  { value: "PaymentInvoice", label: "Hoá Đơn", chip: "success" },
];
const InvoiceUpdates = ({ invoiceId }) => {
  const { data: mailers } = InvoiceApi.InvoiceMailers({
    invoiceId,
    page: 1,
    pageSize: 999,
    query: null,
  });
  const { data: notes } = InvoiceNoteApi.List({
    invoiceId,
  });
  const { data: updates } = InvoiceApi.InvoiceUpdates({ invoiceId });

  const { data: transactions } = InvoiceApi.Transactions({ invoiceId });

  const rows = useMemo(() => {
    return _.sortBy(
      [
        ...mailers?.tasks?.map((mailer) => ({
          ...mailer,
          todo: "sent-mailer",
          metadata: mailer?.metadata?.subject,
          time: mailer?.task_created_at,
          executor: mailer?.metadata?.sender?.employee_name || "",
        })),
        ...notes?.map((note) => ({
          ...note,
          todo: "add-note",
          time: note?.created_at,
          metadata: note?.note_content,
          executor: note?.employee?.employee_name,
        })),
        ...transactions?.map((transaction) => ({
          ...transaction,
          todo: "payment-invoice",
          time: transaction?.transaction_created_at,
          metadata: transaction?.transaction_reference,
          executor: transaction?.metadata?.employee_name,
        })),
        ...updates?.map((updated) => ({
          ...updated,
          todo: "invoice-update",
          metadata: [
            INVOICE_UPDATE_TYPES?.find(
              (x) => x?.value === updated?.invoice_update_type
            )?.label,
            updated?.invoice_update_description,
          ].join(""),
          time: updated?.invoice_update_created_at,
          executor: updated?.metadata?.executor,
        })),
      ],
      ["time"]
    )?.reverse();
  }, [notes, mailers, updates, transactions]);

  const [columns] = useState([
    {
      field: "todo",
      headerName: "Thao Tác",
      valueGetter: ({ value }) =>
        INVOICE_ACTIONS?.find((x) => x.value === value)?.label,
      width: 150,
    },
    {
      field: "executor",
      headerName: "Người Thực Hiện",
      width: 150,
    },
    {
      field: "metadata",
      headerName: "Mô Tả",
      width: 500,
    },
    {
      field: "time",
      headerName: "Thời Gian Thực Hiện",
      width: 150,
      renderCell: ({ value }) =>
        value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "---",
    },
  ]);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: "medium", mb: 0.5 }}
      >
        Lịch Sử Thao Tác
      </Typography>
      <DataGrid
        columns={[...columns]}
        rows={rows?.map((item, index) => ({ ...item, id: index }))}
        components={{
          Toolbar: "disabled",
          Pagination: "disabled",
        }}
        paginationMode="client"
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(InvoiceUpdates, {
    feature: "invoice",
    action: "mailers",
    type: "Table",
  }),
  MuiSkeleton["DataGrid"]
);
