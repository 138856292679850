import { SearchOutlined } from '@mui/icons-material';
import { Box, TextField } from '@mui/material';
import React from 'react';
import ButtonMergeInvoice from "../Merge";

const Toolbar = ({
    handleClick,
    disabled,
    searchQuery,
    setSearchQuery
}) => {

    return (
        <Box sx={{
            minHeight: { xs: 'auto', md: 76 },
            maxWidth: '100%',
            mt: { xs: 2, md: 0 },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1.5,
            justifyContent: 'space-between'
        }}>
            <ButtonMergeInvoice
                disabled={disabled}
                handleClick={handleClick}
            />
            <TextField
                size="small"
                InputProps={{
                    startAdornment: <SearchOutlined sx={{ color: '#bfbfbf', mr: 1 }} />,
                }}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event?.target?.value)}
                sx={{ m: 'auto 16px', width: { xs: 'calc(100% - 32px)', md: 300 } }}
                placeholder='Nhập ID hoá đơn để tìm kiếm...'
            />
        </Box>
    )
}

export default Toolbar