import DataGridProCustomize from "@components/DataGridProCustomize";
import React, { useState } from "react";
import _ from "lodash";
import ClusterBandwidthApi from "@/apis/beta/Warehouse/ClusterBandwidthApi";
import { Link, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import Cell from "@components/DataGrid/Cell";

const TableDefault = () => {
    const [page, setPage] = useState({
        page: 0,
        pageSize: 20
    });

    const { data } = ClusterBandwidthApi.index({
        page: page?.page + 1 || 1,
        pageSize: page?.pageSize || 20,
        query: "",
        searchQuery: ""
    })

    const [colunms] = useState([
        {
            field: 'name',
            headerName: 'Tháng',
            renderCell: ({ value, row }) => {
                if (row.group.length === 1) {
                    return (
                        <Link component={NavLink} to={`/warehouse/bandwidths/clusters/${row.id}`} >
                            <Typography className='react-link-table'>
                                {value}
                            </Typography>
                        </Link>
                    )
                } else {
                    <Cell value={`${value}`} />
                }
            },
            sortable: false,
            width: 150,
        },
        {
            field: 'cluster',
            headerName: 'Cụm',
            sortable: false,
            width: 200,
        },
        {
            field: 'bw_limit',
            headerName: 'BW Limit (Gbps)',
            sortable: false,
            width: 200,
        },
        {
            field: 'bw_uplink',
            headerName: 'BW Uplink (Switch)',
            sortable: false,
            width: 200,
        },
        {
            field: 'bw_max',
            headerName: ' Max Bandwidth (Gbps)',
            sortable: false,
            width: 250,
        },
        {
            field: 'volume',
            headerName: 'Customers Volume(GB)',
            sortable: false,
            width: 250,
        },
    ])
    const reFormatData = React.useCallback(() => {
        let results = [];

        _.forEach(data?.cluster_bandwidths, (o, index) => {

            let clusters = []
            let bw_limit = 0
            let bw_uplink = 0
            let bw_maxs = []
            let volume = 0

            _.forEach(o.cluster_bandwidth_items, (i, index_i) => {
                bw_limit = bw_limit + i.cluster_bandwidth_item_bw_limit
                bw_uplink = bw_uplink + i.cluster_bandwidth_item_bw_uplink
                clusters.push(i.cluster_bandwidth_item_name)
                bw_maxs.push(i.cluster_bandwidth_item_max)
                volume = volume + i.cluster_bandwidth_item_customer_volume
                results.push({
                    id: `${o.cluster_bandwidth_id}.${index_i + 1}`,
                    group: [o.cluster_bandwidth_id, index_i + 1],
                    name: "",
                    cluster: `${i.cluster_bandwidth_item_name}`,
                    bw_limit: i.cluster_bandwidth_item_bw_limit,
                    bw_uplink: i.cluster_bandwidth_item_bw_uplink,
                    bw_max: i.cluster_bandwidth_item_max,
                    volume: i.cluster_bandwidth_item_customer_volume
                })
            })

            results.push({
                id: o.cluster_bandwidth_id,
                group: [o.cluster_bandwidth_id],
                name: `${o.cluster_bandwidth_name}`,
                cluster: clusters.join(" , "),
                bw_limit: bw_limit,
                bw_uplink: bw_uplink,
                bw_max: _.max(bw_maxs),
                volume: volume,
            })
        })


        return results
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.cluster_bandwidths, page])

    const onPaginationModelChange = (model) => {
        setPage(model)
    }


    return (
        <DataGridProCustomize
            rows={reFormatData()}
            columns={colunms}
            paginationMode="server"
            pagination
            treeData
            defaultGroupingExpansionDepth={0}
            initialState={{
                pagination: { paginationModel: { pageSize: page?.pageSize } },
            }}
            pageSize={page?.pageSize || 20}
            pageSizeOptions={[10, 20, 50, 100]}
            getTreeDataPath={(row) => row?.group}
            onPaginationModelChange={onPaginationModelChange}
            page={page?.page || 0}
            rowCount={data?.pagination?.total}
        />
    )
}
export default TableDefault;