import DatePicker from "@/components/DatePicker";
import { API_REPORT_DYNAMIC } from '@/libs/constants';
import {
  Typography,
} from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import * as React from 'react';
export default function DialogDateTimePicker(props) {
  const [date, setDate] = React.useState({
    date_start: moment(new Date()).format("YYYY-MM-DD"),
    date_end: moment(new Date()).format("YYYY-MM-DD")
  });
  // const timeLimit = 30*24*60*60*1000; //limit 30 days
  const time = 24 * 60 * 60 * 1000;
  const handleClose = () => {
    props.handleClose();
  };
  const openViewNewTab = (url) => {
    window.open(url, '_blank');
  }
  const handleAccept = () => {
    const url = `${API_REPORT_DYNAMIC}/${props.watch_uuid}?gte=${date.date_start}T00:00:00Z&lte=${date.date_end}T23:59:30Z`
    openViewNewTab(url);
  }
  const checkDate = (date) => {
    const datePick = new Date(date);
    const today = new Date();
    if (((datePick.getTime() - today.getTime()) / time) >= -30) {
      return false;
    }
    return true; // true if it last 30 days
  }
  const handleChangeDateStart = (newValue) => {
    if (!checkDate(newValue)) {
      setDate({
        ...date,
        date_start: newValue
      })
    } else {
      props.setNotification({
        open: true,
        message: "Khoảng thời gian không quá 30 ngày tính từ hôm nay",
        severity: "error",
      })
    }
  }
  const handChangeDateEnd = (newValue) => {
    if (!checkDate(newValue)) {
      if (new Date(date.date_start).getTime() < new Date(newValue).getTime()) {
        setDate({
          ...date,
          date_end: newValue
        })
      } else {
        props.setNotification({
          open: true,
          message: "Khoảng thời gian sau phải lớn hơn khoảng thời gian trước",
          severity: "error",
        })
      }
    } else {
      props.setNotification({
        open: true,
        message: "Khoảng thời gian không quá 30 ngày tính từ hôm nay",
        severity: "error",
      })
    }
  }
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Chọn mốc thời gian {props.watch_name}
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography sx={{ mb: 0.5, fontSize: 14 }}>Từ ngày</Typography>
            <DatePicker onChange={(newValue) => handleChangeDateStart(newValue)} value={date.date_start} />
            <Typography sx={{ mb: 0.5, fontSize: 14 }}>Đến ngày</Typography>
            <DatePicker onChange={(newValue) => handChangeDateEnd(newValue)} value={date.date_end} />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={() => handleAccept()} autoFocus>
            Xem
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}