import React from "react";
import Form from "../Form";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const Create = ({refetchData , handleClose}) => {
    return (
        <>
        <Form refetchData={refetchData} handleClose={handleClose}/>
        </>
    )
}

export default withSuspense(withPermission(Create, {
    feature: "warehouse",
    action: "investment-create"
}), MuiSkeleton["DataGrid"])