import WarehouseRackApi from "@/apis/beta/Warehouse/WarehouseRackApi";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

const SelectPosition = ({ watch, control, row, racks, errors }) => {
  const rack = watch(`rack-${row?.warehouse_item_id}`);
  const position = watch(`position-${row?.warehouse_item_id}`);
  const description = watch(`description-${row?.warehouse_item_id}`);

  const findRack = racks?.warehouse_racks?.find((item) => item?.value === rack);

  const { watch: watch2, setValue } = useFormContext();

  const buildOptionPosition = async (rack) => {
    if (!rack) return [];

    const response = await WarehouseRackApi.WarehoseBoxItem(rack);

    const warehouse_box_items = response?.warehouse_box_items;

    let options = Array.from(
      Array(findRack?.warehouse_rack_height).keys()
    )?.map((item) => ({
      label: item + 1,
      value: item + 1,
      disable: row?.warehouse_item_parent ? true : false,
    }));

    if (warehouse_box_items?.length === 0) return options;

    warehouse_box_items?.forEach((item) => {
      const position = item?.warehouse_box_item_position;
      const u = item?.warehouse_item_form_factor;

      Array.from(Array(u - 1).keys())?.forEach((i) => {
        const itemDeleted = position + i + 1;
        options = options?.filter((i) => i?.value !== itemDeleted);
      });
    });

    return options?.map((item) => {
      const warehouse_box_item = warehouse_box_items?.find(
        (i) => i?.warehouse_box_item_position === item?.value
      );

      if (warehouse_box_item) {
        return {
          label: `[${warehouse_box_item?.warehouse_box_item_position} - ${warehouse_box_item?.warehouse_box_item_position +
            warehouse_box_item?.warehouse_item_form_factor -
            1
            } ] - ${warehouse_box_item?.warehouse_item_name} - ${warehouse_box_item?.warehouse_item_service_tag
            } `,
          value: item?.value,
          disable: row?.warehouse_type?.warehouse_group_id !== 1 ? false : true,
          warehouse_item_id: warehouse_box_item?.warehouse_item_id,
        };
      }

      return {
        label: item?.value,
        value: item?.value,
        disable: row?.warehouse_type?.warehouse_group_id !== 1 ? true : false,
      };
    });
  };

  const positionOptionKey = JSON.stringify({ ...rack, ...row });

  const { data, isLoading } = useQueryWithCustomized([positionOptionKey], () =>
    buildOptionPosition(rack)
  );

  useEffect(() => {
    const params = {
      warehouse_box_item_reference_id:
        findRack?.warehouse_rack_id || watch2("warehouse_process_to")?.value,
      warehouse_box_item_reference_type: findRack?.type || "WarehouseBox",
      warehouse_box_item_position: position,
      warehouse_box_item_description: description,
      warehouse_rack_name: findRack?.warehouse_rack_name,
      warehouse_box_rack_name: findRack?.warehouse_rack_name,
      warehouse_rack: findRack,
      warehouse_item_id_to:
        row?.warehouse_type?.warehouse_group_id !== 1
          ? data?.find((item) => item?.value === position)?.warehouse_item_id ||
          null
          : null,
    };

    setValue(
      "warehouse_process_metadata",
      watch2("warehouse_process_metadata")?.map((item) => {
        const warehouse_box_item = { ...item?.warehouse_box_item };

        return item?.id === row?.id
          ? {
            ...item,
            warehouse_item_box_old: {
              ...warehouse_box_item,
            },
            warehouse_box_item: {
              ...warehouse_box_item,
              ...params,
            },
          }
          : item;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, rack, description, findRack]);

  if (isLoading) return <CircularProgress size={20} />;

  return (
    <Controller
      control={control}
      name={`position-${row?.warehouse_item_id}`}
      rules={{ required: "Vui lòng chọn vị trí" }}
      render={({ field }) => (
        <FormControl fullWidth>
          {!isLoading &&
            !["SỢI"].includes(row?.warehouse_type.warehouse_type_unit) && (
              <Select {...field} fullWidth size="small">
                {data?.map((item) => (
                  <MenuItem
                    disabled={item?.disable}
                    key={item?.value}
                    value={item?.value}
                  >
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            )}

          {errors?.[`position-${row?.warehouse_item_id}`] && (
            <ErrorHelperText
              sx={{ mt: 1 }}
              message={errors?.[`position-${row?.warehouse_item_id}`]?.message}
            />
          )}
        </FormControl>
      )}
    />
  );
};

export default SelectPosition;
