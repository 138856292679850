import ProviderApi from "@/apis/beta/Provider/ProviderApi";
import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import WarehouseDepreciationApi from "@/apis/beta/Warehouse/WarehouseDepreciationApi";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import { format_date_short } from "@/functions";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDataGridParams from "@/hooks/useDataGridParams";
import { WAREHOUSE_STATUS_BUY, format_pricing_vnd } from "@/libs/constants";
import DataGridCustomize from "@components/DataGrid";
import Cell from "@components/DataGrid/Cell";
import CellConfig from "@components/DataGrid/CellConfig2";
import HeaderTitle from "@components/Title/HeaderTitle";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Lens } from "@mui/icons-material";
import { Box, Chip, Link } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import _ from "lodash";
import React from "react";
import { NavLink } from "react-router-dom";
import FormWarehouseItemDepreciate from "../../WarehouseProcesses/ProcessDetail/FormWarehouseItemDepreciate";
import DepreciationStatistical from "./DepreciationStatistical";
import Filters from "./Filters";

const Depreciations = () => {
  const { data: warehouse_models } = WarehouseModelApi.All();
  const { data: providers } = ProviderApi.getAll();
  const { data: warehouse_boxes } = WarehouseBoxApi.getAll();

  const [modal, setModal] = React.useState({ show: false });

  const initialColumns = [
    {
      field: "warehouse_item_type",
      headerName: "Loại",
      width: 120,
      valueGetter: ({ row }) =>
        `${_.find(warehouse_models, {
          warehouse_model_id: parseInt(
            row?.warehouse_model.warehouse_model_id
          ),
        })?.warehouse_type?.warehouse_type_name
        }`,
      renderCell: ({ value }) => <Cell content={value.toUpperCase()} />,
    },
    {
      field: "warehouse_item_name",
      headerName: "Tên ",
      width: 250,
      renderCell: ({ value, row }) => (
        <Link
          component={NavLink}
          to={`/warehouse/items/${row.warehouse_item_id}/detail`}
        >
          {" "}
          <Cell content={value?.toUpperCase()} />
        </Link>
      ),
    },
    {
      field: "warehouse_item_service_tag",
      headerName: "Service Tag / Serial Number",
      sortable: false,
      width: 250,
      renderCell: ({ value }) => <Cell content={value?.toUpperCase()} />,
    },
    {
      field: "warehouse_item_depreciation_date",
      headerName: "Ngày Hết Hạn Khấu Hao",
      sortable: false,
      width: 250,
      renderCell: ({ row }) => (
        <>
          <Chip
            label={
              row.depreciation && row.depreciation?.depreciation_unit > 0
                ? format_date_short(row?.depreciation?.depreciation_end)
                : "Chưa có"
            }
            color={
              row.depreciation && row.depreciation?.depreciation_unit > 0
                ? "primary"
                : "error"
            }
            onClick={() => onClickItem(row)}
          />
        </>
      ),
    },
    {
      field: "warehouse_item_depreciation_price",
      headerName: "Tiền Khấu Hao Hàng Tháng",
      sortable: false,
      width: 250,
      renderCell: ({ row }) => (
        <>
          <Chip
            label={
              row.depreciation && row.depreciation?.depreciation_unit > 0
                ? format_pricing_vnd(row?.depreciation?.depreciation_unit)
                : "Chưa có"
            }
            color={
              row.depreciation && row.depreciation?.depreciation_unit > 0
                ? "primary"
                : "error"
            }
            onClick={() => onClickItem(row)}
          />
        </>
      ),
    },
    {
      field: "warehouse_item_status",
      headerName: "Trình Trạng",
      valueGetter: ({ row }) =>
        _.find(WAREHOUSE_STATUS_BUY, {
          value: parseInt(row.warehouse_item_status),
        })?.label,
      width: 150,
    },
    {
      field: "warehouse_item_import_at",
      headerName: "Ngày Mua",
      valueGetter: ({ row }) => format_date_short(row.warehouse_item_import_at),
      width: 150,
    },
    {
      field: "warehouse_item_warranty_expired_at",
      headerName: "Ngày Hết Bảo Hành",
      valueGetter: ({ row }) => format_date_short(row.warehouse_item_import_at),
      width: 200,
    },
    {
      field: "warehouse_item_availability",
      headerName: "Có Sẳn (Stock Status)",
      renderCell: ({ value }) => (
        <Lens sx={{ m: "auto", color: value ? grey[500] : green[900] }} />
      ),
      width: 200,
    },
    {
      field: "provider_id",
      headerName: "Nhà Cung Cấp",
      valueGetter: ({ row }) =>
        _.find(providers, { provider_id: row.provider_id })?.provider_name,
      renderCell: ({ value }) => <Cell content={value?.toUpperCase()} />,
      width: 150,
    },
    {
      field: "warehouse_item_price",
      headerName: "Giá Mua",
      valueGetter: ({ row }) =>
        format_pricing_vnd(Number(row.warehouse_item_price)),
      width: 150,
    },

    {
      field: "capacity",
      headerName: "Capacity",
      width: 250,
      sortable: true,
      renderCell: ({ value }) => value && <CellConfig configures={value} />,
    },
    {
      field: "warehouse_item_parent",
      headerName: "Tên Thiết Bị",
      sortable: false,
      width: 250,
      valueGetter: ({ row }) => row?.warehouse_item_parent,
      renderCell: ({ value, row }) => (
        <>
          {value ? (
            <Link
              component={NavLink}
              to={`/warehouse/items/${value.warehouse_item_id}/detail`}
            >
              <Cell content={value.warehouse_item_name?.toUpperCase()} />
            </Link>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      field: "warehouse_box_name",
      headerName: "Kho / Datacenter",
      valueGetter: ({ row }) => {
        if (
          row.warehouse_box_item &&
          row.warehouse_box_item.warehouse_box_item_reference_type ===
          "WarehouseBox"
        ) {
          const box = _.find(warehouse_boxes, {
            warehouse_box_id:
              row.warehouse_box_item.warehouse_box_item_reference_id,
          });
          return box;
        } else if (
          row.warehouse_box_item &&
          row.warehouse_box_item.warehouse_box_item_reference_type ===
          "WarehouseRack" &&
          row.warehouse_rack
        ) {
          const box = _.find(warehouse_boxes, {
            warehouse_box_id: row.warehouse_rack?.warehouse_box_id,
          });
          return box;
        }
        return null;
      },
      renderCell: ({ value, row }) => (
        <>
          {value ? (
            value.warehouse_box_type === 0 ? (
              <>{value.warehouse_box_name}</>
            ) : (
              <Link
                component={NavLink}
                to={`/warehouse/boxes/${value.warehouse_box_id}`}
              >
                {" "}
                <Cell content={value.warehouse_box_name?.toUpperCase()} />{" "}
              </Link>
            )
          ) : (
            ""
          )}
        </>
      ),
      width: 200,
    },
    {
      field: "warehouse_box_region",
      headerName: "Khu Vực",
      valueGetter: ({ row }) => {
        if (
          row.warehouse_box_item &&
          row.warehouse_box_item.warehouse_box_item_reference_type ===
          "WarehouseBox"
        ) {
          const box = _.find(warehouse_boxes, {
            warehouse_box_id:
              row.warehouse_box_item.warehouse_box_item_reference_id,
          });
          return box?.warehouse_box_region;
        } else if (
          row.warehouse_box_item &&
          row.warehouse_box_item.warehouse_box_item_reference_type ===
          "WarehouseRack" &&
          row.warehouse_rack
        ) {
          const box = _.find(warehouse_boxes, {
            warehouse_box_id: row.warehouse_rack?.warehouse_box_id,
          });
          return box?.warehouse_box_region;
        }
        return null;
      },
      renderCell: ({ value, row }) => (
        <>{value && <Cell content={value?.toUpperCase()} />}</>
      ),
      width: 200,
    },
    {
      field: "warehouse_box_rack",
      headerName: "Tủ Rack",
      valueGetter: ({ row }) => {
        if (
          row.warehouse_box_item &&
          row.warehouse_box_item.warehouse_box_item_reference_type ===
          "WarehouseRack" &&
          row.warehouse_rack
        ) {
          return row.warehouse_rack;
        }
        return null;
      },
      renderCell: ({ value, row }) => (
        <>
          {value ? (
            <Link
              component={NavLink}
              to={`/warehouse/racks/${value.warehouse_rack_id}`}
            >
              {" "}
              <Cell content={value.warehouse_rack_name?.toUpperCase()} />{" "}
            </Link>
          ) : (
            ""
          )}
        </>
      ),
      width: 200,
    },
    {
      field: "warehouse_box_position",
      headerName: "Vị Trí",
      valueGetter: ({ row }) => {
        if (
          row.warehouse_box_item &&
          row.warehouse_box_item.warehouse_box_item_reference_type ===
          "WarehouseRack" &&
          row.warehouse_rack
        ) {
          return (
            row.warehouse_box_item?.warehouse_box_item_position +
            (row.warehouse_item_form_factor === 0 ||
              row.warehouse_item_form_factor === 1
              ? ""
              : ` - ${row.warehouse_box_item?.warehouse_box_item_position +
              (row.warehouse_item_form_factor - 1)
              }`)
          );
        }
        return "";
      },
      width: 200,
    },
  ];

  const {
    columns,
    debouncedSearchQuery,
    page,
    query,
    searchQuery,
    setColumns,
    setPage,
    setSearchQuery,
    updateQuery,
  } = useDataGridParams(initialColumns, 10);

  const { data, isFetching, refetch } = WarehouseDepreciationApi.index({
    page: page?.page + 1,
    pageSize: page?.pageSize,
    query,
    searchQuery: debouncedSearchQuery,
  });

  const onClickItem = (row) => {
    setModal({ show: true, data: row });
  };

  return (
    <Box>
      <HeaderTitle>Quản lý khấu hao</HeaderTitle>
      <Box mt={2}>
        <DepreciationStatistical
          query={query}
          searchQuery={debouncedSearchQuery}
        />

        <Filters updateQuery={updateQuery} useFilterModel />

        <DataGridCustomize
          rows={data?.warehouse_items || []}
          columns={columns}
          loading={isFetching}
          rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
          componentsProps={{
            toolbar: {
              store: "depreciations.list.hiddenColumns",
              columns: columns?.filter((col) => !col?.hideable),
              setColumns: setColumns,
              initialColumns: initialColumns,
              feature: "depreciations",
              inputSearch: {
                placeholder: "Tìm kiếm",
                value: searchQuery,
                onChange: setSearchQuery,
              },
            },
            pagination: {
              rowsPerPageOptions: [10, 20, 50, 100],
              page: page?.page || 0,
              pageSize: page?.pageSize || 20,
              onPageChange: (newPage) =>
                setPage((prevState) => ({ ...prevState, page: newPage })),
              onPageSizeChange: (newPageSize) =>
                setPage((prevState) => ({
                  ...prevState,
                  pageSize: newPageSize,
                })),
            },
          }}
          sx={{ mt: 2 }}
        />
      </Box>

      {modal.show && (
        <FormWarehouseItemDepreciate
          //   warehouse_process={warehouse_process}
          callbackData={refetch}
          modal={modal}
          setModal={setModal}
        />
      )}
    </Box>
  );
};

export default withSuspense(
  withPermission(Depreciations, {
    feature: "warehouse",
    action: "depreciations",
  }),
  MuiSkeleton["DataGrid"]
);
