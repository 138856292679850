import {
    TICKET_RECEIVE_SOURCE
} from "@/libs/constants";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const TicketSource = () => {
    const { control } = useFormContext()
    return (
        <Controller
            name="ticket_receive_source"
            control={control}
            render={({ field: { value, onChange } }) => (
                <FormControl fullWidth>
                    <SingleSelectCustomize
                        value={TICKET_RECEIVE_SOURCE?.find(x => x?.value === value) || undefined}
                        input={{
                            label: 'Nguồn nhận Ticket *',
                            placeholder: 'Chọn nguồn nhận Ticket...'
                        }}
                        options={TICKET_RECEIVE_SOURCE}
                        onChange={(_, newValue) => onChange(newValue?.value || '')}
                    />
                </FormControl>
            )}
        />
    )
}

export default TicketSource