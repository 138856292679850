import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Slider, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import DeviceTable from "./DeviceTable";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import { ErrorOutline } from "@mui/icons-material";
import _ from "lodash";
import { Filter } from "@/hooks/useFilters";
import WarehouseItemApi from "@/apis/beta/Warehouse/WarehouseItemApi";
import WarehouseResourceApi from "@/apis/beta/Warehouse/WarehouseResourceApi";
import { useNotificationContext } from "@contexts/notification";
import { useNavigate } from "react-router";

const ResourceForm = (
    {
        type = "create",
        resourceItems = [],
        setResourceItems,
        handleClose = () => { },
        resource = null,
        refetchData,
        service_tag = null
    }
) => {

    const { setNotification } = useNotificationContext();

    const { control, watch, handleSubmit, setValue, formState: { errors } } = useForm();

    const query = {
        $and: [
            { "warehouse_items.warehouse_item_form_factor": { $gt: 0 } },
            { "warehouse_box_items.warehouse_box_item_reference_type": { $eq: "WarehouseRack" } }

        ]
    }

    const { refetch: refetchWHItems } = WarehouseItemApi.Index({
        page: 1,
        pageSize: 20,
        query: Filter.stringify(query),
        search: null
    })

    const getStepSlider = (maxValue) => {
        if (maxValue >= 10000) return Math.round(maxValue / 50)
        if (maxValue >= 1000) return Math.round(maxValue / 25)
        if (maxValue >= 500) return Math.round(maxValue / 20)
        if (maxValue >= 100) return Math.round(maxValue / 20)
        return Math.round(maxValue / 5)
    }

    const warehouse_resource_type = watch("warehouse_resource_type", "dedicate")


    const [loading, setLoading] = React.useState(false)

    const { refetch: refetchDataItems } = WarehouseResourceApi.index({
        query: "",
        page: 1,
        pageSize: 10
    })

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const form = data;

        form.warehouse_resource_metadata = _.map(resourceItems, o => (
            {
                id: o.id,
                warehouse_item_id: o.warehouse_item_id,
                warehouse_item_price: o.warehouse_item_price,
                warehouse_item_name: o.warehouse_item_name,
                warehouse_item_service_tag: o.warehouse_item_service_tag,
                warehouse_box_item: _.pick(o.warehouse_box_item, ['warehouse_box_item_id', 'warehouse_box_item_position', 'warehouse_box_item_reference_id', 'warehouse_box_item_reference_type']),
                capacity: _.pick(o.capacity, ['capacity_ram', 'capacity_cpu', 'capacity_storage', 'capacity_id', 'capacity_power_supply']),
                warehouse_rack: _.pick(o.warehouse_rack, ['warehouse_rack_name', 'warehouse_box_id'])
            }
        ));

        form.warehouse_resource_active = true;

        if (type === "create") {
            await WarehouseResourceApi.create(form).then((response) => {
                const data = response.data;
                if (data.status !== 201) {
                    setNotification({ open: true, message: data.errors[0].message, severity: "error" });
                    setLoading(false)
                    return;
                }
                setLoading(false);
                refetchDataItems();
                refetchWHItems();
                setNotification({ open: true, message: "Tạo tài nguyên thành công.", severity: "success" });
                navigate(`/warehouse/resources/`);
            })
        } else {
            await WarehouseResourceApi.update(resource?.warehouse_resource_id, form).then((response) => {
                const data = response.data;
                if (data.status !== 200) {
                    setNotification({ open: true, message: data.errors[0].message, severity: "error" });
                    setLoading(false)
                    return;
                }
                setLoading(false);
                handleClose();
                refetchData();
                refetchWHItems();
                setNotification({ open: true, message: "Cập nhật tài nguyên thành công.", severity: "success" });
            })
        }
    }
    
    const [data_configures , setDataConfigures] = React.useState({ cpu: 0, ram: 0, storage: 0 })

     React.useEffect(() => {
        let configures = { cpu: 0, ram: 0, storage: 0 };
        _.forEach(resourceItems, (resourceItem) => {
            configures = {
                cpu: configures?.cpu + resourceItem?.capacity?.capacity_cpu || 0,
                ram: configures?.ram + resourceItem?.capacity?.capacity_ram || 0,
                storage: configures?.storage + resourceItem?.capacity?.capacity_storage || 0,
            }
        })
        if (type !== "create" && resourceItems === resource?.warehouse_resource_metadata) {
            const warehouse_resource_config = _.find(resource.warehouse_resource_configs, { warehouse_resource_config_type: "config" })

            configures = {
                cpu: warehouse_resource_config?.warehouse_resource_config_cpu || 0,
                ram: warehouse_resource_config?.warehouse_resource_config_ram || 0,
                storage: warehouse_resource_config?.warehouse_resource_config_storage || 0,
            }
        }
        _.map(configures, (configValue, key) => {
            setValue(`warehouse_resource_config.warehouse_resource_config_${key}`, configValue)
        })
        setDataConfigures(configures);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourceItems])

    return (
        <Box sx={{ p: '30px', mt: 2 }} >
            <React.Suspense fallback={<CircularProgress size={16} disableShrink />}>
                <DeviceTable warehouse_resource={resource || {}} key="edit-item" resourceItems={resourceItems} setResourceItems={setResourceItems} type={type} service_tag={service_tag}/>
            </React.Suspense>
            <Box sx={{ mt: 2 }} component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Typography sx={{ fontWeight: "medium", textTransform: "uppercase", mb: 1 }}>
                        {type === "create" ? "Tạo" : "Chỉnh sửa"} tài nguyên
                    </Typography>
                    <Controller
                        control={control}
                        name="warehouse_resource_type"
                        defaultValue={resource ? resource?.warehouse_resource_type : "dedicate"}

                        render={({field}) =>
                            <FormControl fullWidth sx={{ mb: 2 }} disabled={type !== "create"}>
                                <FormLabel id="radio-buttons-group-warehouse-resource-type">
                                    Loại Tài Nguyên
                                </FormLabel>
                                <RadioGroup row
                                    aria-labelledby="radio-buttons-group-warehouse-resource-type"
                                    value={field.value}
                                    defaultValue={"dedicate"}
                                    onChange={(event) => field.onChange(event?.target?.value)}
                                >
                                    <FormControlLabel
                                        value={"dedicate"}
                                        control={<Radio size='small' />}
                                        label="Dedicate"
                                    />
                                    <FormControlLabel
                                        value={"virturalization"}
                                        control={<Radio size='small' />}
                                        label="Virturalization"
                                    />
                                </RadioGroup>
                                {!!errors?.warehouse_resource_type && <ErrorHelperText message={errors?.warehouse_resource_type?.message} />}
                            </FormControl>
                        } />
                    <Typography>
                        Cấu Hình {
                            <Tooltip placement="right" title="Tổng cấu hình thiết bị">
                                <ErrorOutline color="disabled" fontSize='small' sx={{ m: 'auto 0' }} />
                            </Tooltip>}
                    </Typography>
                    <Box>
                        {_.map(data_configures, (configValue, key) => {
                            // if (configValue === 0) return null;
                            return (
                                <Controller
                                    key={key}
                                    control={control}
                                    name={`warehouse_resource_config.warehouse_resource_config_${key}`}
                                    defaultValue={configValue}
                                    render={({ field: { value, onChange }}) =>
                                        <Box sx={{ display: 'flex', mt: 2 }}>
                                            <Box sx={{
                                                display: 'flex', minWidth: 160, width: 300,
                                                m: {
                                                    xs: "4px auto auto 0",
                                                    md: "auto 0"
                                                }
                                            }}>
                                                <Typography sx={{ m: 'auto 0', minWidth: 50, textTransform: "uppercase" }}>
                                                    {key}: {value || 0} {key !== "cpu" ? "GB" : "CORE"}
                                                </Typography>
                                            </Box>
                                            <FormControl fullWidth>
                                                <Box sx={{
                                                    display: "flex", justifyContent: 'space-between',
                                                    flexDirection: {
                                                        xs: 'column', md: 'row'
                                                    }, gap: 2
                                                }}>
                                                    <Slider
                                                        value={value}
                                                        onChange={onChange}
                                                        disabled={warehouse_resource_type === 'dedicate'}
                                                        sx={{
                                                            m: 'auto 0',
                                                            maxWidth: { xs: '100%', md: '80%' },
                                                        }}
                                                        valueLabelDisplay="auto"
                                                        valueLabelFormat={(x) => `${x} GB`}
                                                        step={(configValue === 0) ? 1 : getStepSlider(configValue)}
                                                        marks
                                                        size='small'
                                                        min={0}
                                                        max={
                                                            (configValue !== 0 && warehouse_resource_type !== 'dedicate' && key === "cpu")
                                                                ? configValue * 2
                                                                : configValue}
                                                    />
                                                    <TextField
                                                        value={value}
                                                        disabled={warehouse_resource_type === 'dedicate'}
                                                        onChange={(event) => {
                                                            if (event?.target?.value > configValue && key !== "cpu") {
                                                                return onChange(configValue)
                                                            }
                                                            else if (event?.target?.value > configValue * 2 && key === "cpu") {
                                                                return onChange(configValue * 2)
                                                            }
                                                            return onChange(parseInt(event?.target?.value))
                                                        }}
                                                        label={key === "cpu" ? `${key} (CORE)` : `${key} (GB)`}
                                                        InputLabelProps={{
                                                            shrink: true, sx: {
                                                                textTransform: "uppercase"
                                                            }
                                                        }}
                                                        size="small"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 0,
                                                                max: (warehouse_resource_type !== 'dedicate' && key === "cpu") ? configValue * 2 : configValue
                                                            },
                                                            type: "number",
                                                        }}
                                                        sx={{ maxWidth: 250, width: 250 }}
                                                        fullWidth
                                                    />
                                                </Box>
                                            </FormControl>
                                        </Box>
                                    } />
                            )

                        })}

                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        {type === "edit" ? <Button variant='outlined' size='small'
                            onClick={handleClose}
                            sx={{ height: 40 }}>Huỷ Thao Tác</Button> : ""}
                        <Button
                            variant='contained'
                            size="small"
                            startIcon={loading && <CircularProgress size={14} sx={{ color: "#777680" }} />}
                            disabled={resourceItems.length !== 0 ? loading : true}
                            sx={{ height: 40, ml: 2 }}
                            type="submit"
                        >
                            {type === "create" ? " Tạo Tài Nguyên" : "Cập Nhật Tài Nguyên"}
                        </Button>
                    </Box>

            </Box>
        </Box>
    )
}
export default ResourceForm;