
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Button, CircularProgress, IconButton, Link, Paper, Tab, Tabs, Typography } from "@mui/material";
import Header from "../Header";
import { AddOutlined, MoreVertOutlined } from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import WarehouseRackApi from "@/apis/beta/Warehouse/WarehouseRackApi";
import _ from "lodash";
import { GridTreeDataGroupingCell } from "@components/DataGridPreview/DataGridPro";
import React, { useState } from "react";
import RackBandwidthApi from "@/apis/beta/Warehouse/RackBandwidthApi";
import GroupingRowDataGrid from "./Datagrid/GroupingRowDataGrid";
import GroupingColAndRowDataGrid from "./Datagrid/GroupingColAndRowDataGrid";
import RackBandwidthItemApi from "@/apis/beta/Warehouse/RackBandwidthItemApi";
import CachedIcon from '@mui/icons-material/Cached';
const a11yProps = (index) => {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
  };
};
export const getTreeDataPath = (row) => row.hierarchy;
const Rack = () => {
    const [valueTab, setValueTab] = React.useState("table");
    const navigate = useNavigate();
    const [popper, setPopper] = useState({
      anchorEl: undefined,
      data: undefined,
    });
    const [year, setYear] = useState(new Date().getFullYear() || 2024)
    const [typeShow, setTypeShow] = useState("reality")
    // const {data: warehouse_racks, isLoading: warehouse_racks_loading, refetch:refetchRack} = WarehouseRackApi.listRackBW()
    const {data: rack_bandwidths, isLoading: rack_bw_loading, refetch: refetchBW} = RackBandwidthApi.ListAll({year:year})
    const {data: years, refetch: refetchYear, isLoading: years_loading} = RackBandwidthApi.GetYears()
    // const {data: rack_bandwidths_grow, isLoading:rack_bandwidths_grow_loading, refetch:refetchGrow} = RackBandwidthItemApi.getALLGrow()
    const {data: racksByYear} = RackBandwidthItemApi.GetRacksByYear({year:year})
    let initialColumns = [
        {
          field: "warehouse_box_name",
          headerName: "ISP",
          width: 150,
          renderCell: ({value}) => 
            <Typography>
              {value?.replace("IDC ","")?.split(" ")[0]}
            </Typography> 
            || ""
        },
        {
            field: "warehouse_rack_name",
            headerName: "Rack",
            width: 150,
            renderCell: ({value, row}) => {
                if (row?.warehouse_rack_id?.toString()?.includes("ps")){
                  return (
                    <Typography color={"error"} >
                      {value || ""}
                    </Typography>
                  )
                }
                return (
                      <Link component={NavLink} to={`../warehouse/racks/${row?.warehouse_rack_id}`} >
                        <Typography>
                          {value || ""}
                        </Typography>
                      </Link>
                )
            }
        },
        {
            field: "bandwidth_limit",
            headerName: "Uplink giới hạn",
            width: 200
        },
        {
            field: "bandwidth_commit",
            headerName: "Uplink ký kết với nhà mạng",
            width: 200
        },
        {
            field: "bandwidth_reality",
            headerName: "Uplink sử dụng thực tế",
            width: 200
        },
        {
            field: "actions",
            headerName: "Thao tác",
            width: 200,
            renderCell: ({row}) => {
                if(row?.hierarchy?.length === 1){ 
                    return (
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                            >
                            <IconButton
                                title="Thao Tác"
                                onClick={(event) => {
                                setPopper({ anchorEl: event?.target, data: row });
                                }}
                            >
                                <MoreVertOutlined sx={{ m: "auto" }} />
                            </IconButton>
                        </Box>
                    )
                }
            }
        }
    ]
    const [actions] = useState([
        {
          name: "update",
          handleClick: (data) => {
            navigate(`./${data?.rack_bandwidth_id}`);
          },
          icon: "Edit",
          label: "Chỉnh Sửa",
        },
      ]);
    const handleChangeTab = (event, newValue) => {
      setValueTab(newValue);
    };
    const handleChangeYear = (event, newValue) => {
      setYear(newValue);
    };
    const handleChangeTypeShow = (event, newValue) => {
      setTypeShow(newValue)
    }
    const reFormatRows = () => {
        const rows = []
        rack_bandwidths?.rack_bandwidths?.sort((a, b) => a?.rack_bandwidth_name?.localeCompare(b?.rack_bandwidth_name))
        rack_bandwidths?.rack_bandwidths?.forEach((item, index) => {
            const row = {
                hierarchy: [item?.rack_bandwidth_name],
                id: index,
                ...item
            }
            item?.rack_bandwidth_items?.sort((a,b) => a?.warehouse_box_name?.localeCompare(b?.warehouse_box_name))?.forEach((item_child, index_child) => {
                const rack = _.find(racksByYear, { warehouse_rack_id: Number(item_child?.warehouse_rack_id)})

                const rowChild = {
                    ...item_child,
                    hierarchy: [item?.rack_bandwidth_name, index_child + 1 ],
                    warehouse_rack_name: rack?.warehouse_rack_name || item_child?.warehouse_rack_name,
                    id: item?.rack_bandwidth_name + "-" + (rack?.warehouse_rack_name || item_child?.warehouse_rack_name)
                }
                rows?.push(rowChild)
            })
            const child = rows?.filter(rowChild => rowChild?.hierarchy[0] === row?.hierarchy[0]) || []
                if (child && typeof(child[0]) === "object"){
                  Object?.keys(child[0])?.forEach((key) => {
                    if (key !== "hierarchy" && key !== "id" && key !== "warehouse_rack_name" && key !== "rack_bandwidth_id" && key !== "warehouse_box_name" && key !== "rack_bandwidth_date_end") {
                        row[key] = _.sumBy(child, key)
                      }
                  })

                }
            rows?.push(row)
            
        })
        
        return rows || []
    }
    const reFormatAdvancedRows = () => {
        const rows = []
        rack_bandwidths?.rack_bandwidths?.forEach((item, index) => {
            item?.rack_bandwidth_items?.forEach((item_child, index_child) => {
              const row = {
                ...item_child,
                rack_bandwidth_date_start: item?.rack_bandwidth_date_start,
                rack_bandwidth_date_end: item?.rack_bandwidth_date_end,
              }
              rows.push(row)
            })
        })
        return rows || []
    }
    const groupingColDef = React.useCallback(
        () => ({
          field: "__tree_data_group__",
          headerName: 'Nhóm tháng thống kê',
          width: 275,
          renderCell: ({row, ...params}) => {
            const { rowNode } = params;
            if(row?.manufacture){
              params = {
                ...params,
                rowNode: {
                  ...params["rowNode"],
                  groupingKey: row?.warehouse_box_name?.replace("IDC ","")?.split(" ")[0]
                }
              }
            }
            return (
              <GridTreeDataGroupingCell
                total={
                  rowNode?.children?.length
                }
                {...params}
              />
            );
          },
        }),
        []
      );
return (
    <Box>
        <Header />
        <Paper sx={{ p: 2 }}>
          <Button
              variant='contained'
              size='small'
              sx={{
                  height: { xs: 36, md: 42 },
                  m: 0.5
              }}
              onClick={() => {
                  navigate("/warehouse/bandwidths/racks/new")
              }}
              startIcon={<AddOutlined />}
          >
              Thêm đối soát rack
          </Button>
          <Button
              variant='contained'
              size='small'
              disabled = {years_loading || rack_bw_loading}
              sx={{
                  height: { xs: 36, md: 42 },
                  m: 0.5
              }}
              onClick={() => {
                  refetchBW()
                  refetchYear()
              }}
              startIcon={<CachedIcon />}
          >
              Làm mới dữ liệu
          </Button>
        </Paper>
        <Paper sx={{ p: 2, mt: 2, mb: 2 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                  <Tabs value={year} onChange={handleChangeYear} aria-label="basic tabs example">
                    { years?.length > 0 ?
                    years?.map(
                      (rack_year, index) => (
                        <Tab key = {index} label={Number(rack_year?.year)} value={Number(rack_year?.year)} {...a11yProps(index)} />
                      )
                    ):
                    <Tab label={year} value={year} {...a11yProps(0)} />
                  }
                  </Tabs>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                  <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
                      <Tab label={`Hiển thị mặc định`} value={"table"} {...a11yProps(0)} />
                      <Tab label={`Hiển thị đơn giản`} value={"group"} {...a11yProps(1)} />
                  </Tabs>
          </Box>
          {valueTab === "table" &&
            <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
              <Tabs value={typeShow} onChange={handleChangeTypeShow} aria-label="basic tabs example">
                  <Tab label={`Uplink giới hạn`} value={"limit"} {...a11yProps(0)} />
                  <Tab label={`Uplink ký kết với nhà mạng`} value={"commit"} {...a11yProps(1)} />
                  <Tab label={`Uplink thực tế dùng`} value={"reality"} {...a11yProps(2)} />
              </Tabs>
            </Box>
          }
        </Paper>
        <Paper sx={{ mt: 2 }} fallback={<CircularProgress size={24} sx={{ m: 'auto' }} />}>
          {valueTab === "table" ?
              <GroupingColAndRowDataGrid 
                racks = {racksByYear} 
                rows={reFormatAdvancedRows() || []} 
                type={typeShow}
                year={year}
              />:
              <GroupingRowDataGrid 
                groupingColDef={groupingColDef} 
                getTreeDataPath={getTreeDataPath} 
                actions={actions} 
                rows={reFormatRows() || []}
                initialColumns={initialColumns}
                popper={popper}
                setPopper={setPopper}
              />
          }
        </Paper>
    </Box>
)
}
export default withSuspense(withPermission(Rack, {
    feature: "warehouse",
    action: "bandwidth-rack"
}), MuiSkeleton["DataGrid"]) 