import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DialogConfirmChangeStatus(props) {
  const handleClose = () => {
    props.handleClose();
  };
  const handleAccept = () => {
    props.handleAcceptChangeStatus();
  }
    return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        {props.watch_status === 'active' ? "Hủy đăng ký báo cáo" : "Đăng ký báo cáo"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn có chắc muốn <strong>{props.watch_status === 'active' ? "hủy đăng ký báo cáo" : "đăng ký báo cáo"}</strong> theo định kỳ cho khách hàng <strong>{props.watch_name}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={() => handleAccept()} autoFocus>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}