import NumericFormatCustom from '@components/NumericFormatCustom'
import { FormControl, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const PriceSubtotal = () => {

    const { control } = useFormContext()
    return (
        <Controller
            name="commitment.pricing_amount"
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <FormControl fullWidth>
                        <TextField
                            value={value}
                            onChange={onChange}
                            label="Tạm tính *"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: NumericFormatCustom,
                            }}
                            size="small"
                        />
                    </FormControl>
                );
            }}
        />
    )
}

export default PriceSubtotal