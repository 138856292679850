import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Button, Chip, Container, Dialog, IconButton, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { API_STORAGE_URL } from '@/libs/constants';
import IosShareIcon from '@mui/icons-material/IosShare';
import Paper from '@mui/material/Paper';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));
const DialogHistoryReport = (props) => {
    const { tasks } = props;
    if (tasks?.length > 0) {
        tasks?.sort((a, b) => new Date(b?.task_completed_at) - new Date(a?.task_completed_at));
    }
    const handleExportReport = (task) => {
        const url = `${API_STORAGE_URL}reports/waf/${task?.task_completed_at?.slice(0, 7)}/${task?.task_referrer_jid}.pdf`;
        const nameFile = `${props?.historyName?.replaceAll(' ', '')}_${task?.task_completed_at?.slice(0, 10)}`
        props?.handleExportReport(url, nameFile);
    }
    const tableCell = [
        {
            name: "Ngày hoàn thiện (MM,DD,YYYY)",
            align: "left"
        },
        {
            name: "Loại report",
            align: "center"
        },
        {
            name: "Trạng thái gửi",
            align: "center"
        },
        {
            name: "Xuất file",
            align: "center"
        }
    ]
    return (
        <Dialog
            hideBackdrop
            fullScreen
            keepMounted
            open={props?.open}
            onClose={props?.handleClose}
            TransitionComponent={Transition}
            scroll='paper'
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props?.handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Lịch sử báo cáo
                    </Typography>
                    <Button autoFocus color="inherit" onClick={props?.handleClose}>
                        Thoát
                    </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="sm" sx={{ p: 10 }}>
                <Demo>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Lịch sử báo cáo {props?.historyName}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {tableCell.map((cell, index) => (
                                        <TableCell key={index} align={cell?.align}>{cell?.name}</TableCell>
                                    ))}
                                    {/* <TableCell>Dessert (100g serving)</TableCell>
                            <TableCell align="right">Calories</TableCell>
                            <TableCell align="right">Fat&nbsp;(g)</TableCell>
                            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tasks?.map((task, index) => (
                                    // Math.floor((new Date(tasks[tasks.length -1].task_completed_at).getFullYear() - new Date(task.task_completed_at)) / (1000 * 60 * 60 * 24)) <=30 &&
                                    new Date(task?.task_completed_at).getFullYear() >= 2024 &&
                                    <TableRow
                                        hover
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">
                                            {new Date(task?.task_completed_at).toLocaleString()}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>{task?.task_bind?.replace("waf::report::","")}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Chip size='small' label={task?.task_status} color={task?.task_status === "completed" ? "success" : "fail"} variant="outlined" />
                                        </TableCell>
                                        {/* <TableCell align="center">
                                <Button variant="outlined" onClick={() => {handleShowReport(task)}} >Xem trước</Button>
                            </TableCell> */}
                                        <TableCell align="center">
                                            <IconButton edge="end" aria-label="export" onClick={() => handleExportReport(task)}>
                                                <IosShareIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <List dense={true}>
                    {
                    props.tasks.map((task, index) => 
                    Math.floor((new Date(tasks[tasks.length -1].task_completed_at) - new Date(task.task_completed_at)) / (1000 * 60 * 60 * 24)) <=30 &&
                        <ListItem
                        key={index}
                        secondaryAction={
                            <IconButton edge="end" aria-label="export" onClick={() => handleExportReport(task)}>
                            <IosShareIcon />
                            </IconButton>
                        }
                        >
                        <ListItemIcon>
                            <InsertDriveFileIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={`Báo cáo ${new Date(task.task_completed_at).toLocaleString()}`}
                        />
                        </ListItem>
                        )
                        }
                    </List> */}
                </Demo>
            </Container>

        </Dialog>
    );
}

export default DialogHistoryReport;
