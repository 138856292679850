import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import { format_date_short, getMuiDataGridRowHeight } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import { WAREHOUSE_STATUS_BUY, format_pricing_vnd } from "@/libs/constants";
import Cell from "@components/DataGrid/Cell";
import CellConfig from "@components/DataGrid/CellConfig2";
import DataGridProCustomize from "@components/DataGridProCustomize";
import PopperAction from "@components/PopperAction";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { useConfirmContext } from "@contexts/confirm";
import PaperLoading from "@features/Module/WarehouseNew/Shared/Loading/PaperLoading";
import { MoreVertOutlined } from "@mui/icons-material";
import { Box, IconButton, Popper } from "@mui/material";
import { styled } from '@mui/material/styles';
import _ from "lodash";
import { useMemo, useState } from "react";

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1
}));
const TableGroupWarehouseItem = ({
  warehouse_process_metadata,
  type,
  handleRemoveItemInProcessMetadata,
  handleShowDialogEditItemInProcessMetadata,
  process_type = null,
  use = ["delete", "update"],
}) => {
  const { showConfirmation } = useConfirmContext();

  const { data: warehouse_models, isLoading } = WarehouseModelApi.All();

  const [popper, setPopper] = useState({
    anchorEl: null,
    data: null,
    use: use,
  });

  const actions = [
    {
      order: 2,
      name: "delete",
      icon: "Delete",
      label: "Xoá",
      handleClick: (data) => {
        showConfirmation({
          title: "Bạn có chắc muốn xoá linh kiện này ra khỏi thiết bị",
          open: true,
          description: "Cảnh báo trước khi xoá",
          loading: false,
          handler: () => {
            setPopper((prev) => ({ ...prev, anchorEl: undefined }));
            handleRemoveItemInProcessMetadata(data?.id);
          },
        });
      },
    },
    {
      order: 1,
      name: "update",
      icon: "Edit",
      label: "Chỉnh Sửa",
      handleClick: (data) => {
        setPopper((prev) => ({ ...prev, anchorEl: undefined }));
        handleShowDialogEditItemInProcessMetadata(data);
      },
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "Thao Tác",
      width: 80,
      valueGetter: ({ row }) => {
        return row?.warehouse_item_group?.length === 1;
      },
      renderCell: ({ row }) =>
        type === "detail" ? (
          ""
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {row?.warehouse_item_group?.length === 1 && (
              <IconButton
                title="Thao Tác"
                onClick={(event) => {
                  setPopper((prev) => ({
                    ...prev,
                    anchorEl: event?.target,
                    data: row,
                    use: use
                  }));
                }}
              >
                <MoreVertOutlined sx={{ m: "auto" }} />
              </IconButton>
            )}
            {
              process_type === 3 && row?.warehouse_item_group?.length === 2 && (
                <IconButton
                  title="Thao Tác"
                  onClick={(event) => {
                    setPopper((prev) => ({
                      ...prev,
                      anchorEl: event?.target,
                      data: row,
                      use: ["update"],
                    }));
                  }}
                >
                  <MoreVertOutlined sx={{ m: "auto" }} />
                </IconButton>
              )
            }
          </Box>
        ),
    },
    {
      field: "warehouse_item_type",
      valueGetter: ({ row }) =>
        _.find(warehouse_models, {
          warehouse_model_id: parseInt(row.warehouse_model_id),
        })?.warehouse_type?.warehouse_type_name,
      headerName: "Loại",
      width: 300,
    },
    {
      field: "warehouse_item_name",
      headerName: "Tên Hàng",
      width: 300,
    },
    {
      field: "capacity",
      headerName: "Cấu Hình",
      width: 250,
      renderCell: ({ value }) => {
        if (value) {
          return <CellConfig configures={value} />;
        } else {
          <></>;
        }
      },
    },
    {
      field: "total",
      headerName: "Số lượng",
      valueGetter: ({ row }) =>
        !_.isEmpty(row.service_tags)
          ? row.service_tags.length
          : parseInt(row.total || 1),
      width: 150,
    },
    {
      field: "service_tags",
      headerName: "Service_Tag/ Series Number",
      valueGetter: ({ row }) =>
        !_.isEmpty(row.service_tags)
          ? row.service_tags.join(" ,")
          : row?.warehouse_item_service_tag
            ? row?.warehouse_item_service_tag
            : "",
      renderCell: ({ value }) => <Cell content={value} />,
      width: 300,
    },
    {
      field: "warehouse_item_status",
      headerName: "Trình Trạng",
      valueGetter: ({ row }) =>
        _.find(WAREHOUSE_STATUS_BUY, {
          value: parseInt(row.warehouse_item_status),
        })?.label,
      width: 150,
    },
    {
      field: "warehouse_item_import_at",
      headerName: "Ngày Nhập",
      valueGetter: ({ row }) => format_date_short(row.warehouse_item_import_at),
      width: 200,
    },

  ];
  process_type === 3 ? columns.push(
    {
      field: "warranty_start_at",
      headerName: "Ngày bắt đầu bảo hành xuất bán",
      valueGetter: ({ row }) =>
        format_date_short(row.warranty_start_at),
      width: 250,
    },
    {
      field: "warranty_expired_at",
      headerName: "Ngày kết thúc bảo hành xuất bán",
      valueGetter: ({ row }) =>
        format_date_short(row.warranty_expired_at),
      width: 250,
    },
  ) : columns.push({
    field: "warehouse_item_price",
    headerName: "Giá Mua",
    valueGetter: ({ row }) =>
      format_pricing_vnd(Number(row.warehouse_item_price) || 0) || "",
    width: 200,
  },);
  const reFormatData = useMemo(() => {
    let newData = [];

    warehouse_process_metadata?.forEach((wpItem, index) => {
      newData.push({
        ...wpItem,
        warehouse_item_group: [`${index + 1}`],
      });

      if (wpItem?.children) {
        wpItem?.children?.forEach((ele, index2) => {
          try {
            newData.push({
              ...ele,
              warehouse_item_group: [
                `${index + 1}`,
                `${index + 1}.${index2 + 1}`,
              ],
            });
          } catch (exception) {
            console.log("errors", exception);
            return;
          }
        });
      }
    });

    return newData;
  }, [warehouse_process_metadata]);

  if (warehouse_process_metadata?.length === 0) return null;

  if (isLoading) return <PaperLoading />;

  return (
    <>
      <DataGridProCustomize
        sx={{ mt: 2 }}
        rows={reFormatData}
        columns={columns}
        disabled={["Pagination", "Toolbar"]}
        treeData
        getTreeDataPath={(row) => row?.warehouse_item_group}
        getRowHeight={(params) =>
          getMuiDataGridRowHeight(
            params?.model?.capacity,
            [
              "capacity_cpu",
              "capacity_ram",
              "capacity_storage",
              "capacity_power_supply",
            ],
            2
          )
        }
        getRowId={() => Math.random()}
        initialState={{
          pinnedColumns: { left: ["__tree_data_group__", "service_tags"], right: ["id"] },
        }}
      />
      {popper?.anchorEl !== undefined && (
        <StyledPopper anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)}>
          <PopperAction
            {...popper}
            actions={actions?.filter((item) => popper?.use?.includes(item?.name))}
            handleClose={() =>
              setPopper((prev) => ({ ...prev, anchorEl: undefined }))
            }
          />
        </StyledPopper>
      )}
    </>
  );
};

export default withSuspense(
  TableGroupWarehouseItem,
  MuiSkeleton["LinearProgress"]
);
