import React from "react";
import { ErrorOutline } from "@mui/icons-material";
import { Box, Grid, Paper, Typography } from "@mui/material";
import EmployeeApi from "@/apis/beta/Employee/EmployeeApi";
import { WAREHOUSE_PROCESS_TYPE } from "@/libs/constants";
import { format_date_short } from "@/functions";
import { Link } from "react-router-dom";
import { blue } from "@mui/material/colors";

const Information = ({ warehouseProcess, type = "create" }) => {
  const { data: employees } = EmployeeApi.ListEmployees();

  return (
    <Paper sx={{ p: 3, mb: 1, mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Typography sx={{ mb: 0.5, color: "#777680" }}>
            {warehouseProcess?.warehouse_process_type === 0
              ? "Nơi Mua:"
              : "Nơi Đi:"}
          </Typography>
          <Typography sx={{ color: "#00000F" }}>
            {warehouseProcess?.warehouse_process_from || "Kho VNETWORK"}
          </Typography>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Typography sx={{ mb: 0.5, color: "#777680" }}>
            Loại Phiếu:
          </Typography>
          <Typography sx={{ color: "#00000F" }}>
            {WAREHOUSE_PROCESS_TYPE?.find(
              (t) => t.value === warehouseProcess?.warehouse_process_type
            )?.label || "Không xác định"}
          </Typography>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Typography sx={{ mb: 0.5, color: "#777680" }}>Nơi Đến:</Typography>
          <Typography sx={{ color: blue[900], textDecoration: "underline" }}>
            <Link to={warehouseProcess?.customer_id ? `/customers/${warehouseProcess?.customer_id}` : `#`}>
              {warehouseProcess?.warehouse_process_to}
            </Link>
          </Typography>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Typography sx={{ mb: 0.5, color: "#777680" }}>Thủ Kho:</Typography>
          <Typography
            sx={{
              color:
                warehouseProcess?.warehouse_process_storekeeper === "approved"
                  ? "#66BB6A"
                  : "#FF9800",
            }}
          >
            {warehouseProcess?.warehouse_process_storekeeper}
          </Typography>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Typography sx={{ mb: 0.5, color: "#777680" }}>Ngày Tạo:</Typography>
          <Typography sx={{ color: "#00000F" }}>
            {format_date_short(warehouseProcess?.warehouse_process_created_at)}
          </Typography>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Typography sx={{ mb: 0.5, color: "#777680" }}>Nhân Viên:</Typography>
          <Typography sx={{ color: "#00000F" }}>
            {employees?.find(
              (e) => e.employee_id === warehouseProcess.employee_id
            )?.employee_name || "Không xác định"}
          </Typography>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Typography sx={{ mb: 0.5, color: "#777680" }}>Phòng Ban:</Typography>
          <Typography sx={{ color: "#00000F" }}>
            {employees?.find(
              (e) => e.employee_id === warehouseProcess.employee_id
            )?.employee_department || "Không xác định"}
          </Typography>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Typography sx={{ mb: 0.5, color: "#777680" }}>
            Trưởng Nhóm Kỹ Thuật:
          </Typography>
          <Typography
            sx={{
              color:
                warehouseProcess?.warehouse_process_lead === "approved"
                  ? "#66BB6A"
                  : "#FF9800",
            }}
          >
            {warehouseProcess?.warehouse_process_lead}
          </Typography>
        </Grid>
        {type !== "edit" && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography sx={{ mb: 0.5, color: "#777680" }}>Lý Do:</Typography>
            <Typography className="preserve-whitespace">
              {warehouseProcess?.warehouse_process_reason}
            </Typography>
          </Grid>
        )}
      </Grid>
      {type !== "edit" && warehouseProcess.warehouse_process_lead_feedback && (
        <Box sx={{ display: "flex", mb: 2 }}>
          <ErrorOutline sx={{ m: "0 8px auto 0", color: "#1A237E" }} />
          <Box>
            <Typography sx={{ color: "#777680", mb: 0.5 }}>
              Phản hồi từ Tech
            </Typography>
            <Typography>
              {warehouseProcess?.warehouse_process_lead_feedback}
            </Typography>
          </Box>
        </Box>
      )}
      {warehouseProcess.warehouse_process_storekeeper_feedback && (
        <Box sx={{ display: "flex", mb: 2 }}>
          <ErrorOutline sx={{ m: "0 8px auto 0", color: "#1A237E" }} />
          <Box>
            <Typography sx={{ color: "#777680", mb: 0.5 }}>
              Phản hồi từ Thủ Kho
            </Typography>
            <Typography>
              {warehouseProcess?.warehouse_process_storekeeper_feedback}
            </Typography>
          </Box>
        </Box>
      )}
      {warehouseProcess.warehouse_process_note && (
        <Box sx={{ display: "flex", mb: 2 }}>
          <ErrorOutline sx={{ m: "0 8px auto 0", color: "#1A237E" }} />
          <Box>
            <Typography sx={{ color: "#777680", mb: 0.5 }}>Ghi chú</Typography>
            <Typography>{warehouseProcess?.warehouse_process_note}</Typography>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default Information;
