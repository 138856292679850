import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React from "react";

const DatePicker = ({ onChange, value, disabled, ...props }) => {
  const date = moment(value).format("YYYY-MM-DD");
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        {...props}
        disabled={disabled}
        inputFormat="DD/MM/YYYY"
        InputProps={{
          type: "date",
          sx: {
            fontSize: 14,
            height: 40,
            bgcolor: "#FFF",
          },
        }}
        onChange={(newValue) => {
          onChange(moment(newValue?.["$d"])?.format("YYYY-MM-DD") || null);
        }}
        value={date}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props?.label || null}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
