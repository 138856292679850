import { requestWithToken } from "@/functions";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";


class WarehouseBoxItemApi {
  Update = (id, params) =>
    requestWithToken(
      `${API_WAREHOUSE_V1_URL}/warehouse_box_items/${id}`,
      "PUT",
      { warehouse_box_item: params }
    );
}

export default WarehouseBoxItemApi = new WarehouseBoxItemApi();
