import { buildQueryFilterCondition } from "@/functions";
import {
  WAREHOUSE_PROCESS_STATUS_TYPE,
  WAREHOUSE_PROCESS_TYPE,
} from "@/libs/constants";
import { Box, MenuItem, Paper, Select, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

const ProcessesFilter = ({ updateQuery }) => {
  const { control, watch } = useForm({
    defaultValues: {
      "warehouse_processes-warehouse_process_type": "",
      "warehouse_processes-warehouse_process_status": "",
    },
  });

  useEffect(() => {
    const fieldQueries = Object.keys(watch())
      ?.filter(
        (key) => typeof watch()?.[key] !== "undefined" && watch()?.[key] !== ""
      )
      ?.map((key) => ({
        name: key?.replace("-", "."),
        operator: "$eq",
        value: watch()?.[key],
      }));
    if (!fieldQueries?.length) {
      return
    }

    updateQuery(buildQueryFilterCondition("$and", 0, fieldQueries));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watch())]);

  return (
    <Paper sx={{ p: 2, mt: 2, display: "flex", alignItems: "center", gap: 2, flexWrap: "wrap" }}>
      <Box>
        <Typography fontSize={14} fontWeight={600}>
          Loại Phiếu
        </Typography>
        <Controller
          control={control}
          name="warehouse_processes-warehouse_process_type"
          render={({ field }) => (
            <Select
              {...field}
              sx={{ width: 300, mt: 1 }}
              size="small"
              placeholder="Loại"
            >
              {WAREHOUSE_PROCESS_TYPE?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Box>

      <Box>
        <Typography fontSize={14} fontWeight={600}>
          Trạng Thái Phiếu
        </Typography>
        <Controller
          control={control}
          name="warehouse_processes-warehouse_process_status"
          render={({ field }) => (
            <Select
              {...field}
              sx={{ width: 300, mt: 1 }}
              size="small"
              placeholder="Loại"
            >
              {WAREHOUSE_PROCESS_STATUS_TYPE?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Box>
    </Paper>
  );
};

export default ProcessesFilter;
