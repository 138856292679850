import { handleRedirectOpenLink } from "@/functions";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

const CreateService = ({ onClose, customer }) => {
  const handleClick = () => {
    onClose();
    return handleRedirectOpenLink(
      `/customer_contracts/new?customer_id=${customer?.customer_id}`
    );
  };
  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle>Khởi tạo dịch vụ cho khách hàng</DialogTitle>
      <DialogActions>
        <Button size="small" variant="outlined" onClick={onClose}>
          Để Sau
        </Button>
        <Button size="small" variant="contained" onClick={handleClick}>
          Khởi Tạo Dịch Vụ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateService;
