import { UNLIMITED } from '@/libs/constants'
import { useStateArrayContext } from '@contexts/stateArray'
import { RemoveOutlined } from '@mui/icons-material'
import AddOutlined from '@mui/icons-material/AddOutlined'
import { Box, Checkbox, Collapse, IconButton } from '@mui/material'

const ServiceRangeEnabled = ({ data }) => {
    const { updateAttribute } = useStateArrayContext()

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        }}>
            <Checkbox size='small'
                checked={data?.service_range_enabled}
                onChange={(event) => {
                    const checked = event?.target?.checked
                    updateAttribute({
                        service_range_enabled: checked
                    }, data?.id)
                }}
            />
            <Collapse in={data?.service_range_enabled}>
                <IconButton size='small' onClick={() => {
                    updateAttribute({
                        service_price_exceeds: [
                            ...data?.service_price_exceeds,
                            { range_start: 0, range_end: UNLIMITED, pricing: 0 }
                        ]
                    }, data?.id)
                }}>
                    <AddOutlined fontSize='small' color='primary' />
                </IconButton>
                <IconButton size='small' onClick={() => {
                    updateAttribute({
                        service_price_exceeds: [...data?.service_price_exceeds?.slice(0, data?.service_price_exceeds?.length - 1),
                        ]
                    }, data?.id)
                }}>
                    <RemoveOutlined fontSize='small' color='primary' />
                </IconButton>
            </Collapse>
        </Box>
    )
}

export default ServiceRangeEnabled