import React from "react";
import Form from "../Form";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";


const Update = ({refetchData , handleClose , investment }) => {
    return (
        <>
        <Form type={"update"} refetchData={refetchData} handleClose={handleClose} investment={investment} />
        </>
    )
}
export default withSuspense(withPermission(Update, {
    feature: "warehouse",
    action: "investment-update"
}), MuiSkeleton["DataGrid"])