import ThresholdApi from "@/apis/beta/Threshold/ThresholdApi";
import useDataGridParams from "@/hooks/useDataGridParams";
import HeaderTitle from "@components/Title/HeaderTitle";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Popper,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { blue } from "@mui/material/colors";
import moment from "moment";
import DataGridCustomize from "@components/DataGrid";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import ThresholdFilter from "./ThresholdFilter";
import { useState } from "react";
import PopperAction from "@components/PopperAction";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { useConfirmContext } from "@contexts/confirm";
import AddIcon from "@mui/icons-material/Add";
import TechnicalThresholdCreate from "../../Dialog/TechnicalThresholdCreate";

const List = () => {
  const initialColumns = [
    {
      field: "threshold_name",
      headerName: "Name",
      sortable: false,
      filterable: false,
      resizable: true,
      width: 300,
      maxWidth: 450,
      renderCell: ({ row, value }) => {
        return (
          <NavLink to={`/technical/monitoring/thresholds/${row?.threshold_id}`}>
            <Typography
              sx={{ fontSize: 13, color: blue[900], cursor: "pointer" }}
            >
              {value}
            </Typography>
          </NavLink>
        );
      },
    },
    {
      field: "threshold_scope",
      headerName: "Scope",
      sortable: false,
      filterable: false,
      resizable: false,
      width: 200,
    },
    {
      field: "threshold_bind",
      headerName: "Bind",
      sortable: false,
      filterable: false,
      resizable: false,
      width: 250,
    },
    {
      field: "threshold_status",
      headerName: "Status",
      sortable: false,
      filterable: false,
      resizable: false,
      width: 100,
      renderCell: ({ value }) => {
        return (
          <Chip
            label={value}
            sx={{ fontSize: 10, textTransform: "capitalize" }}
            variant="outlined"
            size="small"
            color={(value === "active" && "success") || undefined}
          />
        );
      },
    },
    {
      field: "threshold_interval",
      headerName: "Interval",
      sortable: false,
      filterable: false,
      resizable: false,
      width: 150,
    },
    {
      field: "threshold_alarms",
      headerName: "Alarm Setup",
      sortable: false,
      filterable: false,
      resizable: false,
      width: 150,
      valueGetter: ({ value }) =>
        value.filter((i) => i.alarm_status === "active")?.length,
      renderCell: ({ value }) => (
        <Typography sx={{ fontSize: 13 }}>
          {(value >= 1 && (
            <CheckCircleOutlineIcon
              sx={{ fontSize: 20, color: "#357a38", mr: 0.5 }}
            />
          )) ||
            "-"}
        </Typography>
      ),
    },
    {
      field: "threshold_performed_at",
      headerName: "Last Performed",
      sortable: false,
      filterable: false,
      resizable: false,
      width: 150,
      valueGetter: ({ value }) => (value && moment(value).fromNow()) || "-",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      filterable: false,
      resizable: false,
      width: 120,
      renderCell: ({ row }) => {
        return (
          <IconButton
            onClick={(e) =>
              setPopper({ ...popper, data: row, anchorEl: e?.currentTarget })
            }
          >
            <MoreVertIcon />
          </IconButton>
        );
      },
    },
  ];

  const { columns, page, query, setColumns, setPage, updateQuery } =
    useDataGridParams(initialColumns, 50);

  const { showConfirmation } = useConfirmContext();

  const [popper, setPopper] = useState({
    anchorEl: null,
    data: null,
    actions: [
      {
        order: 2,
        name: "delete",
        icon: "Delete",
        label: "Xoá",
        handleClick: (data) => {
          showConfirmation({
            title: "Bạn có chắc muốn xoá",
            description: "Thao tác này không thể khôi phục",
            open: true,
            loading: ThresholdDestroyLoading,
            handler: async () => {
              await ThresholdDestroy(data?.threshold_id);
              refetch();
              notify("success", "Xoá thành công");
            },
          });
        },
      },
      {
        order: 2,
        name: "update",
        icon: "Edit",
        label: "Chỉnh Sửa",
        handleClick: (data) => {
          setIdUpdateThreshold(data?.threshold_id);
          setShowDialogCreate(true);
        },
      },
      {
        order: 3,
        name: "copy",
        icon: "Copy",
        label: "Sao Chép",
        handleClick: (data) => {
          showConfirmation({
            title: "Sao Chép?",
            description:
              "Thao tác này sẽ giúp bạn copy hoàn toàn threshold này ra một cái mới với status draft",
            open: true,
            loading: CloneThresholdLoading,
            handler: async () => {
              await CloneThreshold({ threshold_id: data?.threshold_id });
              refetch();
              notify("success", "Clone Success");
            },
          });
        },
      },
    ],
  });
  const [showDialogCreate, setShowDialogCreate] = useState(false);
  const [idUpdateThreshold, setIdUpdateThreshold] = useState(null);

  const { data, isFetching, refetch } = ThresholdApi.Index({
    page: page?.page + 1,
    pageSize: page?.pageSize,
    query,
  });

  const {
    mutateAsync: ThresholdDestroy,
    isLoading: ThresholdDestroyLoading,
    notify,
  } = useMutationWithNotification(ThresholdApi.Destroy);

  const { mutateAsync: CloneThreshold, isLoading: CloneThresholdLoading } =
    useMutationWithNotification(ThresholdApi.CloneThreshold);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <HeaderTitle>Technical Working Playground</HeaderTitle>
        <Button
          onClick={() => setShowDialogCreate(true)}
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
        >
          Thêm
        </Button>
      </Box>

      <Box mt={2}>
        <ThresholdFilter updateQuery={updateQuery} />
      </Box>

      <DataGridCustomize
        rows={data?.thresholds || []}
        columns={columns}
        loading={isFetching}
        rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
        getRowId={(row) => row?.threshold_id}
        componentsProps={{
          toolbar: {
            store: "threshold.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "threshold",
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({ ...prevState, pageSize: newPageSize })),
          },
        }}
        sx={{ mt: 2 }}
      />

      {popper?.anchorEl !== undefined && popper?.actions?.length > 0 && (
        <Popper
          anchorEl={popper?.anchorEl}
          open={Boolean(popper.anchorEl)}
          sx={{ zIndex: 100 }}
        >
          <PopperAction
            {...popper}
            handleClose={() => {
              setPopper({ ...popper, anchorEl: null, data: null });
            }}
          />
        </Popper>
      )}

      {showDialogCreate && (
        <TechnicalThresholdCreate
          idUpdateThreshold={idUpdateThreshold}
          setIdUpdateThreshold={setIdUpdateThreshold}
          handleClose={() => {
            setShowDialogCreate(false);
            setIdUpdateThreshold(null);
          }}
          open={showDialogCreate}
          refetch={refetch}
        />
      )}
    </Box>
  );
};

export default withSuspense(
  withPermission(List, { feature: "technical", action: "threshold" }),
  MuiSkeleton["DataGrid"]
);
