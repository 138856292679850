import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const TicketFeedback = ({ ticket }) => {
    const { control } = useFormContext()
    if (!ticket) return null;
    return (
        <Controller
            name="ticket_feedback"
            control={control}
            render={({ field }) => (
                <TextField
                    label="Feedback của khách hàng"
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    size="small"
                    multiline
                    rows={4}
                    placeholder="Feedback"
                />
            )}
        />
    )
}

export default TicketFeedback