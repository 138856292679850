import WorkDayApi from "@/apis/beta/Employee/WorkDayApi";
import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import useDataGridParams from "@/hooks/useDataGridParams";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import DataGridCustomize from "@components/DataGrid";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ListDrawer, defaultFrom, defaultTo, initialColumns } from "../shared";
import EditWorkDay from "./Dialog/EditWorkDay";
import Header from "./Header";

const List = () => {
  const {
    columns,
    debouncedSearchQuery,
    page,
    searchQuery,
    setColumns,
    setPage,
    setSearchQuery,
  } = useDataGridParams(initialColumns);

  const [showDrawer, setShowDrawer] = useState({
    open: false,
    drawer: null,
    values: null,
  });

  const [showDialogEdit, setShowDialogEdit] = useState({
    open: false,
    values: null,
  });

  const methods = useForm({
    defaultValues: {
      from: dayjs(defaultFrom),
      to: dayjs(defaultTo),
    },
  });

  const { watch } = methods;

  const { data, isFetching } = useQueryWithCustomized(
    `workdays-${JSON.stringify({
      ...watch(),
      ...page,
      q: debouncedSearchQuery,
    })}`,
    () =>
      WorkDayApi.FilterWorkDay({ ...watch(), ...page, q: debouncedSearchQuery })
  );

  return (
    <Box>
      {showDrawer?.values && showDrawer?.drawer && showDrawer?.open && (
        <showDrawer.drawer
          open={showDrawer?.open}
          onClose={() =>
            setShowDrawer({ open: false, values: null, drawer: null })
          }
          values={showDrawer?.values}
        />
      )}

      {showDialogEdit?.values && showDialogEdit?.open && (
        <EditWorkDay
          open={showDialogEdit?.open}
          handleClose={() => setShowDialogEdit({ open: false, values: null })}
          values={showDialogEdit?.values}
        />
      )}

      <FormProvider {...methods}>
        <Header />
      </FormProvider>

      <DataGridCustomize
        onCellClick={(params) => {
          switch (params?.field) {
            case "work_n_day":
              setShowDialogEdit({ open: true, values: params?.row });
              break;

            case "work_date_type":
              const Drawer = ListDrawer?.[params?.row?.work_date_type];

              if (!Drawer) return;

              setShowDrawer({
                open: true,
                values: params?.row,
                drawer: Drawer,
              });

              break;

            default:
              console.log("Thì thôi chứ biết xử lí gì!");
              break;
          }
        }}
        getRowId={(row) => row?.work_day_id}
        rows={data?.data || []}
        columns={columns}
        loading={isFetching}
        rowCount={data?.pagination?.total_pages * page?.pageSize || 0}
        componentsProps={{
          toolbar: {
            store: "workdays.list.hiddenColumns",
            columns: columns?.filter((col) => !col?.hideable),
            setColumns: setColumns,
            initialColumns: initialColumns,
            feature: "workdays",
            inputSearch: {
              placeholder: "Tìm kiếm....",
              value: searchQuery,
              onChange: setSearchQuery,
            },
          },
          pagination: {
            rowsPerPageOptions: [10, 20, 50, 100],
            page: page?.page || 0,
            pageSize: page?.pageSize || 20,
            onPageChange: (newPage) =>
              setPage((prevState) => ({ ...prevState, page: newPage })),
            onPageSizeChange: (newPageSize) =>
              setPage((prevState) => ({
                ...prevState,
                pageSize: newPageSize,
              })),
          },
        }}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(List, {
    feature: "workdays",
    action: "export-excel-file-with-client-data",
  }),
  MuiSkeleton["DataGrid"]
);
