import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box, Collapse, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import JourneyUpdateOne from "./JourneyUpdateOne";

const JourneyUpdateList = ({
  journeyUpdates,
  isFetching,
  setPageSize,
  customerId,
  journeyId,
}) => {
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={9}>
          {isFetching && <MuiSkeleton.LinearProgress />}
          {journeyUpdates?.journey_updates?.map((item, index) => {
            return (
              <JourneyUpdateOne
                item={item}
                key={index}
                customerId={customerId}
                journeyId={journeyId}
              />
            );
          })}
          {
            <Collapse
              in={
                journeyUpdates?.pagination?.total >
                journeyUpdates?.journey_updates?.length
              }
            >
              <Box
                sx={{ mt: 1.25, display: "flex", justifyContent: "flex-end" }}
              >
                <Typography
                  onClick={() => setPageSize((prePage) => prePage + 3)}
                  sx={{
                    fontSize: 13,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  color="primary"
                >
                  Xem thêm
                </Typography>
              </Box>
            </Collapse>
          }
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default JourneyUpdateList;
