import PlaybookActionEdit from "@features/Module/Technical/Dialog/Playbook/PlaybookAction";
import { Box, Button, Chip, Grid, Paper, Popover, Typography } from "@mui/material";
import React from "react";
import ReactJson from "react-json-view";
import CircleIcon from '@mui/icons-material/Circle';

const PlaybookActionDetail = ({action, playbook, refetch}) => {
    const [showDialogCreatePlayBookAction, setShowDialogCreatePlayBookAction] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const isJSON = (string) => {
        if (typeof string !== "object") return false;
        try {
        JSON.parse(string);
        return true;
        } catch (error) {
        return false;
        }
    };
    
    
    const renderTextValue = (obj) => {
        return isJSON(obj) ? (
        <ReactJson collapsed src={JSON.parse(obj)} />
        ) : typeof obj === "object" ? (
        <ReactJson collapsed src={obj} />
        ) : (
        obj
        );
    };

    const handleEdit = () =>{
        setShowDialogCreatePlayBookAction(true)
    }
    return (
        <>
           {action &&
           <>
                <Paper 
                    sx={{
                        p: 3, 
                        mb: 1, 
                        mt: 2,
                        cursor: 'pointer',
                        '&:hover': {
                                backgroundColor: '#e0e0e010',
                                boxShadow: 5,
                                transform: 'scale(1.02)',
                                transition: 'transform 0.4s ease-in-out',
                            },
                        transition: 'transform 0.5s ease-in-out',
                        '&:enter': {
                            transform: 'scale(1)'
                        },
                    }}
                    onClick={() => handleEdit()}
                >
                    <Box sx={{ mt: 2, mb: 1 ,display: "flex", justifyContent: "space-between" }}>
                        <Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
                            <Grid item xs={6}>
                                <Typography sx={{ fontSize: 14, color: "#00000F" }}>
                                    Name
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ fontSize: 14, color: "#00000F",
                                    "&:hover": {
                                        whiteSpace: "pre-line",
                                    },
                                 }} noWrap>
                                    : {action?.action_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                                    Description
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ fontSize: 14, color: "#777680",
                                    "&:hover": {
                                        whiteSpace: "pre-line",
                                    },
                                 }} noWrap>
                                    : {action?.action_description}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                                    Note
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ fontSize: 14, color: "#777680",
                                    "&:hover": {
                                        whiteSpace: "pre-line",
                                    },
                                 }} noWrap>
                                    : {action?.action_note}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                                    Key
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ fontSize: 14, color: "#777680", 
                                   "&:hover": {
                                        whiteSpace: "pre-line",
                                    },
                                }}
                                 noWrap
                                >
                                    : {action?.action_key}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ fontSize: 14, color: "#777680" }}>
                                    Metadata
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ color: "#777680" }}>
                                    {renderTextValue(action?.metadata)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <CircleIcon 
                            sx={{color: action?.action_status === "active" ? "green" : "red",
                              ml: -3,
                              mt: -2
                            }} 
                            size = "small"
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        />
                    </Box>
                </Paper>
                <Popover
                    id="mouse-over-popover"
                    sx={{ pointerEvents: 'none' }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography sx={{ p: 1 }}>{action?.action_status}</Typography>
                </Popover>
                {
                showDialogCreatePlayBookAction && <PlaybookActionEdit
                    open={showDialogCreatePlayBookAction}
                    handleClose={() => setShowDialogCreatePlayBookAction(false)}
                    playbook={playbook}
                    refetch={refetch}
                    action={action}
                />
                }
            </>
            }
        </>
    );
};

export default PlaybookActionDetail;
