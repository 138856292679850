import { Chip, Grid, Paper, Typography, Link } from "@mui/material";
import React from "react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import WarehouseModelApi from "@/apis/beta/Warehouse/WarehouseModelApi";
import { format_date_short, getMuiDataGridRowHeight } from "@/functions";
import CellConfig from "@components/DataGrid/CellConfig2";
import Cell from "@components/DataGrid/Cell";
import DataGridProCustomize from "@components/DataGridProCustomize";
import FormWarehouseItemDepreciate from "../FormWarehouseItemDepreciate";

const TableWarehouseItemTransfer = ({
  wpItemsJson,
  warehouse_process,
  callbackProcess,
}) => {
  const { data: warehouse_models } = WarehouseModelApi.All();
  const [modal, setModal] = React.useState({ show: false });

  const reFormatData = React.useCallback(() => {
    let newData = [];
    wpItemsJson?.forEach((wpItem, index) => {
      newData.push({
        ...wpItem,
        index: index,
        id: index,
        warehouse_model_id: wpItem.warehouse_model.warehouse_model_id,
        warehouse_item_group: [`${index + 1}`],
      });
      if (wpItem?.children) {
        wpItem?.children?.forEach((ele, index2) => {
          try {
            newData.push({
              ...ele,
              id: `${index}?.${index2}`,
              warehouse_item_group: [
                `${index + 1}`,
                `${index + 1}.${index2 + 1}`,
              ],
              warehouse_box_item: {
                ...wpItem.warehouse_box_item,
                warehouse_box_item_description: "",
              },
              warehouse_item_form_factor: wpItem.warehouse_item_form_factor,
              warehouse_item_box_old: wpItem.warehouse_item_box_old,
              warehouse_rack: wpItem.warehouse_rack,
              warehouse_model_id: ele.warehouse_model_id,
            });
          } catch (exception) {
            console.log("errors", exception);
            return;
          }
        });
      }
    });
    return newData;
  }, [wpItemsJson]);

  const onClickItem = (row) => {
    setModal({ show: true, data: row });
  };

  const columns = [
    {
      field: "depreciation",
      headerName: "Thời gian khấu hao",
      renderCell: ({ row, value }) => (
        <>
          <Chip
            label={
              row.depreciation && row.depreciation?.depreciation_unit > 0
                ? format_date_short(row?.depreciation?.depreciation_end)
                : "Chưa có"
            }
            color={
              row.depreciation && row.depreciation?.depreciation_unit > 0
                ? "primary"
                : "error"
            }
            onClick={() => onClickItem(row)}
          />
        </>
      ),
      width: 250,
    },
    {
      field: "warehouse_item_type",
      valueGetter: ({ row }) =>
        _.find(warehouse_models, {
          warehouse_model_id: parseInt(row.warehouse_model_id),
        })?.warehouse_type?.warehouse_type_name,
      headerName: "Loại",
      width: 300,
    },
    {
      field: "warehouse_item_name",
      headerName: "Model",
      renderCell: ({ row, value }) => (
        <Link
          component={NavLink}
          to={`/warehouse/items/${row.warehouse_item_id}/detail`}
        >
          <Typography className="react-link-table">{value}</Typography>
        </Link>
      ),
      width: 300,
    },
    {
      field: "total",
      headerName: "Số lượng",
      valueGetter: ({ value }) => (value ? value : 1),
      width: 150,
    },
    {
      field: "warehouse_item_service_tag",
      headerName: "Service_Tag/ Series Number",
      renderCell: ({ value }) =>
        value && <Chip label={value} title={value} color="primary" />,
      width: 300,
    },
    {
      field: "capacity",
      headerName: "Cấu Hình",
      width: 250,
      renderCell: ({ value }) => {
        if (value) {
          return <CellConfig configures={value} />;
        } else {
          <></>;
        }
      },
    },
    {
      field: "rack",
      headerName: "Đến Tủ Rack",
      width: 250,
      valueGetter: ({ row }) => {
        if (
          row.warehouse_box_item.warehouse_box_item_reference_type ===
          "WarehouseRack"
        ) {
          return row.warehouse_box_item.warehouse_box_rack_name;
        } else {
          return "";
        }
      },
      renderCell: ({ value }) => <>{value}</>,
    },
    {
      field: "position",
      headerName: "Đến Vị Trí",
      width: 250,
      valueGetter: ({ row }) => {
        if (
          row.warehouse_item_service_tag &&
          row.warehouse_box_item &&
          row.warehouse_box_item.warehouse_box_item_reference_type ===
          "WarehouseRack"
        ) {
          return `[${row.warehouse_box_item?.warehouse_box_item_position +
            ([0, 1].includes(row.warehouse_item_form_factor)
              ? ""
              : ` - ${row.warehouse_box_item?.warehouse_box_item_position +
              (row.warehouse_item_form_factor - 1)
              }`)
            }]`;
        }
        return "";
      },
      renderCell: ({ value }) => <>{value}</>,
    },
    {
      field: "warehouse_box_item_description",
      headerName: "Ghi Chú",
      valueGetter: ({ row }) =>
        row?.warehouse_box_item?.warehouse_box_item_description,
      renderCell: ({ value }) => <Cell content={value} />,
      width: 300,
    },
    {
      field: "warehouse_item_box_old",
      headerName: "Nơi Đi - Rack - Vị Trí Cũ",
      valueGetter: ({ row }) => {
        if (row.warehouse_rack) {
          const postion = `[${row.warehouse_item_box_old?.warehouse_box_item_position +
            ([0, 1].includes(row.warehouse_item_form_factor)
              ? ""
              : ` - ${row.warehouse_item_box_old?.warehouse_box_item_position +
              (row.warehouse_item_form_factor - 1)
              }`)
            }]`;
          return `${warehouse_process?.warehouse_process_from} - ${row.warehouse_rack.warehouse_rack_name} - ${postion}`;
        } else {
          return warehouse_process?.warehouse_process_from;
        }
      },
      renderCell: ({ value }) => <>{value}</>,
      width: 300,
    },
  ];

  return (
    <>
      <Paper sx={{ p: 3, mb: 3, mt: 2 }}>
        <Grid container spacing={2}>
          {wpItemsJson?.length > 0 && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <DataGridProCustomize
                rows={reFormatData()}
                columns={columns}
                disabled={["Pagination", "Toolbar"]}
                treeData
                getTreeDataPath={(row) => row?.warehouse_item_group}
                getRowHeight={(params) =>
                  getMuiDataGridRowHeight(
                    params?.model?.capacity,
                    [
                      "capacity_cpu",
                      "capacity_ram",
                      "capacity_storage",
                      "capacity_power_supply",
                    ],
                    2
                  )
                }
              />
            </Grid>
          )}
        </Grid>

        {modal.show && (
          <FormWarehouseItemDepreciate
            warehouse_process={warehouse_process}
            callbackData={callbackProcess}
            modal={modal}
            setModal={setModal}
          />
        )}
      </Paper>
    </>
  );
};

export default TableWarehouseItemTransfer;
