import JourneyUpdateApi from "@/apis/beta/Customer/Journey/JourneyUpdateApi";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import JourneyUpdateCreate from "./Create";
import JourneyUpdateList from "./List";

const JourneyUpdates = ({ journey, mode = "edit" }) => {
  const { customer_id: customerId } = useParams();

  const [pageSize, setPageSize] = useState(3);

  const { data, isFetching } = JourneyUpdateApi.List({
    customerId,
    journeyId: journey?.customer_journey_id,
    pageSize: pageSize,
  });
  console.log(data);
  return (
    <Fragment>
      {mode !== "view" && (
        <JourneyUpdateCreate
          journey={journey}
          customerId={customerId}
          journeyId={journey?.customer_journey_id}
        />
      )}
      <JourneyUpdateList
        setPageSize={setPageSize}
        journeyUpdates={data}
        isFetching={isFetching}
        customerId={customerId}
        journeyId={journey?.customer_journey_id}
      />
    </Fragment>
  );
};

export default withSuspense(JourneyUpdates, MuiSkeleton["Loading"]);
