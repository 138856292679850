import { encodeBase64, requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_WAREHOUSE_V1_URL } from "@/libs/constants";

class InvestmentApi {
  index = ({ query = null, searchQuery = null, page = 1, pageSize = 20 }) =>
    useQueryWithCustomized(
      ["warehouse.investments.list", query, searchQuery, page, pageSize],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/investments?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${searchQuery}` : ""
          }&page=${page}&page_size=${pageSize}`
        )
          .then(({ data }) => {
            if (data?.error) return data;

            return {
              investments: data?.data?.investments.map((o) => ({
                ...o,
                id: o.investment_id,
              })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  getAll = () =>
    useQueryWithCustomized(
      ["warehouse.investments.all"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/investments/list`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.investments?.map((o) => ({
              value: o.investment_id,
              label: o.investment_name,
              ...o,
            }));
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );
  show = (id, callback) =>
    useQueryWithCustomized(
      ["warehouse.investments.detail", id],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/investments/${id}`
        )
          .then(({ data }) => {
            if (data?.errors) return callback(data.errors);

            return {
              investment: data?.data?.investment,
            };
          })
          .catch((error) => {
            return {
              warehouse_rack: null,// ???
            };
          });
      },
      {
        staleTime: 10 * 60 * 1000,
        notifyOnChangePropsExclusions: ["isStale"],
        suspense: true,
      }
    );
  create = (params) =>
    requestWithToken(`${API_WAREHOUSE_V1_URL}/investments`, "POST", {
      investment: params,
    });
  update = (id, params) =>
    requestWithToken(`${API_WAREHOUSE_V1_URL}/investments/${id}`, "PUT", {
      investment: params,
    });

  resourceServices = ({
    investment_id,
    query = null,
    searchQuery = null,
    page = 1,
    pageSize = 20,
  }) =>
    useQueryWithCustomized(
      [
        "warehouse.investments.resource.list",
        investment_id,
        query,
        searchQuery,
        page,
        pageSize,
      ],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/investments/${investment_id}/resource_services?${query ? `&filters=${encodeBase64(query)}` : ""
          }${searchQuery ? `&queries=${searchQuery}` : ""
          }&page=${page}&page_size=${pageSize}`
        )
          .then(({ data }) => {
            if (data?.error) return data;

            return {
              warehouse_resource_services:
                data?.data?.warehouse_resource_services.map((o) => ({
                  ...o,
                  id: o.warehouse_resource_service_id,
                })),
              pagination: data?.data?.pagination,
            };
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );

  overview = () =>
    useQueryWithCustomized(
      ["warehouse.investments.overview"],
      async () => {
        return await requestWithToken(
          `${API_WAREHOUSE_V1_URL}/investments/overview`
        )
          .then(({ data }) => {
            if (data?.error) return data;
            return data?.data?.resource_services;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        notifyOnChangePropsExclusions: ["isStale"],
      }
    );
}

export default InvestmentApi = new InvestmentApi();
