import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import { getMuiDataGridRowHeight } from "@/functions";
import Cell from "@components/DataGrid/Cell";
import CellConfig from "@components/DataGrid/CellConfig2";
import DataGridProCustomize from "@components/DataGridProCustomize";
import PopperAction from "@components/PopperAction";
import { useConfirmContext } from "@contexts/confirm";
import { MoreVertOutlined } from "@mui/icons-material";
import { Box, IconButton, Popper } from "@mui/material";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import SelectPosition from "./SelectPosition";
import SelectRacks from "./SelectRacks";

const TableTransfer = ({
  warehouse_process_metadata,
  warehouse_process_to,
  handelDeleteItem,
}) => {
  const params = useParams();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: params?.id
      ? {
        ...warehouse_process_metadata?.reduce((final, item) => {
          final[`position-${item?.warehouse_item_id}`] =
            item?.warehouse_box_item?.warehouse_box_item_position;
          final[`rack-${item?.warehouse_item_id}`] =
            item?.warehouse_box_item?.warehouse_rack?.warehouse_rack_id;

          return final;
        }, {}),
      }
      : {},
  });

  const { data: racks, isLoading: racksLoading } = WarehouseBoxApi.racks(
    warehouse_process_to?.value
  );

  const { showConfirmation } = useConfirmContext();

  const [popper, setPopper] = useState({
    anchorEl: null,
    data: null,
  });

  const actions = [
    {
      order: 2,
      name: "delete",
      icon: "Delete",
      label: "Xoá",
      handleClick: (data) => {
        showConfirmation({
          title: "Bạn có chắc muốn xoá linh kiện này ra khỏi thiết bị",
          open: true,
          description: "Cảnh báo trước khi xoá",
          loading: false,
          handler: () => handelDeleteItem(data?.id),
        });
      },
    },
  ];

  const reFormatData = useMemo(() => {
    return warehouse_process_metadata?.length > 0
      ? warehouse_process_metadata.map((value) => {
        const item = value;
        item.total = item.total ? item.total : 1;
        item.warehouse_box_item.warehouse_box_item_reference_type =
          warehouse_process_to?.type || "WarehouseBox";
        if (warehouse_process_to?.type === "WarehouseBox") {
          item.warehouse_box_item.warehouse_box_item_reference_id =
            warehouse_process_to?.value;
          item.warehouse_box_item.warehouse_box_item_position = 0;
        }
        return item;
      })
      : [];
  }, [warehouse_process_metadata, warehouse_process_to]);

  const initialColumns = [
    {
      field: "warehouse_item_id",
      headerName: "Action",
      width: 80,
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              title="Thao Tác"
              onClick={(event) => {
                setPopper((prev) => ({
                  ...prev,
                  anchorEl: event?.target,
                  data: row,
                }));
              }}
            >
              <MoreVertOutlined sx={{ m: "auto" }} />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "warehouse_item_name",
      headerName: "Model",
      width: 250,
      renderCell: ({ value }) => <Cell content={value.toUpperCase()} />,
    },
    {
      field: "warehouse_item_service_tag",
      headerName: "Serial Number",
      width: 250,
      renderCell: ({ value }) => <Cell content={value.toUpperCase()} />,
    },
    {
      field: "total",
      headerName: "Số lượng",
      valueGetter: ({ value }) => (value ? value : 1),
      width: 100,
    },
    {
      field: "capacity",
      headerName: "Capacity",
      width: 250,
      renderCell: ({ value }) => <CellConfig configures={value} />,
    },
    {
      field: "box_rack_new",
      headerName: "Tủ Rack",
      width: 250,
      renderCell: ({ row }) => {
        if (
          row?.warehouse_box_item?.warehouse_box_item_reference_type ===
          "WarehouseRack"
        ) {
          return (
            <SelectRacks
              errors={errors}
              control={control}
              racks={racks}
              racksLoading={racksLoading}
              row={row}
              setValuePosition={(value) =>
                setValue(`position-${row?.warehouse_item_id}`, value)
              }
            />
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: "position_new",
      headerName: "Vị Trí",
      width: 300,
      renderCell: ({ row }) => {
        if (
          row?.warehouse_box_item?.warehouse_box_item_reference_type ===
          "WarehouseRack"
        ) {
          return (
            <SelectPosition
              watch={watch}
              control={control}
              row={row}
              racks={racks}
              errors={errors}
            />
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  return (
    <>
      <DataGridProCustomize
        sx={{ mt: 2 }}
        rows={reFormatData}
        columns={initialColumns}
        disabled={["Pagination", "Toolbar"]}
        getRowHeight={(params) =>
          getMuiDataGridRowHeight(
            params?.model?.capacity,
            [
              "capacity_cpu",
              "capacity_ram",
              "capacity_storage",
              "capacity_power_supply",
            ],
            2
          )
        }
      />

      {popper?.anchorEl !== undefined && (
        <Popper anchorEl={popper?.anchorEl} open={Boolean(popper.anchorEl)}>
          <PopperAction
            {...popper}
            actions={actions}
            handleClose={() =>
              setPopper((prev) => ({ ...prev, anchorEl: undefined }))
            }
          />
        </Popper>
      )}
    </>
  );
};

export default TableTransfer;
