import InvestmentApi from "@/apis/beta/Warehouse/InvestmentApi";
import WarehouseRackApi from "@/apis/beta/Warehouse/WarehouseRackApi";
import { format_date_short, getDataGridRowHeight, getRacks } from "@/functions";
import useDebounce from "@/hooks/useDebounce";
import React from "react";
import { useNavigate } from "react-router";
import _ from "lodash";
import CellSpecification from "@components/DataGrid/CellSpecification";
import Cell from "@components/DataGrid/Cell";
import { Box, Button, Drawer, Link } from "@mui/material";
import DataGridCustomize from "@components/DataGrid";
import { NavLink } from "react-router-dom";
import { format_pricing_vnd } from "@/libs/constants";
import { AddOutlined } from "@mui/icons-material";
import Create from "../Create"
import MuiSkeleton from "@components/shared/MuiSkeleton";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
const queryString = require("query-string");

const List = () => {

    const navigate = useNavigate();

    const url_params = queryString.parse(window.location.search, {
        skipNull: true,
        arrayFormat: 'bracket',
        skipEmptyString: true,
    })

    const [urlParams, setUrlParams] = React.useState(
        {
            page: 1,
            page_size: 20,
            ...url_params,
        }
    )
    const { data: warehouse_racks } = WarehouseRackApi.getAll();

    const [searchQuery, setSearchQuery] = React.useState('')
    const debouncedSearchQuery = useDebounce(searchQuery, 1500)
    const filterableSearch = React.useCallback(() => {
        if (!debouncedSearchQuery || debouncedSearchQuery?.trim() === '') return '';
        return debouncedSearchQuery.trim()
    }, [debouncedSearchQuery])

    const { data, refetch, isFetching } = InvestmentApi.index({
        page: urlParams?.page || 1,
        pageSize: urlParams?.page_size || 20,
        query: "",
        searchQuery: filterableSearch() !== '' ? filterableSearch() : null
    })


    React.useEffect(() => {
        navigate(`?page=${urlParams?.page}&page_size=${urlParams?.page_size}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams])

    const initialColumns = [
        {
            field: 'investment_name',
            headerName: 'Tên Cụm Đầu Tư',
            width: 200,
            renderCell: ({ row, value }) => (
                <Link component={NavLink} to={`/warehouse/investments/${row.investment_id}`} > <Cell content={value.toUpperCase()} /></Link>
            )
        },
        {
            field: 'investment_status',
            headerName: 'Trình Trạng Đầu Tư',
            width: 200,
            valueGetter: ({ value }) => value === "active" ? "Hoạt Động" : "Hết Hạn",

        },
        {
            field: 'investment_start',
            headerName: 'Thời Gian Bắt Đầu',
            valueGetter: ({ row }) => format_date_short(row.investment_start),
            width: 200,

        },
        {
            field: 'investment_end',
            headerName: 'Thời Gian Hết Hạn đầu',
            valueGetter: ({ row }) => format_date_short(row.investment_end),
            width: 200,
        },
        {
            field: 'investment_month',
            headerName: 'Số tháng',
            width: 150,
            valueGetter: ({ row }) => {
                const from = new Date(row.investment_start);
                let to = new Date(row.investment_end);
                to.setDate(to.getDate() + 1);

                const months =
                    to.getMonth() -
                    from.getMonth() +
                    12 * (to.getFullYear() - from.getFullYear());
                return months;
            }
        },
        {
            field: 'investment_price',
            headerName: 'Tiền Đầu Tư',
            width: 200,
            valueGetter: ({ row }) => format_pricing_vnd(_.sumBy(row.warehouse_resource_services, o => o.warehouse_resource_service_price) + row.investment_cost_other),
        },
        {
            field: 'devices',
            headerName: 'Số thiết bị',
            valueGetter: ({ row }) => _.uniq(_.flatten(row.warehouse_resource_services.map(o => o.warehouse_resource_service_metadata))).length,
            width: 150,
        },
        {
            field: 'investment_depreciation',
            headerName: 'Khấu Hao Đầu Tư',
            width: 200,
        },
        {
            field: 'investment_service',
            headerName: 'Dịch Vụ Chạy Trong Cụm',
            valueGetter: ({ row }) => {
                return _.uniq(row?.warehouse_resource_services).filter(o => o["warehouse_resource_service_status"] === "active").map(o => (
                    { label: `${o.warehouse_resource_service_name}`, value: "", unit: "" }
                ))
            },
            renderCell: ({ value }) => (value && <CellSpecification items={_.uniqBy(value, o => o.label)} />),
            width: 200,
        },
        {
            field: 'racks',
            width: 300,
            headerName: 'Tủ Rack - IDC',
            valueGetter: ({ row }) => {
                return getRacks(row.warehouse_items, warehouse_racks)
            },
            renderCell: ({ value }) => (value && <CellSpecification items={_.uniqBy(value, o => o.label)} />),
        },
        {
            field: 'investment_description',
            headerName: 'Ghi Chú',
            width: 200,
            renderCell: ({ value }) => <Cell content={value.toUpperCase()} />,
        },

    ]

    const [openDrawer, setOpenDrawer] = React.useState(false)


    return (
        <Box>
            <React.Suspense>
                {openDrawer &&
                    <Drawer
                        anchor="right"
                        open={openDrawer}
                        sx={{ width: 600 }}
                        onClose={() => setOpenDrawer(false)}
                    >
                        <Create refetchData={refetch} handleClose={() => setOpenDrawer(false)} />
                    </Drawer>}
            </React.Suspense>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, mt: 2 }}>
                <Button variant='contained'
                    size='small'
                    sx={{
                        height: { xs: 36, md: 42 },
                        m: 'auto 0'
                    }}
                    disabled={openDrawer}
                    onClick={() => setOpenDrawer(true)}
                    startIcon={<AddOutlined />}
                >
                    Tạo Đầu Tư
                </Button>
            </Box>
            <DataGridCustomize
                rows={data?.investments || []}
                columns={initialColumns}
                loading={isFetching}
                getRowHeight={(params) => {
                    return getDataGridRowHeight([_.uniq(params.model?.warehouse_items.map(o => o.warehouse_box_item_reference_id)), [{}, {}]], {})
                }}
                componentsProps={{
                    toolbar: {
                        store: "warehouse.investment.list",
                        initialColumns: initialColumns,
                        feature: 'investment',
                        actions: [

                        ],
                        inputSearch: {
                            placeholder: "Nhập tên cụm đầu tư",
                            value: searchQuery,
                            onChange: setSearchQuery
                        }
                    },
                    pagination: {
                        page: urlParams?.page - 1 || 0,
                        pageSize: urlParams?.page_size || 20,
                        rowsPerPageOptions: [20, 50, 100],
                        onPageChange: (newPage) => {
                            setUrlParams(prevState => ({ ...prevState, page: newPage + 1 }))
                        },
                        onPageSizeChange: (newPageSize) => setUrlParams(prevState => ({ ...prevState, page_size: newPageSize, page: 1 }))
                    }
                }}
                rowCount={data?.pagination?.total}
            />
        </Box>
    )
}
export default withSuspense(withPermission(List, {
    feature: "warehouse",
    action: "investment"
}), MuiSkeleton["DataGrid"]) 