import MuiScrollTabs from "@components/shared/MuiScrollTabs";
import React from "react";
import { DesignServicesOutlined, DisplaySettingsOutlined } from "@mui/icons-material";

const Header = () => {
    const initialTabs = [
        {
            value: "warehouse-item-server",
            label: "Thiết Bị",
            href: 'devices',
            icon: DisplaySettingsOutlined
        },
        {
            value: "warehouse-item-processor",
            label: "Linh Kiện & Khác",
            href: 'others',
            icon: DesignServicesOutlined
        },
    ]
    return (
        <MuiScrollTabs prefix={`warehouse/report-items`}
                                    initialTabs={initialTabs}
                                />
    )
}
export default Header;