import { format_date_short } from "@/functions";
import { vestResolver } from "@hookform/resolvers/vest";
import { Box, Button, Collapse, Paper, Typography } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ServiceTemplates from "./modules";
import Product from "./shared/Product";
import ProductGroup from "./shared/ProductGroup";
import serviceValidator from "./validators";

const PRIVAT_TEMPLATES = ["CloudService", "VnisService"]

const ServiceForm = ({
  customerId = -1,
  orderredProductIds = [],
  type = "create",
  saver,
  closer,
  defaultValues = {},
  isPoc = false,
}) => {
  const [product, setProduct] = useState({
    product_group_id: defaultValues?.product_group_id,
    product_group_name: defaultValues?.product_group_name,
    product_id: defaultValues?.product_id,
    product_name: defaultValues?.product_name,
  });

  const methods = useForm({
    reValidateMode: "onSubmit",
    resolver: isPoc
      ? undefined
      : vestResolver((props) =>
        serviceValidator({
          ...props,
          orderredProductIds: orderredProductIds,
          method: type,
        })
      ),
    defaultValues: { ...defaultValues },
  });

  const onChangeProductGroup = (newProductGroup) => {
    switch (newProductGroup?.product_group_id) {
      case 15:
        methods.setValue('product_template', "VnisService")
        break;
      case 22:
        methods.setValue('product_template', "CloudService")
        break;
      default:
        methods.setValue('product_template', "Blank")
        break;
    }
    setProduct((prev) => ({
      ...prev,
      product_group_id: newProductGroup?.product_group_id,
      product_group_name: newProductGroup?.product_group_name,
    }));
  };

  const serviceTemplate = (template) => {
    const componentProps = {
      isPoc,
      customerId,
      product,
      closer,
      saver
    };
    const Component = ServiceTemplates[template] || ServiceTemplates["Blank"];
    if (!Component) return ServiceTemplates["Blank"];
    return <Component {...componentProps} />;
  };
  const rebuildProduct = (metadata = {}) => {
    let form = _.pick(metadata, [
      "product_id",
      "product_name",
      "product_group_id",
      "product_group_name",
      "product_payment_type",
      "product_type",
      "product_template",
      "service_extra_fields",
      "product_pricing_amount",
      "product_pricing_init",
      "product_addons",
      "product_paid",
    ]);
    form.product_addon_mergebill = !form.product_addon_mergebill;
    // perform date of bill
    form.service_management_billing = {
      service_billing_startdate: format_date_short(
        metadata?.service_management_billing?.service_billing_startdate?.$d ||
        metadata?.service_management_billing?.service_billing_startdate
      ),
      service_billing_enddate: format_date_short(
        metadata?.service_management_billing?.service_billing_enddate?.$d
      ),
      service_nextbill_startdate: format_date_short(
        metadata?.service_management_billing?.service_nextbill_startdate?.$d
      ),
      service_nextbill_enddate: format_date_short(
        metadata?.service_management_billing?.service_nextbill_enddate?.$d
      ),
      service_control_startdate: format_date_short(
        metadata?.service_management_billing?.service_control_startdate?.$d
      ),
      service_control_enddate: format_date_short(
        metadata?.service_management_billing?.service_control_enddate?.$d
      ),
    };
    if (metadata?.product_template?.match(/(SwiftFederation)/)) {
      form.product_configoptions = metadata?.product_configoptions || [];
    }
    if (metadata?.product_template?.match(/(SwiftFederation.Default)/)) {
      form.product_pricing_configures = metadata?.product_pricing_configures;
    }
    if (!metadata?.product_template?.match(/(SwiftFederation|Domain)/)) {
      form.product_informations = metadata?.product_informations || [];
    }
    if (metadata?.product_template?.match(/(Domain)/)) {
      form.product_billing_cycle = metadata?.product_billing_cycle;
      form.product_metadata = {
        domain: {
          ...metadata?.domain,
          domain_registrationperiod: metadata?.product_billing_cycle,
        },
      };
    }
    return form;
  };

  const onSubmit = (values) => {
    let form = rebuildProduct(values);
    if (type === "update") {
      form.index = values.index;
    }
    return saver(form);
  };

  return (
    <FormProvider {...methods}>
      <Paper component="form" sx={{ p: 3 }} elevation={8}>
        <Typography
          sx={{
            fontWeight: "medium",
            textTransform: "capitalize",
            mb: 1.5,
          }}
        >
          Thêm sản phẩm/ dịch vụ
        </Typography>
        {customerId !== -1 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              mb: 2,
            }}
          >
            <ProductGroup
              isPoc={isPoc}
              customerId={customerId}
              disabled={type !== "create"}
              onChangeProductGroup={onChangeProductGroup}
            />
            {!PRIVAT_TEMPLATES?.includes(methods.watch(`product_template`)) && <Product
              disabled={type !== "create"}
              product={product}
              setProduct={setProduct}
            />}
          </Box>
        )}
        <Collapse in={!!methods.watch(`product_template`)}>
          {serviceTemplate(methods.watch(`product_template`))}
        </Collapse>
        {!PRIVAT_TEMPLATES?.includes(methods.watch(`product_template`)) && <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: { xs: "column", md: "row" },
            gap: 1.5,
            mt: 3,
          }}
        >
          <Button
            size="small"
            variant="text"
            sx={{ height: 36 }}
            onClick={closer}
          >
            Huỷ Thao Tác
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={methods.handleSubmit(onSubmit)}
            sx={{ height: 36, minWidth: 150 }}
          >
            {type === "create" ? "Thêm Sản Phẩm" : "Cập nhật"}
          </Button>
        </Box>}
      </Paper>
    </FormProvider>
  );
};

export default ServiceForm;
