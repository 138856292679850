import { format_number_usage, format_numeral_price } from '@/functions'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'

const ServicePriceExceed = ({
    product
}) => {
    if (product?.service_price_exceeds?.length === 1) {
        return format_numeral_price(product?.service_price_exceeds?.[0]?.pricing)
    }
    return (
        <Table size='small' sx={{
            minWidth: { xs: '100%', md: '100%' },
            border: '1px solid #333',
            borderBottomColor: '#333',
        }} aria-label="product-vnis-addon-prices">
            <TableBody>
                {product?.service_price_exceeds?.map((price_exceed, index) => (
                    <TableRow key={index}>
                        <TableCell component="th" scope="row" style={{
                            fontSize: 13,
                            fontWeight: 500
                        }}>
                            {index === 0 ? product?.payment_config_method === 'tierd' ? 'Theo Thoả Thuận' : 'Luỹ Tiến' : ''}
                        </TableCell>
                        <TableCell component="th" scope="row" style={{ fontSize: 13 }}>
                            {format_number_usage(price_exceed?.range_start)}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: 13 }}>
                            {format_number_usage(price_exceed?.range_end)}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: 13 }}>
                            {format_numeral_price(price_exceed?.pricing)}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default ServicePriceExceed