import BwDatacenter from "./Datacenter";
import BwDatacenterCreate from "./Datacenter/Create";
import BwDatacenterDetail from "./Datacenter/Detail";
import BwCluster from "./Cluster";
import BwClusterCreate from "./Cluster/Create";
import BwClusterDetail from "./Cluster/Detail";
import BwRack from "./Rack";
import BwRackCreate from "./Rack/Create";
import BwRackDetail from "./Rack/Detail";


export default {
    BwDatacenter,
    BwDatacenterCreate,
    BwDatacenterDetail,
    BwCluster,
    BwClusterCreate,
    BwClusterDetail,
    BwRack,
    BwRackCreate,
    BwRackDetail
}