import TicketApi from "@/apis/beta/Ticket/TicketApi";
import { Box, CircularProgress, Button } from "@mui/material";
import React, { useRef, useState } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { useParams } from "react-router";
import { InView } from "react-intersection-observer";
// import Form from "./Form";
import CommentItem from "./CommentItem";
import Editor from "@components/Editor";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import { blobToFile, onAttachmentPushing } from "@/functions";
// import FileApi from "@/apis/shared/File/FileApi";
import { PREFIX_OF_ATTACHMENT, REACT_APP_DOMAIN_FILE } from "@/libs/constants";
import { useNotificationContext } from "@contexts/notification";

const Comments = () => {
  const { id } = useParams();
  let [commentText, setCommentText] = useState("");
  const queryClient = useQueryClient();

  const editorRef = useRef()

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      [`ticket.comments.${id}`],
      (pageParams) => {
        return TicketApi.Comments({
          ticket_id: id,
          pageSize: 10,
          page: pageParams.pageParam || 1,
        });
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage?.pagination?.page_next;
        },
      }
    );

  const { setNotification } = useNotificationContext()

  const {
    mutateAsync,
    isLoading: addCommentLoading,
    notify,
  } = useMutationWithNotification((e) => handleAddComment(e));

  const handleAddComment = async (e) => {
    e.preventDefault();

    if (!commentText?.trim()) return;

    const listBlobImage = await editorRef?.current?.getBlobImage()

    if (listBlobImage?.length > 0) {
      const results = await Promise.all(listBlobImage?.map(async (item) => {
        const file = await blobToFile(item, `image_comment_${Date.now()}`)
        const response = await onAttachmentPushing(file, PREFIX_OF_ATTACHMENT.ticket, setNotification)

        return {
          blobURL: item,
          pathImageServer: `${REACT_APP_DOMAIN_FILE}${response?.attachment_file_url}`
        }
      }))

      results.forEach(item => {
        commentText = commentText.replace(item?.blobURL, item?.pathImageServer)
      })

      editorRef?.current?.clearBlobImage()
    }

    const response = await TicketApi.AddComment({
      ticket_id: id,
      values: {
        ticket_comment_text: commentText,
        ticket_comment_pinted: false,
      },
      mailNotification: [],
    });

    if (!response?.errors) {
      notify("success", "Success");
      queryClient.refetchQueries([`ticket.comments.${id}`]);
      setCommentText("");
    }
  };

  if (isLoading)
    return (
      <Box p={2}>
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      <Box onSubmit={mutateAsync} component="form">
        <Editor
          ref={editorRef}
          model={commentText}
          onModelChange={(value) => setCommentText(value)}
        />
        <Box textAlign="right">
          <Button
            startIcon={addCommentLoading && <CircularProgress size={14} />}
            disabled={addCommentLoading}
            type="submit"
            size="small"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Add Process
          </Button>
        </Box>
      </Box>

      <Box maxHeight={500} mt={2} overflow={"auto"}>
        {data?.pages
          ?.reduce((final, item) => {
            final.push(...item?.ticket_comments);
            return final;
          }, [])
          ?.map((item) => (
            <CommentItem key={item?.ticket_comment_id} item={item} />
          ))}

        <InView
          fallbackInView
          onChange={(InVidew) => {
            if (InVidew && hasNextPage && !isFetchingNextPage) {
              fetchNextPage();
            }
          }}
        >
          {({ ref }) => (
            <Box
              ref={ref}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ pb: 1 }}
            >
              {isFetchingNextPage && (
                <CircularProgress size={25} sx={{ mt: 2 }} />
              )}
            </Box>
          )}
        </InView>
      </Box>
    </Box>
  );
};

export default Comments;
