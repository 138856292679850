import { encodeBase64 } from "@/functions";
import useFilters, { Filter } from "@/hooks/useFilters";
import { INITIAL_OPERATORS } from "@/libs/constants";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { Box, Button, Paper } from "@mui/material";
import React, { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonExports from "./ButtonExports";
import ChipCustomize from "./ChipCustomize";
import ConditionGroupButton from "./ConditionGroupButton";
// import FilterFlex from "./FilterFlex";
import PopperPopupState from "./PopperPopupState";

const DataGridFilter = React.memo(({ callback, filters }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const keyLocalStorage = filters?.key;
  const [query, updateQuery] = useFilters(filters?.key, location);

  const handleAddQuery = (hash) => {
    const filter = Object.assign(query[hash?.condition] || {}, {});
    filter[Object.keys(filter).length] = hash.filter;
    const filters = { ...query, [hash?.condition]: filter };
    navigate({
      pathname: location?.pathname,
      search: Filter.stringify(filters).toString(),
    });
    localStorage.setItem(
      keyLocalStorage,
      encodeBase64(Filter.stringify(filters))
    );

    return [updateQuery(filters), callback(filters)];
  };

  const handleAddMultiQuery = (queries = [], skip = false) => {
    let filters = { ...query };
    queries?.forEach((hash) => {
      const filter = Object.assign(query[hash?.condition] || {}, {});
      filter[Object.keys(filter).length] = hash.filter;
      filters = { ...filters, [hash?.condition]: filter };
    });
    navigate({
      pathname: location?.pathname,
      search: Filter.stringify(filters).toString(),
    });
    localStorage.setItem(
      keyLocalStorage,
      encodeBase64(Filter.stringify(filters))
    );
    try {
      if (!skip) {
        setAnchorElFilter(null);
      }
    } catch (error) {
      alert("DataGrid: " + JSON.stringify(error))
    }
    return [updateQuery(filters), callback(filters)];
  };

  const [anchorElFilter, setAnchorElFilter] = React.useState(null);

  const handleOpenPopper = (event) => {
    const { currentTarget } = event;
    setAnchorElFilter(currentTarget);
  };
  const handleClosePopper = () => setAnchorElFilter(null);

  const handleChangeConditionQuery = (filters) => {
    navigate({
      pathname: location?.pathname,
      search: Filter.stringify(filters).toString(),
    });
    localStorage.setItem(
      keyLocalStorage,
      encodeBase64(Filter.stringify(filters))
    );
    return [updateQuery(filters), callback(filters)];
  };

  const handleRemoveQuery = (filter, condition) => {
    const currentFilterKey = Object.keys(filter)[0];
    const currentFilter = Object.values(filter)[0];
    const operator = Object.keys(currentFilter)[0];
    const value = Object.values(currentFilter)[0];
    const currentConditionFilters = Object.values(query[condition]);
    const currentFilterDelIndex = currentConditionFilters?.findIndex(
      (ele) =>
        Object.keys(ele)[0] === currentFilterKey &&
        Object.keys(Object.values(ele)[0])[0] === operator &&
        Object.values(Object.values(ele)[0])[0] === value
    );
    if (currentFilterDelIndex <= -1) return;
    currentConditionFilters.splice(currentFilterDelIndex, 1);
    const filters = { ...query, [condition]: currentConditionFilters };
    navigate({
      pathname: location?.pathname,
      search: Filter.stringify(filters).toString(),
    });
    localStorage.setItem(
      keyLocalStorage,
      encodeBase64(Filter.stringify(filters))
    );
    return [updateQuery(filters), callback(filters)];
  };
  const hanldeCombineFilterQueries = React.useCallback(() => {
    let conditions = [];
    try {
      conditions =
        Object.values(Filter.parse(query.$and))?.map((cod) => ({
          ...cod,
          type: "$and",
          key: Object.keys(cod)[0],
        })) || [];
    } catch (error) {
      return console.log(error?.message);
    }

    try {
      const orConditions = Object.values(Filter.parse(query?.$or)) || [];
      for (let condition of orConditions) {
        let keys = Object.keys(condition);
        if (keys.length !== 1) return;
        const index = conditions?.map((cod) => cod?.key)?.lastIndexOf(keys[0]);
        if (index === -1) {
          conditions.push({ ...condition, type: "$or", key: keys[0] });
        } else {
          conditions = handleInsert(conditions, index, {
            ...condition,
            type: "$or",
            key: keys[0],
          });
        }
      }
    } catch (error) {
      return console.log(error?.message);
    }
    return conditions;
  }, [query]);

  const handleInsert = (arrays = [], index, item = {}) => {
    return [...arrays.slice(0, index + 1), item, ...arrays.slice(index + 1)];
  };

  const [filterQueries, setFilterQueries] = React.useState(
    hanldeCombineFilterQueries()
  );
  React.useEffect(() => {
    setFilterQueries(hanldeCombineFilterQueries());
  }, [hanldeCombineFilterQueries, query]);

  const ModuleExport =
    ButtonExports[filters?.exportFeature] || ButtonExports.Blank;

  // const [filterMode, setFilterMode] = useState("Basic");
  if (!filters?.filterables?.length) return;
  return (
    <Fragment>
      {/* <FilterFlex
        filterQueries={filterQueries}
        fields={filters?.filterables}
        query={{ query, updateQuery, callback, navigate, location, local: { key: localStorage } }}
      /> */}
      <Box mt={2}>
        <Paper
          sx={{
            p: 2,
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={1}>
            {filterQueries?.map((filter, index) => {
              try {
                const key = Object.keys(filter)[0];
                const attribute = filters?.filterables?.find(
                  (f) => f?.field === key
                );
                const currentFilter = Object.values(
                  Object.values(filter)[0]
                )[0];
                const currentOperator = Object.keys(
                  Object.values(filter)[0]
                )[0];

                return (
                  <React.Fragment key={index}>
                    {index !== 0 && (
                      <ConditionGroupButton
                        currentFilter={filter}
                        condition={filter?.type} // ["$and","$or"]
                        query={query}
                        handleChangeQuery={handleChangeConditionQuery}
                      />
                    )}
                    <ChipCustomize
                      handleDelete={() =>
                        handleRemoveQuery(filter, filter?.type)
                      }
                      filterAttribute={{
                        label: attribute?.label,
                        type: attribute?.operators?.type,
                        collections: attribute?.operators?.collections || [],
                        value: currentFilter,
                        filter: INITIAL_OPERATORS?.find(
                          (o) => o?.operator === currentOperator
                        ),
                      }}
                    />
                  </React.Fragment>
                );
              } catch (error) {
                return console.log(error?.message);
              }
            })}
            <Button
              startIcon={<FilterListOutlinedIcon />}
              onClick={(event) => handleOpenPopper(event)}
              variant="outlined"
              size="small"
              sx={{
                "&.MuiButton-root": {
                  height: 36,
                  m: "auto 0",
                },
              }}
            >
              Bộ Lọc
            </Button>
            {filters && filters?.exportFeature && <ModuleExport query={query} />}
            {anchorElFilter !== undefined && anchorElFilter !== null && (
              <PopperPopupState
                fields={filters?.filterables}
                anchorEl={anchorElFilter}
                handleClosePopper={handleClosePopper}
                handleAddQuery={handleAddQuery}
                handleAddMultiQuery={handleAddMultiQuery}
              />
            )}
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
});

export default DataGridFilter;
