import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class TicketRequestApi {
  ListTicketRequest = () => {
    return useQueryWithCustomized(["ticket_requests.all"], async () => {
      const response = await requestWithToken(
        `${API_V3_URL}/ticket_requests/list`
      );
      if (response?.data?.data) {
        return response?.data?.data?.ticket_requests;
      }
      return response?.data;
    });
  };

  CreateTicketRequest = async (ticket_request_title) => {
    const response = await requestWithToken(
      `${API_V3_URL}/ticket_requests/create_ticket_request`,
      "POST",
      { ticket_request_title }
    );

    return response?.data
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TicketRequestApi();
