import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Box, Chip, Paper, Typography } from "@mui/material";
import EditAlarm from "../../Dialog/Alarm/EditAlarm";
import DataInfo from "./DataInfo";
import AddWatches from "../../Dialog/Watches/AddWatches";
import AddChannel from "../../Dialog/Channel/AddChannel";
import AddSubscriber from "../../Dialog/Subscriber/AddSubscriber";
import ThresholdApi from "@/apis/beta/Threshold/ThresholdApi";
import { useNotificationContext } from "@contexts/notification";
import { useConfirmContext } from "@contexts/confirm";
import Loading from "@components/shared/MuiSkeleton/Loading";
import ElasticInfo from "./ElasticInfo";

const ThresholdDetail = () => {
  const { id } = useParams();

  const [showDialogEditAlarm, setShowDialogEditAlarm] = useState(false);
  const [showDialogWatches, setShowDialogWatches] = useState(false);
  const [showDialogChannels, setShowDialogChannels] = useState(false);
  const [showDialogSubscribers, setShowDialogSubscribers] = useState(false);
  const [idUpdateAlarm, setIdUpdateAlarm] = useState(null);
  const [currentAlarmId, setCurrentAlarmId] = useState(null);
  const [curentWatchId, setCurentWatchId] = useState(null);
  const [curentChannelId, setCurentChannelId] = useState(null);
  const [curentSubscribersId, setCurentSubscribersId] = useState(null);

  const { data, isLoading, refetch } = useQuery(`get-threshold-${id}`, () =>
    ThresholdApi.FindOneThreshold(id)
  );
  const { setNotification } = useNotificationContext()
  const { showConfirmation } = useConfirmContext();

  const data_threshold = data?.data?.threshold;
  const threshold_alarms = data?.data?.threshold_alarms;

  const { mutateAsync: DestroyAlarmMutation } = useMutation(
    ThresholdApi.DestroyAlarm,
    {
      onError: () => {
        setNotification({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      },
    }
  );

  const { mutateAsync: DestroyWatchMutation } = useMutation(
    ThresholdApi.DestroyWatch,
    {
      onError: () => {
        setNotification({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      },
    }
  );

  const { mutateAsync: DestroyChannelMutation } = useMutation(
    ThresholdApi.DestroyChannel,
    {
      onError: () => {
        setNotification({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      },
    }
  );

  const { mutateAsync: DestroySubscriberMutation } = useMutation(
    ThresholdApi.DestroySubscriber,
    {
      onError: () => {
        setNotification({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      },
    }
  );

  const handleResponseApi = (response) => {
    if (response?.errors) {
      return setNotification({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }

    setNotification({
      open: true,
      message: "Success",
      severity: "success",
    });

    refetch();
  };

  const handleDestroyAlarm = async (values) => {
    const response = await DestroyAlarmMutation(values);
    handleResponseApi(response);
  };

  const handleDestroyWatch = async ({ alarm_id, watch_id }) => {
    const response = await DestroyWatchMutation({ alarm_id, watch_id });
    handleResponseApi(response);
  };

  const handleDestroyChannel = async ({ alarm_id, channel_id }) => {
    const response = await DestroyChannelMutation({ alarm_id, channel_id });
    handleResponseApi(response);
  };

  const handleDestroySubscriber = async ({ channel_id, subscriber_id }) => {
    const response = await DestroySubscriberMutation({
      channel_id,
      subscriber_id,
    });
    handleResponseApi(response);
  };

  const handleResetState = () => {
    setIdUpdateAlarm(null);
    setCurrentAlarmId(null);
    setCurentWatchId(null);
    setCurentChannelId(null);
    setCurentSubscribersId(null);
  };

  if (isLoading && !data?.data?.threshold) return <Loading />;

  return (
    <Box p={1}>
      <DataInfo data={data_threshold} />
      {data_threshold?.threshold_scope === "OriginShield-RemoteAddress" &&
        <Paper sx={{ p: 3, mb: 1 }}>
          <ElasticInfo 
          data_threshold = {data_threshold}
          />
        </Paper>
      }
      <Paper sx={{ p: 3, mb: 1 }}>
        <Box
          onClick={() => {
            setIdUpdateAlarm(null);
            setShowDialogEditAlarm(true);
          }}
          display="flex"
          alignItems="center"
        >
          <Typography>Alarms</Typography>
          <Chip
            color="primary"
            sx={{ ml: 2, cursor: "pointer" }}
            label="Thêm Alarms"
          />
        </Box>
      </Paper>

      {threshold_alarms?.length > 0 && (
        <Box>
          {threshold_alarms?.map(({ alarm, watches, channels }) => (
            <Box key={alarm?.alarm_id}>
              <DataInfo data={alarm}>
                <Box display="flex" alignItems="center" mt={2}>
                  <Chip
                    onClick={() => {
                      setIdUpdateAlarm(alarm?.alarm_id);
                      setShowDialogEditAlarm(true);
                    }}
                    color="success"
                    sx={{ cursor: "pointer" }}
                    label="Sửa"
                  />

                  <Chip
                    onClick={() =>
                      showConfirmation({
                        handler: () =>
                          handleDestroyAlarm({
                            threshold_id: data_threshold?.threshold_id,
                            alarm_id: alarm?.alarm_id,
                          }),
                        open: true,
                        title: "Bạn có chắc muốn xoá",
                        description: "Thao tác này không thể khôi phải",
                      })
                    }
                    sx={{ cursor: "pointer", ml: 2 }}
                    color="error"
                    label="Xoá"
                  />

                  <Chip
                    onClick={() => {
                      setCurrentAlarmId(alarm?.alarm_id);
                      setShowDialogWatches(true);
                    }}
                    sx={{ cursor: "pointer", ml: 2 }}
                    color="secondary"
                    label="Thêm Watches"
                  />

                  <Chip
                    onClick={() => {
                      setCurrentAlarmId(alarm?.alarm_id);
                      setShowDialogChannels(true);
                    }}
                    sx={{ cursor: "pointer", ml: 2 }}
                    color="warning"
                    label="Thêm Channels"
                  />
                </Box>

                <Box mt={2}>
                  <Typography>All Watches</Typography>
                  <Box mt={2}>
                    {watches?.map((item) => (
                      <DataInfo data={item} key={item?.watch_id}>
                        <Box display="flex" alignItems="center" mt={2}>
                          <Chip
                            onClick={() => {
                              setCurrentAlarmId(alarm?.alarm_id);
                              setCurentWatchId(item?.watch_id);
                              setShowDialogWatches(true);
                            }}
                            color="success"
                            sx={{ cursor: "pointer" }}
                            label="Sửa"
                          />

                          <Chip
                            onClick={() =>
                              showConfirmation({
                                handler: () =>
                                  handleDestroyWatch({
                                    watch_id: item?.watch_id,
                                    alarm_id: alarm?.alarm_id,
                                  }),
                                open: true,
                                title: "Bạn có chắc muốn xoá",
                                description: "Thao tác này không thể khôi phục"
                              })
                            }
                            sx={{ cursor: "pointer", ml: 2 }}
                            color="error"
                            label="Xoá"
                          />
                        </Box>
                      </DataInfo>
                    ))}
                  </Box>
                </Box>

                <Box mt={2}>
                  <Typography>All Channels</Typography>
                  <Box mt={2}>
                    {channels?.map(({ channel: item, subscribers }, index) => (
                      <DataInfo data={item} key={index}>
                        <Box display="flex" alignItems="center" mt={2}>
                          <Chip
                            onClick={() => {
                              setCurentChannelId(item?.channel_id);
                              setCurrentAlarmId(alarm?.alarm_id);
                              setShowDialogChannels(true);
                            }}
                            color="success"
                            sx={{ cursor: "pointer" }}
                            label="Sửa"
                          />

                          <Chip
                            sx={{ cursor: "pointer", ml: 2 }}
                            color="error"
                            label="Xoá"
                            onClick={() =>
                              showConfirmation({
                                handler: () =>
                                  handleDestroyChannel({
                                    channel_id: item?.channel_id,
                                    alarm_id: alarm?.alarm_id,
                                  }),
                                open: true,
                                title: "Bạn có chắc muốn xoá",
                                description: "Thao tác không thể khôi phải"
                              })
                            }
                          />

                          <Chip
                            onClick={() => {
                              setCurentChannelId(item?.channel_id);
                              setShowDialogSubscribers(true);
                            }}
                            sx={{ cursor: "pointer", ml: 2 }}
                            color="secondary"
                            label="Thêm Subscribers"
                          />
                        </Box>

                        <Box mt={2}>
                          <Typography>All Subscribers</Typography>
                          <Box mt={2}>
                            {" "}
                            {subscribers?.map((p) => (
                              <DataInfo data={p} key={p?.subscriber_id}>
                                <Box mt={2}>
                                  <Chip
                                    onClick={() => {
                                      setCurentChannelId(item?.channel_id);
                                      setCurentSubscribersId(p?.subscriber_id);
                                      setCurrentAlarmId(alarm?.alarm_id);
                                      setShowDialogSubscribers(true);
                                    }}
                                    color="success"
                                    sx={{ cursor: "pointer" }}
                                    label="Sửa"
                                  />

                                  <Chip
                                    onClick={() =>
                                      showConfirmation({
                                        handler: () =>
                                          handleDestroySubscriber({
                                            channel_id: item?.channel_id,
                                            subscriber_id: p?.subscriber_id,
                                          }),
                                        open: true,
                                        title: "Bạn có chắc muốn xoá",
                                        description: "Thao tác không thể khôi phải"
                                      })
                                    }
                                    sx={{ cursor: "pointer", ml: 2 }}
                                    color="error"
                                    label="Xoá"
                                  />
                                </Box>
                              </DataInfo>
                            ))}
                          </Box>
                        </Box>
                      </DataInfo>
                    ))}
                  </Box>
                </Box>
              </DataInfo>
            </Box>
          ))}
        </Box>
      )}

      {showDialogEditAlarm && (
        <EditAlarm
          values={
            threshold_alarms?.find(
              ({ alarm }) => alarm?.alarm_id === idUpdateAlarm
            )?.alarm
          }
          open={showDialogEditAlarm}
          handleClose={() => {
            setShowDialogEditAlarm(false);
            handleResetState()
          }}
          refetch={refetch}
          handleResetState={handleResetState}
        />
      )}

      {showDialogWatches && (
        <AddWatches
          currentAlarmId={currentAlarmId}
          open={showDialogWatches}
          handleClose={() => {
            setShowDialogWatches(false);
            handleResetState()
          }}
          refetch={refetch}
          handleResetState={handleResetState}
          values={threshold_alarms
            ?.find(({ alarm }) => alarm?.alarm_id === currentAlarmId)
            ?.watches?.find((item) => item?.watch_id === curentWatchId)}
        />
      )}

      {showDialogChannels && (
        <AddChannel
          currentAlarmId={currentAlarmId}
          open={showDialogChannels}
          handleClose={() => {
            setShowDialogChannels(false);
            handleResetState()
          }}
          refetch={refetch}
          handleResetState={handleResetState}
          values={
            threshold_alarms
              ?.find(({ alarm }) => alarm?.alarm_id === currentAlarmId)
              ?.channels?.find(
                ({ channel }) => channel?.channel_id === curentChannelId
              )?.channel
          }
        />
      )}     

      {showDialogSubscribers && (
        <AddSubscriber
          open={showDialogSubscribers}
          handleClose={() => {
            setShowDialogSubscribers(false);
            handleResetState()
          }}
          refetch={refetch}
          curentChannelId={curentChannelId}
          handleResetState={handleResetState}
          values={threshold_alarms
            ?.find(({ alarm }) => alarm?.alarm_id === currentAlarmId)
            ?.channels?.find(
              ({ channel }) => channel?.channel_id === curentChannelId
            )
            .subscribers.find(
              (item) => item.subscriber_id === curentSubscribersId
            )}
        />
      )}
    </Box>
  );
};

export default ThresholdDetail;
