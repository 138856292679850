import ReportWatchApi from '@/apis/beta/Threshold/Report/ReportWatchApi';
import { format_date_short } from '@/functions';
import { API_STORAGE_URL } from '@/libs/constants';
import { Box, Chip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
const TaskStatus = (props) => {
  const [status, setStatus] = React.useState(false);
  const [textStatus, setTextStatus] = React.useState('');
  const { 
    data,
  } = ReportWatchApi.History({
    uuid: props?.uuid,
  });
  if (data?.data?.tasks?.length > 0) {
    data?.data?.tasks?.sort((a, b) => new Date(a?.task_created_at) - new Date(b?.task_created_at));
  }
  const checkFileValid = async(data) => {
    const url = `${API_STORAGE_URL}reports/waf/${data?.data?.tasks[data?.data?.tasks?.length - 1]?.task_completed_at?.slice(0, 7)}/${data?.data?.tasks[data?.data?.tasks?.length - 1]?.task_referrer_jid}.pdf`;
    await fetch(
        url
      ).then((res) => {
        if(res.status === 200){
          setStatus(true);
          setTextStatus("Có file");
          return;
        }else if(res.status === 404){
          setStatus(false);
          setTextStatus("Không tìm thấy file");
          return;
        }
        setStatus(false);
        setTextStatus(`Lỗi ${res.status}`);
      }).catch((err) => {
        console.log(err?.message);
      })
  }
  useEffect(() => {
    if(data?.data?.tasks?.length > 0 && props?.query === "checkFile"){
      checkFileValid(data);
    }
    return () => {
      
    };
  },[])
  
  return (
    <>
      {props?.query === "date" ? <>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ fontSize: 14, m: "auto 0" }}
          >
            {data?.data?.tasks[data?.data?.tasks?.length - 1]?.task_created_at ? format_date_short(data?.data?.tasks[data?.data?.tasks?.length - 1]?.task_created_at) : ""}
          </Typography>
        </Box>
      </> :
        props?.query === "status" ? 
          <Chip size="small" label={data?.data?.tasks[data?.data?.tasks?.length - 1]?.task_status || "Không có report"} color={data?.data?.tasks[data?.data?.tasks?.length - 1]?.task_status || "none" === "completed" ? "success" : "error"} variant='outlined' />
        :
          <Chip size="small" label={data?.data?.tasks?.length > 0 ? textStatus: "Không có report"} color={status ? "success" : "error"} variant='outlined' />
        }
    </>
  );
}

export default TaskStatus;
