import DataGridCustomize from '@components/DataGrid'
import React, { useState } from 'react'
import ServicePrice from './ServicePrice'
import ServiceMetadata from './ServiceMetadata'
import Footer from './Footer'
import { Box, IconButton } from '@mui/material'
import { DeleteOutlineOutlined } from '@mui/icons-material'
import ServicePriceExceed from './ServicePriceExceed'
import { useStateArrayContext } from '@contexts/stateArray'
import ServiceQty from './ServiceQty'
import ServicePaymentMethod from './ServicePaymentMethod'
import ServiceRangeEnabled from './ServiceRangeEnabled'

const DataProducts = ({
    isFetching = false
}) => {
    const {
        state: products,
        removeIndex: remove
    } = useStateArrayContext()

    const [columns] = useState([
        {
            field: "product_name",
            headerName: "Tên",
            width: 300,
        },
        {
            field: "service_packages",
            headerName: "Gói Cố Định/ Theo Gói",
            width: 230,
            renderCell: ({ row }) => <ServiceMetadata data={row} />
        },
        {
            field: "product_value",
            headerName: "Mua Thêm",
            width: 130,
            renderCell: ({ row }) => <ServiceQty data={row} />
        },
        {
            field: "service_price",
            headerName: "Đơn Giá Bán",
            width: 160,
            renderCell: ({ row }) => <ServicePrice data={row} />
        },
        {
            field: "service_price_exceed",
            headerName: "Đơn Giá Đối Soát Vượt",
            width: 350,
            renderCell: ({ row }) => <ServicePriceExceed data={row} />
        },
        {
            field: "service_range_enabled",
            headerName: "Range",
            width: 120,
            renderCell: ({ row }) => <ServiceRangeEnabled data={row} />
        },
        {
            field: "service_payment_method",
            headerName: "Hình Thức Đối Soát",
            width: 200,
            renderCell: ({ row }) => <ServicePaymentMethod data={row} />
        },
        {
            field: "actions",
            headerName: "",
            width: 40,
            renderCell: ({ row }) => <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton size='small' color="error" onClick={() => remove(row?.id)}>
                    <DeleteOutlineOutlined fontSize='small' />
                </IconButton>
            </Box>
        },
    ])
    return (
        <DataGridCustomize
            rows={
                products?.slice(1)?.map((product, index) => ({
                    ...product,
                    id: index + 1,
                })) || []
            }
            getRowHeight={({ model }) => {
                const PADDING_OFFSET_Y = 8;
                const COUNT_RPICE_RANGES =
                    40 * (model?.service_price_exceeds?.length || 0) + PADDING_OFFSET_Y;
                return Math.max(COUNT_RPICE_RANGES + 16, 52);
            }}
            columns={columns}
            loading={isFetching}
            rowCount={products?.length}
            initialState={{
                pinnedColumns: { right: ["actions"] },
            }}
            components={{
                Toolbar: 'disabled',
                Pagination: Footer
            }}
            componentProps={{
                pagination: {
                    data: products
                }
            }}
        />
    )
}

export default DataProducts