import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class JourneyApi {
  Detail = ({ customerId = -1, journeyId = -1 }) => {
    return useQueryWithCustomized(
      ["customers.journey", customerId, journeyId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/journeys/${journeyId}`
        )
          .then(({ data }) => {
            return data?.data;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  List = ({ customerId = -1 }) => {
    return useQueryWithCustomized(
      ["customers.journeys", customerId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/journeys`
        )
          .then(({ data }) => {
            return data?.data?.journeys || [];
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Explaination = ({ customerId = -1, journeyId = -1 }) => {
    return useQueryWithCustomized(
      ["customers.journey.explaination", customerId, journeyId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/journeys/${journeyId}/explaination`
        )
          .then(({ data }) => {
            return data?.data?.explaination;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  UpdateExplaination = async ({ customerId = -1, journeyId = -1, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/journeys/${journeyId}/explaination`,
      "PUT",
      {
        explaination: data,
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  FindService = ({ customerId = -1, journeyId = -1 }) => {
    return useQueryWithCustomized(
      ["customers.journey.service", customerId, journeyId],
      async () => {
        return await requestWithToken(
          `${API_V3_URL}/customers/${customerId}/journeys/${journeyId}/service`
        )
          .then(({ data }) => {
            return data?.data?.service;
          })
          .catch((error) => {
            return error.response;
          });
      },
      {
        suspense: true,
        staleTime: 10 * 60 * 1000,
      }
    );
  };

  Create = async ({ customerId = -1, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/journeys`,
      "POST",
      {
        explaination: { ...data },
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  Locked = async ({ customerId = -1, journeyId = -1, data }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/journeys/${journeyId}/locked`,
      "PUT",
      {
        journey: { ...data },
      },
      {},
      {}
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  PrecheckPOC = async ({ customerId, journeyId }) => {
    return await requestWithToken(
      `${API_V3_URL}/customers/${customerId}/journeys/${journeyId}/poc`,
      "GET",
      {},
      {},
      {}
    )
      .then(({ data }) => {
        if (data?.error) return data;
        return data?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new JourneyApi();
