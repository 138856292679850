import { Box, Button, CircularProgress, IconButton, Paper, Popper, Slide, Typography } from "@mui/material";
import React from "react";
import Header from "../Header";
import { useNavigate, useParams } from "react-router";
import WarehouseResourceApi from "@/apis/beta/Warehouse/WarehouseResourceApi";
import { AddOutlined, Lens, MoreVertOutlined } from "@mui/icons-material";
import CellConfig from "@components/DataGrid/CellConfig2";
import { green, grey } from "@mui/material/colors";
import { format_pricing_vnd } from "@/libs/constants";
import _ from "lodash";
import WarehouseResoureServiceApi from "@/apis/beta/Warehouse/WarehouseResoureServiceApi";
import DrawerWarehouseItem from "../../Create/share/DrawerWarehouseItem";
import { format_date_short, getDataGridRowHeight } from "@/functions";
import PopperAction from "@components/PopperAction";
import ConfirmDialog from "@features/Module/WarehouseNew/Investment/Detail/share/ConfirmDialog";
import DataGridCustomize from "@components/DataGrid";
import FormResourceSevice from "./FormResourceSevice";
import withSuspense from "@/hocs/withSuspense";
import withPermission from "@/hocs/withPermission";
import MuiSkeleton from "@components/shared/MuiSkeleton";

const queryString = require("query-string")



const Service = () => {
    const params = useParams();
    const { data: { warehouse_resource }, refetch: refetchData } = WarehouseResourceApi.show(params?.id)

    const [device, setDevice] = React.useState({
        open: false
    })

    const [popper, setPopper] = React.useState({
        anchorEl: undefined,
        data: undefined
    })

    const url_params = queryString.parse(window.location.search, {
        skipNull: true,
        arrayFormat: 'bracket',
        skipEmptyString: true,
    })

    const [urlParams, setUrlParams] = React.useState(
        {
            page: 1,
            page_size: 10,
            ...url_params,
        }
    )

    const { data, isFetching, refetch: refetchResourceSevice } = WarehouseResoureServiceApi.index({
        resource_id: params?.id,
        page: urlParams.page,
        pageSize: urlParams.page_size
    })

    const [confirm, ToggleConfirm] = React.useState({ show: false });
    const navigate = useNavigate()

    const [actions] = React.useState([
        {
            name: "update",
            handleClick: (data) => {
                navigate(`/warehouse/resources/${data?.warehouse_resource_id}/services/${data?.warehouse_resource_service_id}/edit`)
            },
            icon: "Edit",
            label: "Chỉnh Sửa"
        },
        // {
        //     name: "delete",
        //     handleClick: (data) => {
        //         ToggleConfirm({
        //             show: true,
        //             callback: () =>{
        //                 // onCancelled(data)

        //             },
        //             title: 'Huỷ Tài Nguyên Chạy Dịch Vụ',
        //             description: `Bạn có muốn huỷ tài nguyên dịch vụ ${data?.warehouse_resource_service_code} - ${data?.warehouse_resource_service_name}. Vui lòng liên hệ bộ phận liên quan đầu tư để huỷ tài nguyên.`,
        //           })

        //     },
        //     icon: "Delete",
        //     label: "Huỷ"
        // },
    ])

    const initialColumns = [
        {
            field: 'warehouse_resource_service_node',
            headerName: 'Node',
            hide: warehouse_resource?.warehouse_resource_type === "dedicate" ? true : false,
            width: 200,
        },
        {
            field: 'warehouse_resource_service_code',
            headerName: 'Cụm đầu tư',
            width: 200,
        },
        {
            field: 'warehouse_items',
            headerName: 'Thiết Bị',
            hide: warehouse_resource?.warehouse_resource_type === "dedicate" ? false : true,
            renderCell: ({ value }) => <DrawerWarehouseItem items={value} />,
            width: 200,
        },
        {
            field: 'warehouse_item_service_tag',
            headerName: 'service tag/ serial number',
            hide: warehouse_resource?.warehouse_resource_type === "dedicate" ? false : true,
            renderCell: ({ row }) => 
                <Box sx={{ width: '100%', p: 1 }}>
                    {row?.warehouse_items?.map((item) => 
                    <Typography
                    sx={{ fontSize: 14 }}
                    >
                        {
                        item?.warehouse_item_service_tag
                        }
                    </Typography>) || ""}
                </Box>
                ,
            width: 200,
        },
        {
            field: 'warehouse_resource_service_price',
            headerName: 'Tiền đầu tư',
            renderCell: ({ value }) => format_pricing_vnd(value),
            width: 200,
        },
        {
            field: 'warehouse_resource_service_name',
            headerName: 'Dịch Vụ',
            width: 200,
        },
        {
            field: 'warehouse_resource_service_status',
            headerName: 'Trạng Thái',
            renderCell: ({ value }) => <Lens sx={{ m: "auto", color: value === "active" ? green[900] : grey[500] }} />,
            width: 120,
        },
        {
            field: 'warehouse_resource_config',
            headerName: 'Cấu Hình',
            width: 350,
            valueGetter: ({ row, value }) => {
                const config = _.find(row?.warehouse_resource_configs, { warehouse_resource_config_type: "config" })

                return {
                    capacity_cpu: config?.warehouse_resource_config_cpu,
                    capacity_ram: config?.warehouse_resource_config_ram,
                    capacity_storage: config?.warehouse_resource_config_storage
                }
            },
            renderCell: ({ value }) => (value && <CellConfig configures={value} />)
        },
        {
            field: 'warehouse_resource_config_used',
            headerName: 'Cấu Hình Khách Hàng Sử Đụng',
            width: 350,
            valueGetter: ({ row, value }) => {
                const config = _.find(row?.warehouse_resource_configs, { warehouse_resource_config_type: "used" })

                return {
                    capacity_cpu: config?.warehouse_resource_config_cpu,
                    capacity_ram: config?.warehouse_resource_config_ram,
                    capacity_storage: config?.warehouse_resource_config_storage
                }
            },
            renderCell: ({ value }) => (value && <CellConfig configures={value} />)
        },
        {
            field: 'warehouse_resource_service_created_at',
            width: 200,
            headerName: 'Ngày Tạo',
            valueGetter: ({ value }) => (format_date_short(value)),
        },
        {
            field: "id",
            headerName: "Thao Tác",
            width: 80,
            renderCell: ({ row }) => (
                <>
                    {row.warehouse_resource_service_status === "active" ?
                        <Box sx={{
                            width: '100%', display: 'flex', justifyContent: 'center',
                        }}>
                            <IconButton
                                title="Thao Tác"
                                onClick={(event) => {
                                    setPopper(prev => ({ ...prev, anchorEl: event?.target, data: row }))
                                }}
                            >
                                <MoreVertOutlined sx={{ m: 'auto' }} />
                            </IconButton>
                        </Box> : null}
                </>)
        },
    ]

    return (
        <Box>
            <Header />
            <Button
                startIcon={<AddOutlined />}
                variant='contained'
                disabled={device?.open}
                focusRipple={!device?.open}
                onClick={() => setDevice({ open: true })}
                size='small' sx={{ height: { xs: 36, md: 42 }, mb: 2, mt: 2 }}>
                Thêm Tài Nguyên Dịch Vụ
            </Button>
            {device?.open && <Slide in={device?.open} direction="up">
                <Paper sx={{ p: 2, mt: 2 }} >
                    <React.Suspense fallback={<CircularProgress size={16} disableShrink />}>
                        <FormResourceSevice key={warehouse_resource?.warehouse_resource_id} handleClose={()=> setDevice({open: false})} refetchResourceSevice={refetchResourceSevice} refetchData={refetchData} warehouse_resource={warehouse_resource} type="create" />
                    </React.Suspense>
                </Paper>
            </Slide>}
            <Box sx={{ mt: 3 }}>
                <DataGridCustomize
                    rows={data?.warehouse_resource_services || []}
                    columns={initialColumns?.filter(col => !col?.hide)}
                    loading={isFetching}
                    getRowHeight={(params) => getDataGridRowHeight([params?.model?.warehouse_items, [{}, {}]], {})}
                    componentsProps={{
                        toolbar: {
                            store: "warehouse.resource.list.hiddenColumns",
                            initialColumns: initialColumns,
                            feature: 'warehouse-resource-service',
                            actions: [
                            ],
                        },
                        pagination: {
                            page: urlParams?.page - 1 || 0,
                            pageSize: urlParams?.page_size || 20,
                            rowsPerPageOptions: [10, 20, 50, 100],
                            onPageChange: (newPage) => {
                                setUrlParams(prevState => ({ ...prevState, page: newPage + 1 }))
                            },
                            onPageSizeChange: (newPageSize) => setUrlParams(prevState => ({ ...prevState, page_size: newPageSize, page: 1 }))
                        }
                    }}
                    rowCount={data.pagination ? data?.pagination?.total : 0}
                />
            </Box>
            {popper?.anchorEl !== undefined && <Popper anchorEl={popper?.anchorEl}
                open={Boolean(popper.anchorEl)}>
                <PopperAction
                    {...popper}
                    actions={actions}
                    handleClose={() => setPopper(prev => ({ ...prev, anchorEl: undefined }))} />
            </Popper>}
            {confirm.show && (
                <ConfirmDialog
                close={() => ToggleConfirm({ show: false })}
                title={confirm.title}
                description={confirm.description}
                callback={() => {
                    if (confirm.callback) confirm.callback();
                    ToggleConfirm({ show: false });
                }}
            />)}
        </Box>
    )
}
export default withSuspense(withPermission(Service, {
    feature: "warehouse",
    action: "resource-service"
}), MuiSkeleton["DataGrid"]) 