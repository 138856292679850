import CustomerApi from "@/apis/beta/Customer/CustomerApi";
import { buildQueryFilterCondition } from "@/functions";
import withSuspense from "@/hocs/withSuspense";
import useDebounce from "@/hooks/useDebounce";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import SingleSelectCustomize from "@components/shared/SingleSelectCustomize";
import { Box, FormControl, Typography } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

const ContractCustomer = ({ disabled }) => {
  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const [searchParams] = useSearchParams();

  const [state, setState] = React.useState("");

  const debouncedSearchQuery = useDebounce(state, 400);

  const filterableCustomer = React.useCallback(() => {
    let fieldQueries = [];
    if (parseInt(watch(`contract_customer_id`)) > 0) {
      fieldQueries.push({
        name: "customers.customer_id",
        operator: "$eq",
        value: parseInt(watch(`contract_customer_id`)),
      });
    }

    if (!!debouncedSearchQuery && !(debouncedSearchQuery?.trim() === "")) {
      fieldQueries.push(
        ...[
          {
            name: "customers.customer_id",
            operator: "$eq",
            disabled: !parseInt(debouncedSearchQuery),
            value: parseInt(debouncedSearchQuery),
          },
          {
            name: "customers.customer_name",
            operator: "$contains",
            value: debouncedSearchQuery,
          },
          {
            name: "company_name",
            operator: "$contains",
            value: debouncedSearchQuery,
          },
          {
            name: "customers.customer_email",
            operator: "$contains",
            value: debouncedSearchQuery,
          },
        ]
      );
    }
    return buildQueryFilterCondition("$or", 0, fieldQueries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, watch(`contract_customer_id`)]);

  const {
    isFetching,
    data: { customers },
  } = CustomerApi.List({
    page: 1,
    pageSize: 15,
    query: filterableCustomer(),
  });

  React.useEffect(() => {
    if (!!searchParams.get("customer_id")) {
      return setValue(`contract_customer_id`, searchParams.get("customer_id"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("customer_id")]);

  const rebuildCustomerSelections = React.useMemo(() => {
    if (!customers || customers?.length === 0) return [];

    return customers?.map((customer, index) => {
      let label = customer?.customer_id;
      if (customer?.customer_type !== "individual") {
        if (!!customer?.customer_company?.company_name) {
          label += ` - ${customer?.customer_company?.company_name}`;
        }
        label += ` - (${customer?.customer_name})`;
      } else {
        label += ` - ${customer?.customer_name} (${customer?.customer_email})`;
      }

      return {
        value: customer?.customer_id,
        label,
      };
    });
  }, [customers]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: { xs: 0.5 },
      }}
    >
      <Box sx={{ minWidth: { xs: "100%", md: 300 } }}>
        <Typography sx={{ fontSize: 14, color: "#777680" }}>
          Khách Hàng *
        </Typography>
      </Box>
      <Controller
        name="contract_customer_id"
        control={control}
        render={({ field: { value, onChange } }) => {
          let options = rebuildCustomerSelections;
          const selected =
            options?.find((v) => parseInt(v?.value) === parseInt(value)) ||
            null;
          return (
            <FormControl
              fullWidth
              sx={{
                width: { xs: "100%" },
              }}
            >
              <SingleSelectCustomize
                loading={isFetching}
                value={selected}
                disabled={disabled}
                options={options}
                onChange={(event, newValue) => onChange(newValue?.value || -1)}
                input={{
                  placeholder: "Nhập tên & Id khách hàng tìm kiếm...",
                  value: state,
                  onChange: (e) => setState(e?.target?.value || ""),
                }}
                mode="server"
              />
              {!!errors?.contract_customer_id && (
                <ErrorHelperText
                  message={errors?.contract_customer_id?.message}
                />
              )}
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default withSuspense(
  ContractCustomer,
  MuiSkeleton["FormControlTextField"]
);
