import { requestWithToken } from "@/functions";
import useQueryWithCustomized from "@/hooks/useQueryWithCustomized";
import { API_V3_URL } from "@/libs/constants";

class TicketStatusApi {
  ListTicketStatus = () => {
    return useQueryWithCustomized(["ticket_statuses.all"], async () => {
      const response = await requestWithToken(
        `${API_V3_URL}/ticket_statuses/list`
      );
      if (response?.data?.data) {
        return response?.data?.data?.ticket_statuses;
      }
      return response?.data;
    });
  };

  Update = ({ ticket_status_id, values }) => {
    const response = requestWithToken(
      `${API_V3_URL}/ticket_statuses/${ticket_status_id}/update_ticket_status`,
      "PUT",
      {
        ticket_status: values,
      }
    );

    return response?.data;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TicketStatusApi();
