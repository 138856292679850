import { Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import useMutationWithNotification from "@/hooks/useMutationWithNotification";
import VcardApi from "@/apis/beta/Vcard/VcardApi";
import { useConfirmContext } from "@contexts/confirm";

const ButtonAction = ({ row, refetch }) => {
  const [anchorEl, setAnchorEl] = useState();

  const { mutateAsync, isLoading } = useMutationWithNotification(
    VcardApi.DeleteVcard
  );
  const { showConfirmation } = useConfirmContext();

  const handleDelete = async () => {
    const response = await mutateAsync({ id: row?.id });
    if (!response?.errors) refetch();
  };

  return (
    <div>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e?.currentTarget);
        }}
      >
        <MoreVertIcon />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Link
          to={`/account/vcard/update/${row?.id}`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <MenuItem>
            <EditIcon sx={{ mr: 2 }} /> Chỉnh sửa
          </MenuItem>
        </Link>

        <MenuItem
          onClick={() =>
            showConfirmation({
              title: "Bạn có chắc muốn xoá",
              open: true,
              description: "Thao tác này không thể khôi phục",
              loading: isLoading,
              handler: handleDelete,
            })
          }
        >
          <DeleteIcon sx={{ mr: 2 }} /> Xoá
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ButtonAction;
