import { format_numeral_price, getPricingConfigoption } from '@/functions'
import { Box, Typography } from '@mui/material'
import React from 'react'

const ServicePricing = ({
    product_pricing_init,
    product_pricing_amount,
    product_template,
    product_billing_configures,
    product_billing_cycle,
    product_pricing_configures,
    product_configoptions,
    product_addons = []
}) => {

    const handlerAmount = React.useCallback(() => {
        let tt = 0
        switch (product_template) {
            case "Normal":
                const productPricing = product_billing_configures?.find((item) => item?.name === "monthly")?.pricing
                tt += productPricing
                break;
            case "Customized":
                tt += Math.round(getPricingConfigoption({
                    method: product_pricing_configures?.method,
                    value: parseFloat(product_pricing_configures?.value) || 0,
                    pricingApply: 0,
                    options: product_pricing_configures?.product_pricing_steps
                }) / parseInt(product_pricing_configures?.commitment_term) || 1)
                break;
            case "PrivateCDN":
                product_configoptions?.forEach((item) => {
                    tt += Math.round(getPricingConfigoption({
                        method: item?.method,
                        value: parseFloat(item?.value) || 0,
                        pricingApply: 0,
                        options: item?.product_pricing_steps
                    }))
                })
                break;
            case "Domain":
                tt += parseFloat(product_pricing_init)
                break;
            default:
                break;
        }
        // const coff = SERVICE_PERIODS.find(item => item.value === product_billing_cycle)?.coefficient
        if (product_addons && product_addons.length !== 0) {
            product_addons?.forEach((addon) => {
                tt += parseInt(addon?.qty) * parseFloat(addon?.pricing)
            })
        }
        return tt
    }, [product_addons, product_pricing_init, product_billing_configures, product_configoptions, product_pricing_configures?.commitment_term, product_pricing_configures?.method, product_pricing_configures?.product_pricing_steps, product_pricing_configures?.value, product_template])

    const [amount, setAmount] = React.useState(handlerAmount())

    React.useEffect(() => {
        setAmount(handlerAmount())
    }, [handlerAmount])
    return (
        <Box sx={{ display: 'flex' }}>
            <Typography component="div" sx={{
                fontSize: 14,
                color: "#777680",
                mt: 1
            }}>
                Đơn giá niêm yết:
                <Typography sx={{
                    fontSize: 14,
                    display: 'inline-block',
                    textDecoration: 'underline',
                    ml: 0.5,
                    color: "#00000F"
                }}>đ</Typography>
                <Typography sx={{
                    fontSize: 14,
                    display: 'inline-block',
                    color: "#00000F"
                }}>
                    {format_numeral_price(amount)}
                </Typography>
                {product_template === "Domain" && <Typography sx={{
                    ml: 0.5,
                    color: "#777680",
                    fontSize: 14,
                    display: 'inline-block',
                }}>
                    ( 12 tháng)
                </Typography>}
            </Typography>
        </Box>
    )
}

export default ServicePricing