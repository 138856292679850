import SingleSelectCustomize from '@components/shared/SingleSelectCustomize'
import { useStateArrayContext } from '@contexts/stateArray'
import { Box, FormControl } from '@mui/material'
import React from 'react'

const SERVICE_CONFIG_PAYMENT_METHODS = [
    { label: 'Theo thoả thuận', value: 'tierd' },
    { label: 'Luỹ tiến', value: 'progressive' },
]
const ServicePaymentMethod = ({ data }) => {
    const { updateAttribute } = useStateArrayContext()
    if (!data?.service_range_enabled) return <></>
    return (
        <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
                <SingleSelectCustomize
                    options={SERVICE_CONFIG_PAYMENT_METHODS}
                    value={SERVICE_CONFIG_PAYMENT_METHODS?.find((x) => x?.value === data?.service_payment_method) || undefined}
                    onChange={(event, newValue) => updateAttribute({
                        payment_config_method: newValue?.value || ''
                    }, data?.id)}
                />
            </FormControl>
        </Box>
    )
}

export default ServicePaymentMethod