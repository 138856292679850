import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import HeaderTitle from "@components/Title/HeaderTitle";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import { Box } from "@mui/material";
import WarehouseItemDataGridDevice from "../../Shared/WarehouseItemDataGridDevice";

const Customer = () => {
  return (
    <Box>
      <Box mb={2}>
        <HeaderTitle>Thiết bị khách hàng</HeaderTitle>
      </Box>

      <WarehouseItemDataGridDevice
        name="customer"
        types_id={[1, 2]}
        customer
        item_exports
        analysist={false}
        analysist_length
      />
    </Box>
  );
};

export default withSuspense(
  withPermission(Customer, { feature: "warehouse", action: "customer" }),
  MuiSkeleton["DataGrid"]
);
