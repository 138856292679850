import withPermission from "@/hocs/withPermission";
import withSuspense from "@/hocs/withSuspense";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import React from "react";
import { useNavigate } from "react-router";
import _ from "lodash";
import { Box, Button, Chip, Link, Paper } from "@mui/material";
import { NavLink } from "react-router-dom";
import { format_pricing_vnd } from "@/libs/constants";
import { AddOutlined } from "@mui/icons-material";
import WarehouseResoureServiceApi from "@/apis/beta/Warehouse/WarehouseResoureServiceApi";
import { GridTreeDataGroupingCell } from "@components/DataGridPreview/DataGridPro";
import DataGridPreview, { getTreeDataPath } from "@components/DataGridPreview";
import { format_date_short } from "@/functions";
import Header from "../Header";



const ServiceList = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = React.useState("");
    const {data: warehouse_resource_services, isFetching} = WarehouseResoureServiceApi.GetResourceServices({searchQuery:searchQuery});
    const initialColumns = [
        {
            field: "warehouse_item_name",
            headerName: "Tên thiết bị",
            sortable: true,
            width: 150,
            renderCell: ({ value, row }) => 
            <Link 
                component={NavLink} 
                to={`/warehouse/items/${row?.warehouse_item_id}/detail`}>
                    {value}
            </Link>
        },
        {
            field: "warehouse_item_service_tag",
            headerName: "Service tag",
            sortable: true,
            width: 150,
            renderCell: ({ value, row }) => 
                <Link 
                    component={NavLink} 
                    to={row?.warehouse_resource_type === "dedicate"? 
                        `/warehouse/resources/${row?.warehouse_resource_id}/services/${row?.warehouse_resource_service_id}/edit?service_tag=${value}` 
                        : 
                        `/warehouse/resources/${row?.warehouse_resource_id}/information?service_tag=${value}`}>
                        {value}
                </Link>
        },
        {
            field: "warehouse_box_item_position",
            headerName: "Vị trí",
            sortable: true,
            width: 50,
            renderCell: ({value, row}) => value ? value + (row?.warehouse_item_form_factor - 1) > value ? `${value}-${value + (row?.warehouse_item_form_factor - 1)}` : value : ""
        },
        {
            field: "warehouse_rack_name",
            headerName: "Rack",
            sortable: true,
            width: 100,
            renderCell: ({value, row}) => 
            <Link 
                component={NavLink} 
                to={`/warehouse/racks/${row?.warehouse_rack_id}`}>
                    {value}
            </Link>
        },
        {
            field: "warehouse_box_name",
            headerName: "IDC",
            sortable: true,
            width: 200,
            renderCell: ({value, row}) => 
            <Link 
                component={NavLink} 
                to={`/warehouse/boxes/${row?.warehouse_box_id}`}>
                    {value}
            </Link>
        },
        {
            field: "warehouse_item_price",
            headerName: "Số tiền đầu tư dịch vụ",
            sortable: true,
            width: 150,
            renderCell: ({value}) => format_pricing_vnd(value)
        },
        {
            field: "warehouse_resource_service_status",
            headerName: "Trạng thái dịch vụ",
            sortable: true,
            width: 150,
            renderCell: ({value}) => value && <Chip label={value === "active"? "active" : "cancelled"} color={value === "active" ? "success" : "error"} size="small" />
        },
        {
            field: "warehouse_resource_service_node",
            headerName: "Node",
            sortable: true,
            width: 150,
        },
        {
            field: "warehouse_resource_service_code",
            headerName: "Cụm đầu tư",
            sortable: true,
            width: 150,
        },
    ]
    const groupingColDef = React.useCallback(
        () => ({
          field: "__tree_data_group__",
          headerName: 'Group',
          width: 275,
    
          renderCell: ({...params}) => {
            const { rowNode } = params;
              params = {
                ...params,
                rowNode: {
                  ...params["rowNode"],
                  groupingKey: params["rowNode"]?.groupingKey?.split("-")[0],
                },
              }
            console.log(params)

            return (
              <GridTreeDataGroupingCell
                total={
                  rowNode?.children?.length
                }
                {...params}
              />
    
            );
          },
        }),
        []
      );
    
    const rows = () => {
        let groups = []
        let groups2 = []
        let rows = warehouse_resource_services?.map((item,index) => 
            {
                if (!groups?.includes(item?.warehouse_resource_service_name)){
                    groups.push(item?.warehouse_resource_service_name)
                };
                if (!groups2?.find((itemGroup) => itemGroup?.hierarchy[1] === `${item?.warehouse_resource_type}_${format_date_short(item?.warehouse_resource_created_at)}-${item?.warehouse_resource_id}` && itemGroup?.hierarchy[0] === item?.warehouse_resource_service_name)){
                    groups2.push({
                        hierarchy: [item?.warehouse_resource_service_name,`${item?.warehouse_resource_type}_${format_date_short(item?.warehouse_resource_created_at)}-${item?.warehouse_resource_id}`],
                    })
                };
                return ({
                        ...item,
                        hierarchy: [
                            item?.warehouse_resource_service_name,
                           `${item?.warehouse_resource_type}_${format_date_short(item?.warehouse_resource_created_at)}-${item?.warehouse_resource_id}`,
                            `${item?.warehouse_resource_service_name}-${index}`
                        ]
                    })
            });
            groups2?.forEach((item,index) => {
                rows?.push({
                    ...item,
                    id: `${item?.hierarchy[0]}-${item?.hierarchy[1]}-${index}-${_.sumBy(rows?.filter(row => row?.hierarchy[1] === item?.hierarchy[1] && row?.hierarchy?.length === 3), "warehouse_item_price")}`,
                    warehouse_item_price: _.sumBy(rows?.filter(row => row?.hierarchy[1] === item?.hierarchy[1] && row?.hierarchy?.length === 3 && row?.hierarchy[0] === item?.hierarchy[0]), "warehouse_item_price"),
                })
            })
            groups?.forEach((item,index) => {
                rows?.push({
                    id: `${item}-${index}`,
                    hierarchy: [item],
                    warehouse_item_price: _.sumBy(rows?.filter(row => row?.hierarchy[0] === item && row?.hierarchy?.length === 2), "warehouse_item_price"),
                })
            })
        return rows;
    }
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, mt: 2 }}>
                <Button variant='contained'
                    size='small'
                    sx={{
                        height: { xs: 36, md: 42 },
                        m: 'auto 0'
                    }}
                    onClick={() => {
                        navigate("/warehouse/resources/new")
                    }}
                    startIcon={<AddOutlined />}
                >
                    Thêm Tài Nguyên
                </Button>
            </Box>
            <Header />
            <Paper>
                <DataGridPreview
                    treeData
                    autoHeight
                    isFetching={isFetching}
                    groupingColDef={groupingColDef}
                    getTreeDataPath={getTreeDataPath}
                    rows={rows() || []}
                    hideAble = {true}
                    disableSelectionOnClick={false}
                    options={{
                        name: 'Thống kê vật tư kho',
                        columns: initialColumns || [],
                    }}
                    disableColumnPinning={false}
                    initialState={{
                        sorting: [{ field: "capacity", sort: "asc" }],
                        pinnedColumns: { left: ["__tree_data_group__", "capacity"] },
                    }}
                    componentsProps={{
                        toolbar: {

                            feature: 'resource-service-list',
                            actions: [

                            ],
                            inputSearch: {
                                placeholder: "Nhập Service Tag / Serial Number, tên thiết bị, IDC,Rack tìm kiếm...",
                                value: searchQuery,
                                onChange: setSearchQuery
                            }
                        },
                        
                    }}
                    disableColumnSelector = {false}
                    hideFooter={false}
                />
            </Paper>
        </Box>
    )
}
export default withSuspense(withPermission(ServiceList, {
    feature: "warehouse",
    action: "resource-service-list"
}), MuiSkeleton["DataGrid"]) 