
import DataGrid from "@/components/DataGrid";
import { Box, Link, Typography } from "@mui/material";
// import _ from "lodash";
import DialogShowJsonData from "@components/DialogShowJsonData";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

export const ITEM_ACTIONS = [
  { value: "create", label: "Tạo" },
  { value: "cancel", label: "Huỷ" },
  { value: "update", label: "Cập nhật" },
  { value: "confirm", label: "Duyệt" },
  { value: "remove", label: "Tách" },
  { value: "export", label: "Xuất bán" },
  { value: "transfer", label: "Luân chuyển" },
];
export const ITEM_TYPES = [
  { value: "child", label: "linh kiện" },
  { value: "item", label: "thiết bị/linh kiện" },
  { value: "status", label: "trạng thái" },
  { value: "owner", label: "sở hữu" },
  { value: "availability", label: "availability" },

  // { value: "process", label: "phiếu" },
]

const ItemLogs = (props) => {
  const navigate = useNavigate();
  const [dialogInfor, setDialogInfor] = useState({
    open: false,
    data: {},
    title: "Thông tin chi tiết"
  })
  const data = props?.data || []
  // const rows = props?.data?.warehouse_item_logs || []
  if (data?.length > 1) {
    data?.sort((a, b) => new Date(b.warehouse_item_update_created_at) - new Date(a.warehouse_item_update_created_at));
  }

  const [columns] = useState([
    {
      field: "warehouse_item_updater_id",
      headerName: "Id người thực hiện",
      width: 50,
    },
    {
      field: "warehouse_item_updater_name",
      headerName: "Tên người thực hiện",
      width: 150,
    },
    {
      field: "warehouse_item_update_type",
      headerName: "Thao tác",
      valueGetter: ({ value }) => {
        return ITEM_ACTIONS?.find((x) => x.value === (value?.split('_')[0])?.toLowerCase())?.label + " " + ITEM_TYPES?.find((x) => x.value === (value?.split('_')[1])?.toLowerCase())?.label
      },
      width: 150,
    },
    {
      field: "warehouse_item_related_id",
      headerName: "Thiết bị/ Linh kiện liên quan",
      width: 200,
      renderCell: ({ row, value }) =>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
          }}
          onClick={() =>
            value &&
            navigate(
              `/warehouse/items/${value}/detail`
            )}
        >
          <Link
            //   underline="hover"
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            color="primary"
          >
            {row.warehouse_item_related_name || "---"}
          </Link>
        </Box>
        || "---",
    },
    {
      field: "warehouse_item_update_created_at",
      headerName: "Thời gian thực hiện",
      width: 150,
      renderCell: ({ value }) =>
        value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "---",
    },
    {
      field: "warehouse_item_update_description",
      headerName: "Mô tả",
      width: 200,
      renderCell: ({ value }) => {
        return (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
            onClick={() => setDialogInfor({ open: true, data: value })}
          >
            <Link
              component={NavLink}
            >
              <Typography color="primary" sx={{ fontSize: 12 }}>
                {value || "---"}
              </Typography>
            </Link>
          </Box>
        )
      }
    },
    {
      field: "metadata",
      headerName: "Thông tin",
      width: 100,
      renderCell: ({ value }) => {
        return (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
            onClick={() => setDialogInfor({ open: true, data: value })}
          >
            <Link
              //   underline="hover"
              sx={{
                textDecoration: "underline",
              }}
              color="primary"
            >
              Xem chi tiết
            </Link>
          </Box>
        )
      }
    },

  ]);
  const onClose = () => {
    setDialogInfor((prev) => ({ ...prev, open: false, data: {} }));
  }

  return (
    <Box sx={{ mt: 2 }}>
      <DialogShowJsonData
        open={dialogInfor.open}
        onClose={onClose}
        title={dialogInfor.title}
        metadata={dialogInfor.data}
        noTranslate={true}
      />
      <Typography
        sx={{ fontSize: { xs: 16, md: 18 }, fontWeight: "medium", mb: 0.5 }}
      >
        Lịch Sử Thao Tác
      </Typography>
      <DataGrid
        columns={[...columns]}
        rows={data?.map((item, index) => ({ ...item, id: index }))}
        components={{
          Toolbar: "disabled",
          Pagination: "disabled",
        }}
        paginationMode="client"
      />
    </Box>
  );
};

export default ItemLogs;
