import WarehouseBoxApi from "@/apis/beta/Warehouse/WarehouseBoxApi";
import ErrorHelperText from "@components/shared/ErrorHelperText";
import SearchDevice from "@features/Module/WarehouseNew/WarehouseBoxes/Boxes/SearchDevice";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import AddCords from "../FormExport/AddCords";
import TableTransfer from "./TableTransfer";
import MuiSkeleton from "@components/shared/MuiSkeleton";
import withSuspense from "@/hocs/withSuspense";

const FormTransfer = ({ handleChange, handleCreate, isFetching }) => {
  const params = useParams();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useFormContext();
  const [isResetMetadata, setIsResetMetadata] = useState(true);

  const [showFormAddCords, setShowFormAddCords] = useState(false);

  const { data: warehouse_boxes, isLoading: warehouse_boxes_fetch } =
    WarehouseBoxApi.getAll();

  const warehouse_process_from =
    warehouse_boxes &&
    warehouse_boxes?.find(
      (item) => item?.value === watch("warehouse_process_from")
    );

  const warehouse_process_to =
    warehouse_boxes &&
    warehouse_boxes?.find(
      (item) => item?.value === watch("warehouse_process_to")
    );

  const handelDeleteItem = (id) => {
    setValue("warehouse_process_metadata", [
      ...watch("warehouse_process_metadata")?.filter((item) => item?.id !== id),
    ]);
  };

  useEffect(() => {
    const warehouse_process_from =
      warehouse_boxes?.find(
        (item) => item?.label === watch("warehouse_process_from")
      ) || "";

    const warehouse_process_to =
      warehouse_boxes?.find(
        (item) => item?.label === watch("warehouse_process_to")
      ) || "";

    setValue("warehouse_process_from", warehouse_process_from?.value);
    setValue("warehouse_process_to", warehouse_process_to?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse_boxes, isFetching]);
  if (isResetMetadata) {
    //reset when change type process
    setValue("warehouse_process_metadata", []);
    setIsResetMetadata(false);
  }
  return (
    <Box
    // component={showFormAddCords ? "div" : "form"}
    // onSubmit={
    //   showFormAddCords
    //     ? () => {}
    //     : handleSubmit((values) =>
    //         handleCreate({
    //           ...values,
    //           warehouse_process_from: warehouse_process_from?.label,
    //           warehouse_process_to: warehouse_process_to?.label,
    //         })
    //       )
    // }
    >
      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Từ *</Typography>
        </Grid>

        <Grid xs={8}>
          <Controller
            rules={{ required: "Trường này là bắt buộc" }}
            name="warehouse_process_from"
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setValue("warehouse_process_metadata", []);
                  }}
                  size="small"
                  fullWidth
                  disabled={warehouse_boxes_fetch || params?.id}
                >
                  {warehouse_boxes?.map((item) => (
                    <MenuItem key={item?.value} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>

                {!!errors?.warehouse_process_from && (
                  <ErrorHelperText
                    message={errors?.warehouse_process_from?.message}
                  />
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Đến *</Typography>
        </Grid>

        <Grid xs={8}>
          <Controller
            rules={{ required: "Trường này là bắt buộc" }}
            name="warehouse_process_to"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  size="small"
                  fullWidth
                  disabled={warehouse_boxes_fetch || params?.id}
                >
                  {warehouse_boxes?.map((item) => (
                    <MenuItem key={item?.value} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>

                {!!errors?.warehouse_process_to && (
                  <ErrorHelperText
                    message={errors?.warehouse_process_to?.message}
                  />
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      {warehouse_process_from && (
        <Grid container mt={2}>
          <Grid xs={4}>
            <Typography>Tìm Kiếm Thiết Bị & Linh Kiện *</Typography>
          </Grid>

          <SearchDevice
            limit={5}
            size={8}
            maxHeight={200}
            onChange={handleChange}
            device={false}
            haveSearchWhenKeyWordEmpty
            paramsSearch={{
              warehouse_box_item_reference_id: warehouse_process_from?.value,
              warehouse_box_item_reference_type: warehouse_process_from?.type,
            }}
          />
        </Grid>
      )}

      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Lý do *</Typography>
        </Grid>

        <Grid xs={8}>
          <Controller
            rules={{ required: "Vui lòng nhập lý do" }}
            name="warehouse_process_reason"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  size="small"
                  placeholder="Nhập lý do..."
                  multiline
                  minRows={3}
                />

                {!!errors?.warehouse_process_reason && (
                  <ErrorHelperText
                    message={errors?.warehouse_process_reason?.message}
                  />
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Ghi chú</Typography>
        </Grid>

        <Grid xs={8}>
          <Controller
            name="warehouse_process_note"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  multiline
                  minRows={3}
                  size="small"
                  placeholder="Nhập ghi chú..."
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid xs={4}>
          <Typography>Luân chuyển dây</Typography>
        </Grid>

        <Grid xs={8}>
          <Button
            variant="outlined"
            size="small"
            startIcon={<SyncAltIcon />}
            onClick={() => setShowFormAddCords(true)}
          >
            Chọn dây
          </Button>
        </Grid>
      </Grid>

      {watch("warehouse_process_metadata")?.length > 0 && (
        <TableTransfer
          warehouse_process_metadata={[...watch("warehouse_process_metadata")]}
          warehouse_process_to={warehouse_process_to}
          handelDeleteItem={handelDeleteItem}
        />
      )}

      {showFormAddCords && (
        <AddCords
          open={showFormAddCords}
          onClose={() => setShowFormAddCords(false)}
          onChange={handleChange}
          params={{
            warehouse_box_item_reference_id: warehouse_process_from?.value,
            warehouse_box_item_reference_type: warehouse_process_from?.type,
          }}
        />
      )}

      <Box mt={4}>
        <Button
          variant="contained"
          size="small"
          fullWidth
          type="button"
          disabled={isFetching}
          onClick={handleSubmit((values) =>
            handleCreate({
              ...values,
              warehouse_process_from: warehouse_process_from?.label,
              warehouse_process_to: warehouse_process_to?.label,
            })
          )}
          startIcon={isFetching && <CircularProgress size={14} />}
        >
          {params?.id ? "Cập nhật phiếu" : "Thêm phiếu"}
        </Button>
      </Box>
    </Box>
  );
};

export default withSuspense(FormTransfer, MuiSkeleton["LinearProgress"]);
