// import react, react-markdown-editor-lite, and a markdown parser you like
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import { extractBlobImageFromMarkdown } from "@/functions";
import usePrevious from "@/hooks/usePrevious";
// import style manually
import "react-markdown-editor-lite/lib/index.css";

const mdParser = new MarkdownIt({ html: true });

const Editor = forwardRef(({ onModelChange, model }, ref) => {
  if (!ref) {
    throw new Error("Missing ref from editor components")
  }

  const [blobUrls, setBlobUrls] = useState([]);
  const blobUrlsPrevious = usePrevious(blobUrls);

  function handleEditorChange({ text }) {
    onModelChange(text);
  }

  function onImageUpload(file) {
    return new Promise(resolve => {
      resolve(URL.createObjectURL(file))
    });
  }

  useImperativeHandle(ref, () => ({
    getBlobImage() {
      return blobUrls
    },

    clearBlobImage() {
      blobUrls?.forEach(url => URL.revokeObjectURL(url))
    }
  }));

  useEffect(() => {
    setBlobUrls(extractBlobImageFromMarkdown(model))
  }, [model])

  useEffect(() => {
    const diff = blobUrlsPrevious?.filter(url => !blobUrls.includes(url))
    diff && diff?.forEach(url => URL.revokeObjectURL(url))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blobUrls])

  return (
    <MdEditor
      style={{ height: "300px" }}
      renderHTML={(text) => mdParser.render(text)}
      onChange={handleEditorChange}
      value={model}
      onImageUpload={onImageUpload}
      imageAccept=".gif,.jpeg,.jpg,.png"
    />
  );
})

export default Editor;

