import DatePicker from '@components/datepickers/DateRangPicker'
import { FormControl } from '@mui/material'
import { useFormContext } from 'react-hook-form'

const Dates = () => {

    const { watch, setValue } = useFormContext()

    const onChangeDates = (values = []) => {
        setValue(`commitment.startdate`, values?.[0])
        setValue(`commitment.enddate`, values?.[1])
    }
    return (
        <FormControl fullWidth>
            <DatePicker
                value={[
                    watch(`commitment.startdate`),
                    watch(`commitment.enddate`)]}
                handler={onChangeDates}
                localeText={{
                    start: `Ngày bắt đầu hiệu lực`,
                    end: `Ngày kết thúc gói hiện tại`
                }}
                sx={{
                    display: 'flex',
                    gap: 2,
                    flexDirection: 'column',
                    ".MuiInputBase-fullWidth": {
                        height: '40px !important'
                    }

                }}
            />
        </FormControl>
    )
}

export default Dates