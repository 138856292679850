import { Box } from "@mui/material";
import { indigo } from "@mui/material/colors";
import React from "react";

const HighLight = ({ cardId, column }) => {
  return (
    <Box
      sx={{
        backgroundColor: indigo[900],
        opacity: 0,
        my: 0.5,
        transition: "opacity 0.1s ease-in-out",
      }}
      width="100%"
      height="4px"
      id={`hightlight-${cardId}`}
      data-column={column}
    />
  );
};

export default HighLight;
