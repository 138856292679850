import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const Prepaid = () => {
    const { control, watch } = useFormContext()

    return (
        <Controller
            name="commitment.prepaid"
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <FormControl
                        fullWidth
                        sx={{
                            alignContent: 'center'
                        }}
                    >
                        <FormControlLabel
                            disabled={parseFloat(watch("commitment.value")) <= 0}
                            control={<Checkbox size="small" checked={value} onChange={onChange} />}
                            label="Trả trước"
                        />
                    </FormControl>
                );
            }}
        />
    )
}

export default Prepaid