import MuiDateTimePicker from "@components/shared/MuiDateTimePicker";
import { FormControl, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const TicketTimeResponse = ({ ticket }) => {
    const { control } = useFormContext()
    if (!ticket) return null;
    return (
        <Controller
            name="ticket_time_response"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
                <FormControl fullWidth>
                    <MuiDateTimePicker
                        {...field}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Ngày phản hồi khách hàng"
                    />
                    <Typography
                        fontSize={13}
                        mt={1}
                        px={2}
                        color="GrayText"
                    >
                        Ngày phản hồi với khách hàng đã xử lý xong ticket
                        để khách hàng kiểm tra xác nhận
                    </Typography>
                </FormControl>
            )}
        />
    )
}

export default TicketTimeResponse