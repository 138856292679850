import BusinessApi from "@/apis/beta/Employee/BusinessApi";
import { useEmployeeContext } from "@/contexts/employee";
import { useNotificationContext } from "@/contexts/notification";
import { queryClientRefetcher, sleep } from "@/functions";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import BusinessPlace from "./BusinessPlace";
import BusinessType from "./BusinessType";
import Customer from "./Customer";
import Timer from "./Timer";
import WorkPurpose from "./WorkPurpose";

const BusinessForm = ({
  type = "create",
  customerId = -1,
  data,
  handleClose,
}) => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams()

  const [locked] = useState(
    !!searchParams.get(`journey_id`) && !!searchParams.get(`journey_update_id`)
  )

  const { setNotification } = useNotificationContext();
  const { employee } = useEmployeeContext();
  const [submit, setSubmit] = React.useState(false);
  let navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      business_place: "",
      business_type: "meeting",
      business_content: "",
      metadata: {
        days: [{ timestamp: moment(), business_option: 2 }],
        customer: {
          customer_id: customerId || -1,
          customer_type: "company",
          customer_name: "",
          customer_position: "",
          customer_email: "",
          customer_company_name: "",
          customer_company_address: "",
          customer_company_website: "",
        },
      },
    },
  });
  const { handleSubmit, reset, setValue, watch } = methods;
  // reset only update
  React.useEffect(() => {
    const redirectData = localStorage.getItem("fromJourneyUpdate");
    if (redirectData) {
      const { customerId, customerName, customeType } = JSON.parse(redirectData);
      setValue("business_type", "meeting");
      setValue("metadata.customer.customer_id", customerId);
      setValue("metadata.customer.customer_name", customerName);
      setValue("metadata.customer.customer_type", customeType);
      localStorage.removeItem("fromJourneyUpdate");
    }
  }, [setValue]);
  React.useEffect(() => {
    if (type === "create") return;
    reset({ ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = async (values) => {
    setSubmit(true);
    let form = {
      employee: {
        ..._.pick(employee, [
          "employee_department",
          "employee_email_company",
          "employee_id",
          "employee_name",
          "employee_position",
        ]),
      },
      business: {
        ..._.pick(values, [
          "business_type",
          "business_content",
          "business_place",
        ]),
        domain: window?.location?.href?.substr(
          0,
          window.location?.href?.length - 3
        ),
        business_department: employee?.employee_department,
      },
    };
    let dayOptions = values?.metadata?.days;
    dayOptions = dayOptions.map((day) => {
      let startTime = moment(day?.timestamp?.["_d"] || day?.timestamp);
      let endTime = moment(day?.timestamp?.["_d"] || day?.timestamp);
      // startTime
      switch (parseInt(day?.business_option)) {
        case 0:
        case 2: // S || CN
          startTime = startTime.set("hour", 8);
          startTime = startTime.set("minute", 30);
          startTime = startTime.set("second", 0);
          break;

        case 1:
          startTime = startTime.set("hour", 13);
          startTime = startTime.set("minute", 0);
          startTime = startTime.set("second", 0); // C - Chieu
          break;
        default:
          return null;
      }
      // startTime
      switch (parseInt(day?.business_option)) {
        case 0: // S
          endTime = endTime.set("hour", 12);
          endTime = endTime.set("minute", 0);
          endTime = endTime.set("second", 0);
          break;
        case 1:
        case 2: // C || CN
          endTime = endTime.set("hour", 17);
          endTime = endTime.set("minute", 30);
          endTime = endTime.set("second", 0); // C - Chieu
          break;
        default:
          return null;
      }
      return {
        ...day,
        timestamp: moment(day.timestamp?.["_d"]).format("YYYY-MM-DD HH:mm:ss"),
        business_startdate: startTime.format("YYYY-MM-DD HH:mm:ss"),
        business_enddate: endTime.format("YYYY-MM-DD HH:mm:ss"),
      };
    });

    dayOptions = _.sortBy(dayOptions, ["business_startdate"]);
    form = {
      ...form,
      business: {
        ...form.business,
        business_startdate: dayOptions?.[0]?.business_startdate,
        business_enddate:
          dayOptions?.[dayOptions?.length - 1]?.business_enddate,
        business_timestamps: dayOptions,
      },
    };
    if (values.business_type === "meeting") {
      form = {
        ...form,
        customer: _.omit(values?.metadata?.customer),
        metadata: {
          journey_id: searchParams.get('journey_id'),
          journey_update_id: searchParams.get('journey_update_id')
        }
      };
    }
    const response =
      type === "update"
        ? await BusinessApi.Update({})
        : await BusinessApi.Create(form);
    await sleep(500);

    setSubmit(false);

    if (!response || response?.errors) {
      return setNotification({
        open: true,
        message: response?.errors[0]?.message,
        severity: "error",
      });
    }
    setNotification({
      open: true,
      message: `${type === "create"
        ? "Tạo phiếu"
        : type === "update"
          ? "Cập nhật"
          : "Huỷ phiếu"
        } công tác thành công`,
      severity: "success",
    });

    queryClientRefetcher(queryClient, [
      "business.list",
      "business.show",
      "customers.list",
      "customers.statistical",
      "customers.statistics",
      "customers.all",
      "customer.customer-journeys",
      "company.detail",
      "companies.list",
    ]);
    try {
      handleClose();
      navigate(`/businesses/${response?.data?.business?.business_id}`, {
        replace: true,
      });
    } catch (error) {
      console.log(`debug:[error-on-business-action]:`, error?.message);
    }
  };
  const [isRedirectFromCustomer] = React.useState(
    JSON.parse(localStorage.getItem("customer.journey.business.create") || "{}")
  );
  React.useEffect(() => {
    if (
      isRedirectFromCustomer?.business_type === "meeting" &&
      !!isRedirectFromCustomer?.customer_id
    ) {
      localStorage.removeItem("customer.journey.business.create");
      localStorage.setItem(
        "redirect",
        `/customers/${isRedirectFromCustomer?.customer_id}/customer-journey`
      );
      setValue("business_type", "meeting");
      return setValue(
        "metadata.customer.customer_id",
        parseInt(isRedirectFromCustomer?.customer_id)
      );
    }
  }, [isRedirectFromCustomer, setValue]);
  return (
    <FormProvider {...methods}>
      <Paper
        sx={{
          p: "30px",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          sx={{
            textTransform: "uppercase",
            fontSize: { xs: "auto", md: 18 },
            fontWeight: "medium",
            mb: 2,
          }}
        >
          {type === "create" ? "Tạo" : type === "update" ? "Cập nhật" : "Huỷ"}{" "}
          phiếu công tác
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3.5}>
            <Typography>Loại Phiếu Công Tác</Typography>
          </Grid>
          <Grid item xs={12} md={8.5}>
            <BusinessType locked={locked} />
            <Collapse in={watch("business_type") === "meeting"}>
              <Customer customerId={customerId} locked={locked} />
            </Collapse>
          </Grid>
          <BusinessPlace />
          <Timer />
          <WorkPurpose />
        </Grid>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 1.5, md: 2 },
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{
              height: {
                xs: 40,
                md: 42,
              },
            }}
            onClick={handleClose}
            disabled={submit}
          >
            Huỷ Thao Tác
          </Button>
          <Button
            variant="contained"
            type="submit"
            size="small"
            sx={{
              height: {
                xs: 40,
                md: 42,
              },
              minWidth: 150,
            }}
            disabled={submit}
            startIcon={
              submit && <CircularProgress size={14} sx={{ color: "#777680" }} />
            }
          >
            {type === "cancel"
              ? "huỷ phiếu"
              : type === "update"
                ? "cập nhật"
                : "tạo phiếu"}{" "}
          </Button>
        </Box>
      </Paper>
    </FormProvider>
  );
};

export default BusinessForm;
