import { INDUSTRIES } from '@/libs/constants';
import ErrorHelperText from '@components/shared/ErrorHelperText';
import SingleSelectCustomize from '@components/shared/SingleSelectCustomize';
import { FormControl, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const CustomerIndustryField = () => {
    const { control, formState: { errors }, watch } = useFormContext();

    return (
        <React.Fragment>
            <Typography sx={{ mb: 0.5, color: "#777680", fontSize: 14 }}>
                Loại Hình Kinh Doanh
            </Typography>
            <Controller
                control={control}
                name="customer.customer_industry_field"
                render={({ field: { value, onChange } }) => {
                    const options = INDUSTRIES.find(industry => industry.value === watch("customer.customer_industry"))?.childrens || []
                    const selected = options?.find(o => o?.value === value) || null
                    return (
                        <FormControl fullWidth>
                            <SingleSelectCustomize
                                disabled={!watch("customer.customer_industry")}
                                value={selected}
                                options={options}
                                input={{ placeholder: "Chọn loại hình kinh doanh..." }}
                                onChange={(event, newValue) => onChange(newValue?.value || -1)}
                            />
                            {!!errors?.customer?.customer_industry_detail && <ErrorHelperText message={errors?.customer?.customer_industry_detail?.message} />}
                        </FormControl>
                    )
                }} />
        </React.Fragment>
    )
}

export default CustomerIndustryField