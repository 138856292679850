import Face6Icon from "@mui/icons-material/Face6";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styleLineClamp } from "@/libs/constants";
import { blue } from "@mui/material/colors";

const renderText = (key, value) => {
  const key_render_time = [
    "ticket_time_request",
    "ticket_time_repply",
    "ticket_time_release",
    "ticket_time_response",
  ];

  if (Array.isArray(value)) {
    return JSON.stringify(value);
  }

  return key_render_time.includes(key)
    ? value
      ? moment(value).format("DD/MM/YYYY HH:mm")
      : ""
    : value;
};

const LogItem = ({ item }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center" gap={2}>
          <Face6Icon />

          <Box>
            <Typography sx={styleLineClamp(1)}>
              <span style={{ color: blue[500] }}>{item?.employee_name}</span>{" "}
              made {item?.ticket_log_action}
            </Typography>
            <Typography fontSize={14} color="GrayText" mt={0.5}>
              {moment(item?.created_at).format("DD/MM/YYYY HH:mm")}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>

      {Object.keys(item?.ticket_log_change)?.length > 0 && (
        <AccordionDetails>
          {Object.keys(item?.ticket_log_change)?.length > 0 ? (
            Object.keys(item?.ticket_log_change)
              ?.filter((p) => p !== "updated_at")
              ?.map((p) => (
                <Grid container>
                  <Grid item xs={12} xl={4}>
                    <Typography fontWeight={600}>{p}</Typography>
                  </Grid>
                  <Grid item xs={12} xl={4}>
                    <Typography sx={styleLineClamp(1)}>
                      <b>Original</b>:{" "}
                      {renderText(p, item?.ticket_log_change?.[p]?.[0])}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} xl={4}>
                    <Typography sx={styleLineClamp(1)}>
                      <b>New</b>:{" "}
                      {renderText(p, item?.ticket_log_change?.[p]?.[1])}
                    </Typography>
                  </Grid>
                </Grid>
              ))
          ) : (
            <Typography>No changes</Typography>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default LogItem;
