import { Box, Typography } from '@mui/material'
import React from 'react'
import withPermission from '@/hocs/withPermission'
import FormPartnerContract from '../shared/FormPartnerContract'

const Update = ({contract = {} , callback , handleClose}) => {
    return (
        <Box sx={{ p: 3, width: 550, minWidth: 360 }}>
            <Typography sx={{ m: 'auto 0', fontSize: 14, color: 'grey.700', mb: 2 }}>
                Cập Nhật Hợp Đồng Đối Tác
            </Typography>
            <FormPartnerContract type="update" contract={contract} handleClose={handleClose} callback={callback} />
        </Box>
    )
}

export default withPermission(Update, { feature: 'contract', action: 'partner-contract-update' })